import { SmRoleModel } from 'models/m0102';
import http from 'utils/axios';

const getSmRoleListAsync = async (page: number, size: number, keyword?: string) => {
  const params = {
    page,
    size,
    keyword,
  };

  return await http.get('api/smrole', { params });
};

const getSmRoleDetailAsync = async (id: string) => {
  return await http.get(`api/smrole/${id}`);
};

const createSmRoleAsync = async (body: SmRoleModel) => {
  return await http.post('api/smrole', body);
};

const deleteSmRoleAsync = async (id: string) => {
  return await http.delete(`api/smrole/${id}`);
};

const updateSmRoleAsync = async (id: string, body: SmRoleModel) => {
  return await http.put(`api/smrole/${id}`, body);
};

const getProgramListAsync = async (id: string, page: number, size: number) => {
  const params = {
    page,
    size,
  };

  return await http.get(`api/smrole/${id}/rolesprogram`, { params });
};

const smroleService = {
  getSmRoleListAsync,
  getSmRoleDetailAsync,
  createSmRoleAsync,
  deleteSmRoleAsync,
  updateSmRoleAsync,
  getProgramListAsync,
};

export default smroleService;