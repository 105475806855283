import { SelectionType } from "constant";
import { CtMakeDocumentDetailChapterCriteria, CtMakeDocumentListCriteria, UserSelection } from "models";
import { LoaderFunctionArgs } from "react-router-dom";
import { c0204, selectionService } from "services";

export const c0204LoaderAsync = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 1;
  const size = url.searchParams.get('size') || 10;
  const criteriaStringify = url.searchParams.get('criteria') || '{}';
  const criteria = JSON.parse(criteriaStringify) as CtMakeDocumentListCriteria;

  const [bureaus, committees, subCommittees, ctMakeDocumentList, smUser] = await Promise.all([
    selectionService.getSelectionAsync(SelectionType.SM_BUREAU),
    selectionService.getSelectionAsync(SelectionType.CT_COMMITTEE),
    selectionService.getSelectionAsync(SelectionType.CT_SUBCOMMITTEE),
    c0204.getCtMakeListAsync(+page, +size, criteria),
    selectionService.getUserSelectionAsync(SelectionType.SM_USER, { isMainGroup: true } as UserSelection),
  ]);

  return {
    bureausItems: bureaus.data,
    committeesItems: committees.data,
    subCommitteesItems: subCommittees.data,
    data: {
      rows: ctMakeDocumentList.data.rows,
      totalRows: ctMakeDocumentList.data.totalRows,
      page: +page,
      size: +size,
    },
    smUser: smUser.data,
  };
};

export const c0204DetailLoaderAsync = async ({ request, params }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 1;
  const size = url.searchParams.get('size') || 10;
  const criteriaStringify = url.searchParams.get('criteria') || '{}';
  const criteria = JSON.parse(criteriaStringify) as CtMakeDocumentDetailChapterCriteria;
  const { id } = params;

  if (id) {
    const [ctMakeDocumentHeader, ctMakeDocumentChapterList] = await Promise.all([
      c0204.getCtMakeDocumentDetailHeaderAsync(id),
      c0204.getCtMakeDocumentDetailChapterAsync(id, +page, +size, criteria)
    ]);

    return {
      ctMakeDocumentHeaderData: ctMakeDocumentHeader.data,
      data: {
        rows: ctMakeDocumentChapterList.data.rows,
        totalRows: ctMakeDocumentChapterList.data.totalRows,
        page: +page,
        size: +size,
      },
    }
  }
};

export const c0204DocumentLoaderAsync = async ({ params }: LoaderFunctionArgs) => {
  return {};
};