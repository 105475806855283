import { useMemo } from "react";

interface Props {
  children?: JSX.Element | JSX.Element[] | string | number | null;
  className?: string;
  center?: boolean;
  right?: boolean;
}

export function Cell(props: Props) {
  const className = useMemo(() => {
    if (!props.className && !props.center && !props.right) {
      return;
    }

    let value = '';

    if (props.className) {
      value = props.className;
    }

    if (props.center) {
      value = `${value} text-center`;
    }

    if (props.right) {
      value = `${value} text-end`;
    }

    return { className: value.trim() };
  }, [props.className, props.center, props.right]);

  return (
    <td {...className}>
      {props.children}
    </td>
  );
}