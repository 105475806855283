import { MeetingInspectCriteria, MeetingInspectDetailCriteria } from 'models';
import http from 'utils/axios';

const getInspectListAsync = async (page: number, size: number, criteria?: MeetingInspectCriteria) => {
  const params = {
    page,
    size,
    ...criteria
  };

  return await http.get(`api/snreviewdocument`, { params });
};

const getInspectDetailListAsync = async (id: string | undefined, page: number, size: number, criteria?: MeetingInspectDetailCriteria) => {
  const params = {
    page,
    size,
    ...criteria
  };

  return await http.get(`api/snmakedocument/meetingId/${id}`, { params });
};

const sendEditInspectAsync = async (id: string, fileId: string, comment: string, score?: number) => {
  const data = {
    fileId,
    score,
    comment,
  };

  return await http.put(`api/snreviewdocument/sendedit/${id}`, data);
};

const approveInspectAsync = async (id: string) =>
  await http.put(`api/snreviewdocument/reviewed/${id}`);

const getAgendaDDLAsync = async (id: string | undefined) =>
  await http.get(`api/snmeetingagenda/snmeeting/${id}`);

const inspectCollectAsync = async (id: string | undefined) =>
  await http.put(`api/snreviewdocument/collect/${id}`);

const s0205 = {
  getInspectListAsync,
  getInspectDetailListAsync,
  getAgendaDDLAsync,
  sendEditInspectAsync,
  approveInspectAsync,
  inspectCollectAsync,
};

export default s0205;