import { Card, Input, Layout, Status, StatusType, Table } from "components";
import Video, { VideoOption } from "components/Video/Video";
import { HttpStatusCode } from "constant";
import { InQuiryMeetingVideoDetailList, InQuiryMeetingVideoHeaderDetail, InquiryMeetingVideoDetailCriteria, Pagination } from "models";
import { Dispatch, FormEvent, SetStateAction, createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaAngleLeft, FaCloudDownloadAlt, FaPlay, FaSearch, FaTrashAlt } from "react-icons/fa";
import { useLoaderData, useNavigate, useParams, useSubmit } from "react-router-dom";
import { snVideoService } from "services";
import { getFormatDateBC, getTime, getYearBC } from "utils";

type Loader = { headerData: InQuiryMeetingVideoHeaderDetail, listData: Pagination<InQuiryMeetingVideoDetailList>, };

type InQuiryMeetingVideoDetailContext = {
  list: Pagination<InQuiryMeetingVideoDetailList>;
  setList: Dispatch<SetStateAction<Pagination<InQuiryMeetingVideoDetailList>>>;
  searchAsync: Function;
  onClear: () => void;
  criteria: InquiryMeetingVideoDetailCriteria;
  setCriteria: Dispatch<SetStateAction<InquiryMeetingVideoDetailCriteria>>;
  header: InQuiryMeetingVideoHeaderDetail;
  setHeader: Dispatch<SetStateAction<InQuiryMeetingVideoHeaderDetail>>;
  listSelected: InQuiryMeetingVideoDetailList;
  setListSelected: Dispatch<SetStateAction<InQuiryMeetingVideoDetailList>>;
  videoSelect: File | undefined;
  setVideoSelect: Dispatch<SetStateAction<File | undefined>>;
  getVideoSelectAsync: Function;
  getFileIdAsync: Function;
};

const Context = createContext({} as InQuiryMeetingVideoDetailContext);

export default function S0303Detail() {
  const { headerData, listData } = useLoaderData() as Loader;
  const [header, setHeader] = useState<InQuiryMeetingVideoHeaderDetail>(headerData);
  const [list, setList] = useState<Pagination<InQuiryMeetingVideoDetailList>>(listData);
  const [listSelected, setListSelected] = useState<InQuiryMeetingVideoDetailList>({} as InQuiryMeetingVideoDetailList);
  const [videoSelect, setVideoSelect] = useState<File>();
  const [criteria, setCriteria] = useState<InquiryMeetingVideoDetailCriteria>({} as InquiryMeetingVideoDetailCriteria);
  const submit = useSubmit();
  const meetingId = useParams().id;
  const [fileIds, setFileIds] = useState<string[]>([]);
  const [fileIndex, setFileIndex] = useState<number>(0);
  const videoRef = useRef<VideoOption>({} as VideoOption);

  useEffect(() => {
    if (headerData.id) {
      getFullVideoAsync(headerData.id);
    }
  }, [headerData]);

  useEffect(() => {
    setList(listData);
    setHeader(headerData);
  }, [listData, headerData]);

  useEffect(() => {
    if (fileIds.length) {
      getVideoSelectAsync(fileIds[0]);
      setFileIndex(0);
    } else {
      setVideoSelect(undefined);
    }
  }, [fileIds]);

  const searchAsync = useCallback(async (size: number, page: number, criteria?: InquiryMeetingVideoDetailCriteria) => {
    submit({
      page: page.toString(),
      size: size.toString(),
      criteria: JSON.stringify(criteria),
    });
  }, [submit]);

  const onClear = () => {
    setCriteria({} as InquiryMeetingVideoDetailCriteria);

    submit({
      page: listData.page.toString(),
      size: listData.size.toString(),
    });
  };

  const getVideoSelectAsync = async (id: string) => {
    const { data, status } = await snVideoService.getVideoAsync(id, meetingId);

    if (status === HttpStatusCode.OK) {
      setVideoSelect(data);

      return data;
    };

    setVideoSelect({} as File);
  };

  const getFileIdAsync = async (id: string) => {
    const { data, status } = await snVideoService.getRecordFileIdAsync(id, meetingId!);

    if (status === HttpStatusCode.OK) {
      setFileIds(data);

      return data as string[];
    }

    return [];
  };

  const contextValue = useMemo(() => {
    return {
      list,
      setList,
      searchAsync,
      onClear,
      criteria,
      setCriteria,
      header,
      setHeader,
      listSelected,
      setListSelected,
      videoSelect,
      setVideoSelect,
      getVideoSelectAsync,
      getFileIdAsync,
    }
  }, [getFileIdAsync, list, setList, searchAsync, onClear, criteria, setCriteria, header, setHeader, listSelected, setListSelected, videoSelect, setVideoSelect, getVideoSelectAsync]);

  const getFullVideoAsync = async (id: string) => {
    const { data, status } = await snVideoService.getFullVideoAsync(id);

    if (status === HttpStatusCode.OK) {
      setVideoSelect(data);
    }
  };

  const onEndedAsync = async () => {
    const currentIndex = fileIndex + 1;

    if (fileIds.length > currentIndex) {
      getVideoSelectAsync(fileIds[currentIndex]);

      setFileIndex(currentIndex);
    }
    else {
      setFileIndex(0);
    }
  };

  const onLoadedData = () => {
    if (fileIndex) {
      videoRef.current.playRef();
    }
  };

  return (
    <Context.Provider value={contextValue}>
      <Layout title="ข้อมูลภาพและเสียงการประชุม">
        <HeaderButton
          onWatchVideoClick={() => getFullVideoAsync(headerData.id)} />
        <Row>
          <Col md={4} lg={4}>
            <MeetingDetail />
          </Col>
          <Col md={8} lg={8}>
            <div>
              <Video
                ref={videoRef}
                onEnded={onEndedAsync}
                onLoadedData={onLoadedData}
                files={videoSelect?.type ? [videoSelect] : []}
                showPlayButton={false}
                showPlayTimeConfig={false}
                showSpeakToText={false}>
              </Video>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <DebaterCriteria />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <DataTable />
          </Col>
        </Row>
      </Layout>
    </Context.Provider>
  );
}

function HeaderButton(
  props: { onWatchVideoClick: () => void }
) {
  const navigate = useNavigate();

  return (
    <div className="my-3 d-flex justify-content-between">
      <Button
        variant="outline-primary"
        type="button"
        onClick={() => navigate("/s0303")}>
        <FaAngleLeft className="me-2" />ย้อนกลับ
      </Button>
      <Button
        variant="outline-primary"
        type="button"
        onClick={props.onWatchVideoClick}>
        <FaPlay className="me-2" />ดูวิดีโอที่รวบรวม
      </Button>
    </div>
  );
}

function MeetingDetail() {
  const { header, listSelected } = useContext(Context);

  return (
    <Card>
      <h3>รายละเอียดการประชุม</h3>
      <Row className="mx-3">
        <Col xs={12}>
          <p>ประเภทการประชุม<span className="mx-2">:</span>{header.meetingTypeName}</p>
        </Col>
        <Col xs={12}>
          <p>ครั้งที่<span className="mx-2">:</span>ครั้งที่ {header.time}</p>
        </Col>
        <Col xs={12}>
          <p>สมัยการประชุม<span className="mx-2">:</span>{header.meetingPeriodName}</p>
        </Col>
        <Col xs={12}>
          <p>ปี<span className="mx-2">:</span>{getYearBC(header.year.toString())}</p>
        </Col>
        <Col xs={12}>
          <p>ห้องประชุม<span className="mx-2">:</span>{header.meetingRoomName}</p>
        </Col>
        <Col xs={12}>
          <p>วันที่ประชุม<span className="mx-2">:</span>{getFormatDateBC(header.startDate)}</p>
        </Col>
      </Row>
      <hr />
      <h3>รายละเอียดการบันทึกสัญญาณภาพและเสียง</h3>
      <Row className="mx-3">
        <Col xs={12}>
          <p>ช่วงเวลา<span className="mx-2">:</span>{listSelected.startDate ? `${getTime(listSelected.startDate)} - ${getTime(listSelected.endDate)}` : ``}</p>
        </Col>
        <Col xs={12}>
          <p>ผู้จดรายงานการประชุม<span className="mx-2">:</span>{listSelected.dutyOfficerUserFullName}</p>
        </Col>
        <Col xs={12}>
          <p>ชื่อไฟล์วิดีโอ<span className="mx-2">:</span>{listSelected.fileName}</p>
        </Col>
        <Col xs={12}>
          {listSelected.status ?
            <div className="d-flex">
              {`สถานะ`}<span className="mx-2">:</span><Status type={StatusType.CHAPTER_STATUS} value={listSelected.status} textOnly />
            </div>
            : <p>สถานะ<span className="mx-2">:</span></p>}
        </Col>
        <Col xs={12}>
          <p>เวลา<span className="mx-2">:</span>{listSelected.startRecord ? `${getTime(listSelected.startRecord)} - ${getTime(listSelected.endRecord)}` : ``}</p>
        </Col>
      </Row>
    </Card>
  );
};

function DebaterCriteria() {
  const { criteria, setCriteria, onClear, searchAsync, list } = useContext(Context);

  const onSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    searchAsync(list.size, list.page, criteria);
  };

  return (
    <Card className="mt-2">
      <Form onSubmit={onSubmitSearch}>
        <label>ค้นหา</label>
        <Row>
          <Col sm={12} md={4}>
            <Input
              label="ผู้อภิปราย"
              placeholder="ผู้อภิปราย"
              value={criteria.debater}
              onChange={(value) => setCriteria({ ...criteria, debater: value })} />
          </Col>
          <Col sm={12} md={4}>
            <Input
              label="วาระ"
              placeholder="วาระ"
              value={criteria.agenda}
              onChange={(value) => setCriteria({ ...criteria, agenda: value })} />
          </Col>
          <Col sm={12} md={4}>
            <div className="criteria-flex">
              <div className="button">
                <Button
                  variant="primary"
                  type="submit">
                  <FaSearch className="me-2" />
                  ค้นหา
                </Button>
                <Button
                  variant="outline-primary"
                  onClick={onClear}>
                  <FaTrashAlt className="me-2" />
                  ล้างค่า
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

function DataTable() {
  const { list, searchAsync, criteria, setListSelected, getVideoSelectAsync, getFileIdAsync } = useContext(Context);

  const watchVideoAsync = async (data: InQuiryMeetingVideoDetailList) => {
    await getFileIdAsync(data.id);

    setListSelected(data);
  };

  const downloadAsync = async (data: InQuiryMeetingVideoDetailList) => {
    const fileIds = await getFileIdAsync(data.id) as string[];

    fileIds.forEach(async id => {
      const file = await getVideoSelectAsync(id);

      if (file) {
        const a = document.createElement('a');
        a.style.display = 'none';
        document.body.appendChild(a);
        a.href = URL.createObjectURL(new Blob([file], { type: file.type }));
        a.setAttribute('download', data.fileName!);
        a.click();

        URL.revokeObjectURL(a.href);
        document.body.removeChild(a);
      }
    });
  };

  return (
    <Table total={list.totalRows} onChange={(size, page) => searchAsync(size, page, criteria)}>
      <Table.Header>
        <Table.Row>
          <Table.Column minWidth={75}>ตอนที่</Table.Column>
          <Table.Column minWidth={125}>ช่วงเวลา</Table.Column>
          <Table.Column minWidth={200}>ผู้จดรายงานการประชุม</Table.Column>
          <Table.Column minWidth={150}>สถานะ</Table.Column>
          <Table.Column minWidth={100}>ชื่อไฟลวิดีโอ</Table.Column>
          <Table.Column minWidth={100}>เวลา</Table.Column>
          <Table.Column minWidth={250} />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {list.rows?.map((value) => (
          <Table.Row key={value.id}>
            <Table.Cell center>{value.chapter}</Table.Cell>
            <Table.Cell center>{`${getTime(value.startDate)} - ${getTime(value.endDate)}`}</Table.Cell>
            <Table.Cell center>{value.dutyOfficerUserFullName}</Table.Cell>
            <Table.Cell center><Status type={StatusType.CHAPTER_STATUS} value={value.status} textOnly /></Table.Cell>
            <Table.Cell center>{value.fileName}</Table.Cell>
            <Table.Cell center>{value.startRecord ? `${getTime(value.startRecord)} ${value.endRecord ? ` - ${getTime(value.endRecord)}` : ''}` : ``}</Table.Cell>
            <Table.Cell>
              <div className="d-flex justify-content-center gap-2">
                <Button
                  variant="outline-primary"
                  type="button"
                  onClick={() => downloadAsync(value)}>
                  <FaCloudDownloadAlt className="me-2" />ดาวน์โหลดวิดีโอ
                </Button>
                <Button
                  variant="outline-primary"
                  onClick={() => watchVideoAsync(value)}>
                  <FaPlay className="me-2" />ดูวิดีโอ
                </Button>
              </div>
            </Table.Cell>
          </Table.Row>))}
      </Table.Body>
    </Table>
  );
};