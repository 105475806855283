import { CtInquiryMeetingStatusCriteria, CtInquiryMeetingStatusDetailCriteria } from 'models';
import http from 'utils/axios';

const getListAsync = async (page: number, size: number, criteria?: CtInquiryMeetingStatusCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get('api/ctinquirymeetingstatus', { params });
};

const getDetailAsync = async (id: string) =>
  await http.get(`api/ctinquirymeetingstatus/ctmeeting/${id}`);

const getDetailHistoryListAsync = async (id: string) =>
  await http.get(`api/ctinquirymeetingstatus/ctmeeting/${id}/ctmeetinghistory`);

const getDetailListAsync = async (id: string, page: number, size: number, criteria?: CtInquiryMeetingStatusDetailCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get(`api/ctinquirymeetingstatus/ctmeeting/${id}/ctmeetingchapter`, { params });
}

const c0301 = {
  getListAsync,
  getDetailAsync,
  getDetailHistoryListAsync,
  getDetailListAsync,
};

export default c0301;