export const showLoading = (url?: string) => {
  document.dispatchEvent(new CustomEvent('onShowLoading', { detail: { url } }));
};

export const hideLoading = (url?: string) => {
  document.dispatchEvent(new CustomEvent('onHideLoading', { detail: { url } }));
};

export default {
  showLoading,
  hideLoading,
};