import { HttpStatusCode } from "constant";
import { IpAddressVideo } from "constant/ipAddress";
import blackMagicServices from "services/blackMagicService";
import { showModalConfirmAsync } from "./modal";

export const manageCacheVideoAsync = async () => {
  let cacheData = "";
  const { status } = await blackMagicServices.getBlackMagicAsync(IpAddressVideo.IP_VIDEO);

  if (status === HttpStatusCode.OK) {
    cacheData = await getCacheStateVideoAsync();

    return cacheData;
  }
};

const getCacheStateVideoAsync = async () => {
  const { data, status } = await blackMagicServices.getBlackMagicStateAsync(IpAddressVideo.IP_VIDEO);

  if (status === HttpStatusCode.OK) {
    return data;
  }
};

export const stopCacheVideoAsync = async () => {
  if (!await showModalConfirmAsync("ต้องการปิดเครื่องบันทึกใช่หรือไม่")) {
    return;
  }

  const { status } = await blackMagicServices.stopBlackMagicAsync(IpAddressVideo.IP_VIDEO);

  if (status === HttpStatusCode.CREATED) {
    return status;
  }
};

export const startCacheVideoAsync = async () => {
  if (!await showModalConfirmAsync("ต้องการเปิดเครื่องบันทึกใช่หรือไม่")) {
    return;
  }

  const { status } = await blackMagicServices.startBlackMagicAsync(IpAddressVideo.IP_VIDEO);

  if (status === HttpStatusCode.CREATED) {
    return status;
  };
};

export const clearCacheVideoAsync = async () => {
  if (!await showModalConfirmAsync("ต้องการล้างแคชเครื่องบันทึกใช่หรือไม่")) {
    return;
  }

  const { status } = await blackMagicServices.removeCacheBlackMagicAsync(IpAddressVideo.IP_VIDEO);

  if (status === HttpStatusCode.NO_CONTENT) {
    return status;
  }
};