import { Card, Input, Layout, Modal, Table } from "components";
import { HttpStatusCode, ModalType } from "constant";
import { CtMeetingType, ItemModel, Pagination } from "models";
import { FormEvent, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaEdit, FaPlus, FaSearch, FaTrashAlt } from "react-icons/fa";
import { MdAddCircleOutline, MdOutlineModeEdit } from "react-icons/md";
import { useLoaderData, useSubmit } from "react-router-dom";
import { c0100 } from "services";
import { fullDateTime, showModalRemoveAsync, submitForm } from "utils";
import toast from "utils/toast";

type Loader = { ctMeetingList: Pagination<CtMeetingType>, smUser: ItemModel[] };

export default function C0100() {
  const [keyword, setKeyword] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState<ModalType>(ModalType.ADD);
  const [listData, setListData] = useState<CtMeetingType>({} as CtMeetingType);
  const { ctMeetingList, smUser } = useLoaderData() as Loader;
  const submit = useSubmit();

  const onSearch = (size?: number, page?: number) => {
    submit({
      page: page ? page : ctMeetingList.page,
      size: size ? size : ctMeetingList.size,
      keyword: keyword,
    });
  };

  const onClearKeyword = () => {
    setKeyword("");

    submit({
      page: 1,
      size: 10,
    });
  };

  const onCreateData = () => {
    setModalType(ModalType.ADD);
    setShowModal(true);
  };

  const onEditData = (listData: CtMeetingType) => {
    setModalType(ModalType.EDIT);
    setShowModal(true);
    setListData(listData);
  };

  const onRemoveDataAsync = async (id: string) => {
    if (!await showModalRemoveAsync()) {
      return;
    }

    const { status } = await c0100.deleteCtMeetingTypeAsync(id);

    if (status === HttpStatusCode.NO_CONTENT) {
      toast.success("ลบข้อมูลสำเร็จ");

      onSearch();
    }
  };

  const getNameUser = (id?: string) => {
    return smUser.find((item) => item.value === id)?.label;
  };

  const onHideModal = () => {
    setShowModal(false);
    setListData({} as CtMeetingType);
  };

  const onSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSearch();
  };

  return (
    <Layout title="ประเภทการประชุม">
      <Card>
        <Form onSubmit={onSubmitSearch}>
          <Row>
            <Col xs={3}>
              <Input
                value={keyword}
                label="ค้นหา"
                placeholder="ประเภทการประชุม"
                onChange={(value) => setKeyword(value)} />
            </Col>
          </Row>
          <div className="d-flex gap-2">
            <Button
              variant="primary"
              type="submit">
              <FaSearch className="me-2" />ค้นหา
            </Button>
            <Button
              variant="outline-primary"
              onClick={onClearKeyword}>
              <FaTrashAlt className="me-2" />ล้างค่า
            </Button>
          </div>
        </Form>
      </Card>
      <div className="d-flex justify-content-end my-3">
        <Button onClick={onCreateData}>
          <FaPlus className="me-2" />เพิ่ม
        </Button>
      </div>
      <div className="table-relative-fix">
        <div className="table-scroll">
          <Table
            total={ctMeetingList.totalRows}
            page={ctMeetingList.page}
            size={ctMeetingList.size}
            onChange={onSearch}>
            <Table.Header>
              <Table.Row>
                <Table.Column minWidth={150}>ลำดับ</Table.Column>
                <Table.Column minWidth={100}>ประเภทการประชุม</Table.Column>
                <Table.Column minWidth={100}>
                  <>จำนวนผู้จัดทำราย<br />งานการประชุม</>
                </Table.Column>
                <Table.Column minWidth={200}>ระยะเวลา/ตอน</Table.Column>
                <Table.Column minWidth={200}>ผู้แก้ไขข้อมูล</Table.Column>
                <Table.Column minWidth={200}>วันที่แก้ไขข้อมูล</Table.Column>
                <Table.Column minWidth={100} className="fix-col" />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {ctMeetingList.rows?.map((item, index) => (
                <Table.Row key={item.id}>
                  <Table.Cell center>{index + 1}</Table.Cell>
                  <Table.Cell>{item.name}</Table.Cell>
                  <Table.Cell right><>{item.numberOfPersons} คน</></Table.Cell>
                  <Table.Cell right><>{item.minute} นาที</></Table.Cell>
                  <Table.Cell center>{getNameUser(item.updateById ? item.updateById : item.createById)}</Table.Cell>
                  <Table.Cell center>{fullDateTime(item.updateDate ? item.updateDate : item.createDate)}</Table.Cell>
                  <Table.Cell center className="fs-5 fix-col">
                    <FaEdit className='me-3' onClick={() => onEditData(item)} />
                    <FaTrashAlt className="me-3" onClick={() => onRemoveDataAsync(item.id ?? "")} />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </div>
      <ModalMeetingType
        show={showModal}
        type={modalType}
        onHide={onHideModal}
        listData={listData} />
    </Layout>
  );
}

function ModalMeetingType(props: {
  show: boolean,
  type: ModalType,
  onHide: () => void,
  listData?: CtMeetingType,
}) {
  const [data, setData] = useState<CtMeetingType>({} as CtMeetingType);
  const submit = useSubmit();

  useEffect(() => {
    if (props.listData && props.show) {
      setData(props.listData);
    }
  }, [props.listData, props.show]);

  const onChangeData = (key: keyof CtMeetingType, value: any) => {
    setData({ ...data, [key]: value });
  };

  const onHide = () => {
    props.onHide();

    setData({} as CtMeetingType);
  };

  const createCtMeetingTypeAsync = async () => {
    const { status } = await c0100.createCtMeetingTypeAsync(data);

    if (status === HttpStatusCode.CREATED) {
      toast.success("เพิ่มข้อมูลสำเร็จ");
    }
  };

  const updateCtMeetingTypeAsync = async (id: string) => {
    const { status } = await c0100.updateCtMeetingTypeAsync(id, data);

    if (status === HttpStatusCode.ACCEPTED) {
      toast.success("แก้ไขข้อมูลสำเร็จ");
    }
  };

  const onSubmit = async () => {
    submitForm();

    if (!data.name || !data.numberOfPersons || !data.minute) {
      return;
    }

    if (data.id) {
      await updateCtMeetingTypeAsync(data.id);
    } else {
      await createCtMeetingTypeAsync();
    }

    onHide();
    submit({});
  };

  return (
    <Modal
      show={props.show}
      icon={props.type === ModalType.ADD ? <MdAddCircleOutline /> : <MdOutlineModeEdit />}
      title={`${props.type === ModalType.ADD ? 'เพิ่ม' : 'แก้ไข'}ประเภทการประชุม`}>
      <div className="mt-3">
        <Input
          label="รหัสประเภทการประชุม"
          rule={{ required: true }}
          value={data.code}
          onChange={(value) => onChangeData("code", value)} />
        <Input
          label="ชื่อประเภทการประชุม"
          rule={{ required: true }}
          value={data.name}
          onChange={(value) => onChangeData("name", value)} />
        <Input
          type="number"
          label="จำนวนผู้จัดทำรายงานการประชุม"
          rule={{ required: true }}
          value={data.numberOfPersons}
          onChange={(value) => onChangeData("numberOfPersons", value)} />
        <Input
          type="number"
          label="ระยะเวลา(นาที) / ตอน"
          rule={{ required: true }}
          value={data.minute}
          onChange={(value) => onChangeData("minute", value)} />
      </div>
      <div className="d-flex justify-content-between">
        <Button
          variant="outline-primary"
          className="me-2 w-100"
          onClick={onHide}>
          ยกเลิก
        </Button>
        <Button
          className="me-2 w-100"
          onClick={onSubmit}>
          ยืนยัน
        </Button>
      </div>
    </Modal>
  );
}