import { Layout, Card, Selector, Input, InputDate, Table, Status, StatusType } from "components";
import { HttpStatusCode, statusMeetingItems } from "constant";
import { ItemModel, SnMeetingAgendaEventLogCriteria, SnmeetingAgendatEventLogList } from "models";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { Form, Col, Row, Button } from "react-bootstrap";
import { FaSearch, FaTrashAlt, FaRegFileAlt, FaRegFileWord } from "react-icons/fa";
import { useLoaderData, useNavigate } from "react-router-dom";
import { s0203 } from "services";
import { calculateRowNumber, exportFileWordOldVersion, formatDateTh, fullDateTime, getTime, getYearList, exportPdfByte } from "utils";
import toast from "utils/toast";

type Loader = {
  snMeetingAgendaEventLogList: SnmeetingAgendatEventLogList[],
  totalRows: number,
  snMeetingTypeItems: ItemModel[],
  snMeetingPeriodItems: ItemModel[]
}

export default function S0203() {
  const { snMeetingAgendaEventLogList, totalRows, snMeetingTypeItems, snMeetingPeriodItems } = useLoaderData() as Loader;
  const [dataList, setDataList] = useState<SnmeetingAgendatEventLogList[]>([]);
  const [criteria, setCriteria] = useState<SnMeetingAgendaEventLogCriteria>({} as SnMeetingAgendaEventLogCriteria);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const navigete = useNavigate();

  useEffect(() => {
    if (snMeetingAgendaEventLogList && totalRows) {
      setDataList(snMeetingAgendaEventLogList);
      setTotalRecords(totalRows);
    }
  }, [snMeetingAgendaEventLogList, totalRows]);

  const onChangePageSize = (size: number, page: number) => {
    setPage(page);
    setSize(size);
  };

  const getSnMeetingAgendaEventlogAsync = useCallback(async (criteriaData?: SnMeetingAgendaEventLogCriteria) => {
    const { data, status } = await s0203.getSnMeetingAgendaEventLogAsync(page, size, criteriaData);

    if (status === HttpStatusCode.OK) {
      setDataList(data.rows);
      setTotalRecords(data.totalRows);
    }

  }, [page, size]);

  useEffect(() => {
    getSnMeetingAgendaEventlogAsync();
  }, [getSnMeetingAgendaEventlogAsync]);

  const onChangeCriteria = (prop: keyof SnMeetingAgendaEventLogCriteria, value?: string | Date | number) => {
    setCriteria({ ...criteria, [prop]: value });
  };

  const onSearchAsync = async () => {
    await getSnMeetingAgendaEventlogAsync(criteria);
  };

  const clearCriteria = async () => {
    setCriteria({} as SnMeetingAgendaEventLogCriteria);
    setPage(1);
    setSize(10);

    await getSnMeetingAgendaEventlogAsync({} as SnMeetingAgendaEventLogCriteria);
  };

  const onSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSearchAsync();
  };

  const onExportDocumentAsync = async (meetingId: string) => {
    const { data, status } = await s0203.exportEventDocumentAsync(meetingId);

    if (status === HttpStatusCode.OK) {
      exportPdfByte(data);
      toast.success("ออกรายงานสำเร็จ");
    }
  };

  return (
    <Layout title="บันทึกเหตุการณ์">
      <Card>
        <Form onSubmit={onSubmitSearch}>
          <Row>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Selector
                label="ประเภทการประชุม"
                placeholder="ประเภทการประชุม"
                items={snMeetingTypeItems}
                value={criteria.meetingTypeId}
                onChange={(value) => onChangeCriteria("meetingTypeId", value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Input
                type="number"
                className="w-50"
                label="ครั้งที่ประชุม"
                value={criteria.time}
                onChange={(value) => onChangeCriteria("time", value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Selector
                label="สมัยการประชุม"
                placeholder="สมัยการประชุม"
                items={snMeetingPeriodItems}
                value={criteria.periodId}
                onChange={(value) => onChangeCriteria("periodId", value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Input
                label="ชื่อการประชุม"
                value={criteria.name}
                onChange={(value) => onChangeCriteria("name", value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Selector
                label="ปี"
                placeholder="ปี"
                items={getYearList()}
                value={criteria.year}
                onChange={(value) => onChangeCriteria("year", value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <InputDate
                className="w-100"
                name="meeting-date"
                label="วันที่ประชุม"
                value={criteria.meetingDate}
                onChange={(value) => onChangeCriteria("meetingDate", value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Selector
                label="สถานะ"
                placeholder="สถานะ"
                items={statusMeetingItems}
                value={criteria.status}
                onChange={(value) => onChangeCriteria("status", value)} />
            </Col>
          </Row>
          <div className="d-flex gap-3 justify-content-start mt-2">
            <Button
              variant="primary"
              type="submit">
              <FaSearch className="me-2" />ค้นหา
            </Button>
            <Button
              variant="outline-primary"
              type="button"
              onClick={clearCriteria}>
              <FaTrashAlt className="me-2" />ล้างค่า
            </Button>
          </div>
        </Form>
      </Card>
      <Row>
        <Col sm={12}>
          <div className="d-flex justify-content-end my-2">

          </div>
        </Col>
        <Col>
          <div className="table-relative-fix">
            <div className="table-scroll">
              <Table
                total={totalRecords}
                page={page}
                size={size}
                onChange={onChangePageSize}>
                <Table.Header>
                  <Table.Row>
                    <Table.Column minWidth={100}>ลำดับ</Table.Column>
                    <Table.Column minWidth={150}>ประเภทการประชุม</Table.Column>
                    <Table.Column minWidth={100}>ครั้งที่</Table.Column>
                    <Table.Column minWidth={250}>สมัยการประชุม</Table.Column>
                    <Table.Column minWidth={250}>ชื่อการประชุม</Table.Column>
                    <Table.Column minWidth={250}>วันที่ประชุม</Table.Column>
                    <Table.Column minWidth={200}>สถานะ</Table.Column>
                    <Table.Column minWidth={200}>ผู้แก้ไขข้อมูล</Table.Column>
                    <Table.Column minWidth={200}>วันที่แก้ไขข้อมูล</Table.Column>
                    <Table.Column minWidth={290} className="fix-col" />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {dataList?.map((data, index) => (
                    <Table.Row key={data.id}>
                      <Table.Cell center>{calculateRowNumber(index, page, size).toString()}</Table.Cell>
                      <Table.Cell center>{data.meetingType}</Table.Cell>
                      <Table.Cell center>{data.time.toString()}</Table.Cell>
                      <Table.Cell center>{data.meetingPeriod}</Table.Cell>
                      <Table.Cell>{data.name}</Table.Cell>
                      <Table.Cell center>
                        {`${formatDateTh(data.meetingDate)} ${getTime(data.startTimeFirst)} - ${getTime(data.endTimeFirst)} น.`}
                      </Table.Cell>
                      <Table.Cell center><Status type={StatusType.MEETING_STATUS} value={data.status} /></Table.Cell>
                      <Table.Cell>{data.updateByUserFullName}</Table.Cell>
                      <Table.Cell center>{fullDateTime(data.updateDate)}</Table.Cell>
                      <Table.Cell center className="fix-col">
                        <div className="mx-4 d-flex justify-content-end">
                          <Button
                            variant="outline-primary"
                            className="mx-1"
                            size="sm"
                            onClick={() => onExportDocumentAsync(data.id)}>
                            <FaRegFileWord className="me-2" />ออกรายงาน
                          </Button>
                          <Button
                            variant="outline-primary"
                            size="sm"
                            onClick={() => navigete(`detail/${data.id}`)}>
                            <FaRegFileAlt className="me-2" />ดูรายละเอียด
                          </Button>
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}