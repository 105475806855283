import { Layout, Card, Selector, Input, InputDate, Table, Status, StatusType } from "components";
import { StatusMeeting, statusMeetingCommitteeItems } from "constant";
import { CtMakeDocumentList, CtMakeDocumentListCriteria, ItemModel, Pagination } from "models";
import { FormEvent, useEffect, useMemo, useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { FaRegFileAlt, FaSearch, FaTrashAlt } from "react-icons/fa";
import { useLoaderData, useNavigate, useSubmit } from "react-router-dom";
import { formatDateTh, fullDateTime, getYearList, yearToTHFormat } from "utils";

type Loader = { bureausItems: ItemModel[], committeesItems: ItemModel[], subCommitteesItems: ItemModel[], data: Pagination<CtMakeDocumentList>; smUser: ItemModel[]; };

export default function C0204() {
  const { bureausItems, committeesItems, subCommitteesItems, data, smUser } = useLoaderData() as Loader;
  const [subCommitteeItems, setSubCommitteeItems] = useState<ItemModel[]>([...subCommitteesItems]);
  const [criteria, setCriteria] = useState<CtMakeDocumentListCriteria>({} as CtMakeDocumentListCriteria);
  const navigate = useNavigate();
  const submit = useSubmit();

  const statusItems = [
    StatusMeeting.CONFIRM,
    StatusMeeting.RECORD,
    StatusMeeting.RECORDED,
    StatusMeeting.DOCUMENT_MAKING,
    StatusMeeting.DOCUMENT_COMPLETE,
  ];

  const filterStatus = useMemo(() => statusMeetingCommitteeItems.filter(s => statusItems.includes(s.value)), [statusMeetingCommitteeItems]);

  const onChangeCriteria = (prop: keyof CtMakeDocumentListCriteria, value?: string | number | Date,) => {
    setCriteria({ ...criteria, [prop]: value });
  };

  useEffect(() => {
    if (criteria.committeeId) {
      const selectedId = committeesItems.find(f => f.value === criteria.committeeId)?.id;

      const filterItems = subCommitteesItems.filter(f => f.depenId === selectedId);

      setSubCommitteeItems(filterItems);

      return;
    }

    setCriteria({ ...criteria, subCommitteeId: "" });
    setSubCommitteeItems(subCommitteesItems);
  }, [criteria.committeeId]);

  const onSearch = (size: number, page: number) => {
    submit({
      page: page.toString(),
      size: size.toString(),
      criteria: JSON.stringify(criteria),
    });
  };

  const onClear = () => {
    setCriteria({} as CtMakeDocumentListCriteria);

    submit({
      page: 1,
      size: 10,
    });
  };

  const onSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSearch(data.size, data.page);
  };

  const getMeetingTime = (timeFirst: string, timeSecond?: string) => {
    if (timeFirst && timeSecond) {
      return <>{timeFirst}/<br />{timeSecond}</>
    } else if (timeSecond) {
      return timeSecond;
    } else {
      return timeFirst;
    }
  };

  return (
    <Layout title="จัดทำ/แก้ไข รายงานการประชุม">
      <Card>
        <Form onSubmit={onSubmitSearch}>
          <Row>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Selector
                label="สำนัก"
                placeholder="สำนัก"
                items={bureausItems}
                value={criteria.bureauId}
                onChange={(value) => onChangeCriteria('bureauId', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Selector
                label="คณะกรรมาธิการ/อื่นๆ"
                placeholder="คณะกรรมาธิการ/อื่นๆ"
                items={committeesItems}
                value={criteria.committeeId}
                onChange={(value) => onChangeCriteria('committeeId', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Selector
                label="คณะอนุกรรมาธิการ/คณะทำงาน"
                placeholder="คณะอนุกรรมาธิการ/คณะทำงาน"
                items={subCommitteeItems}
                value={criteria.subCommitteeId}
                onChange={(value) => onChangeCriteria('subCommitteeId', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Input
                label="ครั้งที่ประชุม"
                value={criteria.time}
                onChange={(value) => onChangeCriteria('time', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Selector
                label="ปี"
                placeholder="ปี"
                items={getYearList()}
                value={criteria.year}
                onChange={(value) => onChangeCriteria('year', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <InputDate
                label="วันที่ประชุม"
                name="meeting-date"
                value={criteria.date}
                onChange={(value) => onChangeCriteria('date', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Selector
                label="ชื่อผู้จด"
                placeholder="ชื่อผู้จด"
                value={criteria.dutyOfficerUserId}
                items={smUser}
                onChange={(value) => onChangeCriteria('dutyOfficerUserId', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Selector
                label="สถานะ"
                placeholder="สถานะ"
                items={filterStatus}
                value={criteria.status}
                onChange={(value) => onChangeCriteria('status', value)} />
            </Col>
            <div className="d-flex justify-content-start mt-2 gap-3">
              <Button
                variant="primary"
                type="submit">
                <FaSearch className="me-2" />
                ค้นหา
              </Button>
              <Button
                variant="outline-primary"
                type="button"
                onClick={onClear}>
                <FaTrashAlt className="me-2" />
                ล้างค่า
              </Button>
            </div>
          </Row>
        </Form>
      </Card>
      <Row className="mt-3">
        <Col>
          <div className="table-relative-fix">
            <div className="table-scroll">
              <Table
                total={data.totalRows}
                page={data.page}
                size={data.size}
                onChange={onSearch}>
                <Table.Header>
                  <Table.Row>
                    <Table.Column minWidth={50}>ลำดับ</Table.Column>
                    <Table.Column minWidth={120}><>ประเภทการ<br />ประชุม</></Table.Column>
                    <Table.Column minWidth={150}>ห้องประชุม</Table.Column>
                    <Table.Column minWidth={100}>วันที่ประชุม</Table.Column>
                    <Table.Column minWidth={130}>เวลาประชุม</Table.Column>
                    <Table.Column minWidth={150}>สำนัก</Table.Column>
                    <Table.Column minWidth={180}>คณะกรรมาธิการ/อื่นๆ</Table.Column>
                    <Table.Column minWidth={180}>ชื่อการประชุม</Table.Column>
                    <Table.Column minWidth={50}>ปี</Table.Column>
                    <Table.Column minWidth={200}>สถานะ</Table.Column>
                    <Table.Column minWidth={250}>ผู้แก้ไขข้อมูล</Table.Column>
                    <Table.Column minWidth={200}>วันที่แก้ไขข้อมูล</Table.Column>
                    <Table.Column minWidth={200} className="fix-col" />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {data.rows?.map((value, index) => (
                    <Table.Row key={value.id}>
                      <Table.Cell center>{index + 1}</Table.Cell>
                      <Table.Cell center>{value.meetingType}</Table.Cell>
                      <Table.Cell center>{value.meetingRoomName}</Table.Cell>
                      <Table.Cell center>{formatDateTh(value.meetingDate)}</Table.Cell>
                      <Table.Cell center>{getMeetingTime(value.meetingTimeFirst, value.meetingTimeSecond)}</Table.Cell>
                      <Table.Cell center>{value.bureauName}</Table.Cell>
                      <Table.Cell>
                        {value.committeeShortName ? value.committeeShortName : value.committeeName}
                      </Table.Cell>
                      <Table.Cell>
                        {value.subCommitteeShortName ? value.subCommitteeShortName : value.name}
                      </Table.Cell>
                      <Table.Cell center>{yearToTHFormat(value.year)}</Table.Cell>
                      <Table.Cell center><Status type={StatusType.MEETING_STATUS} value={value.status} committee /></Table.Cell>
                      <Table.Cell>{value.updateByUserFullName}</Table.Cell>
                      <Table.Cell center>{fullDateTime(value.updateDate)}</Table.Cell>
                      <Table.Cell center className="fix-col">
                        <Button
                          variant="outline-primary"
                          size='sm'
                          onClick={() => navigate(`detail/${value.id}`)}>
                          <FaRegFileAlt className="me-2" />
                          ดูรายละเอียด
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}