import http from 'utils/axios';

const getBlackMagicAsync = async (ipAddress: string) => {
  return await http.get(`/api/blackmagic/${ipAddress}`, { headers: { isDisabledLoading: true } });
};

const getBlackMagicStateAsync = async (ipAddress: string) => {
  return await http.get(`/api/blackmagic/${ipAddress}/state`, { headers: { isDisabledLoading: true } });
};

const stopBlackMagicAsync = async (ipAddress: string) => {
  return await http.post(`/api/blackmagic/${ipAddress}/state/stop`, { headers: { isDisabledLoading: true } });
};

const startBlackMagicAsync = async (ipAddress: string) => {
  return await http.post(`/api/blackmagic/${ipAddress}/state/start`, { headers: { isDisabledLoading: true } });
};

const removeCacheBlackMagicAsync = async (ipAddress: string) => {
  return await http.delete(`/api/blackmagic/${ipAddress}/cache`, { headers: { isDisabledLoading: true } });
};

const blackMagicServices = {
  getBlackMagicAsync,
  getBlackMagicStateAsync,
  stopBlackMagicAsync,
  startBlackMagicAsync,
  removeCacheBlackMagicAsync,
};

export default blackMagicServices;