import { SelectionType } from "constant";
import { ChapterDocuments, CtInquiryMeetingCriteria, CtInquiryMeetingDetailCriteria, CtReviewOverviewDetail } from "models";
import { DetailType } from "pages/C/C0302/Detail";
import { LoaderFunctionArgs } from "react-router-dom";
import { c0302 as service, selectionService, ctService, } from 'services';

export const c0302LoaderAsync = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 1;
  const size = url.searchParams.get('size') || 10;
  const criteriaStringify = url.searchParams.get('criteria') || '{}';
  const criteria = JSON.parse(criteriaStringify) as CtInquiryMeetingCriteria;

  const [bureaus, committees, subCommittees, list] = await Promise.all([
    selectionService.getSelectionAsync(SelectionType.SM_BUREAU),
    selectionService.getSelectionAsync(SelectionType.CT_COMMITTEE),
    selectionService.getSelectionAsync(SelectionType.CT_SUBCOMMITTEE),
    service.getListAsync(+page, +size, criteria),
  ]);


  return {
    bureaus: bureaus.data,
    committees: committees.data,
    subCommittees: subCommittees.data,
    data: {
      rows: list.data.rows,
      totalRows: list.data.totalRows,
      page: +page,
      size: +size,
    },
  };
};

export const c0302DetailLoaderAsync = async ({ request, params }: LoaderFunctionArgs) => {
  const id = params.id as string;
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 1;
  const size = url.searchParams.get('size') || 10;
  const criteriaStringify = url.searchParams.get('criteria') || '{}';
  const criteria = JSON.parse(criteriaStringify) as CtInquiryMeetingDetailCriteria;

  const [headers, list] = await Promise.all([
    service.getDetailHeaderAsync(id),
    service.getDetailAsync(id, +page, +size, criteria),
  ]);

  return {
    header: headers.data,
    data: {
      rows: list.data.rows,
      totalRows: list.data.totalRows,
      page: +page,
      size: +size,
    },
  };
};

export const c0302DocumentLoaderAsync = async ({ params }: LoaderFunctionArgs) => {
  const id = params.id as string;
  const type = params.type as DetailType;
  const docId = params.docId as string;

  const [headers, videos, document] = await Promise.all([
    service.getDetailHeaderAsync(id),
    type === DetailType.MEETING_RANK ? ctService.getRecordFileIdAsync(docId, id) : ctService.getFullVideoAsync(id),
    type === DetailType.MEETING_RANK ? service.getChapterDocumentAsync(docId) : service.getMeetingDocumentAsync(id),
  ]);

  if (type === DetailType.MEETING_RANK) {
    const chapterVideo = await ctService.getVideoAsync(videos.data[0]);

    return {
      header: headers.data,
      video: chapterVideo.data,
      documentData: document.data.ctChapterDocuments as ChapterDocuments,
      fileId: videos.data,
    }
  }

  return {
    header: headers.data,
    video: videos.data,
    documentData: document.data.ctDocuments as CtReviewOverviewDetail,
  };
};