export enum SelectionType {
  CT_COMMITTEE = 'ctcommittee',
  CT_SUBCOMMITTEE = 'ctsubcommittee',
  SM_BUREAU = 'smbureau',
  SM_BUREAU_GROUP = 'smbureaugroup',
  SM_MEETING_ROOM = 'smmeetingroom',
  SN_AGENDA_TYPE = 'snagendatype',
  SN_MEETING_PERIOD = 'snmeetingperiod',
  SN_MEETING_TYPE = 'snmeetingtype',
  SN_MEMBER = 'snmember',
  SM_USER = 'smuser',
  CT_MEETING_TYPE = 'ctmeetingtype',
  SN_OFFICER_RANK = 'snofficerrank',
}

export enum ShareValue {
  ATTENDEE_TYPE = 'AttendeeType',
  POSITION_TYPE = 'PositionType',
  DAILY_STATUS = 'DailyStatus',
  COMMANDER_POSITION = 'CommanderPosition',
  CT_MEETING_TYPE = 'CtMeetingType',
  DEBATER_TYPE = 'DebaterType',
  QUEUE_TYPE = 'QueueType',
}