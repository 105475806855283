import { Button, Col, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { MdOutlineWarningAmber } from "react-icons/md";

interface ToolTipProps {
  subject: string;
};

export function ToolTipComment(props: ToolTipProps) {
  const popover = (
    <Popover>
      <Popover.Header as="h3">
        <span className="fs-3">ความคิดเห็น</span>
      </Popover.Header>
      <Popover.Body>
        <span className="fs-4">{props.subject}</span>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger
      overlay={popover}
      trigger={'click'}
      placement='bottom'>
      <Row className='mt-2 mb-2'>
        <Col sm={12}>
          <Button variant='danger' className='w-100 fs-5'>
            <span className="d-block text-truncate gap-4 text-start">
              <MdOutlineWarningAmber className='fs-4' /> {`ความคิดเห็น : ${props.subject}`}
            </span>
          </Button>
        </Col>
      </Row>
    </OverlayTrigger>
  );
};