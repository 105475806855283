import http from 'utils/axios';
import { OfficerRankModel } from 'models';

const getOfficerRankListAsync = async (page: number, size: number, keyword?: string) => {
  const params = {
    page,
    size,
    keyword,
  };

  return await http.get(`api/snofficerrank`, { params });
};

const createOfficerRankAsync = async (data: OfficerRankModel) =>
  await http.post(`api/snofficerrank`, data);

const deleteOfficerRankAsync = async (id: string) =>
  await http.delete(`api/snofficerrank/${id}`);

const updateOfficerRankAsync = async (data: OfficerRankModel[]) =>
  await http.put(`api/snofficerrank`, data);

const s0104 = {
  getOfficerRankListAsync,
  createOfficerRankAsync,
  deleteOfficerRankAsync,
  updateOfficerRankAsync,
};

export default s0104;