import { FormEvent, useState } from 'react';
import { Button, Col, Form, Row, Tab } from 'react-bootstrap';
import { Input, InputFile, Layout, Selector, Tabs, InputTime, InputDate, Check } from 'components';
import { submitForm } from 'utils';
import VideoPlayer from 'components/Video/VideoSrcPlayer';

const TABS = [
  { label: 'Tab 1', value: 1 },
  { label: 'Tab 2', value: 2 },
  { label: 'Tab 3', value: 3 },
  { label: 'Tab 4', value: 4 },
  { label: 'Tab 5', value: 5 },
];

// ** Comment Authorize and useClaim in Back-end **
// *** Change Ur Backend api path and id Video ***
// http://192.168.0.168:5183/api/ctmeetingrecord/401726e8-24c2-4da3-a61b-9cd57199f2c8/file

export default function Demo() {
  const [text, setText] = useState<string>();
  const [file, setFile] = useState<File | Blob>();
  const [Selectors, setSelectors] = useState<string>();
  const [time, setTime] = useState<Date>();
  const [date, setDate] = useState<Date>();

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = {
      text: text,
      file: file,
      Selector: Selector,
    };

    submitForm(data);
  };

  return (
    <Layout>
      <Row>
        <Col className="d-flex flex-row gap-2">
          <Button variant="primary" type="button">Primary</Button>
          <Button variant="danger" type="button">Danger</Button>
          <Button variant="light" type="button">Light</Button>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col className="d-flex flex-row gap-2">
          <Button variant="outline-primary" type="button">Primary</Button>
          <Button variant="outline-danger" type="button">Danger</Button>
          <Button variant="outline-light" type="button">Light</Button>
        </Col>
      </Row>
      <Form onSubmit={onSubmit}>
        <Row>
          <Col xs={3}>
            <Input name='text' label='Text' value={text} onChange={value => setText(value)} rule={{ required: true }} />
          </Col>
          <Col xs={3}>
            <InputFile name='file' label='File' value={file} onChange={value => setFile(value)} rule={{ required: true }} />
          </Col>
          <Col xs={3}>
            <Selector name='Selector' label='Selector' value={Selectors} onChange={value => setSelectors(value.toString())} rule={{ required: true }} items={[]} />
          </Col>
          <Col xs={3}>
            <InputTime label='Time' name='time' value={time} onChange={value => setTime(value)} rule={{ required: true }} />
          </Col>
          <Col xs={3}>
            <InputDate name='inputDate' label='Date' value={date} rule={{ required: true }} onChange={value => setDate(value)} />
          </Col>
          <Col xs={3}>
            <Check label='Check' />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button variant="primary" type="submit">Submit</Button>
          </Col>
        </Row>
      </Form>
      <Row className='mt-3'>
        <Col>
          <Tabs items={TABS} defaultActiveKey={1}>
            <Tab.Content>
              <Tab.Pane eventKey={1}>
                1 Full many a glorious morning have I seen Flatter the mountain tops with sovereign eye, Kissing with golden face the meadows green, Gilding pale streams with heavenly alchemy; Anon permit the basest clouds to ride With ugly rack on his celestial face, And from the forlorn world his visage hide, Stealing unseen to west with this disgrace: Even so my sun one early morn did shine, With all triumphant splendour on my brow;
              </Tab.Pane>
              <Tab.Pane eventKey={2}>
                2 Full many a glorious morning have I seen Flatter the mountain tops with sovereign eye, Kissing with golden face the meadows green, Gilding pale streams with heavenly alchemy; Anon permit the basest clouds to ride With ugly rack on his celestial face, And from the forlorn world his visage hide, Stealing unseen to west with this disgrace: Even so my sun one early morn did shine, With all triumphant splendour on my brow;
              </Tab.Pane>
              <Tab.Pane eventKey={3}>
                3 Full many a glorious morning have I seen Flatter the mountain tops with sovereign eye, Kissing with golden face the meadows green, Gilding pale streams with heavenly alchemy; Anon permit the basest clouds to ride With ugly rack on his celestial face, And from the forlorn world his visage hide, Stealing unseen to west with this disgrace: Even so my sun one early morn did shine, With all triumphant splendour on my brow;
              </Tab.Pane>
              <Tab.Pane eventKey={4}>
                4 Full many a glorious morning have I seen Flatter the mountain tops with sovereign eye, Kissing with golden face the meadows green, Gilding pale streams with heavenly alchemy; Anon permit the basest clouds to ride With ugly rack on his celestial face, And from the forlorn world his visage hide, Stealing unseen to west with this disgrace: Even so my sun one early morn did shine, With all triumphant splendour on my brow;
              </Tab.Pane>
              <Tab.Pane eventKey={5}>
                5 Full many a glorious morning have I seen Flatter the mountain tops with sovereign eye, Kissing with golden face the meadows green, Gilding pale streams with heavenly alchemy; Anon permit the basest clouds to ride With ugly rack on his celestial face, And from the forlorn world his visage hide, Stealing unseen to west with this disgrace: Even so my sun one early morn did shine, With all triumphant splendour on my brow;
              </Tab.Pane>
            </Tab.Content>
          </Tabs>
        </Col>
      </Row>
      <Row className="mt-4 mb-4">
        <Col lg={12}>
          <VideoPlayer
            path="http://192.168.0.168:5183/api/ctmeetingrecord/401726e8-24c2-4da3-a61b-9cd57199f2c8/file"
            showPlayButton
            showPlayTimeConfig
            showSpeakToText
            uploadSection
            uploadSecretSection
          />
        </Col>
      </Row>
    </Layout>
  );
}