import { SnMeeting, SnMeetingCriteria, SnMeetingInspect, SnMeetingRank, SnMeetingRankCriteria } from 'models';
import http from 'utils/axios';

const getSnMeetingListAsync = async (page: number, size: number, criteria?: SnMeetingCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get('api/snmeeting', { params });
};

const createSnMeetingAsync = async (body: SnMeeting) => {
  return await http.post('api/snmeeting', body);
};

const getSnMeetingDetailAsync = async (id: string) => {
  return await http.get(`api/snmeeting/${id}`);
};

const getAgendaDataTableAsync = async (id: string, size: number, page: number) => {
  return await http.get(`api/snmeeting/${id}/agenda`, { params: { page, size } });
};

const getMemberDataTableAsync = async (id: string, size: number, page: number) => {
  return await http.get(`api/snmeeting/${id}/member`, { params: { page, size } });
};

const updateSnMeetingAsync = async (id: string, body: SnMeeting) => {
  return await http.put(`api/snmeeting/${id}`, body);
}

const deleteSnMeetingAsync = async (id: string) => {
  return await http.delete(`api/snmeeting/${id}`);
};

const getSnMeetingRankAsync = async (id: string, criteria?: SnMeetingRankCriteria) => {
  const params = {
    id,
    ...criteria,
  };

  return await http.get(`api/snmeeting/meetingrank/${id}`, { params });
};

const updateSnMeetingRankAsync = async (id: string, body: SnMeetingRank) => {
  return await http.put(`api/snmeeting/meetingrank/${id}`, body);
};

const updateSnMeetingRankUpdateRankAsync = async (id: string, body: SnMeetingRank[]) => {
  return await http.put(`api/snmeeting/meetingrank/updaterank/${id}`, body);
};

const getSnMeetingInspectAsync = async (id: string) => {
  return await http.get(`api/snmeeting/meetinginspect/${id}`);
};

const createSnMeetingInspectAsync = async (id: string, body: SnMeetingInspect) => {
  return await http.post(`api/snmeeting/meetinginspect/${id}`, body);
};

const updateSnMeetingInspectAsync = async (id: string, body: SnMeetingInspect) => {
  return await http.put(`api/snmeeting/meetinginspect/${id}`, body);
};

const deleteSnMeetingInspectAsync = async (id: string) => {
  return await http.delete(`api/snmeeting/meetinginspect/${id}`);
};

const syncDataAsync = async (date: Date) => {
  const meetingDate = date.toISOString().split('T')[0];

  return await http.post(`api/snmeeting/sync-data`, JSON.stringify(meetingDate),
    { headers: { 'Content-Type': 'application/json' } });
};

const syncAgendaAsync = async (id: string, meetingIdPis: string) => {
  return await http.post(`api/snmeeting/sync-agenda/${id}`, JSON.stringify(meetingIdPis),
    { headers: { 'Content-Type': 'application/json' } });
};

const updateNewQueueAsync = async (id: string, dutyOfficerId: string) => {
  return await http.put(`api/snmeeting/${id}/new-queue`, JSON.stringify(dutyOfficerId),
    { headers: { 'Content-Type': 'application/json' } });
};

const deleteSnMeetingRankAsync = async (id: string, meetingId: string) => {
  const params = {
    meetingId,
  };

  return await http.delete(`api/snmeeting/snmeetingrank/${id}`, { params });
};

const exportAsync = async (id: string, exportType: string) => {
  return await http.get('api/snmeeting/export', {
    params: { id, exportType },
    responseType: 'blob',
  });
};

const createMeetingChapterAsync = async (id: string, data: SnMeetingRank) => {
  return await http.post(`api/snmeeting/${id}/snmeetingchapter`, data);
};

const snMeetingService = {
  getSnMeetingListAsync,
  createSnMeetingAsync,
  getSnMeetingDetailAsync,
  updateSnMeetingAsync,
  deleteSnMeetingAsync,
  getSnMeetingRankAsync,
  updateSnMeetingRankAsync,
  updateSnMeetingRankUpdateRankAsync,
  getSnMeetingInspectAsync,
  createSnMeetingInspectAsync,
  updateSnMeetingInspectAsync,
  deleteSnMeetingInspectAsync,
  syncDataAsync,
  syncAgendaAsync,
  getAgendaDataTableAsync,
  getMemberDataTableAsync,
  updateNewQueueAsync,
  deleteSnMeetingRankAsync,
  exportAsync,
  createMeetingChapterAsync,
};

export default snMeetingService;