import { Card, Input, InputDate, Layout, Selector, Table } from "components";
import { CriteriaOldSystemMeeting, ItemModel, OldSystemMeeting, Pagination } from "models";
import { FormEvent, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaCloudDownloadAlt, FaSearch, FaTrashAlt } from "react-icons/fa";
import { useLoaderData, useSubmit } from "react-router-dom";
import { calculateRowNumber, exportFileWord, exportFileWordOldVersion, formatDateTh, getTime } from "utils";
import { s0304 } from 'services';
import { HttpStatusCode } from "constant";

type Loader = { meetingType: ItemModel[]; data: Pagination<OldSystemMeeting>; }

export default function S0304() {
  const { meetingType, data } = useLoaderData() as Loader;
  const programName = useState<string>("ค้นหารายงานการประชุมจากระบบเดิม");
  const [criteria, setCriteria] = useState<CriteriaOldSystemMeeting>({} as CriteriaOldSystemMeeting);
  const submit = useSubmit();

  const onChangeCriteria = (prop: keyof CriteriaOldSystemMeeting, value?: string | number | Date) => {
    setCriteria({ ...criteria, [prop]: value });
  };

  const onClear = () => {
    setCriteria({} as CriteriaOldSystemMeeting);
    submit({});
  };

  const onSearch = (size: number, page: number) => {
    submit({
      page: page,
      size: size,
      criteria: JSON.stringify(criteria),
    });
  }

  const onSubmitSearch = (event: FormEvent<HTMLElement>) => {
    event.preventDefault();

    onSearch(data.size, data.page);
  };

  const onExportDocumentAsync = async (id: string) => {
    const { data, status } = await s0304.exportDocumentAsync(id);

    if (status === HttpStatusCode.OK) {
      exportFileWordOldVersion(data);
    }
  };

  return (
    <Layout title={programName[0]}>
      <Card>
        <Form onSubmit={onSubmitSearch}>
          <Row>
            <Col sm={12} md={3}>
              <Selector
                label="ประเภทการประชุม"
                placeholder="ประเภทการประชุม"
                items={meetingType}
                value={criteria.meetingType}
                onChange={(value) => onChangeCriteria('meetingType', value)} />
            </Col>
            <Col sm={12} md={3}>
              <Input
                type="number"
                label="ครั้งที่"
                value={criteria.time}
                onChange={(value) => onChangeCriteria('time', value)} />
            </Col>
            <Col sm={12} md={3}>
              <Input
                label="ชื่อการประชุม"
                value={criteria.meetingName}
                onChange={(value) => onChangeCriteria('meetingName', value)} />
            </Col>
            <Col sm={12} md={3}>
              <Input
                label="ผู้รวบรวมรายงานการประชุม"
                value={criteria.collectName}
                onChange={(value) => onChangeCriteria('collectName', value)} />
            </Col>
            <Col sm={12} md={3}>
              <InputDate
                label="วันที่ประชุม"
                value={criteria.date}
                onChange={(value) => onChangeCriteria('date', value)} />
            </Col>
          </Row>
          <Row>
            <Col className="d-flex gap-2 justify-content-start mt-4">
              <Button
                variant="primary"
                type="submit">
                <FaSearch className="me-2" />ค้นหา
              </Button>
              <Button
                variant="outline-primary"
                type="button"
                onClick={onClear}>
                <FaTrashAlt className="me-2" />ล้างค่า
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <div className="table-relative-fix">
        <div className="table-scroll">
          <Table
            onChange={onSearch}
            page={data.page}
            size={data.size}
            total={data.totalRows}>
            <Table.Header>
              <Table.Row>
                <Table.Column minWidth={80}>ลำดับ</Table.Column>
                <Table.Column minWidth={200}>ประเภทการประชุม</Table.Column>
                <Table.Column minWidth={100}>ครั้งที่</Table.Column>
                <Table.Column minWidth={350}>ชื่อการประชุม</Table.Column>
                <Table.Column minWidth={200}>ผู้รวบรวมรายงานการประชุม</Table.Column>
                <Table.Column minWidth={250}>วันที่ประชุม</Table.Column>
                <Table.Column minWidth={200} className="fix-col" />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.rows?.map((value, index) => (
                <Table.Row key={value.unid}>
                  <Table.Cell center>{calculateRowNumber(index, data.page, data.size)}</Table.Cell>
                  <Table.Cell center>{value.meetingTypeName}</Table.Cell>
                  <Table.Cell center>{value.meetingNo}</Table.Cell>
                  <Table.Cell>{value.meetingSessionName}</Table.Cell>
                  <Table.Cell>{value.collectUser}</Table.Cell>
                  <Table.Cell center>{`${formatDateTh(value.meetingDate)} ${getTime(value.startHour)} - ${getTime(value.startHour)} น.`}</Table.Cell>
                  <Table.Cell center className="fix-col">
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={() => onExportDocumentAsync(value.unid)}>
                      <FaCloudDownloadAlt className="me-2" /> ดาวโหลดเอกสาร
                    </Button>
                  </Table.Cell>
                </Table.Row>))}
            </Table.Body>
          </Table>
        </div>
      </div>
    </Layout>
  );
};