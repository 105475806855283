import { Layout, Selector, Card, Input, InputDate, Table, Status, StatusType, Modal } from "components";
import { Row, Col, Button, Form } from "react-bootstrap";
import { FaSearch, FaTrashAlt, FaSyncAlt, FaPlus, FaEdit, FaRegFileExcel, FaRegFilePdf, FaRegFileWord } from "react-icons/fa";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { HttpStatusCode, StatusMeeting, statusMeetingCommitteeItems } from "constant";
import { FormEvent, useEffect, useMemo, useState } from "react";
import { C0202Criteria, ItemModel } from "models";
import { exportFileExcel, exportFileWord, exportPdfByte, formatDateTh, fullDateTime, getYearList, showModalConfirmAsync, submitForm, yearToTHFormat } from "utils";
import { c0202 } from "services";
import toast from "utils/toast";

type MeetingListResponse = {
  id: string;
  isSync: boolean;
  meetingType: string;
  meetingRoomName: string;
  meetingDate: string;
  name: string;
  bureauName: string;
  committeeName: string;
  committeeShortName: string;
  subCommitteeName: string;
  subCommitteeShortName: string;
  meetingTimeFirst: string;
  meetingTimeSecond?: string;
  time: string;
  year: number;
  status: string;
  updateDate: Date;
  updateByUserFullName: string;
  dutyOfficer: string;
};

type Loader = {
  bureaus: ItemModel[];
  committees: ItemModel[];
  subCommittees: ItemModel[];
  smUser: ItemModel[];
};

export default function C0202() {
  const navigate = useNavigate();
  const { bureaus, committees, subCommittees, smUser } = useLoaderData() as Loader;
  const [subCommitteeItems, setSubCommitteeItems] = useState<ItemModel[]>([...subCommittees]);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [meetingList, setMeetingList] = useState<MeetingListResponse[]>([]);
  const [criteria, setCriteria] = useState<C0202Criteria>({} as C0202Criteria);
  const [criteriaTemp, setCriteriaTemp] = useState<C0202Criteria>({} as C0202Criteria);
  const [showSyncDataModal, setShowSyncDataModal] = useState<boolean>(false);
  const [onInit, setOninit] = useState<boolean>(true);
  const [showExportMeetingModal, setShowExportMeetingModal] = useState<{ show: boolean, type: string }>({ show: false, type: "" });
  const criteriaListData = useLocation().state?.criteriaData as C0202Criteria;
  const listPage = useLocation().state?.page as number;
  const listSize = useLocation().state?.size as number;

  const statusItems = [
    StatusMeeting.DRAFT,
    StatusMeeting.CONFIRM,
    StatusMeeting.CANCEL,
  ];

  const filterStatus = useMemo(() => statusMeetingCommitteeItems.filter(s => statusItems.includes(s.value)), [statusMeetingCommitteeItems]);

  useEffect(() => {
    if (criteriaListData && listPage && listSize && onInit) {
      setCriteria(criteriaListData);
      setCriteriaTemp(criteriaListData);

      (async () => {
        const data = await getDataOnBackFromDetailAsync(listPage, listSize, criteriaListData);

        setPage(listPage);
        setSize(listSize);
        setTotalRows(data.totalRows);
        setMeetingList(data.rows);
      })();

      if (criteriaListData.committeeId) {
        const selectedId = committees.find(f => f.value === criteriaListData.committeeId)?.id;
        const filterItems = subCommittees.filter(f => f.depenId === selectedId);

        setSubCommitteeItems(filterItems);
      }

      setOninit(false);

      return;
    }

    getListAsync();
  }, [criteriaListData, page, size]);

  const getListAsync = async (criteriaData?: C0202Criteria) => {
    const { data, status } = await c0202.getListAsync(page, size, criteriaData ?? criteria);

    if (status === HttpStatusCode.OK) {
      setTotalRows(data.totalRows);
      setMeetingList(data.rows);
    }
  };

  const onChange = (prop: string, value?: string | number | Date) => {
    setCriteria({ ...criteria, [prop]: value });
  };

  const onClear = () => {
    setPage(1);
    setSize(10);

    setCriteria({} as C0202Criteria);
    setCriteriaTemp({} as C0202Criteria);

    (async () => await getClearData())();
  };

  const getClearData = async () => {
    const data = await getDataOnBackFromDetailAsync(1, 10, {} as C0202Criteria);

    setTotalRows(data.totalRows);
    setMeetingList(data.rows);
  }

  const onSearch = (size: number, page: number) => {
    setCriteriaTemp(criteria);
    setPage(page);
    setSize(size);
  };

  const onRemoveAsync = async (id: string) => {
    if (!await showModalConfirmAsync('ยืนยันการลบการประชุม')) {
      return;
    }

    const { status } = await c0202.removeAsync(id);

    if (status === HttpStatusCode.NO_CONTENT) {
      toast.success('ลบข้อมูลสำเร็จ');

      getListAsync();
    }
  };

  const getMeetingTime = (timeFirst: string, timeSecond?: string) => {
    if (timeFirst && timeSecond) {
      return <>{timeFirst}/<br />{timeSecond}</>
    } else if (timeSecond) {
      return timeSecond;
    } else {
      return timeFirst;
    }
  };

  const onSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setCriteriaTemp(criteria);

    getListAsync();
  };

  const canDelete = (status: string) => {
    return (status === StatusMeeting.DRAFT || status === StatusMeeting.CANCEL);
  };

  const onChangeCommitteeCriteria = (value: string | number) => {
    if (value) {
      setCriteria({ ...criteria, committeeId: value as number });

      const selectedId = committees.find(f => f.value === value)?.id;
      const filterItems = subCommittees.filter(f => f.depenId === selectedId);

      setSubCommitteeItems(filterItems);

      return;
    }

    setCriteria({ ...criteria, committeeId: undefined, subCommitteeId: undefined });
    setSubCommitteeItems(subCommittees);
  };

  return (
    <Layout title="กำหนดข้อมูลการประชุม">
      <Card>
        <Form onSubmit={onSubmitSearch}>
          <Row>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Selector
                value={criteria.bureauId}
                label="สำนัก"
                placeholder="สำนัก"
                items={bureaus}
                onChange={(value) => onChange('bureauId', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Selector
                value={criteria.committeeId}
                label="คณะกรรมาธิการ/อื่นๆ"
                placeholder="คณะกรรมาธิการ/อื่นๆ"
                items={committees}
                onChange={(value) => onChangeCommitteeCriteria(value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Selector
                value={criteria.subCommitteeId}
                label="คณะอนุกรรมาธิการ/คณะทำงาน"
                placeholder="คณะอนุกรรมาธิการ/คณะทำงาน"
                items={subCommitteeItems}
                onChange={(value) => onChange('subCommitteeId', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Input
                value={criteria.time}
                label="ครั้งที่ประชุม"
                type="number"
                onChange={(value) => onChange('time', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Selector
                value={criteria.year}
                label="ปี"
                placeholder="ปี"
                items={getYearList()}
                onChange={(value) => onChange('year', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <InputDate
                value={criteria.meetingDate}
                name="meeting-date"
                label="วันที่ประชุม"
                onChange={(value) => onChange('meetingDate', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Selector
                label="ชื่อผู้จด"
                placeholder="ชื่อผู้จด"
                value={criteria.dutyOfficerUserId}
                items={smUser}
                onChange={(value) => onChange('dutyOfficerUserId', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Selector
                label="สถานะ"
                placeholder="สถานะ"
                value={criteria.status}
                items={filterStatus}
                onChange={(value) => onChange('status', value)} />
            </Col>
          </Row>
          <div className="d-flex gap-3">
            <Button
              variant="primary"
              type="submit">
              <FaSearch className="me-2" />ค้นหา
            </Button>
            <Button
              variant="outline-primary"
              type="button"
              onClick={onClear}>
              <FaTrashAlt className="me-2" />ล้างค่า
            </Button>
          </div>
        </Form>
      </Card>
      <div className="d-flex justify-content-end my-3 align-items-center">
        <Button
          variant="outline-primary"
          className="me-2"
          onClick={() => setShowExportMeetingModal({ show: true, type: "PDF" })} >
          <FaRegFilePdf className="me-2" />Export PDF
        </Button>
        <Button
          variant="outline-primary"
          className="me-2"
          onClick={() => setShowExportMeetingModal({ show: true, type: "Word" })} >
          <FaRegFileWord className="me-2" />Export Word
        </Button>
        <Button
          variant="outline-primary"
          onClick={() => setShowExportMeetingModal({ show: true, type: "Excel" })}>
          <FaRegFileExcel className="me-2" />Export Excel
        </Button>
        <Button
          variant="primary"
          type="button"
          className="mx-3"
          onClick={() => setShowSyncDataModal(true)}>
          <FaSyncAlt className="me-2" />ซิงค์ข้อมูล
        </Button>
        <Button
          variant="outline-primary"
          type="button"
          onClick={() => navigate('detail', { state: { criteriaData: criteriaTemp } })}>
          <FaPlus className="me-2" />เพิ่มการประชุม
        </Button>
      </div>
      <Row>
        <Col sm="12" md={{ span: 8, offset: 4 }} className="d-flex justify-content-end align-items-center">
          <p className="text-danger fs-7">* เมื่อกดซิงก์ข้อมูลแล้ว กรุณาค้นหาห้องที่ซิงก์ข้อมูลเพื่อใส่ข้อมูลการประชุม ปุ่มเพิ่มการประชุมมีไว้สำหรับห้องที่ไม่จองในระบบ PIS</p>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <div className="table-relative-fix">
            <div className="table-scroll">
              <Table
                onChange={onSearch}
                total={totalRows}
                page={page}
                size={size}>
                <Table.Header>
                  <Table.Row>
                    <Table.Column minWidth={80}></Table.Column>
                    <Table.Column minWidth={50}>ลำดับ</Table.Column>
                    <Table.Column minWidth={150}><>ประเภทการ<br />ประชุม</></Table.Column>
                    <Table.Column minWidth={150}>ห้องประชุม</Table.Column>
                    <Table.Column minWidth={100}>วันที่ประชุม</Table.Column>
                    <Table.Column minWidth={130}>เวลาประชุม</Table.Column>
                    <Table.Column minWidth={150}>สำนัก</Table.Column>
                    <Table.Column minWidth={180}>คณะกรรมาธิการ/อื่นๆ</Table.Column>
                    <Table.Column minWidth={180}>ชื่อการประชุม</Table.Column>
                    <Table.Column minWidth={200}>ผู้จัดทำรายงาน</Table.Column>
                    <Table.Column minWidth={50}>ปี</Table.Column>
                    <Table.Column minWidth={100}>สถานะ</Table.Column>
                    <Table.Column minWidth={150}>ผู้แก้ไขข้อมูล</Table.Column>
                    <Table.Column minWidth={150}>วันที่แก้ไขข้อมูล</Table.Column>
                    <Table.Column minWidth={100} className="fix-col" />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {meetingList?.map((data, index) =>
                    <Table.Row key={data.id}>
                      <Table.Cell center>{data.isSync ? <FaSyncAlt className="me-2" /> : null}</Table.Cell>
                      <Table.Cell center>{index + 1}</Table.Cell>
                      <Table.Cell center>{data.meetingType}</Table.Cell>
                      <Table.Cell>{data.meetingRoomName}</Table.Cell>
                      <Table.Cell center>{formatDateTh(data.meetingDate)}</Table.Cell>
                      <Table.Cell center>{getMeetingTime(data.meetingTimeFirst, data.meetingTimeSecond)}</Table.Cell>
                      <Table.Cell center>{data.bureauName}</Table.Cell>
                      <Table.Cell>
                        {data.committeeShortName ? `${data.committeeShortName}` : data.committeeName}
                      </Table.Cell>
                      <Table.Cell>
                        {data.subCommitteeShortName ? `${data.subCommitteeShortName}` : data.name}
                      </Table.Cell>
                      <Table.Cell>{data.dutyOfficer.split("\n").map((str, i) => <p className="my-0" key={i}>{str}</p>)}</Table.Cell>
                      <Table.Cell center>{yearToTHFormat(data.year)}</Table.Cell>
                      <Table.Cell center><Status type={StatusType.MEETING_STATUS} value={data.status} committee /></Table.Cell>
                      <Table.Cell>{data.updateByUserFullName}</Table.Cell>
                      <Table.Cell center>{fullDateTime(data.updateDate)}</Table.Cell>
                      <Table.Cell center className="fs-5 fix-col">
                        <>
                          <FaEdit
                            className="cursor-pointer text-primary me-2"
                            onClick={() => navigate(`detail/${data.id}`, { state: { criteriaData: criteriaTemp, page: page, size: size } })} />
                          {canDelete(data.status) ?
                            <FaTrashAlt
                              className="cursor-pointer text-primary"
                              onClick={() => onRemoveAsync(data.id)} /> : null}
                        </>
                      </Table.Cell>
                    </Table.Row>)}
                </Table.Body>
              </Table>
            </div>
          </div>
        </Col>
      </Row>
      <SyncDataModal
        show={showSyncDataModal}
        onHide={() => setShowSyncDataModal(false)}
        onFinish={() => onClear()} />
      <ExportMeetingModal
        show={showExportMeetingModal.show}
        exportType={showExportMeetingModal.type}
        onHide={() => setShowExportMeetingModal({ show: false, type: "" })} />
    </Layout>
  );
}

function SyncDataModal(props: {
  show: boolean;
  onHide: () => void;
  onFinish: () => void;
}) {
  const [meetingDate, setMeetingDate] = useState<Date>();

  useEffect(() => {
    if (props.show) {
      setMeetingDate(undefined);
    }
  }, [props.show]);

  const onSubmitAsync = async () => {
    submitForm(true);

    if (!meetingDate) {
      return;
    }

    const { status } = await c0202.syncDataAsync(meetingDate);

    if (status === HttpStatusCode.CREATED) {
      toast.success('ซิงค์ข้อมูลสำเร็จ');

      props.onFinish();
      props.onHide();
    }
  };

  return (
    <Modal
      show={props.show}
      icon={<FaSyncAlt />}
      title="ซิงค์ข้อมูล">
      <InputDate
        name="meeting-date"
        label="วันที่ประชุม"
        value={meetingDate}
        onChange={setMeetingDate}
        rule={{ required: true }} />
      <div className="d-flex justify-content-end gap-2 mt-3">
        <Button variant="outline-primary" onClick={props.onHide}>
          ยกเลิก
        </Button>
        <Button variant="primary" onClick={onSubmitAsync}>
          ตกลง
        </Button>
      </div>
    </Modal>
  );
}

const getDataOnBackFromDetailAsync = async (
  page: number,
  size: number,
  criteria: C0202Criteria,
) => {
  return (await c0202.getListAsync(page, size, criteria)).data;
}

function ExportMeetingModal(props: {
  show: boolean;
  exportType: string;
  onHide: () => void;
}) {
  const [exportDate, setExportDate] = useState<Date>();

  const onExport = () => {
    submitForm();

    if (exportDate) {
      switch (props.exportType) {
        case "Excel":
          exportMeetingExcelAsync(exportDate);
          break;
        case "Word":
          exportMeetingWordAsync(exportDate);
          break;
        case "PDF":
          exportMeetingPDFAsync(exportDate);
          break;
      }

      onHide();
    }
  };

  const exportMeetingExcelAsync = async (date: Date) => {
    const dateOnlyFormat = `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;

    const { data, status } = await c0202.exportMeetingExcelAsync(dateOnlyFormat);

    if (status === HttpStatusCode.OK) {
      exportFileExcel(data);
      toast.success("Export การประชุมสำเร็จ");
    }
  };

  const exportMeetingWordAsync = async (date: Date) => {
    const dateOnlyFormat = `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;

    const { data, status } = await c0202.exportMeetingWordAsync(dateOnlyFormat);

    if (status === HttpStatusCode.OK) {
      exportFileWord(data);
      toast.success("Export การประชุมสำเร็จ");
    }
  };

  const exportMeetingPDFAsync = async (date: Date) => {
    const dateOnlyFormat = `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;

    const { data, status } = await c0202.exportMeetingPDFAsync(dateOnlyFormat);

    if (status === HttpStatusCode.OK) {
      exportPdfByte(data);
      toast.success("Export การประชุมสำเร็จ");
    }
  };

  const onHide = () => {
    setExportDate(undefined);

    props.onHide();
  };

  return (
    <Modal
      show={props.show}
      icon={<FaRegFileExcel />}
      title="ออกรายงานการประชุม">
      <InputDate
        name="meeting-date"
        label="วันที่ประชุม"
        rule={{ required: true }}
        onChange={(value) => setExportDate(value)} />
      <div className="d-flex justify-content-end gap-2 mt-3">
        <Button
          variant="outline-primary"
          onClick={onHide}>
          ยกเลิก
        </Button>
        <Button
          variant="primary"
          onClick={onExport}>
          ตกลง
        </Button>
      </div>
    </Modal>
  );
}