import { SmActivityLogCriteria } from 'models';
import http from 'utils/axios';

const getSmActivityLogListAsync = async (page: number, size: number, criteria?: SmActivityLogCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get('api/smactivitylog', { params });
};

const exportCsvAsync = async (page: number, size: number, criteria?: SmActivityLogCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get('api/smactivitylog/report', { params, responseType: "blob" });
};

const smActivityLogService = {
  getSmActivityLogListAsync,
  exportCsvAsync,
};

export default smActivityLogService;