interface Props {
  children?: string | JSX.Element;
  minWidth?: number;
  maxWidth?: number;
  className?: string;
  colSpan?: number;
}

export function Column(props: Props) {
  return (
    <th style={{ minWidth: props.minWidth, maxWidth: props.maxWidth }} colSpan={props.colSpan} className={props.className}>
      {props.children}
    </th>
  );
}