import { SelectionType } from "constant";
import { LoaderFunctionArgs } from "react-router-dom";
import { c0100, selectionService } from "services";

export const c0100LoaderAsync = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 1;
  const size = url.searchParams.get('size') || 10;
  const keyword = url.searchParams.get('keyword') || '';

  const [ctMeetingList, smUser] = await Promise.all([
    c0100.getCtMeetingTypeListAsync(+page, +size, keyword),
    selectionService.getUserSelectionAsync(SelectionType.SM_USER),
  ]);

  return {
    ctMeetingList: {
      rows: ctMeetingList.data.rows,
      totalRows: ctMeetingList.data.totalRows,
      page: +page,
      size: +size,
    },
    smUser: smUser.data,
  };
};