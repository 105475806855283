import { CtReportMakerCreate, ReportMakerDto } from 'models';
import http from 'utils/axios';

const getListAsync = async () => {
  return await http.get('api/ctreportmaker');
};

const deleteAsync = async (id: string) =>
  await http.delete(`api/ctreportmaker/${id}`);

const createAsync = async (data: CtReportMakerCreate) =>
  await http.post(`api/ctreportmaker`, data);

const updateAsync = async (data: ReportMakerDto[]) =>
  await http.put(`api/ctreportmaker`, data);

const c0104 = {
  getListAsync,
  deleteAsync,
  createAsync,
  updateAsync,
};

export default c0104;