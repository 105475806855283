import { HttpStatusCode, RecordType } from 'constant';
import { MeetingRecordCriteria } from 'models';
import http from 'utils/axios';
import * as tus from 'tus-js-client'
import { showModalProgressBarLoadingAsync } from 'utils';

const getSnMeetingRecordListAsync = async (page: number, size: number, criteria?: MeetingRecordCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get('api/snmeetingrecord', { params });
};

const getSnMeetingRecordDetailListAsync = async (page: number, size: number, id: string | undefined) => {
  const params = {
    page,
    size,
    id,
  };

  return await http.get(`api/snmeetingrecord/${id}`, { params });
};

const uploadManualVideoAsync = async (meetingId: string, id: string, video: File) => {
  const fileId = await new Promise<string>(
    (resolve, reject) => {
      const upload = new tus.Upload(video, {
        endpoint: `${process.env.REACT_APP_API_URL}api/files/tus/`,
        // Retry delays will enable tus-js-client to automatically retry on errors
        retryDelays: [0, 3000, 5000, 10000, 20000],
        chunkSize: 30000000,
        parallelUploads: 1,
        // Attach additional meta data about the file for the server
        metadata: {
          filename: video.name,
          filetype: video.type,
        },
        // Callback for errors which cannot be fixed using retries
        onError: function (error) {
          reject(error);
        },
        // Callback for reporting upload progress
        onProgress: function (bytesUploaded, bytesTotal) {
          const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2)
          showModalProgressBarLoadingAsync(parseFloat(percentage), "กำลังอัพโหลดวิดิโอ...");
        },
        // Callback for once the upload is completed
        onSuccess: async function () {
          const paths = upload.url?.split('/');
          if (paths != null) {
            const lastPath = paths[paths?.length - 1];

            const formData = new FormData();
            formData.append("SnMeetingRankId", id);
            formData.append("TusFileId", lastPath);
            formData.append("FileName", video.name);

            const resp = await http.post(
              `api/snmeetingrecord/uploadmanualvideo/${meetingId}`,
              formData)

            if (resp.status === HttpStatusCode.ACCEPTED ||
              resp.status === HttpStatusCode.OK) {
              resolve(resp.data);
            } else {
              reject(resp.data);
            }
          }
        },
      })

      upload.start();
    })

  return fileId;
};

const startAndStopRecordAsync = async (id: string, type: RecordType) =>
  await http.put(`api/snmeetingrecord/${id}`, JSON.stringify(type),
    { headers: { 'Content-Type': 'application/json' } });

const startRecordAsync = async (id: string) =>
  await http.post(`api/snmeetingrecord/${id}/startrecord`);

const pauseRecordAsync = async (id: string) =>
  await http.post(`api/snmeetingrecord/${id}/pauserecord`);

const stopRecordAsync = async (id: string) =>
  await http.post(`api/snmeetingrecord/${id}/stoprecord`);

const uploadFullVideoManualAsync = async (id: string, video: File) => {
  const fileId = await new Promise<string>(
    (resolve, reject) => {
      const upload = new tus.Upload(video, {
        endpoint: `${process.env.REACT_APP_API_URL}api/files/tus/`,
        // Retry delays will enable tus-js-client to automatically retry on errors
        retryDelays: [0, 3000, 5000, 10000, 20000],
        chunkSize: 30000000,
        parallelUploads: 1,
        // Attach additional meta data about the file for the server
        metadata: {
          filename: video.name,
          filetype: video.type,
        },
        // Callback for errors which cannot be fixed using retries
        onError: function (error) {
          console.log('Failed because: ' + error);
          reject(error);
        },
        // Callback for reporting upload progress
        onProgress: function (bytesUploaded, bytesTotal) {
          const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2)
          showModalProgressBarLoadingAsync(parseFloat(percentage), "กำลังอัพโหลดวิดิโอ...");
        },
        // Callback for once the upload is completed
        onSuccess: async function () {
          const paths = upload.url?.split('/');
          if (paths != null) {
            const lastPath = paths[paths?.length - 1];
            const formData = new FormData();
            formData.append("TusFileId", lastPath);
            formData.append("FileName", video.name);

            const resp = await http.post(
              `api/snmeetingrecord/${id}/uploadmanualfullvideo`,
              formData)

            if (resp.status === HttpStatusCode.ACCEPTED ||
              resp.status === HttpStatusCode.OK) {
              resolve(resp.data);
            } else {
              reject(resp.data);
            }
          }
        },
      })

      upload.start();
    })

  return fileId;
};

const removeFileAsync = async (meetingId: string, id: string) => {
  return await http.delete(`api/snmeetingrecord/${meetingId}/rank/${id}`);
};

const getMeetingDetailAsync = async (id: string) =>
  await http.get(`api/snmeetingrecord/meeting/${id}`);

const getStreamingUrlAsync = async (id: string) =>
  await http.get(`api/smmeetingroom/${id}/streamingurl`);

const s0202 = {
  getSnMeetingRecordListAsync,
  getSnMeetingRecordDetailListAsync,
  uploadManualVideoAsync,
  startAndStopRecordAsync,
  startRecordAsync,
  pauseRecordAsync,
  stopRecordAsync,
  uploadFullVideoManualAsync,
  removeFileAsync,
  getMeetingDetailAsync,
  getStreamingUrlAsync,
};

export default s0202;
