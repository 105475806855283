import { LoaderFunctionArgs } from "react-router-dom";
import { m0102 } from "services";
import m0102Service from "services/m0102Service";

export const m0102DetailLoader = async ({ params, request }: LoaderFunctionArgs) => {
  const { id } = params;
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 1;
  const size = url.searchParams.get('size') || 100;

  if (id) {
    const [smRoleDetailData, programList] = await Promise.all([
      m0102Service.getSmRoleDetailAsync(id),
      m0102.getProgramListAsync(id, +page, +size),
    ]);

    return {
      smRoleDetailData: smRoleDetailData.data,
      data: {
        rows: programList.data.rows,
        totalRows: programList.data.totalRows,
        page: +page,
        size: +size,
      },
    }
  }

  return {
    smRoleDetailData: null,
    programData: null,
  }
}