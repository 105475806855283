import { StatusChapter, StatusInqueryS1, StatusMeeting } from "constant"
import { Badge } from "react-bootstrap";

export enum StatusType {
  MEETING_STATUS,
  CHAPTER_STATUS,
  STEP_STATUS,
  D03_DETAIL_STATUS,
}

interface Props {
  type: StatusType;
  value: StatusMeeting | StatusChapter | StatusInqueryS1 | string;
  textOnly?: boolean;
  committee?: boolean;
}

export function Status(props: Props) {
  const statusCheck = (className: string, text: string) => {
    if (props.textOnly) {
      return <p>{text}</p>;
    }
    return <Badge className={`badge w-100 rounded-pill ${className}`}>{text}</Badge>;
  };

  const status = (value: StatusMeeting | string) => {
    switch (props.type) {
      case StatusType.MEETING_STATUS:
        return getStatusMeeting(value);
      case StatusType.CHAPTER_STATUS:
        return getStatusChapter(value);
      case StatusType.STEP_STATUS:
        return getStatusStep(value);
      case StatusType.D03_DETAIL_STATUS:
        return getStatusD03(value);
    }
  }

  function getStatusMeeting(value: StatusMeeting | string) {
    switch (value) {
      case StatusMeeting.DRAFT:
        return statusCheck('draft', 'ร่างการประชุม');
      case StatusMeeting.CONFIRM:
        return statusCheck('confirm', 'ยืนยันการประชุม');
      case StatusMeeting.CANCEL:
        return statusCheck('cancel', 'ยกเลิกการประชุม');
      case StatusMeeting.RECORD:
        return statusCheck('record', 'กำลังบันทึกภาพและเสียง');
      case StatusMeeting.PAUSE_RECORD:
        return statusCheck('pauseRecord', 'หยุดบันทึกภาพและเสียงชั่วคราว');
      case StatusMeeting.RECORDED:
        return statusCheck('recorded', 'บันทึกภาพและเสียงสำเร็จ');
      case StatusMeeting.DOCUMENT_MAKING:
        return statusCheck('documentMaking', 'กำลังจัดทำรายงาน');
      case StatusMeeting.DOCUMENT_COMPLETE:
        return statusCheck('documentComplete', 'จัดทำรายงานสำเร็จ');
      case StatusMeeting.COLLECT:
        return statusCheck('collect', 'รวบรวมรายงาน');
      case StatusMeeting.OVERVIEW_REVIEWING:
        return statusCheck('overviewReviewing', 'กำลังตรวจสอบภาพรวม');
      case StatusMeeting.OVERVIEW_REVIEWED:
        return statusCheck('overviewReviewed', props.committee ? 'รอรับเรื่อง' : 'ตรวจสอบภาพรวมสำเร็จ');
      case StatusMeeting.GUARANTEE:
        return statusCheck('guarantee', props.committee ? 'รับเรื่องแล้ว' : 'รับรองรายงาน');
      case StatusMeeting.REJECT:
        return statusCheck('reject', 'ส่งกลับแก้ไขภาพรวม');
    }
  }

  function getStatusChapter(value: StatusMeeting | string) {
    switch (value) {
      case StatusChapter.ASSIGN_DUTY_OFFICER:
        return statusCheck('assignDutyOfficer', 'กำหนดผู้รับชอบ');
      case StatusChapter.WAIT_RECORD:
        return statusCheck('waitRecord', 'รอบันทึกภาพและเสียง');
      case StatusChapter.RECORD:
        return statusCheck('record', 'กำลังบันทึกภาพและเสียง');
      case StatusChapter.PAUSE_RECORD:
        return statusCheck('pauseRecord', 'หยุดบันทึกชั่วคราว');
      case StatusChapter.RECORDED:
        return statusCheck('recorded', 'บันทึกภาพและเสียงสำเร็จ');
      case StatusChapter.DOCUMENT_MAKING:
        return statusCheck('makeDocument', 'กำลังจัดทำรายงาน');
      case StatusChapter.DOCUMENT_COMPLETE:
        return statusCheck('makeDoneDocument', 'จัดทำรายงานสำเร็จ');
      case StatusChapter.REVIEWED_DOCUMENT:
        return statusCheck('reviewedDocument', 'ตรวจทานรายงานแล้ว');
      case StatusChapter.SEND_EDIT_DOCUMENT:
        return statusCheck('sendEditDocument', 'ส่งรายงานกลับแก้ไข');
      case StatusChapter.COLLECT:
        return statusCheck('collect', 'รวบรวมรายงาน');
      case StatusChapter.GUARANTEE:
        return statusCheck('guarantee', 'รับรองรายงาน');
      case StatusChapter.CONFIRM:
        return statusCheck('comfirm-assign', 'ยืนยันผู้รับผิดชอบ');
      case StatusChapter.REJECT:
        return statusCheck('reject', 'บันทึกภาพและเสียงไม่สำเร็จ');
    }
  }

  function getStatusStep(value: StatusMeeting | string) {
    switch (value) {
      case StatusInqueryS1.MEETING_SCHEDULE:
        return statusCheck('assignDutyOfficer', 'กำหนดการประชุม');
      case StatusInqueryS1.RECORDING:
        return statusCheck('waitRecord', 'บันทึกภาพสัญญาณ');
      case StatusInqueryS1.MEMO:
        return statusCheck('recorded', 'บันทึกเหตุการณ์');
      case StatusInqueryS1.MOM:
        return statusCheck('makeDocument', 'จัดทำรายงานการประชุม');
      case StatusInqueryS1.REVIEW_MOM:
        return statusCheck('makeDoneDocument', props.committee ? 'อนุมัติรายงานการประชุม' : 'ตรวจทานรายงานการประชุม');
      case StatusInqueryS1.COLLECT_MOM:
        return statusCheck('reviewedDocument', props.committee ? 'ตรวจสอบและรวบรวมรายงานการประชุม' : 'รวบรวมรายงานการประชุม');
      case StatusInqueryS1.CERTIFY_MOM:
        return statusCheck('sendEditDocument', props.committee ? 'ตรวจสอบและรับรายงานการประชุม' : 'ตรวจสอบและรับรองรายงาน');
    }
  }

  function getStatusD03(value: StatusMeeting | string) {
    switch (value) {
      case StatusChapter.ASSIGN_DUTY_OFFICER:
        return statusCheck('assignDutyOfficer', 'ยังไม่ได้ดำเนินการ');
      case StatusChapter.RECORD:
          return statusCheck('recorded', 'บันทึกข้อมูลภาพและเสียง');
      case StatusChapter.DOCUMENT_MAKING:
        return statusCheck('makeDocument', 'กำลังจัดทำรายงาน');
      case StatusChapter.DOCUMENT_COMPLETE:
        return statusCheck('makeDoneDocument', 'ส่งรายงานแล้ว');
      case StatusChapter.REVIEWED_DOCUMENT:
        return statusCheck('reviewedDocument', 'ตรวจรายงานแล้ว');
      case StatusChapter.SEND_EDIT_DOCUMENT:
        return statusCheck('sendEditDocument', 'ส่งกลับแก้ไข');
    }
  }

  return (
    <div className="status">
      {status(props.value)}
    </div>
  );
};
