import { Card, Layout, Input, InputDate, Table } from "components";
import { HttpStatusCode } from "constant";
import { SmActivityLog, SmActivityLogCriteria } from "models";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaFileExcel, FaSearch, FaTrashAlt } from "react-icons/fa";
import { useLoaderData } from "react-router";
import { m0105 } from "services";
import { exportFileCsv, fullDateTime } from "utils";
import toast from "utils/toast";

type Loader = { smActivityLogData: SmActivityLog[], smActivityLogTotalRow: number }

export default function M0105() {
  const { smActivityLogData, smActivityLogTotalRow } = useLoaderData() as Loader;
  const [smActivityLog, setSmActivityLog] = useState<SmActivityLog[]>([]);
  const [criteria, setCriteria] = useState<SmActivityLogCriteria>({} as SmActivityLogCriteria);
  const [criteriaTemp, setCriteriaTemp] = useState<SmActivityLogCriteria>({} as SmActivityLogCriteria);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    if (smActivityLogData && smActivityLogTotalRow) {
      setSmActivityLog(smActivityLogData);
      setTotalRows(smActivityLogTotalRow);
    }
  }, [smActivityLogData, smActivityLogTotalRow]);

  const getSmActivityLogAsync = useCallback(async (criteriaData?: SmActivityLogCriteria) => {
    const { data, status } = await m0105.getSmActivityLogListAsync(page, size, criteriaData ? criteriaData : criteria);

    if (status === HttpStatusCode.OK) {
      setSmActivityLog(data.rows);
      setTotalRows(data.totalRows);
    }
  }, [page, size]);

  useEffect(() => {
    getSmActivityLogAsync();
  }, [page, size]);

  const onChangePageSize = (page: number, size: number) => {
    setPage(page);
    setSize(size);
  };

  const onSearch = () => {
    getSmActivityLogAsync(criteria);
    setCriteriaTemp(criteria);
  };

  const onClearCriteria = () => {
    const criteriaData = {} as SmActivityLogCriteria;

    setCriteria(criteriaData);
    setPage(1);
    setSize(10);
    getSmActivityLogAsync(criteriaData);
    setCriteriaTemp(criteriaData);
  };

  const exportCSVAsync = async () => {
    if (smActivityLog.length === 0) {
      return toast.warn("ไม่พบข้อมูลที่ต้องการ export");
    }
    const { data, status } = await m0105.exportCsvAsync(page, size, criteriaTemp);

    if (status === HttpStatusCode.OK) {
      exportFileCsv(data);
      onSearch();
    }
  };

  const onSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSearch();
  };

  const onChange = (props: keyof SmActivityLogCriteria, value?: string | number | Date) => {
    setCriteria({ ...criteria, [props]: value });
  }

  return (
    <Layout title="บันทึกข้อมูลการใช้งาน">
      <Card>
        <Form onSubmit={onSubmitSearch}>
          <Row>
            <Col md={6} lg={3}>
              <Input
                label="ค้นหา"
                value={criteria.keyword}
                onChange={(value) => onChange('keyword', value)}
                placeholder="ผู้ใช้งาน, ประเภท, IP Address, ระบบงาน" />
            </Col>
            <Col md={6} lg={3}>
              <InputDate
                label="จากวันที่"
                value={criteria.startDate}
                onChange={(value) => onChange('startDate', value)} />
            </Col>
            <Col md={6} lg={3}>
              <InputDate
                label="ถึงวันที่"
                value={criteria.endDate}
                onChange={(value) => onChange('endDate', value)} />
            </Col>
            <Col md={6} lg={3}>
              <div className="criteria-flex mb-5">
                <div className="button">
                  <Button
                    variant="primary"
                    type="submit">
                    <FaSearch className="me-2" />ค้นหา
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={onClearCriteria}>
                    <FaTrashAlt className="me-2" />ล้างค่า
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </Card>
      <div className="d-flex justify-content-end mt-3">
        <Button variant="outline-primary" onClick={exportCSVAsync}>
          <FaFileExcel className="me-2" />Export CSV
        </Button>
      </div>
      <div className="mt-3">
        <Table
          total={totalRows}
          page={page}
          size={size}
          onChange={(size, page) => onChangePageSize(page, size)}>
          <Table.Header>
            <Table.Row>
              <Table.Column minWidth={200}>วันที่</Table.Column>
              <Table.Column minWidth={100}>ผู้ใช้งาน</Table.Column>
              <Table.Column minWidth={100}>ประเภท</Table.Column>
              <Table.Column minWidth={150}>IP Address</Table.Column>
              <Table.Column minWidth={300}>ระบบงาน</Table.Column>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {smActivityLog?.map((data) => (
              <Table.Row key={data.id}>
                <Table.Cell center>{fullDateTime(data.logDate)}</Table.Cell>
                <Table.Cell>{data.userName}</Table.Cell>
                <Table.Cell center>{data.logType}</Table.Cell>
                <Table.Cell center>{data.ipAddress}</Table.Cell>
                <Table.Cell>{data.logDescription}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </Layout>
  );
}