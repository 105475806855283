import { Layout, Card, Modal, UploadFile, FileValue, ToolTipComment } from "components";
import Collabora, { CollaboraRef } from "components/Document/Collabora";
import { VideoOption } from "components/Video/Video";
import { HttpStatusCode, StatusMeeting, VideoControl, statusMeetingItems } from "constant";
import { ItemModel, SnMeetingDocument, SnReviewOverviewDetail } from "models";
import { SnDocument } from "models/s0206";
import { useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Dropdown, DropdownButton, Form, Row } from "react-bootstrap";
import { FaAngleLeft, FaBook, FaDownload, FaFileAlt, FaRegFilePdf, FaRegFileWord, FaTimes, FaUndo } from "react-icons/fa";
import { HiOutlineDocumentCheck } from "react-icons/hi2";
import { TbFileCheck, TbFileDownload } from "react-icons/tb";
import { useLoaderData, useNavigate, useParams, useSubmit } from "react-router-dom";
import { s0206 } from "services";
import { exportFilePDFAsync, exportFileWordAsync, formatDateTh, getFile, getTime, showModalConfirmAsync } from "utils";
import toast from "utils/toast";

type Loader = { snReviewOverviewDetail: SnReviewOverviewDetail, snMeetingTypeItems: ItemModel[], snMeetingPeriodItems: ItemModel[] };

export default function S0206Detail() {
  const { snReviewOverviewDetail, snMeetingTypeItems, snMeetingPeriodItems } = useLoaderData() as Loader;
  const [snReviewOverview, setSnReviewOverview] = useState<SnReviewOverviewDetail>({} as SnReviewOverviewDetail);
  const [canWrite, setCanWrite] = useState<boolean>();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const submit = useSubmit();
  const { id } = useParams();
  const [docVersion, setDocVersion] = useState<SnDocument>({} as SnDocument);
  const [programName, setProgramName] = useState<string>("ตรวจสอบภาพรวมและจัดทำรูปเล่ม");

  useEffect(() => {
    setSnReviewOverview(snReviewOverviewDetail);
    setDocVersion(snReviewOverviewDetail.snDocuments ? snReviewOverviewDetail.snDocuments[0] : {} as SnDocument);

    setCanWrite(snReviewOverviewDetail.status === StatusMeeting.COLLECT ||
      snReviewOverviewDetail.status === StatusMeeting.OVERVIEW_REVIEWING ||
      snReviewOverviewDetail.status === StatusMeeting.REJECT ||
      snReviewOverview.status === StatusMeeting.OVERVIEW_REVIEWED);
  }, [snReviewOverviewDetail]);

  const getSnMeetingTypeName = (value: string) => {
    const findData = snMeetingTypeItems.find(s => s.value === value);

    return findData?.label;
  };

  const getSnMeetingPeriodName = (value: string) => {
    const findData = snMeetingPeriodItems.find(s => s.value === value);

    return findData?.label;
  };

  const convertStatusToText = (status: string) => {
    const findStatus = statusMeetingItems.find(s => s.value === status)?.label;

    return findStatus;
  };

  const updateStatusAsync = async (statusMeeting: StatusMeeting) => {
    if (id) {
      const { status } = await s0206.updateSnReviewOverviewAsync(id, statusMeeting, snReviewOverview.documents);

      if (status === HttpStatusCode.ACCEPTED && statusMeeting === StatusMeeting.OVERVIEW_REVIEWING) {
        toast.success("บันทึกข้อมูลสำเร็จ");

        submit({});
      }

      if (status === HttpStatusCode.ACCEPTED && statusMeeting === StatusMeeting.OVERVIEW_REVIEWED) {
        toast.success("ส่งรายงานสำเร็จ");

        submit({});
      }
    }
  };

  const sendReportAsync = async () => {
    if (!await showModalConfirmAsync('ต้องการยืนยันการส่งรายงานหรือไม่', 'ส่งรายงาน', <HiOutlineDocumentCheck className="my-1 text-success" style={{ fontSize: 80 }} />)) {
      return;
    }

    updateStatusAsync(StatusMeeting.OVERVIEW_REVIEWED);
  };

  const collaboraRef = useRef<CollaboraRef>(null);

  const videoRef = useRef<VideoOption>({} as VideoOption);

  function loadCollabora() {
    const iframe = document.getElementById(docVersion.id) as HTMLIFrameElement;

    iframe.contentWindow?.addEventListener('keydown', (e) => clickBtn(e.key));
  }

  function clickBtn(key: string) {
    switch (key) {
      case VideoControl.F10:
        videoRef.current.backRef();
        break;
      case VideoControl.F9:
        videoRef.current.playRef();
        break;
      case VideoControl.F11:
        videoRef.current.aheadRef();
        break;
    }
  }

  const uploadFileAsync = async (file: File, sequence: number) => {
    const res = await s0206.uploadFileAsync(id!, file, sequence);

    if (res.status === HttpStatusCode.ACCEPTED) {
      toast.success('อัพโหลดไฟล์สำเร็จ');

      setSnReviewOverview({
        ...snReviewOverview,
        documents: [
          ...snReviewOverview.documents,
          {
            id: res.data,
            fileName: file.name,
            sequence,
            snMeetingId: snReviewOverview.id,
          },
        ],
      });
    }
  };

  const removeFileAsync = async (index: number) => {
    const fileDeleted = snReviewOverview.documents.splice(index, 1);

    const res = await s0206.removeFileAsync(fileDeleted[0].snMeetingId, fileDeleted[0].id);

    if (res.status === HttpStatusCode.ACCEPTED) {
      toast.success('ลบไฟล์สำเร็จ');

      setSnReviewOverview({
        ...snReviewOverview,
        documents: [...snReviewOverview.documents],
      });
    }
  };

  const documents = useMemo(() => {
    return snReviewOverview.documents?.map(d => ({ id: d.id, fileName: d.fileName, sequence: d.sequence })) as FileValue[];
  }, [snReviewOverview.documents]);

  const onMoveChange = (value: FileValue[]) => {
    setSnReviewOverview({
      ...snReviewOverview,
      documents: [
        ...value.map((v, i) => ({ ...v, sequence: i + 1 } as SnMeetingDocument)),
      ],
    });
  };

  const dowloadFileAsync = async (id: string, fileName?: string) => {
    const { data, status } = await s0206.downloadFileAsync(id);

    if (status === HttpStatusCode.OK) {
      getFile(data, fileName);
    }
  };


  const onSelectVerstion = (id: string | null) => {
    if (id) {
      const documentSelected = snReviewOverviewDetail.snDocuments.find(f => f.id == id);

      setDocVersion(documentSelected ? documentSelected : {} as SnDocument);

      if (documentSelected !== snReviewOverviewDetail.snDocuments[0]) {
        setCanWrite(false);

        return;
      }

      if (snReviewOverviewDetail.status === StatusMeeting.COLLECT ||
        snReviewOverviewDetail.status === StatusMeeting.OVERVIEW_REVIEWING ||
        snReviewOverviewDetail.status === StatusMeeting.REJECT) {
        setCanWrite(true);
      }
    }
  };

  const prepareDocumentAsync = async () => {
    if (id) {
      const { status } = await s0206.prepareDocumentAsync(id);

      if (status === HttpStatusCode.OK) {
        toast.success('จัดทำเอกสารสำเร็จ');

        submit({});
      }
    }
  };

  const setDefaultDocumentOverview = () => {
    setDocVersion(snReviewOverview.snDocuments ? snReviewOverview.snDocuments[0] : {} as SnDocument);
  };

  return (
    <Layout title={programName}>
      <div className="d-flex justify-content-between my-3">
        <Button
          variant="outline-primary"
          type="button"
          onClick={() => navigate('/s0206')}>
          <FaAngleLeft className="me-2" />ย้อนกลับ
        </Button>
        <div className="d-flex gap-2">
          <Button
            variant="outline-primary"
            onClick={setDefaultDocumentOverview}>
            <FaFileAlt className="me-2" /> ดูรายงานที่รวบรวม
          </Button>
          <DropdownButton
            title={<span><FaUndo className="me-2" />เวอร์ชั่นก่อนหน้า ({docVersion.versionDocument})</span>}
            drop="down-centered"
            variant="outline-primary"
            onSelect={(value) => onSelectVerstion(value)}>
            {snReviewOverviewDetail.snDocuments.map(doc =>
              <Dropdown.Item
                key={doc.id}
                eventKey={doc.id}>
                {doc.versionDocument}
              </Dropdown.Item>)}
          </DropdownButton>
          <Button
            variant="outline-primary"
            type="button"
            onClick={() => exportFilePDFAsync(docVersion.id, programName[0])}>
            <FaRegFilePdf className="me-2" />Export PDF
          </Button>
          <Button
            variant="outline-primary"
            type="button"
            onClick={() => exportFileWordAsync(docVersion.id, programName)}>
            <FaRegFileWord className="me-2" />Export Word
          </Button>
          {(snReviewOverview.status === StatusMeeting.OVERVIEW_REVIEWING || snReviewOverview.status === StatusMeeting.COLLECT || snReviewOverview.status === StatusMeeting.REJECT) && <>
            <Button
              variant="outline-primary"
              type="button"
              onClick={() => updateStatusAsync(StatusMeeting.OVERVIEW_REVIEWING)}>
              <TbFileDownload className="me-2" />บันทึกเวอร์ชั่น
            </Button>
            <Button
              variant="primary"
              type="button"
              onClick={sendReportAsync}>
              <TbFileCheck className="me-2" />ส่งรายงาน
            </Button>
          </>}
        </div>
      </div>
      <Row>
        <Col xl={4}>
          {snReviewOverview.status === StatusMeeting.REJECT && <ToolTipComment subject={snReviewOverview.certifyingReportRejectComment ?? "ไม่มีความคิดเห็น"} />}
          <Card className="mt-2 border-primary">
            <Form.Label className="fw-bold">
              ข้อมูลการประชุม
            </Form.Label>
            <Row>
              <Col lg={6}>
                ประเภทวาระประชุม : {getSnMeetingTypeName(snReviewOverview.snMeetingTypeId)}
              </Col>
              <Col lg={6}>
                ครั้งที่ : {snReviewOverview.time}
              </Col>
              <Col lg={12}>
                สมัยที่ประชุม : {getSnMeetingPeriodName(snReviewOverview.snMeetingPeriodId)}
              </Col>
              <Col lg={6}>
                วันที่ประชุม : {formatDateTh(snReviewOverview.startDate)}
              </Col>
              <Col lg={6}>
                เวลา : {`${getTime(snReviewOverview.startTimeFirst)} - ${getTime(snReviewOverview.endTimeFirst)}`}
              </Col>
              <Col lg={12}>
                สถานะ : {convertStatusToText(snReviewOverview.status)}
              </Col>
            </Row>
          </Card>
          <UploadFile
            disabled={snReviewOverview.status === StatusMeeting.OVERVIEW_REVIEWED || snReviewOverview.status === StatusMeeting.GUARANTEE}
            rightButton={
              (snReviewOverview.status === StatusMeeting.OVERVIEW_REVIEWED || snReviewOverview.status === StatusMeeting.GUARANTEE) ? <></>
                : <Button variant="primary" className="d-flex align-items-center gap-2" onClick={prepareDocumentAsync}>
                  <FaBook />จัดทำเอกสาร
                </Button>}
            value={documents}
            onChange={uploadFileAsync}
            onRemove={removeFileAsync}
            onMoveChange={onMoveChange}
            dowloadFile={(id, fileName) => dowloadFileAsync(id, fileName)}
            canEdit
            onEditDocument={(id) => setDocVersion({ ...docVersion, id: id })} />
        </Col>
        <Col xl={8}>
          <Collabora
            docId={docVersion.id}
            docName="s0204"
            height="1000px"
            ref={collaboraRef}
            onCollaboraLoaded={() => loadCollabora()}
            canWrite={canWrite} />
        </Col>
      </Row>
      <ModalUploadDocument show={showModal} onHide={() => setShowModal(false)} />
    </Layout>
  );
}

interface ModalProps {
  show: boolean;
  onHide: () => void;
}

function ModalUploadDocument(props: ModalProps) {
  return (
    <Modal show={props.show}>
      <div className="d-flex justify-content-between">
        <div>
          <FaDownload className="me-2" />นำเข้าข้อมูลภายนอก
        </div>
        <div>
          <FaTimes onClick={props.onHide} />
        </div>
      </div>
    </Modal>
  )
}