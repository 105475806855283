import { CtInqueryMeetingRecordCriteria } from 'models';
import http from 'utils/axios';

const getCtInquiryMeetingRecordListAsync = async (page: number, size: number, criteria?: CtInqueryMeetingRecordCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get('api/ctinquirymeetingrecord', { params });
};

const getCtInquiryMeetingRecordDetailHeaderAsync = async (id: string) => {
  return await http.get(`api/ctinquirymeetingrecord/ctmeeting/${id}`);
};

const getCtInquiryMeetingRecordDetailListAsync = async (id: string, page: number, size: number) => {
  const params = {
    page,
    size,
  };

  return await http.get(`api/ctinquirymeetingrecord/ctmeeting/${id}/ctmeetingrecord`, { params });
};

const ctInquiryMeetingRecordService = {
  getCtInquiryMeetingRecordListAsync,
  getCtInquiryMeetingRecordDetailHeaderAsync,
  getCtInquiryMeetingRecordDetailListAsync,
};

export default ctInquiryMeetingRecordService;