import axios from 'utils/axios';

const documentSessionServices = {
  activateSession: async (): Promise<any> => {
    try {
      const response = await axios.get('/wopi/sessions');
      return response.data.sessionToken;
    } catch (error) {
      console.error('Error activating session:', error);
      throw error;
    }
  },

  deactivateSession: async (sessionToken: string): Promise<any> => {
    try {
      const response =
        await axios.delete(
          '/wopi/sessions', 
        {
          data: { sessionToken },
        });
      return response.data;
    } catch (error) {
      console.error('Error deactivating session:', error);
      throw error;
    }
  },

  renewSession: async (sessionToken: string): Promise<any> => {
    try {
      const response =
        await axios.post('/wopi/sessions/renew', { sessionToken });
      return response.data.sessionToken;
    } catch (error) {
      console.error('Error renewing session:', error);
      throw error;
    }
  },
};

export default documentSessionServices;