import { Layout, Table, Modal, Card, TextArea } from "components";
import { useEffect, useRef, useState } from 'react';
import { Button, Col, Dropdown, DropdownButton, Form, Row } from "react-bootstrap";
import { FaAngleLeft, FaUndo, FaRegFilePdf, FaRegFileWord, FaRegCheckCircle, FaRegClock, FaRegTimesCircle, FaCheck, FaTimes } from "react-icons/fa";
import { useLoaderData, useNavigate, useParams, useSubmit } from "react-router-dom";
import { exportFilePDFAsync, exportFileWordAsync, submitForm } from "utils";
import { HttpStatusCode, ModalType, StatusChapter, VideoControl } from "constant";
import { ChapterDocuments, CtReviewChapterList, Pagination } from "models";
import { c0204, c0205, ctService } from "services";
import Collabora, { CollaboraRef } from 'components/Document/Collabora';
import Video, { VideoOption } from 'components/Video/Video';
import toast from "utils/toast";

type Loader = { data: Pagination<CtReviewChapterList> };

interface ModalProps {
  showModal: boolean;
  onHide: () => void;
  data?: CtReviewChapterList;
}

export default function C0205Document() {
  const { data } = useLoaderData() as Loader;
  const [showEditModal, setShowEditModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [rowSelected, setRowSelected] = useState<CtReviewChapterList>();
  const [video, setVideo] = useState<File>({} as File);
  const [docVersion, setDocVersion] = useState<ChapterDocuments>({} as ChapterDocuments);
  const [documentList, setDocumentList] = useState<ChapterDocuments[]>([]);
  const [dataSelected, setDataSelected] = useState<CtReviewChapterList>({} as CtReviewChapterList);
  const [canWrite, setCanWrite] = useState<boolean>();
  const [lastVersion, setLastVersion] = useState("");
  const [fileIds, setFileIds] = useState<string[]>([]);
  const [fileIndex, setFileIndex] = useState<number>(0);
  const programName = useState<string>("อนุมัติรายงานการประชุม");
  const navigate = useNavigate();
  const submit = useSubmit();
  const meetingId = useParams().id;

  useEffect(() => {
    const setShowVideoAsync = async () => {
      await getDetailAsync(data.rows[0]);
    }

    if (data) {
      setShowVideoAsync();
    }
  }, [data]);

  useEffect(() => {
    if (fileIds.length) {
      getVideoDataAsync(fileIds[0]);
      setFileIndex(0);
    }
  }, [fileIds]);

  const onSearch = (size: number, page: number) => {
    submit({
      page: page.toString(),
      size: size.toString(),
    });
  };

  const getIconStatus = (status: StatusChapter) => {
    switch (status) {
      case StatusChapter.REVIEWED_DOCUMENT:
        return <FaRegCheckCircle className="text-success fs-5" />
      case StatusChapter.SEND_EDIT_DOCUMENT:
        return <FaRegTimesCircle className="text-danger fs-5" />
      case StatusChapter.COLLECT:
        return <FaRegCheckCircle className="text-success fs-5" />
      default:
        return <FaRegClock className="text-warning fs-5" />
    }
  };

  const onShowModal = (data: CtReviewChapterList, type: ModalType) => {
    setRowSelected(data);

    switch (type) {
      case ModalType.ADD:
        setShowApproveModal(true);
        break;
      case ModalType.EDIT:
        setShowEditModal(true);
        break;
    }
  };

  const getVideoDataAsync = async (vidId: string) => {
    const { data, status } = await ctService.getVideoAsync(vidId);

    if (status === HttpStatusCode.OK) {
      setVideo(data);
    } else {
      setVideo({} as File);
    }
  };

  const getDetailAsync = async (dataSelect: CtReviewChapterList) => {
    getFileIdAsync(dataSelect.id);

    if (dataSelect.status === StatusChapter.DOCUMENT_COMPLETE) {
      setCanWrite(true);
    } else {
      setCanWrite(false);
    }

    const documentResponse = await c0204.getCtMakeDocumentChapterDetailAsync(dataSelect.id);

    if (documentResponse.status === HttpStatusCode.OK) {
      const documentData: ChapterDocuments[] = documentResponse.data.ctChapterDocuments;

      setDocVersion(documentData.length > 0 ? documentData[0] : {} as ChapterDocuments);
      setDocumentList(documentData);
      setDataSelected(dataSelect);
      setLastVersion(documentData.length > 0 ? (documentData[0].versionDocument).toString() : "");
    }
  };

  const getFileIdAsync = async (id: string) => {
    const { data, status } = await ctService.getRecordFileIdAsync(id, meetingId!);

    if (status === HttpStatusCode.OK) {
      setFileIds(data);
    }
  };

  const onEndedAsync = async () => {
    const currentIndex = fileIndex + 1;

    if (fileIds.length > currentIndex) {
      getVideoDataAsync(fileIds[currentIndex]);

      setFileIndex(currentIndex);
    } else {
      setFileIndex(0);
    }
  };

  const collaboraRef = useRef<CollaboraRef>(null);

  const videoRef = useRef<VideoOption>({} as VideoOption);

  function loadCollabora() {
    const iframe = document.getElementById(docVersion.id) as HTMLIFrameElement;

    iframe.contentWindow?.addEventListener('keydown', (e) => clickBtn(e.key));
  }

  function clickBtn(key: string) {
    switch (key) {
      case VideoControl.F10:
        videoRef.current.backRef();
        break;
      case VideoControl.F9:
        videoRef.current.playRef();
        break;
      case VideoControl.F11:
        videoRef.current.aheadRef();
        break;
    }
  };

  const onSelectVersion = (id: string | null) => {
    if (id) {
      const documentSelected = documentList.find(f => f.id == id);

      setDocVersion(documentSelected ? documentSelected : {} as ChapterDocuments);
      setLastVersion(documentSelected ? (documentSelected.versionDocument).toString() : "");

      if (documentSelected !== documentList[0]) {
        setCanWrite(false);

        return;
      }

      if (dataSelected.status === StatusChapter.DOCUMENT_COMPLETE) {
        setCanWrite(true);

        return;
      }
    }
  };

  return (
    <Layout title={programName[0]} className="s0205-detail">
      <Form>
        <div className="d-flex justify-content-between my-3">
          <Button
            variant="outline-primary"
            type="button"
            onClick={() => navigate('/c0205')}>
            <FaAngleLeft className="me-2" />ย้อนกลับ
          </Button>
          <div className="d-flex gap-2">
            <DropdownButton
              title={<span><FaUndo className="me-2" />เวอร์ชั่นก่อนหน้า ({lastVersion})</span>}
              drop="down-centered"
              variant="outline-primary"
              onSelect={(value) => onSelectVersion(value)}>
              {documentList?.map((data) => (
                <Dropdown.Item
                  eventKey={data.id}
                  key={data.id}>
                  {data.versionDocument}
                </Dropdown.Item>
              ))}
            </DropdownButton>
            <Button
              variant="outline-primary"
              onClick={() => exportFilePDFAsync(docVersion.id, programName[0])}>
              <FaRegFilePdf className="me-2" />Export PDF
            </Button>
            <Button
              variant="outline-primary"
              onClick={() => exportFileWordAsync(docVersion.id, programName[0])}>
              <FaRegFileWord className="me-2" />Export Word
            </Button>
          </div>
        </div>
        <Row>
          <Col xl={4}>
            <Video
              onEnded={onEndedAsync}
              ref={videoRef}
              files={video.type ? [video] : []}
              showPlayButton={true}
              showPlayTimeConfig={false}
              showSpeakToText={false}>
              <div className="mt-2 border-primary">
                <div className="table-relative-fix">
                  <div className="table-scroll">
                    <Table total={data.totalRows} onChange={onSearch}>
                      <Table.Header>
                        <Table.Row>
                          <Table.Column minWidth={50}>ตอนที่</Table.Column>
                          <Table.Column minWidth={200}>ผู้จดรายงานการประชุม</Table.Column>
                          <Table.Column minWidth={75}>สถานะ</Table.Column>
                          <Table.Column minWidth={175} className="fix-col" />
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {data.rows?.map(d => (
                          <Table.Row key={d.id}>
                            <Table.Cell center>{d.chapter}</Table.Cell>
                            <Table.Cell>{d.dutyOfficerFullName}</Table.Cell>
                            <Table.Cell center>
                              {getIconStatus(d.status as StatusChapter)}
                            </Table.Cell>
                            <Table.Cell center className="fix-col">
                              <div className="d-flex justify-content-center gap-1">
                                {d.status === StatusChapter.DOCUMENT_COMPLETE ?
                                  <>
                                    <Button
                                      size="sm"
                                      onClick={() => onShowModal(d, ModalType.ADD)}>
                                      <FaCheck />
                                    </Button>
                                    <Button
                                      variant="outline-primary"
                                      size="sm"
                                      onClick={() => onShowModal(d, ModalType.EDIT)}>
                                      <FaTimes />
                                    </Button>
                                  </>
                                  : <></>}
                                <Button
                                  size="sm"
                                  onClick={() => getDetailAsync(d)}>
                                  รายละเอียด
                                </Button>
                              </div>
                            </Table.Cell>
                          </Table.Row>))}
                      </Table.Body>
                    </Table>
                  </div>
                </div>
                <ModalDisApproved showModal={showEditModal} onHide={() => setShowEditModal(false)} data={rowSelected} />
                <ModalApprove showModal={showApproveModal} onHide={() => setShowApproveModal(false)} data={rowSelected} />
              </div>
            </Video>
          </Col>
          <Col xl={8}>
            <Collabora
              docId={docVersion.id}
              docName="c0205"
              height="1000px"
              ref={collaboraRef}
              onCollaboraLoaded={() => loadCollabora()}
              canWrite={canWrite} />
          </Col>
        </Row>
      </Form>
    </Layout>
  );
}

function ModalDisApproved(props: ModalProps) {
  const [comment, setComment] = useState("");
  const [dataSelected, setDataSelected] = useState<CtReviewChapterList>({} as CtReviewChapterList);
  const submit = useSubmit();

  useEffect(() => {
    if (props.data) {
      setDataSelected(props.data);
      setComment("");
    }
  }, [props.data]);

  const sendEditAsync = async () => {
    submitForm();

    if (!comment) {
      return;
    }

    const { status } = await c0205.sendEditChapterAsync(dataSelected.id, comment);

    if (status === HttpStatusCode.ACCEPTED) {
      toast.success('ส่งแก้ไขสำเร็จ');

      props.onHide();
      submit({});
    }
  };

  return (
    <Modal show={props.showModal}>
      <Form.Label>ไม่อนุมัติ</Form.Label>
      <Card>
        <p>ตอนที่ : {dataSelected.chapter}</p>
        <p>ผู้จดรายงาน : {dataSelected.dutyOfficerFullName}</p>
      </Card>
      <TextArea
        rule={{ required: true }}
        className="mt-3"
        label="แสดงความคิดเห็น"
        value={comment}
        onChange={(value) => setComment(value)} />
      <div className="d-flex justify-content-center gap-3">
        <Button
          variant="outline-primary"
          className="w-25"
          type="button"
          onClick={props.onHide}>
          ยกเลิก
        </Button>
        <Button
          className="w-25"
          type="button"
          onClick={sendEditAsync}>
          ยืนยัน
        </Button>
      </div>
    </Modal >
  );
}

function ModalApprove(props: ModalProps) {
  const [dataSelected, setDataSelected] = useState<CtReviewChapterList>({} as CtReviewChapterList);
  const submit = useSubmit();

  useEffect(() => {
    if (props.data) {
      setDataSelected(props.data);
    }
  }, [props.data]);

  const sendReviewAsync = async () => {
    const { status } = await c0205.sendReviewChapterAsync(dataSelected.id);

    if (status === HttpStatusCode.ACCEPTED) {
      toast.success('อนุมัติรายงานสำเร็จ');

      props.onHide();
      submit({});
    }
  };

  return (
    <Modal show={props.showModal} title="อนุมัติ" icon={<FaRegCheckCircle className="text-success" />}>
      <Row className="mt-2">
        <Col className="mt-1">
          <h5>
            ต้องการที่จะอนุมัติใช่หรือไม่ ?
          </h5>
        </Col>
      </Row>
      <div className="button d-flex flex-row gap-3 justify-content-center mt-4">
        <Button
          variant="outline-primary"
          className="w-50"
          onClick={props.onHide}>
          ยกเลิก
        </Button>
        <Button
          variant="primary"
          className="w-50"
          onClick={sendReviewAsync}>
          อนุมัติ
        </Button>
      </div>
    </Modal>
  );
}