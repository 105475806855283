import { SnReviewOverViewCriteria, SnMeetingDocument } from 'models';
import http from 'utils/axios';

const getSnReviewOverviewListAsync = async (page: number, size: number, criteria?: SnReviewOverViewCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get('api/snreviewoverview', { params });
};

const getSnReviewOverviewByIdAsync = async (id: string) => {
  return await http.get(`api/snreviewoverview/${id}`);
};

const updateSnReviewOverviewAsync = async (id: string, status: string, documents: SnMeetingDocument[]) => {
  const body = {
    status: status,
    documents,
  };

  return await http.put(`api/snreviewoverview/${id}`, body);
};

const uploadFileAsync = async (meetingId: string, document: File, sequence: number) => {
  const formData = new FormData();
  formData.append('document', document);
  formData.append('sequence', sequence.toString());

  return http.post(`api/snreviewoverview/snmeeting/${meetingId}/document`, formData);
};

const removeFileAsync = async (meetingId: string, documentId: string) => {
  return http.delete(`api/snreviewoverview/snmeeting/${meetingId}/document/${documentId}`);
};

const downloadFileAsync = async (documentId: string) => {
  return http.get(`api/snreviewoverview/snmeeting/${documentId}/document`, {
    responseType: 'blob',
  });
}

const prepareDocumentAsync = async (docId: string) => {
  return http.get(`api/snreviewoverview/snmeeting/${docId}`);
};

const snReviewOverviewService = {
  getSnReviewOverviewListAsync,
  getSnReviewOverviewByIdAsync,
  updateSnReviewOverviewAsync,
  uploadFileAsync,
  removeFileAsync,
  downloadFileAsync,
  prepareDocumentAsync,
};

export default snReviewOverviewService;
