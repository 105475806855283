import { MeetingTypeModel } from 'models';
import http from 'utils/axios';

const getMeetingTypeListAsync = async (page: number, size: number, keyword?: string) => {
  const params = {
    page,
    size,
    keyword,
  };

  return await http.get(`api/snmeetingtype`, { params });
}

const createMeetingTypeAsync = async (data: MeetingTypeModel) => 
  await http.post(`api/snmeetingtype`, JSON.stringify(data.name), 
  { headers: { 'Content-Type': 'application/json' } });

const deleteMeetingTypeAsync = async (id: string) => 
  await http.delete(`api/snmeetingtype/${id}`);

const updateMeetingTypeAsync = async (data: MeetingTypeModel) => 
  await http.put(`api/snmeetingtype/${data.id}`, JSON.stringify(data.name), 
  { headers: { 'Content-Type': 'application/json' } });

const s0101 = {
  getMeetingTypeListAsync,
  createMeetingTypeAsync,
  deleteMeetingTypeAsync,
  updateMeetingTypeAsync,
};

export default s0101;