import { Card, Input, InputTime, Layout, Selector, Status, StatusType, Table } from "components";
import { HttpStatusCode, StatusChapter, statusChapterItems, statusMeetingItems } from "constant";
import { ItemModel, SnInquiryMeetingHeader, SnMakeDocumentMeetingCriteria, SnMakeDocumentMeetingList } from "models";
import { FormEvent, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaAngleLeft, FaFileAlt, FaSearch, FaTrashAlt } from "react-icons/fa";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { s0204, s0302 } from "services";
import { formatDateTh, formatTimeToInput } from "utils";
import toast from "utils/toast";

type Loader = { snAgendaTypeList: ItemModel[] };

export enum DetailType {
  MEETING = 'meeting',
  MEETING_RANK = 'meetingrank',
}

export default function S0302List() {
  const { snAgendaTypeList } = useLoaderData() as Loader;
  const [headerData, setHeaderData] = useState<SnInquiryMeetingHeader>({} as SnInquiryMeetingHeader);
  const [criteria, setCriteria] = useState<SnMakeDocumentMeetingCriteria>({} as SnMakeDocumentMeetingCriteria);
  const [snMeetingDocument, setSnMeetingDocument] = useState<SnMakeDocumentMeetingList[]>([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getSnMeetingDocumentAsync(criteria);
    getSnInquiryMeetingHeaderAsync();
  }, [page, size]);

  const getSnMeetingDocumentAsync = async (criteria: SnMakeDocumentMeetingCriteria) => {
    if (id) {
      const { data, status } = await s0204.getSnMakeDocumentMeetingListAsync(id, page, size, criteria);

      if (status === HttpStatusCode.OK) {
        setSnMeetingDocument(data.rows);
        setTotalRows(data.totalRows);
      }
    }
  };

  const getSnInquiryMeetingHeaderAsync = async () => {
    if (id) {
      const { data, status } = await s0302.getSnInquiryMeetingHeaderAsync(id);

      if (status === HttpStatusCode.OK) {
        setHeaderData(data);
      }
    }
  };

  const onSearch = () => {
    getSnMeetingDocumentAsync(criteria);
  };

  const onClear = () => {
    const criteriaClear = {} as SnMakeDocumentMeetingCriteria;

    setCriteria(criteriaClear);
    setPage(1);
    setSize(10);
    getSnMeetingDocumentAsync(criteriaClear);
  };

  const onChangeCriteria = (value: string | number | Date, prop: string) => {
    setCriteria({ ...criteria, [prop]: value });
  };

  const convertStatusToText = (status: string) => {
    const findStatus = statusMeetingItems.find(s => s.value === status)?.label;

    return findStatus;
  };

  const onChangeTime = (prop: keyof SnMakeDocumentMeetingCriteria, time?: Date) => {
    setCriteria({ ...criteria, [prop]: time });
  };

  const onChangePageSize = (size: number, page: number) => {
    setPage(page);
    setSize(size);
  };

  const onSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSearch();
  };

  const onClickCollectDetail = () => {
    if (snMeetingDocument.every(d => d.status === StatusChapter.COLLECT ||
      d.status === StatusChapter.GUARANTEE ||
      d.status === StatusChapter.CONFIRM ||
      d.status === StatusChapter.REJECT ||
      d.status === StatusChapter.REVIEWED_DOCUMENT) &&
      snMeetingDocument.length > 0) {

      navigate(`detail/${id}/type/${DetailType.MEETING}`);

      return;
    }

    toast.warn("ไม่สามารถเข้าได้เนื่องจากยังไม่มีการรวบรวมรายงาน");
  };

  return (
    <Layout title="รายละเอียดรายงานการประชุม">
      <div className="d-flex my-3">
        <Button
          variant="outline-primary"
          type="button"
          onClick={() => navigate('/s0302')}>
          <FaAngleLeft className="me-2" />ย้อนกลับ
        </Button>
      </div>
      <Card>
        <label>ข้อมูลการประชุม</label>
        <div className="mt-3 mx-4">
          <Row>
            <Col sm={6} md={4} xxl={3}>
              <p>ประเภทการประชุม : {headerData.meetingTypeName}</p>
            </Col>
            <Col sm={6} md={4} xxl={3}>
              <p>ครั้งที่ : {headerData.time}</p>
            </Col>
            <Col sm={6} md={4} xxl={3}>
              <p>สมัยการประชุม : {headerData.meetingPeriodName}</p>
            </Col>
            <Col sm={6} md={4} xxl={3}>
              <p>วันที่ประชุม : {formatDateTh(headerData.startDate)}</p>
            </Col>
            <Col sm={6} md={4} xxl={3}>
              <p>เวลา : {`${formatTimeToInput(headerData.startTime)}-${formatTimeToInput(headerData.endTime)}`}</p>
            </Col>
            <Col sm={6} md={4} xxl={3}>
              <p>กลุ่มงานที่รับผิดชอบ : {headerData.bureauGroups}</p>
            </Col>
            <Col sm={6} md={4} xxl={3}>
              <p>สถานะ : {convertStatusToText(headerData.status)}</p>
            </Col>
          </Row>
        </div>
      </Card>
      <Card className="mt-2">
        <label>ค้นหา</label>
        <Form onSubmit={onSubmitSearch}>
          <Row className="criteria">
            <Col sm={6} md={4} lg={4} xl={3}>
              <Input
                type="number"
                label="ตอนที่"
                value={criteria.chapter}
                onChange={(value) => onChangeCriteria(value, 'chapter')} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <InputTime
                name="start-time"
                label="ช่วงเวลาเริ่มต้น"
                value={criteria.startTime}
                onChange={(value) => onChangeTime('startTime', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <InputTime
                name="end-time"
                label="ช่วงเวลาสิ้นสุด"
                value={criteria.endTime}
                onChange={(value) => onChangeTime('endTime', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Selector
                label="วาระการประชุม"
                items={snAgendaTypeList}
                value={criteria.meetingAgendaId}
                onChange={(value) => onChangeCriteria(value, 'meetingAgendaId')} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Input
                label="ผู้อภิปราย"
                value={criteria.meetingMemberName}
                onChange={(value) => onChangeCriteria(value, 'meetingMemberName')} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Input
                label="ผู้จดรายงานการประชุม"
                value={criteria.dutyOfficerName}
                onChange={(value) => onChangeCriteria(value, 'dutyOfficerName')} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Selector
                label="สถานะ"
                items={statusChapterItems}
                value={criteria.status}
                onChange={(value) => onChangeCriteria(value, 'status')} />
            </Col>
            <Col
              sm={6} md={4} lg={4} xl={3}
              className="button d-flex gap-2">
            </Col>
          </Row>
          <div className="d-flex gap-3 justify-content-start mt-2">
            <Button
              variant="primary"
              type="submit">
              <FaSearch className="me-2" />ค้นหา
            </Button>
            <Button
              variant="outline-primary"
              type="button"
              onClick={onClear}>
              <FaTrashAlt className="me-2" />ล้างค่า
            </Button>
          </div>
        </Form>
      </Card>
      <div className="my-3 d-flex justify-content-end">
        <Button onClick={onClickCollectDetail}>
          รายงานที่ตรวจสอบและจัดทำรูปเล่มแล้ว
        </Button>
      </div>
      <div className="table-relative-fix">
        <div className="table-scroll">
          <Table
            total={totalRows}
            page={page}
            size={size}
            onChange={onChangePageSize}>
            <Table.Header>
              <Table.Row>
                <Table.Column minWidth={100}>ตอนที่</Table.Column>
                <Table.Column minWidth={150}>ช่วงเวลา</Table.Column>
                <Table.Column minWidth={250}>วาระการประชุม</Table.Column>
                <Table.Column minWidth={250}>ผู้อภิปราย</Table.Column>
                <Table.Column minWidth={250}>ผู้จดรายงานการประชุม</Table.Column>
                <Table.Column minWidth={200}>ผู้ตรวจ</Table.Column>
                <Table.Column minWidth={200}>สถานะ</Table.Column>
                <Table.Column minWidth={200}>แก้ไขครั้งล่าสุด</Table.Column>
                <Table.Column minWidth={200}>เวอร์ชั่น</Table.Column>
                <Table.Column minWidth={200} className="fix-col" />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {snMeetingDocument?.map((data) => (
                <Table.Row key={data.id}>
                  <Table.Cell center>{data.chapter}</Table.Cell>
                  <Table.Cell center>{`${formatTimeToInput(data.startDate)}-${formatTimeToInput(data.endDate)}`}</Table.Cell>
                  <Table.Cell center>{data.agendaTitle}</Table.Cell>
                  <Table.Cell>{data.debater}</Table.Cell>
                  <Table.Cell>{data.dutyOfficerFullName}</Table.Cell>
                  <Table.Cell center>{data.reviewerFullName}</Table.Cell>
                  <Table.Cell center><Status type={StatusType.CHAPTER_STATUS} value={data.status} /></Table.Cell>
                  <Table.Cell center>{data.updateDate ? formatDateTh(data.updateDate) : ""}</Table.Cell>
                  <Table.Cell center>{data.versionDocument}</Table.Cell>
                  <Table.Cell center className="fix-col">
                    <div>
                      <Button
                        variant="outline-primary"
                        type="button"
                        onClick={() => navigate(`detail/${data.id}/type/${DetailType.MEETING_RANK}`, { state: data })}>
                        <FaFileAlt className="me-2" />ดูรายละเอียด
                      </Button>
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </div>
    </Layout>
  );
}