import { SelectionType } from "constant";
import { CtCertifyDocumentListCriteria } from "models";
import { LoaderFunctionArgs } from "react-router-dom";
import { c0207, selectionService } from "services";

export const c0207LoaderAsync = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 1;
  const size = url.searchParams.get('size') || 10;
  const criteriaStringify = url.searchParams.get('criteria') || '{}';
  const criteria = JSON.parse(criteriaStringify) as CtCertifyDocumentListCriteria;

  const [bureaus, committees, subCommittees, ctMakeDocumentList] = await Promise.all([
    selectionService.getSelectionAsync(SelectionType.SM_BUREAU),
    selectionService.getSelectionAsync(SelectionType.CT_COMMITTEE),
    selectionService.getSelectionAsync(SelectionType.CT_SUBCOMMITTEE),
    c0207.getCtCertifyDocumentListAsync(+page, +size, criteria),
  ]);

  return {
    bureausItems: bureaus.data,
    committeesItems: committees.data,
    subCommitteesItems: subCommittees.data,
    data: {
      rows: ctMakeDocumentList.data.rows,
      totalRows: ctMakeDocumentList.data.totalRows,
      page: +page,
      size: +size,
    },
  };
};

export const c0207DetailLoaderAsync = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;

  if (id) {
    const [ctMakeDocumentDetail] = await Promise.all([
      c0207.getCtCertifyDocumentDetailAsync(id),
    ]);

    return {
      ctMakeDocumentDetail: ctMakeDocumentDetail.data,
    }
  }
};