export function showModalRemoveAsync() {
  return new Promise((resolve) => {
    document.dispatchEvent(new CustomEvent('onShowModalRemove'));

    document.addEventListener('onShowModalRemoveClosed', (event) => {
      document.removeEventListener('onShowModalRemoveClosed', () => { });

      const customEvent = event as CustomEvent;

      resolve(customEvent.detail.result);
    });
  });
}

export function showModalCancelAsync() {
  return new Promise((resolve) => {
    document.dispatchEvent(new CustomEvent('onShowModalCancel'));

    document.addEventListener('onShowModalCancelClosed', (event) => {
      document.removeEventListener('onShowModalCancelClosed', () => { });

      const customEvent = event as CustomEvent;

      resolve(customEvent.detail.result);
    });
  });
}

export function showModalConfirmAsync(detail: string, title?: string | null, icon?: JSX.Element | null, numberOfPages?: boolean, numberOfPagesValue?: number) {
  return new Promise((resolve) => {
    document.dispatchEvent(new CustomEvent('onShowModalConfirm', {
      detail: {
        detail,
        title,
        icon,
        numberOfPages,
        numberOfPagesValue,
      }
    }));

    document.addEventListener('onShowModalConfirmClosed', (event) => {
      document.removeEventListener('onShowModalConfirmClosed', () => { });

      const customEvent = event as CustomEvent;

      if (numberOfPages) {
        resolve(customEvent.detail);

        return;
      }

      resolve(customEvent.detail.result);
    });
  });
}

export function showModalCollectAsync() {
  return new Promise((resolve) => {
    document.dispatchEvent(new CustomEvent('onShowModalCollect'));

    document.addEventListener('onShowModalCollectClosed', (event) => {
      document.removeEventListener('onShowModalCollectClosed', () => { });

      const customEvent = event as CustomEvent;

      resolve(customEvent.detail.result);
    });
  });
}

export function showModalProgressBarLoadingAsync(loadingData: number, title?: string, dummyLoading?: boolean) {
  return new Promise((resolve) => {
    document.dispatchEvent(new CustomEvent('onShowModalProgressBarLoading', {
      detail: {
        loadingData,
        title,
        dummyLoading,
      }
    }));

    document.addEventListener('onShowModalProgressBarLoadingClosed', (event) => {
      document.removeEventListener('onShowModalProgressBarLoadingClosed', () => { });

      const customEvent = event as CustomEvent;

      resolve(customEvent.detail.result);
    });
  });
}