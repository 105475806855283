import { SenateGroup } from 'constant';
import { SenateCriteriaList, CommitteeCriteriaList } from 'models';
import http from 'utils/axios';

const getPerformanceOverviewDashboardSenateChartAsync = async (criteria?: SenateCriteriaList) => {
  const params = {
    ...criteria,
  };

  return await http.get('api/performanceoverviewdashboard/senate/chart', { params });
};

const getPerformanceOverviewDashboardSenateDataTableAsync = async (page: number, size: number, criteria?: SenateCriteriaList) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get('api/performanceoverviewdashboard/senate/datatable', { params });
};

const getPerformanceOverviewDashboardCommitteeChartAsync = async (criteria?: CommitteeCriteriaList) => {
  const params = {
    ...criteria,
  };

  return await http.get('api/performanceoverviewdashboard/committee/chart', { params });
};

const getPerformanceOverviewDashboardCommitteeDataTableAsync = async (page: number, size: number, criteria?: CommitteeCriteriaList) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get('api/performanceoverviewdashboard/committee/datatable', { params });
};

const exportExcelSenateAsync = async (page: number, size: number, criteria?: SenateCriteriaList) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get('api/performanceoverviewdashboard/senate/export/excel', { params, responseType: 'blob' });
};

const exportPdfSenateAsync = async (page: number, size: number, criteria?: SenateCriteriaList) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get('api/performanceoverviewdashboard/senate/export/pdf', { params, responseType: 'blob' });
};

const exportExcelCommitteeAsync = async (page: number, size: number, criteria?: CommitteeCriteriaList) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get('api/performanceoverviewdashboard/committee/export/excel', { params, responseType: 'blob' });
};

const exportPdfCommitteeAsync = async (page: number, size: number, criteria?: CommitteeCriteriaList) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get('api/performanceoverviewdashboard/committee/export/pdf', { params, responseType: 'blob' });
};

const getMeetingPeriodListAsync = async (page: number, size: number, senateGroupCode: string, startDate: Date, endDate: Date) => {
  const params = {
    page,
    size,
    senateGroupCode,
    startDate,
    endDate,
  };

  return await http.get('api/performanceoverviewdashboard/senate/meetingperiodlist', { params });
};

const getCommitteeListAsync = async (page: number, size: number) => {
  const params = {
    page,
    size,
  };

  return await http.get('api/performanceoverviewdashboard/committee/committeelist', { params });
};

const getBureauListAsync = async (page: number, size: number) => {
  const params = {
    page,
    size,
  };

  return await http.get('api/performanceoverviewdashboard/committee/bureaulist', { params });
};

const getCommitteeGroupAsync = async (page: number, size: number) => {
  const params = {
    page,
    size,
  };

  return await http.get('api/performanceoverviewdashboard/committee/committeeGroupList', { params });
};

const getCommitteeByBureauGroupAsync = async (page: number, size: number) => {
  const params = {
    page,
    size,
  };

  return await http.get('api/performanceoverviewdashboard/committee/bureauByGroupList', { params });
};

const getCommitteeGroupByBureauIdAsync = async (page: number, size: number, bureauId: string, startDate: Date, endDate: Date) => {
  const params = {
    page,
    size,
    bureauId,
    startDate,
    endDate,
  };

  return await http.get('api/performanceOverviewdashboard/committee/committeeGrouplistbybureauid', { params });
};

const getCommitteeByBureauGroupListByBureauIdAsync = async (page: number, size: number, bureauId: string, startDate: Date, endDate: Date) => {
  const params = {
    page,
    size,
    bureauId,
    startDate,
    endDate,
  };

  return await http.get('api/PerformanceOverviewDashboard/committee/bureauByGroupListByBureauId', { params });
};

const getSenateMeetingPeriodListDetailAsync = async (page: number, size: number, senateGroupCode: string, periodId: string, startDate: Date, endDate: Date) => {
  const params = {
    page,
    size,
    senateGroupCode,
    periodId,
    startDate,
    endDate,
  };

  return await http.get('api/PerformanceOverviewDashboard/senate/meetingperiodlist/detail', { params });
};

const getCommitteeGroupDetailAsync = async (page: number, size: number, committeeId: string, bureauGroupId: string) => {
  const params = {
    page,
    size,
    committeeId,
    bureauGroupId,
  };

  return await http.get('api/PerformanceOverviewDashboard/committee/committeeGroup/detail', { params });
};

const getCommitteeBureauDetailAsync = async (page: number, size: number, bureauGroupId: string, bureauId: string) => {
  const params = {
    page,
    size,
    bureauGroupId,
    bureauId,
  };

  return await http.get('api/PerformanceOverviewDashboard/committee/committeebureau/detail', { params });
};

const d02Service = {
  getPerformanceOverviewDashboardSenateChartAsync,
  getPerformanceOverviewDashboardSenateDataTableAsync,
  getPerformanceOverviewDashboardCommitteeChartAsync,
  getPerformanceOverviewDashboardCommitteeDataTableAsync,
  exportExcelSenateAsync,
  exportExcelCommitteeAsync,
  getMeetingPeriodListAsync,
  getCommitteeListAsync,
  getBureauListAsync,
  exportPdfSenateAsync,
  exportPdfCommitteeAsync,
  getCommitteeGroupAsync,
  getCommitteeByBureauGroupAsync,
  getCommitteeGroupByBureauIdAsync,
  getCommitteeByBureauGroupListByBureauIdAsync,
  getSenateMeetingPeriodListDetailAsync,
  getCommitteeGroupDetailAsync,
  getCommitteeBureauDetailAsync,
};

export default d02Service;