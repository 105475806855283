import ProgressBar from "@ramonak/react-progress-bar";
import { useEffect, useState } from "react";
import { Modal } from "./Modal";

export function ProgressBarLoading() {
  const [maxCompleted] = useState(99);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState<string>();
  const [loadingData, setLoadingData] = useState(0);
  const [dummyLoading, setDummyLoading] = useState<boolean>();

  useEffect(() => {
    addEventListener();

    if (loadingData > 0 && dummyLoading) {
      setDummyData();
    }
  }, [loadingData]);

  const addEventListener = () => document.addEventListener('onShowModalProgressBarLoading', (data) => {
    const event = data as CustomEvent;

    setDummyLoading(event.detail.dummyLoading);

    if (loadingData >= maxCompleted) {
      setShow(false);

      return;
    }

    if (event.detail.dummyLoading) {
      setLoadingData(10);
    } else {
      setLoadingData((event.detail.loadingData) - 1);
    }

    setTitle(event.detail.title);
    setShow(true);
  });

  const setDummyData = () => {
    if (loadingData >= maxCompleted) {
      setShow(false);

      document.dispatchEvent(new CustomEvent('onShowModalProgressBarLoadingClosed', { detail: { result: false } }));

      return;
    }

    const increaseLoadingData = () => {
      setLoadingData((prevLoadingData) => prevLoadingData + 10);
    };

    setTimeout(increaseLoadingData, 500);
  };

  return (
    <>
      <Modal show={show} size="xl">
        <div className="text-center">
          <h5>{title}</h5>
        </div>
        <div className="my-4">
          <ProgressBar
            completed={loadingData}
            maxCompleted={maxCompleted}
            bgColor="#6A0F0F"
            labelColor="#ffffff"
            transitionTimingFunction="ease-out" />
        </div>
      </Modal>
    </>
  );
}