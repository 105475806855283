import { Card, InputDate, Layout, Table } from "components";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaPlus, FaRegFileAlt, FaSearch, FaTrashAlt } from "react-icons/fa";
import { useLoaderData, useNavigate, useSubmit } from "react-router-dom";
import { FormEvent, useState } from "react";
import { CtReportMakeDailyList, CtReportMakerDailyCriteria, Pagination } from "models";
import { calculateRowNumber, fullDateTime, thaiFullFormatData } from "utils";

type Loader = { data: Pagination<CtReportMakeDailyList> };

export default function C0201() {
  const { data } = useLoaderData() as Loader;
  const [criteria, setCriteria] = useState<CtReportMakerDailyCriteria>({} as CtReportMakerDailyCriteria);
  const navigate = useNavigate();
  const submit = useSubmit();

  const onChangeCriteria = (prop: keyof CtReportMakerDailyCriteria, value?: Date) => {
    if (prop === 'date') {
      setCriteria({ ...criteria, date: value });
    }
  };

  const onSearch = (size: number, page: number) => {
    submit({
      page: page.toString(),
      size: size.toString(),
      criteria: JSON.stringify(criteria),
    });
  };

  const onClear = () => {
    setCriteria({} as CtReportMakerDailyCriteria);

    submit({
      page: 1,
      size: 10,
    });
  };

  const onSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSearch(data.size, data.page);
  };

  return (
    <Layout title="รายชื่อผู้จดรายงานการประชุมประจำวัน">
      <Card>
        <Form onSubmit={onSubmitSearch}>
          <Row>
            <Col xs={12} md={4} lg={3}>
              <InputDate
                value={criteria.date}
                name="date"
                label="วันที่ประชุม"
                onChange={(value) => onChangeCriteria('date', value)} />
            </Col>
          </Row>
          <div className="button d-flex gap-3 justify-content-start mt-2">
            <Button
              variant="primary"
              type="submit">
              <FaSearch className="me-2" />ค้นหา
            </Button>
            <Button
              variant="outline-primary"
              type="button"
              onClick={onClear}>
              <FaTrashAlt className="me-2" />ล้างค่า
            </Button>
          </div>
        </Form>
      </Card>
      <div className="d-flex justify-content-end my-3">
        <Button
          variant="primary"
          type="button"
          onClick={() => navigate('detail')}>
          <FaPlus className="me-2" />เพิ่มรายชื่อผู้จดรายงานประจำวัน
        </Button>
      </div>
      <Row className="mt-3">
        <Col>
          <Table
            total={data.totalRows}
            page={data.page}
            size={data.size}
            onChange={onSearch}>
            <Table.Header>
              <Table.Row>
                <Table.Column minWidth={100} maxWidth={120}>ลำดับ</Table.Column>
                <Table.Column minWidth={250}>วัน/เดือน/ปี</Table.Column>
                <Table.Column minWidth={150}>ผู้แก้ไขล่าสุด</Table.Column>
                <Table.Column minWidth={150}>วันที่แก้ไขข้อมูล</Table.Column>
                <Table.Column minWidth={100} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.rows?.map((d, index) => (
                <Table.Row key={d.id}>
                  <Table.Cell center>{calculateRowNumber(index, data.page, data.size).toString()}</Table.Cell>
                  <Table.Cell center>{thaiFullFormatData(d.date)}</Table.Cell>
                  <Table.Cell center>{d.updateBy}</Table.Cell>
                  <Table.Cell center>{fullDateTime(d.updateDate)}</Table.Cell>
                  <Table.Cell right className="px-5 d-flex justify-content-end">
                    <div>
                      <Button
                        variant="outline-primary"
                        type="button"
                        onClick={() => navigate(`detail/${d.id}`)}>
                        <FaRegFileAlt className="me-2" />ดูรายละเอียด
                      </Button>
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Col>
      </Row>
    </Layout>
  );
}