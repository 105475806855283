import { Input, Selector } from "components";
import { FormEvent, useEffect, useMemo, useState } from "react";
import { Col, Row, Image, Button, Form } from "react-bootstrap";
import { authenticationService } from "services";
import { isAuthorized, submitForm } from "utils";
import toast from "utils/toast";
import { Cookie, HttpStatusCode, MODULES, Module } from "constant";
import { useNavigate } from "react-router-dom";
import { useModule, useSignIn } from "hooks";
import cookie from "utils/cookie";
import { Menu } from "models";

export default function SignIn() {
  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  const navigate = useNavigate();
  const { setIsSignedIn } = useSignIn();
  const [showSelectModuleSection, setShowSelectModuleSection] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const { currentModule, setCurrentModule } = useModule();

  useEffect(() => {
    if (isAuthorized()) {
      navigate('/');
    }
  }, []);

  const isReadyToReader = useMemo(() => {
    return !isAuthorized();
  }, []);

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    submitForm({ username, password });

    if (!username || !password) {
      return;
    }

    setIsDisabled(true);

    const signInResponse = await authenticationService.signInAsync(username, password);

    switch (signInResponse.status) {
      case HttpStatusCode.BAD_REQUEST:
        toast.error('ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง');
        setIsDisabled(false);

        return;
      case HttpStatusCode.CONFLICT:
        toast.error('ไม่สามารถเข้าสู่ระบบได้เนื่องจากผู้ใช้นี้กำลังใช้งานอยู่');
        setIsDisabled(false);

        return;
      case HttpStatusCode.NOT_FOUND:
        setIsDisabled(false);

        return;
      case HttpStatusCode.UNAUTHORIZED:
        toast.error('ผู้ใช้งานนี้ไม่มีสิทธิ์เข้าใช้งานในระบบ');
        setIsDisabled(false);

        return;
    }

    const moduleResponse = await authenticationService.getModuleAsync(signInResponse.data.accessToken)

    if (moduleResponse.status === HttpStatusCode.NOT_FOUND) {
      setIsDisabled(false);

      return;
    }

    const lockResponse = await authenticationService.lockAsync(signInResponse.data.accessToken)

    if (lockResponse.status === HttpStatusCode.NOT_FOUND) {
      setIsDisabled(false);

      return;
    }

    cookie.set(Cookie.ACCESS_TOKEN, signInResponse.data.accessToken);

    setIsSignedIn(true);

    toast.success('เข้าสู่ระบบสำเร็จ');

    const modules = moduleResponse.data as string[];

    if (modules.some(m => m === Module.Senate) && modules.some(m => m === Module.Committee)) {
      cookie.set(Cookie.MODULE, Module.Committee);

      setShowSelectModuleSection(true);
    } else {
      if (modules.some(m => m === Module.Senate)) {
        setCurrentModule(Module.Senate);
        cookie.set(Cookie.MODULE, Module.Senate);

        onGetMenuAsync(Module.Senate);
      }

      if (modules.some(m => m === Module.Committee)) {
        setCurrentModule(Module.Committee);
        cookie.set(Cookie.MODULE, Module.Committee);

        onGetMenuAsync(Module.Committee);
      }
    }
  };

  const onGetMenuAsync = async (module: string) => {
    const res = await authenticationService.getMenusAsync(module);

    if (res.status === HttpStatusCode.OK) {
      const data = (res.data as Menu[]);

      if (data[0].children?.some(c => c.code.includes("D03"))) {
        navigate('/d03');

        return;
      } else if (data[0].children && data[0].children[0].children) {
        navigate(data[0].children[0].children[0].path);

        return;
      }
    }

    navigate("/");
  }

  const onModuleSelectAsync = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    submitForm({ currentModule });

    if (!currentModule) {
      return;
    }

    cookie.set(Cookie.MODULE, currentModule);

    onGetMenuAsync(currentModule);
  }

  return (
    <div style={{ height: "100vh" }}>
      {isReadyToReader ?
        <>
          <div className="sign-in">
            <div className="content">
              <Row className="mt-5">
                <Col className="d-flex justify-content-center">
                  <Image src="/images/logo-color.png" />
                </Col>
              </Row>
              <Row className="text-center mt-3">
                <Col xs={12}>
                  <h3>ระบบจัดทำรายงานการประชุม</h3>
                </Col>
                <Col xs={12}>
                  <h5>สำนักงานเลขาธิการวุฒิสภา</h5>
                </Col>
              </Row>
              {showSelectModuleSection ?
                <Form onSubmit={onModuleSelectAsync}>
                  <p className="text-center">เลือกองค์กร</p>
                  <Row className="justify-content-center mt-3">
                    <Col xs={8}>
                      <Selector
                        name="currentModule"
                        value={currentModule || Module.Committee}
                        items={MODULES}
                        onChange={val => setCurrentModule(val.toString())}
                        rule={{ required: true }}
                        isSearchable={false} />
                    </Col>
                  </Row>
                  <Row className="justify-content-center mt-3 mb-5">
                    <Col xs={8}>
                      <Button
                        variant="primary"
                        type="submit"
                        className="w-100 d-flex justify-content-center">
                        ตกลง
                      </Button>
                    </Col>
                  </Row>
                </Form> :
                <Form onSubmit={onSubmit}>
                  <Row className="justify-content-center mt-3">
                    <Col xs={8}>
                      <Input
                        label="ชื่อผู้ใช้งาน"
                        name='username'
                        value={username}
                        onChange={value => setUsername(value)}
                        rule={{ required: true }} />
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col xs={8}>
                      <Input
                        label="รหัสผ่าน"
                        name='password'
                        value={password}
                        onChange={value => setPassword(value)}
                        rule={{ required: true }}
                        type="password" />
                    </Col>
                  </Row>
                  <Row className="justify-content-center mt-3 mb-5">
                    <Col xs={8}>
                      <Button
                        variant="primary"
                        type="submit"
                        className="w-100 d-flex justify-content-center"
                        disabled={isDisabled}>
                        {isDisabled ? 'กรุณารอสักครู่...' : 'เข้าสู่ระบบ'}
                      </Button>
                    </Col>
                  </Row>
                </Form>}
            </div>
            <p className="user-manual fs-7 mx-2">
              <a
                href="https://drive.google.com/file/d/1S2bDEdoelqmlfU9LY6hhtvMcQbzyGPN5/view?usp=drive_link"
                target="_blank"
                className="text-white px-1">
                คู่มือสำหรับ จนท.ชวเลข
              </a>
              /
              <a
                href="https://drive.google.com/file/d/1XNhOUqTp0DTpS7YOBOqgJBdEyV86ULNz/view?usp=sharing"
                target="_blank"
                className="text-white px-1">
                คู่มือสำหรับ จนท. กมธ
              </a>
            </p>
            <div className="version">
              <p className="text-end mx-2 text-decoration-underline cursor-pointer">
                <a href="/web/free-page" target="_black" className="text-white">จำลองการใช้งาน</a>
              </p>
              <p className="fs-7 mx-2">เวอร์ชั่น 1.0.144 29/01/2567 15:30:00</p>
            </div>
          </div>
        </> : null}
    </div>
  );
}