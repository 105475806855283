import { ProgramModel } from 'models/program';
import http from 'utils/axios';

const getSmProgramListAsync = async (page: number, size: number, criteria?: ProgramModel) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return http.get('api/smprogram', { params });
};

const smProgramService = {
  getSmProgramListAsync,
};

export default smProgramService;