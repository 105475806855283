import { Code, PersonType, SelectionType, ShareValue, StaticId } from "constant";
import { ItemModel, UserSelection } from "models";
import { LoaderFunctionArgs } from "react-router-dom";
import { s0203, selectionService } from "services";

export const s0203LoaderAsync = async () => {
  const [snMeetingAgendaEventLog, snMeetingType, snMeetingPeriod] = await Promise.all([
    s0203.getSnMeetingAgendaEventLogAsync(1, 10),
    selectionService.getSelectionAsync(SelectionType.SN_MEETING_TYPE),
    selectionService.getSelectionAsync(SelectionType.SN_MEETING_PERIOD),
  ]);

  return {
    snMeetingAgendaEventLogList: snMeetingAgendaEventLog.data.rows,
    totalRows: snMeetingAgendaEventLog.data.totalRows,
    snMeetingTypeItems: snMeetingType.data,
    snMeetingPeriodItems: snMeetingPeriod.data,
  }
};

export const s0203DetailLoaderAsync = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;

  if (id) {
    const [snMeetingAgendaEventLog, snAgendatype, smUser, attendTypes, smBureauGroup, smBureau, members, debaterType] = await Promise.all([
      s0203.getSnMeetingAgendaEventLogDetailAsync(id),
      selectionService.getSelectionAsync(SelectionType.SN_AGENDA_TYPE),
      selectionService.getUserSelectionAsync(SelectionType.SM_USER, { bureauId: StaticId.BUREAU_ID, isNotContract: true } as UserSelection),
      selectionService.getPersonTypeListAsync(SelectionType.SN_MEMBER),
      selectionService.getSelectionAsync(SelectionType.SM_BUREAU_GROUP),
      selectionService.getSelectionAsync(SelectionType.SM_BUREAU),
      selectionService.getSelectionAsync(SelectionType.SN_MEMBER),
      selectionService.getShareValueAsync(ShareValue.DEBATER_TYPE),
    ]);

    const smBureauData = smBureau.data as ItemModel[];
    const smBureauGroupData = smBureauGroup.data as ItemModel[];
    attendTypes.data.push({ value: PersonType.Other, label: PersonType.Other });

    return {
      snMeetingAgendaEventLogDetail: snMeetingAgendaEventLog.data,
      snAgendaTypeItems: snAgendatype.data,
      smUserItems: smUser.data,
      attendTypes: attendTypes.data,
      smBureauGroup: smBureauGroupData.filter(f => f.id == smBureauData.filter(f => f.label.includes(Code.BUREAU_CODE))[0].value).slice(2),
      meetingMembers: members.data,
      debatersType: debaterType.data,
    }
  }
};