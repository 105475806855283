import { CtCertifyDocumentListCriteria } from 'models';
import http from 'utils/axios';

const getCtCertifyDocumentListAsync = async (page: number, size: number, criteria?: CtCertifyDocumentListCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get('api/ctcertifydocument', { params });
};

const getCtCertifyDocumentDetailAsync = async (id: string) => {
  return await http.get(`api/ctcertifydocument/ctmeeting/${id}`);
};

const updateStatusCtCertifyDocumentAsync = async (id: string, status: string, comment?: string) => {
  const body = {
    status,
    comment,
  };

  return await http.put(`api/ctcertifydocument/ctmeeting/${id}`, body);
};

const ctcertifydocumentService = {
  getCtCertifyDocumentListAsync,
  getCtCertifyDocumentDetailAsync,
  updateStatusCtCertifyDocumentAsync,
};

export default ctcertifydocumentService;