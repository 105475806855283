import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Modal } from "./Modal";
import { Input } from "components";
import { isNullOrEmpty, submitForm } from "utils";
import toast from "utils/toast";

export function ModalConfirm() {
  const [show, setShow] = useState<boolean>(false);
  const [detail, setDetail] = useState<string>('');
  const [title, setTitle] = useState<string>();
  const [icon, setIcon] = useState<JSX.Element>();
  const [numberOfPages, setNumberOfPages] = useState<boolean>(false);
  const [resultNumberOfPages, setResultNumberOfPages] = useState<number>();

  useEffect(() => { addEventListener(); }, []);

  const addEventListener = () => document.addEventListener('onShowModalConfirm', (data) => {
    const event = data as CustomEvent;

    setDetail(event.detail.detail);
    setTitle(event.detail.title);
    setIcon(event.detail.icon);
    setNumberOfPages(event.detail.numberOfPages);
    setResultNumberOfPages(event.detail.numberOfPagesValue);

    setShow(true);
  });

  const action = (confirm: boolean) => {
    if (numberOfPages && confirm) {
      submitForm();

      if (isNullOrEmpty(resultNumberOfPages)) {
        return toast.warn("กรุณาระบุจำนวนหน้ากระดาษ");
      }

      document.dispatchEvent(new CustomEvent('onShowModalConfirmClosed', { detail: { result: confirm, numberOfPages: resultNumberOfPages } }));
    } else {
      document.dispatchEvent(new CustomEvent('onShowModalConfirmClosed', { detail: { result: confirm } }));
    }

    if (numberOfPages) {
      setResultNumberOfPages(undefined);
    }

    setShow(false);
  };

  return (
    <Modal
      show={show}>
      <div>
        <Row>
          {icon ?
            <Col sm={12} className="mt-1 d-flex align-items-center justify-content-center fs-1">
              {icon}
            </Col> : null}
          {title ?
            <Col sm={12} className="mt-3 d-flex align-items-center justify-content-center">
              <h3>{title}</h3>
            </Col> : null}
          <Col sm={12} className="mt-2 d-flex align-items-center justify-content-center">
            <p>{detail}</p>
          </Col>
          {numberOfPages ? <Col sm={12} className="mb-4 d-flex align-items-center justify-content-center">
            <Input
              type="number"
              value={resultNumberOfPages}
              onChange={(value) => setResultNumberOfPages(Number(value))}
              rule={{ required: true }} />
          </Col> : null}
        </Row>
      </div>
      <Row>
        <Col>
          <div className="d-flex flex-row gap-2 justify-content-center">
            <Button variant="light" className="w-50" type="button" onClick={() => action(false)}>ยกเลิก</Button>
            <Button variant="primary" className="w-50" type="button" onClick={() => action(true)}>ยืนยัน</Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
}