import { LoaderFunctionArgs } from 'react-router-dom';
import { s0101 as service } from 'services';

export const s0101LoaderAsync = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 1;
  const size = url.searchParams.get('size') || 10;
  const keyword = url.searchParams.get('keyword') || '';

  const { data } = await service.getMeetingTypeListAsync(+page, +size, keyword);

  return {
    meetingTypeList: data,
  };
};