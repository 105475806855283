import { Rule } from "models";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { usePopperTooltip } from "react-popper-tooltip";
import { FaClock, FaTimes } from "react-icons/fa";
import { padZero2Digit } from "utils";
import { Selector } from "./Selector";

interface Props {
  label?: string;
  rule?: Rule;
  value?: Date;
  onChange?: (value?: Date) => void;
  name?: string;
  className?: string;
  disabled?: boolean;
}

enum TimeType {
  HOURS = 'Hours',
  MINUTES = 'Minutes',
}

export function InputTime(props: Props) {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [hour, setHour] = useState<string>("00");
  const [minute, setMinute] = useState<string>("00");
  const [timeShow, setTimeShow] = useState<string>();
  const [value, setValue] = useState<string>('');
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (props.value) {
      const date = new Date(props.value);
      const value = `${date.getHours()}:${date.getMinutes()}`;

      setValue(value);
    } else {
      setValue("");
    }
  }, [props.value]);

  const validate = (value: string) => {
    const checkRequired = (value: string) => {
      if (props.rule?.required && !value) {
        setErrorMessage('กรุณากรอกข้อมูล');

        return false;
      }

      return true;
    };

    if (!checkRequired(value)) {
      return;
    }

    setErrorMessage('');
  };

  useEffect(() => {
    document.addEventListener('onSubmitCustom', (data) => {
      const event = data as CustomEvent;

      if (event.detail.hasNameOnly) {
        if (props.name) {
          validate(value);
        }
      } else {
        validate(value);
      }
    });
  }, [validate, props.name]);

  const setLabelValue = useCallback(() => {
    if (value) {
      const splitValue = value.split(':');
      const hour = String(splitValue[0].toString()).padStart(2, '0');
      const minute = String(splitValue[1].toString()).padStart(2, '0');

      setHour(hour);
      setMinute(minute);
      setTimeShow(`${hour}.${minute}`);
    } else {
      setHour("00");
      setMinute("00");
      setTimeShow(undefined);
    }
  }, [value]);

  useEffect(() => {
    setLabelValue();
  }, [value, setLabelValue]);

  const handlerOnVisibleChange = (value: boolean) => {
    setVisible(value);

    if (!value) {
      setLabelValue();
    }
  };

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
  } = usePopperTooltip({
    trigger: 'click',
    interactive: true,
    onVisibleChange: handlerOnVisibleChange,
    closeOnOutsideClick: true,
    visible,
  });

  const getRequired = () => {
    if (props.rule?.required) {
      return <span className='text-danger'>*</span>;
    }

    return null;
  };

  const getErrorMessage = useMemo(() => {
    if (errorMessage) {
      return <Form.Text className="text-danger">{errorMessage}</Form.Text>;
    }

    return null;
  }, [errorMessage]);

  const getHours = () => Array.from(Array(24)).map((_, i) => ({ label: padZero2Digit(i), value: padZero2Digit(i) }));

  const getMinutes = () => Array.from(Array(60)).map((_, i) => ({ label: padZero2Digit(i), value: padZero2Digit(i) }));

  const onSubmit = (minuted?: string) => {
    const minutedValue = minuted ? minuted : minute;
    const value = `${hour}:${minutedValue}`;
    const date = new Date();
    date.setHours(+hour, +minutedValue, 0, 0);

    setValue(value);
    validate(value);
    setVisible(false);

    if (props.onChange) {
      props.onChange(date);
    }
  };

  const onClear = () => {
    setHour("00");
    setMinute("00");
    setTimeShow(undefined);
    validate('');

    if (props.onChange) {
      props.onChange(undefined);
    }
  }

  const onChangeHoursOrMinutes = (value: string, type: string) => {
    type === TimeType.MINUTES ? setMinute(value) : setHour(value);

    if (type === TimeType.MINUTES) {
      onSubmit(value);
    }
  };

  const time = () => {
    return (
      <div className='calendar'>
        <Row>
          <Col>
            <Selector
              label="ชั่วโมง"
              className='mb-0'
              name='hour'
              size='sm'
              value={hour}
              items={getHours()}
              onChange={value => onChangeHoursOrMinutes(value.toString(), TimeType.HOURS)} notFixed isHideClearButton />
          </Col>
          <Col>
            <Selector
              label="นาที"
              className='mb-0'
              name='minute'
              size="sm"
              value={minute}
              items={getMinutes()}
              onChange={value => onChangeHoursOrMinutes(value.toString(), TimeType.MINUTES)} notFixed isHideClearButton />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button className="w-100" onClick={() => onSubmit()}>
              ยืนยัน
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <div>
      <Form.Group className={`w-100 position-relative ${props.className ?? ''} ${props.label ? 'mb-3' : ''}`}>
        {props.label ? <Form.Label className='m-0'>{props.label} {getRequired()}</Form.Label> : null}
        <InputGroup ref={setTriggerRef}>
          <Form.Control
            disabled={props.disabled}
            readOnly
            className={`none-border-right ${getErrorMessage ? 'is-invalid' : ''}`}
            value={timeShow ?? ''}
            type='text'
            placeholder='00:00' />
          <InputGroup.Text
            className={props.disabled ? '' : 'cursor-pointer'}>
            <FaClock />
          </InputGroup.Text>
        </InputGroup>
        {(props.value && !props.disabled) ?
          <InputGroup.Text
            className="fatime-noborder-nobg cursor-pointer">
            <FaTimes onClick={onClear} />
          </InputGroup.Text>
          : null
        }
        {getErrorMessage}
      </Form.Group>
      {visible && !props?.disabled ? (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: 'tooltip-container',
            style: visible
              ? { visibility: 'visible', zIndex: '4' }
              : { visibility: 'hidden', pointerEvents: 'none' },
          })}>
          {time()}
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
        </div>
      ) : null}
    </div>
  );
}