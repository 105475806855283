import { MeetingRoomModel } from 'models';
import http from 'utils/axios';

const getMeetingRoomListAsync = async (page: number, size: number, keyword?: string) => {
  const params = {
    page,
    size,
    keyword,
  };

  return await http.get(`api/smmeetingroom`, { params });
};

const createMeetingRoomAsync = async (data: MeetingRoomModel) => {
  return await http.post(`/api/smmeetingroom`, data);
}

const deleteMeetingRoomAsync = async (id: string) => await http.delete(`api/smmeetingroom/${id}`);

const updateMeetingRoomAsync = async (data: MeetingRoomModel) => await http.put(`api/smmeetingroom/${data.id}`, data);

const m0101 = {
  getMeetingRoomListAsync,
  createMeetingRoomAsync,
  deleteMeetingRoomAsync,
  updateMeetingRoomAsync,
}

export default m0101;