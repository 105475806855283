import { Rule } from 'models';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

interface Props {
  label?: string;
  value?: string | number;
  onChange?: (value: string | number) => void;
  name?: string;
  className?: string;
  items: { label: string | JSX.Element, value: string | number }[];
  rule?: Rule;
  inline?: boolean;
  dflex?: boolean;
  disabled?: boolean;
}

export function Radio(props: Props) {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [value, setValue] = useState(props.value);

  document.addEventListener('onFormSubmit', () => {
    validate(value);
  });

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const getRequired = () => {
    if (props.rule?.required) {
      return <span className="text-danger">*</span>;
    }

    return null;
  };

  const validate = (value?: string | number) => {
    if (!checkRequired(value)) {
      return;
    }

    setErrorMessage('');
  };

  const checkRequired = (value?: string | number) => {
    if (props.rule?.required && !value) {
      setErrorMessage('กรุณาเลือกข้อมูล');

      return false;
    }

    return true;
  };

  const handlerOnChange = (event: HTMLInputElement) => {
    if (props.onChange) {
      props.onChange(event.value);
    }

    setValue(event.value);

    validate(event.value);
  };

  return (
    <Form.Group className={`${props.className ?? ''} ${props.label ? 'mb-3' : ''}`}>
      {props.label ? <Form.Label className={`m-0 ${props.inline ? 'me-3' : ''}`}>{props.label} {getRequired()}</Form.Label> : null}
      <div className={`m-0 ${props.dflex ? 'd-flex' : ''}`}>
        {props.items.map((item, i) => (
          <Form.Check
            checked={value === item.value}
            key={item.value}
            type="radio"
            inline={props.inline}
            value={item.value}
            label={item.label}
            name={props.name}
            onChange={(event) => handlerOnChange(event.target as HTMLInputElement)}
            disabled={props.disabled}
          />
        ))}
      </div>
      {
        errorMessage
          ? (
            <span className="d-flex invalid-feedback">
              {errorMessage}
            </span>
          ) : null
      }
    </Form.Group >
  );
}