import { CtInquiryMeetingCriteria, CtInquiryMeetingDetailCriteria } from 'models';
import http from 'utils/axios';

const getListAsync = async (page: number, size: number, criteria?: CtInquiryMeetingCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get(`api/ctinquirymeeting`, { params });
};

const getDetailHeaderAsync = async (id: string) =>
  await http.get(`api/ctinquirymeeting/ctmeeting/${id}`);

const getDetailAsync = async (id: string, page: number, size: number, criteria?: CtInquiryMeetingDetailCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  }

  return await http.get(`api/ctinquirymeeting/ctmeeting/${id}/ctmeetingchapter`, { params });
};

const getMeetingDocumentAsync = async (id: string) => {
  return await http.get(`api/ctreviewoverview/ctmeeting/${id}`);
};

const getChapterDocumentAsync = async (id: string) => {
  return await http.get(`api/ctmakeDocument/ctmeetingchapter/${id}`);
};

const c0302 = {
  getListAsync,
  getDetailHeaderAsync,
  getDetailAsync,
  getMeetingDocumentAsync,
  getChapterDocumentAsync,
};

export default c0302;