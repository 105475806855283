import { Card, Input, InputDate, Layout, Modal, Selector, Status, StatusType, Table } from "components";
import { HttpStatusCode, StatusMeeting, statusMeetingItems } from "constant";
import { ItemModel, Pagination, SnMeetingCriteria, SnMeetingList } from "models";
import { FormEvent, useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaEdit, FaPlus, FaRedo, FaSearch, FaSort, FaSyncAlt, FaTrashAlt } from "react-icons/fa";
import { useLoaderData, useNavigate, useSubmit } from "react-router-dom";
import { s0201 } from "services";
import { calculateRowNumber, formatDateTh, fullDateTime, getYearList, showModalRemoveAsync, submitForm } from "utils";
import toast from "utils/toast";

type Loader = { snMeetingList: Pagination<SnMeetingList>, snMeetingTypeItems: ItemModel[], snMeetingPeriodItems: ItemModel[] };

export default function S0201() {
  const { snMeetingList, snMeetingTypeItems, snMeetingPeriodItems } = useLoaderData() as Loader;
  const [criteria, setCriteria] = useState<SnMeetingCriteria>({} as SnMeetingCriteria);
  const [showSyncDataModal, setShowSyncDataModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const submit = useSubmit();

  const statusItems = [
    StatusMeeting.DRAFT,
    StatusMeeting.CONFIRM,
    StatusMeeting.CANCEL,
  ];

  const filterStatus = useMemo(() => statusMeetingItems.filter(s => statusItems.includes(s.value)), [statusMeetingItems]);

  const onChangeCriteria = (prop: keyof SnMeetingCriteria, value?: string | number | Date) => {
    setCriteria({ ...criteria, [prop]: value });
  };

  const onSearch = (size: number, page: number) => {
    submit({
      page: page,
      size: size,
      criteria: JSON.stringify(criteria),
    });
  };

  const onClearCriteria = () => {
    setCriteria({} as SnMeetingCriteria);

    submit({});
  };

  const handlerRemoveAsync = async (id: string) => {
    if (await showModalRemoveAsync()) {
      deleteSnMeetingAsync(id);
    }
  };

  const deleteSnMeetingAsync = async (id: string) => {
    const { status } = await s0201.deleteSnMeetingAsync(id);

    if (status === HttpStatusCode.NO_CONTENT) {
      toast.success("ลบข้อมูลสำเร็จ");

      submit({
        page: snMeetingList.page,
        size: snMeetingList.size,
        criteria: JSON.stringify(criteria),
      });
    }
  };

  const onSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSearch(snMeetingList.size, snMeetingList.page);
  };

  const canDelete = (status: string) => {
    return (status === StatusMeeting.DRAFT || status === StatusMeeting.CANCEL);
  };

  return (
    <Layout title="กำหนดข้อมูลการประชุม">
      <Card>
        <Form onSubmit={onSubmitSearch}>
          <Row>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Selector
                label="ประเภทการประชุม"
                placeholder="ประเภทการประชุม"
                items={snMeetingTypeItems}
                value={criteria.meetingTypeId}
                onChange={(value) => onChangeCriteria('meetingTypeId', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Input
                type="number"
                label="ครั้งที่ประชุม"
                value={criteria.time}
                onChange={(value) => onChangeCriteria('time', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Selector
                label="สมัยการประชุม"
                placeholder="สมัยการประชุม"
                items={snMeetingPeriodItems}
                value={criteria.periodId}
                onChange={(value) => onChangeCriteria('periodId', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Input
                label="ชื่อการประชุม"
                value={criteria.name}
                onChange={(value) => onChangeCriteria('name', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Selector
                label="ปี"
                placeholder="ปี"
                items={getYearList()}
                value={criteria.year}
                onChange={(value) => onChangeCriteria('year', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <InputDate
                label="วันที่ประชุม"
                value={criteria.meetingDate}
                onChange={(value) => onChangeCriteria('meetingDate', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Selector
                label="สถานะ"
                placeholder="สถานะ"
                items={filterStatus}
                value={criteria.status}
                onChange={(value) => onChangeCriteria('status', value)} />
            </Col>
          </Row>
          <Row>
            <Col className="d-flex gap-2 justify-content-start">
              <Button
                variant="primary"
                type="submit">
                <FaSearch className="me-2" />ค้นหา
              </Button>
              <Button
                variant="outline-primary"
                type="button"
                onClick={onClearCriteria}>
                <FaTrashAlt className="me-2" />ล้างค่า
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <div className="my-3 d-flex justify-content-end gap-2">
        <Button
          variant="outline-primary"
          type="button"
          onClick={() => setShowSyncDataModal(true)}>
          <FaRedo className="me-2" />ซิงค์ข้อมูล
        </Button>
        <Button
          variant="primary"
          type="submit"
          onClick={() => navigate('detail')}>
          <FaPlus className="me-2" />เพิ่ม
        </Button>
      </div>
      <div className="table-relative-fix">
        <div className="table-scroll">
          <Table total={snMeetingList.totalRows}
            page={snMeetingList.page}
            size={snMeetingList.size}
            onChange={onSearch}>
            <Table.Header>
              <Table.Row>
                <Table.Column minWidth={100}>ลำดับ</Table.Column>
                <Table.Column minWidth={150}>ประเภทการประชุม</Table.Column>
                <Table.Column minWidth={100}>ครั้งที่</Table.Column>
                <Table.Column minWidth={300}>สมัยการประชุม</Table.Column>
                <Table.Column minWidth={300}>ชื่อการประชุม</Table.Column>
                <Table.Column minWidth={250}>วันที่ประชุม</Table.Column>
                <Table.Column minWidth={150}>สถานะ</Table.Column>
                <Table.Column minWidth={200}>ผู้แก้ไขข้อมูล</Table.Column>
                <Table.Column minWidth={200}>วันที่แก้ไขข้อมูล</Table.Column>
                <Table.Column minWidth={220} className="fix-col" />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {snMeetingList.rows?.map((data, index) => (
                <Table.Row key={data.id}>
                  <Table.Cell center>{calculateRowNumber(index, snMeetingList.page, snMeetingList.size).toString()}</Table.Cell>
                  <Table.Cell >{data.meetingType}</Table.Cell>
                  <Table.Cell center>{(data.time).toString()}</Table.Cell>
                  <Table.Cell center>{data.meetingPeriod}</Table.Cell>
                  <Table.Cell>{data.name}</Table.Cell>
                  <Table.Cell center>{formatDateTh(data.meetingDate)}</Table.Cell>
                  <Table.Cell center><Status type={StatusType.MEETING_STATUS} value={data.status} /></Table.Cell>
                  <Table.Cell>{data.updateByUserFullName}</Table.Cell>
                  <Table.Cell center>{fullDateTime(data.updateDate)}</Table.Cell>
                  <Table.Cell center className="fix-col">
                    {(data.status !== StatusMeeting.DRAFT && data.status !== StatusMeeting.CANCEL) ?
                      <Button
                        variant="outline-primary"
                        type="button"
                        size="sm"
                        onClick={() => navigate(`queue/${data.id}`)}>
                        <FaSort className='me-2' />จัดลำดับคิว
                      </Button> : <></>}
                    <FaEdit className='mx-3 fs-5' onClick={() => navigate(`detail/${data.id}`)} />
                    {canDelete(data.status) ? <FaTrashAlt onClick={() => handlerRemoveAsync(data.id)} className="ms-2 fs-5" /> : <></>}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </div>
      <SyncDataModal
        show={showSyncDataModal}
        onHide={() => setShowSyncDataModal(false)}
        onFinish={onSearch} />
    </Layout>
  );
}

function SyncDataModal(props: {
  show: boolean;
  onHide: () => void;
  onFinish: (size: number, page: number) => void;
}) {
  const [meetingDate, setMeetingDate] = useState<Date>();
  const { snMeetingList } = useLoaderData() as Loader;

  useEffect(() => {
    if (props.show) {
      setMeetingDate(undefined);
    }
  }, [props.show]);

  const onSubmitAsync = async () => {
    submitForm(true);

    if (!meetingDate) {
      return;
    }

    const { status } = await s0201.syncDataAsync(meetingDate);

    if (status === HttpStatusCode.CREATED) {
      toast.success('ซิงค์ข้อมูลสำเร็จ');

      props.onFinish(snMeetingList.size, snMeetingList.page);
      props.onHide();
    }
  };

  return (
    <Modal
      show={props.show}
      icon={<FaSyncAlt />}
      title="ซิงค์ข้อมูล">
      <InputDate
        name="meeting-date"
        label="วันที่ประชุม"
        value={meetingDate}
        onChange={setMeetingDate}
        rule={{ required: true }} />
      <div className="d-flex justify-content-end gap-2 mt-3">
        <Button variant="outline-primary" onClick={props.onHide}>
          ยกเลิก
        </Button>
        <Button variant="primary" onClick={onSubmitAsync}>
          ตกลง
        </Button>
      </div>
    </Modal>
  );
}