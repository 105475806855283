import { useEffect, useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Loading, ModalCancel, ModalCollect, ModalRemove, Navbar, ProgressBarLoading, Sidebar } from "components";
import { isAuthorized, signOutAsync } from "utils";
import { useHls, useModule, useRolePrograms, useUser } from "hooks";
import { ModalConfirm } from "components/Modal/Confirm";
import { Unauthorized } from "./Other";

export default function PageBase() {
  const location = useLocation();
  const { user } = useUser();
  const { setCurrentModule, currentModule } = useModule();
  const { rolePrograms } = useRolePrograms();
  const { hlsRef } = useHls();

  useEffect(() => {
    hlsRef.current.stopLoad();
  }, [location.pathname]);

  useEffect(() => {
    if (!isAuthorized()) {
      signOutAsync();
    }
  }, []);

  const isCanToPage = useMemo(() => {
    const programCode = location.pathname.split('/')[1];

    if (!programCode || programCode === 'sign-in') {
      return true;
    }

    if (rolePrograms && rolePrograms.includes(programCode)) {
      return true;
    }

    return false;
  }, [location, rolePrograms]);

  const isReadyToRender = useMemo(() => {
    if (!isAuthorized()) {
      return false;
    }

    if (!user.id || !rolePrograms) {
      return false;
    }

    return true;
  }, [user, rolePrograms]);

  return (
    <>
      {isReadyToRender ?
        <>
          {isCanToPage ?
            <>
              <Navbar />
              <Sidebar />
              <Outlet context={{ user, setCurrentModule, currentModule, hlsRef }} />
              <ModalRemove />
              <ModalCancel />
              <ModalConfirm />
              <ModalCollect />
              <ProgressBarLoading />
              <Loading />
            </>
            : <Unauthorized />}
        </> : null}
    </>
  );
}