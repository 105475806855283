import { SelectionType, ShareValue } from "constant";
import { C0202Criteria, UserSelection } from "models";
import { LoaderFunctionArgs } from "react-router-dom";
import { selectionService, c0202 } from "services";

export const c0202LoaderAsync = async () => {

  const [bureaus, committees, subCommittees, smUser] = await Promise.all([
    selectionService.getSelectionAsync(SelectionType.SM_BUREAU),
    selectionService.getSelectionAsync(SelectionType.CT_COMMITTEE),
    selectionService.getSelectionAsync(SelectionType.CT_SUBCOMMITTEE),
    selectionService.getUserSelectionAsync(SelectionType.SM_USER, { isMainGroup: true } as UserSelection),
  ]);

  return {
    bureaus: bureaus.data,
    committees: committees.data,
    subCommittees: subCommittees.data,
    smUser: smUser.data,
  };
};

export const c0202DetailLoaderAsync = async ({ params, request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const criteriaStringify = url.searchParams.get('criteria') || '{}';
  const criteria = JSON.parse(criteriaStringify) as C0202Criteria;
  const listPage = url.searchParams.get('listPage') || 1;
  const listSize = url.searchParams.get('listSize') || 10;

  const { id } = params;
  const [smBureaus,
    bureauGroups,
    users,
    positionTypes,
    ctBureaus,
    committees,
    subCommittees,
    meetingRooms,
    meetingType,
    meeting,
    queueType] = await Promise.all([
      selectionService.getSelectionAsync(SelectionType.SM_BUREAU),
      selectionService.getSelectionAsync(SelectionType.SM_BUREAU_GROUP),
      selectionService.getUserSelectionAsync(SelectionType.SM_USER),
      selectionService.getShareValueAsync(ShareValue.POSITION_TYPE),
      selectionService.getSelectionAsync(SelectionType.SM_BUREAU),
      selectionService.getSelectionAsync(SelectionType.CT_COMMITTEE),
      selectionService.getSelectionAsync(SelectionType.CT_SUBCOMMITTEE),
      selectionService.getSelectionAsync(SelectionType.SM_MEETING_ROOM),
      selectionService.getSelectionAsync(SelectionType.CT_MEETING_TYPE),
      id ? c0202.getDetailAsync(id) : null,
      selectionService.getShareValueAsync(ShareValue.QUEUE_TYPE),
    ]);

  return {
    smBureaus: smBureaus.data,
    bureauGroups: bureauGroups.data,
    users: users.data,
    positionTypes: positionTypes.data,
    ctBureaus: ctBureaus.data,
    committees: committees.data,
    subCommittees: subCommittees.data,
    meetingRooms: meetingRooms.data,
    meetingType: meetingType.data,
    meeting: meeting?.data,
    queueType: queueType.data,
    criteriaIndex: criteria,
    listPageLoader: +listPage,
    listSizeLoader: +listSize,
  };
};