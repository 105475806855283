import { Card, Collabora, CollaboraRef, Layout, VideoOption } from "components";
import { HttpStatusCode, VideoControl, statusMeetingItems } from "constant";
import { ItemModel, SnChapterDocument, SnInquiryMeetingDetail, SnMakeDocumentMeetingList } from "models";
import { Button, Col, Dropdown, DropdownButton, Row, Form } from "react-bootstrap";
import { FaAngleLeft, FaRegFilePdf, FaRegFileWord, FaUndo } from "react-icons/fa";
import { useLoaderData, useLocation, useNavigate, useParams } from "react-router-dom";
import { exportFilePDFAsync, exportFileWordAsync, formatDateTh, formatTimeToInput } from "utils";
import { DetailType } from "./List";
import { useEffect, useRef, useState } from 'react';
import Video from "components/Video/Video";
import { snVideoService } from "services";

type Loader = {
  snInquiryMeetingDetail: SnInquiryMeetingDetail,
  snMeetingTypeItems: ItemModel[],
  snMeetingPeriodItems: ItemModel[],
  videoFile: File,
  documentData: SnChapterDocument[],
  fileId: string[],
};

export default function S0302Detail() {
  const { videoFile, documentData, fileId } = useLoaderData() as Loader;
  const [documentSelected, setDocumentSelected] = useState("");
  const [documentSelectedVersion, setDocumentSelectedVersion] = useState<number>(0);
  const [chapterList, setChapterList] = useState<SnChapterDocument[]>([]);
  const collaboraRef = useRef<CollaboraRef>(null);
  const [videoSelect, setVideoSelect] = useState<File>();
  const [fileIndex, setFileIndex] = useState<number>(0);
  const { id, type } = useParams();
  const programName = useState<string>("ค้นหารายงานการประชุม");
  const navigate = useNavigate();

  useEffect(() => {
    if (documentData.length > 0) {
      setDocumentSelected(documentData[0].id);
      setChapterList(documentData);
      setVideoSelect(videoFile);
      setDocumentSelectedVersion(documentData[0].versionDocument);
    }
  }, [documentData]);

  useEffect(() => {
    if (type === DetailType.MEETING_RANK) {
      if (fileId.length) {
        getVideoAsync(fileId[0]);
        setFileIndex(0);
      }
    }
  }, [fileId]);

  const videoRef = useRef<VideoOption>({} as VideoOption);

  function loadCollabora() {
    const iframe = document.getElementById(documentSelected) as HTMLIFrameElement;

    iframe.contentWindow?.addEventListener('keydown', (e) => clickBtn(e.key));
  };

  const getVideoAsync = async (videoId: string) => {
    const { data, status } = await snVideoService.getVideoAsync(videoId);

    if (status === HttpStatusCode.OK) {
      setVideoSelect(data);
    }
  };

  const onEndedAsync = async () => {
    if (type === DetailType.MEETING_RANK) {
      const currentIndex = fileIndex + 1;

      if (fileId.length > currentIndex) {
        getVideoAsync(fileId[currentIndex]);

        setFileIndex(currentIndex);
      } else {
        setFileIndex(0);
      }
    }
  };

  const onLoadedData = () => {
    if (fileIndex) {
      videoRef.current.playRef();
    }
  };

  function clickBtn(key: string) {
    switch (key) {
      case VideoControl.F10:
        videoRef.current.backRef();
        break;
      case VideoControl.F9:
        videoRef.current.playRef();
        break;
      case VideoControl.F11:
        videoRef.current.aheadRef();
        break;
    }
  }

  const onChangeVersionDocument = (id: string | null) => {
    if (id) {
      setDocumentSelected(id);

      const fileVersion = documentData.find(d => d.id === id)?.versionDocument;

      if (fileVersion) {
        setDocumentSelectedVersion(fileVersion);
      }
    }
  };

  return (
    <Layout title={programName[0]}>
      <div className="d-flex justify-content-between my-3">
        <Button
          variant="outline-primary"
          type="button"
          onClick={() => navigate(`/s0302/list/${id}`)}>
          <FaAngleLeft className="me-2" />ย้อนกลับ
        </Button>
        <div className="d-flex gap-2">
          <DropdownButton
            title={<span><FaUndo className="me-2" />เวอร์ชั่นก่อนหน้า ({documentSelectedVersion})</span>}
            drop="down-centered"
            variant="outline-primary"
            onSelect={(value) => onChangeVersionDocument(value)}>
            {chapterList?.map(d => (
              <Dropdown.Item eventKey={d.id} key={d.id}>{d.versionDocument}</Dropdown.Item>
            ))}
          </DropdownButton>
          <Button
            variant="outline-primary"
            onClick={() => exportFilePDFAsync(documentSelected, programName[0])}>
            <FaRegFilePdf className="me-2" />Export PDF
          </Button>
          <Button
            variant="outline-primary"
            onClick={() => exportFileWordAsync(documentSelected, programName[0])}>
            <FaRegFileWord className="me-2" />Export Word
          </Button>
        </div>
      </div>
      <Row>
        <Col md={4}>
          <Video
            onEnded={onEndedAsync}
            onLoadedData={onLoadedData}
            ref={videoRef}
            files={videoSelect ? [videoSelect] : []}
            showPlayButton={false}
            showPlayTimeConfig={false}
            showSpeakToText={false}>
            <MeetingDetail />
          </Video>
        </Col>
        <Col md={8}>
          <Collabora
            docId={documentSelected}
            docName="s0302"
            height="1000px"
            ref={collaboraRef}
            onCollaboraLoaded={() => loadCollabora()} />
        </Col>
      </Row>
    </Layout>
  );
}

function MeetingDetail() {
  const header = useLocation().state as SnMakeDocumentMeetingList;
  const { snInquiryMeetingDetail, snMeetingPeriodItems, snMeetingTypeItems } = useLoaderData() as Loader;
  const { type } = useParams();

  const getSnMeetingTypeName = (value: string) => {
    const findData = snMeetingTypeItems.find(s => s.value === value);

    return findData?.label;
  };

  const getSnMeetingPeriodName = (value: string) => {
    const findData = snMeetingPeriodItems.find(s => s.value === value);

    return findData?.label;
  };

  const convertStatusToText = (status: string) => {
    const findStatus = statusMeetingItems.find(s => s.value === status)?.label;

    return findStatus;
  };

  return (
    <>
      {type === DetailType.MEETING ?
        <Card className="mt-3">
          <Col xl={12}>
            <Form.Label className="fw-bold">
              ข้อมูลการประชุม
            </Form.Label>
            <Row>
              <Col lg={12}>
                ประเภทวาระประชุม : {getSnMeetingTypeName(snInquiryMeetingDetail.snMeetingTypeId)}
              </Col>
              <Col lg={12}>
                ครั้งที่ : {snInquiryMeetingDetail.time}
              </Col>
              <Col lg={12}>
                สมัยที่ประชุม : {getSnMeetingPeriodName(snInquiryMeetingDetail.snMeetingPeriodId)}
              </Col>
              <Col lg={12}>
                วันที่ประชุม : {formatDateTh(snInquiryMeetingDetail.startDate)}
              </Col>
              <Col lg={12}>
                เวลา : {`${formatTimeToInput(snInquiryMeetingDetail.startTimeFirst)} - ${formatTimeToInput(snInquiryMeetingDetail.endTimeFirst)}`}
              </Col>
              <Col lg={12}>
                สถานะ : {convertStatusToText(snInquiryMeetingDetail.status)}
              </Col>
            </Row>
          </Col>
        </Card> :
        <Card className="mt-3">
          <Form.Label className="fw-bold">
            ข้อมูลการประชุม
          </Form.Label>
          <div className="mt-3 mx-4">
            <Row>
              <Col lg={12}>
                ตอนที่ : {header.chapter}
              </Col>
              <Col lg={12}>
                ช่วงเวลา : {`${formatTimeToInput(header.startDate)}-${formatTimeToInput(header.endDate)}`}
              </Col>
              <Col lg={12}>
                ผู้จดรายงานการประชุม	: {header.dutyOfficerFullName}
              </Col>
              <Col lg={12}>
                ผู้ตรวจ : {header.reviewerFullName}
              </Col>
            </Row>
          </div>
        </Card>}
    </>
  );
}