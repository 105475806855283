import { FaPlus, FaTrashAlt, FaRegSave, FaChevronCircleUp, FaChevronCircleDown, FaTimes, FaEdit } from "react-icons/fa";
import { Layout, Table, Selector, Input, Modal, Check } from "components";
import { Dispatch, SetStateAction, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { showModalCancelAsync, showModalRemoveAsync, submitForm } from "utils";
import { CtReportMakerCreate, CtReportMakerList, ItemModel, ReportCommander, ReportCommanderDto, ReportMakerDto, UserSelection } from "models";
import { useLoaderData } from "react-router";
import { selectionService as ddlService, c0104 as service } from "services";
import { Code, SelectionType, MovementType, Position } from "constant";
import { HttpStatusCode } from "axios";
import { MdAddCircleOutline } from "react-icons/md";
import { useSubmit } from "react-router-dom";
import { LONG_MONTHS } from "data";
import toast from "utils/toast";

interface ModalReportProps<T> {
  show: boolean;
  onHide: () => void;
  onSelect: (data: T, onEdit?: boolean, index?: number) => void;
  editSelectedData?: ReportMakerDto;
}

type Loader = {
  bureauList: ItemModel[];
  bureauGroupList: ItemModel[];
  listData: CtReportMakerList;
  commanderPositionList: ItemModel[];
};

type ReportMakerContext = {
  onEdit: boolean;
  setOnEdit: Dispatch<SetStateAction<boolean>>;
  currentSequences: number;
  list: CtReportMakerList;
  bureauItems: ItemModel[];
  bureauGroupItem: ItemModel[];
};

const Context = createContext({} as ReportMakerContext);

export default function C0104() {
  const { listData, bureauList, bureauGroupList, commanderPositionList } = useLoaderData() as Loader;
  const [bureauItems, setBureauItems] = useState<ItemModel[]>([]);
  const [list, setList] = useState<CtReportMakerList>({} as CtReportMakerList);
  const [onEdit, setOnEdit] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalReportCommander, setShowModalReportCommander] = useState(false);
  const [bureauGroupItem, setBureauGroupItem] = useState<ItemModel[]>([]);
  const [reportMakerSelected, setReportMakerSelected] = useState<ReportMakerDto>();
  const submit = useSubmit();

  useEffect(() => {
    const bureauCode = bureauList.filter(f => f.label.includes(Code.BUREAU_CODE));

    setBureauItems(bureauCode);

    if (listData) {
      setList(listData);
    }

    const bureauGroupFilterData = [...bureauGroupList.filter(f => f.id == bureauCode[0].value).slice(2)];

    setBureauGroupItem(bureauGroupFilterData);
  }, [listData]);

  const currentSequences = useMemo(() => (list.reportMakerDtos ? list.reportMakerDtos.length : 0) + 1, [list]);

  const onChangeSequence = (sequenceType: MovementType, index: number, seq: number) => {
    if (sequenceType === MovementType.UP) {
      if (seq === 1) {
        return;
      }

      const currentIndexData = list.reportMakerDtos.find(f => f.sequence === seq);
      const beforeIndexData = list.reportMakerDtos.find(f => f.sequence === (seq - 1));

      if (currentIndexData?.sequence && beforeIndexData?.sequence) {
        currentIndexData.sequence = currentIndexData.sequence - 1;
        beforeIndexData.sequence = beforeIndexData.sequence + 1;

        list.reportMakerDtos.splice((index - 1), 2, currentIndexData, beforeIndexData);

        setList({ ...list, reportMakerDtos: list.reportMakerDtos });
        setOnEdit(true);
      }
    } else if (sequenceType === MovementType.DOWN) {
      if (seq === list.reportMakerDtos.length) {
        return;
      }

      const currentIndexData = list.reportMakerDtos.find(f => f.sequence === seq);
      const afterIndexData = list.reportMakerDtos.find(f => f.sequence === (seq + 1));

      if (currentIndexData?.sequence && afterIndexData?.sequence) {
        currentIndexData.sequence = currentIndexData.sequence + 1;
        afterIndexData.sequence = afterIndexData.sequence - 1;

        list.reportMakerDtos.splice(index, 2, afterIndexData, currentIndexData);

        setList({ ...list, reportMakerDtos: list.reportMakerDtos });
        setOnEdit(true);
      }
    }
  };

  const onCancelEdit = () => {
    submit({});

    setOnEdit(false);
  };

  const onShowModalAsync = async () => {
    if (onEdit && !await showModalCancelAsync()) {
      return;
    }

    setReportMakerSelected(undefined);
    setShowModal(!showModal);
  };

  const onRemoveAsync = async (index: number) => {
    if (!await showModalRemoveAsync()) {
      return;
    }

    const reportMakerList = [...list.reportMakerDtos];
    reportMakerList.splice(index, 1);

    const sortSequenceData: ReportMakerDto[] = reportMakerList.map((r, i) => ({
      bureauGroupId: r.bureauGroupId,
      bureauGroupName: r.bureauGroupName,
      bureauId: r.bureauId,
      bureauName: r.bureauName,
      id: r.id,
      isStopWorker: r.isStopWorker,
      reportMakerFullName: r.reportMakerFullName,
      reportMakerUserId: r.reportMakerUserId,
      sequence: i + 1,
    }));

    setList({ ...list, reportMakerDtos: sortSequenceData });
  };

  const onSubmitAsync = async () => {
    const reportCommanderList = [...list.reportCommanderDtos];
    const reportCommanderToSave: ReportCommander[] = reportCommanderList.map(r => ({
      id: r.id,
      bureauGroupId: r.bureauGroupId,
      bureauId: r.bureauId,
      commanderPositionId: r.commanderPositionId,
      isStopWorker: r.isStopWorker,
      userId: r.userId ? r.userId : r.reportCommanderUserid,
    }));

    const { status } = await service.createAsync({
      groupMonthResponsible: list.groupMonthResponsibleDtos,
      reportCommander: reportCommanderToSave,
      reportMaker: list.reportMakerDtos,
    } as CtReportMakerCreate);

    if (status === HttpStatusCode.Accepted) {
      submit({});

      setOnEdit(false);
      toast.success("บันทึกข้อมูลรายชื่อผู้จัดตั้งต้นสำเร็จ");
    }
  };

  const onChangeGroupCommissioner = (value: string | number, index: number, sequence: number) => {
    const groupMonthResponsible = list;

    switch (sequence) {
      case 1:
        groupMonthResponsible.groupMonthResponsibleDtos[index].groupCommissioner1 = value.toString();
        break;
      case 2:
        groupMonthResponsible.groupMonthResponsibleDtos[index].groupCommissioner2 = value.toString();
        break;
      case 3:
        groupMonthResponsible.groupMonthResponsibleDtos[index].groupCommissioner3 = value.toString();
        break;
      default:
        groupMonthResponsible.groupMonthResponsibleDtos[index].groupOther = value.toString();
    }

    setList(groupMonthResponsible);
  };

  const getMonthName = (index: number) => {
    const monthName = LONG_MONTHS[index - 1];

    return monthName;
  };

  const onChangeIsWorkerReportMaker = (value: boolean, index: number) => {
    const reportMakers = [...list.reportMakerDtos];
    reportMakers[index].isStopWorker = value;

    setList({ ...list, reportMakerDtos: reportMakers });
  };

  const onChangeIsWorkerReportCommander = (value: boolean, index: number) => {
    const reportCommander = [...list.reportCommanderDtos];
    reportCommander[index].isStopWorker = value;

    setList({ ...list, reportCommanderDtos: reportCommander });
  };

  const onSelectReportUser = (data: ReportMakerDto, onEdit?: boolean, index?: number) => {
    const listReportMaker = [...list.reportMakerDtos];
    const findIndex = listReportMaker.findIndex(f => f.sequence === data.sequence);

    if (findIndex >= 0) {
      if (onEdit && index) {
        listReportMaker.splice(index, 1);
      }

      listReportMaker.splice(findIndex, 0, data);

      const sortSequenceData: ReportMakerDto[] = listReportMaker.map((r, i) => ({
        bureauGroupId: r.bureauGroupId,
        bureauGroupName: r.bureauGroupName,
        bureauId: r.bureauId,
        bureauName: r.bureauName,
        id: r.id,
        isStopWorker: r.isStopWorker,
        reportMakerFullName: r.reportMakerFullName,
        reportMakerUserId: r.reportMakerUserId,
        sequence: i + 1,
      }));

      setList({ ...list, reportMakerDtos: sortSequenceData });
    } else {
      listReportMaker.push(data);

      setList({ ...list, reportMakerDtos: listReportMaker });
    }
  };

  const onSelectReportCommander = (data: ReportCommanderDto) => {
    const filterData = commanderPositionList.find(c => c.value === data.commanderPositionId);
    const listCommander = [...list.reportCommanderDtos];

    data.commanderPositionId = filterData ? filterData.id as string : '';

    listCommander.push(data);

    setList({ ...list, reportCommanderDtos: listCommander });
  };

  const getCommanderName = (id: string) => {
    const filterData = commanderPositionList.find(c => c.id === id);

    if (filterData) {
      return filterData.label;
    }

    return "";
  };

  const onRemoveReportCommanderAsync = async (index: number) => {
    if (!await showModalRemoveAsync()) {
      return;
    }

    const listReportCommander = [...list.reportCommanderDtos];
    listReportCommander.splice(index, 1);

    setList({ ...list, reportCommanderDtos: listReportCommander });
  };

  const onShowModalEdit = (selectedData: ReportMakerDto) => {
    setReportMakerSelected(selectedData);
    setShowModal(true);
  };

  const contextValue = useMemo(() => {
    return {
      onEdit,
      setOnEdit,
      currentSequences,
      list,
      bureauItems,
      bureauGroupItem,
    }
  }, [onEdit, setOnEdit, currentSequences, list, bureauItems, bureauGroupItem]);

  return (
    <Context.Provider value={contextValue}>
      <Layout title="รายชื่อผู้จัดตั้งต้น">
        <div className="d-flex justify-content-end my-2">
          <Button
            variant="primary"
            onClick={onSubmitAsync}>
            <FaRegSave className="me-2" />บันทึก
          </Button>
        </div>
        <div className="d-flex justify-content-between mt-2 my-2">
          <label>ตารางรายชื่อ ผบ./ผชบ.</label>
          <Button
            variant="primary"
            onClick={() => setShowModalReportCommander(true)}>
            <FaPlus className="me-2" />เพิ่ม
          </Button>
        </div>
        <Table hidePagination>
          <Table.Header>
            <Table.Row>
              <Table.Column minWidth={300}>ชื่อ-นามสกุล</Table.Column>
              <Table.Column minWidth={236}>กลุ่มงาน</Table.Column>
              <Table.Column minWidth={304}>ตำแหน่ง</Table.Column>
              <Table.Column minWidth={304}>หยุดปฏิบัติหน้าที่</Table.Column>
              <Table.Column minWidth={130} />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {list.reportCommanderDtos?.map((value, index) => (
              <Table.Row key={value.reportCommanderUserid}>
                <Table.Cell>{value.reportCommanderFullName}</Table.Cell>
                <Table.Cell>{value.bureauGroupName}</Table.Cell>
                <Table.Cell>{getCommanderName(value.commanderPositionId)}</Table.Cell>
                <Table.Cell>
                  <div className="d-flex fs-5 justify-content-center">
                    <Check value={value.isStopWorker} onChange={(value) => onChangeIsWorkerReportCommander(value, index)} />
                  </div>
                </Table.Cell>
                <Table.Cell center>
                  <FaTrashAlt
                    className="text-primary fs-5"
                    onClick={() => onRemoveReportCommanderAsync(index)} />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <div className="mt-3">
          <label>ตารางกลุ่มงานที่รับผิดชอบงานกรรมาธิการ</label>
          <Table hidePagination>
            <Table.Header>
              <Table.Row>
                <Table.Column minWidth={100}>เดือน</Table.Column>
                <Table.Column minWidth={200}><>สำนัก<br />กรรมาธิการ 1</></Table.Column>
                <Table.Column minWidth={200}><>สำนัก<br />กรรมาธิการ 2</></Table.Column>
                <Table.Column minWidth={200}><>สำนัก<br />กรรมาธิการ 3</></Table.Column>
                <Table.Column minWidth={200}>งานอิ่นๆ</Table.Column>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {list.groupMonthResponsibleDtos?.map((value, index) => (
                <Table.Row key={value.id}>
                  <Table.Cell center>{getMonthName(value.month)}</Table.Cell>
                  <Table.Cell>
                    <Selector
                      isHideClearButton
                      value={value.groupCommissioner1}
                      items={bureauGroupItem}
                      onChange={(value) => onChangeGroupCommissioner(value, index, 1)} />
                  </Table.Cell>
                  <Table.Cell>
                    <Selector
                      isHideClearButton
                      value={value.groupCommissioner2}
                      items={bureauGroupItem}
                      onChange={(value) => onChangeGroupCommissioner(value, index, 2)} />
                  </Table.Cell>
                  <Table.Cell>
                    <Selector
                      isHideClearButton
                      value={value.groupCommissioner3}
                      items={bureauGroupItem}
                      onChange={(value) => onChangeGroupCommissioner(value, index, 3)} />
                  </Table.Cell>
                  <Table.Cell>
                    <Selector
                      isHideClearButton
                      value={value.groupOther}
                      items={bureauGroupItem}
                      onChange={(value) => onChangeGroupCommissioner(value, index, 4)} />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
        <div className="mt-2">
          <label>ตารางรายชื่อผู้จดตั้งต้น</label>
          <div className="d-flex justify-content-end my-2 gap-2">
            <Button
              variant="outline-primary"
              onClick={onCancelEdit}
              disabled={!onEdit}>
              <FaTimes className="me-2" />ยกเลิกการจัดอันดับ
            </Button>
            <Button
              variant="primary"
              onClick={onShowModalAsync}>
              <FaPlus className="me-2" />เพิ่ม
            </Button>
          </div>
          <Table hidePagination>
            <Table.Header>
              <Table.Row>
                <Table.Column minWidth={100}>ลำดับ</Table.Column>
                <Table.Column minWidth={300}>ชื่อ-นามสกุล</Table.Column>
                <Table.Column minWidth={236}>กลุ่มงาน</Table.Column>
                <Table.Column minWidth={150}>หยุดปฏิบัติหน้าที่</Table.Column>
                <Table.Column minWidth={130} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {list.reportMakerDtos?.map((value, index) => (
                <Table.Row key={value.reportMakerUserId}>
                  <Table.Cell center>{value.sequence.toString()}</Table.Cell>
                  <Table.Cell>{value.reportMakerFullName}</Table.Cell>
                  <Table.Cell>{value.bureauGroupName}</Table.Cell>
                  <Table.Cell center>
                    <div className="d-flex justify-content-center fs-5">
                      <Check value={value.isStopWorker} onChange={(value) => onChangeIsWorkerReportMaker(value, index)} />
                    </div>
                  </Table.Cell>
                  <Table.Cell center className="fs-5">
                    <>
                      <FaChevronCircleUp className="me-2" onClick={() => onChangeSequence(MovementType.UP, index, value.sequence)} />
                      <FaChevronCircleDown className="mx-2" onClick={() => onChangeSequence(MovementType.DOWN, index, value.sequence)} />
                      {!onEdit ? <FaEdit className="ms-2" onClick={() => onShowModalEdit(value)} /> : <></>}
                      {!onEdit ? <FaTrashAlt className="ms-2" onClick={() => onRemoveAsync(index)} /> : <></>}
                    </>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
        <ModalReportMaker
          show={showModal}
          onHide={() => setShowModal(false)}
          onSelect={(data, onEdit, index) => onSelectReportUser(data, onEdit, index)}
          editSelectedData={reportMakerSelected} />
        <ModalReportCommander
          show={showModalReportCommander}
          onHide={() => setShowModalReportCommander(false)}
          onSelect={(data) => onSelectReportCommander(data)} />
      </Layout>
    </Context.Provider>
  );
}

function ModalReportCommander(props: ModalReportProps<ReportCommanderDto>) {
  const { commanderPositionList } = useLoaderData() as Loader;
  const { list, bureauItems, bureauGroupItem } = useContext(Context);
  const [reportCommander, setReportCommander] = useState<ReportCommanderDto>({} as ReportCommanderDto);
  const [userList, setUserList] = useState<ItemModel[]>([]);

  useEffect(() => {
    if (props.show) {
      setReportCommander({ ...reportCommander, bureauId: bureauItems[0].value.toString(), });
    }
  }, [props.show]);

  const onGetUserListAsync = useCallback(async (bureauId: string, bureauGroupId: string) => {
    if (bureauId && bureauGroupId) {
      const criteria: UserSelection = {
        bureauId,
        bureauGroupId,
      };

      const { data, status } = await ddlService.getUserSelectionAsync(SelectionType.SM_USER, criteria);

      if (status === HttpStatusCode.Ok) {
        setUserList(data);
      };
    }
  }, []);

  const onChangeGroup = (value: string | number) => {
    const filterItems = bureauGroupItem.find(b => b.value === value.toString());

    if (filterItems) {
      onGetUserListAsync(reportCommander.bureauId, value.toString());

      setReportCommander({
        ...reportCommander,
        bureauGroupId: filterItems?.value.toString(),
        bureauGroupName: filterItems.label,
      });

      return;
    }

    setUserList([]);
    setReportCommander({ ...reportCommander, userId: "", });
  };

  const onChangeReportUser = (value: string | number) => {
    const filterItems = userList.find(u => u.value === value);

    if (filterItems) {
      setReportCommander({
        ...reportCommander,
        userId: filterItems?.value.toString(),
        reportCommanderUserid: filterItems?.value.toString(),
        reportCommanderFullName: filterItems.label,
      });
    }
  };

  const onSubmitAsync = async () => {
    submitForm(reportCommander);

    const isValid = !reportCommander.bureauId || !reportCommander.bureauGroupId || !reportCommander.userId || !reportCommander.commanderPositionId;

    if (isValid) {
      toast.warn("กรุณากรอกข้อมูลให้ครบถ้วน");

      return;
    }

    if (list.reportCommanderDtos.some(s => s.reportCommanderUserid === reportCommander.userId)) {
      toast.warn("รายชื่อซ้ำ");

      return;
    }

    if (list.reportMakerDtos.some(r => r.reportMakerUserId === reportCommander.userId && reportCommander.commanderPositionId === Position.COMMANDER_POSITION_CODE)) {
      toast.warn('ไม่สามารถเลือกรายชื่อซ้ำกับผู้จดตั้งต้นได้');

      return;
    }

    props.onSelect(reportCommander);

    onHide();
  };

  const onHide = () => {
    setReportCommander({} as ReportCommanderDto);

    props.onHide();
  };

  return (
    <Modal
      show={props.show}
      icon={<MdAddCircleOutline />}
      title='เพิ่มรายชื่อ ผบ./ผชบ.'>
      <Row className="mt-2">
        <Col xs={12}>
          <Selector
            name="bureauId"
            label="สำนัก"
            items={bureauItems}
            value={reportCommander.bureauId}
            rule={{ required: true }}
            disabled />
        </Col>
        <Col xs={12}>
          <Selector
            name="bureauGroupId"
            label="กลุ่มงาน"
            items={bureauGroupItem}
            value={reportCommander.bureauGroupId}
            onChange={(value) => onChangeGroup(value)}
            rule={{ required: true }} />
        </Col>
        <Col xs={12}>
          <Selector
            name="reportMakerUserId"
            label="ชื่อ-นามสกุล"
            items={userList}
            value={reportCommander.userId}
            onChange={(value) => onChangeReportUser(value)}
            rule={{ required: true }} />
        </Col>
        <Col xs={12}>
          <Selector
            name="position"
            label="ตำแหน่ง"
            items={commanderPositionList}
            value={reportCommander.commanderPositionId}
            onChange={(value) => setReportCommander({ ...reportCommander, commanderPositionId: value.toString() })}
            rule={{ required: true }} />
        </Col>
      </Row>
      <div className="button d-flex flex-row gap-3 justify-content-center mt-4">
        <Button
          variant="outline-primary"
          className="w-50"
          onClick={onHide}>
          ยกเลิก
        </Button>
        <Button
          variant="primary"
          className="w-50"
          onClick={onSubmitAsync}>
          บันทึก
        </Button>
      </div>
    </Modal>
  );
}

function ModalReportMaker(props: ModalReportProps<ReportMakerDto>) {
  const { commanderPositionList } = useLoaderData() as Loader;
  const { list, bureauItems, bureauGroupItem } = useContext(Context);
  const [reportMaker, setReportMaker] = useState<ReportMakerDto>({} as ReportMakerDto);
  const [userList, setUserList] = useState<ItemModel[]>([]);

  useEffect(() => {
    if (props.show) {
      if (!props.editSelectedData) {
        setReportMaker({ ...reportMaker, sequence: list.reportMakerDtos.length + 1, bureauId: bureauItems[0].value.toString() });

        return;
      }

      setReportMaker(props.editSelectedData);

      onGetUserListAsync(props.editSelectedData.bureauId, props.editSelectedData.bureauGroupId);
    }
  }, [props.show]);

  const onGetUserListAsync = useCallback(async (bureauId: string, bureauGroupId: string) => {
    if (bureauId && bureauGroupId) {
      const criteria: UserSelection = {
        bureauId,
        bureauGroupId,
      };

      const { data, status } = await ddlService.getUserSelectionAsync(SelectionType.SM_USER, criteria);

      if (status === HttpStatusCode.Ok) {
        setUserList(data);
      };
    }
  }, []);

  const onChangeGroup = (value: string | number) => {
    setReportMaker({ ...reportMaker, reportMakerUserId: "", reportMakerFullName: "", });

    onGetUserListAsync(reportMaker.bureauId, value.toString());

    const filterItems = bureauGroupItem.find(b => b.value === value.toString());

    if (filterItems) {
      setReportMaker(prev => ({
        ...prev,
        bureauGroupId: filterItems?.value.toString(),
        bureauGroupName: filterItems.label,
      }));

      return;
    }

    setUserList([]);
  };

  const onChangeReportUser = (value: string | number) => {
    const filterItems = userList.find(u => u.value === value);

    if (filterItems) {
      setReportMaker({ ...reportMaker, reportMakerUserId: filterItems?.value.toString(), reportMakerFullName: filterItems.label });
    }
  };

  const onSubmitAsync = async () => {
    submitForm(reportMaker);

    const isValid = !reportMaker.bureauId || !reportMaker.bureauGroupId || !reportMaker.reportMakerUserId || !reportMaker.sequence;

    if (isValid) {
      toast.warn("กรุณากรอกข้อมูลให้ครบถ้วน");

      return;
    }

    if (reportMaker.sequence > list.reportMakerDtos.length + 1 && !props.editSelectedData) {
      return toast.warn(`ลำดับที่ต้องไม่เกิน ${list.reportMakerDtos.length + 1}`);
    }

    if (reportMaker.sequence > list.reportMakerDtos.length && props.editSelectedData) {
      return toast.warn(`ลำดับที่ต้องไม่เกิน ${list.reportMakerDtos.length}`);
    }

    if (list.reportMakerDtos.some(s => s.reportMakerUserId === reportMaker.reportMakerUserId) && !props.editSelectedData) {
      toast.warn("รายชื่อซ้ำ");

      return;
    }

    const CommanderPositionId = commanderPositionList.filter(f => f.value === Position.COMMANDER_POSITION_CODE);

    if (list.reportCommanderDtos.some(r => r.reportCommanderUserid === reportMaker.reportMakerUserId && r.commanderPositionId === CommanderPositionId[0].id)) {
      toast.warn("ไม่สามารถเพิ่มผู้บังคับบัญชากลุ่มงานได้");

      return;
    }

    if (props.editSelectedData) {
      const findIndex = list.reportMakerDtos.findIndex(f => f.id === reportMaker.id);

      props.onSelect(reportMaker, true, findIndex);
    } else {
      props.onSelect(reportMaker);
    }

    onHide();
  };

  const onHide = () => {
    props.onHide();
    setReportMaker({} as ReportMakerDto);
    setUserList([]);
  };

  return (
    <Modal
      size="lg"
      show={props.show}
      icon={<MdAddCircleOutline />}
      title='เพิ่มรายชื่อผู้จดรายงานการประชุม'>
      <Row className="mt-2">
        <Col sm={12} lg={6}>
          <Selector
            name="bureauId"
            label="สำนัก"
            items={bureauItems}
            value={reportMaker.bureauId}
            rule={{ required: true }}
            disabled />
        </Col>
      </Row>
      <Row>
        <Col sm={12} lg={6}>
          <Selector
            name="bureauGroupId"
            label="กลุ่มงาน"
            items={bureauGroupItem}
            value={reportMaker.bureauGroupId}
            onChange={(value) => onChangeGroup(value)}
            rule={{ required: true }} />
        </Col>
      </Row>
      <Row>
        <Col sm={12} lg={6}>
          <Selector
            name="reportMakerUserId"
            label="ชื่อ-นามสกุล"
            items={userList}
            value={reportMaker.reportMakerUserId}
            onChange={(value) => onChangeReportUser(value)}
            rule={{ required: true }} />
        </Col>
        <Col sm={12} lg={6}>
          <Input
            type="number"
            rule={{ required: true }}
            name="sequence"
            label="ลำดับที่"
            value={reportMaker.sequence}
            onChange={(value) => setReportMaker({ ...reportMaker, sequence: Number(value) })} />
        </Col>
      </Row>
      <div className="button d-flex flex-row gap-3 justify-content-center mt-4">
        <Button
          variant="outline-primary"
          className="w-50"
          onClick={onHide}>
          ยกเลิก
        </Button>
        <Button
          variant="primary"
          className="w-50"
          onClick={onSubmitAsync}>
          บันทึก
        </Button>
      </div>
    </Modal>
  );
};