import { AgendaTypeModel } from 'models';
import http from 'utils/axios';

const getAgendaTypeListAsync = async (page: number, size: number, keyword?: string) => {
  const params = {
    page,
    size,
    keyword,
  };

  return await http.get(`api/snagendatype`, { params });
};

const createAgendaTypeAsync = async (data: AgendaTypeModel) =>
  await http.post(`api/snagendatype`, data);

const updateAgendaTypeAsync = async (data: AgendaTypeModel) =>
  await http.put(`api/snagendatype/${data.id}`, data);

const deleteAgendaTypeAsync = async (id: string) =>
  await http.delete(`api/snagendatype/${id}`);

const s0102 = {
  getAgendaTypeListAsync,
  createAgendaTypeAsync,
  updateAgendaTypeAsync,
  deleteAgendaTypeAsync,
};

export default s0102;