export enum Module {
  Dashboard = 'Dashboard',
  Senate = 'Senate',
  Committee = 'Committee',
  Management = 'Management',
}

export const MODULES = [
  { label: 'วุฒิสภา', value: 'Senate' },
  { label: 'คณะกรรมาธิการ', value: 'Committee' },
];