import { CriteriaOldSystemMeeting } from 'models';
import http from 'utils/axios';

const getListAsync = async (page: number, size: number, criteria: CriteriaOldSystemMeeting) => {
  const params = {
    page,
    size,
    ...criteria,
  }

  return await http.get("api/snmeetingold", { params });
};

const exportDocumentAsync = async (id: string) => {

  return await http.get(`api/snmeetingold/${id}/download`, { responseType: 'blob' });
};

const s0304 = {
  getListAsync,
  exportDocumentAsync,
};

export default s0304;