import http from 'utils/axios';
import { SubCommitteeModel, SubCommitteeCriteria } from 'models';

const getSubCommitteeListAsync = async (page: number, size: number, criteria?: SubCommitteeCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get(`api/ctsubcommittee`, { params });
};

const createSubCommitteeAsync = async (data: SubCommitteeModel) =>
  await http.post(`api/ctsubcommittee`, data);

const updateSubCommitteeAsync = async (data: SubCommitteeModel) =>
  await http.put(`api/ctsubcommittee/${data.id}`, data);

const deleteSubCommitteeAsync = async (id: string) =>
  await http.delete(`api/ctsubcommittee/${id}`);

const syncDataAsync = async () =>
  await http.post('api/ctsubcommittee/sync-data');

const c0103 = {
  getSubCommitteeListAsync,
  createSubCommitteeAsync,
  updateSubCommitteeAsync,
  deleteSubCommitteeAsync,
  syncDataAsync,
};

export default c0103;