import { SelectionType } from "constant";
import { LoaderFunctionArgs } from "react-router-dom";
import { d02Service, selectionService } from "services";

export const d02LoaderAsync = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const committeeGroupPage = url.searchParams.get('committeeGroupPage') || 1;
  const committeeGroupSize = url.searchParams.get('committeeGroupSize') || 10;
  const committeeBureauPage = url.searchParams.get('committeeBureauPage') || 1;
  const committeeBureauSize = url.searchParams.get('committeeBureauSize') || 10;
  const senateCriteria = JSON.parse(url.searchParams.get('senateCriteria')!);
  const committeeCriteria = JSON.parse(url.searchParams.get('committeeCriteria')!);

  const [
    senateChart,
    committeeChart,
    committeeGroupDataTable,
    committeeBureauDataTable,
    snMeetingTypeItems,
    snMeetingPeriodItems,
    smBureauGroupItems,
    bureauItems,
    committeeItems,
    subCommitteeItems,
  ] = await Promise.all([
    d02Service.getPerformanceOverviewDashboardSenateChartAsync(senateCriteria),
    d02Service.getPerformanceOverviewDashboardCommitteeChartAsync(committeeCriteria),
    d02Service.getCommitteeListAsync(+committeeGroupPage, +committeeGroupSize),
    d02Service.getBureauListAsync(+committeeBureauPage, +committeeBureauSize),
    selectionService.getSelectionAsync(SelectionType.SN_MEETING_TYPE),
    selectionService.getSelectionAsync(SelectionType.SN_MEETING_PERIOD),
    selectionService.getSelectionAsync(SelectionType.SM_BUREAU_GROUP),
    selectionService.getSelectionAsync(SelectionType.SM_BUREAU),
    selectionService.getSelectionParentAsync(SelectionType.CT_COMMITTEE),
    selectionService.getSelectionAsync(SelectionType.CT_SUBCOMMITTEE),
  ]);

  return {
    snMeetingTypeItems: snMeetingTypeItems.data,
    snMeetingPeriodItems: snMeetingPeriodItems.data,
    smBureauGroupItems: smBureauGroupItems.data,
    senateChart: senateChart.data,
    bureauItems: bureauItems.data,
    committeeItems: committeeItems.data,
    subCommitteeItems: subCommitteeItems.data,
    committeeChart: committeeChart.data,
    committeeGroupDataTable: {
      rows: committeeGroupDataTable.data.rows,
      totalRows: committeeGroupDataTable.data.totalRows,
      committeeGroupPage: committeeGroupPage,
      committeeGroupSize: committeeGroupSize,
    },
    committeeBureauDataTable: {
      rows: committeeBureauDataTable.data.rows,
      totalRows: committeeBureauDataTable.data.totalRows,
      committeeBureauPage: committeeBureauPage,
      committeeBureauSize: committeeBureauSize,
    },
  }
};