import { ProgramModel } from "models";

enum Mode {
  PARENT = 'parent',
  CHILDREN = 'children',
};

const addProgram = (programAll: ProgramModel[], programsSelected: ProgramModel[], id: string, mode?: Mode) => {
  const program = programAll.find(pa => pa.id === id);

  if (!program) {
    return programsSelected;
  }

  if (!programsSelected.some(ps => ps.id === program.id)) {
    programsSelected = [...programsSelected, program];
  }

  if ((!mode || mode === Mode.PARENT) && program.parentId) {
    const programParents = addProgram(programAll, programsSelected, program.parentId, Mode.PARENT);

    programsSelected = [...programParents];
  }

  if (!mode || mode === Mode.CHILDREN) {
    const children = programAll.filter(pa => pa.parentId === program.id);

    if (children.length) {
      children.forEach(c => {
        const programChildren = addProgram(programAll, programsSelected, c.id, Mode.CHILDREN);

        programsSelected = [...programChildren];
      });
    }
  }

  return programsSelected;
};

const removeProgram = (programAll: ProgramModel[], programsSelected: ProgramModel[], id: string, mode?: Mode) => {
  const program = programAll.find(pa => pa.id === id);

  if (!program) {
    return programsSelected;
  }

  const i = programsSelected.findIndex(psId => psId.id === program.id);

  programsSelected.splice(i, 1);

  programsSelected = [...programsSelected];

  if ((!mode || mode === Mode.PARENT) && program.parentId) {
    const parent = programAll
      .find(pa => pa.id === program.parentId && programsSelected.some(ps => ps.id === pa.id));

    if (parent) {
      const canRemoveParent = programsSelected.every(ps => ps.parentId !== parent.id);

      if (canRemoveParent) {
        const programRemaining = removeProgram(programAll, programsSelected, parent.id, Mode.PARENT);

        programsSelected = [...programRemaining];
      }
    }
  }

  if (!mode || mode === Mode.CHILDREN) {
    const children = programAll
      .filter(pa => pa.parentId === program.id && programsSelected.some(ps => ps.id === pa.id));

    if (children.length) {
      children.forEach(c => {
        const programRemaining = removeProgram(programAll, programsSelected, c.id, Mode.CHILDREN);

        programsSelected = [...programRemaining];
      });
    }
  }

  return programsSelected;
};

export default {
  addProgram,
  removeProgram,
};