import { StatusMeeting } from 'constant';
import { SnCerifyingReportCriteria } from 'models';
import http from 'utils/axios';

const getSnCerifyingreportListAsync = async (page: number, size: number, criteria?: SnCerifyingReportCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get('api/sncerifyingreport', { params });
};

const getSnCerifyingreportDetailAsync = async (id: string) => {
  return await http.get(`api/sncerifyingreport/${id}`);
};

const actionSnCerifyingreportAsync = async (id: string, status: StatusMeeting) => {
  const body = {
    status,
  };

  return await http.put(`api/sncerifyingreport/actions/${id}`, body);
};

const rejectSnCerifyingreportAsync = async (id: string, comment: string) => {
  const body = {
    comment,
  };

  return await http.put(`api/sncerifyingreport/reject/${id}`, body);
};

const snCerifyingreportService = {
  getSnCerifyingreportListAsync,
  getSnCerifyingreportDetailAsync,
  actionSnCerifyingreportAsync,
  rejectSnCerifyingreportAsync,
};

export default snCerifyingreportService;