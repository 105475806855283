import { SelectionType } from "constant/selection";
import { CommitteeCriteria } from "models";
import { LoaderFunctionArgs } from "react-router-dom";
import { c0102 as serivce, selectionService as ddlService } from "services";

export const c0102LoaderAsync = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 1;
  const size = url.searchParams.get('size') || 10;
  const criteriaStringify = url.searchParams.get('criteria') || '{}';
  const criteria = JSON.parse(criteriaStringify) as CommitteeCriteria;

  const [committees, bureauList] = await Promise.all([
    serivce.getCommitteeListAsync(+page, +size, criteria),
    ddlService.getSelectionAsync(SelectionType.SM_BUREAU),
  ]);

  return {
    committeeList: {
      rows: committees.data.rows,
      totalRows: committees.data.totalRows,
      page: +page,
      size: +size,
    },
    bureauList: bureauList.data,
  };
};