import { Table as TableBT } from 'react-bootstrap';
import { Pagination } from 'components';
import { Header } from './Header';
import { Body } from './Body';
import { Children, useEffect, useState } from 'react';
import { Row } from './Row';
import { Column } from './Column';
import { Cell } from './Cell';
import { MdOutlineSearchOff } from 'react-icons/md';

interface Props {
  children: JSX.Element | JSX.Element[];
  total?: number;
  onChange?: (size: number, page: number) => void;
  className?: string;
  hidePagination?: boolean;
  hideSize?: boolean;
  fixSize?: number;
  size?: number;
  page?: number;
  striped?: boolean;
  onTop?: boolean;
}

function Table(props: Props) {
  const [hasData, setHasData] = useState<boolean>(false);

  useEffect(() => {
    if (Array.isArray(props.children)) {
      props.children.forEach(f => {
        if (f.type(f)?.type === 'tbody') {
          const row = f.props.children;

          if (checkIsTrExist(row)) {
            return;
          }
        }
      });
    }
  }, [props.children]);

  function checkIsTrExist(row: any) {
    if (row?.length >= 0) {
      let exist = false;

      row.forEach((f: any) => {
        exist = checkTableRow(f);
        if (exist) {
          return exist;
        }
      });

      return exist;
    }

    return checkTableRow(row);
  }

  function checkTableRow(row: any) {
    if (row?.type(row)?.type === 'tr') {
      setHasData(true);

      return true;
    }

    return false;
  }

  const handlerPaginationOnChange = (size: number, page: number) => {
    if (props.onChange) {
      props.onChange(size, page);
    }
  };

  function renderChild(child: JSX.Element) {
    return child.type(child)?.type === 'thead' || child.type(child)?.type === 'tbody' ? child : null;
  }

  return (
    <>
      <div className={`w-100 overflow-auto ${props.className ?? ''}`}>
        <TableBT
          striped={props.striped ?? true}
          borderless
          className={`mb-0 ${props.onTop ? "" : "align-middle"}`}>
          {Children.map(props.children, (child) => renderChild(child))}
        </TableBT>
        {!hasData &&
          <h5 className="text-center py-5"><MdOutlineSearchOff className="me-2 fs-4" />"ไม่พบข้อมูล"</h5>
        }
      </div>
      {!props.hidePagination
        ? <Pagination total={props.total || 0}
          onChange={handlerPaginationOnChange}
          hideSize={props.hideSize}
          fixSize={props.fixSize}
          size={props.size}
          page={props.page} />
        : <></>}
    </>
  );
}

Table.Header = Header;
Table.Body = Body;
Table.Row = Row;
Table.Column = Column;
Table.Cell = Cell;

export { Table };