import { Card, Input, Layout, Modal, Table } from "components";
import { Pagination, MeetingPeriodModel } from "models";
import { Dispatch, FormEvent, useState, SetStateAction, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaEdit, FaPlus, FaRedo, FaSearch, FaTrashAlt } from "react-icons/fa";
import { useLoaderData } from "react-router";
import { calculateRowNumber, fullDateTime, showModalConfirmAsync, showModalRemoveAsync, submitForm } from "utils";
import { s0103, s0103 as service } from 'services';
import { HttpStatusCode } from "axios";
import { MdAddCircleOutline, MdOutlineModeEdit } from "react-icons/md";
import { ModalType } from "constant";
import { useSubmit } from "react-router-dom";
import toast from "utils/toast";

type Loader = { meetingPeriodList: Pagination<MeetingPeriodModel>; };

interface ModalProps {
  show: boolean;
  type: ModalType;
  onHide: () => void;
  meetingPeriod: MeetingPeriodModel;
  setMeetingPeriod: Dispatch<SetStateAction<MeetingPeriodModel>>;
  onSearch: () => void;
};

export default function S0103() {
  const { meetingPeriodList } = useLoaderData() as Loader;
  const [meetingTypeSelected, setMeetingTypeSelected] = useState<MeetingPeriodModel>({} as MeetingPeriodModel);
  const [keyword, setKeyword] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState<ModalType>(ModalType.ADD);
  const submit = useSubmit();

  useEffect(() => {
    if (meetingPeriodList) {
      setPage(meetingPeriodList.page);
      setSize(meetingPeriodList.size);
    }

  }, [meetingPeriodList]);

  const onSearch = (size: number, page: number) => {
    submit({
      page: page.toString(),
      size: size.toString(),
      keyword,
    });
  };

  const onChangePageSize = (size: number, page: number) => {
    setPage(page);
    setSize(size);

    submit({
      page,
      size,
    });
  };

  const onClear = () => {
    setKeyword("");

    submit({});
  };

  const handlerShowModal = (type: ModalType, data?: MeetingPeriodModel) => {
    setMeetingTypeSelected(data || {} as MeetingPeriodModel);
    setModalType(type);
    setShowModal(true);
  };

  const onRemoveAsync = async (id: string) => {
    const isConfirm = await showModalRemoveAsync();

    if (isConfirm) {
      const { status } = await service.deleteMeetingPeriodAsync(id);

      switch (status) {
        case HttpStatusCode.NoContent:
          onSearch(meetingPeriodList.size, meetingPeriodList.page);

          toast.success("ลบรายการสมัยการประชุมสำเร็จ");

          break;
        case HttpStatusCode.Conflict:
          toast.warn("ไม่สามารถลบรายการได้ เนื่องจากข้อมูลมีการใช้งานอยู่");

          break;
        case HttpStatusCode.NotFound:
          toast.error("ไม่พบข้อมูลกรุณาลองใหม่อีกครั้ง");

          break;
      }
    }
  };

  const syncMeetingPeriodDataAsync = async () => {
    if (!await showModalConfirmAsync('ยืนยันการซิงค์ข้อมูล')) {
      return;
    }

    const { status } = await s0103.syncMeetingPeriodDataAsync();

    if (status === HttpStatusCode.Accepted) {
      toast.success("ซิงค์ข้อมูลสำเร็จ");

      submit({
        page: meetingPeriodList.page.toString(),
        size: meetingPeriodList.size.toString(),
      });
    }
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSearch(meetingPeriodList.size, meetingPeriodList.page);
  };

  return (
    <>
      <Layout title="สมัยการประชุม">
        <Card>
          <Form onSubmit={onSubmit}>
            <Row>
              <Col sm={12} lg={4}>
                <div className="criteria-flex">
                  <div className="control">
                    <Input
                      label="ค้นหา"
                      placeholder="สมัยการประชุม"
                      value={keyword}
                      onChange={(value) => setKeyword(value)} />
                  </div>
                  <div className="button">
                    <Button
                      variant="primary"
                      type="submit">
                      <FaSearch className="me-2" />ค้นหา
                    </Button>
                    <Button
                      variant="outline-primary"
                      onClick={onClear}>
                      <FaTrashAlt className="me-2" />ล้างค่า
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Card>
        <div className="my-3 d-flex justify-content-end gap-2">
          <Button
            variant="outline-primary"
            type="button"
            onClick={syncMeetingPeriodDataAsync}>
            <FaRedo className="me-2" />ซิงค์ข้อมูล
          </Button>
          <Button
            variant="primary"
            onClick={() => handlerShowModal(ModalType.ADD)}>
            <FaPlus className="me-2" />เพิ่ม
          </Button>
        </div>
        <Table
          total={meetingPeriodList.totalRows}
          page={page}
          size={size}
          onChange={onChangePageSize}>
          <Table.Header>
            <Table.Row>
              <Table.Column minWidth={100}>ลำดับ</Table.Column>
              <Table.Column minWidth={150}>รหัส</Table.Column>
              <Table.Column minWidth={250}>สมัยการประชุม</Table.Column>
              <Table.Column minWidth={100}>ผู้แก้ไขข้อมูล</Table.Column>
              <Table.Column minWidth={100}>วันที่แก้ไขข้อมูล</Table.Column>
              <Table.Column minWidth={100} />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {meetingPeriodList.rows?.map((value, index) => (
              <Table.Row key={value.id}>
                <Table.Cell center>{calculateRowNumber(index, meetingPeriodList.page, meetingPeriodList.size).toString()}</Table.Cell>
                <Table.Cell center>{value.code}</Table.Cell>
                <Table.Cell center>{value.name}</Table.Cell>
                <Table.Cell>{value.updateByUserFullName}</Table.Cell>
                <Table.Cell center>{fullDateTime(value.updateDate)}</Table.Cell>
                <Table.Cell center>
                  <FaEdit className='me-2 fs-5' onClick={() => handlerShowModal(ModalType.EDIT, value)} />
                  <FaTrashAlt className="ms-2 fs-5" onClick={() => onRemoveAsync(value.id)} />
                </Table.Cell>
              </Table.Row>))}
          </Table.Body>
        </Table>
      </Layout>
      <MeetingPeriodModal
        show={showModal}
        type={modalType}
        onHide={() => setShowModal(!showModal)}
        meetingPeriod={meetingTypeSelected}
        setMeetingPeriod={setMeetingTypeSelected}
        onSearch={() => onSearch(meetingPeriodList.size, meetingPeriodList.page)}
      />
    </>
  );
}

function MeetingPeriodModal(props: ModalProps) {
  const handleOnSubmitAsync = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    submitForm(props.meetingPeriod);

    const isValid = !props.meetingPeriod.name;

    if (isValid) {
      toast.warn("กรุณากรอกข้อมูลให้ครบถ้วน");

      return;
    }

    if (props.meetingPeriod.id) {
      const { status } = await service.updateMeetingPeriodAsync(props.meetingPeriod);

      if (status === HttpStatusCode.Accepted) {
        props.onSearch();

        toast.success("แก้ไขสมัยการประชุมสำเร็จ");
      }
    } else {
      const { status } = await service.createMeetingPeriodAsync(props.meetingPeriod);

      if (status === HttpStatusCode.Created) {
        props.onSearch();

        toast.success("เพิ่มรายการสมัยการประชุมสำเร็จ");
      }
    }

    onHideModal();
  };

  const onHideModal = () => {
    props.onHide();
  };

  return (
    <Modal
      show={props.show}
      icon={props.type === ModalType.ADD ? <MdAddCircleOutline /> : <MdOutlineModeEdit />}
      title={props.type === ModalType.ADD ? `เพิ่มสมัยการประชุม` : "แก้ไขสมัยการประชุม"}>
      <Form onSubmit={handleOnSubmitAsync}>
        <div className="mt-3">
          <Input
            name="code"
            label="รหัส"
            value={props.meetingPeriod.code}
            disabled />
          <Input
            name="name"
            label="สมัยการประชุม"
            value={props.meetingPeriod.name}
            onChange={(value) => props.setMeetingPeriod({ ...props.meetingPeriod, name: value })}
            rule={{ required: true, maxLength: 100 }} />
          <div className="button d-flex flex-row gap-3 justify-content-center mt-4">
            <Button
              variant="outline-primary"
              className="w-50"
              type="button"
              onClick={onHideModal}>
              ยกเลิก
            </Button>
            <Button
              variant="primary"
              className="w-50"
              type="submit">
              บันทึก
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
}