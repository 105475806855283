import { isExpired, decodeToken } from "react-jwt";
import { authenticationService } from "services";
import { Cookie } from "constant";
import cookie from "./cookie";
import loading from "./loading";

function isAuthorized() {
  const accessToken = cookie.get(Cookie.ACCESS_TOKEN);

  if (!accessToken || isExpired(accessToken)) {
    cookie.remove(Cookie.ACCESS_TOKEN);
    cookie.remove(Cookie.MODULE);

    return false;
  }

  return true;
}

async function signOutAsync() {
  loading.showLoading('sign-out');

  const accessToken = cookie.get(Cookie.ACCESS_TOKEN);

  if (accessToken) {
    const decode = decodeToken(cookie.get(Cookie.ACCESS_TOKEN)) as { nameid: string };

    cookie.remove(Cookie.ACCESS_TOKEN);
    cookie.remove(Cookie.MODULE);

    await authenticationService.signOutAsync(decode.nameid);
  }

  setTimeout(() => window.location.href = '/web/sign-in', 1500);
}

export {
  isAuthorized,
  signOutAsync,
}