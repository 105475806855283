import { Card, Input, InputTime, Layout, Selector, Status, StatusType, Table } from "components";
import { HttpStatusCode, StatusMeeting, statusChapterItems, statusMeetingItems } from "constant";
import { ItemModel, SnMakeDocumentDetailHeader, SnMakeDocumentMeetingCriteria, SnMakeDocumentMeetingList } from "models";
import { FormEvent, useEffect, useState } from "react";
import { Button, Row, Col, Form, Popover, OverlayTrigger } from "react-bootstrap";
import { FaAngleLeft, FaRegCheckCircle, FaRegKeyboard, FaSearch, FaTrashAlt } from "react-icons/fa";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { s0204 } from "services";
import { formatDateTh, formatTimeToInput } from "utils";
import toast from "utils/toast";
import { usePopperTooltip } from 'react-popper-tooltip';


type Loader = {
  snMakeDocumentMeetingList: SnMakeDocumentMeetingList[];
  snMakeDocumentMeetingRows: number;
  snAgendaTypeList: ItemModel[];
  headerData: SnMakeDocumentDetailHeader;
};

enum onChangeTimeType {
  START_DATE,
  END_DATE,
};

export default function S0204Detail() {
  const { snMakeDocumentMeetingList, snMakeDocumentMeetingRows, snAgendaTypeList, headerData } = useLoaderData() as Loader;
  const [meetingList, setMeetingList] = useState<SnMakeDocumentMeetingList[]>([]);
  const [snMakeDocumentMeetingCriteria, setSnMakeDocumentMeetingCriteria] = useState<SnMakeDocumentMeetingCriteria>({} as SnMakeDocumentMeetingCriteria);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const { id } = useParams();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (snMakeDocumentMeetingList && snMakeDocumentMeetingRows) {
      setMeetingList(snMakeDocumentMeetingList);
      setTotalRows(snMakeDocumentMeetingRows);
    }
  }, [snMakeDocumentMeetingRows, snMakeDocumentMeetingList]);

  const convertStatusToText = (status: string) => {
    const findStatus = statusMeetingItems.find(s => s.value === status)?.label;

    return findStatus;
  };

  const onChangeTime = (prop: keyof SnMakeDocumentMeetingCriteria, time?: Date) => {
    setSnMakeDocumentMeetingCriteria({ ...snMakeDocumentMeetingCriteria, [prop]: time });
  };

  const onChangeTimeTable = (type: onChangeTimeType, index: number, time?: Date) => {
    switch (type) {
      case onChangeTimeType.START_DATE:
        meetingList[index].startDate = time;
        break;
      case onChangeTimeType.END_DATE:
        meetingList[index].endDate = time;
        break;
    };

    setMeetingList([...meetingList]);
  };

  const onChangeCriteria = (value: string | number, prop: string) => {
    setSnMakeDocumentMeetingCriteria({ ...snMakeDocumentMeetingCriteria, [prop]: value });
  };

  const getMeetingListAsync = async (page: number, size: number, criteria: SnMakeDocumentMeetingCriteria) => {
    if (id) {
      const { data, status } = await s0204.getSnMakeDocumentMeetingListAsync(id, page, size, criteria);

      if (status === HttpStatusCode.OK) {
        setMeetingList(data.rows);
        setTotalRows(data.totalRows);
      }
    }
  };

  const onChangePageSize = (page: number, size: number) => {
    setPage(page);
    setSize(size);

    getMeetingListAsync(page, size, snMakeDocumentMeetingCriteria);
  };

  const onSearch = () => {
    getMeetingListAsync(page, size, snMakeDocumentMeetingCriteria);
  };

  const clearCriteria = () => {
    const clearCriteriaData = {} as SnMakeDocumentMeetingCriteria;

    setSnMakeDocumentMeetingCriteria(clearCriteriaData);
    getMeetingListAsync(page, size, clearCriteriaData);
  };

  const navigateDocument = (data: SnMakeDocumentMeetingList) => {
    if (!data.dutyOfficerFullName) {
      toast.warn('กรุณาเพิ่มผู้จดรายงานการประชุม');

      return;
    }

    navigate(`document/${data.id}`);
  };

  const onSubmitChangeTimeTableAsync = async () => {
    if (!meetingList.every(s => s.startDate) || !meetingList.every(s => s.endDate)) {
      return toast.warn("กรุณากรอกเวลาให้ครบถ้วน");
    }

    if (id) {
      const { status } = await s0204.updateTimeofChapterListAsync(id, meetingList);

      if (status === HttpStatusCode.ACCEPTED) {
        toast.success("อัพเดทข้อมูลสำเร็จ");
      }

      onSearch();
    }
  };

  const onSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSearch();
  };

  function PopOverUI(props: { value: string }) {
    const popover = (
      <Popover>
        <Popover.Body className="overflow-scroll-popover">
          <span className="fs-4">{props.value}</span>
        </Popover.Body>
      </Popover>
    );

    return (
      <OverlayTrigger
        overlay={popover}
        trigger={'click'}
        placement='bottom'>
          {/* Inline Style for other page use text-ellipsis */}
        <p className="text-ellipsis cursor-pointer" style={{ maxWidth: 250 }}>
          {props.value}
        </p>
      </OverlayTrigger>
    );
  };

  return (
    <Layout title="จัดทำ/แก้ไข รายงานการประชุม">
      <div className="d-flex my-3">
        <Button
          variant="outline-primary"
          type="button"
          onClick={() => navigate('/s0204')}>
          <FaAngleLeft className="me-2" />ย้อนกลับ
        </Button>
      </div>
      <div>
        <Card>
          <label><b>ข้อมูลการประชุม</b></label>
          <div className="mt-3 mx-4">
            <Row>
              <Col sm={6} md={4} xxl={3}>
                <p>ประเภทการประชุม : {headerData.meetingTypeName}</p>
              </Col>
              <Col sm={6} md={4} xxl={3}>
                <p>ครั้งที่ : {headerData.time}</p>
              </Col>
              <Col sm={6} md={4} xxl={3}>
                <p>สมัยการประชุม : {headerData.meetingPeriodName}</p>
              </Col>
              <Col sm={6} md={4} xxl={3}>
                <p>ชื่อการประชุม : {headerData.meetingName}</p>
              </Col>
              <Col sm={6} md={4} xxl={3}>
                <p>ณ ห้องประชุม : {headerData.meetingRoomName}</p>
              </Col>
              <Col sm={6} md={4} xxl={3}>
                <p>วันที่ประชุม : {formatDateTh(headerData.startDate)}</p>
              </Col>
              <Col sm={6} md={4} xxl={3}>
                <p>เวลา : {`${formatTimeToInput(headerData.startTime)}-${formatTimeToInput(headerData.endTime)}`}</p>
              </Col>
              <Col sm={6} md={4} xxl={3}>
                <p>กลุ่มงานที่รับผิดชอบ : {headerData.bureauGroups}</p>
              </Col>
              <Col sm={6} md={4} xxl={3}>
                <p>สถานะ : {convertStatusToText(headerData.status)}</p>
              </Col>
            </Row>
          </div>
        </Card>
      </div>
      <div className="mt-3">
        <Card>
          <label>ค้นหา</label>
          <Form onSubmit={onSubmitSearch}>
            <Row className="criteria">
              <Col sm={6} md={4} lg={4} xl={3}>
                <Input
                  type="number"
                  label="ตอนที่"
                  value={snMakeDocumentMeetingCriteria.chapter}
                  onChange={(value) => onChangeCriteria(value, 'chapter')} />
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <InputTime
                  name="start-time"
                  label="ช่วงเวลาเริ่มต้น"
                  value={snMakeDocumentMeetingCriteria.startTime}
                  onChange={(value) => onChangeTime('startTime', value)} />
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <InputTime
                  name="end-time"
                  label="ช่วงเวลาสิ้นสุด"
                  value={snMakeDocumentMeetingCriteria.endTime}
                  onChange={(value) => onChangeTime('endTime', value)} />
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <Selector
                  label="วาระการประชุม"
                  items={snAgendaTypeList}
                  value={snMakeDocumentMeetingCriteria.meetingAgendaId}
                  onChange={(value) => onChangeCriteria(value, 'meetingAgendaId')} />
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <Input
                  label="ผู้อภิปราย"
                  value={snMakeDocumentMeetingCriteria.meetingMemberName}
                  onChange={(value) => onChangeCriteria(value, 'meetingMemberName')} />
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <Input
                  label="ผู้จดรายงานการประชุม"
                  value={snMakeDocumentMeetingCriteria.dutyOfficerName}
                  onChange={(value) => onChangeCriteria(value, 'dutyOfficerName')} />
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <Selector
                  label="สถานะ"
                  items={statusChapterItems}
                  value={snMakeDocumentMeetingCriteria.status}
                  onChange={(value) => onChangeCriteria(value, 'status')} />
              </Col>
              <Col
                sm={6} md={4} lg={4} xl={3}
                className="button d-flex gap-2">
              </Col>
            </Row>
            <div className="d-flex gap-3 justify-content-start mt-2">
              <Button
                variant="primary"
                type="submit">
                <FaSearch className="me-2" />ค้นหา
              </Button>
              <Button
                variant="outline-primary"
                type="button"
                onClick={clearCriteria}>
                <FaTrashAlt className="me-2" />ล้างค่า
              </Button>
            </div>
          </Form>
        </Card>
      </div>
      <Row className="mt-3">
        <Col sm={12} xxl={12} className="d-flex justify-content-end mb-3">
          <Button
            variant="primary"
            onClick={onSubmitChangeTimeTableAsync}>
            <FaRegCheckCircle className="me-2" />บันทึก
          </Button>
        </Col>
        <Col>
          <div className="table-relative-fix">
            <div className="table-scroll">
              <Table total={totalRows} onChange={(size, page) => onChangePageSize(page, size)}>
                <Table.Header>
                  <Table.Row>
                    <Table.Column minWidth={100}>ตอนที่</Table.Column>
                    <Table.Column minWidth={200}>เวลาเริ่มต้น</Table.Column>
                    <Table.Column minWidth={200}>เวลาสิ้นสุด</Table.Column>
                    <Table.Column minWidth={200}>กลุ่มงาน</Table.Column>
                    <Table.Column minWidth={250}>ผู้จดรายงานการประชุม</Table.Column>
                    <Table.Column minWidth={250}>ผู้ตรวจทาน</Table.Column>
                    <Table.Column minWidth={200}>สถานะ</Table.Column>
                    <Table.Column minWidth={100}>วาระการประชุม</Table.Column>
                    <Table.Column minWidth={250}>ผู้อภิปราย</Table.Column>
                    <Table.Column minWidth={200}>แก้ไขครั้งล่าสุด</Table.Column>
                    <Table.Column minWidth={200}>เวอร์ชั่น</Table.Column>
                    <Table.Column minWidth={200} className="fix-col" />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {meetingList?.map((data, index) => (
                    <Table.Row key={data.id}>
                      <Table.Cell center>{data.chapter}</Table.Cell>
                      <Table.Cell>
                        <InputTime
                          name="startDate"
                          value={data.startDate}
                          onChange={(value) => onChangeTimeTable(onChangeTimeType.START_DATE, index, value)}
                          rule={{ required: true }} />
                      </Table.Cell>
                      <Table.Cell>
                        <InputTime
                          name="endDate"
                          value={data.endDate}
                          onChange={(value) => onChangeTimeTable(onChangeTimeType.END_DATE, index, value)}
                          rule={{ required: true }} />
                      </Table.Cell>
                      <Table.Cell center>{data.bureauGroupName}</Table.Cell>
                      <Table.Cell>{data.dutyOfficerFullName}</Table.Cell>
                      <Table.Cell>{data.reviewerFullName}</Table.Cell>
                      <Table.Cell center><Status type={StatusType.CHAPTER_STATUS} value={data.status} /></Table.Cell>
                      <Table.Cell center>
                        <PopOverUI value={data.agendaTitle} />
                      </Table.Cell>
                      <Table.Cell>{data.debater}</Table.Cell>
                      <Table.Cell center>{data.updateDate ? formatDateTh(data.updateDate) : ""}</Table.Cell>
                      <Table.Cell center>{data.versionDocument}</Table.Cell>
                      <Table.Cell center className="fix-col" >
                        <Button
                          disabled={data.status === StatusMeeting.RECORD}
                          variant="outline-primary"
                          type="button"
                          onClick={() => navigateDocument(data)}>
                          <FaRegKeyboard className="me-2" />จัดทำรายงาน
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}