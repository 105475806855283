import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight } from 'react-icons/fa';

interface Props {
  total: number;
  onChange?: (size: number, page: number) => void;
  hideSize?: boolean;
  fixSize?: number;
  size?: number;
  page?: number;
}

const MAX_ROW = [
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

export function Pagination(props: Props) {
  const [pageActive, setPageActive] = useState<number>(1);
  const [pageAll, setPageAll] = useState(0);
  const [maxRowPerPage, setMaxRowPerPage] = useState<number>(10);
  const [mounted, setMounted] = useState<boolean>(false);

  useEffect(() => {
    setMaxRowPerPage((props.fixSize || props.size) ?? 10);
  }, [props.size]);

  useEffect(() => {
    setPageActive(props.page ?? 1);
  }, [props.page]);

  useEffect(() => {
    if (mounted) {
      if (props.onChange) {
        props.onChange(maxRowPerPage, pageActive);
      }
    }

    return () => {
      setMounted(true);
    };
  }, [maxRowPerPage, pageActive, mounted]);

  const first = useCallback(() => {
    if (pageActive > 1) {
      setPageActive(1);
    }
  }, [pageActive]);

  const prev = useCallback(() => {
    if (pageActive > 1) {
      const page = pageActive === 1 ? 1 : (pageActive - 1);

      setPageActive(page);
    }
  }, [pageActive]);

  const go = useCallback((page: number) => {
    if (page !== pageActive) {
      setPageActive(page);
    }
  }, [pageActive]);

  const next = useCallback(() => {
    if (pageActive < pageAll) {
      const page = pageActive === pageAll ? pageAll : (pageActive + 1);

      setPageActive(page);
    }
  }, [pageActive, pageAll]);

  const last = useCallback(() => {
    if (pageActive < pageAll) {
      setPageActive(pageAll);
    }
  }, [pageActive, pageAll]);

  const pageButton = useCallback((start: number, end: number) => {
    const templates: JSX.Element[] = [];

    for (let i = start; i <= end; i++) {
      templates.push(
        <Button
          variant='outline-primary'
          key={i}
          className={`d-flex justify-content-center ${pageActive === i ? 'active' : ''}`}
          onClick={() => go(i)}>
          {i}
        </Button>
      );
    }

    return templates;
  }, [go, pageActive]);

  const pages = useMemo(() => {
    const sum = props.total / maxRowPerPage;
    const split = sum.toString().split('.');
    let pageAll = split.length === 1 ? Number(split[0]) : Number(split[0]) + 1;

    if (pageAll === 0) {
      pageAll = 1;
    }

    setPageAll(pageAll);

    if (pageAll === 1) {
      setPageActive(1);
    }

    if (pageActive <= 3) {
      const end = pageAll > 5 ? 5 : pageAll;

      return pageButton(1, end);
    }
    const end = pageAll;

    if (end - pageActive > 2) {
      return pageButton(pageActive - 2, pageActive + 2);
    }

    return pageButton(end - 3, end);
  }, [pageActive, maxRowPerPage, pageButton, props.total]);

  return (
    <div className='pagination d-flex flex-column flex-xl-row align-items-center'>
      {!props.hideSize ?
        <div className='max-row mt-3 w-100 d-flex flex-row flex-fill justify-content-center justify-content-xl-start align-items-center'>
          <p className='mb-0'>แสดง</p>
          <Form.Select
            className='mx-2'
            name='max-row'
            value={maxRowPerPage}
            onChange={event => setMaxRowPerPage(Number(event.target.value))}>
            {MAX_ROW.map((data) =>
              (<option key={data.value} value={data.value}>{data.label}</option>))}
          </Form.Select>
          <p className='mb-0'>รายการ จากทั้งหมด {props.total} รายการ</p>
        </div> :
        <p className='mt-3 mb-0 w-100'>ทั้งหมด {props.total} รายการ</p>}
      <div className='page mt-3 w-100 d-flex flex-row flex-fill justify-content-center justify-content-xl-end gap-2'>
        <Button
          className='d-flex align-items-center'
          variant='outline-primary'
          onClick={first}>
          <FaAngleDoubleLeft />
        </Button>
        <Button
          className='d-flex align-items-center'
          variant='outline-primary'
          onClick={prev}>
          <FaAngleLeft />
        </Button>
        {pages}
        <Button
          className='d-flex align-items-center'
          variant='outline-primary'
          onClick={next}>
          <FaAngleRight />
        </Button>
        <Button
          className='d-flex align-items-center'
          variant='outline-primary'
          onClick={last}>
          <FaAngleDoubleRight />
        </Button>
      </div>
    </div>
  );
}