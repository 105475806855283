import { Card, InputDate, Layout, Modal, Selector, Status, StatusType, Table, Tabs } from "components";
import { HttpStatusCode, Module, SelectionType } from "constant";
import { CommanderDataList, DataTableByUser, DataTableByUserDetail, DutyDataList, IndividualCommitteeDataList, IndividualCriteria, IndividualSenateDataList, IndividualSummary, ItemModel, Leave, Pagination, PaginationIndividualDashBoard } from "models";
import { Dispatch, FormEvent, SetStateAction, createContext, useContext, useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Row, Tab } from "react-bootstrap";
import { FaCalendarMinus, FaEdit, FaFilter, FaRegFilePdf, FaTrashAlt, FaRegFileExcel, FaTimes } from "react-icons/fa";
import { RiTimeLine } from "react-icons/ri";
import { TbList, TbListCheck } from "react-icons/tb";
import { useLoaderData, useNavigate } from "react-router-dom";
import { d03Service, selectionService } from "services";
import { exportFileExcel, exportPdfByte, formatDateTh, showModalConfirmAsync, submitForm, thaiFullFormatData, dashboardThaiDate, fullDateTime } from "utils";
import toast from "utils/toast";

export enum DetailDataTableType {
  Senate,
  Committee,
};

type Loader = {
  bureauGroupItems: ItemModel[];
  dailyStatusItems: ItemModel[];
  leaveListData: Pagination<Leave>;
  dutyListData: Pagination<DutyDataList>;
  commanderListData: Pagination<CommanderDataList>;
  summaryLoaderData: IndividualSummary;
  module: string;
  senateLoaderData: PaginationIndividualDashBoard<IndividualSenateDataList>;
  individualSenateLoaderData: PaginationIndividualDashBoard<DataTableByUser>;
  committeeLoaderData: PaginationIndividualDashBoard<IndividualCommitteeDataList>;
  individualCommitteeLoaderData: PaginationIndividualDashBoard<DataTableByUser>;
};

type IndividualContext = {
  criteria: IndividualCriteria;
  setCriteria: Dispatch<SetStateAction<IndividualCriteria>>;
  onSubmitAsync: (event: FormEvent<HTMLFormElement>) => Promise<void>;
  onClearAsync: () => Promise<void>;
  currentModule: string;
  setCurrentModule: Dispatch<SetStateAction<string>>;
  summaryData: IndividualSummary;
  senateData: PaginationIndividualDashBoard<IndividualSenateDataList>;
  setSenateData: Dispatch<SetStateAction<PaginationIndividualDashBoard<IndividualSenateDataList>>>;
  individualSenateData: PaginationIndividualDashBoard<DataTableByUser>;
  setIndividualSenateData: Dispatch<SetStateAction<PaginationIndividualDashBoard<DataTableByUser>>>;
  committeeData: PaginationIndividualDashBoard<IndividualCommitteeDataList>;
  setCommitteeData: Dispatch<SetStateAction<PaginationIndividualDashBoard<IndividualCommitteeDataList>>>;
  individualComitteeData: PaginationIndividualDashBoard<DataTableByUser>;
  setIndividualCommitteeData: Dispatch<SetStateAction<PaginationIndividualDashBoard<DataTableByUser>>>;
  dutyData: Pagination<DutyDataList>;
  setDutyData: Dispatch<SetStateAction<Pagination<DutyDataList>>>;
  commanderData: Pagination<CommanderDataList>;
  setCommanderData: Dispatch<SetStateAction<Pagination<CommanderDataList>>>;
};

const ModuleTabs = [
  { label: 'การประชุมวุฒิสภา', value: Module.Senate },
  { label: 'คณะกรรมาธิการ', value: Module.Committee },
];

const DailyTabs = [
  { label: 'ผู้จด', value: 1 },
  { label: 'ผบ. และ ผช.', value: 2 },
];

const Context = createContext({} as IndividualContext);

export default function D03() {
  const { bureauGroupItems, module, summaryLoaderData, senateLoaderData, individualSenateLoaderData, committeeLoaderData, individualCommitteeLoaderData, dutyListData, commanderListData } = useLoaderData() as Loader;
  const [criteria, setCriteria] = useState<IndividualCriteria>({} as IndividualCriteria);
  const [userList, setUserList] = useState<ItemModel[]>([]);
  const [currentModule, setCurrentModule] = useState<string>(module);
  const [summaryData, setSummaryData] = useState<IndividualSummary>(summaryLoaderData);
  const [senateData, setSenateData] = useState<PaginationIndividualDashBoard<IndividualSenateDataList>>(senateLoaderData);
  const [individualSenateData, setIndividualSenateData] = useState<PaginationIndividualDashBoard<DataTableByUser>>(individualSenateLoaderData);
  const [committeeData, setCommitteeData] = useState<PaginationIndividualDashBoard<IndividualCommitteeDataList>>(committeeLoaderData);
  const [individualComitteeData, setIndividualCommitteeData] = useState<PaginationIndividualDashBoard<DataTableByUser>>(individualCommitteeLoaderData);
  const [dutyData, setDutyData] = useState<Pagination<DutyDataList>>(dutyListData);
  const [commanderData, setCommanderData] = useState<Pagination<CommanderDataList>>(commanderListData);
  const currentDate = useState<Date>(new Date());

  const onChangeCriteriaAsync = async (prop: keyof IndividualCriteria, value?: string | number | Date) => {
    setCriteria({ ...criteria, [prop]: value });

    if (prop === 'bureauGroupId' && value) {
      setUserList(await getUserByBureauGroupAsync(value.toString()));
    }
  };

  const onSubmitAsync = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (criteria.startDate > criteria.endDate) {
      return toast.warn('วันที่เริ่มต้นมากกว่าวันที่สิ้นสุด');
    }

    setSummaryData(await getSummaryDataAsync(criteria, currentModule));
    setSenateData(await getSenateDataAsync(senateData.senatePage, senateData.senateSize, criteria));
    setIndividualSenateData(await getIndividualSenateDataAsync(individualSenateData.senateByUserPage, individualSenateData.senateByUserSize, criteria));
    setCommitteeData(await getCommitteeDataAsync(committeeData.committeePage, committeeData.committeeSize, criteria));
    setIndividualCommitteeData(await getIndividualCommitteeDataAsync(individualComitteeData.committeeByUserPage, individualComitteeData.committeeByUserSize, criteria));

  };

  const onClearAsync = async () => {
    setCriteria({} as IndividualCriteria);
    setUserList([]);

    setSummaryData(await getSummaryDataAsync({} as IndividualCriteria, currentModule));
    setSenateData(await getSenateDataAsync(1, 10, {} as IndividualCriteria));
    setIndividualSenateData(await getIndividualSenateDataAsync(1, 10, {} as IndividualCriteria));
    setCommitteeData(await getCommitteeDataAsync(1, 10, {} as IndividualCriteria));
    setIndividualCommitteeData(await getIndividualCommitteeDataAsync(1, 10, {} as IndividualCriteria));
  };

  const onSelectedModuleAsync = async (value: string | number | null) => {
    if (value) {
      setCurrentModule(value.toString());
      setSummaryData(await getSummaryDataAsync(criteria, value.toString()));
      setDutyData(await getDutyDataAsync(10, 1, value.toString(), {} as IndividualCriteria));
      setCommanderData(await getCommanderDataAsync(1, 10, value.toString(), criteria));
    };
  }

  const contextValue = useMemo(() => {
    return {
      criteria,
      setCriteria,
      onSubmitAsync,
      onClearAsync,
      currentModule,
      setCurrentModule,
      summaryData,
      senateData,
      setSenateData,
      individualSenateData,
      setIndividualSenateData,
      committeeData,
      setCommitteeData,
      individualComitteeData,
      setIndividualCommitteeData,
      dutyData,
      setDutyData,
      commanderData,
      setCommanderData,
    }
  }, [
    criteria,
    setCriteria,
    onSubmitAsync,
    onClearAsync,
    currentModule,
    setCurrentModule,
    summaryData,
    senateData,
    setSenateData,
    individualSenateData,
    setIndividualSenateData,
    committeeData,
    setCommitteeData,
    individualComitteeData,
    setIndividualCommitteeData,
    dutyData,
    setDutyData,
    commanderData,
    commanderData]);

  return (
    <Context.Provider value={contextValue}>
      <Layout title="ภาพรวมสถิติการปฏิบัติงานรายบุคคล">
        <Card title="กรองข้อมูล">
          <Form onSubmit={onSubmitAsync}>
            <Row>
              <Col sm={6} md={3}>
                <Selector
                  label="กลุ่มงานชวเลข"
                  items={bureauGroupItems}
                  value={criteria.bureauGroupId}
                  onChange={(value) => onChangeCriteriaAsync('bureauGroupId', value)} />
              </Col>
              <Col sm={6} md={3}>
                <Selector
                  label="ชื่อ-นามสกุล"
                  items={userList}
                  value={criteria.userId}
                  onChange={(value) => onChangeCriteriaAsync('userId', value)} />
              </Col>
              <Col sm={6} md={3}>
                <InputDate
                  label="วันที่เริ่มต้น"
                  value={criteria.startDate}
                  onChange={(value) => onChangeCriteriaAsync('startDate', value)} />
              </Col>
              <Col sm={6} md={3}>
                <InputDate
                  label="วันที่สิ้นสุด"
                  value={criteria.endDate}
                  onChange={(value) => onChangeCriteriaAsync('endDate', value)} />
              </Col>
            </Row>
            <div className="d-flex justify-content-start gap-2">
              <Button
                type="submit"
                className="button d-flex align-items-center gap-2">
                <FaFilter />กรอง
              </Button>
              <Button
                variant="outline-primary"
                onClick={onClearAsync}>
                <FaTrashAlt className="me-2" />ล้าง
              </Button>
            </div>
          </Form>
        </Card>
        <LeaveListComponent />
        <SummaryComponent />
        <Card className="my-3">
          <label
            className="text-decoration-underline fs-4 mb-2">
            ตารางสถิติการจัดทำรายงานการประชุมรายบุคคล
          </label>
          <Tabs
            items={ModuleTabs}
            defaultActiveKey={currentModule}
            onSelect={onSelectedModuleAsync}>
            <Tab.Content>
              <Tab.Pane eventKey={Module.Senate}>
                <div className="d-flex justify-content-end">
                  <ExportButton
                    exportExcelFunction={() => exportExcelAsync(senateData.senatePage, senateData.senateSize, criteria, Module.Senate)}
                    exportPdfFunction={() => exportPdfAsync(senateData.senatePage, senateData.senateSize, criteria, Module.Senate)} />
                </div>
                <SenateComponent />
                <IndividualSenateComponent />
              </Tab.Pane>
              <Tab.Pane eventKey={Module.Committee}>
                <div className="d-flex justify-content-end">
                  <ExportButton
                    exportExcelFunction={() => exportExcelAsync(committeeData.committeePage, committeeData.committeeSize, criteria, Module.Committee)}
                    exportPdfFunction={() => exportPdfAsync(committeeData.committeePage, committeeData.committeeSize, criteria, Module.Committee)} />
                </div>
                <CommitteeComponent />
                <IndividualCommitteeComponent />
              </Tab.Pane>
            </Tab.Content>
          </Tabs>
        </Card>
        <Card>
          <label
            className="text-decoration-underline fs-4 mt-1 mb-2">
            ตารางภาพรวมการจัดทำรายงานการประชุมรายบุคคลประจำ{dashboardThaiDate(currentDate[0])}
          </label>
          <Tabs items={DailyTabs} defaultActiveKey={1}>
            <Tab.Content>
              <Tab.Pane eventKey={1}>
                <DutyComponent />
              </Tab.Pane>
              <Tab.Pane eventKey={2}>
                <CommanderComponent />
              </Tab.Pane>
            </Tab.Content>
          </Tabs>
        </Card>
      </Layout>
    </Context.Provider>
  );
};

// Leave Component and Fn 
const LeaveListComponent = () => {
  const { leaveListData, dailyStatusItems } = useLoaderData() as Loader;
  const [isModalShowed, setIsModalShowed] = useState<boolean>(false);
  const [leave, setLeave] = useState<Leave>({} as Leave);
  const [leaveList, setLeaveList] = useState<Leave[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);

  useEffect(() => {
    setLeaveList(leaveListData.rows);
    setTotalRows(leaveListData.totalRows);
  }, [leaveListData]);

  const daily = useMemo(() => dailyStatusItems?.map(d => ({ label: d.label, value: d.id! })), [dailyStatusItems]);

  const onSubmitAsync = async () => {
    submitForm();

    const status = await onActionLeaveAsync(leave);

    switch (status) {
      case HttpStatusCode.CONFLICT:
        return toast.error('ข้อมูลซ้ำ');
      case HttpStatusCode.CREATED:
        getLeaveListAsync(size, page);
        setIsModalShowed(false);
        return toast.success('บันทึกข้อมูลสำเร็จ');
      case HttpStatusCode.ACCEPTED:
        getLeaveListAsync(size, page);
        setIsModalShowed(false);
        return toast.success('แก้ไขข้อมูลสำเร็จ');
      default: return;
    }
  };

  const getLeaveListAsync = async (size: number, page: number) => {
    setPage(page);
    setSize(size);

    const { data, status } = await d03Service.getLeaveListAsync(page, size);

    if (status === HttpStatusCode.OK) {
      setLeaveList(data.rows);
      setTotalRows(data.totalRows);
    }
  };

  const removeAsync = async (id: string) => {
    const confirmResponse = await showModalConfirmAsync('ยืนยันการลบ');

    if (!confirmResponse) {
      return;
    }

    const { status } = await d03Service.removeLeaveAsync(id);

    if (status === HttpStatusCode.NO_CONTENT) {
      toast.success('ลบข้อมูลสำเร็จ');

      getLeaveListAsync(size, page);
    }
  };

  const showModalAdd = () => {
    setLeave({} as Leave);

    setIsModalShowed(true);
  };

  const showModalEdit = (id: string, startDate: Date, endDate: Date, dailyStatus: string) => {
    setLeave({
      id,
      startDate,
      endDate,
      dailyStatus,
    } as Leave);

    setIsModalShowed(true);
  };

  return (
    <>
      <Card className="my-2">
        <div className="d-flex justify-content-between">
          <label
            className="text-decoration-underline fs-4">
            สถานะการพักจัดทำรายงาน
          </label>
          <Button
            type="submit"
            className="button d-flex align-items-center gap-2"
            onClick={showModalAdd}>
            <FaCalendarMinus />พักจัดทำรายงาน
          </Button>
        </div>
        <Table
          className="mt-3"
          total={totalRows}
          page={page}
          size={size}
          onChange={getLeaveListAsync}>
          <Table.Header>
            <Table.Row>
              <Table.Column minWidth={100}>วันเริ่มต้น</Table.Column>
              <Table.Column minWidth={100}>วันสิ้นสุด</Table.Column>
              <Table.Column minWidth={300}>หมายเหตุ</Table.Column>
              <Table.Column minWidth={100}>ผู้แก้ไขข้อมูล</Table.Column>
              <Table.Column minWidth={100}>วันที่แก้ไขข้อมูล</Table.Column>
              <Table.Column minWidth={50} maxWidth={50} />
              <Table.Column minWidth={50} maxWidth={50} />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {leaveList.map(data =>
              <Table.Row key={data.id}>
                <Table.Cell center>{formatDateTh(data.startDate)}</Table.Cell>
                <Table.Cell center>{formatDateTh(data.endDate)}</Table.Cell>
                <Table.Cell>{data.label}</Table.Cell>
                <Table.Cell center>{data.updateBy}</Table.Cell>
                <Table.Cell center>{fullDateTime(data.updateDate)}</Table.Cell>
                <Table.Cell center>
                  <FaEdit onClick={() => showModalEdit(data.id, data.startDate!, data.endDate!, data.dailyStatus)} />
                </Table.Cell>
                <Table.Cell center>
                  <FaTrashAlt onClick={() => removeAsync(data.id)} />
                </Table.Cell>
              </Table.Row>)}
          </Table.Body>
        </Table>
      </Card>
      <Modal show={isModalShowed}>
        <div className="fs-4 d-flex align-items-center gap-2">
          <FaCalendarMinus />พักจัดทำรายงาน
        </div>
        <div className="d-flex flex-column gap-3 mt-3">
          <InputDate
            label="วันที่เริ่มต้น"
            rule={{ required: true }}
            onChange={startDate => setLeave({ ...leave, startDate })}
            value={leave.startDate} />
          <InputDate
            label="วันที่สิ้นสุด"
            rule={{ required: true }}
            onChange={endDate => setLeave({ ...leave, endDate })}
            value={leave.endDate} />
          <Selector
            label="หมายเหตุ"
            rule={{ required: true }}
            items={daily}
            onChange={dailyStatus => setLeave({ ...leave, dailyStatus: dailyStatus.toString() })}
            value={leave.dailyStatus} />
        </div>
        <div className="d-flex gap-2 mt-3">
          <Button
            variant="light"
            className="flex-grow-1"
            onClick={() => setIsModalShowed(false)}>
            ยกเลิก
          </Button>
          <Button variant="primary" className="flex-grow-1" onClick={onSubmitAsync}>
            บันทึก
          </Button>
        </div>
      </Modal>
    </>
  );
};

const getUserByBureauGroupAsync = async (
  bureauGroupId: string,
) => {
  return (await selectionService.getUserSelectionAsync(SelectionType.SM_USER, { bureauGroupId })).data;
};

const onActionLeaveAsync = async (
  selectedData: Leave,
) => {
  if (!selectedData.startDate || !selectedData.endDate || !selectedData.dailyStatus) {
    return;
  }

  if (selectedData.id) {
    return (await d03Service.updateLeaveAsync(selectedData.id, selectedData.startDate, selectedData.endDate, selectedData.dailyStatus)).status;
  } else {
    return (await d03Service.saveLeaveAsync(selectedData.startDate, selectedData.endDate, selectedData.dailyStatus)).status;
  }
};

// Summary Component and Fn
const SummaryComponent = () => {
  const { currentModule, summaryData } = useContext(Context);
  const navigate = useNavigate();
  const [summaryList, setSummaryList] = useState<IndividualSummary>({} as IndividualSummary);

  useEffect(() => {
    setSummaryList(summaryData);
  }, [summaryData]);

  const onClickChapter = () => {
    if (currentModule === Module.Senate) {
      navigate('/s0204');
    } else {
      navigate('/c0204');
    }
  };

  return (
    <Row className="my-3">
      <Col sm={6} xl={3}>
        <Card className="h-100">
          <div className="d-flex align-items-center text-primary cursor-pointer" onClick={onClickChapter}>
            <TbList className="ms-3 me-4 fs-1" />
            <div className="d-flex flex-column">
              <div
                className="text-decoration-underline fs-5 flex-grow-1">
                จำนวนตอนทั้งหมด
              </div>
              <div>
                {summaryList.totalChapter} ตอน
              </div>
            </div>
          </div>
        </Card>
      </Col>
      <Col sm={6} xl={3}>
        <Card className="h-100">
          <div className="d-flex align-items-center text-success cursor-pointer" onClick={onClickChapter}>
            <TbListCheck className="ms-3 me-4 fs-1" />
            <div className="d-flex flex-column">
              <div
                className="text-decoration-underline fs-5 flex-grow-1">
                จำนวนตอนที่สำเร็จทั้งหมด
              </div>
              <div>
                {summaryList.totalFinished} ตอน
              </div>
            </div>
          </div>
        </Card>
      </Col>
      <Col sm={6} xl={3}>
        <Card className="h-100">
          <div className="d-flex align-items-center text-pending cursor-pointer" onClick={onClickChapter}>
            <TbList className="ms-3 me-4 fs-1" />
            <div className="d-flex flex-column">
              <div
                className="text-decoration-underline fs-5 flex-grow-1">
                จำนวนตอนระหว่างดำเนินการ
              </div>
              <div>
                {summaryList.totalInProcess} ตอน
              </div>
            </div>
          </div>
        </Card>
      </Col>
      <Col sm={6} xl={3}>
        <Card className="h-100">
          <div className="d-flex align-items-center text-primary cursor-pointer" onClick={onClickChapter}>
            <RiTimeLine className="ms-3 me-4 fs-1" />
            <div className="d-flex flex-column">
              <div
                className="text-decoration-underline fs-5 flex-grow-1">
                ระยะเวลาการจดรวมทั้งหมด
              </div>
              <div>
                {summaryList.totalDuration} ชม
              </div>
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  )
};

const getSummaryDataAsync = async (
  criteria: IndividualCriteria,
  selectedModule: string,
) => {
  const { data, status } = await d03Service.getIndividualperformanceoverviewdashboardSummaryAsync(selectedModule, criteria);

  if (status === HttpStatusCode.OK) {
    return data;
  } else {
    return {} as IndividualSummary;
  }
};

// Export Excel and Pdf Component 
function ExportButton(props: { exportExcelFunction: Function, exportPdfFunction: Function }) {
  return (
    <div className="d-flex justify-content-end gap-2">
      <Button
        variant="outline-primary"
        type="button"
        onClick={() => props.exportPdfFunction()}>
        <FaRegFilePdf className="me-2" />Export PDF
      </Button>
      <Button
        variant="outline-primary"
        type="button"
        onClick={() => props.exportExcelFunction()}>
        <FaRegFileExcel className="me-2" />Export Excel
      </Button>
    </div>
  );
};

// Export Document Function.
const exportExcelAsync = async (
  page: number,
  size: number,
  criteria: IndividualCriteria,
  type: Module,
) => {
  let exportFunction;

  if (type === Module.Senate) {
    exportFunction = d03Service.exportExcelSenateAsync;
  } else {
    exportFunction = d03Service.exportExcelCommitteeAsync;
  }

  const { data, status } = await exportFunction(page, size, criteria);

  if (status === HttpStatusCode.OK) {
    exportFileExcel(data);
  }
};

const exportPdfAsync = async (
  page: number,
  size: number,
  criteria: IndividualCriteria,
  type: Module,
) => {
  let exportFunction;

  if (type === Module.Senate) {
    exportFunction = d03Service.exportPdfSenateAsync;
  } else {
    exportFunction = d03Service.exportPdfCommitteeAsync;
  }

  const { data, status } = await exportFunction(page, size, criteria);

  if (status === HttpStatusCode.OK) {
    exportPdfByte(data);
  }
};

// Senate Component and Fn
const SenateComponent = () => {
  const { senateData, setSenateData, criteria } = useContext(Context);

  const onChangeAsync = async (size: number, page: number) => {
    setSenateData(await getSenateDataAsync(page, size, criteria));
  };

  return (
    <Table
      className="my-3"
      total={senateData.totalRows}
      page={senateData.senatePage}
      size={senateData.senateSize}
      onChange={onChangeAsync}>
      <Table.Header>
        <Table.Row>
          <Table.Column minWidth={100}>วัน/เดือน/ปี</Table.Column>
          <Table.Column minWidth={100}>ประเภทการประชุม</Table.Column>
          <Table.Column minWidth={100}>ครั้งที่</Table.Column>
          <Table.Column minWidth={100}>สมัยการประชุม</Table.Column>
          <Table.Column minWidth={100}>ตอนทั้งหมด</Table.Column>
          <Table.Column minWidth={100}>รอยืนยัน</Table.Column>
          <Table.Column minWidth={100}>ระหว่างดำเนินการ</Table.Column>
          <Table.Column minWidth={100}>สำเร็จ</Table.Column>
          <Table.Column minWidth={100}>ระยะเวลา/ชม.</Table.Column>
          <Table.Column minWidth={100}>คำผิด</Table.Column>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {senateData.rows?.map((data, index) => (
          <Table.Row key={index}>
            <Table.Cell>{thaiFullFormatData(data.date)}</Table.Cell>
            <Table.Cell>{data.meetingType}</Table.Cell>
            <Table.Cell center>{data.time}</Table.Cell>
            <Table.Cell center>{data.meetingPeriod}</Table.Cell>
            <Table.Cell right>{data.totalChapter}</Table.Cell>
            <Table.Cell right>{data.totalWaitingConfirm}</Table.Cell>
            <Table.Cell right>{data.totalInProcess}</Table.Cell>
            <Table.Cell right>{data.totalFinished}</Table.Cell>
            <Table.Cell center>{data.duration}</Table.Cell>
            <Table.Cell right>{data.wrongWord}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

const getSenateDataAsync = async (
  page: number,
  size: number,
  criteria: IndividualCriteria,
) => {
  const { data, status } = await d03Service.getIndividualperformanceoverviewdashboardSenateAsync(page, size, criteria);

  if (status === HttpStatusCode.OK) {
    return {
      rows: data.rows,
      totalRows: data.totalRows,
      senatePage: page,
      senateSize: size,
    } as PaginationIndividualDashBoard<IndividualSenateDataList>;
  } else {
    return {
      rows: {},
      totalRows: 0,
      senatePage: 1,
      senateSize: 10,
    } as PaginationIndividualDashBoard<IndividualSenateDataList>;
  }
};

// Individual Senate Component and Fn
const IndividualSenateComponent = () => {
  const { individualSenateData, setIndividualSenateData, criteria } = useContext(Context);
  const [showModal, setShowModal] = useState<{ id: string, show: boolean }>({ id: "", show: false });

  const onChangeAsync = async (size: number, page: number) => {
    setIndividualSenateData(await getIndividualSenateDataAsync(page, size, criteria));
  };

  return (
    <>
      <label
        className="text-decoration-underline fs-4 mt-4">
        ตารางภาพรวมการจัดทำรายงานการประชุมรายบุคคล
      </label>
      <Table
        className="my-3"
        total={individualSenateData.totalRows}
        page={individualSenateData.senateByUserPage}
        size={individualSenateData.senateByUserSize}
        onChange={onChangeAsync}>
        <Table.Header>
          <Table.Row>
            <Table.Column minWidth={100}>กลุ่มงาน</Table.Column>
            <Table.Column minWidth={150}>รายชื่อ</Table.Column>
            <Table.Column minWidth={100}>จำนวนตอน</Table.Column>
            <Table.Column minWidth={100}>ส่งรายงาน</Table.Column>
            <Table.Column minWidth={100}><>อยู่ระหว่าง <br />ดำเนินการ</></Table.Column>
            <Table.Column minWidth={100}><>ส่งรายงาน <br />ร้อยละ</></Table.Column>
            <Table.Column minWidth={100}>คำผิด</Table.Column>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {individualSenateData.rows?.map((data, index) => (
            <Table.Row key={index}>
              <Table.Cell center className="summary-row">{data.summaryName ? "รวม" : data.bureauGroupName}</Table.Cell>
              <Table.Cell>
                <Button
                  variant="link text-start"
                  onClick={() => setShowModal({ id: data.dutyOfficerUserId, show: true })}>
                  {data.fullName}
                </Button>
              </Table.Cell>
              <Table.Cell right>{data.totalChapter}</Table.Cell>
              <Table.Cell right>{data.totalFinished}</Table.Cell>
              <Table.Cell right>{data.totalInProcess}</Table.Cell>
              <Table.Cell right>{data.totalFinishedPercentage}</Table.Cell>
              <Table.Cell right>{data.wrongWord}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table >
      <IndividualModalComponent
        show={showModal.show}
        modalType={DetailDataTableType.Senate}
        onHide={() => setShowModal({ id: "", show: !showModal.show })}
        id={showModal.id} />
    </>
  );
};

const getIndividualSenateDataAsync = async (
  page: number,
  size: number,
  criteria: IndividualCriteria,
) => {
  const { data, status } = await d03Service.getSenateDataTableByUserAsync(page, size, criteria);
  if (status === HttpStatusCode.OK) {
    return {
      rows: data.rows,
      totalRows: data.totalRows,
      senateByUserPage: page,
      senateByUserSize: size,
    } as PaginationIndividualDashBoard<DataTableByUser>;
  } else {
    return {
      rows: {},
      totalRows: 0,
      senateByUserPage: 1,
      senateByUserSize: 10,
    } as PaginationIndividualDashBoard<DataTableByUser>;
  }
};

//  Committee Component and Fn
const CommitteeComponent = () => {
  const { committeeData, setCommitteeData, criteria } = useContext(Context);

  const onChangeAsync = async (size: number, page: number) => {
    setCommitteeData(await getCommitteeDataAsync(page, size, criteria));
  };

  return (
    <Table
      className="my-3"
      total={committeeData.totalRows}
      page={committeeData.committeePage}
      size={committeeData.committeeSize}
      onChange={onChangeAsync}>
      <Table.Header>
        <Table.Row>
          <Table.Column minWidth={100}>วัน/เดือน/ปี</Table.Column>
          <Table.Column minWidth={100}>คณะกรรมาธิการ</Table.Column>
          <Table.Column minWidth={100}>ตอนทั้งหมด</Table.Column>
          <Table.Column minWidth={100}>รอยืนยัน</Table.Column>
          <Table.Column minWidth={100}>ระหว่างดำเนินการ</Table.Column>
          <Table.Column minWidth={100}>สำเร็จ</Table.Column>
          <Table.Column minWidth={100}>ระยะเวลา/ชม.</Table.Column>
          <Table.Column minWidth={100}>รายงานผลคะแนน</Table.Column>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {committeeData.rows?.map((data, index) => (
          <Table.Row key={index}>
            <Table.Cell>{thaiFullFormatData(data.date)}</Table.Cell>
            <Table.Cell>{data.committeeName}</Table.Cell>
            <Table.Cell right>{data.totalChapter}</Table.Cell>
            <Table.Cell right>{data.totalWaitingConfirm}</Table.Cell>
            <Table.Cell right>{data.totalInProcess}</Table.Cell>
            <Table.Cell right>{data.totalFinished}</Table.Cell>
            <Table.Cell center>{data.duration}</Table.Cell>
            <Table.Cell right>{data.score}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

const getCommitteeDataAsync = async (
  page: number,
  size: number,
  criteria: IndividualCriteria,
) => {
  const { data, status } = await d03Service.getIndividualperformanceoverviewdashboardCommitteeAsync(page, size, criteria);

  if (status === HttpStatusCode.OK) {
    return {
      rows: data.rows,
      totalRows: data.totalRows,
      committeePage: page,
      committeeSize: size,
    } as PaginationIndividualDashBoard<IndividualCommitteeDataList>;
  } else {
    return {
      rows: {},
      totalRows: 0,
      committeePage: 1,
      committeeSize: 10,
    } as PaginationIndividualDashBoard<IndividualCommitteeDataList>;
  }
};

// Individual Committee Component and Fn
const IndividualCommitteeComponent = () => {
  const { individualComitteeData, setIndividualCommitteeData, criteria } = useContext(Context);
  const [showModal, setShowModal] = useState<{ id: string, show: boolean }>({ id: "", show: false });

  const onChangeAsync = async (size: number, page: number) => {
    setIndividualCommitteeData(await getIndividualCommitteeDataAsync(page, size, criteria));
  };

  return (
    <>
      <label
        className="text-decoration-underline fs-4 mt-4">
        ตารางภาพรวมการจัดทำรายงานการประชุมรายบุคคล
      </label>
      <Table
        className="my-3"
        total={individualComitteeData.totalRows}
        page={individualComitteeData.committeeByUserPage}
        size={individualComitteeData.committeeByUserSize}
        onChange={onChangeAsync}>
        <Table.Header>
          <Table.Row>
            <Table.Column minWidth={100}>กลุ่มงาน</Table.Column>
            <Table.Column minWidth={150}>รายชื่อ</Table.Column>
            <Table.Column minWidth={100}>จำนวนตอน</Table.Column>
            <Table.Column minWidth={100}>ส่งรายงาน</Table.Column>
            <Table.Column minWidth={100}><>อยู่ระหว่าง <br />ดำเนินการ</></Table.Column>
            <Table.Column minWidth={100}><>ส่งรายงาน <br />ร้อยละ</></Table.Column>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {individualComitteeData.rows?.map((data, index) => (
            <Table.Row key={index}>
              <Table.Cell center className="summary-row">{data.summaryName ? "รวม" : data.bureauGroupName}</Table.Cell>
              <Table.Cell>
                <Button
                  variant="link text-start"
                  onClick={() => setShowModal({ id: data.dutyOfficerUserId, show: true })}>
                  {data.fullName}
                </Button>
              </Table.Cell>
              <Table.Cell right>{data.totalChapter}</Table.Cell>
              <Table.Cell right>{data.totalFinished}</Table.Cell>
              <Table.Cell right>{data.totalInProcess}</Table.Cell>
              <Table.Cell right>{data.totalFinishedPercentage}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <IndividualModalComponent
        show={showModal.show}
        modalType={DetailDataTableType.Committee}
        onHide={() => setShowModal({ id: "", show: !showModal.show })}
        id={showModal.id} />
    </>
  );
};

const getIndividualCommitteeDataAsync = async (
  page: number,
  size: number,
  criteria: IndividualCriteria,
) => {
  const { data, status } = await d03Service.getCommitteeDataTableByUserAsync(page, size, criteria);
  if (status === HttpStatusCode.OK) {
    return {
      rows: data.rows,
      totalRows: data.totalRows,
      committeeByUserPage: page,
      committeeByUserSize: size,
    } as PaginationIndividualDashBoard<DataTableByUser>;
  } else {
    return {
      rows: {},
      totalRows: 0,
      committeeByUserPage: 1,
      committeeByUserSize: 10,
    } as PaginationIndividualDashBoard<DataTableByUser>;
  }
};

// Modal Component
const IndividualModalComponent = (
  props: {
    show: boolean,
    onHide: () => void,
    modalType: DetailDataTableType,
    id: string,
  }
) => {
  const { criteria } = useContext(Context);
  const [detailData, setDetailData] = useState<DataTableByUserDetail[]>([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    if (props.show && props.id) {
      (async () => {
        const data = await getIndividualModalDataAsync(props.modalType, page, size, props.id, criteria);
        setDetailData(await data.rows);
        setTotalRows(data.totalRows);
      })();
    }
  }, [props.show, props.id, page, size]);

  const onChangePageSize = (size: number, page: number) => {
    setPage(page);
    setSize(size);
  }

  return (
    <Modal show={props.show} size="xl">
      <div
        className="d-flex justify-content-end align-items-center mb-2 cursor-pointer"
        onClick={props.onHide}>
        <FaTimes className="fs-4" />
      </div>
      <Table
        total={totalRows}
        page={page}
        size={size}
        onChange={onChangePageSize}>
        <Table.Header>
          <Table.Row>
            <Table.Column minWidth={150}>วันเดือนปี</Table.Column>
            <Table.Column minWidth={200}>ชื่อการประชุม</Table.Column>
            <Table.Column minWidth={100}>
              <>จำนวน<br />ตอน</>
            </Table.Column>
            <Table.Column minWidth={80}>สถานะ</Table.Column>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {detailData?.map((data, index) => (
            <Table.Row key={index}>
              <Table.Cell center >{data.summaryName ? "รวม" : formatDateTh(data.meetingDate)}</Table.Cell>
              <Table.Cell >{data.meetingName}</Table.Cell>
              <Table.Cell right >{data.totalChapter}</Table.Cell>
              <Table.Cell right className="summary-row">
                <div className="d-flex justify-content-center">
                  <Status
                    type={StatusType.D03_DETAIL_STATUS}
                    value={data.status} />
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Modal>
  );
};

const getIndividualModalDataAsync = async (
  type: DetailDataTableType,
  page: number,
  size: number,
  id: string,
  criteria: IndividualCriteria,
) => {
  return (await d03Service.getDataTableByUserDetailAsync(type, page, size, id, criteria)).data;
};

// Duty Component
const DutyComponent = () => {
  const { criteria, currentModule, dutyData } = useContext(Context);
  const [dutyList, setDutyList] = useState<DutyDataList[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);

  useEffect(() => {
    setDutyList(dutyData.rows);
    setTotalRows(dutyData.totalRows);
  }, [dutyData]);

  const getDutyListAsync = async (size: number, page: number) => {
    setPage(page);
    setSize(size);

    const result = await getDutyDataAsync(size, page, currentModule, criteria);
    setDutyList(result.rows);
    setTotalRows(result.totalRows);
  };

  return (
    <Table
      className="my-3"
      total={totalRows}
      page={page}
      size={size}
      onChange={getDutyListAsync}>
      <Table.Header>
        <Table.Row>
          <Table.Column minWidth={100}>ชื่อ</Table.Column>
          <Table.Column minWidth={100}>กลุ่มงาน</Table.Column>
          <Table.Column minWidth={100}>จำนวนตอนทั้งหมด (จำนวนตอน)</Table.Column>
          <Table.Column minWidth={100}>จด (จำนวนตอน)</Table.Column>
          <Table.Column minWidth={100}>ส่งงาน (จำนวนตอน)</Table.Column>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {dutyList?.map((data, index) => (
          <Table.Row key={index}>
            <Table.Cell>{data.name}</Table.Cell>
            <Table.Cell>{data.bureauGroupName}</Table.Cell>
            <Table.Cell right>{data.totalChapter}</Table.Cell>
            <Table.Cell right>{data.takeChapter}</Table.Cell>
            <Table.Cell right>{data.submitChapter}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

//Duty Data.
const getDutyDataAsync = async (size: number, page: number, selectedModule: string, criteria: IndividualCriteria) => {
  return (await d03Service.getIndividualPerformanceOverviewDashboardDutyAsync(page, size, selectedModule, criteria)).data;
};

// Commander Component
const CommanderComponent = () => {
  const { criteria, currentModule, commanderData } = useContext(Context);
  const [commanderList, setCommanderList] = useState<CommanderDataList[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);

  useEffect(() => {
    setCommanderList(commanderData.rows);
    setTotalRows(commanderData.totalRows);
  }, [commanderData]);

  const getCommanderListAsync = async (size: number, page: number) => {
    setPage(page);
    setSize(size);

    const result = await getCommanderDataAsync(page, size, currentModule, criteria);

    setCommanderList(result.rows);
    setTotalRows(result.totalRows);
  };

  return (
    <Table
      className="my-3"
      total={totalRows}
      page={page}
      size={size}
      onChange={getCommanderListAsync}>
      <Table.Header>
        <Table.Row>
          <Table.Column minWidth={100}>ชื่อ</Table.Column>
          <Table.Column minWidth={100}>กลุ่มงาน</Table.Column>
          <Table.Column minWidth={100}>อนุมัติ (จำนวนตอน)</Table.Column>
          <Table.Column minWidth={100}>รวบรวม (จำนวนตอน)</Table.Column>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {commanderList?.map((data, index) => (
          <Table.Row key={index}>
            <Table.Cell>{data.name}</Table.Cell>
            <Table.Cell>{data.bureauGroupName}</Table.Cell>
            <Table.Cell right>{data.approveChapter}</Table.Cell>
            <Table.Cell right>{data.collectChapter}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

//Commander Data.
const getCommanderDataAsync = async (page: number, size: number, currentModule: string, criteria: IndividualCriteria) => {
  return (await d03Service.getIndividualPerformanceOverviewDashboardCommanderAsync(page, size, currentModule, criteria)).data;
};