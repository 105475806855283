import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Modal } from "./Modal";
import { MdInfoOutline } from "react-icons/md";

export function ModalCancel() {
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => { addEventListener(); }, []);

  const addEventListener = () => document.addEventListener('onShowModalCancel', () => setShow(true));

  const action = (confirm: boolean) => {
    setShow(false);

    document.dispatchEvent(new CustomEvent('onShowModalCancelClosed', { detail: { result: confirm } }));
  };

  return (
    <Modal
      show={show}>
      <div>
        <Row >
          <Col sm={12} className="mt-1 d-flex align-items-center justify-content-center">
            <MdInfoOutline className="my-1" style={{ fontSize: 100 }} />
          </Col>
          <Col sm={12} className="mt-3 d-flex align-items-center justify-content-center">
            <h3 className="my-auto">ยกเลิกการเปลี่ยนแปลงข้อมูล</h3>
          </Col>
          <Col sm={12} className="mt-2 mb-2 d-flex align-items-center justify-content-center">
            <p>ท่านต้องการยกเลิกการเปลี่ยนแปลงข้อมูลหรือไม่ ?</p>
          </Col>
        </Row>
      </div>
      <Row>
        <Col>
          <div className="d-flex flex-row gap-2 justify-content-center">
            <Button variant="light" className="w-50" type="button" onClick={() => action(false)}>ยกเลิก</Button>
            <Button variant="primary" className="w-50" type="button" onClick={() => action(true)}>ยืนยัน</Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
}