import { Layout, Selector, InputTime, Table, Card, Status, StatusType, Input } from "components";
import { statusChapterItems, statusMeetingItems } from "constant";
import { CtReviewChapterCriteria, CtReviewChapterDocumentDetailHeader, CtReviewChapterList, Pagination } from "models";
import { useState } from "react";
import { Col, Row, Button, Form } from "react-bootstrap";
import { FaAngleLeft, FaFileSignature, FaSearch, FaTrashAlt } from "react-icons/fa";
import { useLoaderData, useNavigate, useSubmit } from "react-router-dom";
import { formatDateTh, formatTimeToInput, yearToTHFormat } from "utils";

type Loader = { ctreviewchapterdocumentHeaderData: CtReviewChapterDocumentDetailHeader, data: Pagination<CtReviewChapterList> };

export default function C0205Detail() {
  const { ctreviewchapterdocumentHeaderData, data } = useLoaderData() as Loader;
  const [criteria, setCriteria] = useState<CtReviewChapterCriteria>({} as CtReviewChapterCriteria);
  const navigate = useNavigate();
  const submit = useSubmit();

  const onSearch = (size: number, page: number) => {
    submit({
      page: page.toString(),
      size: size.toString(),
      criteria: JSON.stringify(criteria),
    });
  };

  const onClear = () => {
    setCriteria({} as CtReviewChapterCriteria);

    submit({
      page: data.page.toString(),
      size: data.size.toString(),
    });
  };

  const onChangeCriteria = (prop: string, value?: string | number | Date) => {
    setCriteria({ ...criteria, [prop]: value });
  };

  const convertStatusToText = (status: string) => {
    const findStatus = statusMeetingItems.find(s => s.value === status)?.label;

    return findStatus;
  };

  return (
    <Layout title="อนุมัติรายงานการประชุม">
      <div className="d-flex justify-content-start">
        <Button
          type="button"
          variant="outline-primary"
          onClick={() => navigate('/c0205')}>
          <FaAngleLeft className="me-2" />ย้อนกลับ
        </Button>
      </div>
      <Card className="mt-2">
        <label>ข้อมูลการประชุม</label>
        <div className="mt-3 mx-4">
          <Row>
            <Col sm={6} md={4} lg={4} xl={3}>
              <p>สำนัก : {ctreviewchapterdocumentHeaderData.ctBureauName}</p>
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <p>คณะกรรมาธิการ/อื่นๆ : {ctreviewchapterdocumentHeaderData.ctCommitteeName}</p>
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <p>คณะอนุกรรมาธิการ/คณะทำงาน : {ctreviewchapterdocumentHeaderData.ctSubCommitteeName}</p>
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <p>ครั้งที่ : {ctreviewchapterdocumentHeaderData.time}</p>
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <p>ปี : {yearToTHFormat(ctreviewchapterdocumentHeaderData.year)}</p>
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <p>ณ ห้องประชุม : {ctreviewchapterdocumentHeaderData.smMeetingRoomName}</p>
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <p>วันที่ประชุม : {formatDateTh(ctreviewchapterdocumentHeaderData.meetingDate)}</p>
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <p>เวลา : {`${formatTimeToInput(ctreviewchapterdocumentHeaderData.startTime)}-${formatTimeToInput(ctreviewchapterdocumentHeaderData.endTime)}`}</p>
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <p>สถานะ : {convertStatusToText(ctreviewchapterdocumentHeaderData.status)}</p>
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <p>จำนวนตอนทั้งหมด : {ctreviewchapterdocumentHeaderData.chapterAll} ตอน</p>
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <p>รอตรวจสอบ : {ctreviewchapterdocumentHeaderData.waitReview} / อนุมัติ : {ctreviewchapterdocumentHeaderData.reviewed}  / ไม่อนุมัติ : {ctreviewchapterdocumentHeaderData.sendEdit}</p>
            </Col>
          </Row>
        </div>
      </Card>
      <Card className="my-2">
        <Form.Label>
          ค้นหา
        </Form.Label>
        <Row>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Input
              type="number"
              label="ตอนที่"
              value={criteria.chapter}
              onChange={(value) => onChangeCriteria('chapter', value)} />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <InputTime
              name="start-time"
              label="ช่วงเวลาเริ่มต้น"
              value={criteria.startTime}
              onChange={(value) => onChangeCriteria('startTime', value)} />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <InputTime
              name="end-time"
              label="ช่วงเวลาสิ้นสุด"
              value={criteria.endTime}
              onChange={(value) => onChangeCriteria('endTime', value)} />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Input
              label="ผู้จดรายงานการประชุม"
              value={criteria.dutyOfficerUserName}
              onChange={(value) => onChangeCriteria('dutyOfficerUserName', value)} />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Selector
              label="สถานะ"
              placeholder="สถานะ"
              items={statusChapterItems}
              value={criteria.status}
              onChange={(value) => onChangeCriteria('status', value)} />
          </Col>
        </Row>
        <div className="d-flex gap-3 justify-content-start mt-2">
          <Button
            variant="primary"
            type="button"
            onClick={() => onSearch(data.size, data.page)}>
            <FaSearch className="me-2" />ค้นหา
          </Button>
          <Button
            variant="outline-primary"
            type="button"
            onClick={onClear}>
            <FaTrashAlt className="me-2" />ล้างค่า
          </Button>
        </div>
      </Card>
      <div className="d-flex justify-content-end mb-2">
        <Button
          type="button"
          onClick={() => navigate(`document`)}>
          <FaFileSignature className="me-2" />ตรวจทาน
        </Button>
      </div>
      <Table total={data.totalRows} onChange={onSearch}>
        <Table.Header>
          <Table.Row>
            <Table.Column minWidth={100}>คู่จด</Table.Column>
            <Table.Column minWidth={100}>ตอนที่</Table.Column>
            <Table.Column minWidth={150}>ช่วงเวลา</Table.Column>
            <Table.Column minWidth={200}>ผู้จดรายงานการประชุม</Table.Column>
            <Table.Column minWidth={200}>ผู้ตรวจทาน</Table.Column>
            <Table.Column minWidth={200}>สถานะ</Table.Column>
            <Table.Column minWidth={150}>แก้ไขครั้งล่าสุด</Table.Column>
            <Table.Column minWidth={200}>เวอร์ชั่น</Table.Column>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.rows?.map(data => (
            <Table.Row key={data.id}>
              <Table.Cell center>{data.counterPath}</Table.Cell>
              <Table.Cell center>{data.chapter}</Table.Cell>
              <Table.Cell center>{`${formatTimeToInput(data.startTime)}-${formatTimeToInput(data.endTime)}`}</Table.Cell>
              <Table.Cell>{data.dutyOfficerFullName}</Table.Cell>
              <Table.Cell>{data.reviewUserFullName}</Table.Cell>
              <Table.Cell center><Status type={StatusType.CHAPTER_STATUS} value={data.status} /></Table.Cell>
              <Table.Cell center>{data.updateDate ? formatDateTh(data.updateDate) : ""}</Table.Cell>
              <Table.Cell center>{data.versionDocument}</Table.Cell>
            </Table.Row>))}
        </Table.Body>
      </Table>
    </Layout>
  );
}