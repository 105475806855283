import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

interface Props {
  label?: string;
  value?: boolean;
  onChange?: (value: boolean) => void;
  name?: string;
  className?: string;
  disabled?: boolean;
}

export function Switch(props: Props) {
  const [value, setValue] = useState<boolean>(props.value ?? false);

  useEffect(() => {
    setValue(props.value ?? false);
  }, [props.value]);

  const handlerOnChange = (event: HTMLInputElement) => {
    if (props.onChange) {
      props.onChange(event.checked);
    }

    setValue(event.checked);
  };

  return (
    <Form.Check
      type="switch"
      label={props.label}
      value={value.toString()}
      onChange={(event) => handlerOnChange(event.target as HTMLInputElement)} />
  );
}