import { SelectionType, ShareValue } from 'constant/selection';
import { UserSelection } from 'models';
import http from 'utils/axios';

const getSelectionAsync = async (selectionType: SelectionType, id?: string) => {
  const params = {
    id,
  };

  return await http.get(`api/${selectionType}/selection`, { params });
};

const getSelectionIdAsync = async (selectionType: SelectionType) => {
  return await http.get(`api/${selectionType}/selectionId`);
};

const getSelectionParentAsync = async (selectionType: SelectionType) => {
  return await http.get(`api/${selectionType}/selectionparent`);
};

const getUserSelectionAsync = async (selectionType: SelectionType, params?: UserSelection) => {
  return await http.get(`api/${selectionType}/selection`, { params });
}

const getShareValueAsync = async (groupCode: ShareValue) => {
  const params = {
    groupCode,
  };
  return await http.get('api/sharevalue/selection', { params });
};

const getMeetingMemberAsync = async (id: string) => {
  return await http.get(`api/snmeetingagendaeventlog/smmeeting/${id}/selection`);
};

const getPersonTypeListAsync = async (selectionType: SelectionType) => {
  return await http.get(`api/${selectionType}/selectionpersontype`);
};

const selectionService = {
  getSelectionAsync,
  getShareValueAsync,
  getUserSelectionAsync,
  getMeetingMemberAsync,
  getSelectionIdAsync,
  getSelectionParentAsync,
  getPersonTypeListAsync,
};

export default selectionService;