export enum StatusMeeting {
  DRAFT = 'Draft',
  CONFIRM = 'Confirm',
  CANCEL = 'Cancel',
  RECORD = 'Record',
  PAUSE_RECORD = 'PauseRecord',
  RECORDED = 'Recorded',
  DOCUMENT_MAKING = 'DocumentMaking',
  DOCUMENT_COMPLETE = 'DocumentComplete',
  COLLECT = 'Collect',
  OVERVIEW_REVIEWING = 'OverviewReviewing',
  OVERVIEW_REVIEWED = 'OverviewReviewed',
  GUARANTEE = 'Guarantee',
  REJECT = 'Reject',
};

export const statusMeetingItems = [
  { label: 'ร่างการประชุม', value: StatusMeeting.DRAFT },
  { label: 'ยืนยันการประชุม', value: StatusMeeting.CONFIRM },
  { label: 'ยกเลิกการประชุม', value: StatusMeeting.CANCEL },
  { label: 'กำลังบันทึกภาพและเสียง', value: StatusMeeting.RECORD },
  { label: 'บันทึกภาพและเสียงสำเร็จ', value: StatusMeeting.RECORDED },
  { label: 'กำลังจัดทำรายงาน', value: StatusMeeting.DOCUMENT_MAKING },
  { label: 'จัดทำรายงานสำเร็จ', value: StatusMeeting.DOCUMENT_COMPLETE },
  { label: 'รวบรวมรายงาน', value: StatusMeeting.COLLECT },
  { label: 'กำลังตรวจสอบภาพรวม', value: StatusMeeting.OVERVIEW_REVIEWING },
  { label: 'ตรวจสอบภาพรวมสำเร็จ', value: StatusMeeting.OVERVIEW_REVIEWED },
  { label: 'รับรองรายงาน', value: StatusMeeting.GUARANTEE },
  { label: 'ส่งกลับแก้ไขภาพรวม', value: StatusMeeting.REJECT },
];

export const statusMeetingCommitteeItems = [
  { label: 'ร่างการประชุม', value: StatusMeeting.DRAFT },
  { label: 'ยืนยันการประชุม', value: StatusMeeting.CONFIRM },
  { label: 'ยกเลิกการประชุม', value: StatusMeeting.CANCEL },
  { label: 'กำลังบันทึกภาพและเสียง', value: StatusMeeting.RECORD },
  { label: 'บันทึกภาพและเสียงสำเร็จ', value: StatusMeeting.RECORDED },
  { label: 'กำลังจัดทำรายงาน', value: StatusMeeting.DOCUMENT_MAKING },
  { label: 'จัดทำรายงานสำเร็จ', value: StatusMeeting.DOCUMENT_COMPLETE },
  { label: 'รวบรวมรายงาน', value: StatusMeeting.COLLECT },
  { label: 'กำลังตรวจสอบภาพรวม', value: StatusMeeting.OVERVIEW_REVIEWING },
  { label: 'รอรับเรื่อง', value: StatusMeeting.OVERVIEW_REVIEWED },
  { label: 'รับเรื่องแล้ว', value: StatusMeeting.GUARANTEE },
  { label: 'ส่งกลับแก้ไข', value: StatusMeeting.REJECT },
];

export enum StatusChapter {
  ASSIGN_DUTY_OFFICER = 'AssignDutyOfficer',
  RECORD = 'Record',
  PAUSE_RECORD = 'PauseRecord',
  WAIT_RECORD = 'WaitRecord',
  RECORDED = 'Recorded',
  DOCUMENT_MAKING = 'DocumentMaking',
  DOCUMENT_COMPLETE = 'DocumentComplete',
  REVIEWED_DOCUMENT = 'ReviewedDocument',
  SEND_EDIT_DOCUMENT = 'SendEditDocument',
  COLLECT = 'Collect',
  GUARANTEE = 'Guarantee',
  CONFIRM = 'Confirm',
  REJECT = 'Reject',
};

export const statusChapterItems = [
  { label: 'กำหนดผู้รับชอบ', value: StatusChapter.ASSIGN_DUTY_OFFICER },
  { label: 'รอบันทึกภาพและเสียง', value: StatusChapter.WAIT_RECORD },
  { label: 'บันทึกภาพและเสียงสำเร็จ', value: StatusChapter.RECORDED },
  { label: 'กำลังจัดทำรายงาน', value: StatusChapter.DOCUMENT_MAKING },
  { label: 'จัดทำรายงานสำเร็จ', value: StatusChapter.DOCUMENT_COMPLETE },
  { label: 'ตรวจทานรายงาน', value: StatusChapter.REVIEWED_DOCUMENT },
  { label: 'ส่งรายงานกลับแก้ไข', value: StatusChapter.SEND_EDIT_DOCUMENT },
  { label: 'รวบรวมรายงาน', value: StatusChapter.COLLECT },
  { label: 'รับรองรายงาน', value: StatusChapter.GUARANTEE },
  { label: 'ยืนยันผู้รับผิดชอบ', value: StatusChapter.CONFIRM },
];

export const meetingRecordStatus = [
  { label: 'ยืนยันการประชุม', value: StatusMeeting.CONFIRM },
  { label: 'กำลังบันทึกภาพและเสียง', value: StatusMeeting.RECORD },
  { label: 'บันทึกภาพและเสียงสำเร็จ', value: StatusMeeting.RECORDED },
];

export const s0205StatusItems = [
  { label: 'บันทึกภาพและเสียงสำเร็จ', value: StatusMeeting.RECORDED },
  { label: 'กำลังจัดทำรายงาน', value: StatusMeeting.DOCUMENT_MAKING },
  { label: 'จัดทำรายงานสำเร็จ', value: StatusMeeting.DOCUMENT_COMPLETE },
  { label: 'รวบรวมรายงาน', value: StatusMeeting.COLLECT },
  { label: 'รับรองรายงาน', value: StatusMeeting.GUARANTEE },
  { label: 'ส่งกลับแก้ไขภาพรวม', value: StatusMeeting.REJECT },
];

export const s0205ChapterStatusItems = [
  { label: 'จัดทำรายงานสำเร็จ', value: StatusChapter.DOCUMENT_COMPLETE },
  { label: 'ตรวจทานรายงาน', value: StatusChapter.REVIEWED_DOCUMENT },
  { label: 'ส่งรายงานกลับแก้ไข', value: StatusChapter.SEND_EDIT_DOCUMENT },
];

export enum StatusInqueryS1 {
  MEETING_SCHEDULE = 'MeetingSchedule',
  RECORDING = 'Recording',
  MEMO = 'Memo',
  MOM = 'Mom',
  REVIEW_MOM = 'ReviewMom',
  COLLECT_MOM = 'CollectMom',
  CERTIFY_MOM = 'CertifyMom',
};

export const stepInqueryS1StatusItems = [
  { label: 'กำหนดการประชุม', value: StatusInqueryS1.MEETING_SCHEDULE },
  { label: 'บันทึกภาพสัญญาณ', value: StatusInqueryS1.RECORDING },
  { label: 'บันทึกเหตุกาณ์', value: StatusInqueryS1.MEMO },
  { label: 'จัดทำรายงานการประชุม', value: StatusInqueryS1.MOM },
  { label: 'ตรวจทานรายงานการประชุม', value: StatusInqueryS1.REVIEW_MOM },
  { label: 'รวบรวมรายงานการประชุม', value: StatusInqueryS1.COLLECT_MOM },
  { label: 'ตรวจสอบและรับรองรายงาน', value: StatusInqueryS1.CERTIFY_MOM },
];

export enum NormalStatus {
  NORMAL = 'normal'
};