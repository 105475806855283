import { Card, Input, Table, Modal, Check } from "components";
import { HttpStatusCode } from "constant";
import { ProgramModel } from "models";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaSearch, FaTrashAlt } from "react-icons/fa";
import smProgramService from "services/programSevice";
import manageProgram from "./manageProgram";

interface Props {
  programSelected: ProgramModel[];
  show: boolean;
  onHide: () => void;
  onSelectProgram: (data: ProgramModel[]) => void;
  programAll: ProgramModel[];
}

enum Mode {
  PARENT = 'parent',
  CHILDREN = 'children',
};

export default function ModalSelectProgram(props: Props) {
  const [programList, setProgramList] = useState<ProgramModel[]>([]);
  const [programsSelected, setProgramsSelected] = useState<ProgramModel[]>([]);
  const [criteria, setCriteria] = useState<ProgramModel>({} as ProgramModel);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    const getDataAsync = async () => {
      if (props.show) {
        if (props.programSelected) {
          setProgramsSelected(props.programSelected);
        }

        setProgramList([]);
        setTotalRows(0);

        await getProgramListAsync();
      }
    };

    getDataAsync();
  }, [props.show, props.programSelected]);

  useEffect(() => {
    getProgramListAsync();
  }, [size, page]);

  const getProgramListAsync = async (criteriaData?: ProgramModel) => {
    const { data, status } = await smProgramService.getSmProgramListAsync(page, size, criteriaData ? criteriaData : criteria);

    if (status === HttpStatusCode.OK) {
      setProgramList(data.rows);
      setTotalRows(data.totalRows);
    }
  };

  const handleOnChangeCriteria = (value: string | number, prop: string) => {
    setCriteria({ ...criteria, [prop]: value });
  };

  const onChangePageSize = (page: number, size: number) => {
    setPage(page);
    setSize(size);
  };

  const onSearch = () => {
    getProgramListAsync(criteria);
  };

  const onClearCriteria = () => {
    const criteria = {} as ProgramModel;

    setCriteria(criteria);
    setPage(1);
    setSize(10);

    getProgramListAsync(criteria);
  };

  const handleOnCheckChange = useCallback((id: string, value: boolean) => {
    if (value) {
      addProgram(id);
    } else {
      removeProgram(id, programsSelected.map(p => p));
    }
  }, [programsSelected, props.programAll]);

  const addProgram = useCallback((id: string, mode?: Mode) => {
    const programs = manageProgram.addProgram(props.programAll, programsSelected, id, mode);

    setProgramsSelected([...programs]);
  }, [programsSelected, props.programAll]);

  const removeProgram = useCallback((id: string, programsSelected: ProgramModel[], mode?: Mode) => {
    const programs = manageProgram.removeProgram(props.programAll, programsSelected, id, mode);

    setProgramsSelected([...programs]);
  }, [props.programAll]);

  const checkValue = useCallback((id: string) => programsSelected.some(p => p.id === id), [programsSelected]);

  const onSelectProgram = () => {
    const sortData = programsSelected.sort((a, b) => a.code.localeCompare(b.code));
    props.onSelectProgram(sortData);
    onHide();
  };

  const onHide = () => {
    setPage(1);
    setSize(10);

    props.onHide();
  };

  const onSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSearch();
  };

  return (
    <Modal show={props.show} size="xl">
      <h3>เพิ่มโปรแกรม</h3>
      <Card>
        <Form onSubmit={onSubmitSearch}>
          <label>ค้นหา</label>
          <Row>
            <Col lg={6}>
              <Input
                label="รหัสโปรแกรม"
                value={criteria.code}
                onChange={(value) => handleOnChangeCriteria(value, "code")} />
            </Col>
            <Col lg={6}>
              <Input
                label="ชื่อโปรแกรม"
                value={criteria.name}
                onChange={(value) => handleOnChangeCriteria(value, "name")} />
            </Col>
          </Row>
          <div className="d-flex gap-2">
            <Button
              variant="primary"
              type="submit">
              <FaSearch className="me-2" />ค้นหา
            </Button>
            <Button
              variant="outline-primary"
              type="button"
              onClick={onClearCriteria}>
              <FaTrashAlt
                className="me-2"
                onClick={() => { }} />ล้างค่า
            </Button>
          </div>
        </Form>
      </Card>
      <div className="mt-3">
        <Table
          total={totalRows}
          page={page}
          size={size}
          onChange={(size, page) => onChangePageSize(page, size)}>
          <Table.Header>
            <Table.Row>
              <Table.Column minWidth={250}>รหัสโปรแกรม</Table.Column>
              <Table.Column minWidth={250}>ชื่อโปรแกรม</Table.Column>
              <Table.Column minWidth={200}></Table.Column>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {programList?.map((data) => (
              <Table.Row key={data.id}>
                {data.parentId ? <Table.Cell center>{data.code}</Table.Cell> : <Table.Cell center>{data.name}</Table.Cell>}
                {data.parentId ? <Table.Cell>{data.name}</Table.Cell> : <Table.Cell></Table.Cell>}
                <Table.Cell right className="fs-5">
                  <div className="d-flex justify-content-center">
                    <Check
                      value={checkValue(data.id)}
                      onChange={(value) => handleOnCheckChange(data.id, value)} />
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
      <div className="mt-3 d-flex justify-content-end gap-2">
        <Button
          variant="outline-primary"
          onClick={onHide}>
          ยกเลิก
        </Button>
        <Button
          onClick={onSelectProgram}>
          ยืนยัน
        </Button>
      </div>
    </Modal>
  );
}