export enum ModalType {
  ADD = "Add",
  EDIT = "Edit",
};

export enum RecordType {
  RECORD = 'Record',
  PAUSE_RECORD = 'PauseRecord',
  RECORDED = "Recorded",
};

export enum MovementType {
  UP,
  DOWN,
};

export enum PersonType {
  Other = 'อื่น ๆ',
  Senate = 'สมาชิกวุฒิสภา',
};