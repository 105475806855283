import http from 'utils/axios';

const exportWordFileAsync = async (docId: string, programName: string) => {
  return await http.get(`/wopi/files/${docId}/contents`, { params: { programName }, responseType: "blob" });
};

const exportPdfFileAsync = async (docId: string, programName: string) => {
  return await http.get(`/wopi/files/${docId}/convert-to-pdf`, { params: { programName }, responseType: "blob" });
}

const exportService = {
  exportWordFileAsync,
  exportPdfFileAsync,
};

export default exportService;