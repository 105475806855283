import { LONG_DAYS, LONG_MONTHS } from "data";
import { ItemModel } from "models";

export const getYearList = () => {
  const date = new Date();
  const getPresentYear = date.getFullYear();
  const thYear = (getPresentYear + 543) - 10;
  const yearItems: ItemModel[] = [];

  for (let i = 0; i <= 20; i++) {
    yearItems.push({
      label: (thYear + i).toString(), value: (getPresentYear - 10) + i
    });
  }

  return yearItems;
};

export const getThDay = LONG_DAYS.map((d, i) => ({ value: d.value, label: d.label }));

export const getThMonth = LONG_MONTHS.map((d, i) => ({ value: i, label: d }));