import { Cookie, Module } from 'constant';
import { useModule, useUser } from 'hooks';
import { useCallback, useMemo } from 'react';
import { Container, Navbar as NavbarBT, Image, Button } from 'react-bootstrap';
import { FaQuestion } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { showModalConfirmAsync, signOutAsync } from 'utils';
import cookie from 'utils/cookie';
import toast from 'utils/toast';

export function Navbar() {
  const { user } = useUser();
  const { currentModule, setCurrentModule, allModules } = useModule();
  const navigate = useNavigate();

  const fullName = useMemo(() => {
    const middleName = user.middleName ? ' ' + user.middleName : '';

    return `${user.title}${user.firstName}${middleName} ${user.lastName}`;
  }, [user]);

  const onSignOutAsync = async () => {
    await signOutAsync();

    toast.success('ออกจากระบบสำเร็จ');
  };

  const switchModuleAsync = useCallback(async () => {
    if (!await showModalConfirmAsync(
      `ท่านต้องการสลับเป็น${currentModule === Module.Senate ? 'คณะกรรมาธิการ' : 'วุฒิสภา'}หรือไม่`,
      `สลับเป็น${currentModule === Module.Senate ? 'คณะกรรมาธิการ' : 'วุฒิสภา'}`,
      <FaQuestion className='text-primary' />)) {
      return;
    }

    switch (currentModule) {
      case Module.Senate:
        setCurrentModule(Module.Committee);
        cookie.set(Cookie.MODULE, Module.Committee);
        break;
      case Module.Committee:
        setCurrentModule(Module.Senate);
        cookie.set(Cookie.MODULE, Module.Senate);
        break;
    }

    navigate("");
  }, [currentModule]);

  const module = useMemo(() => {
    switch (currentModule) {
      case Module.Senate:
        return { icon: '/images/logo.png', label: 'สลับเป็นคณะกรรมาธิการ' };
      case Module.Committee:
        return { icon: '/images/logo-color.png', label: 'สลับเป็นวุฒิสภา' };
    }
  }, [currentModule]);

  const isShowSwitchModule = useMemo(() => {
    if (allModules?.some(m => m === Module.Senate) && allModules?.some(m => m === Module.Committee)) {
      return true;
    }

    return false;
  }, [allModules]);

  return (
    <NavbarBT expand="lg" bg="primary">
      <Container fluid>
        <NavbarBT.Brand>
          <div className="d-flex">
            <Image
              src="/images/logo.png"
              width="60"
              height="60" />
            <div className='d-flex flex-column justify-content-center ms-2 lh-sm'>
              <span className='fs-5'>สำนักงานเลขาธิการวุฒิสภา</span>
              <span className='fs-5'>ระบบจดและจัดทำรายงานการประชุม{currentModule === Module.Senate ? "วุฒิสภา" : "คณะกรรมาธิการ"}</span>
            </div>
          </div>
        </NavbarBT.Brand>
        <NavbarBT.Toggle />
        <NavbarBT.Collapse className="justify-content-lg-end text-white">
          <div className='d-flex justify-content-between'>
            <div className='user-info'>
              <div className='img'>
                <Image src={user.personImage} />
              </div>
              <div className='d-flex flex-column justify-content-center '>
                <span>{fullName}</span>
                <span>ตำแหน่ง : {user.positionName}</span>
              </div>
            </div>
            <div className='d-flex align-items-center'>
              {isShowSwitchModule ?
                <div className='module'>
                  <Button
                    variant="primary"
                    className='bg-switch'
                    onClick={switchModuleAsync}><span><Image src={module?.icon} width={25} className='me-1' /> {module?.label}</span>
                  </Button>
                </div> : null}
              <div
                className='sign-out'>
                <Button
                  variant="primary"
                  onClick={onSignOutAsync}>
                  <Image src="/icons/logout.svg" width={25} className='me-1' />
                </Button>
              </div>
            </div>
          </div>
        </NavbarBT.Collapse>
      </Container>
    </NavbarBT>
  );
}