import { Layout, InputDate, Table, Selector, Input, InputTime, Modal, Status, StatusType } from "components";
import { Code, HttpStatusCode, ModalType, SelectionType, StatusChapter, StatusMeeting, QueueType } from "constant";
import { C0202Criteria, C0202Generate, CtMeeting, CtMeetingChapter, CtMeetingReportInspect, ItemModel, UserSelection } from "models";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { FaAngleLeft, FaCheckCircle, FaSync, FaFileDownload, FaRegTimesCircle, FaTrashAlt, FaUserFriends, FaPlus, FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router";
import { useLoaderData, useLocation, useParams, useSubmit } from "react-router-dom";
import { c0100, c0202, selectionService } from "services";
import { getYearList, showModalConfirmAsync, showModalRemoveAsync, submitForm } from "utils";
import toast from "utils/toast";
import { v4 as uuidV4 } from 'uuid';
import { useUser } from 'hooks';

export interface ListPageSizeModel {
  page: number;
  size: number;
}

type Loader = {
  ctBureaus: ItemModel[];
  smBureaus: ItemModel[];
  bureauGroups: ItemModel[];
  users: ItemModel[];
  positionTypes: ItemModel[];
  committees: ItemModel[];
  subCommittees: ItemModel[];
  meetingRooms: ItemModel[];
  meeting: CtMeeting;
  meetingType: ItemModel[];
  queueType: ItemModel[];
  criteriaIndex: C0202Criteria;
  listPageLoader: number;
  listSizeLoader: number;
};

type InspectorModalData = {
  show: boolean;
  data?: CtMeetingReportInspect;
  mode: ModalType,
};

type ReportMakerModalData = {
  show: boolean;
  data: CtMeetingChapter;
};

export default function C0202Detail() {
  const { ctBureaus, bureauGroups, users, positionTypes, committees, meetingRooms, meetingType, meeting, criteriaIndex, listPageLoader, listSizeLoader } = useLoaderData() as Loader;
  const navigate = useNavigate();
  const [meetingData, setMeetingData] = useState<CtMeeting>({
    year: new Date().getUTCFullYear(),
  } as CtMeeting);
  const [inspectorModalData, setInspectorModalData] = useState<InspectorModalData>({} as InspectorModalData);
  const [reportMakerModalData, setReportMakerModalData] = useState<ReportMakerModalData>({} as ReportMakerModalData);
  const [isDisabledForm, setIsDisabledForm] = useState<boolean>(true);
  const [isShowSave, setShowSave] = useState<boolean>(false);
  const [subCommitteeItems, setSubCommitteeItems] = useState<ItemModel[]>([]);
  const [showModalMeetingChapter, setShowModalMeetingChapter] = useState(false);
  const [meetingTypeMinuteRange, setMeetingTypeMinuteRange] = useState<number>();
  const criteriaListData = useLocation().state?.criteriaData as C0202Criteria;
  const listPage = useLocation().state?.page as number;
  const listSize = useLocation().state?.size as number;
  const [criteriaIndexPage, setCriteriaIndexPage] = useState<C0202Criteria>({} as C0202Criteria);
  const [listPageSize, setListPageSize] = useState<ListPageSizeModel>({} as ListPageSizeModel);
  const { user } = useUser();
  const submit = useSubmit();
  const { id } = useParams();

  useEffect(() => {
    if (meetingData.meetingType) {
      getMeetingTypeAsync(meetingData.meetingType);
    }
  }, [meetingData.meetingType]);

  const initMeetingData = useCallback((meeting: CtMeeting, criteriaListData: C0202Criteria, listPage: number, listSize: number) => {
    if (!meeting) {
      setMeetingData({ ...meetingData });

      setIsDisabledForm(false);

      return;
    }

    setMeetingData({ ...meeting });

    setIsDisabledForm(!(meeting.status === StatusMeeting.DRAFT));

    setShowSave(meeting.status !== StatusMeeting.DRAFT && meeting.status !== StatusMeeting.CANCEL);

    if (meeting.committeeId) {
      getSubmitteeAsync(meeting.committeeId.toString());
    }

    if (criteriaListData && listPage && listSize) {
      setCriteriaIndexPage(criteriaListData);
      setListPageSize({ page: listPage, size: listSize });

      return;
    }

    setCriteriaIndexPage(criteriaIndex);
    setListPageSize({ page: listPageLoader, size: listSizeLoader });
  }, [meeting]);

  useEffect(() => {
    initMeetingData(meeting, criteriaListData, listPage, listSize);
  }, [meeting]);

  const remove = (index: number) => {
    meetingData.ctMeetingReportInspects.splice(index, 1);

    setMeetingData({
      ...meetingData,
      ctMeetingReportInspects: [
        ...meetingData.ctMeetingReportInspects,
      ],
    });
  };

  const onCheckTimeValue = (autoQueue?: boolean) => {
    const isValidMorning = meetingData.startTime && meetingData.endTime;

    const isValidLunch = meetingData.startTimeSecond && meetingData.endTimeSecond;

    const isValidMorningAndLunch = !isValidMorning && !isValidLunch;

    if (autoQueue && isValidMorningAndLunch) {
      toast.warn("กรุณาระบุเวลาก่อนทำการจัดผู้จัดทำรายงาน");

      return false;
    }

    if (!meetingData.startTime &&
      !meetingData.endTime &&
      !meetingData.startTimeSecond &&
      !meetingData.endTimeSecond) {
      return toast.warn('กรุณาเลือกช่วงเวลาการประชุมอย่างน้อย 1 ช่วง');
    }

    if (isValidMorning && (new Date(meetingData.startTime) >= new Date(meetingData.endTime))) {
      toast.warn("เวลาสิ้นสุด (ช่วงเช้า) ต้องมากกว่าเวลาเริ่มต้น (ช่วงเช้า)");

      return false;
    }

    if (isValidLunch && (new Date(meetingData.startTimeSecond) >= new Date(meetingData.endTimeSecond))) {
      toast.warn("เวลาสิ้นสุด (ช่วงบ่าย) ต้องมากกว่าเวลาเริ่มต้น (ช่วงบ่าย)");

      return false;
    }

    if ((meetingData.startTime && !meetingData.endTime) || (!meetingData.startTime && meetingData.endTime)) {
      toast.warn("กรุณาระบุเวลาเริ่มประชุม (ช่วงเช้า) และ เวลาสิ้นสุดประชุม (ช่วงเช้า) ให้ครบถ้วน");

      return false;
    }

    if ((isValidMorning && isValidLunch) && (new Date(meetingData.startTimeSecond) <= new Date(meetingData.endTime) || new Date(meetingData.endTimeSecond) <= new Date(meetingData.endTime))) {
      toast.warn("เวลาประชุม (ช่วงบ่าย) ต้องมากกว่าเวลาสิ้นสุดประชุม (ช่วงเช้า)");

      return false;
    }

    if ((meetingData.startTimeSecond && !meetingData.endTimeSecond) || (!meetingData.startTimeSecond && meetingData.endTimeSecond)) {
      toast.warn("กรุณาระบุเวลาเริ่มประชุม (ช่วงบ่าย) และ เวลาสิ้นสุดประชุม (ช่วงบ่าย) ให้ครบถ้วน");

      return false;
    }

    return true;
  };

  const onSaveAsync = async (isConfirm?: boolean) => {
    if (isConfirm && !await showModalConfirmAsync('ท่านต้องการยืนยันการประชุมหรือไม่ ?', "ยืนยันการประชุม", <FaCheckCircle color="#6A0F0F" />)) {
      return;
    }

    submitForm();

    const checkRequired =
      !meetingData.meetingType ||
      !meetingData.bureauId ||
      !meetingData.committeeId ||
      !meetingData.smMeetingRoomId ||
      !meetingData.year ||
      !meetingData.meetingDate ||
      !meetingData.linkOnline;

    if (checkRequired) {
      return;
    }

    if (meetingData.ctMeetingReportInspects?.length > 0 && !meetingData.ctMeetingReportInspects?.some(c => c.inspectType)) {
      return toast.warn('กรุณาเลือกหน้าที่ปฏิบัติงาน');
    }

    if (!onCheckTimeValue()) {
      return;
    }

    if (!meetingData.status || meetingData.status === StatusMeeting.CANCEL) {
      meetingData.status = StatusMeeting.DRAFT;
    }

    if (isConfirm) {
      if (!meetingData.ctMeetingReportInspects?.length) {
        return toast.warn('กรุณาเพิ่มรายชื่อผู้ตรวจทานรายงานการประชุมอย่างน้อย 1 คน');
      }

      if (!meetingData.ctMeetingChapters?.length) {
        return toast.warn('กรุณาเพิ่มรายชื่อผู้จัดทำรายงานการประชุมอย่างน้อย 1 คน');
      }

      if (meetingData.ctMeetingChapters?.some(mc => !mc.dutyOfficerUserId)) {
        return toast.warn('กรุณาเพิ่มรายชื่อผู้จัดทำรายงานการประชุม');
      }

      meetingData.status = StatusMeeting.CONFIRM;
    }

    if (meetingData.status === StatusMeeting.CONFIRM) {
      if (meetingData.id) {
        updateMeetingAsync(meetingData, "ยืนยันข้อมูลสำเร็จ");

        return;
      }

      createMeetingAsync(meetingData, "ยืนยันข้อมูลสำเร็จ");

      return;
    }

    if (meetingData.id) {
      updateMeetingAsync(meetingData, "บันทึกข้อมูลสำเร็จ");

      return;
    }

    createMeetingAsync(meetingData, "บันทึกข้อมูลสำเร็จ");
  }

  const createMeetingAsync = async (meetingData: CtMeeting, message: string) => {
    const { data, status } = await c0202.saveAsync(meetingData);

    if (status === HttpStatusCode.CREATED) {
      toast.success(message);

      navigate(data);
    }
  };

  const updateMeetingAsync = async (meetingData: CtMeeting, message: string) => {
    const { status } = await c0202.updateAsync(meetingData);

    if (status === HttpStatusCode.ACCEPTED) {
      toast.success(message);

      submit({
        criteria: JSON.stringify(criteriaIndexPage),
        listPage: listPageSize.page,
        listSize: listPageSize.size,
      });
    }
  };

  const onChange = (key: keyof CtMeeting, value?: string | number | Date) => {
    if (!value) {
      value = undefined;
    }

    switch (key) {
      case 'startTime':
        setMeetingData({
          ...meetingData,
          [key]: value as Date,
          endTime: value === undefined ? meetingData.endTime : new Date(new Date().setHours(12, 0, 0, 0)),
        });
        break;
      case 'startTimeSecond':
        setMeetingData({
          ...meetingData,
          [key]: value as Date,
          endTimeSecond: value === undefined ? meetingData.endTimeSecond : new Date(new Date().setHours(16, 0, 0, 0)),
        });
        break;
      default:
        setMeetingData({
          ...meetingData,
          [key]: value,
        });
    }
  };

  const onInspectorModalSubmit = (mode: ModalType, data: CtMeetingReportInspect) => {
    if (meetingData.ctMeetingReportInspects?.some(mri => mri.userId === data.userId && mri.id !== data.id)) {
      toast.warn('มีผู้ตรวจทานรายงานการประชุมนี้แล้ว');

      return;
    }

    setInspectorModalData({ show: false } as InspectorModalData);

    if (mode === ModalType.ADD) {
      data.id = uuidV4();

      setMeetingData({
        ...meetingData,
        ctMeetingReportInspects: [
          ...meetingData.ctMeetingReportInspects || [],
          data,
        ],
      });

      return;
    }

    const i = meetingData.ctMeetingReportInspects.findIndex(mri => mri.id === data.id);

    meetingData.ctMeetingReportInspects[i] = { ...data };

    setMeetingData({
      ...meetingData,
      ctMeetingReportInspects: [
        ...meetingData.ctMeetingReportInspects,
      ],
    });
  };

  const onReportMakerModalSubmit = (data: CtMeetingChapter) => {
    const i = meetingData.ctMeetingChapters.findIndex(mc => mc.id === data.id);
    const meetingChapter = meetingData.ctMeetingChapters[i];

    if (meetingData.ctMeetingChapters.some(m => m.dutyOfficerUserId === data.dutyOfficerUserId) && data.queueType !== QueueType.BY_CHAPTER_ID) {
      return toast.warn('มีผู้จัดทำรายงานการประชุมนี้แล้ว');
    }

    setReportMakerModalData({ show: false } as ReportMakerModalData);

    if (data.queueType !== QueueType.BY_CHAPTER_ID) {
      const mapAllChapter: CtMeetingChapter[] = meetingData.ctMeetingChapters.map(mc =>
        mc.dutyOfficerUserId === meetingChapter.dutyOfficerUserId ? {
          ...mc,
          dutyOfficerUserId: data.dutyOfficerUserId,
          dutyOfficerUserFullName: data.dutyOfficerUserFullName,
          bureauGroupId: data.bureauGroupId,
          bureauGroupFullName: data.bureauGroupName,
          queueType: data.queueType,
        } : mc);

      setMeetingData({ ...meetingData, ctMeetingChapters: [...mapAllChapter] });

      return;
    }

    const chapterList = [...meetingData.ctMeetingChapters];

    meetingChapter.dutyOfficerUserId = data.dutyOfficerUserId;
    meetingChapter.dutyOfficerUserFullName = data.dutyOfficerUserFullName;
    meetingChapter.bureauGroupId = data.bureauGroupId;
    meetingChapter.bureauGroupName = data.bureauGroupName;
    meetingChapter.queueType = data.queueType;

    chapterList.splice(i, 1, meetingChapter);

    setMeetingData({ ...meetingData, ctMeetingChapters: [...chapterList] });
  };

  const bureauGroupName = (id: string) => bureauGroups.find(b => b.value === id)?.label;

  const userName = (id: string) => users.find(b => b.value === id)?.label;

  const positionType = (id: string) => positionTypes.find(b => b.value === id)?.label;

  const onInspectorModalShow = (mode: ModalType, data?: CtMeetingReportInspect) => {
    setInspectorModalData({
      data,
      show: true,
      mode,
    });
  };

  const onReportMakerModalShow = (data: CtMeetingChapter) => {
    setReportMakerModalData({
      data,
      show: true,
    });
  };

  const autoQueueAsync = async () => {
    if (!meetingData.meetingDate) {
      return toast.warn('กรุณากรอกข้อมูลวันที่ประชุม');
    }

    if (!onCheckTimeValue(true)) {
      return;
    }

    if (!await showModalConfirmAsync(`ยืนยันการจัดผู้จัดทำรายงานอัตโนมัติ`, '', <FaSync className="text-primary" />)) {
      return;
    }

    const res = await c0202.autoQueueAsync(
      meetingData.meetingDate,
      meetingData.meetingType,
      meetingData.startTime,
      meetingData.endTime,
      meetingData.startTimeSecond,
      meetingData.endTimeSecond,
      id);

    if (res.status === HttpStatusCode.OK) {
      const ctMeetingChapters: CtMeetingChapter[] = res.data.map((c: CtMeetingChapter) => ({ ...c, id: uuidV4() }));

      setMeetingData({ ...meetingData, ctMeetingChapters: ctMeetingChapters });
      toast.success(`จัดผู้จัดทำรายงานสำเร็จ`);
    }
  }

  const onCancelAsync = async () => {
    if (!await showModalConfirmAsync('ยืนยันการยกเลิกการประชุม')) {
      return;
    }

    const { status } = await c0202.updateStatusAsync(meetingData.id, StatusMeeting.CANCEL);

    if (status === HttpStatusCode.ACCEPTED) {
      toast.success('ยกเลิกการประชุมสำเร็จ');

      submit({
        criteria: JSON.stringify(criteriaIndexPage),
        listPage: listPageSize.page,
        listSize: listPageSize.size,
      });
    }
  };

  const generateInspectAsync = async () => {
    if (!await showModalConfirmAsync('ยืนยันการจัดผู้จัดตรวจทานและภาพรวมอัตโนมัติ', undefined, <FaSync className="text-primary" />)) {
      return;
    }

    if (!meetingData.bureauId || !meetingData.meetingDate) {
      toast.warn('กรุณากำหนดสำนักและวันที่ประชุม');

      return;
    }

    const bureauGroup = ctBureaus.find(c => c.value === meetingData.bureauId)?.label;
    const getCode = bureauGroup?.substring(0, bureauGroup.indexOf(':')).trim();
    const month = new Date(meetingData.meetingDate).getMonth() + 1;
    const { data, status } = await c0202.generateInspectAsync(getCode ?? "", month);

    if (status === HttpStatusCode.OK) {
      const dataList: C0202Generate[] = data;
      const inspectList: CtMeetingReportInspect[] = dataList.map(d => ({
        bureauGroupId: d.bureauGroupId,
        userId: d.userId,
        bureauId: d.bureauId,
        id: uuidV4(),
        inspectType: getInSpectTypeGenerate(d.positionTypeId),
      }));

      setMeetingData({ ...meetingData, ctMeetingReportInspects: inspectList });

      toast.success('จัดผู้จัดตรวจทานและภาพรวมสำเร็จ');
    }
  };

  const getInSpectTypeGenerate = (id: string) => {
    const inSpectType = positionTypes.find(p => p.id === id)?.value;

    if (inSpectType) {
      return inSpectType.toString();
    }

    return "";
  };

  const onChangeStartTimeEndTime = (type: string, index: number, value?: Date) => {
    switch (type) {
      case "startTime":
        meetingData.ctMeetingChapters[index].startTime = value;
        break;
      case "endTime":
        meetingData.ctMeetingChapters[index].endTime = value;
        break;
    }

    setMeetingData({ ...meetingData });
  };

  const onChangeCommiteeAsync = async (value: string | number) => {
    const code = value.toString();

    setMeetingData({
      ...meetingData,
      committeeId: parseInt(code),
    });

    getSubmitteeAsync(code);
  };

  const getSubmitteeAsync = async (code: string) => {
    const commiteeId = committees.find(f => f.value == code)?.id;
    const { data, status } = await selectionService.getSelectionAsync(SelectionType.CT_SUBCOMMITTEE, commiteeId ?? "");

    if (status === HttpStatusCode.OK) {
      setSubCommitteeItems(data);
    }
  };

  const handleConfirmMeetingReportAsync = async (data: CtMeetingChapter) => {
    if (!await showModalConfirmAsync('ต้องการยืนยันการจัดทำหรือไม่ ?', 'ยืนยันการจัดทำ')) {
      return;
    }

    if (data.startTime && data.endTime) {
      const { status } = await c0202.confirmMeetingChapterAsync(data.id, data.startTime, data.endTime);

      if (status === HttpStatusCode.ACCEPTED) {
        toast.success("ยืนยันตอนสำเร็จ");

        submit({
          criteria: JSON.stringify(criteriaIndexPage),
          listPage: listPageSize.page,
          listSize: listPageSize.size,
        });
      }
    }
  };

  const handleRemoveMeetingReportAsync = async (chapterId: string) => {
    if (!await showModalRemoveAsync()) {
      return;
    }

    const { status } = await c0202.removeMeetingChapterAsync(chapterId, id!);

    if (status === HttpStatusCode.NO_CONTENT) {
      toast.success("ลบข้อมูลตอนสำเร็จ");

      submit({
        criteria: JSON.stringify(criteriaIndexPage),
        listPage: listPageSize.page,
        listSize: listPageSize.size,
      });
    }
  };

  const isDisabled = useMemo((): boolean => user.isCommissionAndRoom && !(user.isAdmin && user.isCommander), [user]);

  const isCancelMeeting = useMemo((): boolean => (
    meeting?.status === StatusMeeting.DRAFT ||
    meeting?.status === StatusMeeting.CONFIRM ||
    meeting?.status === StatusMeeting.CANCEL), [meeting?.status]);

  const AddChapterStatus = useMemo(() => {
    switch (meetingData.status) {
      case StatusMeeting.DRAFT:
        return true;
      case StatusMeeting.CONFIRM:
        return true;
      case StatusMeeting.RECORD:
        return true;
      case StatusMeeting.RECORDED:
        return true;
      case StatusMeeting.DOCUMENT_MAKING:
        return true;
      default:
        return false;
    }
  }, [meetingData]);

  const getMeetingTypeAsync = async (meetingTypeId: string) => {
    const { data, status } = await c0100.getCtMeetingTypeByIdAsync(meetingTypeId);

    if (status === HttpStatusCode.OK) {
      setMeetingTypeMinuteRange(data.minute);
    }
  };

  return (
    <Layout title="กำหนดข้อมูลการประชุม">
      <div className="d-flex justify-content-between my-3">
        <Button
          variant="outline-primary"
          type="button"
          onClick={() => navigate('/c0202', {
            state: {
              criteriaData: criteriaIndexPage,
              page: listPageSize.page,
              size: listPageSize.size,
            }
          })}
          className="d-flex align-items-center gap-2">
          <FaAngleLeft />ย้อนกลับ
        </Button>
        <div className="d-flex gap-2">
          {isCancelMeeting ?
            <Button
              variant={meeting?.status === StatusMeeting.CANCEL ? "danger" : "pending"}
              type="button"
              className="d-flex align-items-center gap-2"
              onClick={onCancelAsync}>
              <span className="text-white"> <FaRegTimesCircle className="me-2" />ยกเลิกการประชุม</span>
            </Button> : null}
          {isShowSave ?
            <Button
              variant="outline-primary"
              type="button"
              className="d-flex align-items-center gap-2"
              onClick={() => onSaveAsync()}>
              <FaFileDownload />บันทึก
            </Button> : null}
          {!isDisabledForm ?
            <>
              <Button
                variant="outline-primary"
                type="button"
                className="d-flex align-items-center gap-2"
                onClick={() => onSaveAsync()}>
                <FaFileDownload />บันทึกแบบร่าง
              </Button>
              {((meetingData.status === StatusMeeting.DRAFT || meetingData.status === StatusMeeting.CANCEL) && !isDisabled) &&
                <Button
                  variant="primary"
                  type="button"
                  className="d-flex align-items-center gap-2"
                  onClick={() => onSaveAsync(true)}>
                  <FaCheckCircle />ยืนยัน
                </Button>
              }
            </> : null}
        </div>
      </div>
      <Row>
        <Col sm={12} md={2}>
          <Input
            label="ลำดับ"
            value={meetingData.sequence}
            onChange={val => onChange('sequence', val)} />
        </Col>
        <Col sm={12} md={4}>
          <Selector
            label="ประเภทการประชุม"
            value={meetingData.meetingType}
            rule={{ required: true }}
            onChange={val => onChange('meetingType', val)}
            items={meetingType}
            disabled={isDisabledForm} />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={4}>
          <Selector
            label="สำนัก"
            value={meetingData.bureauId}
            rule={{ required: true }}
            onChange={val => onChange('bureauId', val)}
            items={ctBureaus} />
        </Col>
        <Col sm={12} md={4}>
          <Selector
            label="คณะกรรมาธิการ/อื่นๆ"
            value={meetingData.committeeId?.toString()}
            rule={{ required: true }}
            onChange={val => onChangeCommiteeAsync(val)}
            items={committees} />
        </Col>
        <Col sm={12} md={4}>
          <Selector
            label="คณะอนุกรรมาธิการ/คณะทำงาน"
            value={meetingData.committeeTypeId?.toString()}
            onChange={val => onChange('committeeTypeId', val)}
            items={subCommitteeItems} />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={8}>
          <Input
            label="ชื่อการประชุม"
            value={meetingData.name}
            onChange={val => onChange('name', val)}
            disabled={isDisabledForm} />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={4}>
          <Input
            label="หัวข้อ"
            value={meetingData.topic}
            onChange={val => onChange('topic', val)}
            disabled={isDisabledForm} />
        </Col>
        <Col sm={12} md={4}>
          <Input
            label="ข้อพิจารณา"
            value={meetingData.consider}
            onChange={val => onChange('consider', val)}
            disabled={isDisabledForm} />
        </Col>
        <Col sm={12} md={4}>
          <Selector
            label="ณ ห้องประชุม"
            value={meetingData.smMeetingRoomId}
            rule={{ required: true }}
            onChange={val => onChange('smMeetingRoomId', val)}
            items={meetingRooms}
            disabled={isDisabledForm} />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={4}>
          <Input
            label="ลิงค์ออนไลน์"
            value={meetingData.linkOnline ?? ''}
            rule={{ required: true }}
            onChange={val => onChange('linkOnline', val)} />
        </Col>
        <Col sm={12} md={4}>
          <Input
            label="รหัส"
            value={meetingData.passwordLinkOnline}
            onChange={val => onChange('passwordLinkOnline', val)} />
        </Col>
        <Col sm={12} md={4} lg={3} xl={2}>
          <Input
            label="ครั้งที่"
            type="number"
            value={meetingData.time}
            onChange={val => onChange('time', val)} />
        </Col>
        <Col sm={12} md={4} lg={3} xl={2}>
          <Selector
            label="สมัย/ปี"
            value={meetingData.year}
            rule={{ required: true }}
            onChange={val => onChange('year', val)}
            disabled={isDisabledForm}
            items={getYearList()} />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={4} lg={3} xl={2}>
          <InputDate
            label="วันที่ประชุม"
            value={meetingData.meetingDate}
            rule={{ required: true }}
            onChange={val => onChange('meetingDate', val)}
            isHideClearButton
            disabled={isDisabledForm} />
        </Col>
        <Col sm={12} md={4} lg={3} xl={2}>
          <InputTime
            label="เวลาเริ่มประชุม (ช่วงเช้า)"
            value={meetingData.startTime}
            onChange={val => onChange('startTime', val)} />
        </Col>
        <Col sm={12} md={4} lg={3} xl={2}>
          <InputTime
            label="เวลาสิ้นสุดประชุม (ช่วงเช้า)"
            value={meetingData.endTime}
            onChange={val => onChange('endTime', val)} />
        </Col>
        <Col sm={12} md={4} lg={3} xl={2}>
          <InputTime
            label="เวลาเริ่มประชุม (ช่วงบ่าย)"
            value={meetingData.startTimeSecond}
            onChange={val => onChange('startTimeSecond', val)} />
        </Col>
        <Col sm={12} md={4} lg={3} xl={2}>
          <InputTime
            label="เวลาสิ้นสุดประชุม (ช่วงบ่าย)"
            value={meetingData.endTimeSecond}
            onChange={val => onChange('endTimeSecond', val)} />
        </Col>
      </Row>
      {!isDisabled ? <>
        <div className="d-flex justify-content-between my-3">
          <label>ตารางรายชื่อผู้ตรวจทานรายงานการประชุม</label>
          <div>
            <Button
              variant="outline-primary"
              type="button"
              onClick={generateInspectAsync}
              className="me-2">
              <FaSync className="me-2" /> จัดผู้ตรวจทานและผู้ตรวจสอบภาพรวม
            </Button>
            <Button
              variant="primary"
              type="button"
              onClick={() => onInspectorModalShow(ModalType.ADD)}>
              <FaPlus className="me-2" />เพิ่มผู้ตรวจ
            </Button>
          </div>
        </div>
        <Table hidePagination>
          <Table.Header>
            <Table.Row>
              <Table.Column minWidth={100}>ลำดับ</Table.Column>
              <Table.Column minWidth={250}><>รายชื่อผู้ตรวจทาน<br />และผู้ตรวจสอบภาพรวม</></Table.Column>
              <Table.Column minWidth={200}>กลุ่มงานชวเลข</Table.Column>
              <Table.Column minWidth={150}>หน้าที่ปฏิบัติงาน</Table.Column>
              <Table.Column minWidth={75} />
              <Table.Column minWidth={75} />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {meetingData.ctMeetingReportInspects?.map((data, i) => (
              <Table.Row key={data.id}>
                <Table.Cell center>
                  {i + 1}
                </Table.Cell>
                <Table.Cell>
                  {userName(data.userId)}
                </Table.Cell>
                <Table.Cell center>
                  {bureauGroupName(data.bureauGroupId)}
                </Table.Cell>
                <Table.Cell center>
                  {positionType(data.inspectType)}
                </Table.Cell>
                <Table.Cell center className="fs-5">
                  <FaEdit
                    className="cursor-pointer"
                    onClick={() => onInspectorModalShow(ModalType.EDIT, data)} />
                </Table.Cell>
                <Table.Cell center className="fs-5">
                  <FaTrashAlt
                    className="cursor-pointer"
                    onClick={() => remove(i)} />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <div className="d-flex justify-content-between my-3">
          <label>ตารางรายชื่อผู้จัดทำรายงานการประชุม</label>
          <div>
            {!isDisabledForm ?
              <Button
                variant="outline-primary"
                type="button"
                onClick={() => autoQueueAsync()}
                className="mx-2">
                <FaSync className="me-2" />จัดผู้จัดทำรายงาน
              </Button> : null}
            {(AddChapterStatus) &&
              <Button onClick={() => setShowModalMeetingChapter(true)}>
                <FaPlus className="me-2" />เพิ่มตอน
              </Button>}
          </div>
        </div>
        <div className="table-relative-fix">
          <div className="table-scroll">
            <Table hidePagination>
              <Table.Header>
                <Table.Row>
                  <Table.Column minWidth={100}>ตอน</Table.Column>
                  <Table.Column minWidth={250}><>รายชื่อผู้จัดทำ<br />รายงานการประชุม</></Table.Column>
                  <Table.Column minWidth={100}>กลุ่มงานชวเลข</Table.Column>
                  <Table.Column minWidth={200}>เวลาเริ่ม</Table.Column>
                  <Table.Column minWidth={200}>เวลาสิ้นสุด</Table.Column>
                  <Table.Column minWidth={150}>สถานะตอน</Table.Column>
                  <Table.Column minWidth={200} className="fix-col" />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {meetingData.ctMeetingChapters?.map((data, i) =>
                  <Table.Row key={data.id}>
                    <Table.Cell center>
                      {data.chapter}
                    </Table.Cell>
                    <Table.Cell>
                      {userName(data.dutyOfficerUserId)}
                    </Table.Cell>
                    <Table.Cell center>
                      {bureauGroupName(data.bureauGroupId)}
                    </Table.Cell>
                    <Table.Cell center>
                      <InputTime
                        value={data.startTime}
                        onChange={(value) => onChangeStartTimeEndTime("startTime", i, value)} />
                    </Table.Cell>
                    <Table.Cell center>
                      <InputTime
                        value={data.endTime}
                        onChange={(value) => onChangeStartTimeEndTime("endTime", i, value)} />
                    </Table.Cell>
                    <Table.Cell>
                      <Status type={StatusType.CHAPTER_STATUS} value={data.status} />
                    </Table.Cell>
                    <Table.Cell className="fix-col">
                      <div className="d-flex gap-3 align-items-center">
                        <Button
                          variant="outline-primary"
                          size="sm"
                          className="d-flex align-items-center gap-2"
                          onClick={() => onReportMakerModalShow(data)}>
                          <FaUserFriends />ผู้จัดทำรายงาน
                        </Button>
                        {(data.status === (StatusChapter.ASSIGN_DUTY_OFFICER || StatusChapter.RECORD || StatusChapter.RECORDED) && meetingData.status !== StatusMeeting.DRAFT) && <>
                          <Button
                            size="sm"
                            className="d-flex align-items-center gap-2"
                            onClick={() => handleConfirmMeetingReportAsync(data)}>
                            <FaCheckCircle />ยืนยัน
                          </Button>
                        </>}
                        {(user.isAdmin && Array.from([StatusChapter.ASSIGN_DUTY_OFFICER, StatusChapter.CONFIRM, StatusChapter.RECORD, StatusChapter.RECORDED, StatusChapter.REJECT]).includes(data.status as StatusChapter))
                          || (!user.isAdmin && data.status === StatusChapter.ASSIGN_DUTY_OFFICER) ?
                          <div className="mx-2">
                            <FaTrashAlt
                              className="text-primary"
                              onClick={() => handleRemoveMeetingReportAsync(data.id)} />
                          </div> : null}
                      </div>
                    </Table.Cell>
                  </Table.Row>)}
              </Table.Body>
            </Table>
          </div>
        </div>
      </> : <></>}
      <InspectorModal
        data={inspectorModalData}
        onHide={() => setInspectorModalData({ show: false } as InspectorModalData)}
        onSubmit={onInspectorModalSubmit} />
      <ReportMakerModal
        data={reportMakerModalData}
        onHide={() => setReportMakerModalData({ show: false } as ReportMakerModalData)}
        onSubmit={onReportMakerModalSubmit}
        runQueueData={meetingData} />
      <MeetingChapterModal
        show={showModalMeetingChapter}
        onHide={() => setShowModalMeetingChapter(false)}
        meetingChapterList={meetingData.ctMeetingChapters}
        meetingTypeMinuteRange={meetingTypeMinuteRange}
        criteria={criteriaIndexPage}
        page={listPageSize.page}
        size={listPageSize.size} />
    </Layout>
  );
}

function InspectorModal(props: {
  data: InspectorModalData;
  onHide: () => void;
  onSubmit: (mode: ModalType, value: CtMeetingReportInspect) => void;
}) {
  const { smBureaus, bureauGroups, positionTypes } = useLoaderData() as Loader;
  const [meetingReportInspect, setMeetingReportInspect] = useState<CtMeetingReportInspect>({} as CtMeetingReportInspect);
  const [bureauGroupItems, setBureauGroupItems] = useState<ItemModel[]>([]);
  const [userList, setUserList] = useState<ItemModel[]>([]);
  const [mount, setMount] = useState<boolean>(false);

  useEffect(() => {
    const bureauGroup = smBureaus.find(c => c.label.includes(Code.BUREAU_CODE))?.value;
    const bureauGroupFilterData = [...bureauGroups.filter(f => f.id == bureauGroup).slice(2)];

    setBureauGroupItems(bureauGroupFilterData);

    if (props.data.show) {
      if (props.data.data?.userId) {
        props.data.data.bureauId = bureauGroup?.toString() ?? "";

        setMeetingReportInspect(props.data.data);

        return;
      }

      setMeetingReportInspect({
        bureauId: bureauGroup ?? "",
      } as CtMeetingReportInspect);
    }
  }, [props.data]);

  const onGetUserListAsync = useCallback(async (bureauId: string, bureauGroupId: string) => {
    if (bureauId && bureauGroupId) {
      const criteria: UserSelection = {
        bureauId,
        bureauGroupId,
      };

      const { data, status } = await selectionService.getUserSelectionAsync(SelectionType.SM_USER, criteria);

      if (status === HttpStatusCode.OK) {
        setUserList(data);
      };
    }
  }, []);

  useEffect(() => {
    if (mount) {
      if (isValidOnGetUser) {
        setUserList([]);
      }

      onGetUserListAsync(meetingReportInspect.bureauId, meetingReportInspect.bureauGroupId);
    }

    !mount && setMount(true);
  }, [meetingReportInspect.bureauId, meetingReportInspect.bureauGroupId]);

  const isValidOnGetUser = useMemo(() => {
    return !meetingReportInspect.bureauId || !meetingReportInspect.bureauGroupId;
  }, [meetingReportInspect.bureauId, meetingReportInspect.bureauGroupId]);

  const onSubmit = () => {
    submitForm(meetingReportInspect, true);

    if (!meetingReportInspect.bureauId ||
      !meetingReportInspect.bureauGroupId ||
      !meetingReportInspect.userId ||
      !meetingReportInspect.inspectType) {
      return;
    }

    props.onSubmit(props.data.mode, meetingReportInspect);
  };

  const onChange = (key: string, value: string | number) => {
    setMeetingReportInspect({
      ...meetingReportInspect,
      [key]: value,
    });
  };

  return (
    <Modal
      show={props.data.show}
      icon={<FaUserFriends />}
      title="ผู้ตรวจทานรายงานการประชุม">
      <div className="d-flex flex-column mt-3">
        <Selector
          name='bureau'
          label="สำนัก"
          disabled
          items={smBureaus}
          rule={{ required: true }}
          value={meetingReportInspect.bureauId}
          onChange={val => onChange('bureauId', val)} />
        <Selector
          name='bureauGroup'
          label="กลุ่มงาน"
          isHideClearButton
          items={bureauGroupItems}
          rule={{ required: true }}
          value={meetingReportInspect.bureauGroupId}
          onChange={val => onChange('bureauGroupId', val)} />
        <Selector
          name='user'
          label="เจ้าหน้าที่รับผิดชอบ"
          items={userList}
          rule={{ required: true }}
          value={meetingReportInspect.userId}
          onChange={val => onChange('userId', val)} />
        <Selector
          name='positionType'
          label="หน้าที่"
          items={positionTypes}
          rule={{ required: true }}
          value={meetingReportInspect.inspectType}
          onChange={val => onChange('inspectType', val)} />
      </div>
      <div className="d-flex justify-content-end gap-2">
        <Button variant="outline-primary" onClick={props.onHide}>
          ยกเลิก
        </Button>
        <Button variant="primary" onClick={onSubmit}>
          บันทึก
        </Button>
      </div>
    </Modal>
  );
}

function ReportMakerModal(props: {
  data: ReportMakerModalData;
  onHide: () => void;
  onSubmit: (value: CtMeetingChapter) => void;
  runQueueData: CtMeeting;
}) {
  const { bureauGroups, queueType, smBureaus } = useLoaderData() as Loader;
  const [meetingChapter, setMeetingChapter] = useState<CtMeetingChapter>({} as CtMeetingChapter);
  const [userList, setUserList] = useState<ItemModel[]>([]);
  const [bureauGroupItem, setBureauGroupItem] = useState<ItemModel[]>([]);
  const [mount, setMount] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    const bureauCode = smBureaus.filter(f => f.label.includes(Code.BUREAU_CODE));
    const bureauGroupFilterData = [...bureauGroups.filter(f => f.id == bureauCode[0].value).slice(2)];

    setBureauGroupItem(bureauGroupFilterData);
  }, [smBureaus]);

  const queTypeItems: ItemModel[] = queueType.map(q => ({
    value: q.id as string,
    label: q.label,
  }));

  useEffect(() => {
    if (props.data.show) {
      setMeetingChapter({ ...meetingChapter, queueType: QueueType.SWAP_ID, id: props.data.data.id, bureauGroupId: "", dutyOfficerUserId: "" });
      setDisabled(false);
    }
  }, [props.data]);

  const onGetUserListAsync = useCallback(async (bureauGroupId: string) => {
    if (bureauGroupId) {
      const criteria: UserSelection = {
        bureauGroupId,
      };

      const { data, status } = await selectionService.getUserSelectionAsync(SelectionType.SM_USER, criteria);

      if (status === HttpStatusCode.OK) {
        setUserList(data);
      };
    }
  }, []);

  useEffect(() => {
    if (mount) {
      if (isValidOnGetUser) {
        setUserList([]);
      }

      onGetUserListAsync(meetingChapter.bureauGroupId);
    }

    !mount && setMount(true);
  }, [meetingChapter.bureauGroupId]);

  const isValidOnGetUser = useMemo(() => {
    return !meetingChapter.bureauGroupId;
  }, [meetingChapter.bureauGroupId]);

  const onChange = (key: keyof CtMeetingChapter, value: string | number) => {
    setMeetingChapter({
      ...meetingChapter,
      [key]: value,
    });
  };

  const onSubmit = () => {
    submitForm(meetingChapter, true);

    if (!meetingChapter.bureauGroupId ||
      !meetingChapter.dutyOfficerUserId ||
      !meetingChapter.queueType) {
      return;
    }

    props.onSubmit(meetingChapter);
  };

  const onChangeQueueType = async (value: string | number) => {
    if (value === QueueType.RUN_QUEUE_ID) {
      const { data, status } = await c0202.runQueueAsync(props.runQueueData);

      if (status === HttpStatusCode.OK) {
        setMeetingChapter({
          ...meetingChapter,
          dutyOfficerUserId: data.dutyOfficerUserId,
          dutyOfficerUserFullName: data.dutyOfficerUserFullName,
          bureauGroupId: data.bureauGroupId,
          bureauGroupName: data.bureauGroupName,
          queueType: QueueType.RUN_QUEUE_ID,
        });

        setDisabled(true);
      }
    } else if (value === QueueType.SWAP_ID) {
      setMeetingChapter({
        ...meetingChapter,
        queueType: QueueType.SWAP_ID,
        dutyOfficerUserId: "",
        dutyOfficerUserFullName: "",
        bureauGroupId: "",
        bureauGroupName: "",
      });

      setDisabled(false);
    } else {
      setMeetingChapter({
        ...meetingChapter,
        queueType: QueueType.BY_CHAPTER_ID,
        dutyOfficerUserId: "",
        dutyOfficerUserFullName: "",
        bureauGroupId: "",
        bureauGroupName: "",
      });

      setDisabled(false);
    }
  };

  const onHide = () => {
    props.onHide();
  };

  return (
    <Modal
      show={props.data.show}
      icon={<FaUserFriends />}
      title="ผู้จัดทำรายงานการประชุม">
      <div className="d-flex flex-column mt-3">
        <Selector
          label="เปลี่ยนโดยวิธี"
          rule={{ required: true }}
          items={queTypeItems}
          value={meetingChapter.queueType}
          onChange={val => onChangeQueueType(val)} />
        <Selector
          name='bureauGroupId'
          label="กลุ่มงาน"
          items={bureauGroupItem}
          rule={{ required: true }}
          value={meetingChapter.bureauGroupId}
          disabled={disabled}
          onChange={val => onChange('bureauGroupId', val)} />
        <Selector
          name='dutyOfficerUserId'
          label="เจ้าหน้าที่รับผิดชอบ"
          items={userList}
          rule={{ required: true }}
          value={meetingChapter.dutyOfficerUserId}
          disabled={disabled}
          onChange={val => onChange('dutyOfficerUserId', val)} />
      </div>
      <div className="d-flex justify-content-end gap-2">
        <Button variant="outline-primary" onClick={onHide}>
          ยกเลิก
        </Button>
        <Button variant="primary" onClick={onSubmit}>
          บันทึก
        </Button>
      </div>
    </Modal>
  );
}

function MeetingChapterModal(props: {
  show: boolean;
  onHide: () => void;
  meetingChapterList: CtMeetingChapter[];
  meetingTypeMinuteRange?: number;
  criteria: C0202Criteria;
  page: number;
  size: number;
}) {
  const { bureauGroups, smBureaus } = useLoaderData() as Loader;
  const [meetingChapterData, setMeetingChapterData] = useState<CtMeetingChapter>({} as CtMeetingChapter);
  const [bureauGroupItem, setBureauGroupItem] = useState<ItemModel[]>([]);
  const [bureauId, setBureauId] = useState("");
  const [userList, setUserList] = useState<ItemModel[]>([]);
  const submit = useSubmit();
  const { id } = useParams();

  useEffect(() => {
    if (props.show) {
      setDefaultCounterPathAndChapter();

      const bureauCode = smBureaus.filter(f => f.label.includes(Code.BUREAU_CODE));
      const bureauGroupFilterData = [...bureauGroups.filter(f => f.id == bureauCode[0].value).slice(2)];

      setBureauId((bureauCode[0].value) as string);
      setBureauGroupItem(bureauGroupFilterData);
    }
  }, [props.show]);

  const onHideModal = () => {
    props.onHide();
    setMeetingChapterData({} as CtMeetingChapter);
  };

  const onChangeCreateData = (prop: keyof CtMeetingChapter, value?: string | number | Date) => {
    setMeetingChapterData({ ...meetingChapterData, [prop]: value });
  };

  const onGetUserListAsync = async (bureauId: string, bureauGroupId: string) => {
    onChangeCreateData("bureauGroupId", bureauGroupId);

    if (bureauId && bureauGroupId) {
      const criteria: UserSelection = {
        bureauId,
        bureauGroupId,
      };

      const { data, status } = await selectionService.getUserSelectionAsync(SelectionType.SM_USER, criteria);

      if (status === HttpStatusCode.OK) {
        setUserList(data);
      }
    }
  };

  const setDefaultCounterPathAndChapter = () => {
    if (props.meetingChapterList && props.meetingChapterList.length > 0) {
      const meetingChapterList = props.meetingChapterList;
      const lastData = meetingChapterList[meetingChapterList.length - 1];
      const checkCounterPath = lastData.chapter % 2;

      if (checkCounterPath === 0) {
        lastData.counterPath += 1;
      }

      const lastStartTime = new Date(props.meetingChapterList[props.meetingChapterList.length - 1].endTime!);
      const lastEndTime = new Date(props.meetingChapterList[props.meetingChapterList.length - 1].endTime!);

      lastStartTime.setHours(lastStartTime.getHours(), lastStartTime.getMinutes());
      lastEndTime.setHours(lastEndTime.getHours(), lastEndTime.getMinutes() + (props.meetingTypeMinuteRange ?? 0));

      setMeetingChapterData({
        ...meetingChapterData,
        counterPath: lastData.counterPath,
        chapter: lastData.chapter + 1,
        startTime: lastStartTime,
        endTime: lastEndTime,
      });

      return;
    }

    setMeetingChapterData({ ...meetingChapterData, counterPath: 1, chapter: 1 });
  };

  const createMeetingChapterAsync = async () => {
    submitForm();

    if (!meetingChapterData.bureauGroupId ||
      !meetingChapterData.dutyOfficerUserId ||
      !meetingChapterData.startTime ||
      !meetingChapterData.endTime) {
      return toast.warn("กรุณากรอกข้อมูลให้ครบถ้วน");
    }

    if (id) {
      const { status } = await c0202.createMeetingChapterAsync(id, meetingChapterData);

      if (status === HttpStatusCode.CREATED) {
        toast.success("เพิ่มตอนสำเร็จ");

        onHideModal();
        submit({
          criteria: JSON.stringify(props.criteria),
          listPage: props.page,
          listSize: props.size,
        });
      }
    }
  };

  return (
    <Modal show={props.show}>
      <h5>
        <FaUserFriends className="me-2" />เพิ่มตอน
      </h5>
      <div>
        <Input
          label="ตอนที่"
          disabled
          value={meetingChapterData.chapter}
          onChange={(value) => onChangeCreateData("chapter", value)} />
        <Selector
          label="กลุ่มงาน"
          isHideClearButton
          rule={{ required: true }}
          value={meetingChapterData.bureauGroupId}
          items={bureauGroupItem}
          onChange={(value) => onGetUserListAsync(bureauId, value as string)} />
        <Selector
          label="ชื่อผู้จัดทำรายงานการประชุม"
          rule={{ required: true }}
          items={userList}
          value={meetingChapterData.dutyOfficerUserId}
          onChange={(value) => onChangeCreateData("dutyOfficerUserId", value)} />
        <InputTime
          label="เวลาเริ่ม"
          rule={{ required: true }}
          value={meetingChapterData.startTime}
          onChange={(value) => onChangeCreateData("startTime", value)} />
        <InputTime
          label="เวลาสิ้นสุด"
          rule={{ required: true }}
          value={meetingChapterData.endTime}
          onChange={(value) => onChangeCreateData("endTime", value)} />
      </div>
      <div className="d-flex justify-content-between gap-2">
        <Button
          className="w-100"
          variant="outline-primary"
          onClick={onHideModal}>
          ยกเลิก
        </Button>
        <Button
          className="w-100"
          onClick={createMeetingChapterAsync}>
          บันทึก
        </Button>
      </div>
    </Modal>
  );
}
