import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartEvent,
  ActiveElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Chart } from "models";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

interface Props {
  chartData: Chart;
  onClickChart?: (label: string) => void;
}

ChartJS.defaults.font.family = "Prompt";
ChartJS.defaults.font.size = 15;

export function Chart(props: Props) {
  const handleChartClick = (_: ChartEvent, elements: ActiveElement[]) => {
    if (elements.length > 0) {
      const getLabel = data.datasets[elements[0].datasetIndex].label;

      props.onClickChart?.(getLabel);
    }
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
    },
    onClick: handleChartClick,
  };

  const labels = props.chartData.labels;

  const data = {
    labels,
    datasets: props.chartData.chartData,
  };

  return (
    <div>
      <Bar
        className="w-100 h-100"
        options={options}
        data={data} />
    </div>
  );
}