import http from 'utils/axios';
import { MeetingPeriodModel } from 'models';

const getMeetingPeriodListAsync = async (page: number, size: number, keyword?: string) => {
  const params = {
    page,
    size,
    keyword,
  };

  return await http.get(`api/snmeetingperiod`, { params });
}

const createMeetingPeriodAsync = async (data: MeetingPeriodModel) =>
  await http.post("api/snmeetingperiod", data);

const updateMeetingPeriodAsync = async (data: MeetingPeriodModel) =>
  await http.put(`api/snmeetingperiod/${data.id}`, JSON.stringify(data.name),
    { headers: { 'Content-Type': 'application/json' } });

const deleteMeetingPeriodAsync = async (id: string) =>
  await http.delete(`api/snmeetingperiod/${id}`);

const syncMeetingPeriodDataAsync = async () => {
  return await http.post('api/snmeetingperiod/sync-data');
};

const s0103 = {
  getMeetingPeriodListAsync,
  createMeetingPeriodAsync,
  updateMeetingPeriodAsync,
  deleteMeetingPeriodAsync,
  syncMeetingPeriodDataAsync,
};

export default s0103;