import http from 'utils/axios';
import { CommitteeModel, CommitteeCriteria } from 'models';

const getCommitteeListAsync = async (page: number, size: number, criteria?: CommitteeCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get(`api/ctcommittee`, { params });
};

const createCommitteeAsync = async (data: CommitteeModel) =>
  await http.post(`api/ctcommittee`, data);

const updateCommitteeAsync = async (data: CommitteeModel) =>
  await http.put(`api/ctcommittee/${data.id}`, data);

const deleteCommitteeAsync = async (id: string) =>
  await http.delete(`api/ctcommittee/${id}`);

const syncDataAsync = async () =>
  await http.post('/api/ctcommittee/sync-data');

const c0102 = {
  getCommitteeListAsync,
  createCommitteeAsync,
  updateCommitteeAsync,
  deleteCommitteeAsync,
  syncDataAsync,
};

export default c0102;