import { Layout, Selector, Table, Modal, Card, Input, TextArea } from "components";
import { HttpStatusCode, ModalType, MovementType, PersonType, statusMeetingItems } from "constant";
import { Debaters, ItemModel, MeetingAgenda, SnMeetingAgendas, SnmeetingAgendatEventLogDetail } from "models";
import { useCallback, useEffect, useState } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { FaAngleLeft, FaCheckCircle, FaChevronCircleDown, FaChevronCircleUp, FaPlus, FaRegEdit, FaTrashAlt } from "react-icons/fa";
import { useLoaderData, useNavigate, useParams, useSubmit } from "react-router-dom";
import { s0203 } from "services";
import { formatDateTh, formatTimeToInput, isNullOrEmpty, showModalRemoveAsync, submitForm } from "utils";
import toast from "utils/toast";

type Loader = {
  snMeetingAgendaEventLogDetail: SnmeetingAgendatEventLogDetail,
  snAgendaTypeItems: ItemModel[],
  smUserItems: ItemModel[],
  attendTypes: ItemModel[],
  smBureauGroup: ItemModel[],
  meetingMembers: ItemModel[],
  debatersType: ItemModel[],
}

interface ModalProps {
  showModal: boolean;
  onHide: () => void;
  index?: number;
  detail: SnmeetingAgendatEventLogDetail;
  setDetail: (value: React.SetStateAction<SnmeetingAgendatEventLogDetail>) => void;
  setAgendaList: (value: React.SetStateAction<MeetingAgenda[]>) => void
}

interface ModalAgendaProps {
  showModal: boolean;
  onHide: () => void;
  modalType: ModalType;
  snAgenData?: SnMeetingAgendas;
}

export default function S0203Detail() {
  const { snMeetingAgendaEventLogDetail } = useLoaderData() as Loader;
  const [detail, setDetail] = useState<SnmeetingAgendatEventLogDetail>({} as SnmeetingAgendatEventLogDetail);
  const [agendaList, setAgendaList] = useState<MeetingAgenda[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalAgenda, setShowModalAgenda] = useState(false);
  const [modalType, setModalType] = useState<ModalType>(ModalType.ADD);
  const [debatersDataIndex, setDebatersDataIndex] = useState<number>();
  const [snAgendaData, setSnAgendaData] = useState<SnMeetingAgendas>({} as SnMeetingAgendas);
  const navigate = useNavigate();
  const submit = useSubmit();
  const { id } = useParams();

  useEffect(() => {
    if (snMeetingAgendaEventLogDetail) {
      setDetail(snMeetingAgendaEventLogDetail);
      setAgendaList(snMeetingAgendaEventLogDetail.meetingAgendas);
    }
  }, [snMeetingAgendaEventLogDetail]);

  useEffect(() => {
    if (detail) {
      setAgendaList(detail.meetingAgendas);
    }
  }, [detail]);

  const convertStatusToText = (status: string) => {
    const findStatus = statusMeetingItems.find(s => s.value === status)?.label;

    return findStatus;
  };

  const handleOnShowDebatersModal = (index: number) => {
    setDebatersDataIndex(index);
    setShowModal(true);
  };

  const handleOnHideDebatersModal = () => {
    setDebatersDataIndex(undefined);
    setShowModal(false);
  };

  const handleChangePositionRow = (movementType: MovementType, index: number) => {
    if (movementType === MovementType.UP) {
      if (index === 0) {
        return;
      }

      const currentData = detail.meetingAgendas[index];
      const previousData = detail.meetingAgendas[index - 1];

      const newcurrentData = {
        ...previousData,
        no: currentData.no,
      } as MeetingAgenda;

      const newPreviousData = {
        ...currentData,
        no: previousData.no,
      } as MeetingAgenda;

      const temp = [...detail.meetingAgendas];
      temp.splice((index - 1), 2, newPreviousData, newcurrentData);

      setDetail({ ...detail, meetingAgendas: temp });
    }

    if (movementType === MovementType.DOWN) {
      if (index === detail.meetingAgendas.length - 1) {
        return;
      }

      const currentData = detail.meetingAgendas[index];
      const previousData = detail.meetingAgendas[index + 1];

      const newcurrentData = {
        ...previousData,
        no: currentData.no,
      } as MeetingAgenda;

      const newPreviousData = {
        ...currentData,
        no: previousData.no,
      } as MeetingAgenda;

      const temp = [...detail.meetingAgendas];
      temp.splice((index), 2, newcurrentData, newPreviousData);

      setDetail({ ...detail, meetingAgendas: temp });
    }
  };

  const onSaveAsync = async () => {
    if (id) {
      const { status } = await s0203.updateNoDebatersAsync(id, detail.meetingAgendas);

      if (status === HttpStatusCode.ACCEPTED) {
        toast.success('บันทึกข้อมูลสำเร็จ');

        submit({});
      }
    }
  };

  const onCreateAgenda = () => {
    setShowModalAgenda(true);
    setModalType(ModalType.ADD);
  };

  const onEditAgenda = (data: MeetingAgenda) => {
    const snMeetingData: SnMeetingAgendas = {
      id: data.id,
      no: data.no,
      sequence: data.sequence,
      bullet: data.bullet,
      agendaTypeName: data.agendaTypeName,
      agendaTitle: data.agendaTitle
    };

    setSnAgendaData(snMeetingData);
    setShowModalAgenda(true);
    setModalType(ModalType.EDIT);
  };

  const onHideModalAgenda = () => {
    setShowModalAgenda(false);
    setSnAgendaData({} as SnMeetingAgendas);
  };

  const onRemoveSnAgendaAsync = async (id: string) => {
    if (!await showModalRemoveAsync()) {
      return;
    }

    const { status } = await s0203.deleteSnMeetingAgendaEventLogAgendaAsync(id);

    if (status === HttpStatusCode.NO_CONTENT) {
      toast.success("ลบข้อมูลสำเร็จ");

      submit({});
    }
  };

  return (
    <Layout title="บันทึกเหตุการณ์">
      <Form>
        <div className="d-flex justify-content-between my-3">
          <Button
            variant="outline-primary"
            type="button"
            onClick={() => navigate('/s0203')}>
            <FaAngleLeft className="me-2" />ย้อนกลับ
          </Button>
          <div className="d-flex">
            <Button
              variant="primary"
              type="button"
              onClick={onSaveAsync}>
              <FaCheckCircle className="me-2" />บันทึก
            </Button>
          </div>
        </div>
        <Card>
          <label><b>ข้อมูลการประชุม</b></label>
          <Row>
            <Col sm={6} md={4} xxl={3}>
              <p>ประเภทการประชุม<span className="mx-2">: {detail.meetingTypeName}</span></p>
            </Col>
            <Col sm={6} md={4} xxl={3}>
              <p>ครั้งที่<span className="mx-2">: {detail.time}</span></p>
            </Col>
            <Col sm={6} md={4} xxl={3}>
              <p>สมัยที่ประชุม<span className="mx-2">: {detail.meetingPeriodName}</span></p>
            </Col>
            <Col sm={6} md={4} xxl={3}>
              <p>ชื่อการประชุม<span className="mx-2">: {detail.meetingName}</span></p>
            </Col>
            <Col sm={6} md={4} xxl={3}>
              <p>ณ ห้องประชุม<span className="mx-2">: {detail.meetingRoomName}</span></p>
            </Col>
            <Col sm={6} md={4} xxl={3}>
              <p>วันที่ประชุม<span className="mx-2">: {formatDateTh(detail.startDate)}</span></p>
            </Col>
            <Col sm={6} md={4} xxl={3}>
              <p>เวลา<span className="mx-2">:
                {` ${formatTimeToInput(detail.startTime)} - ${formatTimeToInput(detail.endTime)}`}
              </span></p>
            </Col>
            <Col sm={6} md={4} xxl={3}>
              <p>สถานะ<span className="mx-2">: {convertStatusToText(detail.status)}</span></p>
            </Col>
          </Row>
        </Card>
        <div className="d-flex justify-content-between mt-3">
          <Form.Label>
            ตารางวาระการประชุม
          </Form.Label>
          <Button
            variant="outline-primary"
            type="button"
            onClick={onCreateAgenda}>
            <FaPlus className="me-2" />เพิ่มวาระการประชุม
          </Button>
        </div>
        <Row className="mt-3">
          <Col>
            <div className="table-relative-fix">
              <div className="table-scroll">
                <Table hidePagination>
                  <Table.Header>
                    <Table.Row>
                      <Table.Column minWidth={100} maxWidth={120}>ลำดับ</Table.Column>
                      <Table.Column minWidth={100}>ระเบียบวาระที่</Table.Column>
                      <Table.Column minWidth={100}>ระเบียบวาระย่อยที่</Table.Column>
                      <Table.Column minWidth={200}>ประเภทวาระ</Table.Column>
                      <Table.Column minWidth={300} className="text-break">หัวข้อวาระ</Table.Column>
                      <Table.Column minWidth={200}>ผู้อภิปราย</Table.Column>
                      <Table.Column minWidth={100} className="fix-col" />
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {agendaList?.map((data, index) => (
                      <Table.Row key={data.id}>
                        <Table.Cell center>
                          {data.no ? data.no.toString() : ""}
                        </Table.Cell>
                        <Table.Cell center>
                          {data.sequence ? data.sequence.toString() : ""}
                        </Table.Cell>
                        <Table.Cell center>
                          {data.bullet}
                        </Table.Cell>
                        <Table.Cell>
                          {data.agendaTypeName}
                        </Table.Cell>
                        <Table.Cell>
                          {data.agendaTitle}
                        </Table.Cell>
                        <Table.Cell center>
                          <Button
                            variant="outline-primary"
                            size="sm"
                            className="mx-auto w-75"
                            onClick={() => handleOnShowDebatersModal(index)}>
                            ผู้อภิปราย
                          </Button>
                        </Table.Cell>
                        <Table.Cell className="fix-col">
                          <div className="px-5 d-flex justify-content-end gap-3">
                            <FaChevronCircleUp
                              className="text-primary fs-5 cursor-pointer"
                              onClick={() => handleChangePositionRow(MovementType.UP, index)} />
                            <FaChevronCircleDown
                              className="text-primary fs-5 cursor-pointer"
                              onClick={() => handleChangePositionRow(MovementType.DOWN, index)} />
                            <FaRegEdit
                              className="text-primary fs-5 cursor-pointer"
                              onClick={() => onEditAgenda(data)} />
                            <FaTrashAlt
                              className="text-primary fs-5 cursor-pointer"
                              onClick={() => onRemoveSnAgendaAsync(data.id)} />
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
      <ModalAgenda
        showModal={showModalAgenda}
        onHide={onHideModalAgenda}
        modalType={modalType}
        snAgenData={snAgendaData} />
      <ModalDebater
        showModal={showModal}
        onHide={handleOnHideDebatersModal}
        index={debatersDataIndex}
        detail={detail}
        setDetail={setDetail}
        setAgendaList={setAgendaList} />
    </Layout>
  );
}

function ModalAgenda(props: ModalAgendaProps) {
  const { snAgendaTypeItems } = useLoaderData() as Loader;
  const [snAgendaData, setSnAgendaData] = useState<SnMeetingAgendas>({} as SnMeetingAgendas);
  const { id } = useParams();
  const submit = useSubmit();

  useEffect(() => {
    if (props.snAgenData?.id && props.showModal) {
      const snAgendaData = props.snAgenData;
      snAgendaData.snAgendaTypeId = snAgendaTypeItems.find(s => s.label === snAgendaData.agendaTypeName)?.value?.toString() || "";

      setSnAgendaData(snAgendaData);
    }

    if (!props.showModal) {
      setSnAgendaData({} as SnMeetingAgendas);
    }
  }, [props.snAgenData, props.showModal]);

  const onSaveAgendaAsync = async () => {
    submitForm();

    if (
      isNullOrEmpty(snAgendaData.no) ||
      isNullOrEmpty(snAgendaData.sequence) ||
      !snAgendaData.snAgendaTypeId ||
      !snAgendaData.agendaTitle) {
      return;
    }

    if (id && props.modalType === ModalType.ADD) {
      const { status } = await s0203.createSnMeetingAgendaEventLogAgendaAsync(id, snAgendaData);

      if (status === HttpStatusCode.CREATED) {
        toast.success("บันทึกข้อมูลสำเร็จ");

        submit({});
      }
    } else {
      const { status } = await s0203.updateSnMeetingAgendaEventLogAgendaAsync(snAgendaData.id!, snAgendaData);

      if (status === HttpStatusCode.ACCEPTED) {
        toast.success("แก้ไขข้อมูลสำเร็จ");

        submit({});
      }
    }

    props.onHide();
  };

  const onChangeSnAgendaData = (value: string | number, prop: keyof SnMeetingAgendas) => {
    setSnAgendaData({ ...snAgendaData, [prop]: value });
  };

  const onChangeAgendaType = (value: string | number) => {
    const findAgenda = snAgendaTypeItems.find(s => s.value === value);
    let snAgendatTypeId = "";
    let agendaTypeName = "";

    if (findAgenda) {
      snAgendatTypeId = value.toString();
      agendaTypeName = findAgenda.label;
    }

    setSnAgendaData({ ...snAgendaData, snAgendaTypeId: snAgendatTypeId, agendaTypeName: agendaTypeName });
  };

  return (
    <Modal
      show={props.showModal}
      title={`${props.modalType === ModalType.ADD ? 'เพิ่ม' : 'แก้ไข'}วาระการประชุม`}>
      <Form>
        <div className="d-flex flex-column mt-3">
          <Input
            name="no"
            type="number"
            label="ลำดับ"
            rule={{ required: true }}
            value={snAgendaData.no}
            onChange={(value) => onChangeSnAgendaData(value, 'no')} />
          <Input
            name="sequence"
            type="number"
            label="ระเบียบวาระที่"
            rule={{ required: true }}
            value={snAgendaData.sequence}
            onChange={(value) => onChangeSnAgendaData(value, 'sequence')} />
          <Input
            name="bullet"
            label="ระเบียบวาระย่อยที่"
            value={snAgendaData.bullet}
            onChange={(value) => onChangeSnAgendaData(value, 'bullet')} />
          <Selector
            name="snAgendaTypeId"
            label="ประเภทวาระ"
            rule={{ required: true }}
            items={snAgendaTypeItems}
            value={snAgendaData.snAgendaTypeId}
            onChange={(value) => onChangeAgendaType(value)} />
          <TextArea
            name="agendaTitle"
            label="หัวข้อ"
            rule={{ required: true }}
            value={snAgendaData.agendaTitle}
            onChange={(value) => onChangeSnAgendaData(value, 'agendaTitle')} />
        </div>
        <div className="d-flex justify-content-end gap-2">
          <Button
            className="w-100"
            variant="outline-primary"
            type="button"
            onClick={() => props.onHide()}>
            ยกเลิก
          </Button>
          <Button
            className="w-100"
            variant="primary"
            type="button"
            onClick={onSaveAgendaAsync}>
            บันทึก
          </Button>
        </div>
      </Form>
    </Modal >
  );
}

function ModalDebater(props: ModalProps) {
  const { attendTypes, smBureauGroup, smUserItems, meetingMembers, debatersType } = useLoaderData() as Loader;
  const [meetingAgendaData, setMeetingAgendaData] = useState<MeetingAgenda>({} as MeetingAgenda);
  const [debaters, setDebaters] = useState<Debaters[]>([]);
  const [debaterId, setDebaterId] = useState<string>();
  const submit = useSubmit();
  const { id } = useParams();

  useEffect(() => {
    if (props.index !== undefined && props.showModal) {
      const debatersList = props.detail.meetingAgendas[props.index];

      setMeetingAgendaData(debatersList);
      setDebaterId(debatersList.id);

      if (debatersList.debaters) {
        setDebaters(debatersList.debaters);
      }
    }
  }, [props.index, props.showModal]);

  const ValidateDebatersData = () => {
    if (!debaters.every(d => d.memberType) ||
      !debaters.every(d => d.dutyOfficeUserId) ||
      !debaters.every(d => d.chapter) ||
      !debaters.every(d => d.typeId) ||
      debaters.some(d => d.memberType === PersonType.Other && !d.memberName)) {
      toast.warn("กรุณากรอกข้อมูลให้ครบถ้วน");

      return false;
    }

    return true;
  }

  const addDebaterAsync = async () => {
    const debatersList = [...debaters];

    if (debatersList.length > 0) {
      submitForm();

      if (!ValidateDebatersData()) {
        return;
      }

      if (!debaterId) {
        return;
      }

      const { status } = await s0203.updateDebatersAsync(debaterId, debaters);

      if (status === HttpStatusCode.ACCEPTED && id !== undefined) {
        const { data } = await s0203.getSnMeetingAgendaEventLogDetailAsync(id);

        if (props.index !== undefined && data) {
          props.setDetail(data);
          props.setAgendaList(data.meetingAgendas);

          const newDebatersList = data.meetingAgendas[props.index];

          setMeetingAgendaData(newDebatersList);
          setDebaterId(newDebatersList.id);

          if (newDebatersList.debaters) {
            const lastData = { ...newDebatersList.debaters[newDebatersList.debaters.length - 1] };
            lastData.id = undefined;

            newDebatersList.debaters.push(lastData);

            setDebaters(newDebatersList.debaters);
            return;
          }
        }
      }
    }

    debatersList.push({} as Debaters);
    setDebaters(debatersList);
  };

  const removeDebaterAsync = async (index: number) => {
    const debatersList = [...debaters];

    debatersList.splice(index, 1);
    setDebaters(debatersList);
  };

  const onChangeDebater = (value: string | number, prop: keyof Debaters, index: number) => {
    const debatersList = [...debaters];
    let debater = debaters[index];

    if (prop === 'memberType') {
      debater.memberType = value.toString();

      if (value.toString() === "อื่น ๆ") {
        debater.snMemberId = undefined;
      } else {
        debater.memberName = "";
      }
    }

    if (prop === 'snMemberId') {
      const findUser = meetingMembers.find(f => f.value === value);

      debater.snMemberId = undefined;
      debater.memberName = "";

      if (findUser) {
        debater.snMemberId = findUser.value.toString();
        debater.memberName = findUser.label;
      }
    }

    if (prop === 'typeId') {
      const findTypeId = debatersType.find(f => f.value === value)?.id;

      if (findTypeId) {
        debater.typeId = findTypeId;
      }
    }

    if (prop === 'otherType') {
      debater.otherType = value ? value.toString() : undefined;
    }

    if (prop === 'memberName') {
      debater.snMemberId = undefined;
      debater.memberName = value.toString();
    }

    if (prop === 'chapter') {
      debater.chapter = Number(value);
    }

    if (prop === 'bureauGroupId') {
      debater.bureauGroupId = value.toString();
      debater.dutyOfficeUserId = "";
    }

    if (prop === 'dutyOfficeUserId') {
      const filterGroupId = smUserItems.find(f => f.value === value)?.id;

      if (filterGroupId) {
        const userGroup = smBureauGroup.find(f => f.value === filterGroupId);

        if (userGroup) {
          debater.bureauGroupId = userGroup.value.toString();
        }
      }

      debater.dutyOfficeUserId = value.toString();
    }

    debatersList.splice(index, 1, debater);

    setDebaters(debatersList);
  };

  const onSaveDebaterAsync = async () => {
    submitForm();

    if (!ValidateDebatersData()) {
      return;
    }

    if (debaterId) {
      const { status } = await s0203.updateDebatersAsync(debaterId, debaters);

      if (status === HttpStatusCode.ACCEPTED) {
        toast.success("บันทึกข้อมูลสำเร็จ");

        onHide();
        submit({});
      }
    }
  };

  const onHide = () => {
    setDebaters([]);

    props.onHide();
  };

  const attendTypesFilter = useCallback((type: string) => {
    return attendTypes.find(f => f.value === type)?.value;
  }, [attendTypes]);

  const debaterTypeName = (id: string) => {
    const filterData = debatersType.find(mm => mm.id === id);

    if (filterData) {
      return filterData.value;
    }
    return "";
  };

  return (
    <Modal
      fullscreen
      show={props.showModal}>
      <Form>
        <Row>
          <Col xs={12}>
            <p>รายละเอียดวาระ</p>
            <p className="mx-4">{meetingAgendaData.sequence} {meetingAgendaData.bullet} : {meetingAgendaData.agendaTypeName}</p>
            <p className="mx-5">{meetingAgendaData.agendaTitle}</p>
          </Col>
        </Row>
        <div className="d-flex justify-content-between">
          <Form.Label>
            รายชื่อผู้อภิปราย
          </Form.Label>
          <Button
            variant="outline-primary"
            type="button"
            onClick={addDebaterAsync}>
            <FaPlus size={15} className="me-2" />เพิ่ม
          </Button>
        </div>
        <Table onTop hidePagination className="mt-3">
          <Table.Header>
            <Table.Row>
              <Table.Column minWidth={100}>ลำดับ</Table.Column>
              <Table.Column minWidth={250}>สถานะ</Table.Column>
              <Table.Column minWidth={250}>เหตุการณ์</Table.Column>
              <Table.Column minWidth={300}>รายชื่อ</Table.Column>
              <Table.Column minWidth={250}>ตำแหน่ง</Table.Column>
              <Table.Column minWidth={150}>ตอน</Table.Column>
              <Table.Column minWidth={300}>ผู้จด</Table.Column>
              <Table.Column minWidth={50}></Table.Column>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {debaters?.map((data, index) => (
              <Table.Row key={index}>
                <Table.Cell center>
                  {(index + 1).toString()}
                </Table.Cell>
                <Table.Cell>
                  <Selector
                    items={debatersType}
                    value={debaterTypeName(data.typeId)}
                    onChange={(value) => onChangeDebater(value, 'typeId', index)}
                    rule={{ required: true }} />
                </Table.Cell>
                <Table.Cell>
                  <TextArea
                    value={data.otherType}
                    onChange={(value) => onChangeDebater(value, 'otherType', index)} />
                </Table.Cell>
                <Table.Cell>
                  {attendTypesFilter(data.memberType) === PersonType.Other ?
                    <Input
                      value={data.memberName}
                      onChange={(value) => onChangeDebater(value, 'memberName', index)} />
                    :
                    <Selector
                      rule={{ required: true }}
                      items={meetingMembers}
                      value={data.snMemberId}
                      onChange={(value) => onChangeDebater(value, 'snMemberId', index)} />
                  }
                </Table.Cell>
                <Table.Cell>
                  <Selector
                    items={attendTypes}
                    value={data.memberType}
                    onChange={(value) => onChangeDebater(value, 'memberType', index)} />
                </Table.Cell>
                <Table.Cell>
                  <Input
                    type="number"
                    textCenter
                    value={data.chapter}
                    onChange={(value) => onChangeDebater(value, 'chapter', index)}
                    rule={{ required: true }} />
                </Table.Cell>
                <Table.Cell>
                  <Selector
                    items={smUserItems}
                    value={data.dutyOfficeUserId}
                    onChange={(value) => onChangeDebater(value, 'dutyOfficeUserId', index)}
                    rule={{ required: true }} />
                </Table.Cell>
                <Table.Cell>
                  <FaTrashAlt className="my-2 fs-5" onClick={() => removeDebaterAsync(index)} />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Row>
          <Col xs={12}>
            <Button
              className="w-100 mt-3"
              variant="outline-primary"
              type="button"
              onClick={addDebaterAsync}>
              <FaPlus size={15} className="me-2" />เพิ่ม
            </Button>
          </Col>
        </Row>
        <div className="d-flex gap-3 justify-content-end mt-5">
          <Button
            variant="outline-primary"
            type="button"
            onClick={onHide}>
            ยกเลิก
          </Button>
          <Button
            variant="primary"
            type="button"
            onClick={onSaveDebaterAsync}>
            บันทึก
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
