import { SelectionType } from "constant";
import { CtReviewChapterCriteria, CtReviewChapterDocumentCriteria } from "models";
import { LoaderFunctionArgs } from "react-router-dom";
import { c0205, selectionService } from "services";

export const c0205LoaderAsync = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 1;
  const size = url.searchParams.get('size') || 10;
  const criteriaStringify = url.searchParams.get('criteria') || "{\"isNotApprove\":true}";
  const criteria = JSON.parse(criteriaStringify) as CtReviewChapterDocumentCriteria;

  const [bureaus, committees, subCommittees, ctMakeDocumentList] = await Promise.all([
    selectionService.getSelectionAsync(SelectionType.SM_BUREAU),
    selectionService.getSelectionAsync(SelectionType.CT_COMMITTEE),
    selectionService.getSelectionAsync(SelectionType.CT_SUBCOMMITTEE),
    c0205.getCtReviewChapterDocumentListAsync(+page, +size, criteria),
  ]);

  return {
    bureausItems: bureaus.data,
    committeesItems: committees.data,
    subCommitteesItems: subCommittees.data,
    data: {
      rows: ctMakeDocumentList.data.rows,
      totalRows: ctMakeDocumentList.data.totalRows,
      page: +page,
      size: +size,
    },
  };
};

export const c0205DetailLoaderAsync = async ({ params, request }: LoaderFunctionArgs) => {
  const { id } = params;
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 1;
  const size = url.searchParams.get('size') || 10;
  const criteriaStringify = url.searchParams.get('criteria') || '{}';
  const criteria = JSON.parse(criteriaStringify) as CtReviewChapterCriteria;

  if (id) {
    const [listResponse] = await Promise.all([
      c0205.getCtReviewChapterListAsync(id, +page, +size, criteria),
    ]);

    return {
      data: {
        rows: listResponse.data.rows,
        totalRows: listResponse.data.totalRows,
        page: +page,
        size: +size,
      },
    };
  }
};