import { Card, Collabora, CollaboraRef, Layout, VideoOption } from "components";
import { Dispatch, SetStateAction, createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import { FaAngleLeft, FaUndo } from "react-icons/fa";
import { useLoaderData, useLocation, useNavigate, useParams } from "react-router-dom";
import { CtDocuments, CtInquiryMeetingDetail, CtInquiryMeetingDetailHeader } from "models";
import { formatDateTh, getTime, yearToTHFormat } from "utils";
import { HttpStatusCode, VideoControl } from "constant";
import { DetailType } from "./Detail";
import Video from "components/Video/Video";
import { ctService } from "services";

type Loader = { header: CtInquiryMeetingDetailHeader; video: File; documentData: CtDocuments[]; fileId: string[]; };

type InquiryMeetingContext = {
  documentSelected: CtDocuments;
  setDocumentSelected: Dispatch<SetStateAction<CtDocuments>>;
};

const Context = createContext({} as InquiryMeetingContext);

export default function C0302Document() {
  const { video, documentData, fileId } = useLoaderData() as Loader;
  const [documentSelected, setDocumentSelected] = useState<CtDocuments>({} as CtDocuments);
  const collaboraRef = useRef<CollaboraRef>(null);
  const [videoSelect, setVideoSelect] = useState<File>();
  const [fileIndex, setFileIndex] = useState<number>(0);
  const { docId, type } = useParams();

  useEffect(() => {
    if (documentData) {
      setDocumentSelected(documentData[0]);
      setVideoSelect(video);
    }
  }, [docId]);

  useEffect(() => {
    if (type === DetailType.MEETING_RANK) {
      if (fileId.length) {
        getVideoAsync(fileId[0]);
        setFileIndex(0);
      }
    }
  }, [fileId]);

  const getVideoAsync = async (videoId: string) => {
    if (type === DetailType.MEETING_RANK) {
      const { data, status } = await ctService.getVideoAsync(videoId);

      if (status === HttpStatusCode.OK) {
        setVideoSelect(data);
      }
    }
  };

  const onEndedAsync = async () => {
    if (type === DetailType.MEETING_RANK) {
      const currentIndex = fileIndex + 1;

      if (fileId.length > currentIndex) {
        getVideoAsync(fileId[currentIndex]);

        setFileIndex(currentIndex);
      } else {
        setFileIndex(0);
      }
    }
  };

  const onLoadedData = () => {
    if (fileIndex) {
      videoRef.current.playRef();
    }
  };

  const videoRef = useRef<VideoOption>({} as VideoOption);

  function loadCollabora() {
    const iframe = document.getElementById(documentSelected.id) as HTMLIFrameElement;

    iframe.contentWindow?.addEventListener('keydown', (e) => clickBtn(e.key));
  }

  function clickBtn(key: string) {
    switch (key) {
      case VideoControl.F10:
        videoRef.current.backRef();
        break;
      case VideoControl.F9:
        videoRef.current.playRef();
        break;
      case VideoControl.F11:
        videoRef.current.aheadRef();
        break;
    }
  }

  const contextValue = useMemo(() => {
    return {
      documentSelected,
      setDocumentSelected,
    }
  }, [documentSelected, setDocumentSelected]);

  return (
    <Context.Provider value={contextValue}>
      <Layout title="รายละเอียดรายงานการประชุม">
        <HeaderButton />
        <Row>
          <Col md={4}>
            <Video
              onEnded={onEndedAsync}
              onLoadedData={onLoadedData}
              ref={videoRef}
              files={videoSelect?.type ? [videoSelect] : []}
              showPlayButton={false}
              showPlayTimeConfig={false}
              showSpeakToText={false}>
              <MeetingDetail />
            </Video>
          </Col>
          <Col md={8}>
            <Collabora
              docId={documentSelected.id}
              docName="s0204"
              height="1000px"
              ref={collaboraRef}
              onCollaboraLoaded={() => loadCollabora()} />
          </Col>
        </Row>
      </Layout>
    </Context.Provider>
  );
}

function HeaderButton() {
  const { documentData } = useLoaderData() as Loader;
  const navigate = useNavigate();
  const { setDocumentSelected } = useContext(Context);
  const { id } = useParams();

  const onChangeDocumentVersion = (value: string | null) => {
    if (value) {
      const findDocument = documentData.find(f => f.id == value);

      setDocumentSelected(findDocument ? findDocument : {} as CtDocuments);
    }
  };

  return (
    <div className="d-flex justify-content-between my-3">
      <Button
        variant="outline-primary"
        type="button"
        onClick={() => navigate(`/c0302/detail/${id}`)}>
        <FaAngleLeft className="me-2" />ย้อนกลับ
      </Button>
      <DropdownButton
        title={<span><FaUndo className="me-2" />เวอร์ชั่นก่อนหน้า</span>}
        drop="down-centered"
        variant="outline-primary"
        onSelect={(value) => onChangeDocumentVersion(value)}>
        {documentData?.map((data) => (
          <Dropdown.Item
            eventKey={data.id}
            key={data.id}>
            {data.versionDocument}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </div>
  );
}

function MeetingDetail() {
  const { header } = useLoaderData() as Loader;
  const headerChapter = useLocation().state as CtInquiryMeetingDetail;
  const type = useParams().type as DetailType;

  return (
    <Card title="ข้อมูลการประชุม" className="mt-3">
      {type === DetailType.MEETING ?
        <Row>
          <Col xs={12}>
            <p>สำนัก<span className="mx-2">:</span>{header.ctBureauName}</p>
          </Col>
          <Col xs={12}>
            <p>คณะกรรมาธิการ/อื่นๆ<span className="mx-2">:</span>{header.ctCommitteeName}</p>
          </Col>
          <Col xs={12}>
            <p>คณะอนุกรรมาธิการ/คณะทำงาน<span className="mx-2">:</span>{header.ctSubCommitteeName}</p>
          </Col>
          <Col xs={12}>
            <p>ครั้งที่<span className="mx-2">:</span>{header.time}</p>
          </Col>
          <Col xs={12}>
            <p>ปี<span className="mx-2">:</span>{yearToTHFormat(header.year)}</p>
          </Col>
          <Col xs={12}>
            <p>ณ ห้องประชุม<span className="mx-2">:</span>{header.smMeetingRoomName}</p>
          </Col>
          <Col xs={12}>
            <p>วันที่ประชุม<span className="mx-2">:</span>{formatDateTh(header.meetingDate)}</p>
          </Col>
          <Col xs={12}>
            <p>เวลา<span className="mx-2">:</span>{`${getTime(header.startTime)} - ${getTime(header.endTime)}`}</p>
          </Col>
        </Row>
        :
        <Row>
          <Col xs={12}>
            <p>ตอนที่<span className="mx-2">:</span>{`${headerChapter.chapter}`}</p>
          </Col>
          <Col xs={12}>
            <p>ช่วงเวลา<span className="mx-2">:</span>{`${getTime(headerChapter.startTime)} - ${getTime(headerChapter.endTime)}`}</p>
          </Col>
          <Col xs={12}>
            <p>ผู้จดรายงานการประชุม<span className="mx-2">:</span>{`${headerChapter.dutyOfficerFullName}`}</p>
          </Col>
          <Col xs={12}>
            <p>ผู้ตรวจทาน<span className="mx-2">:</span>{`${headerChapter.reviewUserFullName}`}</p>
          </Col>
        </Row>}
    </Card>
  );
};