import { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Rule } from 'models';

interface Props {
  label?: string;
  type?: string;
  placeholder?: string;
  rule?: Rule;
  value?: string | number;
  onChange?: (value: string) => void;
  name?: string;
  className?: string;
  rows?: number;
}

export function TextArea(props: Props) {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const addEventListener = () => document.addEventListener('onSubmitCustom', (data) => {
    const event = data as CustomEvent;

    if (event.detail.hasNameOnly) {
      if (props.name) {
        validate(value);
      }
    } else {
      validate(value);
    }
  });

  const getRequired = () => {
    if (props.rule?.required) {
      return <span className='text-danger'>*</span>;
    }

    return null;
  };

  const getType = () => {
    if (props.type) {
      return props.type;
    }

    return 'text';
  };

  const validate = (value?: string | number) => {
    if (!checkRequired(value)) {
      return;
    }

    if (!checkMinLength(value)) {
      return;
    }

    if (!checkMaxLength(value)) {
      return;
    }

    if (!checkMinValue(value)) {
      return;
    }

    if (!checkMaxValue(value)) {
      return;
    }

    setErrorMessage('');
  };

  useEffect(() => {
    addEventListener();
  }, [validate, props.name]);

  const checkRequired = (value?: string | number) => {
    if (props.rule?.required && value !== 0 && !value) {
      setErrorMessage('กรุณากรอกข้อมูล');

      return false;
    }

    return true;
  };

  const checkMinLength = (value?: string | number) => {
    if (props.rule?.minLength && value && value.toString().length < props.rule.minLength) {
      setErrorMessage(`กรุณระบุอย่างน้อย ${props.rule.minLength} ตัวอักษร`);

      return false;
    }

    return true;
  };

  const checkMaxLength = (value?: string | number) => {
    if (props.rule?.maxLength && value && value.toString().length > props.rule.maxLength) {
      setErrorMessage(`กรุณระบุไม่เกิน ${props.rule.maxLength} ตัวอักษร`);

      return false;
    }

    return true;
  };

  const checkMinValue = (value?: string | number) => {
    if (props.rule?.minValue && Number(value) < props.rule.minValue) {
      setErrorMessage(`กรุณระบุอย่างน้อย ${props.rule.minValue}`);

      return false;
    }

    return true;
  };

  const checkMaxValue = (value?: string | number) => {
    if (props.rule?.maxValue && Number(value) > props.rule.maxValue) {
      setErrorMessage(`กรุณระบุไม่เกิน ${props.rule.maxValue}`);

      return false;
    }

    return true;
  };

  const getErrorMessage = useMemo(() => {
    if (errorMessage) {
      return <Form.Text className="text-danger">{errorMessage}</Form.Text>;
    }

    return null;
  }, [errorMessage]);

  const handlerOnChange = (event: HTMLInputElement) => {
    if (props.onChange) {
      props.onChange(event.value);
    }

    setValue(event.value);
    validate(event.value);
  };

  return (
    <Form.Group className={`w-100 ${props.className ?? ''} ${props.label ? 'mb-3' : ''}`}>
      {props.label ? <Form.Label className='m-0'>{props.label} {getRequired()}</Form.Label> : null}
      <Form.Control
        as="textarea"
        rows={props.rows ?? 3}
        className={`${getErrorMessage ? 'is-invalid' : ''}`}
        value={value ?? ''}
        type={getType()}
        placeholder={props.placeholder}
        onChange={event => handlerOnChange(event.target as HTMLInputElement)} />
      {getErrorMessage}
    </Form.Group>
  );
}