import { Card, Input, Layout, Table } from "components";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaEdit, FaPlus, FaSearch, FaTrashAlt } from "react-icons/fa";
import { useState, useEffect, useCallback, FormEvent } from "react";
import { calculateRowNumber, fullDateTime, showModalRemoveAsync } from "utils";
import { SmRoleModel } from "models";
import { useNavigate } from "react-router-dom";
import { HttpStatusCode } from "constant";
import m0102Service from "services/m0102Service";
import toast from "utils/toast";

export default function M0102() {
  const [smRoleList, setSmRoleList] = useState<SmRoleModel[]>([]);
  const [userTypeName, setUserTypeName] = useState("");
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const navigate = useNavigate();

  const getSmRoleListAsync = useCallback(async (keyword?: string) => {
    const { data, status } = await m0102Service.getSmRoleListAsync(page, size, keyword ? keyword : userTypeName);

    if (status === HttpStatusCode.OK) {
      setSmRoleList(data.rows);
      setTotalRows(data.totalRows);
    }
  }, [page, size]);

  useEffect(() => {
    getSmRoleListAsync();
  }, [getSmRoleListAsync]);

  const handleClear = () => {
    setUserTypeName("");
    setPage(1);
    setSize(10);

    getSmRoleListAsync("");
  };

  const handleDeleteAsync = async (id: string) => {
    if (await showModalRemoveAsync()) {
      const response = await m0102Service.deleteSmRoleAsync(id);

      if (response.status === HttpStatusCode.NO_CONTENT) {
        toast.success("ลบข้อมูลสำเร็จ");

        getSmRoleListAsync();
      }
    }
  };

  const onChangePageSize = (size: number, page: number) => {
    setPage(page);
    setSize(size);
  };

  const onSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    getSmRoleListAsync(userTypeName);
  };

  return (
    <Layout title="กำหนดสิทธิ์การใช้งาน">
      <Card>
        <Form onSubmit={onSubmitSearch}>
          <Row>
            <Col sm={12} lg={4}>
              <Input
                label="ค้นหา"
                placeholder="ชื่อสิทธิ์"
                value={userTypeName}
                onChange={(value) => setUserTypeName(value)} />
            </Col>
            <Col sm={12} lg={8}>
              <div className="criteria-flex">
                <div className="button">
                  <Button
                    variant="primary"
                    type="submit">
                    <FaSearch className="me-2" />ค้นหา
                  </Button>
                  <Button
                    variant="outline-primary"
                    type="button"
                    onClick={handleClear}>
                    <FaTrashAlt className="me-2" />ล้างค่า
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </Card>
      <div className="d-flex justify-content-end my-3">
        <Button variant="primary" type="button" onClick={() => navigate('detail')}>
          <FaPlus className="me-2" />
          เพิ่มประเภท
        </Button>
      </div>
      <Row className="mt-3">
        <Col>
          <div className="table-relative-fix">
            <div className="table-scroll">
              <Table
                total={totalRows}
                page={page}
                size={size}
                onChange={onChangePageSize}>
                <Table.Header>
                  <Table.Row>
                    <Table.Column minWidth={200}>ลำดับ</Table.Column>
                    <Table.Column minWidth={250}>รหัสสิทธิ์</Table.Column>
                    <Table.Column minWidth={250}>ชื่อสิทธิ์</Table.Column>
                    <Table.Column minWidth={250}>ผู้แก้ไขข้อมูล</Table.Column>
                    <Table.Column minWidth={200}>วันที่แก้ไขข้อมูล</Table.Column>
                    <Table.Column minWidth={200} className="fix-col" />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {smRoleList?.map((data, index) => (
                    <Table.Row key={data.id}>
                      <Table.Cell center>{calculateRowNumber(index, page, size).toString()}</Table.Cell>
                      <Table.Cell center>{data.code}</Table.Cell>
                      <Table.Cell>{data.name}</Table.Cell>
                      <Table.Cell>{data.updateByUserFullName}</Table.Cell>
                      <Table.Cell center>{fullDateTime(data.updateDate)}</Table.Cell>
                      <Table.Cell center className="px-5 fix-col fs-5">
                        <FaEdit className="cursor-pointer text-primary me-2" onClick={() => navigate(`detail/${data.id}`)} />
                        <FaTrashAlt className="cursor-pointer text-primary" onClick={() => handleDeleteAsync(data.id!)} />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}