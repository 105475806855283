import { Layout, Table, Modal, Card, Input, TextArea } from "components";
import { StatusChapter, StatusMeeting, VideoControl } from "constant";
import { MeetingInspectDetailModel, MeetingInspectListModel, Pagination, SnChapterDocument } from "models";
import { Dispatch, SetStateAction, createContext, useContext, useState, useRef, useEffect, useMemo } from 'react';
import { Button, Col, Dropdown, DropdownButton, Form, Row } from "react-bootstrap";
import {
  FaAngleLeft,
  FaUndo,
  FaRegFilePdf,
  FaRegFileWord,
  FaBuffer,
  FaRegCheckCircle,
  FaRegClock,
  FaRegTimesCircle,
  FaCheck,
  FaTimes,
  FaMicrophone,
} from "react-icons/fa";
import { useLoaderData, useNavigate, useParams, useSubmit } from "react-router-dom";
import { exportFilePDFAsync, exportFileWordAsync, showModalCollectAsync, showModalProgressBarLoadingAsync, submitForm } from "utils";
import toast from "utils/toast";
import { s0205 as service, snVideoService } from 'services';
import { HttpStatusCode } from "axios";
import Collabora, { CollaboraRef } from 'components/Document/Collabora';
import Video, { VideoOption } from 'components/Video/Video';
import snMakeDocumentService from "services/s0204Service";
import { TableColor } from "pages/C/C0201/Detail";

type Loader = { inspectDetailList: Pagination<MeetingInspectDetailModel>, headerData: MeetingInspectListModel };

interface ModalDisApprovedProps {
  showModal: boolean;
  onHide: () => void;
  data: MeetingInspectDetailModel;
  documentCurrentVersionId?: string;
}

interface ModalInteract {
  show: boolean;
  data: MeetingInspectDetailModel;
}

type InspectDocumentContext = {
  showModal: ModalInteract;
  setShowModal: Dispatch<SetStateAction<ModalInteract>>;
  showApproveModal: ModalInteract;
  setShowApproveModal: Dispatch<SetStateAction<ModalInteract>>;
  videoSelect: File | undefined;
  setVideoSelect: Dispatch<SetStateAction<File | undefined>>;
  getDetailAsync: (dataSelect: MeetingInspectDetailModel) => Promise<void>;
  changePageSize: Function;
  documentList: SnChapterDocument[];
  dataSelected: MeetingInspectDetailModel;
};

const Context = createContext({} as InspectDocumentContext);

export default function S0205Document() {
  const { inspectDetailList, headerData } = useLoaderData() as Loader;
  const detailId = useParams().id;
  const [showModal, setShowModal] = useState<ModalInteract>({ show: false, data: {} as MeetingInspectDetailModel } as ModalInteract);
  const [showApproveModal, setShowApproveModal] = useState<ModalInteract>({ show: false, data: {} as MeetingInspectDetailModel } as ModalInteract);
  const [videoSelect, setVideoSelect] = useState<File>();
  const [docVersion, setDocVersion] = useState<SnChapterDocument>({} as SnChapterDocument);
  const [documentList, setDocumentList] = useState<SnChapterDocument[]>([]);
  const [canWrite, setCanWrite] = useState<boolean>();
  const [dataSelected, setDataSelected] = useState<MeetingInspectDetailModel>({} as MeetingInspectDetailModel);
  const navigate = useNavigate();
  const submit = useSubmit();
  const [fileIds, setFileIds] = useState<string[]>([]);
  const [fileIndex, setFileIndex] = useState<number>(0);
  const programName = useState<string>("ตรวจทานและรวบรวมรายงานการประชุม");
  const [selectedChapter, setSelectedChapter] = useState<string>('');

  useEffect(() => {
    const showVideoAsync = async () => {
      await getDetailAsync(inspectDetailList.rows[0]);
    };

    showVideoAsync();
  }, [inspectDetailList.rows]);

  useEffect(() => {
    if (fileIds.length) {
      getVideoDataAsync(fileIds[0]);
      setFileIndex(0);
    }
  }, [fileIds]);

  const getVideoDataAsync = async (videoId: string) => {
    const { data, status } = await snVideoService.getVideoAsync(videoId!);

    if (status === HttpStatusCode.Ok) {
      setVideoSelect(data);
    }
  };

  const getFileIdAsync = async (chapterId: string) => {
    const { data, status } = await snVideoService.getRecordFileIdAsync(chapterId, detailId!);

    if (status === HttpStatusCode.Ok) {
      setFileIds(data);
    }
  };

  const onEndedAsync = async () => {
    const currentIndex = fileIndex + 1;

    if (fileIds.length > currentIndex) {
      getVideoDataAsync(fileIds[currentIndex]);

      setFileIndex(currentIndex);
    } else {
      setFileIndex(0);
    }
  };

  const onLoadedData = () => {
    if (fileIndex) {
      videoRef.current.playRef();
    }
  };

  const canWriteStatus = (status: string): boolean => {
    if (status === StatusChapter.ASSIGN_DUTY_OFFICER ||
      status === StatusChapter.CONFIRM ||
      status === StatusChapter.RECORD ||
      status === StatusChapter.RECORDED ||
      status === StatusChapter.DOCUMENT_MAKING ||
      status === StatusChapter.DOCUMENT_COMPLETE) {
      return true;
    }
    else {
      return false;
    }
  }

  const getDetailAsync = async (dataSelect: MeetingInspectDetailModel) => {
    setSelectedChapter(`ตอนที่ ${dataSelect.chapter} ผู้จดรายงาน : ${dataSelect.dutyOfficerFullName}`);

    getFileIdAsync(dataSelect.id);
    if (canWriteStatus(dataSelect.status)) {
      setCanWrite(true);
    } else {
      setCanWrite(false);
    }

    const { data, status } = await snMakeDocumentService.getSnMakeDocumentDetailAsync(dataSelect.id);

    if (status === HttpStatusCode.Ok) {
      const documentData: SnChapterDocument[] = data.snChapterDocuments;

      setDocVersion(documentData ? documentData[0] : {} as SnChapterDocument);
      setDocumentList(documentData);
      setDataSelected(dataSelect);
    }
  };

  const onCollectAsync = async () => {
    if (!await showModalCollectAsync()) {

      return;
    }

    if (inspectDetailList.rows.some(s => s.status === StatusChapter.DOCUMENT_COMPLETE)) {
      toast.warn("กรุณาตรวจทานการจดรายงานให้ครบถ้วนก่อนทำการรวบรวม");

      return;
    }

    await showModalProgressBarLoadingAsync(0, "กำลังรวบรวมรายงานการประชุม...", true);

    const { status } = await service.inspectCollectAsync(detailId);

    if (status === HttpStatusCode.Accepted) {
      toast.success("รวบรวมรายงานการประชุมและส่งอนุมัติสำเร็จ");

      submit({
        page: inspectDetailList.page,
        size: inspectDetailList.size,
      });
    }
  };

  const changePageSize = (size: number, page: number) => {
    submit({
      page,
      size
    });
  };

  const onSelectVersion = (id: string | null) => {
    if (id) {
      const documentSelected = documentList.find(f => f.id == id);

      setDocVersion(documentSelected ? documentSelected : {} as SnChapterDocument);

      if (documentSelected !== documentList[0]) {
        setCanWrite(false);
      } else if (canWriteStatus(dataSelected.status)) {
        setCanWrite(true);
      }
    }
  };

  const contextValue = useMemo(() => {
    return {
      showModal,
      setShowModal,
      showApproveModal,
      setShowApproveModal,
      videoSelect,
      setVideoSelect,
      getDetailAsync,
      changePageSize,
      documentList,
      dataSelected,
    }
  }, [showModal, setShowModal, showApproveModal, setShowApproveModal, videoSelect, setVideoSelect, getDetailAsync, changePageSize, documentList, dataSelected]);

  const collaboraRef = useRef<CollaboraRef>(null);

  const videoRef = useRef<VideoOption>({} as VideoOption);

  function loadCollabora() {
    const iframe = document.getElementById(docVersion.id) as HTMLIFrameElement;

    iframe.contentWindow?.addEventListener('keydown', (e) => clickBtn(e.key));
  }

  function clickBtn(key: string) {
    switch (key) {
      case VideoControl.F10:
        videoRef.current.backRef();
        break;
      case VideoControl.F9:
        videoRef.current.playRef();
        break;
      case VideoControl.F11:
        videoRef.current.aheadRef();
        break;
    }
  }

  return (
    <Context.Provider value={contextValue}>
      <Layout title={programName[0]} subTitle={selectedChapter} className="s0205-detail">
        <Form>
          <div className="d-flex justify-content-between my-3">
            <Button
              variant="outline-primary"
              type="button"
              onClick={() => navigate('/s0205')}>
              <FaAngleLeft className="me-2" />ย้อนกลับ
            </Button>
            <div className="d-flex gap-2">
              <DropdownButton
                title={<span><FaUndo className="me-2" />เวอร์ชั่นก่อนหน้า ({docVersion.versionDocument})</span>}
                drop="down-centered"
                variant="outline-primary"
                onSelect={(value) => onSelectVersion(value)}>
                {documentList?.map((data) => (
                  <Dropdown.Item eventKey={data.id} key={data.id}>{data.versionDocument}</Dropdown.Item>
                ))}
              </DropdownButton>
              <Button
                variant="outline-primary" onClick={() => exportFilePDFAsync(docVersion.id, programName[0])}>
                <FaRegFilePdf className="me-2" />Export PDF
              </Button>
              <Button
                variant="outline-primary"
                onClick={() => exportFileWordAsync(docVersion.id, programName[0])}>
                <FaRegFileWord className="me-2" />Export Word
              </Button>
              {headerData.status === StatusMeeting.DOCUMENT_COMPLETE &&
                <>
                  <Button
                    onClick={onCollectAsync}>
                    <FaBuffer className="me-2" />รวบรวมรายงานการประชุม
                  </Button>
                </>}
            </div>
          </div>
          <Row>
            <Col xl={4}>
              <Video
                onEnded={onEndedAsync}
                onLoadedData={onLoadedData}
                ref={videoRef}
                files={videoSelect ? [videoSelect] : []}
                showPlayButton={true}
                showPlayTimeConfig={false}
                showSpeakToText={false}>
                <div className="mt-2 border-primary">
                  <DataTable />
                </div>
              </Video>
            </Col>
            <Col xl={8}>
              <Collabora
                docId={docVersion.id}
                docName="s0205"
                height="1000px"
                ref={collaboraRef}
                onCollaboraLoaded={() => loadCollabora()}
                canWrite={canWrite} />
            </Col>
          </Row>
        </Form>
      </Layout>
    </Context.Provider>
  );
}

function DataTable() {
  const { showModal, setShowModal, showApproveModal, setShowApproveModal, getDetailAsync, changePageSize, dataSelected } = useContext(Context);
  const { inspectDetailList } = useLoaderData() as Loader;
  const navigate = useNavigate();
  const { id } = useParams();

  const handleShowModal = (value: MeetingInspectDetailModel, type: string) => {
    if (type === "SendEdit") {
      setShowModal({ ...showModal, show: true, data: value });
    } else {
      setShowApproveModal({ ...showApproveModal, show: true, data: value })
    }
  };

  const statusIcon = (status: StatusChapter | string) => {
    switch (status) {
      case StatusChapter.DOCUMENT_COMPLETE:
        return (<FaRegClock className="text-warning fs-5" />);
      case StatusChapter.REVIEWED_DOCUMENT:
        return (<FaRegCheckCircle className="text-success fs-5" />);
      case StatusChapter.SEND_EDIT_DOCUMENT:
        return (<FaRegTimesCircle className="text-danger fs-5" />);
      default: return (<></>);
    }
  };

  const onChangeChapterAsync = async (data: MeetingInspectDetailModel) => {
    await getDetailAsync(data);
  };

  return (
    <>
      <div className="table-relative-fix">
        <div className="table-scroll">
          <Table striped={false} total={inspectDetailList.totalRows} onChange={(size, page) => changePageSize(size, page)}>
            <Table.Header>
              <Table.Row>
                <Table.Column minWidth={50}>ตอนที่</Table.Column>
                <Table.Column minWidth={200}>ผู้จดรายงานการประชุม</Table.Column>
                <Table.Column minWidth={75}>สถานะ</Table.Column>
                <Table.Column minWidth={175} className="fix-col" />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {inspectDetailList.rows?.map((value) => (
                <Table.Row
                  key={value.id}
                  backgroundColor={dataSelected.id === value.id ? TableColor.TABLE_LAST_ACTION_COLOR : ""}
                  color={dataSelected.id === value.id ? TableColor.TABLE_TEXT_COLOR : ""}>
                  <Table.Cell center>{value.chapter}</Table.Cell>
                  <Table.Cell>{value.dutyOfficerFullName}</Table.Cell>
                  <Table.Cell center>
                    {statusIcon(value.status)}
                  </Table.Cell>
                  <Table.Cell center className={`fix-col ${dataSelected.id === value.id ? "rows-selected" : ''}`}>
                    <div
                      className="d-flex justify-content-center gap-1">
                      {value.status === StatusChapter.DOCUMENT_COMPLETE ?
                        <>
                          <Button
                            size="sm"
                            onClick={() => handleShowModal(value, "Approve")}>
                            <FaCheck />
                          </Button>
                          <Button
                            variant="outline-primary"
                            size="sm"
                            onClick={() => handleShowModal(value, "SendEdit")}>
                            <FaTimes />
                          </Button>
                        </>
                        : <></>}
                      <Button
                        className="mx-2"
                        size="sm"
                        onClick={() => onChangeChapterAsync(value)}>รายละเอียด</Button>
                      <Button
                        className="mx-2"
                        size="sm"
                        onClick={() => navigate(`/s0204/detail/${id}/document/${value.id}`)}>
                        <FaMicrophone />
                      </Button>
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </div>
      <ModalDisApproved
        showModal={showModal.show}
        onHide={() => setShowModal({ ...showModal, show: false, data: {} as MeetingInspectDetailModel })}
        data={showModal.data} />
      <ModalApprove
        showModal={showApproveModal.show}
        onHide={() => setShowApproveModal({ ...showApproveModal, show: false, data: {} as MeetingInspectDetailModel })}
        data={showApproveModal.data} />
    </>
  );
}

function ModalDisApproved(props: ModalDisApprovedProps) {
  const { inspectDetailList } = useLoaderData() as Loader;
  const [assessment, setAssessment] = useState<number>(-1);
  const [comment, setComment] = useState<string>("");
  const [versionDocId, setVersionDocId] = useState("");
  const submit = useSubmit();

  useEffect(() => {
    setAssessment(0);
    setComment("");
    if (props.showModal && props.data) {
      getVersionIdAsync(props.data.id);
    }
  }, [props.data, props.data]);

  const getVersionIdAsync = async (id: string) => {
    const documentResponse = await snMakeDocumentService.getSnMakeDocumentDetailAsync(id);

    if (documentResponse.status === HttpStatusCode.Ok) {
      const documentData: SnChapterDocument[] = documentResponse.data.snChapterDocuments;

      setVersionDocId(documentData ? documentData[0].id : "");
    }
  };

  const onSubmitAsync = async () => {
    const objData = {
      assessment: assessment,
      comment: comment,
    };

    submitForm(objData);

    const isValid = typeof (assessment) != "number";

    if (isValid) {
      toast.warn("กรุณาประเมินคะแนน");

      return;
    }

    const { status } = await service.sendEditInspectAsync(props.data.id, versionDocId, comment, assessment);

    if (status === HttpStatusCode.Accepted) {
      submit({
        page: inspectDetailList.page,
        size: inspectDetailList.size,
      });

      toast.success("บันทึกผลการตรวจทานสำเร็จ");
      props.onHide();
    }
  };

  const onHide = () => {
    setAssessment(0);
    setComment("");

    props.onHide();
  };

  return (
    <Modal show={props.showModal} title="ไม่อนุมัติ">
      <Card className="mt-2">
        <Row>
          <Col sm={12}>
            <p>ตอนที่ : {props.data?.chapter}</p>
          </Col>
          <Col sm={12}>
            <p>ผู้จดรายงาน : {props.data?.dutyOfficerFullName}</p>
          </Col>
        </Row>
      </Card>
      <Input
        name="assessment"
        className="mt-3"
        label="ประเมินคะแนน"
        rule={{ required: true }}
        value={assessment}
        type="number"
        onChange={(value) => setAssessment(Number(value))} />
      <TextArea
        className="mt-3"
        label="แสดงความคิดเห็น"
        value={comment}
        onChange={(value) => setComment(value)} />
      <div className="d-flex justify-content-center gap-3">
        <Button
          variant="outline-primary"
          className="w-25"
          type="button"
          onClick={() => onHide()}>
          ยกเลิก
        </Button>
        <Button
          className="w-25"
          onClick={onSubmitAsync}>
          ยืนยัน
        </Button>
      </div>
    </Modal>
  );
}

function ModalApprove(props: ModalDisApprovedProps) {
  const { inspectDetailList } = useLoaderData() as Loader;
  const submit = useSubmit();

  const onSubmitAsync = async () => {
    const { status } = await service.approveInspectAsync(props.data.id);

    if (status === HttpStatusCode.Accepted) {
      submit({
        page: inspectDetailList.page,
        size: inspectDetailList.size,
      });

      toast.success("บันทึกผลการตรวจทานสำเร็จ");
      props.onHide();
    }
  };

  const onHide = () => {
    props.onHide();
  };

  return (
    <Modal show={props.showModal} title="อนุมัติ" icon={<FaRegCheckCircle className="text-success" />}>
      <Row className="mt-2">
        <Col className="mt-1">
          <h5>
            ต้องการที่จะอนุมัติใช่หรือไม่ ?
          </h5>
        </Col>
      </Row>
      <div className="button d-flex flex-row gap-3 justify-content-center mt-4">
        <Button
          variant="outline-primary"
          className="w-50"
          onClick={onHide}>
          ยกเลิก
        </Button>
        <Button
          variant="primary"
          className="w-50"
          onClick={onSubmitAsync}>
          อนุมัติ
        </Button>
      </div>
    </Modal>
  );
}