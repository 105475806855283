export function up<T>(currentIndex: number, items: T[]) {
  if (!currentIndex) {
    return items;
  }

  const newItems = [...items];
  const beforeItem = newItems[currentIndex - 1];
  const currentItem = newItems[currentIndex];

  newItems[currentIndex - 1] = currentItem;
  newItems[currentIndex] = beforeItem;

  return newItems;
}

export function down<T>(currentIndex: number, items: T[]) {
  if ((currentIndex + 1) === items.length) {
    return items;
  }

  const newItems = [...items];
  const currentItem = newItems[currentIndex];
  const afterItem = newItems[currentIndex + 1];

  newItems[currentIndex] = afterItem;
  newItems[currentIndex + 1] = currentItem;

  return newItems;
}