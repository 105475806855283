import { SelectionType } from "constant/selection";
import { s0104 as Service, selectionService as ddlService } from "services";

export const s0104LoaderAsync = async () => {
  const [bureauList, bureauGroupList, officerRankList] = await Promise.all([
    ddlService.getSelectionAsync(SelectionType.SM_BUREAU),
    ddlService.getSelectionAsync(SelectionType.SM_BUREAU_GROUP),
    Service.getOfficerRankListAsync(1, 9999),
  ]);

  return {
    bureauList: bureauList.data,
    bureauGroupList: bureauGroupList.data,
    officerRankList: officerRankList.data,
  };
};