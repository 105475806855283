import { BureauCriteria } from "models";
import { LoaderFunctionArgs } from "react-router-dom";
import { c0101 as serivce } from "services";

export const c0101LoaderAsync = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 1;
  const size = url.searchParams.get('size') || 10;
  const criteriaStringify = url.searchParams.get('criteria') || '{}';
  const criteria = JSON.parse(criteriaStringify) as BureauCriteria;

  const { data } = await serivce.getBureauListAsync(+page, +size, criteria);

  return {
    bureauList: {
      rows: data.rows,
      totalRows: data.totalRows,
      page: +page,
      size: +size,
    },
  };
};