import { CtMakeDocumentDetailChapterCriteria, CtMakeDocumentDetailChapterList, CtMakeDocumentListCriteria } from 'models';
import http from 'utils/axios';
import * as tus from 'tus-js-client'
import { HttpStatusCode } from "constant";
import { showModalProgressBarLoadingAsync } from 'utils';

const getCtMakeListAsync = async (page: number, size: number, criteria: CtMakeDocumentListCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get('api/ctmakedocument', { params });
};

const getCtMakeDocumentDetailHeaderAsync = async (id: string) => {
  return await http.get(`api/ctmakedocument/ctmeeting/${id}`);
};

const getCtMakeDocumentDetailChapterAsync = async (id: string, page: number, size: number, criteria: CtMakeDocumentDetailChapterCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get(`api/ctmakeDocument/ctmeeting/${id}/ctmeetingchapter`, { params });
};

const getCtMakeDocumentChapterDetailAsync = async (id: string) => {
  return await http.get(`api/ctmakeDocument/ctmeetingchapter/${id}`);
};

const updateMakeDocumentChapterAsync = async (id: string, status: string) => {
  return await http.put(`api/ctmakedocument/actions/${id}`, JSON.stringify(status),
    { headers: { 'Content-Type': 'application/json' } });
};

const updateTimeOfChapterListAsync = async (id: string, data: CtMakeDocumentDetailChapterList[]) =>
  await http.put(`api/ctmakedocument/ctmeeting/${id}/chapter`, data);

const uploadDocumentAsync = async (id: string, chapterId: string, video: File) => {
  const fileId = await new Promise<string>(
    (resolve, reject) => {
      const upload = new tus.Upload(video, {
        endpoint: `${process.env.REACT_APP_API_URL}api/files/tus/`,
        // Retry delays will enable tus-js-client to automatically retry on errors
        retryDelays: [0, 3000, 5000, 10000, 20000],
        chunkSize: 30000000,
        parallelUploads: 1,
        // Attach additional meta data about the file for the server
        metadata: {
          filename: video.name,
          filetype: video.type,
        },
        // Callback for errors which cannot be fixed using retries
        onError: function (error) {
          reject(error);
        },
        // Callback for reporting upload progress
        onProgress: function (bytesUploaded, bytesTotal) {
          let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2)
          showModalProgressBarLoadingAsync(parseFloat(percentage), "กำลังอัพโหลดวิดิโอ...");
        },
        // Callback for once the upload is completed
        onSuccess: async function () {
          const paths = upload.url?.split('/');
          if (paths != null) {
            const lastPath = paths[paths?.length - 1];

            const formData = new FormData();
            formData.append("ctMeetingChapterId", chapterId);
            formData.append("TusFileId", lastPath);
            formData.append("FileName", video.name);

            const resp = await http.post(
              `api/ctmeetingrecord/uploadmanualvideo/${id}`,
              formData)

            if (resp.status === HttpStatusCode.ACCEPTED ||
              resp.status === HttpStatusCode.OK) {
              resolve(resp.data);
            } else {
              reject(resp.data);
            }
          }
        },
      })

      upload.start();
    })

  return fileId;
};

const removeDocAsync = async (id: string) =>
  await http.delete(`api/ctMakeDocument/ctchapterdocument/${id}`);

const ctMakeDocumentService = {
  getCtMakeListAsync,
  getCtMakeDocumentDetailHeaderAsync,
  getCtMakeDocumentDetailChapterAsync,
  getCtMakeDocumentChapterDetailAsync,
  updateMakeDocumentChapterAsync,
  updateTimeOfChapterListAsync,
  uploadDocumentAsync,
  removeDocAsync,
};

export default ctMakeDocumentService;