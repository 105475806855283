import { IndividualCriteria } from 'models';
import { DetailDataTableType } from 'pages/D/D03';
import http from 'utils/axios';

const saveLeaveAsync = async (startDate: Date, endDate: Date, dailyStatus: string) => {
  const body = {
    startDate: startDate.toISOString().split('T')[0],
    endDate: endDate.toISOString().split('T')[0],
    dailyStatus,
  };

  return http.post('api/smleave', body);
};

const updateLeaveAsync = async (id: string, startDate: Date, endDate: Date, dailyStatus: string) => {
  const body = {
    startDate: new Date(startDate).toISOString().split('T')[0],
    endDate: new Date(endDate).toISOString().split('T')[0],
    dailyStatus,
  };

  return http.put(`api/smleave/${id}`, body);
};

const getLeaveListAsync = async (page: number, size: number) =>
  http.get('api/smleave', { params: { page, size } });

const removeLeaveAsync = async (id: string) =>
  http.delete(`api/smleave/${id}`);

const getIndividualperformanceoverviewdashboardSenateAsync = async (page: number, size: number, criteria?: IndividualCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get('api/individualperformanceoverviewdashboard/senate', { params });
};

const getIndividualperformanceoverviewdashboardCommitteeAsync = async (page: number, size: number, criteria?: IndividualCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get('api/individualperformanceoverviewdashboard/committee', { params });
};

const getIndividualperformanceoverviewdashboardSummaryAsync = async (moduleSelected: string, criteria?: IndividualCriteria) => {
  const params = {
    moduleSelected,
    ...criteria,
  };

  return await http.get('api/individualperformanceoverviewdashboard/summary', { params });
};

const exportExcelSenateAsync = async (page: number, size: number, criteria?: IndividualCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get('api/individualperformanceoverviewdashboard/senate/export/excel', { params, responseType: 'blob' });
};

const exportPdfSenateAsync = async (page: number, size: number, criteria?: IndividualCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get('api/individualperformanceoverviewdashboard/senate/export/pdf', { params, responseType: 'blob' });
};

const exportExcelCommitteeAsync = async (page: number, size: number, criteria?: IndividualCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get('api/individualperformanceoverviewdashboard/committee/export/excel', { params, responseType: 'blob' });
};

const exportPdfCommitteeAsync = async (page: number, size: number, criteria?: IndividualCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get('api/individualperformanceoverviewdashboard/committee/export/pdf', { params, responseType: 'blob' });
};

const getIndividualPerformanceOverviewDashboardDutyAsync = async (page: number, size: number, moduleSelected: string, criteria?: IndividualCriteria) => {
  const params = {
    page,
    size,
    moduleSelected,
    ...criteria,
  };

  return await http.get('api/individualperformanceoverviewdashboard/duty', { params });
};

const getIndividualPerformanceOverviewDashboardCommanderAsync = async (page: number, size: number, moduleSelected: string, criteria?: IndividualCriteria) => {
  const params = {
    page,
    size,
    moduleSelected,
    ...criteria,
  };

  return await http.get('api/individualperformanceoverviewdashboard/commander', { params });
};

const getSenateDataTableByUserAsync = async (page: number, size: number, criteria?: IndividualCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get('api/individualperformanceoverviewdashboard/senatebyuser', { params });
};

const getCommitteeDataTableByUserAsync = async (page: number, size: number, criteria?: IndividualCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get('api/individualperformanceoverviewdashboard/committeebyuser', { params });
};

const getDataTableByUserDetailAsync = async (type: DetailDataTableType, page: number, size: number, dutyOfficerId: string, criteria: IndividualCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  if (type === DetailDataTableType.Senate) {
    return await http.get(`api/individualperformanceoverviewdashboard/senatebyuser/${dutyOfficerId}`, { params });
  }
  else {
    return await http.get(`api/individualperformanceoverviewdashboard/committeebyuser/${dutyOfficerId}`, { params });
  }
};



const d03Service = {
  saveLeaveAsync,
  updateLeaveAsync,
  getLeaveListAsync,
  removeLeaveAsync,
  getIndividualperformanceoverviewdashboardSenateAsync,
  getIndividualperformanceoverviewdashboardCommitteeAsync,
  getIndividualperformanceoverviewdashboardSummaryAsync,
  exportExcelSenateAsync,
  exportExcelCommitteeAsync,
  exportPdfSenateAsync,
  exportPdfCommitteeAsync,
  getIndividualPerformanceOverviewDashboardDutyAsync,
  getIndividualPerformanceOverviewDashboardCommanderAsync,
  getSenateDataTableByUserAsync,
  getCommitteeDataTableByUserAsync,
  getDataTableByUserDetailAsync,
};

export default d03Service;