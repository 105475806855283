import { Card, Input, InputDate, Layout, Radio, Selector, Status, StatusType, Table } from "components";
import { statusMeetingItems } from "constant";
import { ItemModel, Pagination, SnInquiryMeetingCriteria, SnInquiryMeetingList } from "models";
import { FormEvent, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaRegFileAlt, FaSearch, FaTrashAlt } from "react-icons/fa";
import { useLoaderData, useNavigate, useSubmit } from "react-router-dom";
import { formatDateTh, fullDateTime, getTime, getYearList } from "utils";

type Loader = { snMeetingTypeItems: ItemModel[], snMeetingPeriodItems: ItemModel[], data: Pagination<SnInquiryMeetingList>, }

const REPORT_TYPE = [
  { label: "ทั้งหมด", value: "all" },
  { label: "รายงานที่ยังไม่ตรวจสอบและจัดทำรูปเล่ม", value: "isNotCheck" },
  { label: "รายงานที่่ตรวจสอบและจัดทำรูปเล่มแล้ว", value: "check" },
];

export default function S0302() {
  const { data, snMeetingPeriodItems, snMeetingTypeItems } = useLoaderData() as Loader;
  const [criteria, setCriteria] = useState<SnInquiryMeetingCriteria>({
    isCollect: null
  } as SnInquiryMeetingCriteria);
  const navigate = useNavigate();
  const submit = useSubmit();

  const onSearch = (size: number, page: number) => {
    submit({
      page: page.toString(),
      size: size.toString(),
      criteria: JSON.stringify(criteria),
    });
  };

  const onClear = () => {
    setCriteria({
      isCollect: null
    } as SnInquiryMeetingCriteria);

    submit({});
  };

  const onChangeCriteria = (prop: keyof SnInquiryMeetingCriteria, value?: string | number | Date) => {
    setCriteria({ ...criteria, [prop]: value });
  };

  const getReportType = (value: boolean | null) => {
    if (value === null) {
      return "all";
    } else if (!value) {
      return "isNotCheck";
    }
    return "check";
  };

  const onChangeRadio = (value: string | number) => {
    switch (value) {
      case "all":
        setCriteria({ ...criteria, isCollect: null });
        break;
      case "isNotCheck":
        setCriteria({ ...criteria, isCollect: false });
        break;
      default:
        setCriteria({ ...criteria, isCollect: true });
    }
  };

  const onSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSearch(data.size, data.page);
  };

  return (
    <Layout title="ค้นหารายงานการประชุม">
      <Card className="my-3">
        <Form onSubmit={onSubmitSearch}>
          <Row>
            <Col>
              <Radio
                value={getReportType(criteria.isCollect)}
                name="reportType"
                label="ประเภทรายงาน" items={REPORT_TYPE} inline
                onChange={(value) => onChangeRadio(value)} />
            </Col>
          </Row>
          <Row>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Selector
                label="ประเภทการประชุม"
                placeholder="ประเภทการประชุม"
                items={snMeetingTypeItems}
                value={criteria.meetingTypeId}
                onChange={(value) => onChangeCriteria('meetingTypeId', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Input
                type="number"
                label="ครั้งที่ประชุม"
                value={criteria.time}
                onChange={(value) => onChangeCriteria('time', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Selector
                label="สมัยการประชุม"
                placeholder="สมัยการประชุม"
                items={snMeetingPeriodItems}
                value={criteria.periodId}
                onChange={(value) => onChangeCriteria('periodId', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Input
                label="ชื่อการประชุม"
                value={criteria.name}
                onChange={(value) => onChangeCriteria('name', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Selector
                label="ปี"
                placeholder="ปี"
                items={getYearList()}
                value={criteria.year}
                onChange={(value) => onChangeCriteria('year', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <InputDate
                name="meeting-date"
                label="วันที่ประชุม"
                value={criteria.meetingDate}
                onChange={(value) => onChangeCriteria('meetingDate', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Selector
                label="สถานะ"
                placeholder="สถานะ"
                items={statusMeetingItems}
                value={criteria.status}
                onChange={(value) => onChangeCriteria('status', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Input
                label="ผู้อภิปราย"
                value={criteria.debater}
                onChange={(value) => onChangeCriteria('debater', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Input
                label="วาระการประชุม"
                value={criteria.agenda}
                onChange={(value) => onChangeCriteria('agenda', value)} />
            </Col>
          </Row>
          <Row>
            <Col className="d-flex gap-2 justify-content-start mt-2">
              <Button
                variant="primary"
                type="submit">
                <FaSearch className="me-2" />ค้นหา
              </Button>
              <Button
                variant="outline-primary"
                type="button"
                onClick={onClear}>
                <FaTrashAlt className="me-2" />ล้างค่า
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <div className="table-relative-fix">
        <div className="table-scroll">
          <Table
            total={data.totalRows}
            page={data.page}
            size={data.size}
            onChange={onSearch}>
            <Table.Header>
              <Table.Row>
                <Table.Column minWidth={80}>ลำดับ</Table.Column>
                <Table.Column minWidth={200}>ประเภทการประชุม</Table.Column>
                <Table.Column minWidth={100}>ครั้งที่</Table.Column>
                <Table.Column minWidth={200}>สมัยการประชุม</Table.Column>
                <Table.Column minWidth={200}>ชื่อการประชุม</Table.Column>
                <Table.Column minWidth={200}>ผู้ตรวจสอบและจัดทำรูปเล่ม</Table.Column>
                <Table.Column minWidth={200}>ผู้ตรวจสอบรูปเล่มรายงาน</Table.Column>
                <Table.Column minWidth={250}>วันที่ประชุม</Table.Column>
                <Table.Column minWidth={200}>สถานะ</Table.Column>
                <Table.Column minWidth={200}>ผู้แก้ไขข้อมูล</Table.Column>
                <Table.Column minWidth={200}>วันที่แก้ไขข้อมูล</Table.Column>
                <Table.Column minWidth={200} className="fix-col" />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.rows?.map((data, index) => (
                <Table.Row key={data.id}>
                  <Table.Cell center>{(index + 1).toString()}</Table.Cell>
                  <Table.Cell center>{data.meetingType}</Table.Cell>
                  <Table.Cell center>{data.time.toString()}</Table.Cell>
                  <Table.Cell center>{data.meetingPeriod}</Table.Cell>
                  <Table.Cell>{data.name}</Table.Cell>
                  <Table.Cell>{data.reviewerFullName}</Table.Cell>
                  <Table.Cell>{data.certifyingFullName}</Table.Cell>
                  <Table.Cell center>{`${formatDateTh(data.meetingDate)} ${getTime(data.startTime)} - ${getTime(data.endTime)} น.`}</Table.Cell>
                  <Table.Cell center><Status type={StatusType.MEETING_STATUS} value={data.status} /></Table.Cell>
                  <Table.Cell>{data.updateByUserFullName}</Table.Cell>
                  <Table.Cell center>{fullDateTime(data.updateDate)}</Table.Cell>
                  <Table.Cell center className="fix-col">
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={() => navigate(`list/${data.id}`)}>
                      <FaRegFileAlt className="me-2" /> ดูรายละเอียด
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </div>
    </Layout>
  );
}