import { HttpStatusCode } from "constant";
import { exportService } from "services";

export enum FileType {
  XLSX = '.xlsx',
  XLSM = '.xlsm',
  XLSB = '.xlsb',
  XLTX = '.xltx',
  PDF = '.pdf',
  CSV = '.csv',
  DOCX = '.docx',
  DOC = '.doc',
}

const currentDate = new Date();
const date = String(currentDate.getDate()).padStart(2, '0');
const month = String(currentDate.getMonth() + 1).padStart(2, '0');
const year = currentDate.getFullYear() + 543;
const hour = String(currentDate.getHours()).padStart(2, '0');
const minutes = String(currentDate.getMinutes()).padStart(2, '0');
const seconds = String(currentDate.getSeconds()).padStart(2, '0');
const fileName = `${date}${month}${year}${hour}${minutes}${seconds}`;

export const getFile = (file: Blob, fileName?: string, fileType?: FileType) => {
  const downloadLink = document.createElement('a');

  downloadLink.href = URL.createObjectURL(new Blob([file], { type: file.type }));
  downloadLink.download = `${fileName}${fileType ? `${fileType}` : ""}`;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  window.URL.revokeObjectURL(downloadLink.href);
};

export const exportFileExcel = (file: Blob) => {
  getFile(file, fileName, FileType.XLSX);
};

export const exportFileWord = (file: Blob) => {
  getFile(file, fileName, FileType.DOCX);
}

export const exportFileWordOldVersion = (file: Blob) =>
  getFile(file, fileName, FileType.DOC);

export const exportFileCsv = (file: Blob) => {
  getFile(file, fileName, FileType.CSV);
};

export const exportFileWordAsync = async (id: string, programName: string) => {
  const { data, status } = await exportService.exportWordFileAsync(id, programName);

  if (status === HttpStatusCode.OK) {
    getFile(data, fileName, FileType.DOCX);
  }
};

export const exportPdfByte = (file: Blob) => {
  getFile(file, fileName, FileType.PDF);
};

export const exportFilePDFAsync = async (id: string, programName: string) => {
  const { data, status } = await exportService.exportPdfFileAsync(id, programName);

  if (status === HttpStatusCode.OK) {
    getFile(data, fileName, FileType.PDF);
  };
};