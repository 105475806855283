import { Container } from "react-bootstrap";
import { Line } from "components";
import { useEffect, useState } from "react";

interface Props {
  children?: JSX.Element | JSX.Element[];
  title?: string;
  className?: string;
  subTitle?: string;
}

export function Layout(props: Props) {
  return (
    <Container fluid className={`layout ${props.className || ''}`}>
      {props.title ?
        <>
          <div className={`title d-flex align-items-end ${props.subTitle && 'justify-content-between'}`}>
            <div>
              {props.title}
            </div>
            <div>
              <h5>{props.subTitle}</h5>
            </div>
          </div>
          <Line />
        </> : null}
      {props.children}
    </Container>
  );
}