import { Card, Input, Layout, Table, Modal } from "components";
import { BureauCriteria, BureauModel, Pagination } from "models";
import { Dispatch, FormEvent, SetStateAction, useState } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import { FaEdit, FaPlus, FaSearch, FaTrashAlt, FaSyncAlt } from "react-icons/fa";
import { useLoaderData, useSubmit } from "react-router-dom";
import { calculateRowNumber, fullDateTime, showModalConfirmAsync, showModalRemoveAsync, submitForm } from "utils";
import { c0101 as service } from 'services';
import { HttpStatusCode } from "axios";
import toast from "utils/toast";
import { ModalType } from "constant";
import { MdAddCircleOutline, MdOutlineModeEdit } from "react-icons/md";

interface ModalProps {
  show: boolean;
  type: ModalType;
  onHide: () => void;
  bureau: BureauModel;
  setBureau: Dispatch<SetStateAction<BureauModel>>;
  onSearch: () => void;
};

type Loader = { bureauList: Pagination<BureauModel>; };

export default function C0101() {
  const { bureauList } = useLoaderData() as Loader;
  const [bureauSelected, setBureauSelected] = useState<BureauModel>({} as BureauModel);
  const [criteria, setCriteria] = useState<BureauCriteria>({} as BureauCriteria);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState<ModalType>(ModalType.ADD);
  const submit = useSubmit();

  const onSearchAsync = (size: number, page: number) => {
    submit({
      size: size.toString(),
      page: page.toString(),
      criteria: JSON.stringify(criteria),
    });
  };

  const onClear = () => {
    setCriteria({} as BureauCriteria);

    submit({
      size: 10,
      page: 1,
    });
  };

  const handlerShowModal = (type: ModalType, data?: BureauModel) => {
    setBureauSelected(data || {} as BureauModel);
    setModalType(type);
    setShowModal(true);
  };

  const onRemoveAsync = async (id: string) => {
    const isConfirm = await showModalRemoveAsync();

    if (isConfirm) {
      const { status } = await service.deleteBureauAsync(id);

      if (status === HttpStatusCode.NoContent) {
        submit({
          size: bureauList.size.toString(),
          page: bureauList.page.toString(),
          criteria: JSON.stringify(criteria),
        });

        toast.success("ลบรายการสำนักสำเร็จ");

        return;
      }

      toast.warn("ไม่สามารถลบรายการได้ เนื่องจากข้อมูลมีการใช้งานอยู่");
    }
  };

  const syncDataAsync = async () => {
    if (!await showModalConfirmAsync('ยืนยันการซิงค์ข้อมูล')) {
      return;
    }

    const res = await service.syncDataAsync();

    if (res.status === HttpStatusCode.Created) {
      toast.success('ซิงค์ข้อมูลสำเร็จ');

      onClear();
    }
  };

  const onSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSearchAsync(bureauList.size, bureauList.page)
  };

  return (
    <>
      <Layout title="สำนัก">
        <Card>
          <Form onSubmit={onSubmitSearch}>
            <Row>
              <Col sm={12} lg={6}>
                <div className="criteria-flex">
                  <Input
                    value={criteria.code}
                    label="รหัส"
                    placeholder="รหัส"
                    onChange={(value) => setCriteria({ ...criteria, code: value })} />
                  <Input
                    value={criteria.name}
                    label="ชื่อสำนัก"
                    placeholder="ชื่อสำนัก"
                    onChange={(value) => setCriteria({ ...criteria, name: value })} />
                </div>
              </Col>
              <Col sm={12}>
                <div className="d-flex gap-2">
                  <Button
                    variant="primary"
                    type="submit">
                    <FaSearch className="me-2" />ค้นหา
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={onClear}>
                    <FaTrashAlt className="me-2" />ล้างค่า
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Card>
        <div className="d-flex justify-content-end my-3">
          <Button variant="outline-primary" type="button" className="mx-3" onClick={syncDataAsync}>
            <FaSyncAlt className="me-2" />ซิงค์ข้อมูล
          </Button>
          <Button
            variant="primary"
            onClick={() => handlerShowModal(ModalType.ADD)}>
            <FaPlus className="me-2" />เพิ่ม
          </Button>
        </div>
        <Row className="mt-3">
          <Col>
            <div className="table-relative-fix">
              <div className="table-scroll">
                <Table
                  total={bureauList.totalRows}
                  onChange={onSearchAsync}
                  page={bureauList.page}
                  size={bureauList.size}>
                  <Table.Header>
                    <Table.Row>
                      <Table.Column minWidth={100} maxWidth={120}>ลำดับ</Table.Column>
                      <Table.Column minWidth={150}>รหัส</Table.Column>
                      <Table.Column minWidth={350}>สำนัก</Table.Column>
                      <Table.Column minWidth={250}>ผู้แก้ไขข้อมูล</Table.Column>
                      <Table.Column minWidth={200}>วันที่แก้ไขข้อมูล</Table.Column>
                      <Table.Column minWidth={100} className="fix-col" />
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {bureauList.rows?.map((value, index) => (
                      <Table.Row key={value.id}>
                        <Table.Cell center>{calculateRowNumber(index, bureauList.page, bureauList.size).toString()}</Table.Cell>
                        <Table.Cell center>{value.code}</Table.Cell>
                        <Table.Cell>{value.name}</Table.Cell>
                        <Table.Cell>{value.updateByUserFullName}</Table.Cell>
                        <Table.Cell center>{fullDateTime(value.updateDate)}</Table.Cell>
                        <Table.Cell center className="fs-5 fix-col">
                          <FaEdit className='me-2' onClick={() => handlerShowModal(ModalType.EDIT, value)} />
                          <FaTrashAlt className="ms-2" onClick={() => onRemoveAsync(value.id)} />
                        </Table.Cell>
                      </Table.Row>))}
                  </Table.Body>
                </Table>
              </div>
            </div>
          </Col>
        </Row>
      </Layout>
      <ModalManageBureau
        show={showModal}
        type={modalType}
        onHide={() => setShowModal(!showModal)}
        bureau={bureauSelected}
        setBureau={setBureauSelected}
        onSearch={() => onSearchAsync(bureauList.size, bureauList.page)}
      />
    </>
  );
}

function ModalManageBureau(props: ModalProps) {
  const handleOnSubmitAsync = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    submitForm(props.bureau);

    const isValid = !props.bureau.name;

    if (isValid) {
      toast.warn("กรุณากรอกข้อมูลให้ครบถ้วน");

      return;
    }

    if (props.bureau.id) {
      const { status } = await service.updateBureauAsync(props.bureau);

      if (status === HttpStatusCode.Accepted) {
        toast.success("แก้ไขรายการสำนักสำเร็จ");
      }
    } else {
      const { status } = await service.createBureauAsync(props.bureau);

      if (status === HttpStatusCode.Created) {
        toast.success("เพิ่มรายการสำนักสำเร็จ");
      }
    }

    props.onSearch();

    onHideModal();
  };

  const onHideModal = () => {
    props.onHide();
  };

  return (
    <Modal
      show={props.show}
      icon={props.type === ModalType.ADD ? <MdAddCircleOutline /> : <MdOutlineModeEdit />}
      title={props.type === ModalType.ADD ? `เพิ่มสำนัก` : "แก้ไขสำนัก"}>
      <Form onSubmit={handleOnSubmitAsync}>
        <div className="mt-3">
          <Input
            name="code"
            label="รหัส"
            value={props.bureau.code}
            disabled />
          <Input
            name="name"
            label="ชื่อสำนัก"
            value={props.bureau.name}
            onChange={(value) => props.setBureau({ ...props.bureau, name: value })}
            rule={{ required: true, maxLength: 100 }} />
          <div className="button d-flex flex-row gap-3 justify-content-center mt-4">
            <Button
              variant="outline-primary"
              className="w-50"
              onClick={onHideModal}>
              ยกเลิก
            </Button>
            <Button
              variant="primary"
              className="w-50"
              type="submit">
              บันทึก
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
}