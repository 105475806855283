import { HttpStatusCode } from "axios";
import { Card, Check, Input, Layout, Modal, Selector, Table } from "components";
import { Code, MovementType } from "constant";
import { SelectionType } from "constant/selection";
import { ItemModel, ModalProps, OfficerRankModel, Pagination, UserSelection } from "models";
import { FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaChevronCircleDown, FaChevronCircleUp, FaPlus, FaRegSave, FaSearch, FaTrashAlt } from "react-icons/fa";
import { MdAddCircleOutline } from "react-icons/md";
import { useLoaderData } from "react-router-dom";
import { s0104 as service, selectionService as ddlService } from "services";
import { showModalCancelAsync, showModalRemoveAsync, submitForm } from "utils";
import toast from "utils/toast";

type Loader = { bureauList: ItemModel[], bureauGroupList: ItemModel[], officerRankList: PaginationExtends };

interface ModalExtendsOfficerRank extends ModalProps<OfficerRankModel> {
  lastSequence: number;
  officerRank: OfficerRankModel[];
};

interface PaginationExtends extends Pagination<OfficerRankModel> {
  onEdit: boolean;
};

export default function S0104() {
  const { officerRankList } = useLoaderData() as Loader;
  const [mount, setMount] = useState<boolean>(false);
  const [officerRank, setOfficerRank] = useState<PaginationExtends>(officerRankList);
  const [keyword, setKeyword] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(9999);
  const [showModal, setShowModal] = useState(false);
  const [onDisabled, setOnDisabled] = useState(false);

  const onSearchAsync = useCallback(async (page: number, size: number, onEdit: boolean, keyword?: string) => {
    if (onEdit) {
      const IsCancel = await showModalCancelAsync();

      if (!IsCancel) {
        return;
      }
    }

    const { data, status } = await service.getOfficerRankListAsync(page, size, keyword);

    if (status === HttpStatusCode.Ok) {
      setOfficerRank(data);
      setOnDisabled(true);
    }
  }, [page, size]);

  useEffect(() => {
    if (mount) {
      onSearchAsync(page, size, officerRank.onEdit, keyword);
    }

    !mount && setMount(true);
  }, [onSearchAsync]);

  const onClearAsync = async () => {
    if (officerRank.onEdit) {
      const IsCancel = await showModalCancelAsync();

      if (!IsCancel) {
        return;
      }
    }

    setKeyword("");

    const { data, status } = await service.getOfficerRankListAsync(page, size);

    if (status === HttpStatusCode.Ok) {
      setOfficerRank(data);
      setOnDisabled(false);
    }
  };

  const onRemoveAsync = async (id: string) => {
    const isConfirm = await showModalRemoveAsync();
    if (isConfirm) {

      const { status } = await service.deleteOfficerRankAsync(id);

      if (status === HttpStatusCode.NoContent) {

        const { data, status } = await service.getOfficerRankListAsync(page, size);

        if (status === HttpStatusCode.Ok) {
          setOfficerRank(data);
          toast.success("ลบข้อมูลรายชื่อผู้จดรายงานการประชุมสำเร็จ");
        }
      }
    }
  };

  const onChangePageSize = (size: number, page: number) => {
    setPage(page);
    setSize(size);
  };

  const currentRank = useMemo(() => {
    if (officerRank.rows.length > 0) {
      return officerRank.rows.length + 1;
    }

    return 1;
  }, [officerRank]);

  const onChangeSequence = (sequenceType: MovementType, index: number, sequence: number) => {
    if (sequenceType === MovementType.UP) {
      if (sequence === 1) {

        return;
      }

      const currentIndexData = officerRank.rows.find(f => f.rank === sequence);
      const beforeIndexData = officerRank.rows.find(f => f.rank === (sequence - 1));

      if (currentIndexData?.rank && beforeIndexData?.rank) {
        currentIndexData.rank = currentIndexData.rank - 1;
        beforeIndexData.rank = beforeIndexData.rank + 1;

        const temp = [...officerRank.rows];
        temp.splice((index - 1), 2, currentIndexData, beforeIndexData);


        setOfficerRank({ ...officerRank, rows: temp, onEdit: true });
      }
    } else if (sequenceType === MovementType.DOWN) {
      if (sequence === officerRank.rows.length) {

        return;
      }

      const currentIndexData = officerRank.rows.find(f => f.rank === sequence);
      const afterIndexData = officerRank.rows.find(f => f.rank === (sequence + 1));

      if (currentIndexData?.rank && afterIndexData?.rank) {
        currentIndexData.rank = currentIndexData.rank + 1;
        afterIndexData.rank = afterIndexData.rank - 1;

        const temp = [...officerRank.rows];
        temp.splice(index, 2, afterIndexData, currentIndexData);

        setOfficerRank({ ...officerRank, rows: temp, onEdit: true });
      }
    }
  };

  const onSubmitAsync = async () => {
    const { status } = await service.updateOfficerRankAsync(officerRank.rows);

    if (status === HttpStatusCode.Accepted) {
      const listData = await service.getOfficerRankListAsync(page, size);

      if (listData.status === HttpStatusCode.Ok) {
        setOfficerRank(listData.data);
        setOnDisabled(false);
      }

      toast.success("บันทึกข้อมูลลำดับผู้จดรายงานการประชุมสำเร็จ");
    }
  };

  const onShowModalAsync = async () => {
    if (officerRank.onEdit) {
      const IsCancel = await showModalCancelAsync();

      if (!IsCancel) {
        return;
      }
    }

    const { data, status } = await service.getOfficerRankListAsync(page, size);

    if (status === HttpStatusCode.Ok) {
      setOfficerRank(data);
    }

    setShowModal(!showModal);
  };

  const onCheckStopWork = (index: number, value: boolean) => {
    const findDataInList = officerRank.rows[index];

    if (findDataInList) {
      findDataInList.stopWork = value;

      const temp = [...officerRank.rows];
      temp.splice(index, 1, findDataInList);

      setOfficerRank({ ...officerRank, rows: temp, onEdit: true });
    }
  };

  const onSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSearchAsync(page, size, officerRank.onEdit, keyword);
  };

  return (
    <>
      <Layout title="รายชื่อผู้จดรายงานการประชุม">
        <div className="d-flex justify-content-end my-2">
          <Button
            variant="primary"
            onClick={onSubmitAsync}
            disabled={onDisabled}>
            <FaRegSave className="me-2" />บันทึก
          </Button>
        </div>
        <Card>
          <Form onSubmit={onSubmitSearch}>
            <Row>
              <Col sm={12} lg={4}>
                <div className="criteria-flex">
                  <div className="control">
                    <Input
                      label="ค้นหา"
                      placeholder="สำนัก/กลุ่มงาน/ชื่อ-นามสกุล"
                      value={keyword}
                      onChange={(value) => setKeyword(value)} />
                  </div>
                  <div className="button">
                    <Button
                      variant="primary"
                      type="submit">
                      <FaSearch className="me-2" />ค้นหา
                    </Button>
                    <Button
                      variant="outline-primary"
                      onClick={onClearAsync}>
                      <FaTrashAlt className="me-2" />ล้างค่า
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Card>
        <div className="d-flex justify-content-end mt-2 my-2">
          <Button
            variant="primary"
            onClick={onShowModalAsync}>
            <FaPlus className="me-2" />เพิ่ม
          </Button>
        </div>
        <Table total={officerRank.totalRows} onChange={onChangePageSize} hidePagination>
          <Table.Header>
            <Table.Row>
              <Table.Column minWidth={100}>ลำดับ</Table.Column>
              <Table.Column minWidth={300}>สำนัก</Table.Column>
              <Table.Column minWidth={236}>กลุ่มงาน</Table.Column>
              <Table.Column minWidth={304}>ชื่อ - นามสกุล</Table.Column>
              <Table.Column minWidth={100}>หยุดปฏิบัติหน้าที่</Table.Column>
              <Table.Column minWidth={130} />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {officerRank.rows?.map((value, index) => (
              <Table.Row key={value.id}>
                <Table.Cell center>{value.rank.toString()}</Table.Cell>
                <Table.Cell>{value.bureauName}</Table.Cell>
                <Table.Cell>{value.bureauGroupName}</Table.Cell>
                <Table.Cell>{value.name}</Table.Cell>
                <Table.Cell>
                  <div className="d-flex justify-content-center">
                    <Check
                      value={value.stopWork}
                      onChange={(value) => onCheckStopWork(index, value)} />
                  </div>
                </Table.Cell>
                <Table.Cell center>
                  {!onDisabled ?
                    <>
                      <FaChevronCircleUp className="me-2 fs-5" onClick={() => onChangeSequence(MovementType.UP, index, value.rank)} />
                      <FaChevronCircleDown className="mx-2 fs-5" onClick={() => onChangeSequence(MovementType.DOWN, index, value.rank)} />
                      {!officerRank.onEdit && <FaTrashAlt className="ms-2 fs-5" onClick={() => onRemoveAsync(value.id)} />}
                    </>
                    : <></>
                  }
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Layout>
      <ModalOfficerRank
        show={showModal}
        onHide={() => setShowModal(!showModal)}
        onSearch={onClearAsync}
        lastSequence={currentRank}
        officerRank={officerRank.rows}
      />
    </>
  );
};

function ModalOfficerRank(props: ModalExtendsOfficerRank) {
  const { bureauList, bureauGroupList } = useLoaderData() as Loader;
  const [bureauGroupItems, setBureauGroupItems] = useState<ItemModel[]>([]);
  const [officer, setOfficer] = useState<OfficerRankModel>({} as OfficerRankModel);
  const [userList, setUserList] = useState<ItemModel[]>([]);

  useEffect(() => {
    if (props.show) {
      const bureauFilterData = bureauList.filter(f => f.label.includes(Code.BUREAU_CODE))[0].value.toString();

      setBureauGroupItems(bureauGroupList.filter(f => f.id == bureauFilterData).slice(2));

      setOfficer({
        ...officer,
        rank: props.lastSequence,
        bureauId: bureauList.filter(f => f.label.includes(Code.BUREAU_CODE))[0].value.toString(),
      });
    }
  }, [props.show]);

  const onGetUserListAsync = useCallback(async (bureauId: string, bureauGroupId: string) => {
    if (bureauId && bureauGroupId) {
      const criteria: UserSelection = {
        bureauId,
        bureauGroupId,
        isNotContract: true,
      };

      const { data, status } = await ddlService.getUserSelectionAsync(SelectionType.SM_USER, criteria);

      if (status === HttpStatusCode.Ok) {
        setUserList(data);
      };
    }
  }, []);

  const onChangeBureauGroup = (value: string | number) => {
    if (value) {
      setOfficer({ ...officer, bureauGroupId: value.toString(), userId: "", });

      onGetUserListAsync(officer.bureauId, value.toString());

      return;
    }

    setOfficer({ ...officer, bureauGroupId: "", userId: "", });
    setUserList([]);
  }

  const onSubmitAsync = async () => {
    submitForm(officer);

    const isValid = !officer.bureauId || !officer.bureauGroupId || !officer.userId || !officer.rank;

    if (isValid) {
      toast.warn("กรุณากรอกข้อมูลให้ครบถ้วน");

      return;
    }

    if (props.officerRank.some(s => s.userId === officer.userId)) {
      toast.warn("รายชื่อซ้ำ");

      return;
    }

    const { status } = await service.createOfficerRankAsync(officer);

    if (status !== HttpStatusCode.Created) {

      toast.warn("ลำดับ หรือ รายชื่อ ผู้จดรายงานการประชุมซ้ำ");

      return;
    }

    props.onSearch();

    toast.success("บันทึกข้อมูลลำดับผู้จดรายงานการประชุมสำเร็จ");

    onHideModal();
  };

  const onHideModal = () => {
    setOfficer({} as OfficerRankModel);

    props.onHide();
  };

  return (
    <Modal
      size="lg"
      show={props.show}
      icon={<MdAddCircleOutline />}
      title={`เพิ่มรายชื่อผู้จดรายงานการประชุม`}>
      <Row className="mt-2">
        <Col sm={12} lg={6}>
          <Selector
            name="bureauId"
            label="สำนัก"
            placeholder="สำนัก"
            items={bureauList.filter(f => f.label.includes(Code.BUREAU_CODE))}
            value={officer.bureauId}
            onChange={(value) => setOfficer({ ...officer, bureauId: value.toString() })}
            rule={{ required: true }}
            disabled />
        </Col>
      </Row>
      <Row>
        <Col sm={12} lg={6}>
          <Selector
            name="bureauGroupId"
            label="กลุ่มงาน"
            placeholder="กลุ่มงาน"
            items={bureauGroupItems}
            value={officer.bureauGroupId}
            onChange={(value) => onChangeBureauGroup(value)}
            rule={{ required: true }} />
        </Col>
      </Row>
      <Row>
        <Col sm={12} lg={6}>
          <Selector
            name="userId"
            label="ชื่อ-นามสกุล"
            placeholder="ชื่อ-นามสกุล"
            items={userList}
            value={officer.userId}
            onChange={(value) => setOfficer({ ...officer, userId: value.toString() })}
            rule={{ required: true }} />
        </Col>
        <Col sm={12} lg={6}>
          <Input
            name="rank"
            label="ลำดับที่"
            placeholder="ลำดับที่"
            type="number"
            value={officer.rank}
            onChange={(value) => setOfficer({ ...officer, rank: Number(value) })}
            disabled />
        </Col>
      </Row>
      <div className="button d-flex flex-row gap-3 justify-content-center mt-4">
        <Button
          variant="outline-primary"
          className="w-50"
          onClick={onHideModal}>
          ยกเลิก
        </Button>
        <Button
          variant="primary"
          className="w-50"
          onClick={onSubmitAsync}>
          บันทึก
        </Button>
      </div>
    </Modal>
  );
};