import { HttpStatusCode } from 'constant';
import { SnMeetingChapterDebaters } from 'models';
import { SnMakeDocumentCriteria, SnMakeDocumentMeetingCriteria, SnMakeDocumentMeetingList } from 'models/s0204';
import http from 'utils/axios';
import * as tus from 'tus-js-client'
import { showModalProgressBarLoadingAsync } from 'utils';

const getSnMakeDocumentListAsync = async (page: number, size: number, criteria?: SnMakeDocumentCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get('api/snmakedocument', { params });
};

const getSnMakeDocumentMeetingListAsync = async (id: string, page: number, size: number, criteria?: SnMakeDocumentMeetingCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get(`api/snmakedocument/meetingId/${id}`, { params });
};

const getSnMakeDocumentDetailAsync = async (id: string) => {
  return await http.get(`api/snmakedocument/${id}`);
};

const updateSmMakeDocumentDetailAsync = async (id: string, status: string) => {
  const body = {
    status
  };

  return await http.put(`api/snmakedocument/${id}`, body);
};

const createDebatersChapterAsync = async (id: string, body: SnMeetingChapterDebaters[]) => {
  return await http.post(`api/snmakedocument/debaterchapter/${id}`, body);
};

const getAgenDataItemsAsync = async (id: string) => {
  return await http.get(`api/snmakedocument/smmeeting/${id}/meetingagenda`);
};

const updateTimeofChapterListAsync = async (id: string, data: SnMakeDocumentMeetingList[]) =>
  await http.put(`api/snmakedocument/smmeeting/${id}/chapter/`, data);

const uploadVideoAsync = async (meetingId: string, id: string, video: File) => {
  const fileId = await new Promise<string>(
    (resolve, reject) => {
      const upload = new tus.Upload(video, {
        endpoint: `${process.env.REACT_APP_API_URL}api/files/tus/`,
        // Retry delays will enable tus-js-client to automatically retry on errors
        retryDelays: [0, 3000, 5000, 10000, 20000],
        chunkSize: 30000000,
        parallelUploads: 1,
        // Attach additional meta data about the file for the server
        metadata: {
          filename: video.name,
          filetype: video.type,
        },
        // Callback for errors which cannot be fixed using retries
        onError: function (error) {
          reject(error);
        },
        // Callback for reporting upload progress
        onProgress: function (bytesUploaded, bytesTotal) {
          const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2)
          showModalProgressBarLoadingAsync(parseFloat(percentage), "กำลังอัพโหลดวิดิโอ...");
        },
        // Callback for once the upload is completed
        onSuccess: async function () {
          const paths = upload.url?.split('/');
          if (paths != null) {
            const lastPath = paths[paths?.length - 1];

            const formData = new FormData();
            formData.append("SnMeetingRankId", id);
            formData.append("TusFileId", lastPath);
            formData.append("FileName", video.name);

            const resp = await http.post(
              `api/snmakedocument/uploadmanualvideo/${meetingId}`,
              formData)

            if (resp.status === HttpStatusCode.ACCEPTED ||
              resp.status === HttpStatusCode.OK) {
              resolve(resp.data);
            } else {
              reject(resp.data);
            }
          }
        },
      })

      upload.start();
    })

  return fileId;
};

const removeDocAsync = async (id: string) =>
  await http.delete(`api/SnMakeDocument/snchapterdocument/${id}`);

const getDutyOfficerByChapterAsync = async (meetingId: string, chapterId: string) =>
  await http.get(`api/SnMakeDocument/snmeeting/chapter/detail/${meetingId}`, { params: { chapterId } });

const snMakeDocumentService = {
  getSnMakeDocumentListAsync,
  getSnMakeDocumentMeetingListAsync,
  getSnMakeDocumentDetailAsync,
  updateSmMakeDocumentDetailAsync,
  createDebatersChapterAsync,
  getAgenDataItemsAsync,
  updateTimeofChapterListAsync,
  uploadVideoAsync,
  removeDocAsync,
  getDutyOfficerByChapterAsync,
};

export default snMakeDocumentService;
