import { Debaters, MeetingAgenda, SnMeetingAgendaEventLogCriteria, SnMeetingAgendas } from 'models';
import http from 'utils/axios';

const getSnMeetingAgendaEventLogAsync = async (page: number, size: number, criteria?: SnMeetingAgendaEventLogCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get('api/snmeetingagendaeventlog', { params });
};

const getSnMeetingAgendaEventLogDetailAsync = async (id: string) => {
  return await http.get(`api/snmeetingagendaeventlog/${id}`);
};

const updateDebatersAsync = async (id: string, data: Debaters[]) => {
  const body = {
    debaters: data,
  };

  return await http.put(`api/snmeetingagendaeventlog/${id}`, body);
};

const createSnMeetingAgendaEventLogAgendaAsync = async (id: string, body: SnMeetingAgendas) => {
  return await http.post(`api/snmeetingagendaeventlog/meetingId/${id}`, body);
};

const updateSnMeetingAgendaEventLogAgendaAsync = async (id: string, body: SnMeetingAgendas) => {
  return await http.put(`api/snmeetingagendaeventlog/snmeetingagenda/${id}`, body);
};

const deleteSnMeetingAgendaEventLogAgendaAsync = async (id: string) => {
  return await http.delete(`api/snmeetingagendaeventlog/snmeetingagenda/${id}`);
};

const updateNoDebatersAsync = async (id: string, body: MeetingAgenda[]) => {
  return await http.put(`api/snmeetingagendaeventlog/meetingId/${id}`, body);
};

const exportEventDocumentAsync = async (meetingId: string) =>
  await http.get(`api/snmeetingagendaeventlog/export-pdf/${meetingId}`, { responseType: 'blob' });

const smMeetingAgendaEventlogService = {
  getSnMeetingAgendaEventLogAsync,
  getSnMeetingAgendaEventLogDetailAsync,
  updateDebatersAsync,
  createSnMeetingAgendaEventLogAgendaAsync,
  updateNoDebatersAsync,
  updateSnMeetingAgendaEventLogAgendaAsync,
  deleteSnMeetingAgendaEventLogAgendaAsync,
  exportEventDocumentAsync,
};

export default smMeetingAgendaEventlogService;