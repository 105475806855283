import { PersonType, SelectionType } from "constant";
import { SnMakeDocumentCriteria } from "models";
import { LoaderFunctionArgs } from "react-router";
import { s0204, s0205, selectionService, s0301 } from "services";

export const s0204LoaderAsync = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 1;
  const size = url.searchParams.get('size') || 10;
  const criteriaStringify = url.searchParams.get('criteria') || '{}';
  const criteria = JSON.parse(criteriaStringify) as SnMakeDocumentCriteria;

  const [snMakeDocument, snMeetingType, snMeetingPeriod] = await Promise.all([
    s0204.getSnMakeDocumentListAsync(+page, +size, criteria),
    selectionService.getSelectionAsync(SelectionType.SN_MEETING_TYPE),
    selectionService.getSelectionAsync(SelectionType.SN_MEETING_PERIOD),
  ]);

  return {
    snMakeDocumentList: {
      rows: snMakeDocument.data.rows,
      totalRows: snMakeDocument.data.totalRows,
      page: +page,
      size: +size,
    },
    snMeetingTypeItems: snMeetingType.data,
    snMeetingPeriodItems: snMeetingPeriod.data,
  };
};

export const s0204DetailLoaderAsync = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;

  if (id) {
    const [snMakeDocumentMeeting, snAgendaType, headerData] = await Promise.all([
      s0204.getSnMakeDocumentMeetingListAsync(id, 1, 10),
      s0205.getAgendaDDLAsync(id),
      s0301.getDetailAsync(id),
    ]);

    return {
      snMakeDocumentMeetingList: snMakeDocumentMeeting.data.rows,
      snMakeDocumentMeetingRows: snMakeDocumentMeeting.data.totalRows,
      snAgendaTypeList: snAgendaType.data,
      headerData: headerData.data,
    }
  }

  return {
    snMakeDocumentMeetingList: null,
    snMakeDocumentMeetingRows: null,
    headerData: {},
  }
};

export const s0204DocumentLoaderAsync = async ({ params }: LoaderFunctionArgs) => {
  const { documentId, id } = params;

  if (documentId && id) {
    const [snMakeDocumentDetail, attendedType, snMember, agendaItems, dutyOfficerData, headerData] = await Promise.all([
      s0204.getSnMakeDocumentDetailAsync(documentId),
      selectionService.getPersonTypeListAsync(SelectionType.SN_MEMBER),
      selectionService.getSelectionAsync(SelectionType.SN_MEMBER),
      s0204.getAgenDataItemsAsync(id),
      s0204.getDutyOfficerByChapterAsync(id, documentId),
      s0301.getDetailAsync(id),
    ]);

    attendedType.data.push({ value: PersonType.Other, label: PersonType.Other });

    return {
      snMakeDocumentData: snMakeDocumentDetail.data,
      attendedTypeItems: attendedType.data,
      snMemberItems: snMember.data,
      agendaItems: agendaItems.data,
      dutyOfficerData: dutyOfficerData.data,
      headerData: headerData.data,
    }
  }
};