import { Card, Switch } from "components";
import { useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaCopy } from "react-icons/fa";
import { AiOutlineClear } from "react-icons/ai";
import toast from "utils/toast";

const speechRecognition = (window as any).webkitSpeechRecognition;
const microphone = new speechRecognition;

microphone.continuous = true;
microphone.interimResults = true;
microphone.lang = "th-TH";

export default function SpeechToText() {
  const [text, setText] = useState<string>('');
  const [results, setResults] = useState<string[]>([]);
  const [startSpeechRecognition, setStartSpeechRecognition] = useState<boolean>(false);
  const [loopNo, setLoopNo] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  microphone.onstart = () => {
    console.log("Speech recognition service has started");

    listenerResult();
  };

  microphone.onend = () => {
    console.log("Speech recognition service disconnected");

    if (startSpeechRecognition) {
      microphone.start();
    }
  };

  microphone.onerror = (event: any) =>
    console.error(`Speech recognition error detected: ${event.error}`);

  const listenerResult = () => {
    microphone.onresult = (event: any) => {
      for (let i = event.resultIndex; i < event.results.length; i++) {
        const { transcript } = event.results[i][0];

        results[Number(`${loopNo}${event.resultIndex + 1}`)] = transcript;

        setResults([...results]);
        setText(results.join(' '));

        if (event.resultIndex == 2 && startSpeechRecognition) {
          setLoopNo(loopNo + 1);

          microphone.stop();
          microphone.start();
        }
      }
    };
  };

  const copyText = () => {
    navigator.clipboard.writeText(text.replace(/\s/g, ""));

    toast.success('คัดลอกข้อความสำเร็จ');
  };

  const clearText = () => {
    if (startSpeechRecognition) {
      return toast.info("กรุณาปิดการใช้งานการแปลงเสียงพูดเป็นข้อความก่อนล้างข้อความ");
    }

    setResults([]);
    setText('');

    toast.success('ล้างข้อความสำเร็จ');
  };

  const switchOnChange = (value: boolean) => {
    if (value) {
      setStartSpeechRecognition(() => true);
      setLoopNo(loopNo + 1);

      microphone.start();

      return;
    }

    setStartSpeechRecognition(() => false);

    microphone.stop();
  };

  return (
    <Card className="mt-2 border-primary">
      <div className="d-flex justify-content-between align-items-center">
        <Form.Label
          className="text-decoration-underline fs-7">
          การแปลงเสียงพูดเป็นข้อความ
        </Form.Label>
        <Switch label="เปิดการใช้งาน" onChange={switchOnChange} />
      </div>
      <div style={{ height: 200, overflowY: 'auto' }} ref={ref}>
        {text}
      </div>
      <Row>
        <Col xs={6} className="mt-3">
          <span>
            <a
              href="https://drive.google.com/file/d/1gqMzGpNUcTJ4TGpj_uVOHsDlz8BVt3mj/view"
              target="_blank"
              className="fs-6">
              ดาวน์โหลดและติดตั้งเครื่องมือ
            </a>
          </span>
        </Col>
        <Col xs={6} className="my-auto">
          <div className="d-flex gap-2 justify-content-end">
            <Button
              size="sm"
              variant="outline-primary"
              onClick={clearText}>
              <AiOutlineClear className="me-2" />ล้าง
            </Button>
            <Button
              size="sm"
              variant="outline-primary"
              onClick={copyText}>
              <FaCopy className="me-2" />คัดลอก
            </Button>
          </div>
        </Col>
      </Row>
    </Card>
  );
};