import http from 'utils/axios';
import { BureauModel, BureauCriteria } from 'models';

const getBureauListAsync = async (page: number, size: number, criteria?: BureauCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get(`api/smbureau`, { params });
};

const createBureauAsync = async (data: BureauModel) =>
  await http.post(`api/smbureau`, data);

const updateBureauAsync = async (data: BureauModel) =>
  await http.put(`api/smbureau/${data.id}`, data);

const deleteBureauAsync = async (id: string) =>
  await http.delete(`api/smbureau/${id}`);

const syncDataAsync = async () =>
  await http.post('api/smbureau/sync-data');

const c0101 = {
  getBureauListAsync,
  createBureauAsync,
  updateBureauAsync,
  deleteBureauAsync,
  syncDataAsync,
};

export default c0101;