import { SelectionType } from "constant";
import { LoaderFunctionArgs } from "react-router-dom";
import { s0206, selectionService } from "services";

export const s0206LoaderAsync = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 1;
  const size = url.searchParams.get('size') || 10;
  const criteria = JSON.parse(url.searchParams.get('criteria')!);

  const [snReviewOverview, snMeetingType, snMeetingPeriod] = await Promise.all([
    s0206.getSnReviewOverviewListAsync(+page, +size, criteria),
    selectionService.getSelectionAsync(SelectionType.SN_MEETING_TYPE),
    selectionService.getSelectionAsync(SelectionType.SN_MEETING_PERIOD),
  ]);

  return {
    data: {
      rows: snReviewOverview.data.rows,
      totalRows: snReviewOverview.data.totalRows,
      page: +page,
      size: +size,
    },
    snMeetingTypeItems: snMeetingType.data,
    snMeetingPeriodItems: snMeetingPeriod.data,
  };
};

export const s0206DetailLoaderAsync = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;

  if (id) {
    const [snReviewOverview, snMeetingType, snMeetingPeriod] = await Promise.all([
      s0206.getSnReviewOverviewByIdAsync(id),
      selectionService.getSelectionAsync(SelectionType.SN_MEETING_TYPE),
      selectionService.getSelectionAsync(SelectionType.SN_MEETING_PERIOD),
    ]);

    return {
      snReviewOverviewDetail: snReviewOverview.data,
      snMeetingTypeItems: snMeetingType.data,
      snMeetingPeriodItems: snMeetingPeriod.data,
    }
  }
};