import { Card, Input, Layout, Table, Modal, Selector } from "components";
import { HttpStatusCode, ModalType } from "constant";
import { ItemModel, Pagination, SubCommitteeCriteria, SubCommitteeModel } from "models";
import { Dispatch, FormEvent, SetStateAction, useState } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import { FaEdit, FaPlus, FaSearch, FaTrashAlt, FaSyncAlt } from "react-icons/fa";
import { useLoaderData, useSubmit } from "react-router-dom";
import { calculateRowNumber, fullDateTime, showModalConfirmAsync, showModalRemoveAsync, submitForm } from "utils";
import { c0103 as service } from 'services';
import { MdAddCircleOutline, MdOutlineModeEdit } from "react-icons/md";
import toast from "utils/toast";

interface ModalProps {
  show: boolean;
  type: ModalType;
  onHide: () => void;
  subCommittee: SubCommitteeModel;
  setSubCommittee: Dispatch<SetStateAction<SubCommitteeModel>>;
  onSearch: () => void;
};

type Loader = { subCommitteeList: Pagination<SubCommitteeModel>, bureauList: ItemModel[], committeeList: ItemModel[] };

export default function C0103() {
  const { subCommitteeList, committeeList } = useLoaderData() as Loader;
  const [subCommitteeSelected, setSubCommitteeSelected] = useState<SubCommitteeModel>({} as SubCommitteeModel);
  const [criteria, setCriteria] = useState<SubCommitteeCriteria>({} as SubCommitteeCriteria);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState<ModalType>(ModalType.ADD);
  const submit = useSubmit();

  const onSearchAsync = (size: number, page: number) => {
    submit({
      size: size.toString(),
      page: page.toString(),
      criteria: JSON.stringify(criteria),
    });
  };

  const onClear = () => {
    setCriteria({} as SubCommitteeCriteria);

    submit({
      size: 10,
      page: 1,
    });
  };

  const handlerShowModal = (type: ModalType, data?: SubCommitteeModel) => {
    setSubCommitteeSelected(data || {} as SubCommitteeModel);
    setModalType(type);
    setShowModal(true);
  };

  const onRemoveAsync = async (id: string) => {
    const isConfirm = await showModalRemoveAsync();

    if (isConfirm) {
      const { status } = await service.deleteSubCommitteeAsync(id);

      if (status === HttpStatusCode.NO_CONTENT) {
        onSearchAsync(subCommitteeList.size, subCommitteeList.page);

        toast.success("ลบรายการคณะกรรมาธิการสำเร็จ");

        return;
      }

      toast.warn("ไม่สามารถลบรายการได้ เนื่องจากข้อมูลมีการใช้งานอยู่");
    }
  };

  const onChange = (key: string, value: string | number | Date) => {
    setCriteria({
      ...criteria,
      [key]: value,
    });
  };

  const syncDataAsync = async () => {
    if (!await showModalConfirmAsync('ยืนยันการซิงค์ข้อมูล')) {
      return;
    }

    const res = await service.syncDataAsync();

    if (res.status === HttpStatusCode.ACCEPTED) {
      toast.success('ซิงค์ข้อมูลสำเร็จ');

      onClear();
    }
  };

  const onSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSearchAsync(subCommitteeList.size, subCommitteeList.page);
  };

  return (
    <>
      <Layout title="คณะอนุกรรมาธิการ/คณะทำงาน">
        <Card>
          <Form onSubmit={onSubmitSearch}>
            <Row>
              <Col sm={12} lg={8}>
                <div className="criteria-flex d-flex gap-3">
                  <div className="control d-flex gap-3">
                    <Selector
                      label="คณะกรรมาธิการ/อื่นๆ"
                      placeholder="คณะกรรมาธิการ/อื่นๆ"
                      items={committeeList}
                      value={criteria.committeeId}
                      onChange={(value) => onChange("committeeId", value)} />
                    <Input
                      label="รหัส"
                      placeholder="รหัส"
                      value={criteria.code}
                      onChange={(value) => onChange("code", value)} />
                    <Input
                      label="คณะอนุกรรมาธิการ/คณะทำงาน"
                      placeholder="คณะอนุกรรมาธิการ/คณะทำงาน"
                      value={criteria.name}
                      onChange={(value) => onChange("name", value)} />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="button d-flex gap-3 justify-content-start mt-2">
                  <Button
                    variant="primary"
                    type="submit">
                    <FaSearch className="me-2" />ค้นหา
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={onClear}>
                    <FaTrashAlt className="me-2" />ล้างค่า
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Card>
        <div className="d-flex justify-content-end my-3">
          <Button variant="outline-primary" type="button" className="mx-3" onClick={syncDataAsync}>
            <FaSyncAlt className="me-2" />ซิงค์ข้อมูล
          </Button>
          <Button
            variant="primary"
            type="button"
            onClick={() => handlerShowModal(ModalType.ADD)}>
            <FaPlus className="me-2" />เพิ่ม
          </Button>
        </div>
        <Row className="mt-3">
          <Col>
            <div className="table-relative-fix">
              <div className="table-scroll">
                <Table
                  total={subCommitteeList.totalRows}
                  page={subCommitteeList.page}
                  size={subCommitteeList.size}
                  onChange={onSearchAsync}>
                  <Table.Header>
                    <Table.Row>
                      <Table.Column minWidth={100} maxWidth={120}>ลำดับ</Table.Column>
                      <Table.Column minWidth={200}>คณะกรรมาธิการ/อื่นๆ</Table.Column>
                      <Table.Column minWidth={250}>ชื่อย่อ</Table.Column>
                      <Table.Column minWidth={150} maxWidth={150}>รหัสคณะอนุกรรมาธิการ/คณะทำงาน</Table.Column>
                      <Table.Column minWidth={150} maxWidth={250}>คณะอนุกรรมาธิการ/คณะทำงาน</Table.Column>
                      <Table.Column minWidth={200}>ผู้แก้ไขข้อมูล</Table.Column>
                      <Table.Column minWidth={150}>วันที่แก้ไขข้อมูล</Table.Column>
                      <Table.Column minWidth={100} className="fix-col" />
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {subCommitteeList.rows?.map((value, index) => (
                      <Table.Row key={value.id}>
                        <Table.Cell center>{calculateRowNumber(index, subCommitteeList.page, subCommitteeList.size).toString()}</Table.Cell>
                        <Table.Cell>{value.committeeName}</Table.Cell>
                        <Table.Cell>{value.shortName}</Table.Cell>
                        <Table.Cell center>{value.code}</Table.Cell>
                        <Table.Cell>{value.name}</Table.Cell>
                        <Table.Cell>{value.updateByUserFullName}</Table.Cell>
                        <Table.Cell center>{fullDateTime(value.updateDate)}</Table.Cell>
                        <Table.Cell center className="fs-5 fix-col">
                          <FaEdit className='me-2' onClick={() => handlerShowModal(ModalType.EDIT, value)} />
                          <FaTrashAlt className="ms-2" onClick={() => onRemoveAsync(value.id)} />
                        </Table.Cell>
                      </Table.Row>))}
                  </Table.Body>
                </Table>
              </div>
            </div>
          </Col>
        </Row>
      </Layout>
      <ModalManageSubCommittee
        show={showModal}
        type={modalType}
        onHide={() => setShowModal(!showModal)}
        subCommittee={subCommitteeSelected}
        setSubCommittee={setSubCommitteeSelected}
        onSearch={() => onSearchAsync(subCommitteeList.size, subCommitteeList.page)} />
    </>
  );
}

function ModalManageSubCommittee(props: ModalProps) {
  const { committeeList, bureauList } = useLoaderData() as Loader;

  const handleOnSubmitAsync = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    submitForm(props.subCommittee);

    const isValid =
      !props.subCommittee.name ||
      !props.subCommittee.bureauId ||
      !props.subCommittee.committeeId;

    if (isValid) {
      toast.warn("กรุณากรอกข้อมูลให้ครบถ้วน");

      return;
    }

    if (props.subCommittee.id) {
      const { status } = await service.updateSubCommitteeAsync(props.subCommittee);

      if (status !== HttpStatusCode.ACCEPTED) {
        toast.warn("รหัสคณะอนุกรรมาธิการ/คณะทำงานซ้ำ");

        return;
      }

      toast.success("แก้ไขรายการคณะอนุกรรมาธิการ/คณะทำงานสำเร็จ");
    } else {
      const { status } = await service.createSubCommitteeAsync(props.subCommittee);

      if (status !== HttpStatusCode.CREATED) {
        toast.warn("รหัสคณะอนุกรรมาธิการ/คณะทำงานซ้ำ");

        return;
      }

      toast.success("เพิ่มรายการคณะอนุกรรมาธิการ/คณะทำงานสำเร็จ");
    }

    props.onSearch();

    onHideModal();
  };

  const onHideModal = () => {
    props.onHide();
  };

  return (
    <Modal
      show={props.show}
      icon={props.type === ModalType.ADD ? <MdAddCircleOutline /> : <MdOutlineModeEdit />}
      title={props.type === ModalType.ADD ? `เพิ่มคณะอนุกรรมาธิการ/คณะทำงาน` : "แก้ไขคณะอนุกรรมาธิการ/คณะทำงาน"}>
      <Form onSubmit={handleOnSubmitAsync}>
        <div className="mt-3">
          <Selector
            name="bureauId"
            label="สำนัก"
            placeholder="สำนัก"
            value={props.subCommittee.bureauId}
            rule={{ required: true }}
            onChange={(value) => props.setSubCommittee({ ...props.subCommittee, bureauId: value.toString() })}
            items={bureauList} />
          <Selector
            name="committeeId"
            label="คณะกรรมาธิการ/อื่นๆ"
            placeholder="คณะกรรมาธิการ/อื่นๆ"
            value={props.subCommittee.committeeId}
            rule={{ required: true }}
            onChange={(value) => props.setSubCommittee({ ...props.subCommittee, committeeId: value.toString() })}
            items={committeeList} />
          <Input
            name="code"
            label="รหัส"
            value={props.subCommittee.code}
            disabled />
          <Input
            name="name"
            label="คณะอนุกรรมาธิการ/คณะทำงาน"
            value={props.subCommittee.name}
            rule={{ required: true }}
            onChange={(value) => props.setSubCommittee({ ...props.subCommittee, name: value })} />
          <Input
            label="ชื่อย่อ"
            value={props.subCommittee.shortName}
            onChange={(value) => props.setSubCommittee({ ...props.subCommittee, shortName: value })} />
          <div className="button d-flex flex-row gap-3 justify-content-center mt-4">
            <Button
              variant="outline-primary"
              className="w-50"
              onClick={onHideModal}>
              ยกเลิก
            </Button>
            <Button
              variant="primary"
              className="w-50"
              type="submit">
              บันทึก
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
}