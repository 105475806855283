import { Card, Input, InputDate, Layout, Radio, Selector, Status, StatusType, Table } from "components";
import { statusMeetingItems } from "constant";
import { CtInquiryMeeting, CtInquiryMeetingCriteria, ItemModel, Pagination } from "models";
import { Dispatch, FormEvent, SetStateAction, createContext, useContext, useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaRegFileAlt, FaSearch, FaTrashAlt } from "react-icons/fa";
import { useLoaderData, useNavigate, useSubmit } from "react-router-dom";
import { formatDateTh, fullDateTime, getYearList, yearToTHFormat } from "utils";

type Loader = { bureaus: ItemModel[]; committees: ItemModel[]; subCommittees: ItemModel[]; data: Pagination<CtInquiryMeeting>; };

type CtInquiryMeetingRecordContext = {
  data: Pagination<CtInquiryMeeting>;
  criteria: CtInquiryMeetingCriteria;
  setCriteria: Dispatch<SetStateAction<CtInquiryMeetingCriteria>>;
  onSearch: (size: number, page: number) => void;
  onClear: () => void;
};

const Context = createContext({} as CtInquiryMeetingRecordContext);

const enum ReportType {
  ALL = 'all',
  IS_NOT_CHECK = "isNotCheck",
  CHECK = 'check',
}

const REPORT_TYPE = [
  { label: "ทั้งหมด", value: ReportType.ALL },
  { label: "รายงานที่ยังไม่ตรวจสอบและจัดทำรูปเล่ม", value: ReportType.IS_NOT_CHECK },
  { label: "รายงานที่ตรวจสอบและจัดทำรูปเล่มแล้ว", value: ReportType.CHECK },
];

export default function C0302() {
  const { data } = useLoaderData() as Loader;
  const [criteria, setCriteria] = useState<CtInquiryMeetingCriteria>({ isCollect: null } as CtInquiryMeetingCriteria);
  const submit = useSubmit();

  const onSearch = (size: number, page: number) => {
    submit({
      size: size.toString(),
      page: page.toString(),
      criteria: JSON.stringify(criteria),
    });
  };

  const onClear = () => {
    setCriteria({ isCollect: null } as CtInquiryMeetingCriteria);

    submit({
      page: 1,
      size: 10,
    });
  };

  const contextValue = useMemo(() => {
    return {
      data,
      criteria,
      setCriteria,
      onSearch,
      onClear,
    }
  }, [data, criteria, setCriteria, onSearch, onClear]);

  return (
    <Context.Provider value={contextValue}>
      <Layout title="ค้นหารายงานการประชุม">
        <Criteria />
        <DataTable />
      </Layout>
    </Context.Provider>
  );
};

function Criteria() {
  const { bureaus, committees, subCommittees } = useLoaderData() as Loader;
  const [subCommitteeItems, setSubCommitteeItems] = useState<ItemModel[]>([...subCommittees]);
  const { data, criteria, setCriteria, onSearch, onClear } = useContext(Context);

  const onChange = (prop: keyof CtInquiryMeetingCriteria, value?: string | number | Date) => {
    setCriteria({ ...criteria, [prop]: value });
  };

  useEffect(() => {
    if (criteria.committeeId) {
      const selectedId = committees.find(f => f.value === criteria.committeeId)?.id;

      const filterItems = subCommittees.filter(f => f.depenId === selectedId);

      setSubCommitteeItems(filterItems);

      return;
    }

    setCriteria({ ...criteria, subCommitteeId: "" });
    setSubCommitteeItems(subCommittees);
  }, [criteria.committeeId]);

  const getReportType = (value: boolean | null) => {
    switch (value) {
      case null:
        return ReportType.ALL
      case false:
        return ReportType.IS_NOT_CHECK
      case true:
        return ReportType.CHECK
    }
  };

  const onChangeRadio = (value: string | number) => {
    switch (value) {
      case ReportType.ALL:
        setCriteria({ ...criteria, isCollect: null });
        break;
      case ReportType.IS_NOT_CHECK:
        setCriteria({ ...criteria, isCollect: false });
        break;
      default:
        setCriteria({ ...criteria, isCollect: true });
    }
  };

  const onSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSearch(data.size, data.page);
  };

  return (
    <Card className="mb-4">
      <Form onSubmit={onSubmitSearch}>
        <Row>
          <Col>
            <Radio
              value={getReportType(criteria.isCollect)}
              name="reportType"
              label="ประเภทรายงาน" items={REPORT_TYPE} inline
              onChange={(value) => onChangeRadio(value)} />
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Selector
              label="สำนัก"
              placeholder="สำนัก"
              name="bureauId"
              items={bureaus}
              value={criteria.bureauId}
              onChange={(value) => onChange("bureauId", value)} />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Selector
              label="คณะกรรมาธิการ/อื่นๆ"
              placeholder="คณะกรรมาธิการ/อื่นๆ"
              name="committeeId"
              items={committees}
              value={criteria.committeeId}
              onChange={(value) => onChange("committeeId", value)} />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Selector
              label="คณะอนุกรรมาธิการ/คณะทำงาน"
              placeholder="คณะอนุกรรมาธิการ/คณะทำงาน"
              name="subCommitteeId"
              items={subCommitteeItems}
              value={criteria.subCommitteeId}
              onChange={(value) => onChange("subCommitteeId", value)} />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Input
              label="ครั้งที่ประชุม"
              name="time"
              type="number"
              value={criteria.time}
              onChange={(value) => onChange("time", value)} />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Selector
              label="ปี"
              placeholder="ปี"
              name="year"
              items={getYearList()}
              value={criteria.year}
              onChange={(value) => onChange("year", value)} />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <InputDate
              label="วันที่ประชุม"
              name="date"
              value={criteria.date}
              onChange={(value) => onChange("date", value)} />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Selector
              label="สถานะ"
              placeholder="สถานะ"
              name="status"
              items={statusMeetingItems}
              value={criteria.status}
              onChange={(value) => onChange("status", value)} />
          </Col>
        </Row>
        <Row>
          <Col className="d-flex gap-2 justify-content-start mt-2">
            <Button
              variant="primary"
              type="submit">
              <FaSearch className="me-2" />ค้นหา
            </Button>
            <Button
              variant="outline-primary"
              onClick={onClear}>
              <FaTrashAlt className="me-2" />ล้างค่า
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

function DataTable() {
  const navigate = useNavigate();
  const { data, onSearch } = useContext(Context);

  return (
    <div className="table-relative-fix">
      <div className="table-scroll">
        <Table
          total={data.totalRows}
          page={data.page}
          size={data.size}
          onChange={onSearch}>
          <Table.Header>
            <Table.Row>
              <Table.Column minWidth={100}>วันที่ประชุม</Table.Column>
              <Table.Column minWidth={200}>สำนัก</Table.Column>
              <Table.Column minWidth={250}>คณะกรรมาธิการ/อื่นๆ</Table.Column>
              <Table.Column minWidth={300}>คณะอนุกรรมาธิการ/คณะทำงาน</Table.Column>
              <Table.Column minWidth={100}>ครั้งที่ประชุม</Table.Column>
              <Table.Column minWidth={100}>ปี</Table.Column>
              <Table.Column minWidth={250}>ผู้ตรวจสอบและจัดทำรูปเล่ม</Table.Column>
              <Table.Column minWidth={250}>ผู้ตรวจสอบรูปเล่มรายงาน</Table.Column>
              <Table.Column minWidth={100}>สถานะ</Table.Column>
              <Table.Column minWidth={250}>ผู้แก้ไขข้อมูล</Table.Column>
              <Table.Column minWidth={200}>วันที่แก้ไขข้อมูล</Table.Column>
              <Table.Column minWidth={200} className="fix-col" />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.rows?.map((value) => (
              <Table.Row key={value.id}>
                <Table.Cell center>{formatDateTh(value.meetingDate)}</Table.Cell>
                <Table.Cell center>{value.bureauName}</Table.Cell>
                <Table.Cell>{value.committeeName}</Table.Cell>
                <Table.Cell>{value.subCommitteeName}</Table.Cell>
                <Table.Cell center>{value.time}</Table.Cell>
                <Table.Cell center>{yearToTHFormat(value.year)}</Table.Cell>
                <Table.Cell>{value.reviewerOverviewFullName}</Table.Cell>
                <Table.Cell>{value.certifyingReportFullName}</Table.Cell>
                <Table.Cell><Status type={StatusType.MEETING_STATUS} value={value.status} /></Table.Cell>
                <Table.Cell>{value.updateByUserFullName}</Table.Cell>
                <Table.Cell center>{fullDateTime(value.updateDate)}</Table.Cell>
                <Table.Cell center className="fix-col">
                  <div className="d-flex justify-content-center">
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={() => navigate(`detail/${value.id}`)}>
                      <FaRegFileAlt className="me-2" /> ดูรายละเอียด
                    </Button>
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};