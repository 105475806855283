import { CtMeetingType } from 'models';
import http from 'utils/axios';

const getCtMeetingTypeListAsync = async (page: number, size: number, keyword?: string) => {
  const params = {
    page,
    size,
    keyword,
  };

  return await http.get('/api/ctMeetingType', { params });
};

const createCtMeetingTypeAsync = async (data: CtMeetingType) => {
  return await http.post('/api/ctMeetingType', data);
};

const updateCtMeetingTypeAsync = async (id: string, data: CtMeetingType) => {
  return await http.put(`/api/ctMeetingType/${id}`, data);
};

const deleteCtMeetingTypeAsync = async (id: string) => {
  return await http.delete(`/api/ctMeetingType/${id}`);
};

const getCtMeetingTypeByIdAsync = async (id: string) => {
  return await http.get(`/api/ctMeetingType/${id}`, { headers: { isDisabledLoading: true } });
};

const ctMeetingTypeServices = {
  getCtMeetingTypeListAsync,
  createCtMeetingTypeAsync,
  updateCtMeetingTypeAsync,
  deleteCtMeetingTypeAsync,
  getCtMeetingTypeByIdAsync,
};

export default ctMeetingTypeServices;