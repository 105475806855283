import { SelectionType, ShareValue } from "constant/selection";
import { c0104 as service, selectionService as ddlService, selectionService } from "services";

export const c0104LoaderAsync = async () => {
  const [bureauList, bureauGroupList, dataResponse, commanderPosition] = await Promise.all([
    ddlService.getSelectionAsync(SelectionType.SM_BUREAU),
    ddlService.getSelectionAsync(SelectionType.SM_BUREAU_GROUP),
    service.getListAsync(),
    selectionService.getShareValueAsync(ShareValue.COMMANDER_POSITION),
  ]);

  return {
    bureauList: bureauList.data,
    bureauGroupList: bureauGroupList.data,
    listData: dataResponse.data,
    commanderPositionList: commanderPosition.data,
  };
};