import { InQuiryMeetingVideoCriteria, InquiryMeetingVideoDetailCriteria } from 'models';
import http from 'utils/axios';

const getListAsync = async (page: number, size: number, criteria?: InQuiryMeetingVideoCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get('api/SnInquiryMeetingVideo', { params });
};

const getDetailListAsync = async (page: number, size: number, criteria?: InquiryMeetingVideoDetailCriteria, id?: string) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get(`api/sninquirymeetingvideo/meeting/${id}`, { params });
};

const getDetailAsync = async (id?: string) =>
  await http.get(`api/sninquirymeetingvideo/meeting/${id}/header`);

const s0303 = {
  getListAsync,
  getDetailListAsync,
  getDetailAsync,
};

export default s0303;