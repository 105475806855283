import { Color } from 'constant';
import { Oval } from 'react-loader-spinner'
import { Image } from "react-bootstrap";
import { useCallback, useEffect, useState } from 'react';

enum LoadingState {
  FADING_IN,
  FADE_IN_FINISH,
  FADING_OUT,
  FADE_OUT_FINISH,
}

enum Style {
  HIDE = 'd-none',
  FADE_IN = 'fade-in',
  FADE_OUT = 'fade-out',
}

enum EventListener {
  SHOW_LOADING = 'onShowLoading',
  HIDE_LOADING = 'onHideLoading',
}

export function Loading() {
  const [loadingShowStack, setLoadingShowStack] = useState<string[]>([]);
  const [loadingHideStack, setLoadingHideStack] = useState<string[]>([]);
  const [loadingState, setLoadingState] = useState<LoadingState>();
  const [style, setStyle] = useState<Style>(Style.HIDE);
  const delay = 500;

  useEffect(() => {
    addEventListener();
  }, []);

  useEffect(() => {
    if (loadingState === LoadingState.FADE_IN_FINISH && loadingHideStack.length > 0) {
      const hides = [...loadingHideStack];

      hides.forEach((h, i) => {
        loadingHideStack.splice(i, 1);

        const showIndex = loadingShowStack.findIndex(s => s === h);

        loadingShowStack.splice(showIndex, 1);
      });

      setLoadingHideStack([...loadingHideStack]);
      setLoadingShowStack([...loadingShowStack]);

      if (!loadingHideStack.length) {
        setStyle(Style.FADE_OUT);
        setLoadingState(LoadingState.FADING_OUT);
        setTimeout(() => {
          setLoadingState(undefined);
          setStyle(Style.HIDE);
        }, delay);
      }
    }
  }, [loadingState, loadingShowStack, loadingHideStack]);

  const addEventListener = useCallback(() => {
    document.addEventListener(EventListener.SHOW_LOADING, (data) => {
      const event = data as CustomEvent;

      if (loadingShowStack.length === 0) {
        setStyle(Style.FADE_IN);
        setLoadingState(LoadingState.FADING_IN);
        setTimeout(() => setLoadingState(LoadingState.FADE_IN_FINISH), delay);
      }

      setLoadingShowStack(prev => [...prev, event.detail.url]);
    });

    document.addEventListener(EventListener.HIDE_LOADING, (data) => {
      const event = data as CustomEvent;

      setLoadingHideStack(prev => [...prev, event.detail.url]);
    });
  }, [loadingShowStack]);

  return (
    <div className={`loading ${style}`}>
      <div className='spinner'>
        <Oval
          height="125"
          width="125"
          color={Color.PRIMARY}
          secondaryColor='transparent' />
      </div>
      <Image className='icon' src="/images/logo-color.png" height={100} width={100} />
      <div className='back-drop' />
    </div>
  );
}