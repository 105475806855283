import { Modal as ModalBT } from "react-bootstrap";

interface Props {
  title?: string;
  icon?: JSX.Element;
  show: boolean;
  size?: "sm" | "lg" | "xl";
  children: JSX.Element | JSX.Element[];
  fullscreen?: boolean;
}

export function Modal(props: Props) {
  return (
    <ModalBT
      size={props.size}
      show={props.show}
      fullscreen={props.fullscreen || "md-down"}
      centered>
      <ModalBT.Body>
        <div className="d-flex align-items-center gap-2">
          {props.icon ? <div className="d-flex fs-5">{props.icon}</div> : null}
          {props.title ? <h4 className="my-auto">{props.title}</h4> : null}
        </div>
        {props.children}
      </ModalBT.Body>
    </ModalBT>
  );
}