import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Button, Form, ListGroup } from 'react-bootstrap';
import { FaBackward, FaExclamationCircle, FaForward, FaPause, FaPlay, FaTimes } from 'react-icons/fa';
import { Card } from '../Card';
import { VideoControl } from 'constant';
import { showModalConfirmAsync } from 'utils';
import { Input } from 'components/Input';
import ReactPlayer from 'react-player';
import toast from 'utils/toast';
import SpeechToText from 'components/SpeechToText';

export interface VideoOption {
  playRef: () => void;
  backRef: () => void;
  aheadRef: () => void;
}

interface VideoProps {
  files: File[];
  showPlayButton: boolean;
  showPlayTimeConfig: boolean;
  showSpeakToText: boolean;
  children?: React.ReactNode;
  uploadVideo?: boolean;
  uploadSecretVideo?: boolean;
  uploadFakeVideo?: boolean;
  uploadVideoData?: (file: File) => void;
  onEnded?: () => void;
  onLoadedData?: () => void;
  showGuideCollabora?: boolean;
  getUrlLink?: boolean;
}

const Video = forwardRef<VideoOption, VideoProps>((props, ref) => {
  const videoRef = useRef<HTMLVideoElement>({} as HTMLVideoElement);
  const reactPlayer = useRef<ReactPlayer>({} as ReactPlayer);
  const [played, setPlayed] = useState<boolean>(false);
  const [videoActive, setVideoActive] = useState<number | undefined>();
  const [back, setBack] = useState<number | undefined>(5);
  const [ahead, setAhead] = useState<number | undefined>(5);
  const [files, setFiles] = useState<File[]>([]);
  const [secretFiles, setSecretFiles] = useState<File[]>([]);
  const [uploadSecret, setUploadSecret] = useState<boolean>(false);
  const [urlLink, setUrlLink] = useState<string>('');
  const [urlSelected, setUrlSelected] = useState<string>();
  const [iframeShow, setIframeShow] = useState<boolean>(false);
  const [showVideo, setShowVideo] = useState<boolean>(false);

  function setVideoRef(fileLength: number) {
    if (fileLength) {
      setFiles(props.files);

      const newObjectUrl = URL.createObjectURL(props.files[0]);

      videoRef.current.src = newObjectUrl;
      videoRef.current.load();

      setVideoActive(1);
      setShowVideo(true);

      return;
    }

    videoRef.current.src = "";
    videoRef.current.load();
  }

  useEffect(() => {
    if (files.length > 0 && files[0] !== props.files[0]) {
      setShowVideo(false);
    }
  }, [props.files]);

  useEffect(() => {
    if (!urlLink && !urlSelected && !showVideo) {
      setVideoRef(props.files.length);
    }
  }, [props.files, urlLink, urlSelected, showVideo]);

  useImperativeHandle(ref, () => ({
    playRef() {
      play();
    },
    backRef() {
      scrollBack();
    },
    aheadRef() {
      scrollAhead();
    },
  }));

  const play = useCallback(() => {
    if (videoActive === undefined) {
      return;
    }

    if (played) {
      videoRef.current.pause();
      setPlayed(false);

      return;
    }

    videoRef.current.currentTime = videoRef.current.currentTime - 2;
    videoRef.current.play();
    setPlayed(true);
  }, [videoActive, played]);

  const onPlay = useCallback(() => {
    setPlayed(true);
  }, []);

  const onPause = useCallback(() => {
    setPlayed(false);
  }, []);

  const scrollBack = useCallback(() => {
    if (back) {
      videoRef.current.currentTime = videoRef.current.currentTime - back;
    }
  }, [back]);

  const scrollAhead = useCallback(() => {
    if (ahead) {
      videoRef.current.currentTime = videoRef.current.currentTime + ahead;
    }
  }, [ahead]);

  const selectSecretVideoAndPlay = useCallback((i: number) => {
    if (videoActive !== i) {
      const secretFile = secretFiles[i];

      videoRef.current.src = URL.createObjectURL(secretFile);
      videoRef.current.play();

      setVideoActive(i);
      setPlayed(true);
    }
  }, [secretFiles, videoActive]);

  const browseVideoRef = useRef<HTMLInputElement>({} as HTMLInputElement);

  const onSelectFileHandler = useCallback((event: HTMLInputElement) => {
    const file = event.files?.length ? event.files[0] : null;

    if (uploadSecret && file) {
      setSecretFiles([file]);
      setUploadSecret(false);
      browseVideoRef.current.value = '';
      videoRef.current.src = URL.createObjectURL(file);
      videoRef.current.play();

      setVideoActive(0);
      setPlayed(true);

      return;
    }

    if (file) {
      setFiles([...files, file]);
      props.uploadVideoData!(file);
    }

    browseVideoRef.current.value = '';
  }, [files, secretFiles, uploadSecret]);

  useEffect(() => {
    if (props.showPlayButton) {
      document.onkeydown = (e) => {
        switch (e.key) {
          case VideoControl.F10:
            scrollBack();
            return false;
          case VideoControl.F9:
            play();
            return false;
          case VideoControl.F11:
            if (e.ctrlKey) {
              scrollAhead();
              return false;
            }
        }
      }
    }
  }, [props.showPlayButton, scrollBack, play, scrollAhead]);

  const onClickUploadSecretVideo = () => {
    setUploadSecret(true);

    browseVideoRef.current.click();
  };

  const onDeleteSecretFiles = async () => {
    setSecretFiles([]);

    const restoreFile = props.files[0];

    videoRef.current.src = URL.createObjectURL(restoreFile);
    videoRef.current.play();

    setVideoActive(1);
    setPlayed(true);
  };

  const verifyVideoUploadAsync = async () => {
    const fileLength = files.length;
    const modalConfirm = await showModalConfirmAsync("ท่านต้องการแนบไฟล์วิดีโอหรือไม่ ?", "วิดีโอใหม่ที่แนบจะทับวิดีโอเดิม", <FaExclamationCircle className="text-primary" />);

    if (fileLength && !modalConfirm) {
      return;
    }

    browseVideoRef.current.click();
  };

  const getVideoList = useMemo(() => {
    if (secretFiles.length) {
      return (
        <>
          <span>รายการวิดีโอ</span>
          <ListGroup className='mt-1'>
            {secretFiles.map((file, i) =>
              <ListGroup.Item key={i} onClick={() => selectSecretVideoAndPlay(i)} active={videoActive === i}>
                <div className='d-flex justify-content-between align-items-center'>
                  {file.name} <FaTimes onClick={onDeleteSecretFiles} />
                </div>
              </ListGroup.Item>)}
          </ListGroup>
        </>);
    }
  }, [secretFiles, videoActive]);

  const onChangeUrl = (value: string) => {
    if (!value) {
      setUrlSelected('');
    }

    setUrlLink(value);
  };

  const isValidHttpUrl = () => {
    try {
      new URL(urlLink);
      return true
    } catch (err) {
      return false;
    }
  }

  const onConfirmUrlSelected = () => {
    if (!isValidHttpUrl()) {
      return toast.error('URL ไม่ถูกต้อง');
    }

    setIframeShow(false);
    setUrlSelected(urlLink);
  };

  return (
    <>
      {urlSelected
        ? (!iframeShow
          ? <ReactPlayer url={urlSelected} controls width='100%' ref={reactPlayer} onError={() => setIframeShow(true)} />
          : <div className="iframe-custom">
            <iframe className="responsive-iframe" src={urlSelected} title="description" />
          </div>)
        : <video
          className='w-100'
          controls
          ref={videoRef}
          onPlay={onPlay}
          onPause={onPause}
          onEnded={props.onEnded ?? undefined}
          onLoadedData={props.onLoadedData ?? undefined}>
          <source />
        </video>}
      {props.getUrlLink
        && <div className='mt-3'>
          <Input
            label="Video URL"
            placeholder="example: www.youtube.com"
            value={urlLink}
            onChange={onChangeUrl} />
          <Button
            className='w-100 fs-7'
            variant="outline-primary"
            onClick={onConfirmUrlSelected}>ยืนยัน URL
          </Button>
        </div>
      }
      <div>
        {props.uploadVideo &&
          <Button
            variant='outline-primary'
            className='w-100 mt-2 d-flex justify-content-center align-items-center fs-7'
            onClick={verifyVideoUploadAsync}>
            เพิ่มวิดีโอ
          </Button>}
        {props.uploadSecretVideo ?
          <>
            {getVideoList}
            <Button
              variant='outline-primary'
              className='w-100 mt-2 d-flex justify-content-center align-items-center fs-7'
              onClick={onClickUploadSecretVideo}>
              เพิ่มวิดีโอ ( ประชุมลับ )
            </Button>
          </>
          : null
        }
        {props.uploadFakeVideo ?
          <>
            {getVideoList}
            <Button
              variant='outline-primary'
              className='w-100 mt-2 d-flex justify-content-center align-items-center fs-7'
              onClick={onClickUploadSecretVideo}>
              เพิ่มวิดีโอ
            </Button>
          </>
          : null
        }
        <Form.Control
          ref={browseVideoRef}
          type='file'
          accept='video/mp4, video/x-m4v, video/*, audio/mp3,audio/*'
          className='d-none'
          onChange={event => onSelectFileHandler(event.target as HTMLInputElement)} />
      </div >
      {
        props.showPlayButton && <div className="d-flex justify-content-center gap-2 mt-2">
          <Button
            variant='outline-primary'
            disabled={videoActive === undefined}
            className='w-100 fs-7'
            onClick={scrollBack}>
            <FaBackward className="me-2" /> ย้อนหลัง (F10)
          </Button>
          <Button
            id='stopPlay'
            variant='outline-primary'
            disabled={videoActive === undefined}
            className='w-100 fs-7'
            onClick={play}>
            {played ?
              <><FaPause className="mx-1" /> หยุดชั่วคราว (F9)</> :
              <><FaPlay className="mx-1" /> เล่น (F9)</>}
          </Button>
          <Button
            variant='outline-primary'
            disabled={videoActive === undefined}
            className='w-100 fs-7'
            onClick={scrollAhead}>
            เดินหน้า (Ctrl + F11) < FaForward className="mx-2" />
          </Button>
        </div>
      }
      {
        props.children
      }
      {
        props.showSpeakToText && <SpeechToText />
      }
      {
        props.showPlayTimeConfig && <Card className="mt-2 border-primary">
          <Form.Label className="text-primary">
            ตั้งค่ากำหนดเวลา
          </Form.Label>
          <div className="d-flex justify-content-center gap-3 mt-4">
            <p className="text-primary">ย้อนหลัง</p>
            <div className="w-25">
              <Form.Control
                name='back'
                type='number'
                value={back}
                onChange={event => setBack(+event.target.value)} />
            </div>
            <p className="text-primary">วินาที</p>
          </div>
          <div className="d-flex justify-content-center gap-3 mt-4">
            <p className="text-primary">เดินหน้า</p>
            <div className="w-25">
              <Form.Control
                name='front'
                type='number'
                value={ahead}
                onChange={event => setAhead(+event.target.value)} />
            </div>
            <p className="text-primary">วินาที</p>
          </div>
        </Card>
      }
      {
        props.showGuideCollabora ?
          <div className="d-flex gap-2 justify-content-end mt-2">
            <p className="user-manual fs-6 mx-2">
              <a
                href="https://shorthand.senate.go.th/dictionary/"
                target="_blank"
                className="px-1">
                ระบบฐานข้อมูลคำทับศัพท์
              </a>
            </p>
          </div> : null
      }
    </>
  );
});

export default Video;
