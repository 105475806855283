import { Layout, Card, Input, Selector, InputDate, Table, Status, StatusType } from "components";
import { FaRegFileAlt, FaSearch, FaTrashAlt } from "react-icons/fa";
import { Col, Row, Button, Form } from "react-bootstrap";
import { useLoaderData, useNavigate, useSubmit } from "react-router-dom";
import { FormEvent, createContext, useContext, useMemo, useState } from "react";
import { ItemModel, MeetingInspectCriteria, MeetingInspectListModel, Pagination } from "models";
import { getFormatDateBC, getTime, calculateRowNumber, fullDateTime } from "utils";
import { StatusMeeting, statusMeetingItems } from "constant";

type MeetingInspectContext = {
  onSearch: Function;
};

const Context = createContext({} as MeetingInspectContext);

type Loader = {
  meetingTypeDDL: ItemModel[];
  meetingPeriodDDL: ItemModel[];
  yearsDDL: ItemModel[];
  inspectList: Pagination<MeetingInspectListModel>;
};

export default function S0205() {
  const { meetingPeriodDDL, meetingTypeDDL, yearsDDL, inspectList } = useLoaderData() as Loader;
  const [criteria, setCriteria] = useState<MeetingInspectCriteria>({} as MeetingInspectCriteria);
  const submit = useSubmit();

  const statusItems = [
    StatusMeeting.DOCUMENT_MAKING,
    StatusMeeting.OVERVIEW_REVIEWING,
    StatusMeeting.OVERVIEW_REVIEWED,
    StatusMeeting.COLLECT,
    StatusMeeting.DOCUMENT_COMPLETE,
  ];

  const filterStatus = useMemo(() => statusMeetingItems.filter(s => statusItems.includes(s.value)), [statusMeetingItems]);

  const onSearch = (size: number, page: number) => {
    submit({
      page: page.toString(),
      size: size.toString(),
      criteria: JSON.stringify(criteria),
    });
  };

  const onClear = () => {
    setCriteria({} as MeetingInspectCriteria);

    submit({});
  };

  const contextValue = useMemo(() => {
    return {
      onSearch,
    }
  }, [onSearch]);

  const onSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSearch(inspectList.size, inspectList.page);
  };

  return (
    <Context.Provider value={contextValue}>
      <Layout title="ตรวจทานและรวบรวมรายงานการประชุม">
        <Card className="my-3">
          <Form onSubmit={onSubmitSearch}>
            <Row>
              <Col sm={6} md={4} lg={4} xl={3}>
                <Selector
                  label="ประเภทการประชุม"
                  placeholder="ประเภทการประชุม"
                  items={meetingTypeDDL}
                  value={criteria.meetingTypeId}
                  onChange={(value) => setCriteria({ ...criteria, meetingTypeId: value.toString() })} />
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <Input
                  label="ครั้งที่ประชุม"
                  value={criteria.time}
                  type="number"
                  onChange={(value) => setCriteria({ ...criteria, time: Number(value) })} />
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <Selector
                  label="สมัยการประชุม"
                  placeholder="สมัยการประชุม"
                  items={meetingPeriodDDL}
                  value={criteria.periodId}
                  onChange={(value) => setCriteria({ ...criteria, periodId: value.toString() })} />
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <Input
                  label="ชื่อการประชุม"
                  value={criteria.name}
                  onChange={(value) => setCriteria({ ...criteria, name: value.toString() })} />
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <Selector
                  label="ปี"
                  placeholder="ปี"
                  items={yearsDDL}
                  value={criteria.year}
                  onChange={(value) => setCriteria({ ...criteria, year: Number(value) })} />
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <InputDate
                  label="วันที่ประชุม"
                  value={criteria.meetingDate}
                  onChange={(value) => setCriteria({ ...criteria, meetingDate: value })} />
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <Selector
                  label="สถานะ"
                  placeholder="สถานะ"
                  items={filterStatus}
                  value={criteria.status}
                  onChange={(value) => setCriteria({ ...criteria, status: value.toString() })} />
              </Col>
            </Row>
            <div className="d-flex gap-2 justify-content-start mt-2">
              <Button
                type="submit">
                <FaSearch className="me-2" />ค้นหา
              </Button>
              <Button
                variant="outline-primary"
                onClick={onClear}>
                <FaTrashAlt className="me-2" />ล้างค่า
              </Button>
            </div>
          </Form>
        </Card>
        <DataTable />
      </Layout>
    </Context.Provider>
  );
}

function DataTable() {
  const navigate = useNavigate();
  const { inspectList } = useLoaderData() as Loader;
  const { onSearch } = useContext(Context);

  return (
    <div className="table-relative-fix">
      <div className="table-scroll">
        <Table
          total={inspectList.totalRows}
          page={inspectList.page}
          size={inspectList.size}
          onChange={(size, page) => onSearch(size, page)}>
          <Table.Header>
            <Table.Row>
              <Table.Column minWidth={100}>ลำดับ</Table.Column>
              <Table.Column minWidth={150}>ประเภทการประชุม</Table.Column>
              <Table.Column minWidth={100}>ครั้งที่</Table.Column>
              <Table.Column minWidth={300}>สมัยการประชุม</Table.Column>
              <Table.Column minWidth={300}>ชื่อการประชุม</Table.Column>
              <Table.Column minWidth={250}>วันที่ประชุม</Table.Column>
              <Table.Column minWidth={150}>สถานะ</Table.Column>
              <Table.Column minWidth={200}>กลุ่มที่รวบรายงาน</Table.Column>
              <Table.Column minWidth={200}>ผู้แก้ไขข้อมูล</Table.Column>
              <Table.Column minWidth={200}>วันที่แก้ไขข้อมูล</Table.Column>
              <Table.Column minWidth={150} className="fix-col" />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {inspectList.rows?.map((value, index) => (
              <Table.Row key={value.id}>
                <Table.Cell center>{calculateRowNumber(index, inspectList.page, inspectList.size).toString()}</Table.Cell>
                <Table.Cell center>{value.meetingType}</Table.Cell>
                <Table.Cell center>{value.time}</Table.Cell>
                <Table.Cell center>{value.meetingPeriod}</Table.Cell>
                <Table.Cell>{value.name}</Table.Cell>
                <Table.Cell center>{`${getFormatDateBC(value.meetingDate)} ${getTime(value.startTime)} - ${getTime(value.endTime)} น.`}</Table.Cell>
                <Table.Cell center><Status type={StatusType.MEETING_STATUS} value={value.status} /></Table.Cell>
                <Table.Cell center>{value.bureauGroupNames}</Table.Cell>
                <Table.Cell>{value.updateByUserFullName}</Table.Cell>
                <Table.Cell center>{fullDateTime(value.updateDate)}</Table.Cell>
                <Table.Cell center className="fix-col">
                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => navigate(`detail/${value.id}/document`)}>
                    <FaRegFileAlt className="me-2" />ดูรายละเอียด
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
}