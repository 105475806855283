import { Collabora, CollaboraRef, Input, Layout, Modal, Selector, Table, ToolTipComment, VideoOption } from 'components';
import { Button, DropdownButton, Dropdown, Form, Row, Col, Collapse } from 'react-bootstrap';
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaPlus, FaRegFilePdf, FaRegFileWord, FaTrashAlt, FaUndo } from 'react-icons/fa';
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { HiOutlineUserGroup } from "react-icons/hi";
import { TbFileDownload, TbFileCheck } from "react-icons/tb";
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ItemModel, SnChapterDocument, SnMakeDocumentDetail, DutyofficerDetailByChapter, SnMeetingChapterDebaters, SnMakeDocumentDetailHeader } from "models";
import { s0204, snVideoService } from "services";
import { HttpStatusCode, PersonType, StatusChapter, StatusMeeting, VideoControl } from "constant";
import { exportFilePDFAsync, exportFileWordAsync, showModalConfirmAsync, showModalRemoveAsync, submitForm } from "utils";
import toast from "utils/toast";
import { HiOutlineDocumentCheck } from "react-icons/hi2";
import Video from "components/Video/Video";

type Loader = {
  attendedTypeItems: ItemModel[];
  snMemberItems: (ItemModel & { id: string })[];
  agendaItems: ItemModel[];
  dutyOfficerData: DutyofficerDetailByChapter;
  headerData: SnMakeDocumentDetailHeader;
};

interface ModalProps {
  showModal: boolean;
  onHide: () => void;
  snMakeDocumentData: SnMeetingChapterDebaters[];
  getSnMakeDocumentDetailAsync: Function;
  documentStatus: string;
}

export default function S0204Document() {
  const { headerData, dutyOfficerData } = useLoaderData() as Loader;
  const [snMakeDocument, setSnMakeDocument] = useState<SnMakeDocumentDetail>({} as SnMakeDocumentDetail);
  const [showModal, setShowModal] = useState(false);
  const [docVersion, setDocVersion] = useState<SnChapterDocument>({} as SnChapterDocument);
  const [canWrite, setCanWrite] = useState<boolean>();
  const navigate = useNavigate();
  const { id, documentId } = useParams();
  const collaboraRef = useRef<CollaboraRef>(null);
  const [videoData, setVideoData] = useState<File>();
  const [fileIds, setFileIds] = useState<string[]>([]);
  const [fileIndex, setFileIndex] = useState<number>(0);
  const [open, setOpen] = useState(true);
  const [screenWidth, setScreenWidth] = useState(1200);
  const programName = useState<string>("จัดทำ/แก้ไข รายงานการประชุม");

  useEffect(() => {
    getSnMakeDocumentDetailAsync();
  }, [documentId]);

  useEffect(() => {
    if (fileIds.length) {
      getVideoDataAsync(fileIds[0]);
      setFileIndex(0);
    }
  }, [fileIds]);

  useEffect(() => {
    checkWidthScreen();
  }, []);

  const checkWidthScreen = useCallback(() => {
    window.onresize = function (event) {
      const widthScreen = (event.target as Window).innerWidth;

      setScreenWidth(widthScreen);
    };
  }, []);

  const canWriteDocument = useMemo(() => {
    const check = snMakeDocument.status === StatusChapter.RECORDED ||
      snMakeDocument.status === StatusChapter.DOCUMENT_MAKING ||
      snMakeDocument.status === StatusChapter.ASSIGN_DUTY_OFFICER ||
      snMakeDocument.status === StatusChapter.SEND_EDIT_DOCUMENT ||
      snMakeDocument.status === StatusChapter.RECORD ||
      snMakeDocument.status === StatusChapter.REJECT;

    return check;
  }, [snMakeDocument]);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const getSnMakeDocumentDetailAsync = async () => {
    if (documentId) {
      const { data, status } = await s0204.getSnMakeDocumentDetailAsync(documentId);

      if (status === HttpStatusCode.OK) {
        setSnMakeDocument(data);
        setDocVersion(data.snChapterDocuments ? data.snChapterDocuments[0] : {} as SnChapterDocument);
        getFileIdAsync(data.id);

        setCanWrite(data.status === StatusChapter.RECORDED ||
          data.status === StatusChapter.DOCUMENT_MAKING ||
          data.status === StatusChapter.ASSIGN_DUTY_OFFICER ||
          data.status === StatusChapter.SEND_EDIT_DOCUMENT ||
          data.status === StatusChapter.RECORD ||
          data.status === StatusChapter.REJECT);
      }
    }
  };

  const updateStatusAsync = async (statusUpdate: StatusChapter) => {
    if (documentId) {
      const { status } = await s0204.updateSmMakeDocumentDetailAsync(documentId, statusUpdate);

      if (status === HttpStatusCode.ACCEPTED && statusUpdate === StatusChapter.DOCUMENT_MAKING) {
        toast.success('บันทึกข้อมูลสำเร็จ');
      }

      if (status === HttpStatusCode.ACCEPTED && statusUpdate === StatusChapter.DOCUMENT_COMPLETE) {
        toast.success('ส่งรายงานสำเร็จ');
      }

      getSnMakeDocumentDetailAsync();
    }
  };

  const handleSendReportAsync = async () => {

    if (!await showModalConfirmAsync('ต้องการยืนยันการส่งรายงานหรือไม่', 'ส่งรายงาน', <HiOutlineDocumentCheck className="my-1 text-success" style={{ fontSize: 80 }} />)) {
      return;
    }

    collaboraRef.current?.clickSave();

    await updateStatusAsync(StatusChapter.DOCUMENT_COMPLETE);
  };

  const onSelectVersion = (id: string | null) => {
    if (id) {
      const documentSelected = snMakeDocument.snChapterDocuments.find(f => f.id == id);

      setDocVersion(documentSelected ? documentSelected : {} as SnChapterDocument);

      if (documentSelected !== snMakeDocument.snChapterDocuments[0]) {
        setCanWrite(false);

        return;
      }

      if (canWriteDocument) {
        setCanWrite(true);

        return;
      }
    }
  };

  const button = () => {
    if (canWriteDocument) {

      return (
        <>
          <Button
            variant="outline-primary"
            type="button"
            onClick={() => updateStatusAsync(StatusChapter.DOCUMENT_MAKING)}>
            <TbFileDownload className="me-2" />บันทึกเวอร์ชั่น
          </Button>
          <Button
            variant="primary"
            type="button"
            onClick={handleSendReportAsync}>
            <TbFileCheck className="me-2" />ส่งรายงาน
          </Button>
        </>
      );
    }
  };
  const videoRef = useRef<VideoOption>({} as VideoOption);

  function loadCollabora() {
    const iframe = document.getElementById(docVersion.id) as HTMLIFrameElement;

    iframe.contentWindow?.addEventListener('keydown', (e) => clickBtn(e.key));
  }

  function clickBtn(key: string) {
    switch (key) {
      case VideoControl.F10:
        videoRef.current.backRef();
        break;
      case VideoControl.F9:
        videoRef.current.playRef();
        break;
      case VideoControl.F11:
        videoRef.current.aheadRef();
        break;
    }
  }

  const getTitleName = () => {
    return `ตอนที่ ${dutyOfficerData.chapter} ผู้จดรายงาน : ${dutyOfficerData.fullName}`;
  };

  const getVideoDataAsync = async (vidId: string) => {
    const { data, status } = await snVideoService.getVideoAsync(vidId!);

    if (status === HttpStatusCode.OK) {
      setVideoData(data);
    }
  };

  const getFileIdAsync = async (chapterId: string) => {
    const { data, status } = await snVideoService.getRecordFileIdAsync(chapterId, id!);

    if (status === HttpStatusCode.OK) {
      setFileIds(data);
    }
  };

  const onEndedAsync = async () => {
    const currentIndex = fileIndex + 1;

    if (fileIds.length > currentIndex) {
      getVideoDataAsync(fileIds[currentIndex]);

      setFileIndex(currentIndex);
    } else {
      setFileIndex(0);
    }
  };

  const onLoadedData = () => {
    if (fileIndex) {
      videoRef.current.playRef();
    }
  };

  const onUploadVideoAsync = async (fileUpload: File) => {
    await s0204.uploadVideoAsync(id!, documentId!, fileUpload);

    toast.success("อัพโหลดวิดิโอสำเร็จ");

    getFileIdAsync(dutyOfficerData.chapterId);
  };

  const removeDocAsync = async () => {
    if (!await showModalRemoveAsync()) {
      return;
    }

    const { status } = await s0204.removeDocAsync(docVersion.id);

    if (status === HttpStatusCode.NO_CONTENT) {
      await getSnMakeDocumentDetailAsync();

      toast.success('ลบเอกสารสำเร็จ');
    }
  };

  const isDisabledRemoveDoc = useMemo(() => {
    const index = snMakeDocument.snChapterDocuments?.findIndex(d => d.id === docVersion.id);

    return index === 0;
  }, [docVersion]);

  return (
    <Layout title={programName[0]} className="s0204-detail" subTitle={getTitleName()}>
      <Form>
        <div className="d-flex my-3 gap-2 flex-wrap">
          <Button
            className='me-auto'
            variant="outline-primary"
            type="button"
            onClick={() => navigate(`/s0204/detail/${headerData.id}`)}>
            <FaAngleLeft className="me-2" />ย้อนกลับ
          </Button>
          <Button
            variant="outline-primary" onClick={() => exportFilePDFAsync(docVersion.id, programName[0])}>
            <FaRegFilePdf className="me-2" />Export PDF
          </Button>
          <Button
            variant="outline-primary"
            onClick={() => exportFileWordAsync(docVersion.id, programName[0])}>
            <FaRegFileWord className="me-2" />Export Word
          </Button>
          <DropdownButton
            title={<span><FaUndo className="me-2" />เวอร์ชั่นก่อนหน้า ({docVersion.versionDocument})</span>}
            drop="down-centered"
            variant="outline-primary"
            onSelect={(value) => onSelectVersion(value)}>
            {snMakeDocument.snChapterDocuments?.map((data) => (
              <Dropdown.Item eventKey={data.id} key={data.id}>{data.versionDocument}</Dropdown.Item>
            ))}
          </DropdownButton>
          <Button
            variant="danger"
            onClick={removeDocAsync}
            disabled={isDisabledRemoveDoc}>
            <FaTrashAlt className="me-2" />ลบเอกสาร
          </Button>
          <Button
            variant="outline-primary"
            type="button"
            onClick={() => handleShowModal()}>
            <HiOutlineUserGroup className="me-2" />รายชื่อผู้อภิปราย
          </Button>
          {button()}
        </div>
        <Row>
          <Collapse in={open} dimension="width" timeout={50}>
            <Col xl={3}>
              {snMakeDocument.status === StatusChapter.SEND_EDIT_DOCUMENT && <ToolTipComment subject={docVersion.comment ?? "ไม่มีความคิดเห็น"} />}
              <Video
                onEnded={onEndedAsync}
                onLoadedData={onLoadedData}
                ref={videoRef}
                files={videoData?.type ? [videoData] : []}
                showPlayButton={true}
                showPlayTimeConfig={true}
                showSpeakToText={true}
                uploadVideo
                uploadSecretVideo
                uploadVideoData={(fileData) => onUploadVideoAsync(fileData)}
                showGuideCollabora
                getUrlLink />
            </Col>
          </Collapse>
          {(open && screenWidth > 1199) ? <div className='collapse-video' onClick={() => setOpen(!open)}>
            <div>
              <FaAngleDoubleLeft />
            </div>
          </div> : <></>}
          <Col>
            {(!open && screenWidth > 1199) ? <div className='show-video' onClick={() => setOpen(!open)}>
              <div>
                <FaAngleDoubleRight />
              </div>
            </div> : <></>}
            <Collabora
              docId={docVersion.id}
              docName="s0204"
              height="1000px"
              ref={collaboraRef}
              onCollaboraLoaded={() => loadCollabora()}
              canWrite={canWrite} />
          </Col>
        </Row>
        <ModalDebater
          showModal={showModal}
          onHide={() => setShowModal(false)}
          snMakeDocumentData={snMakeDocument.snMeetingChapterDebaters}
          getSnMakeDocumentDetailAsync={getSnMakeDocumentDetailAsync}
          documentStatus={snMakeDocument.status} />
      </Form>
    </Layout >
  );
}

function ModalDebater(props: ModalProps) {
  const { attendedTypeItems, snMemberItems, agendaItems, headerData } = useLoaderData() as Loader;
  const [debaterList, setDebaterList] = useState<SnMeetingChapterDebaters[]>([]);
  const { documentId } = useParams();

  useEffect(() => {
    if (props.snMakeDocumentData && props.showModal) {
      setDebaterList(props.snMakeDocumentData);
    }
  }, [props.snMakeDocumentData, props.showModal]);

  const canAddDebater = useMemo(() => {
    return (
      props.documentStatus === StatusChapter.DOCUMENT_MAKING ||
      props.documentStatus === StatusChapter.RECORDED ||
      props.documentStatus === StatusChapter.SEND_EDIT_DOCUMENT ||
      props.documentStatus === StatusChapter.ASSIGN_DUTY_OFFICER ||
      props.documentStatus === StatusChapter.RECORD);
  }, [props.documentStatus]);

  const disabled = useMemo(() => {
    return (
      props.documentStatus !== StatusChapter.DOCUMENT_MAKING &&
      props.documentStatus !== StatusChapter.RECORDED &&
      props.documentStatus !== StatusChapter.SEND_EDIT_DOCUMENT &&
      props.documentStatus !== StatusChapter.ASSIGN_DUTY_OFFICER &&
      props.documentStatus !== StatusChapter.RECORD);
  }, [props.documentStatus]);

  const addDebater = () => {
    const debatersList = [...debaterList];

    debatersList.push({} as SnMeetingChapterDebaters);
    setDebaterList(debatersList);
  };

  const removeDebater = (index: number) => {
    const debatersList = [...debaterList];

    debatersList.splice(index, 1);
    setDebaterList(debatersList);
  };

  enum Type {
    MEMBER_TYPE = "memberType",
    MEMBER_NAME = "memberName",
    SN_MEMBER_ID = "snMemberId",
    SN_MEETING_AGENDA_ID = "snMeetingAgendaId",
  }

  const onChangeData = (value: string | number, prop: string, index: number) => {
    const debatersList = [...debaterList];
    let debater = debaterList[index];

    if (prop === Type.MEMBER_TYPE) {
      debater.memberType = value.toString();
      debater.snMemberId = undefined;
      debater.memberName = "";
    }

    if (prop === Type.SN_MEMBER_ID) {
      const findUser = snMemberItems.find(f => f.value === value);

      if (findUser) {
        const personType = attendedTypeItems.filter(f => f.value === findUser.id);

        debater.memberType = personType[0].value.toString();
        debater.snMemberId = findUser.value.toString();
        debater.memberName = findUser.label;
      }
    }

    if (prop === Type.MEMBER_NAME) {
      debater.snMemberId = undefined;
      debater.memberName = value.toString();
    }

    if (prop === Type.SN_MEETING_AGENDA_ID) {
      debater.snMeetingAgendaId = value.toString();
    }

    debatersList.splice(index, 1, debater);

    setDebaterList(debatersList);
  };

  const onHide = () => {
    setDebaterList([]);

    props.onHide();
  };

  const onSaveAsync = async () => {
    submitForm();

    if (!debaterList.every(d => d.memberType) ||
      !debaterList.every(d => d.snMeetingAgendaId)) {
      return;
    }

    if (!debaterList.filter(d => d.memberType === PersonType.Other && d.memberName)) {
      return;
    }


    if (documentId) {
      const { status } = await s0204.createDebatersChapterAsync(documentId, debaterList);

      if (status === HttpStatusCode.ACCEPTED) {
        toast.success('บันทึกข้อมูลสำเร็จ');

        props.getSnMakeDocumentDetailAsync();
        props.onHide();
      }
    }
  };

  const isShowButton = useMemo(() => {
    return headerData.status === StatusMeeting.DRAFT ||
      headerData.status === StatusMeeting.CONFIRM ||
      headerData.status === StatusMeeting.CANCEL ||
      headerData.status === StatusMeeting.RECORD ||
      headerData.status === StatusMeeting.RECORDED ||
      headerData.status === StatusMeeting.DOCUMENT_MAKING;
  }, [headerData]);

  const attendedTypeFilter = useCallback((type: string) => {
    return attendedTypeItems.find(f => f.value === type)?.value;
  }, [attendedTypeItems]);

  return (
    <Modal
      show={props.showModal}
      size="xl">
      <Form>
        <Form.Label>รายชื่อผู้อภิปราย</Form.Label>
        {canAddDebater ? <div className="d-flex justify-content-end">
          <Button
            variant="outline-primary"
            type="button"
            onClick={addDebater}>
            <FaPlus className="me-2" />เพิ่ม
          </Button>
        </div> : <></>}
        <Row className="mt-2">
          <Col>
            <Table total={debaterList?.length}>
              <Table.Header>
                <Table.Row>
                  <Table.Column minWidth={100}>ลำดับ</Table.Column>
                  <Table.Column minWidth={150}>ประเภทผู้อภิปราย</Table.Column>
                  <Table.Column minWidth={250}>รายชื่อ</Table.Column>
                  <Table.Column minWidth={300}>หัวข้อวาระ</Table.Column>
                  {canAddDebater
                    ? <Table.Column minWidth={100} />
                    : <></>}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {debaterList?.map((data, index) => (
                  <Table.Row key={data.id}>
                    <Table.Cell center>
                      {(index + 1).toString()}
                    </Table.Cell>
                    <Table.Cell>
                      <Selector
                        disabled={disabled}
                        rule={{ required: true }}
                        items={attendedTypeItems}
                        value={data.memberType}
                        onChange={(value) => onChangeData(value, Type.MEMBER_TYPE, index)} />
                    </Table.Cell>
                    <Table.Cell>
                      {attendedTypeFilter(data.memberType) === PersonType.Other ?
                        <Input
                          value={data.memberName}
                          onChange={(value) => onChangeData(value, 'memberName', index)} />
                        :
                        <Selector
                          disabled={disabled}
                          rule={{ required: true }}
                          items={snMemberItems}
                          value={data.snMemberId}
                          onChange={(value) => onChangeData(value, Type.SN_MEMBER_ID, index)} />
                      }

                    </Table.Cell>
                    <Table.Cell>
                      <Selector
                        disabled={disabled}
                        rule={{ required: true }}
                        items={agendaItems}
                        value={data.snMeetingAgendaId}
                        onChange={(value) => onChangeData(value, Type.SN_MEETING_AGENDA_ID, index)} />
                    </Table.Cell>
                    {!disabled
                      ? <Table.Cell center>
                        <FaTrashAlt className="my-2 fs-5" onClick={() => removeDebater(index)} />
                      </Table.Cell>
                      : <></>}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Col>
        </Row>
        {isShowButton ?
          <div className="d-flex justify-content-end gap-2 mt-4">
            <Button
              variant="outline-primary"
              type="button"
              onClick={onHide}>
              ยกเลิก
            </Button>
            <Button
              variant="primary"
              type="button"
              onClick={onSaveAsync}>
              ยืนยัน
            </Button>
          </div> : null}
      </Form>
    </Modal>
  );
}