import { Layout, Card, Input, Table } from "components";
import { HttpStatusCode } from "constant";
import { Pagination, SmRoleModel, ProgramModel } from "models";
import { useState, useEffect } from "react";
import { Button, Form, Col, Row } from "react-bootstrap";
import { FaAngleLeft, FaPlus, FaSave, FaTrashAlt } from "react-icons/fa";
import { useLoaderData, useNavigate, useParams, useSubmit } from "react-router-dom";
import { submitForm } from "utils";
import toast from "utils/toast";
import ModalSelectProgram from "./ModalSelectProgram";
import smProgramService from "services/programSevice";
import { m0102 } from "services";
import manageProgram from "./manageProgram";

type Loader = { smRoleDetailData: SmRoleModel, data: Pagination<ProgramModel> };

export default function M0102Detail() {
  const { smRoleDetailData, data } = useLoaderData() as Loader;
  const [showModal, setShowModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [onInit, setOnInit] = useState(true);
  const [smRoleDetail, setSmRoleDetail] = useState<SmRoleModel>({} as SmRoleModel);
  const [programSelected, setProgramSelected] = useState<ProgramModel[]>([]);
  const [programList, setProgramList] = useState<ProgramModel[]>([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const submit = useSubmit();

  useEffect(() => {
    getProgramListAsync();
    setOnInit(false);
  }, []);

  useEffect(() => {
    if (smRoleDetailData) {
      setSmRoleDetail(smRoleDetailData);
      setProgramSelected(data.rows);
      setDisabled(true);
    }
  }, [smRoleDetailData]);

  const getSmRoleDetailAsync = async () => {
    if (id) {
      const { data, status } = await m0102.getSmRoleDetailAsync(id);

      if (status === HttpStatusCode.OK) {
        setSmRoleDetail(data);
      }
    }
  };

  const getProgramListAsync = async () => {
    const { data, status } = await smProgramService.getSmProgramListAsync(1, 9999);

    if (status === HttpStatusCode.OK) {
      const programData: ProgramModel[] = data.rows;

      setProgramList(programData);
    }
  };

  const handleOnChange = (value: string | number, prop: string) => {
    setSmRoleDetail({ ...smRoleDetail, [prop]: value });
  };

  const handleOnSubmit = async () => {
    submitForm(smRoleDetail);

    if (!smRoleDetail.code || !smRoleDetail.name) {
      toast.warn("กรุณากรอกข้อมูลให้ครบถ้วน");

      return;
    }


    if (programSelected?.length === 0) {
      toast.warn("กรุณาเลือกอย่างน้อย 1 โปรแกรม");

      return;
    }

    if (id) {
      onUpdateAsync(id);

      return;
    }

    onSaveAsync();
  };

  const onSaveAsync = async () => {
    const body = smRoleDetail;
    body.programIds = programSelected.map(p => p.id);

    const { data, status } = await m0102.createSmRoleAsync(body);

    if (status === HttpStatusCode.CREATED) {
      toast.success('บันทึกข้อมูลสำเร็จ');

      navigate(`/m0102/detail/${data}`);

      getSmRoleDetailAsync();
    }
  };

  const onUpdateAsync = async (id: string) => {
    const body = smRoleDetail;
    body.programIds = programSelected.map(p => p.id);

    const { status } = await m0102.updateSmRoleAsync(id, body);

    if (status === HttpStatusCode.ACCEPTED) {
      toast.success('แก้ไขข้อมูลสำเร็จ');

      submit({});
      getSmRoleDetailAsync();
    }
  };

  const onRemoveProgramSelected = (index: number) => {
    const program = programSelected[index];

    const programs = manageProgram.removeProgram(programList, programSelected, program.id);

    setProgramSelected([...programs]);
  };

  return (
    <Layout title="กำหนดสิทธิ์การใช้งาน (เพิ่ม/แก้ไข)">
      <div className="d-flex justify-content-between my-3">
        <Button variant="outline-primary" type="button" onClick={() => navigate('/m0102')}>
          <FaAngleLeft className="me-2" />ย้อนกลับ
        </Button>
        <Button variant="primary" type="button" onClick={handleOnSubmit}>
          <FaSave className="me-2" />บันทึก
        </Button>
      </div>
      {!onInit ? <>
        <Card>
          <Form>
            <Form.Label>ข้อมูลสิทธิ์</Form.Label>
            <Row>
              <Col md={6} lg={3}>
                <Input
                  disabled={disabled}
                  name="code"
                  value={smRoleDetail.code}
                  label="รหัสสิทธิ์"
                  rule={{ required: true }}
                  onChange={(value) => handleOnChange(value, "code")} />
              </Col>
              <Col md={6} lg={3}>
                <Input
                  name="name"
                  value={smRoleDetail.name}
                  label="ชื่อสิทธิ์"
                  rule={{ required: true }}
                  onChange={(value) => handleOnChange(value, "name")} />
              </Col>
            </Row>
          </Form>
        </Card>
        <Card className="mt-3">
          <div className="d-flex justify-content-between">
            <label>โปรแกรม</label>
            <Button onClick={() => setShowModal(true)}>
              <FaPlus className="me-2" />เพิ่มโปรแกรม
            </Button>
          </div>
          <div className="mt-3">
            <Table hidePagination>
              <Table.Header>
                <Table.Row>
                  <Table.Column minWidth={200}>ลำดับ</Table.Column>
                  <Table.Column minWidth={250}>รหัสโปรแกรม</Table.Column>
                  <Table.Column minWidth={250}>ชื่อโปรแกรม</Table.Column>
                  <Table.Column minWidth={200}></Table.Column>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {programSelected?.map((data, index) => (
                  <Table.Row key={data.id}>
                    <Table.Cell center>{(index + 1).toString()}</Table.Cell>
                    <Table.Cell>{data.code}</Table.Cell>
                    <Table.Cell>{data.name}</Table.Cell>
                    <Table.Cell right className="px-5 d-flex justify-content-end fs-5">
                      <div className="mx-4">
                        <FaTrashAlt className="cursor-pointer text-primary" onClick={() => onRemoveProgramSelected(index)} />
                      </div>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </Card>
      </> : <></>}
      <ModalSelectProgram
        programSelected={programSelected}
        show={showModal}
        onHide={() => setShowModal(false)}
        onSelectProgram={setProgramSelected}
        programAll={programList} />
    </Layout>
  );
}