import { Layout, Card, Table, Selector, InputTime, Status, StatusType, Input } from "components";
import { statusChapterItems } from "constant";
import { InQueryS1Detail, InQueryS1DetailCriteria, InQueryS1HeaderDetail, ItemModel, Pagination } from "models";
import { Dispatch, FormEvent, SetStateAction, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaAngleLeft, FaFileAlt, FaSearch, FaTrashAlt } from "react-icons/fa";
import { useLoaderData, useNavigate, useSubmit } from "react-router-dom";
import { getFormatDateBC, getTime, fullDateTime } from "utils";

type Loader = { headerData: InQueryS1HeaderDetail, listData: Pagination<InQueryS1Detail>, agendaDDL: ItemModel[], };

type InQueryS1DetailContext = {
  list: Pagination<InQueryS1Detail>;
  setList: Dispatch<SetStateAction<Pagination<InQueryS1Detail>>>;
  searchAsync: Function;
  criteria: InQueryS1DetailCriteria;
  header: InQueryS1HeaderDetail;
  setHeader: Dispatch<SetStateAction<InQueryS1HeaderDetail>>;
};

const Context = createContext({} as InQueryS1DetailContext);

export default function S0301Detail() {
  const { headerData, listData, agendaDDL } = useLoaderData() as Loader;
  const [header, setHeader] = useState<InQueryS1HeaderDetail>(headerData);
  const [list, setList] = useState<Pagination<InQueryS1Detail>>(listData);
  const [criteria, setCriteria] = useState<InQueryS1DetailCriteria>({} as InQueryS1DetailCriteria);
  const navigate = useNavigate();
  const submit = useSubmit();

  useEffect(() => {
    setList(listData);
    setHeader(headerData);
  }, [listData]);

  const searchAsync = useCallback(async (size: number, page: number, criteria?: InQueryS1DetailCriteria) => {
    submit({
      page: page.toString(),
      size: size.toString(),
      criteria: JSON.stringify(criteria),
    });
  }, []);

  const onClearAsync = async () => {
    setCriteria({} as InQueryS1DetailCriteria);
    submit({});
  };

  const onChangeTime = (prop: keyof InQueryS1DetailCriteria, time?: Date) => {
    setCriteria({ ...criteria, [prop]: time });
  };

  const contextValue = useMemo(() => {
    return {
      list,
      setList,
      searchAsync,
      criteria,
      header,
      setHeader,
    }
  }, [list, setList, searchAsync, criteria, header, setHeader]);

  const onSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    searchAsync(list.size, list.page, criteria);
  };

  return (
    <Context.Provider value={contextValue}>
      <Layout title="รายละเอียดสถานะรายงานการประชุม">
        <div className="d-flex my-3">
          <Button
            variant="outline-primary"
            type="button"
            onClick={() => navigate("/s0301")}>
            <FaAngleLeft className="me-2" />ย้อนกลับ
          </Button>
        </div>
        <Card>
          <label><b>ข้อมูลการประชุม</b></label>
          <div className="mt-3 mx-4">
            <Row>
              <Col sm={6} md={4} lg={4} xl={3}>
                <p>ประเภทการะประชุม : {headerData.meetingTypeName}</p>
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <p>ครั้งที่ : {headerData.time}</p>
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <p>สมัยที่ประชุม : {headerData.meetingPeriodName}</p>
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <p>ชื่อการประชุม : {headerData.meetingName}</p>
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <p>ณ ห้องประชุม : {headerData.meetingRoomName}</p>
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <p>วันที่ประชุม : {getFormatDateBC(headerData.startDate)}</p>
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <p>เวลา : {`${getTime(headerData.startTime)} - ${getTime(headerData.endTime)}`}</p>
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <p>กลุ่มงานที่รับผิดชอบ : {headerData.bureauGroups}</p>
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <div className="d-flex gap-1">สถานะ : <Status type={StatusType.MEETING_STATUS} value={headerData.status} textOnly /></div>
              </Col>
            </Row>
          </div>
        </Card>
        <div className="my-3">
          <DocumentStatusDataTable />
        </div>
        <Card>
          <Form onSubmit={onSubmitSearch}>
            <Form.Label>ค้นหา</Form.Label>
            <Row>
              <Col sm={6} md={4} lg={4} xl={3}>
                <Input
                  label="ตอนที่"
                  value={criteria.chapter}
                  onChange={(value) => setCriteria({ ...criteria, chapter: Number(value) })} />
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <InputTime
                  name="startTime"
                  label="ช่วงเวลาเริ่มต้น"
                  value={criteria.startTime}
                  onChange={(value) => onChangeTime("startTime", value)} />
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <InputTime
                  name="endTime"
                  label="ช่วงเวลาสิ้นสุด"
                  value={criteria.endTime}
                  onChange={(value) => onChangeTime("endTime", value)} />
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <Selector
                  label="วาระการประชุม"
                  items={agendaDDL}
                  value={criteria.meetingAgendaId}
                  onChange={(value) => setCriteria({ ...criteria, meetingAgendaId: value.toString() })} />
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <Input
                  label="ผู้อภิปราย"
                  value={criteria.meetingMemberName}
                  onChange={(value) => setCriteria({ ...criteria, meetingMemberName: value.toString() })} />
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <Input
                  label="ผู้จดรายงานการประชุม"
                  value={criteria.dutyOfficerName}
                  onChange={(value) => setCriteria({ ...criteria, dutyOfficerName: value.toString() })} />
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <Selector
                  label="สถานะ"
                  items={statusChapterItems}
                  value={criteria.status}
                  onChange={(value) => setCriteria({ ...criteria, status: value.toString() })} />
              </Col>
            </Row>
            <div className="d-flex gap-3 justify-content-start mt-2">
              <Button
                variant="primary"
                type="submit">
                <FaSearch className="me-2" />
                ค้นหา
              </Button>
              <Button variant="outline-primary"
                onClick={onClearAsync}>
                <FaTrashAlt className="me-2" />
                ล้างค่า
              </Button>
            </div>
          </Form>
        </Card>
        <div className="mt-3">
          <ReportDataTable />
        </div>
      </Layout>
    </Context.Provider>
  );
};

function DocumentStatusDataTable() {
  const { header } = useContext(Context);

  return (
    <>
      <label>สถานะของเอกสาร</label>
      <Table hidePagination>
        <Table.Header>
          <Table.Row>
            <Table.Column minWidth={100}>ขั้นตอน</Table.Column>
            <Table.Column minWidth={300}>รายการ</Table.Column>
            <Table.Column minWidth={150}>วันที่</Table.Column>
            <Table.Column minWidth={100}>ผู้รับผิดชอบ</Table.Column>
            <Table.Column minWidth={100}>สถานะ</Table.Column>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {header.snMeetingSteps?.map((value) => (
            <Table.Row key={value.id}>
              <Table.Cell center>{value.step.toString()}</Table.Cell>
              <Table.Cell>{<Status type={StatusType.STEP_STATUS} value={value.meetingStep} textOnly />}</Table.Cell>
              <Table.Cell center>{fullDateTime(value.updateDate)}</Table.Cell>
              <Table.Cell center>{value.updatedFullName}</Table.Cell>
              <Table.Cell center>{value.status}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

function ReportDataTable() {
  const navigate = useNavigate();
  const { list, searchAsync, criteria } = useContext(Context);

  const navigateToDocument = (id: string) => {
    navigate(`document/${id}`);
  };

  return (
    <>
      <label>ตารางการจดรายงาน</label>
      <div className="table-relative-fix">
        <div className="table-scroll">
          <Table
            total={list.totalRows}
            page={list.page}
            size={list.size}
            onChange={(size, page) => searchAsync(size, page, criteria)}>
            <Table.Header>
              <Table.Row>
                <Table.Column minWidth={80}>ตอนที่</Table.Column>
                <Table.Column minWidth={150}>ช่วงเวลา</Table.Column>
                <Table.Column minWidth={250}>วาระการประชุม</Table.Column>
                <Table.Column minWidth={200}>ผู้อภิปราย</Table.Column>
                <Table.Column minWidth={250}>ผู้จดรายงานการประชุม</Table.Column>
                <Table.Column minWidth={200}>สถานะ</Table.Column>
                <Table.Column minWidth={200}>แก้ไขครั้งล่าสุด</Table.Column>
                <Table.Column minWidth={50}>เวอร์ชั่น</Table.Column>
                <Table.Column minWidth={200} className="fix-col" />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {list.rows?.map((value) => (
                <Table.Row key={value.id}>
                  <Table.Cell center>{value.chapter}</Table.Cell>
                  <Table.Cell center>{`${getTime(value.startDate)} - ${getTime(value.endDate)}`}</Table.Cell>
                  <Table.Cell center>{value.agendaTitle}</Table.Cell>
                  <Table.Cell>{value.debater}</Table.Cell>
                  <Table.Cell>{value.dutyOfficerFullName}</Table.Cell>
                  <Table.Cell center><Status type={StatusType.CHAPTER_STATUS} value={value.status} textOnly /></Table.Cell>
                  <Table.Cell center>{getFormatDateBC(value.updateDate)}</Table.Cell>
                  <Table.Cell center>{value.versionDocument?.toString()}</Table.Cell>
                  <Table.Cell className="fix-col">
                    <div className="d-flex justify-content-center">
                      <Button
                        variant="outline-primary"
                        onClick={() => navigateToDocument(value.id)}>
                        <FaFileAlt className="me-2" />
                        ดูรายละเอียด
                      </Button>
                    </div>
                  </Table.Cell>
                </Table.Row>))}
            </Table.Body>
          </Table>
        </div>
      </div>
    </>
  );
};