import { Layout, Modal, UploadFile, FileValue, ToolTipComment, Table, Status, StatusType } from "components";
import { HttpStatusCode, StatusMeeting, VideoControl } from "constant";
import { ChapterDocuments, CtMeetingDocument, CtReviewChapterList, CtReviewOverviewDetail, Pagination } from "models";
import { useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import { FaAngleLeft, FaBuffer, FaDownload, FaFileAlt, FaRegFilePdf, FaRegFileWord, FaTimes, FaUndo } from "react-icons/fa";
import { HiOutlineDocumentCheck } from "react-icons/hi2";
import { TbFileCheck, TbFileDownload } from "react-icons/tb";
import { useLoaderData, useNavigate, useParams, useSubmit } from "react-router-dom";
import { exportFilePDFAsync, exportFileWordAsync, getFile, showModalCollectAsync, showModalConfirmAsync, showModalProgressBarLoadingAsync } from "utils";
import { c0204, c0205, c0206, ctService, s0206 } from "services";
import Collabora, { CollaboraRef } from "components/Document/Collabora";
import Video, { VideoOption } from "components/Video/Video";
import toast from "utils/toast";

interface ModalProps {
  show: boolean;
  onHide: () => void;
}

interface ModalConfirm {
  result: boolean;
  numberOfPages?: number;
}

type Loader = { ctReviewOverviewDetail: CtReviewOverviewDetail, ctMeetingChapterData: Pagination<CtReviewChapterList> };

export default function C0206Detail() {
  const { ctReviewOverviewDetail, ctMeetingChapterData } = useLoaderData() as Loader;
  const [ctReviewOverview, setCtReviewOverview] = useState<CtReviewOverviewDetail>({} as CtReviewOverviewDetail);
  const [collaboraSelectId, setCollaboraSelectId] = useState("");
  const [video, setVideo] = useState<File>({} as File);
  const [disable, setDisable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [documentList, setDocumentList] = useState<ChapterDocuments[]>([]);
  const [canWrite, setCanWrite] = useState<boolean>();
  const [overviewDocument, setOverviewDocument] = useState(false);
  const [hasOverviewDocument, setHasOverviewDocument] = useState(false);
  const [lastVersion, setLastVersion] = useState("");
  const [fileIds, setFileIds] = useState<string[]>([]);
  const [fileIndex, setFileIndex] = useState<number>(0);
  const programName = useState<string>("ตรวจสอบและรวบรวมรายงานการประชุม");
  const navigate = useNavigate();
  const submit = useSubmit();
  const { id } = useParams();

  useEffect(() => {
    setCtReviewOverview(ctReviewOverviewDetail);

    if (ctReviewOverviewDetail.ctDocuments.length > 0) {
      defaultOverview();
      setHasOverviewDocument(true);
    }

    if (ctReviewOverviewDetail.status === StatusMeeting.OVERVIEW_REVIEWED || ctReviewOverviewDetail.status === StatusMeeting.GUARANTEE) {
      setDisable(true);
    }

    setCanWrite(ctReviewOverviewDetail.status === StatusMeeting.DOCUMENT_COMPLETE ||
      ctReviewOverviewDetail.status === StatusMeeting.COLLECT ||
      ctReviewOverviewDetail.status === StatusMeeting.REJECT);
  }, [ctReviewOverviewDetail, ctMeetingChapterData]);

  const collaboraRef = useRef<CollaboraRef>(null);

  const videoRef = useRef<VideoOption>({} as VideoOption);

  function loadCollabora() {
    const iframe = document.getElementById(collaboraSelectId) as HTMLIFrameElement;

    iframe.contentWindow?.addEventListener('keydown', (e) => clickBtn(e.key));
  }

  function clickBtn(key: string) {
    switch (key) {
      case VideoControl.F10:
        videoRef.current.backRef();
        break;
      case VideoControl.F9:
        videoRef.current.playRef();
        break;
      case VideoControl.F11:
        videoRef.current.aheadRef();
        break;
    }
  }

  const defaultOverview = (action?: boolean) => {
    if (action && collaboraSelectId === ctReviewOverviewDetail.ctDocuments[0].id) {
      return toast.success('แสดงรายงานที่รวบรวมแล้ว');
    }

    setCollaboraSelectId(ctReviewOverviewDetail.ctDocuments[0].id);
    setLastVersion((ctReviewOverviewDetail.ctDocuments[0].versionDocument).toString());

    const doucumentList: ChapterDocuments[] = ctReviewOverviewDetail.ctDocuments.map(d => ({
      id: d.id,
      versionDocument: d.versionDocument,
    }));

    setDocumentList(doucumentList);
    setOverviewDocument(true);
    setVideo({} as File);

    setCanWrite(ctReviewOverviewDetail.status === StatusMeeting.DOCUMENT_COMPLETE ||
      ctReviewOverviewDetail.status === StatusMeeting.COLLECT ||
      ctReviewOverviewDetail.status === StatusMeeting.REJECT);
  };

  const updateStatusAsync = async (statusUpdate: StatusMeeting, textAnnounce: string, numberOfPages?: number) => {
    if (id) {
      const { status } = await c0206.updateCtReviewOverviewStatusAsync(id, statusUpdate, ctReviewOverview.documents, numberOfPages);

      if (status === HttpStatusCode.ACCEPTED) {
        toast.success(textAnnounce);

        submit({});
      }
    }
  };

  const onSaveDraftAsync = async () => {
    await updateStatusAsync(ctReviewOverview.status as StatusMeeting, 'บันทึกข้อมูลสำเร็จ');
  };

  const onSendReportAsync = async () => {
    const response: ModalConfirm = await showModalConfirmAsync('กรุณาระบุจำนวนหน้ากระดาษก่อนส่งรายงานการประชุม',
      'ส่งรายงาน',
      <HiOutlineDocumentCheck className="my-1 text-success" style={{ fontSize: 80 }} />,
      true,
      ctReviewOverview.numberOfPages) as ModalConfirm;

    if (!response.result || !response.numberOfPages) {
      return;
    }

    await updateStatusAsync(StatusMeeting.OVERVIEW_REVIEWED, 'ส่งรายงานสำเร็จ', response.numberOfPages);
  };

  const uploadFileAsync = async (file: File, sequence: number) => {
    const res = await c0206.uploadFileAsync(id!, file, sequence);

    if (res.status === HttpStatusCode.ACCEPTED) {
      toast.success('อัพโหลดไฟล์สำเร็จ');

      setCtReviewOverview({
        ...ctReviewOverview,
        documents: [
          ...ctReviewOverview.documents,
          {
            id: res.data,
            fileName: file.name,
            sequence,
            ctMeetingId: ctReviewOverview.id,
          },
        ],
      });
    }
  };

  const removeFileAsync = async (index: number) => {
    const fileDeleted = ctReviewOverview.documents.splice(index, 1);

    const res = await c0206.removeFileAsync(fileDeleted[0].ctMeetingId, fileDeleted[0].id);

    if (res.status === HttpStatusCode.ACCEPTED) {
      toast.success('ลบไฟล์สำเร็จ');

      setCtReviewOverview({
        ...ctReviewOverview,
        documents: [...ctReviewOverview.documents],
      });
    }
  };

  const documents = useMemo(() => {
    return ctReviewOverview.documents?.map(d => ({ id: d.id, fileName: d.fileName, sequence: d.sequence })) as FileValue[];
  }, [ctReviewOverview.documents]);

  const onMoveChange = (value: FileValue[]) => {
    setCtReviewOverview({
      ...ctReviewOverview,
      documents: [
        ...value.map((v, i) => ({ ...v, sequence: i + 1 } as CtMeetingDocument)),
      ],
    });
  };

  const dowloadFileAsync = async (id: string, fileName?: string) => {
    const { data, status } = await s0206.downloadFileAsync(id);

    if (status === HttpStatusCode.OK) {
      getFile(data, fileName);
    }
  };

  const getDetailAsync = async (data: CtReviewChapterList) => {
    const fileId = await getFileIdAsync(data.id);

    showVideoAsync(fileId);

    const documentResponse = await c0204.getCtMakeDocumentChapterDetailAsync(data.id);

    if (documentResponse.status === HttpStatusCode.OK) {
      const documentData: ChapterDocuments[] = documentResponse.data.ctChapterDocuments;

      setCollaboraSelectId(documentData[0].id);
      setDocumentList(documentData);
      setCanWrite(false);
      setOverviewDocument(false);
      setLastVersion((documentData[0].versionDocument).toString());
    }
  };

  const showVideoAsync = async (videoId: string) => {
    const { data, status } = await ctService.getVideoAsync(videoId);

    if (status === HttpStatusCode.OK) {
      setVideo(data);
    } else {
      setVideo({} as File);
    }
  };

  const collectDocumentAsync = async () => {
    if (!await showModalCollectAsync()) {

      return;
    }

    if (id) {
      await showModalProgressBarLoadingAsync(0, "กำลังรวบรวมรายงานการประชุม...", true);

      const { status } = await c0205.collectDocumentAsync(id);

      if (status === HttpStatusCode.ACCEPTED) {
        toast.success('รวบรวมรายงานการประชุมสำเร็จ');
      }

      submit({});
    }
  };

  const onSearch = (size: number, page: number) => {
    submit({
      page: page,
      size: size,
    })
  };

  const onSelectVersion = (id: string | null) => {
    if (id) {
      const documentSelected = documentList.find(f => f.id == id);

      if (documentSelected) {
        setLastVersion(documentSelected.versionDocument.toString());
        setCollaboraSelectId(documentSelected.id);
      }

      if (documentSelected !== documentList[0]) {
        setCanWrite(false);

        return;
      }

      if ((ctReviewOverviewDetail.status === StatusMeeting.DOCUMENT_COMPLETE || ctReviewOverviewDetail.status === StatusMeeting.COLLECT) && overviewDocument) {
        setCanWrite(true);

        return;
      }
    }
  };

  const getFileIdAsync = async (fileId: string) => {
    const { data, status } = await ctService.getRecordFileIdAsync(fileId, id!);

    if (status === HttpStatusCode.OK) {
      setFileIds(data);

      return data[0];
    }
  };

  const onEndedAsync = async () => {
    const currentIndex = fileIndex + 1;

    if (fileIds.length > currentIndex) {
      showVideoAsync(fileIds[currentIndex]);

      setFileIndex(currentIndex);
    } else {
      setFileIndex(0);
    }
  };

  return (
    <Layout title={programName[0]}>
      <div className="d-flex justify-content-between my-3">
        <Button
          variant="outline-primary"
          type="button"
          onClick={() => navigate('/c0206')}>
          <FaAngleLeft className="me-2" />ย้อนกลับ
        </Button>
        <div className="d-flex gap-2">
          {hasOverviewDocument &&
            <Button variant="outline-primary" onClick={() => defaultOverview(true)}>
              <FaFileAlt className="me-2" /> ดูรายงานที่รวบรวม
            </Button>}
          <DropdownButton
            title={<span><FaUndo className="me-2" />เวอร์ชั่นก่อนหน้า ({lastVersion})</span>}
            drop="down-centered"
            variant="outline-primary"
            onSelect={(value) => onSelectVersion(value)}>
            {documentList?.map((data) => (
              <Dropdown.Item eventKey={data.id} key={data.id}>{data.versionDocument}</Dropdown.Item>
            ))}
          </DropdownButton>
          <Button
            variant="outline-primary"
            onClick={() => exportFilePDFAsync(collaboraSelectId, programName[0])}>
            <FaRegFilePdf className="me-2" />Export PDF
          </Button>
          <Button
            variant="outline-primary"
            onClick={() => exportFileWordAsync(collaboraSelectId, programName[0])}>
            <FaRegFileWord className="me-2" />Export Word
          </Button>
          {(ctReviewOverview.status === StatusMeeting.COLLECT
            || ctReviewOverview.status === StatusMeeting.REJECT) &&
            <Button
              variant="outline-primary"
              type="button"
              onClick={onSaveDraftAsync}>
              <TbFileDownload className="me-2" />บันทึกเวอร์ชั่น
            </Button>}
          {(ctReviewOverview.status === StatusMeeting.DOCUMENT_COMPLETE) &&
            <Button onClick={collectDocumentAsync}>
              <FaBuffer className="me-2" />รวบรวมรายงานการประชุม
            </Button>}
          {(ctReviewOverview.status === StatusMeeting.COLLECT || ctReviewOverview.status === StatusMeeting.REJECT) &&
            <Button
              variant="primary"
              type="button"
              onClick={onSendReportAsync}>
              <TbFileCheck className="me-2" />ส่งรายงาน
            </Button>}
        </div>
      </div>
      <Row>
        <Col xl={4}>
          {ctReviewOverview.status === StatusMeeting.REJECT ? <ToolTipComment subject={ctReviewOverview.certifyingReportRejectComment ?? "ไม่มีความคิดเห็น"} /> : null}
          <Video
            onEnded={onEndedAsync}
            ref={videoRef}
            files={video.type ? [video] : []}
            showPlayButton
            showPlayTimeConfig={false}
            showSpeakToText={false} />
          <UploadFile
            disabled={disable}
            value={documents}
            onChange={uploadFileAsync}
            onRemove={removeFileAsync}
            onMoveChange={onMoveChange}
            dowloadFile={(id, fileName) => dowloadFileAsync(id, fileName)}
            canEdit
            onEditDocument={(id) => setCollaboraSelectId(id)} />
          <div className="table-relative-fix">
            <div className="table-scroll">
              <Table className="mt-2" total={ctMeetingChapterData.totalRows} onChange={onSearch}>
                <Table.Header>
                  <Table.Row>
                    <Table.Column minWidth={80}>ตอนที่่</Table.Column>
                    <Table.Column minWidth={150}>ผู้จดรายงานการประชุม</Table.Column>
                    <Table.Column>สถานะ</Table.Column>
                    <Table.Column minWidth={150} className="fix-col" />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {ctMeetingChapterData.rows?.map(c => (
                    <Table.Row key={c.id}>
                      <Table.Cell center>{c.chapter}</Table.Cell>
                      <Table.Cell>{c.dutyOfficerFullName}</Table.Cell>
                      <Table.Cell ><Status type={StatusType.CHAPTER_STATUS} value={c.status} /></Table.Cell>
                      <Table.Cell center className="fix-col">
                        <Button
                          variant="outline-primary"
                          size="sm"
                          onClick={() => getDetailAsync(c)}>
                          <FaFileAlt className="me-2" /> ดูรายละเอียด
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          </div>
        </Col>
        <Col xl={8}>
          {(collaboraSelectId) &&
            <Collabora
              docId={collaboraSelectId}
              docName="c0206"
              height="1000px"
              ref={collaboraRef}
              onCollaboraLoaded={() => loadCollabora()}
              canWrite={canWrite} />}
        </Col>
      </Row>
      <ModalUploadDocument show={showModal} onHide={() => setShowModal(false)} />
    </Layout >
  );
}

function ModalUploadDocument(props: ModalProps) {
  return (
    <Modal show={props.show}>
      <div className="d-flex justify-content-between">
        <div>
          <FaDownload className="me-2" />นำเข้าข้อมูลภายนอก
        </div>
        <div>
          <FaTimes onClick={props.onHide} />
        </div>
      </div>
    </Modal>
  );
}