import { Layout } from "components";
import { useEffect, useRef, useState } from 'react';
import { Button, Col, Dropdown, DropdownButton, Form, Row } from "react-bootstrap";
import { FaAngleLeft, FaUndo, FaRegFilePdf, } from "react-icons/fa";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import Collabora, { CollaboraRef } from 'components/Document/Collabora';
import Video, { VideoOption } from 'components/Video/Video';
import { HttpStatusCode, VideoControl } from "constant";
import { SnMakeDocumentDetail } from "models";
import { exportFilePDFAsync } from "utils";
import { snVideoService } from "services";

type Loader = { documentData: SnMakeDocumentDetail; fileId: string[]; };

export default function S0301Document() {
  const { documentData, fileId } = useLoaderData() as Loader;
  const [documentSelected, setDocumentSelected] = useState("");
  const [documentSelectedVersion, setDocumentSelectedVersion] = useState<number>(0);
  const [videoSelect, setVideoSelect] = useState<File>();
  const [fileIndex, setFileIndex] = useState<number>(0);
  const programName = useState<string>("สถานะรายงานการประชุม");
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    setDocumentSelected(documentData.snChapterDocuments[0].id);
    setDocumentSelectedVersion(documentData.snChapterDocuments[0].versionDocument);
  }, [documentData]);

  useEffect(() => {
    if (fileId.length) {
      getVideoAsync(fileId[0]);
      setFileIndex(0);
    }
  }, [fileId]);

  const getVideoAsync = async (videoId: string) => {
    const { data, status } = await snVideoService.getVideoAsync(videoId);

    if (status === HttpStatusCode.OK) {
      setVideoSelect(data);
    }
  }

  const onEndedAsync = async () => {
    const currentIndex = fileIndex + 1;

    if (fileId.length > currentIndex) {
      getVideoAsync(fileId[currentIndex]);

      setFileIndex(currentIndex);
    } else {
      setFileIndex(0);
    }
  };

  const onLoadedData = () => {
    if (fileIndex) {
      videoRef.current.playRef();
    }
  };

  const collaboraRef = useRef<CollaboraRef>(null);

  const videoRef = useRef<VideoOption>({} as VideoOption);

  function loadCollabora() {
    const iframe = document.getElementById(documentSelected) as HTMLIFrameElement;

    iframe.contentWindow?.addEventListener('keydown', (e) => clickBtn(e.key));
  }

  function clickBtn(key: string) {
    switch (key) {
      case VideoControl.F10:
        videoRef.current.backRef();
        break;
      case VideoControl.F9:
        videoRef.current.playRef();
        break;
      case VideoControl.F11:
        videoRef.current.aheadRef();
        break;
    }
  };

  const onChangeVersionDocument = (id: string | null) => {
    if (id) {
      setDocumentSelected(id);

      const fileVersion = documentData.snChapterDocuments.find(d => d.id === id)?.versionDocument;

      if (fileVersion) {
        setDocumentSelectedVersion(fileVersion);
      }
    }
  };

  return (
    <Layout title="รายละเอียดการจด/แก้ไข รายงานการประชุม" className="s0205-detail">
      <Form>
        <div className="d-flex justify-content-between my-3">
          <Button
            variant="outline-primary"
            type="button"
            onClick={() => navigate(`/s0301/detail/${id}`)}>
            <FaAngleLeft className="me-2" />ย้อนกลับ
          </Button>
          <div className="d-flex gap-2">
            <DropdownButton
              title={<span><FaUndo className="me-2" />เวอร์ชั่นก่อนหน้า ({documentSelectedVersion})</span>}
              drop="down-centered"
              variant="outline-primary"
              onSelect={(value) => onChangeVersionDocument(value)}>
              {documentData.snChapterDocuments?.map(d => (
                <Dropdown.Item eventKey={d.id} key={d.id}>{d.versionDocument}</Dropdown.Item>
              ))}
            </DropdownButton>
            <Button
              variant="outline-primary"
              onClick={() => exportFilePDFAsync(documentSelected, programName[0])}>
              <FaRegFilePdf className="me-2" />Export PDF
            </Button>
          </div>
        </div>
        <Row>
          <Col xl={4} className="mb-2">
            <Video
              onEnded={onEndedAsync}
              onLoadedData={onLoadedData}
              ref={videoRef}
              files={videoSelect?.type ? [videoSelect] : []}
              showPlayButton={false}
              showPlayTimeConfig={false}
              showSpeakToText={false} />
          </Col>
          <Col xl={8}>
            <Collabora
              docId={documentSelected}
              docName="s0301"
              height="1000px"
              ref={collaboraRef}
              onCollaboraLoaded={() => loadCollabora()}
            />
          </Col>
        </Row>
      </Form>
    </Layout>
  );
}