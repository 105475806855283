import { SelectionType } from "constant";
import { CtReviewOverviewCriteria } from "models";
import { LoaderFunctionArgs } from "react-router-dom";
import { c0206, selectionService } from "services";

export const c0206LoaderAsync = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 1;
  const size = url.searchParams.get('size') || 10;
  const criteriaStringify = url.searchParams.get('criteria') || '{\"isNotReviewed\": true }';
  const criteria = JSON.parse(criteriaStringify) as CtReviewOverviewCriteria;

  const [bureaus, committees, subCommittees, ctMakeDocumentList] = await Promise.all([
    selectionService.getSelectionAsync(SelectionType.SM_BUREAU),
    selectionService.getSelectionAsync(SelectionType.CT_COMMITTEE),
    selectionService.getSelectionAsync(SelectionType.CT_SUBCOMMITTEE),
    c0206.getCtReviewOverviewListAsync(+page, +size, criteria),
  ]);

  return {
    bureausItems: bureaus.data,
    committeesItems: committees.data,
    subCommitteesItems: subCommittees.data,
    data: {
      rows: ctMakeDocumentList.data.rows,
      totalRows: ctMakeDocumentList.data.totalRows,
      page: +page,
      size: +size,
    },
  };
};

export const c0206DetailLoaderAsync = async ({ params, request }: LoaderFunctionArgs) => {
  const { id } = params;
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 1;
  const size = url.searchParams.get('size') || 10;

  if (id) {
    const [ctReviewOverviewDetail, ctMeetingChapter] = await Promise.all([
      c0206.getCtReviewOverviewDetailAsync(id),
      c0206.getCtReviewChapterListAsync(id, +page, +size),
    ]);

    return {
      ctReviewOverviewDetail: ctReviewOverviewDetail.data,
      ctMeetingChapterData: {
        rows: ctMeetingChapter.data.rows,
        totalRows: ctMeetingChapter.data.totalRows,
        page: +page,
        size: +size,
      }
    }
  }
};