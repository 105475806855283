import { SelectionType } from "constant";
import { LoaderFunctionArgs } from "react-router-dom";
import { s0207, selectionService } from "services";

export const s0207LoaderAsync = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 1;
  const size = url.searchParams.get('size') || 10;
  const criteria = JSON.parse(url.searchParams.get('criteria')!);

  const [snCerifyingreport, snMeetingType, snMeetingPeriod] = await Promise.all([
    s0207.getSnCerifyingreportListAsync(+page, +size, criteria),
    selectionService.getSelectionAsync(SelectionType.SN_MEETING_TYPE),
    selectionService.getSelectionAsync(SelectionType.SN_MEETING_PERIOD),
  ]);

  return {
    data: {
      rows: snCerifyingreport.data.rows,
      totalRows: snCerifyingreport.data.totalRows,
      page: +page,
      size: +size,
    },
    snMeetingTypeItems: snMeetingType.data,
    snMeetingPeriodItems: snMeetingPeriod.data,
  };
};

export const s0207DetailLoaderAsync = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;

  if (id) {
    const [snCerifyingreport, snMeetingType, snMeetingPeriod, snMeetingRoom] = await Promise.all([
      s0207.getSnCerifyingreportDetailAsync(id),
      selectionService.getSelectionAsync(SelectionType.SN_MEETING_TYPE),
      selectionService.getSelectionAsync(SelectionType.SN_MEETING_PERIOD),
      selectionService.getSelectionAsync(SelectionType.SM_MEETING_ROOM),
    ]);

    return {
      snCerifyingreportData: snCerifyingreport.data,
      snMeetingTypeItems: snMeetingType.data,
      snMeetingPeriodItems: snMeetingPeriod.data,
      snMeetingRoomItems: snMeetingRoom.data,
    }
  }
};