import { Layout, Card, InputDate, Table, Selector, Input, Check } from "components";
import { HttpStatusCode, MovementType, NormalStatus, PerType, StatusMeeting } from "constant";
import { CtReportMakeDailyCreate, CtReportMakerDailyDetail, Generate, ItemModel, MakerDaily } from "models";
import { Dispatch, createContext, useContext, useEffect, useMemo, useState } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { FaAngleLeft, FaSync, FaFileDownload, FaRegFileExcel, FaChevronCircleUp, FaChevronCircleDown } from "react-icons/fa";
import { useNavigate } from "react-router";
import { useLoaderData, useParams, useSubmit } from "react-router-dom";
import { c0201 } from "services";
import { exportFileExcel } from "utils/dowloadFile";
import toast from "utils/toast";

type Loader = {
  smBureauItems: ItemModel[],
  smBureauGroupItems: ItemModel[],
  smUserItems: ItemModel[],
  positionTypeItems: ItemModel[],
  dailyStatusTypeItems: ItemModel[],
  ctReportMakerDailyData: CtReportMakerDailyDetail,
};

enum Type {
  DAILY_STATUS,
  SPECIAL_POINT,
}

interface Context {
  ctReportMakerDaily: CtReportMakeDailyCreate;
  setCtReportMakerDaily: Dispatch<React.SetStateAction<CtReportMakeDailyCreate>>;
  disabled: boolean;
}

export enum TableColor {
  TABLE_TEXT_COLOR = '#FF6000',
  TABLE_LAST_ACTION_COLOR = '#FFDCDC',
}

const Context = createContext({} as Context);

export default function C0201Detail() {
  const { ctReportMakerDailyData } = useLoaderData() as Loader;
  const [ctReportMakerDaily, setCtReportMakerDaily] = useState<CtReportMakeDailyCreate>({} as CtReportMakeDailyCreate);
  const [showDataTable, setShowDataTable] = useState(false);
  const [disabledSelect, setDisabledSelect] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const submit = useSubmit();
  const [errorGenerate, setErrorGenerate] = useState<string[]>([]);

  useEffect(() => {
    if (ctReportMakerDailyData) {
      getDetail(ctReportMakerDailyData);
      setShowDataTable(true);
      setDisabledSelect(true);

      if (ctReportMakerDailyData.status === StatusMeeting.CONFIRM) {
        setDisabled(true);
      }
    }
  }, [ctReportMakerDailyData]);

  const conTextValue = useMemo(() => {
    return {
      ctReportMakerDaily,
      setCtReportMakerDaily,
      disabled,
    }
  }, [ctReportMakerDaily, setCtReportMakerDaily, disabled]);

  const getDetail = (data: CtReportMakerDailyDetail) => {
    const reportMaker = data.reportMakers;
    const convertReportMaker: MakerDaily[] = reportMaker.map(r => ({
      bureauGroupName: r.bureauGroupName,
      id: r.id,
      sequence: r.sequence,
      ctReportMakerId: r.ctReportMakerId,
      dailyStatus: r.dailyStatus,
      score: r.score,
      scoreSpecial: r.scoreSpecial,
      userFullName: r.fullName,
      position: r.position,
      lastQueue: r.lastQueue,
      perType: r.perType,
      chapterScore: r.chapterScore,
      dailyScore: r.dailyScore,
      dailySpecialScore: r.dailySpecialScore,
    }));

    const detailData: CtReportMakeDailyCreate = {
      date: data.date,
      makerDaily: convertReportMaker,
      status: data.status,
    };

    setCtReportMakerDaily(detailData);
  };

  const onChangeDateAsync = async (value?: Date) => {
    setErrorGenerate([]);
    setShowDataTable(false);

    if (value) {
      const date = new Date(value);
      const day = date.toISOString().split('T')[0];
      const response = await c0201.checkDuplicateDateAsync(value);

      if (response.status === HttpStatusCode.CONFLICT) {
        toast.warn("วันที่ซ้ำ");

        return;
      }

      const { data, status } = await c0201.getCtReportMakerDailyGenerateAsync(day);

      if (status === HttpStatusCode.OK) {
        const generateData: Generate[] = data;
        const matchData: MakerDaily[] = generateData.map((data) =>
        ({
          bureauGroupName: data.bureauGroupName,
          userFullName: data.userFullName,
          position: data.position,
          score: data.score,
          dailyStatus: data.dailyStatus,
          ctReportMakerId: data.id,
          sequence: data.sequence,
          bureauName: data.bureauName,
          perType: data.perType,
          dailyScore: data.dailyScore,
          chapterScore: data.chapterScore,
          dailySpecialScore: data.dailySpecialScore,
        }));

        setCtReportMakerDaily({ ...ctReportMakerDaily, makerDaily: matchData, date: value });
        setShowDataTable(true);
      }

      if (status === HttpStatusCode.NOT_FOUND) {
        const newLineText = data.split("\n");

        setErrorGenerate(newLineText);
      }
    }
  };

  const onSaveAsync = async () => {
    const body = ctReportMakerDaily;

    body.date = (new Date(ctReportMakerDaily.date)).toISOString().split('T')[0];
    body.status = StatusMeeting.DRAFT;
    body.makerDaily = ctReportMakerDaily.makerDaily.map((d, i) => ({
      bureauGroupName: d.bureauGroupName,
      dailyStatus: d.dailyStatus === NormalStatus.NORMAL ? null : d.dailyStatus,
      userFullName: d.userFullName,
      position: d.position,
      id: d.id,
      score: d.score ? d.score : 0,
      scoreSpecial: d.scoreSpecial,
      ctReportMakerId: d.ctReportMakerId ?? "",
      sequence: i + 1,
      lastQueue: d.lastQueue,
      perType: d.perType,
      chapterScore: d.chapterScore,
      dailyScore: d.dailyScore,
      dailySpecialScore: d.dailySpecialScore,
    }));

    const { data, status } = await c0201.createCtReportMakerDailyAsync(body);

    if (status === HttpStatusCode.CREATED) {
      toast.success("บันทึกข้อมูลสำเร็จ");

      navigate(`/c0201/detail/${data}`);
    }
  };

  const onUpdateAsync = async (statusData: StatusMeeting, id: string) => {
    const body = ctReportMakerDaily;

    body.status = statusData;
    body.makerDaily = ctReportMakerDaily.makerDaily.map((d, i) => ({
      bureauGroupName: d.bureauGroupName,
      dailyStatus: d.dailyStatus === NormalStatus.NORMAL ? null : d.dailyStatus,
      userFullName: d.userFullName,
      position: d.position,
      id: d.id,
      score: d.score,
      scoreSpecial: d.scoreSpecial,
      ctReportMakerId: d.ctReportMakerId,
      lastQueue: d.lastQueue,
      sequence: i + 1,
      perType: d.perType,
      chapterScore: d.chapterScore,
      dailyScore: d.dailyScore,
      dailySpecialScore: d.dailySpecialScore,
    }));

    const { status } = await c0201.updateCtReportMakerDailyAsync(id, body);

    if (status === HttpStatusCode.ACCEPTED) {
      switch (statusData) {
        case StatusMeeting.DRAFT:
          toast.success("บันทึกข้อมูลสำเร็จ");
          break;
        case StatusMeeting.CONFIRM:
          toast.success("ยืนยันสำเร็จสำเร็จ");
          break;
      }
    }

    submit({});
  };

  const onSubmit = () => {
    if (id) {
      onUpdateAsync(StatusMeeting.DRAFT, id);

      return;
    }

    onSaveAsync();
  };

  const sortDataByResult = () => {
    const sortData = ctReportMakerDaily.makerDaily.sort((a, b) => ((a.score ?? 0) + (a.scoreSpecial ?? 0)) - ((b.score ?? 0) + (b.scoreSpecial ?? 0)));

    setCtReportMakerDaily({ ...ctReportMakerDaily, makerDaily: sortData });
  };

  return (
    <Context.Provider value={conTextValue}>
      <Layout title="รายชื่อผู้จดรายงานการประชุมประจำวัน">
        <Form>
          <div className="d-flex justify-content-between my-3">
            <Button variant="outline-primary" type="button" onClick={() => navigate('/c0201')}>
              <FaAngleLeft size={15} className="me-2" />ย้อนกลับ
            </Button>
            <div className="d-flex">
              <Button
                variant="outline-primary"
                type="button"
                onClick={sortDataByResult}>
                <FaSync size={15} className="me-2" />จัดรายชื่ออัตโนมัติตามคะแนน
              </Button>
              <Button
                variant="outline-primary"
                type="button"
                className="mx-2"
                onClick={onSubmit}>
                <FaFileDownload size={15} className="me-2" />บันทึก
              </Button>
            </div>
          </div>
          <Card>
            <Row>
              <Col xs={4}>
                <InputDate
                  name="date-time"
                  label="วันที่"
                  disabled={disabledSelect}
                  value={ctReportMakerDaily.date as Date}
                  onChange={(value) => onChangeDateAsync(value)} />
              </Col>
            </Row>
          </Card>
          {errorGenerate.length > 0 &&
            <div className="mt-4 text-danger">
              <label><b>**พบผู้ปฎิบัติงานไม่ยืนยันการจัดทำรายงาน</b></label>
              {errorGenerate.map((value, index) => (
                <>
                  <br />
                  <label className="mx-4" key={index}>{value}</label>
                </>
              ))}
            </div>
          }
          {showDataTable && <>
            <MeetingDataTable />
          </>}
        </Form>
      </Layout >
    </Context.Provider>
  );
}

function MeetingDataTable() {
  const { ctReportMakerDaily, setCtReportMakerDaily, disabled } = useContext(Context);
  const { dailyStatusTypeItems: dailyStatusTypeItems, ctReportMakerDailyData } = useLoaderData() as Loader;
  const [dailyStatusItems, setDailyStatusItems] = useState<ItemModel[]>([]);
  const [lastAction, setLastAction] = useState<string>('');

  useEffect(() => {
    getDailyStatusAsync();
  }, []);

  const getDailyStatusAsync = () => {
    const itemsData: ItemModel[] = [...dailyStatusTypeItems];
    itemsData.unshift({ value: NormalStatus.NORMAL, label: 'ปกติ' });

    setDailyStatusItems(itemsData);
  };

  const onChangeStatus = (value: string | number, index: number, type: Type) => {
    const makerList = [...ctReportMakerDaily.makerDaily];
    const valueConvert = value.toString();

    switch (type) {
      case Type.DAILY_STATUS:
        makerList[index].dailyStatus = valueConvert;
        break;
      case Type.SPECIAL_POINT:
        makerList[index].scoreSpecial = parseFloat(valueConvert);
        break;
    }

    setCtReportMakerDaily({ ...ctReportMakerDaily, makerDaily: makerList });
  };

  const getDailyStatusName = (id: string | null | undefined) => {
    const filterData = dailyStatusItems.find(data => data.id === id);

    if (filterData) {
      return filterData.value;
    }

    return NormalStatus.NORMAL;
  };

  const onChangeDailyStatus = (index: number, value: string | number) => {
    const makerList = [...ctReportMakerDaily.makerDaily];
    const filterData = dailyStatusItems.find(f => f.value === value);

    makerList[index].dailyStatus = filterData?.id;

    setCtReportMakerDaily({ ...ctReportMakerDaily, makerDaily: makerList });
  };

  const exportExcelAsync = async (id: string) => {
    const { data, status } = await c0201.exportExcelAsync(id);

    if (status === HttpStatusCode.OK) {
      exportFileExcel(data);
    }
  };

  const onChangeSequence = (sequenceType: MovementType, index: number) => {
    if (!disabled) {
      if (sequenceType === MovementType.UP && index > 0) {
        const currentIndexData = ctReportMakerDaily.makerDaily[index];
        const beforeIndexData = ctReportMakerDaily.makerDaily[index - 1];

        currentIndexData.isMoved = true;
        beforeIndexData.isMoved = true;

        setLastAction(currentIndexData.id!);

        if (currentIndexData?.sequence && beforeIndexData?.sequence) {
          ctReportMakerDaily.makerDaily.splice((index - 1), 2, currentIndexData, beforeIndexData);

          setCtReportMakerDaily({ ...ctReportMakerDaily, makerDaily: [...ctReportMakerDaily.makerDaily] });
        }
      } else if (sequenceType === MovementType.DOWN && index !== ctReportMakerDaily.makerDaily.length - 1) {
        const currentIndexData = ctReportMakerDaily.makerDaily[index];
        const afterIndexData = ctReportMakerDaily.makerDaily[index + 1];

        currentIndexData.isMoved = true;
        afterIndexData.isMoved = true;

        setLastAction(currentIndexData.id!);

        if (currentIndexData?.sequence && afterIndexData?.sequence) {
          ctReportMakerDaily.makerDaily.splice(index, 2, afterIndexData, currentIndexData);

          setCtReportMakerDaily({ ...ctReportMakerDaily, makerDaily: [...ctReportMakerDaily.makerDaily] });
        }
      }
    }
  };

  const onCheckLastQueue = (index: number) => {
    const upDateLastQueue = ctReportMakerDaily.makerDaily.find(m => m.lastQueue);

    if (upDateLastQueue) {
      upDateLastQueue.lastQueue = false;
    }

    ctReportMakerDaily.makerDaily[index].lastQueue = true;

    setCtReportMakerDaily({ ...ctReportMakerDaily, makerDaily: [...ctReportMakerDaily.makerDaily] });
  };

  return (
    <Row className="mt-3">
      <Col>
        <div className="d-flex justify-content-between my-3">
          <span className="fw-bold">ตารางรายชื่อผู้จัดทำรายงานการประชุม</span>
          <Button type="button" variant="outline-primary" onClick={() => exportExcelAsync(ctReportMakerDailyData.id)}>
            <FaRegFileExcel className="me-2" size={15} />Export Excel
          </Button>
        </div>
        <Table hidePagination striped={false}>
          <Table.Header>
            <Table.Row>
              <Table.Column minWidth={50}>ลำดับคิว</Table.Column>
              <Table.Column minWidth={100}>กลุ่ม</Table.Column>
              <Table.Column minWidth={150}>รายชื่อผู้จดรายงาน</Table.Column>
              <Table.Column minWidth={200}>ตำแหน่ง</Table.Column>
              <Table.Column minWidth={100}>
                <>
                  จำนวนตอนที่ได้<br />ก่อนหน้า
                </>
              </Table.Column>
              <Table.Column minWidth={100}>
                <>
                  คะแนนประจำวัน<br />ก่อนหน้า
                </>
              </Table.Column>
              <Table.Column minWidth={100}>
                <>
                  คะแนนพิเศษประจำวัน<br />ก่อนหน้า
                </>
              </Table.Column>
              <Table.Column minWidth={100}>คะแนน</Table.Column>
              <Table.Column minWidth={100}>คะแนนพิเศษ</Table.Column>
              <Table.Column minWidth={100}>คะแนนรวม</Table.Column>
              <Table.Column minWidth={150}>สถานะประจำวัน</Table.Column>
              <Table.Column minWidth={80}>ผู้จดลำดับสุดท้าย</Table.Column>
              <Table.Column minWidth={100}></Table.Column>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {ctReportMakerDaily.makerDaily?.map((data, index) => (
              <Table.Row
                key={data.id}
                color={data.perType === PerType.EMPLOYEE ? TableColor.TABLE_TEXT_COLOR : ''}
                backgroundColor={data.id === lastAction ? TableColor.TABLE_LAST_ACTION_COLOR : ''}>
                <Table.Cell center>{(index + 1)}</Table.Cell>
                <Table.Cell center>{data.bureauGroupName}</Table.Cell>
                <Table.Cell>{data.userFullName}</Table.Cell>
                <Table.Cell>{data.position}</Table.Cell>
                <Table.Cell center>{data.chapterScore}</Table.Cell>
                <Table.Cell center>{data.dailyScore}</Table.Cell>
                <Table.Cell center>{data.dailySpecialScore}</Table.Cell>
                <Table.Cell center>{data.score}</Table.Cell>
                <Table.Cell>
                  <Input
                    type="number"
                    disabled={disabled}
                    textEnd
                    value={data.scoreSpecial}
                    onChange={(value) => onChangeStatus(value, index, Type.SPECIAL_POINT)} />
                </Table.Cell>
                <Table.Cell right>
                  {(data.score ? data.score : 0) + (data.scoreSpecial ? data.scoreSpecial : 0)}
                </Table.Cell>
                <Table.Cell>
                  <Selector
                    isHideClearButton
                    disabled={disabled}
                    items={dailyStatusItems}
                    value={getDailyStatusName(data.dailyStatus)}
                    onChange={(value) => onChangeDailyStatus(index, value)} />
                </Table.Cell>
                <Table.Cell center>
                  <div className="d-flex justify-content-center">
                    <Check
                      disabled={disabled}
                      value={data.lastQueue}
                      onChange={() => onCheckLastQueue(index)} />
                  </div>
                </Table.Cell>
                <Table.Cell center className="fs-5">
                  <>
                    <FaChevronCircleUp className="me-2" onClick={() => onChangeSequence(MovementType.UP, index)} />
                    <FaChevronCircleDown className="mx-2" onClick={() => onChangeSequence(MovementType.DOWN, index)} />
                  </>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Col>
    </Row>
  );
}