import { FormEvent, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useLoaderData, useNavigate, useSubmit } from "react-router-dom";
import { Card, Input, Layout, Selector, Table } from "components";
import { FaEdit, FaPlus, FaSearch, FaTrashAlt } from "react-icons/fa";
import { Pagination, M0103Criteria, ItemModel } from "models";
import { calculateRowNumber, fullDateTime } from "utils";

type UserListResponse = {
  id: string;
  bureauName: string;
  bureauGroupName: string;
  name: string;
  positionName: string;
  isActive: boolean;
  isLock: boolean;
  updateDate: Date;
  updateByUserFullName: string;
};

type Loader = {
  data: Pagination<UserListResponse>;
  bureauSelection: ItemModel[];
  bureauGroupSelection: ItemModel[];
};

export default function M0103() {
  const navigate = useNavigate();
  const { data, bureauSelection, bureauGroupSelection } = useLoaderData() as Loader;
  const [criteria, setCriteria] = useState<M0103Criteria>({} as M0103Criteria);
  const submit = useSubmit();

  const onClear = () => {
    setCriteria({} as M0103Criteria);

    submit({
      size: 10,
      page: 1,
    });
  };

  const onCriteriaChange = (value: string | number, prop: string) => {
    setCriteria({ ...criteria, [prop]: value });
  };

  const status = (
    isActive: boolean,
    isLock: boolean,
  ) => {
    if (isActive) {
      if (isLock) {
        return 'ใช้งานอยู่';
      }

      return 'ปกติ';
    }

    return 'ระงับการใช้งาน';
  };

  const onSearch = (size: number, page: number) => {
    submit({
      size: size.toString(),
      page: page.toString(),
      criteria: JSON.stringify(criteria),
    });
  };

  const onSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSearch(data.size, data.page);
  };

  return (
    <Layout title="บุคลากร">
      <Card>
        <Form onSubmit={onSubmitSearch}>
          <Row>
            <Col sm={12} lg={3}>
              <Selector
                label="สำนัก"
                items={bureauSelection}
                value={criteria.bureauId}
                onChange={(value) => onCriteriaChange(value, 'bureauId')} />
            </Col>
            <Col sm={12} lg={3}>
              <Selector
                label="กลุ่มงาน"
                items={bureauGroupSelection}
                value={criteria.bureauGroupId}
                onChange={(value) => onCriteriaChange(value, 'bureauGroupId')} />
            </Col>
            <Col sm={12} lg={3}>
              <Input
                value={criteria.name}
                label="ชื่อ - นามสกุล"
                onChange={(value) => onCriteriaChange(value, 'name')} />
            </Col>
            <Col sm={12} lg={3}>
              <div className="criteria-flex button">
                <div className="button">
                  <Button
                    variant="primary"
                    type="submit">
                    <FaSearch className="me-2" />ค้นหา
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={onClear}>
                    <FaTrashAlt className="me-2" />ล้างค่า
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </Card>
      <div className="d-flex justify-content-end my-3">
        <Button variant="primary" type="button" onClick={() => navigate('detail')}>
          <FaPlus className="me-2" />เพิ่ม
        </Button>
      </div>
      <Row className="mt-3">
        <Col>
          <div className="table-relative-fix">
            <div className="table-scroll">
              <Table
                total={data.totalRows}
                page={data.page}
                size={data.size}
                onChange={onSearch}>
                <Table.Header>
                  <Table.Row>
                    <Table.Column minWidth={100}>ลำดับ</Table.Column>
                    <Table.Column minWidth={250}>สำนัก</Table.Column>
                    <Table.Column minWidth={250}>กลุ่มงาน</Table.Column>
                    <Table.Column minWidth={250}>ชื่อ - นามสกุล</Table.Column>
                    <Table.Column minWidth={250}>ตำแหน่ง</Table.Column>
                    <Table.Column minWidth={100}>สถานะ</Table.Column>
                    <Table.Column minWidth={250}>ผู้แก้ไขข้อมูล</Table.Column>
                    <Table.Column minWidth={200}>วันที่แก้ไขข้อมูล</Table.Column>
                    <Table.Column minWidth={100} className="fix-col" />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {data.rows?.map((row, i) => (
                    <Table.Row key={row.id}>
                      <Table.Cell center>{calculateRowNumber(i, data.page, data.size).toString()}</Table.Cell>
                      <Table.Cell center>{row.bureauName}</Table.Cell>
                      <Table.Cell center>{row.bureauGroupName}</Table.Cell>
                      <Table.Cell>{row.name}</Table.Cell>
                      <Table.Cell>{row.positionName}</Table.Cell>
                      <Table.Cell center>{status(row.isActive, row.isLock)}</Table.Cell>
                      <Table.Cell>{row.updateByUserFullName}</Table.Cell>
                      <Table.Cell center>{fullDateTime(row.updateDate)}</Table.Cell>
                      <Table.Cell center className="fix-col">
                        <FaEdit className="fs-5" onClick={() => navigate(`detail/${row.id}`)} />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}