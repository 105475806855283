import { SelectionType, ShareValue } from "constant";
import { LoaderFunctionArgs } from "react-router-dom";
import { c0201, selectionService } from "services";

export const c0201LoaderAsync = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 1;
  const size = url.searchParams.get('size') || 10;
  const criteria = JSON.parse(url.searchParams.get('criteria')!);

  const [s0206nInquiryMeeting] = await Promise.all([
    c0201.getCtReportMakerDailyListAsync(+page, +size, criteria),
  ]);

  return {
    data: {
      rows: s0206nInquiryMeeting.data.rows,
      totalRows: s0206nInquiryMeeting.data.totalRows,
      page: +page,
      size: +size,
    },
  };
}

export const c0201DetailLoaderAsync = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;

  if (id) {
    const [smBureau, smBureauGroup, smUser, positionType, dailyStatusType, ctReportMakerDaily] = await Promise.all([
      selectionService.getSelectionAsync(SelectionType.SM_BUREAU),
      selectionService.getSelectionAsync(SelectionType.SM_BUREAU_GROUP),
      selectionService.getSelectionAsync(SelectionType.SM_USER),
      selectionService.getShareValueAsync(ShareValue.POSITION_TYPE),
      selectionService.getShareValueAsync(ShareValue.DAILY_STATUS),
      c0201.getCtReportMakerDailyDetailAsync(id),
    ]);

    return {
      smBureauItems: smBureau.data,
      smBureauGroupItems: smBureauGroup.data,
      smUserItems: smUser.data,
      positionTypeItems: positionType.data,
      dailyStatusTypeItems: dailyStatusType.data,
      ctReportMakerDailyData: ctReportMakerDaily.data,
    };
  }

  const [smBureau, smBureauGroup, smUser, positionType, dailyStatusType] = await Promise.all([
    selectionService.getSelectionAsync(SelectionType.SM_BUREAU),
    selectionService.getSelectionAsync(SelectionType.SM_BUREAU_GROUP),
    selectionService.getSelectionAsync(SelectionType.SM_USER),
    selectionService.getShareValueAsync(ShareValue.POSITION_TYPE),
    selectionService.getShareValueAsync(ShareValue.DAILY_STATUS),
  ]);

  return {
    smBureauItems: smBureau.data,
    smBureauGroupItems: smBureauGroup.data,
    smUserItems: smUser.data,
    positionTypeItems: positionType.data,
    dailyStatusTypeItems: dailyStatusType.data,
  };
}