import { useEffect, useState } from "react";

export function Footer() {
  const [sidebarIsCollapsed, setSidebarIsCollapsed] = useState<boolean>(false);

  useEffect(() => {
    document.addEventListener('onSidebarIsCollapsed', (event) => {
      const customEvent = event as CustomEvent;

      setSidebarIsCollapsed(customEvent.detail);
    });
  }, []);

  return (
    <div className={`footer ${sidebarIsCollapsed ? 'collapsed' : ''}`}>
      ลิขสิทธิ์ © 2566 สำนักงานเลขาธิการวุฒิสภา | The Secretariat of the Senate
    </div>
  );
}