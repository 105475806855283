import http from 'utils/axios';

const signInAsync = async (username: string, password: string) => {
  return http.post('api/authentication/sign-in',
    { username, password },
    { headers: { isDisabledLoading: true } });
};

const signOutAsync = async (id: string) => {
  return await http.post(`api/authentication/sign-out/${id}`,
    null, { headers: { isDisabledLoading: true } });
};

const lockAsync = async (accessToken: string) => {
  return await http.post('api/authentication/lock',
    null,
    {
      headers: {
        isDisabledLoading: true,
        Authorization: `Bearer ${accessToken}`,
      },
    });
};

const getModuleAsync = async (token?: string) => {
  const accessToken = token ? `Bearer ${token}` : '';

  return await http.get('api/authentication/module',
    {
      headers: {
        isDisabledLoading: true,
        Authorization: accessToken,
      },
    });
};

const getUserAsync = async () => {
  return await http.get(`api/authentication/user`);
};

const getRoleProgramsAsync = async () => {
  return await http.get(`api/authentication/role-programs`);
};

const getMenusAsync = async (module: string) => {
  return await http.get(`api/authentication/menus`, { params: { module } });
};

const authenticationService = {
  signInAsync,
  signOutAsync,
  lockAsync,
  getModuleAsync,
  getUserAsync,
  getRoleProgramsAsync,
  getMenusAsync,
};

export default authenticationService;