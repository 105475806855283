import { Layout, ToolTipComment } from "components";
import { Button, DropdownButton, Dropdown, Row, Col, Collapse } from "react-bootstrap";
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaRegFilePdf, FaRegFileWord, FaTrashAlt, FaUndo } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TbFileDownload, TbFileCheck } from "react-icons/tb";
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { HttpStatusCode, StatusChapter, VideoControl } from "constant";
import { HiOutlineDocumentCheck } from "react-icons/hi2";
import { exportFilePDFAsync, exportFileWordAsync, showModalConfirmAsync, showModalRemoveAsync } from "utils";
import { ChapterDocuments, CtMakeDocumentChapterDetail, CtMakeDocumentDetailChapterList, CtMakeDocumentDetailHeader } from "models";
import Collabora, { CollaboraRef } from 'components/Document/Collabora';
import Video, { VideoOption } from 'components/Video/Video';
import { c0204, ctService } from "services";
import toast from "utils/toast";

export default function C0204Document() {
  const headerData = useLocation().state.data as CtMakeDocumentDetailChapterList;
  const meetingData = useLocation().state.meetingData as CtMakeDocumentDetailHeader;
  const [videoData, setVideoData] = useState<File>();
  const [ctMakeDocumentData, setCtMakeDocumentData] = useState<CtMakeDocumentChapterDetail>({} as CtMakeDocumentChapterDetail);
  const [documentSelected, setDocumentSelected] = useState<ChapterDocuments>({} as ChapterDocuments);
  const [canWrite, setCanWrite] = useState<boolean>();
  const [fileIds, setFileIds] = useState<string[]>([]);
  const [fileIndex, setFileIndex] = useState<number>(0);
  const { id, documentId } = useParams();
  const [open, setOpen] = useState(true);
  const [screenWidth, setScreenWidth] = useState(1200);
  const programName = useState<string>("จัดทำ/แก้ไข รายงานการประชุม");
  const navigate = useNavigate();

  useEffect(() => {
    getCtMakeDocumentDetailAsync();
  }, [documentId]);

  useEffect(() => {
    if (fileIds.length) {
      getVideoAsync(fileIds[0]);

      setFileIndex(0);
    } else {
      setVideoData(undefined);
    }
  }, [fileIds]);

  useEffect(() => {
    checkWidthScreen();
  }, []);

  const checkWidthScreen = useCallback(() => {
    window.onresize = function (event) {
      const widthScreen = (event.target as Window).innerWidth;

      setScreenWidth(widthScreen);
    };
  }, []);

  const collaboraRef = useRef<CollaboraRef>(null);

  const videoRef = useRef<VideoOption>({} as VideoOption);

  function loadCollabora() {
    const iframe = document.getElementById(documentSelected.id) as HTMLIFrameElement;

    iframe.contentWindow?.addEventListener('keydown', (e) => clickBtn(e.key));
  }

  function clickBtn(key: string) {
    switch (key) {
      case VideoControl.F10:
        videoRef.current.backRef();
        break;
      case VideoControl.F9:
        videoRef.current.playRef();
        break;
      case VideoControl.F11:
        videoRef.current.aheadRef();
        break;
    }
  }

  const canWriteDocument = useMemo(() => {
    const check = ctMakeDocumentData.status === StatusChapter.RECORDED ||
      ctMakeDocumentData.status === StatusChapter.DOCUMENT_MAKING ||
      ctMakeDocumentData.status === StatusChapter.SEND_EDIT_DOCUMENT ||
      ctMakeDocumentData.status === StatusChapter.ASSIGN_DUTY_OFFICER ||
      ctMakeDocumentData.status === StatusChapter.CONFIRM ||
      ctMakeDocumentData.status === StatusChapter.RECORD ||
      ctMakeDocumentData.status === StatusChapter.REJECT;

    return check;
  }, [ctMakeDocumentData]);

  const getCtMakeDocumentDetailAsync = async () => {
    if (documentId) {
      const { data, status } = await c0204.getCtMakeDocumentChapterDetailAsync(documentId);

      if (status === HttpStatusCode.OK) {
        const mapData = data as CtMakeDocumentChapterDetail;

        setDocumentSelected(mapData.ctChapterDocuments[0]);
        setCtMakeDocumentData(mapData);
        getFileIdAsync(mapData.id);

        setCanWrite(mapData.status === StatusChapter.ASSIGN_DUTY_OFFICER ||
          mapData.status === StatusChapter.RECORDED ||
          mapData.status === StatusChapter.DOCUMENT_MAKING ||
          mapData.status === StatusChapter.SEND_EDIT_DOCUMENT ||
          mapData.status === StatusChapter.CONFIRM ||
          mapData.status === StatusChapter.RECORD ||
          mapData.status === StatusChapter.REJECT);
      }
    }
  };

  const getTitleName = () => {
    if (meetingData.ctShortSubCommitteeName || meetingData.ctShortCommitteeName) {
      return `${meetingData.ctShortSubCommitteeName ?? meetingData.ctShortCommitteeName} ตอนที่ ${headerData.chapter} ผู้จดรายงาน : ${headerData.dutyOfficerFullName}`;
    }

    return `ตอนที่ ${headerData.chapter} ผู้จดรายงาน : ${headerData.dutyOfficerFullName}`;
  };

  const onSaveDraftAsync = async () => {
    await updateCtMakeDocumentStatusAsync(StatusChapter.DOCUMENT_MAKING, 'บันทึกข้อมูลสำเร็จ');
  };

  const onSendReportAsync = async () => {
    if (!await showModalConfirmAsync('ต้องการยืนยันการส่งรายงานหรือไม่', 'ส่งรายงาน', <HiOutlineDocumentCheck className="my-1 text-success" style={{ fontSize: 80 }} />)) {
      return;
    }

    await updateCtMakeDocumentStatusAsync(StatusChapter.DOCUMENT_COMPLETE, 'ส่งรายงานสำเร็จ');
  };

  const updateCtMakeDocumentStatusAsync = async (statusUpdate: StatusChapter, textAnnounce: string) => {
    if (documentId) {
      const { status } = await c0204.updateMakeDocumentChapterAsync(documentId, statusUpdate);

      if (status === HttpStatusCode.ACCEPTED) {
        toast.success(textAnnounce);

        getCtMakeDocumentDetailAsync();
      }
    }
  };

  const onChangeDocumentVersion = (value: string | null) => {
    if (value) {
      const findDocument = ctMakeDocumentData.ctChapterDocuments.find(f => f.id == value);

      setDocumentSelected(findDocument ? findDocument : {} as ChapterDocuments);

      if (findDocument !== ctMakeDocumentData.ctChapterDocuments[0]) {
        setCanWrite(false);

        return;
      }

      if (canWriteDocument) {
        setCanWrite(true);

        return;
      }
    }
  };

  const getVideoAsync = async (id: string) => {
    const { data, status } = await ctService.getVideoAsync(id);

    if (status === HttpStatusCode.OK) {
      setVideoData(data);
    }
  };

  const getFileIdAsync = async (fileId: string) => {
    const { data, status } = await ctService.getRecordFileIdAsync(fileId, id!);

    if (status === HttpStatusCode.OK) {
      setFileIds(data);
    }
  };

  const onEndedAsync = async () => {
    const currentIndex = fileIndex + 1;

    if (fileIds.length > currentIndex) {
      getVideoAsync(fileIds[currentIndex]);

      setFileIndex(currentIndex);
    } else {
      setFileIndex(0);
    }
  };

  const onUploadVideoAsync = async (fileUpload: File) => {
    await c0204.uploadDocumentAsync(id!, documentId!, fileUpload);

    toast.success("อัพโหลดวิดิโอสำเร็จ");
    getFileIdAsync(documentId!);
  };

  useMemo(() => {
    if (ctMakeDocumentData.ctChapterDocuments) {
      const lastVersion = ctMakeDocumentData.ctChapterDocuments[0];

      return documentSelected.id === lastVersion.id && (ctMakeDocumentData.status === StatusChapter.RECORDED || ctMakeDocumentData.status === StatusChapter.DOCUMENT_MAKING);
    }
  }, [documentSelected, ctMakeDocumentData]);

  const selectVersion = (data: ChapterDocuments) => {
    setDocumentSelected(data);
    loadCollabora();
  };

  const docDefault = useMemo(() => {
    if (ctMakeDocumentData.ctChapterDocuments) {
      return ctMakeDocumentData.ctChapterDocuments[0];
    }

    return null;
  }, [ctMakeDocumentData.ctChapterDocuments]);

  const removeDocAsync = async () => {
    if (!await showModalRemoveAsync()) {
      return;
    }

    const { status } = await c0204.removeDocAsync(documentSelected.id);

    if (status === HttpStatusCode.NO_CONTENT) {
      await getCtMakeDocumentDetailAsync();

      toast.success('ลบเอกสารสำเร็จ');
    }
  };

  const isDisabledRemoveDoc = useMemo(() => {
    const index = ctMakeDocumentData.ctChapterDocuments?.findIndex(d => d.id === documentSelected.id);

    return index === 0;
  }, [documentSelected]);

  return (
    <Layout className="c0204-detail" title={programName[0]} subTitle={getTitleName()}>
      <div className="d-flex my-3 gap-2 flex-wrap">
        <Button
          className='me-auto'
          variant="outline-primary"
          type="button"
          onClick={() => navigate(`/c0204/detail/${id}`)}>
          <FaAngleLeft className="me-2" />ย้อนกลับ
        </Button>
        <Button
          variant="outline-primary" onClick={() => exportFilePDFAsync(documentSelected.id, programName[0])}>
          <FaRegFilePdf className="me-2" />Export PDF
        </Button>
        <Button
          variant="outline-primary"
          onClick={() => exportFileWordAsync(documentSelected.id, programName[0])}>
          <FaRegFileWord className="me-2" />Export Word
        </Button>
        <DropdownButton
          title={<span><FaUndo className="me-2" />เวอร์ชั่นก่อนหน้า ({documentSelected.versionDocument})</span>}
          drop="down-centered"
          variant="outline-primary"
          onSelect={(value) => onChangeDocumentVersion(value)}>
          {ctMakeDocumentData.ctChapterDocuments?.map((data) => (
            <Dropdown.Item
              eventKey={data.id}
              key={data.id}
              onClick={() => selectVersion(data)}>
              {data.versionDocument}
            </Dropdown.Item>
          ))}
        </DropdownButton>
        <Button
          variant="danger"
          onClick={removeDocAsync}
          disabled={isDisabledRemoveDoc}>
          <FaTrashAlt className="me-2" />ลบเอกสาร
        </Button>
        {(canWriteDocument) && <>
          <Button
            variant="outline-primary"
            type="button"
            onClick={onSaveDraftAsync}>
            <TbFileDownload className="me-2" />บันทึกเวอร์ชั่น
          </Button>
          <Button
            variant="primary"
            type="button"
            onClick={onSendReportAsync}>
            <TbFileCheck className="me-2" />ส่งรายงาน
          </Button>
        </>}
      </div>
      <Row>
        <Collapse in={open} dimension="width" timeout={50}>
          <Col xl={3}>
            {ctMakeDocumentData.status === StatusChapter.SEND_EDIT_DOCUMENT && <ToolTipComment subject={documentSelected.comment ?? "ไม่มีความคิดเห็น"} />}
            <Video
              onEnded={onEndedAsync}
              ref={videoRef}
              files={videoData?.type ? [videoData] : []}
              showPlayButton={true}
              showPlayTimeConfig={true}
              showSpeakToText={true}
              uploadVideo
              uploadSecretVideo
              uploadVideoData={(fileData) => onUploadVideoAsync(fileData)}
              showGuideCollabora
              getUrlLink />
          </Col>
        </Collapse>
        {(open && screenWidth > 1199) ? <div className='collapse-video' onClick={() => setOpen(!open)}>
          <div>
            <FaAngleDoubleLeft />
          </div>
        </div> : <></>}
        <Col>
          {(!open && screenWidth > 1199) ? <div className='show-video' onClick={() => setOpen(!open)}>
            <div>
              <FaAngleDoubleRight />
            </div>
          </div> : <></>}
          {docDefault ?
            <Collabora
              docId={documentSelected.id}
              docName="c0204"
              height="1000px"
              ref={collaboraRef}
              onCollaboraLoaded={() => loadCollabora()}
              canWrite={canWrite} /> : null}
        </Col>
      </Row>
    </Layout>
  );
}