import { Card, FileValue, Layout, Modal, TextArea, UploadFile, VideoOption } from "components";
import { Button, DropdownButton, Dropdown, Col, Row, Form } from "react-bootstrap";
import { FaAngleLeft, FaFileAlt, FaRegFilePdf, FaUndo } from "react-icons/fa";
import { useLoaderData, useNavigate, useParams, useSubmit } from "react-router-dom";
import { TbFileCheck, TbFileX } from "react-icons/tb";
import { useEffect, useMemo, useRef, useState } from 'react';
import { CtCertifyDocumentDetail, CtCertifyDocuments } from "models";
import { HttpStatusCode, StatusMeeting, VideoControl } from "constant";
import { convertNumberToCurrency, exportFilePDFAsync, formatDateTh, formatTimeToInput, getFile, showModalConfirmAsync, submitForm, yearToTHFormat } from "utils";
import { c0207, s0206 } from "services";
import { HiOutlineDocumentCheck } from "react-icons/hi2";
import Collabora, { CollaboraRef } from "components/Document/Collabora";
import toast from "utils/toast";

type Loader = { ctMakeDocumentDetail: CtCertifyDocumentDetail };

export default function C0207Document() {
  const { ctMakeDocumentDetail } = useLoaderData() as Loader;
  const [documentSelected, setDocumentSelected] = useState<CtCertifyDocuments>({} as CtCertifyDocuments);
  const [showModal, setShowModal] = useState(false);
  const [lastVersion, setLastVersion] = useState("");
  const programName = useState<string>("ตรวจสอบและรับรายงานการประชุม");
  const { id } = useParams();
  const navigate = useNavigate();
  const submit = useSubmit();

  useEffect(() => {
    setDocumentSelected(ctMakeDocumentDetail.ctDocuments ? ctMakeDocumentDetail.ctDocuments[0] : {} as CtCertifyDocuments);
    setLastVersion((ctMakeDocumentDetail.ctDocuments ? (ctMakeDocumentDetail.ctDocuments[0].versionDocument).toString() : ""));
  }, [ctMakeDocumentDetail]);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const collaboraRef = useRef<CollaboraRef>(null);

  const videoRef = useRef<VideoOption>({} as VideoOption);

  function loadCollabora() {
    const iframe = document.getElementById(documentSelected.id) as HTMLIFrameElement;

    iframe.contentWindow?.addEventListener('keydown', (e) => clickBtn(e.key));
  }

  function clickBtn(key: string) {
    switch (key) {
      case VideoControl.F10:
        videoRef.current.backRef();
        break;
      case VideoControl.F9:
        videoRef.current.playRef();
        break;
      case VideoControl.F11:
        videoRef.current.aheadRef();
        break;
    }
  }

  const onGuaranteeAsync = async () => {
    if (!await showModalConfirmAsync('ต้องการรับรองรายงานหรือไม่', 'รับรองรายงาน', <HiOutlineDocumentCheck className="my-1 text-success" style={{ fontSize: 80 }} />)) {
      return;
    }

    await updateStatusAsync(StatusMeeting.GUARANTEE, 'อนุมัติสำเร็จ');
  };

  const updateStatusAsync = async (statusUpdate: StatusMeeting, textAnnounce: string) => {
    if (id) {
      const { status } = await c0207.updateStatusCtCertifyDocumentAsync(id, statusUpdate);

      if (status === HttpStatusCode.ACCEPTED) {
        toast.success(textAnnounce);

        submit({});
      }
    }
  };

  const onChangeDocumentVersion = (value: string | null) => {
    if (value) {
      const findDocument = ctMakeDocumentDetail.ctDocuments.find(f => f.id == value);

      setDocumentSelected(findDocument ? findDocument : {} as CtCertifyDocuments);
      setLastVersion(findDocument ? findDocument.versionDocument.toString() : "");
    }
  };

  const documents = useMemo(() => {
    return ctMakeDocumentDetail.documents?.map(d => ({ id: d.id, fileName: d.fileName, sequence: d.sequence })) as FileValue[];
  }, [ctMakeDocumentDetail.documents]);

  const setDefaultDocumentOverview = () => {
    if (documentSelected === ctMakeDocumentDetail.ctDocuments[0]) {
      return toast.success('แสดงรายงานที่รวบรวมแล้ว');
    };

    setDocumentSelected(ctMakeDocumentDetail.ctDocuments ? ctMakeDocumentDetail.ctDocuments[0] : {} as CtCertifyDocuments);
    setLastVersion((ctMakeDocumentDetail.ctDocuments ? (ctMakeDocumentDetail.ctDocuments[0].versionDocument).toString() : ""));
  };

  const dowloadFileAsync = async (id: string, fileName?: string) => {
    const { data, status } = await s0206.downloadFileAsync(id);

    if (status === HttpStatusCode.OK) {
      getFile(data, fileName);
    }
  };

  return (
    <Layout title={programName[0]} className="c0207-detail">
      <div className="d-flex justify-content-between my-3">
        <Button
          variant="outline-primary"
          type="button"
          onClick={() => navigate('/c0207')}>
          <FaAngleLeft className="me-2" />ย้อนกลับ
        </Button>
        <div className="d-flex gap-1">
          <Button
            variant="outline-primary"
            onClick={setDefaultDocumentOverview}>
            <FaFileAlt className="me-2" /> ดูรายงานที่รวบรวม
          </Button>
          <DropdownButton
            title={<span><FaUndo className="me-2" />เวอร์ชั่นก่อนหน้า ({lastVersion})</span>}
            drop="down-centered"
            variant="outline-primary"
            onSelect={(value) => onChangeDocumentVersion(value)}>
            {ctMakeDocumentDetail.ctDocuments?.map((data, index) => (
              <Dropdown.Item eventKey={data.id} key={index}>{data.versionDocument}</Dropdown.Item>
            ))}
          </DropdownButton>
          <Button
            variant="outline-primary"
            type="button"
            onClick={() => exportFilePDFAsync(documentSelected.id, programName[0])}>
            <FaRegFilePdf className="me-2" />Export PDF
          </Button>
          {ctMakeDocumentDetail.status === StatusMeeting.OVERVIEW_REVIEWED && <>
            <Button
              variant="outline-primary"
              type="button"
              onClick={() => handleShowModal()}>
              <TbFileX className="me-2" />ไม่รับรายงาน
            </Button>
            <Button
              variant="primary"
              type="button"
              onClick={onGuaranteeAsync}>
              <TbFileCheck className="me-2" />รับรายงาน
            </Button>
          </>}
        </div>
      </div>
      <Row>
        <Col xl={4}>
          <ManageVideo />
          <UploadFile
            value={documents}
            disabled
            canEdit
            dowloadFile={(id, fileName) => dowloadFileAsync(id, fileName)}
            onEditDocument={(value) => setDocumentSelected({ ...documentSelected, id: value })} />
        </Col>
        <Col xl={8}>
          <Collabora
            docId={documentSelected.id}
            docName="s0204"
            height="1000px"
            ref={collaboraRef}
            onCollaboraLoaded={() => loadCollabora()} />
        </Col>
      </Row>
      <ModalDisApprove showModal={showModal} onHide={() => setShowModal(false)} />
    </Layout>
  );
}

interface ModalProps {
  showModal: boolean;
  onHide: () => void;
}

function ModalDisApprove(props: ModalProps) {
  const [comment, setComment] = useState("");
  const { id } = useParams();
  const submit = useSubmit();

  const onRejectAsync = async () => {
    submitForm();

    if (!comment) {
      return;
    }

    const { status } = await c0207.updateStatusCtCertifyDocumentAsync(id!, StatusMeeting.REJECT, comment);

    if (status === HttpStatusCode.ACCEPTED) {
      toast.success('ไม่รับรองรายงานสำเร็จ');

      onHide();
      submit({});
    }
  };

  const onHide = () => {
    setComment("");

    props.onHide();
  };

  return (
    <Modal
      show={props.showModal}
      title="ไม่รับรองรายงาน">
      <TextArea
        label="ให้ความคิดเห็น"
        rule={{ required: true }}
        value={comment}
        onChange={(value) => setComment(value)} />
      <div className="d-flex justify-content-center gap-4">
        <Button
          type="button"
          className="w-25"
          variant="outline-primary"
          onClick={onHide}>
          ยกเลิก
        </Button>
        <Button
          type="button"
          className="w-25"
          onClick={onRejectAsync}>
          ยืนยัน
        </Button>
      </div>
    </Modal>
  );
}

function ManageVideo() {
  const { ctMakeDocumentDetail } = useLoaderData() as Loader;

  return (
    <>
      <Card className="mt-2">
        <Form.Label>รายละเอียดรายงานการประชุม</Form.Label>
        <Row className="mx-3">
          <Col xl={12}>
            <p>สำนัก : {ctMakeDocumentDetail.ctBureauName}</p>
          </Col>
          <Col xl={12}>
            <p>คณะกรรมาธิการ/อื่นๆ : {ctMakeDocumentDetail.ctCommitteeName}</p>
          </Col>
          <Col xl={12}>
            <p>คณะอนุกรรมาธิการ/คณะทำงาน : {ctMakeDocumentDetail.ctSubCommitteeName}</p>
          </Col>
          <Col xl={6}>
            <p>ครั้งที่ : {ctMakeDocumentDetail.time}</p>
          </Col>
          <Col xl={6}>
            <p>ปี : {yearToTHFormat(ctMakeDocumentDetail.year)}</p>
          </Col>
          <Col xl={12}>
            <p>ณ ห้องประชุม : {ctMakeDocumentDetail.smMeetingRoomName}</p>
          </Col>
          <Col xl={6}>
            <p>วันที่ประชุม : {formatDateTh(ctMakeDocumentDetail.meetingDate)}</p>
          </Col>
          <Col xl={6}>
            <p>เวลา : {`${formatTimeToInput(ctMakeDocumentDetail.startTime)}-${formatTimeToInput(ctMakeDocumentDetail.endTime)}`}</p>
          </Col>
          <Col xl={12}>
            <p>จำนวนหน้ากระดาษรายงานการประชุม : {convertNumberToCurrency(ctMakeDocumentDetail.numberOfPages ?? 0)} หน้า</p>
          </Col>
        </Row>
      </Card>
    </>
  );
}