import { Card, Input, InputDate, Layout, Selector, Status, StatusType, Table } from "components";
import { ItemModel, MeetingRecordCriteria, MeetingRecordListModel, Pagination } from "models";
import { Dispatch, FormEvent, SetStateAction, createContext, useContext, useMemo, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaRegFileAlt, FaSearch, FaTrashAlt } from "react-icons/fa";
import { useLoaderData, useNavigate, useSubmit } from "react-router-dom";
import { calculateRowNumber, fullDateTime, getFormatDateBC, getTime } from "utils";
import { StatusMeeting, statusMeetingItems } from "constant";

type MeetingRecordContext = {
  criteria: MeetingRecordCriteria;
  setCriteria: Dispatch<SetStateAction<MeetingRecordCriteria>>;
  onSearch: (page: number, size: number, criteria?: MeetingRecordCriteria) => void
  onChangePageSize: Function;
  onClear: () => void;
};

const Context = createContext({} as MeetingRecordContext);

type Loader = {
  meetingTypeDDL: ItemModel[],
  meetingPeriodDDL: ItemModel[],
  yearsDDL: ItemModel[],
  meetingRecordList: Pagination<MeetingRecordListModel>,
};

export default function S0202() {
  const [criteria, setCriteria] = useState<MeetingRecordCriteria>({} as MeetingRecordCriteria);
  const submit = useSubmit();

  const onSearch = (page: number, size: number, criteria?: MeetingRecordCriteria) => {
    submit({
      page,
      size,
      criteria: JSON.stringify(criteria),
    });
  };

  const onClear = () => {
    setCriteria({} as MeetingRecordCriteria);

    submit(null);
  };

  const onChangePageSize = (size: number, page: number) => {
    submit({
      page: page,
      size: size,
      criteria: JSON.stringify(criteria),
    });
  };

  const contextValue = useMemo(() => {
    return {
      criteria,
      setCriteria,
      onSearch,
      onChangePageSize,
      onClear,
    }
  }, [criteria, setCriteria, onSearch, onChangePageSize, onClear]);

  return (
    <Context.Provider value={contextValue}>
      <Layout title="บันทึกข้อมูลภาพและเสียงการประชุม">
        <Criteria />
        <DataTable />
      </Layout>
    </Context.Provider>
  );
}

function Criteria() {
  const { meetingRecordList, meetingTypeDDL, meetingPeriodDDL, yearsDDL, } = useLoaderData() as Loader;
  const { criteria, setCriteria, onClear } = useContext(Context);
  const submit = useSubmit();

  const statusItems = [
    StatusMeeting.CONFIRM,
    StatusMeeting.RECORD,
    StatusMeeting.RECORDED,
  ];

  const filterStatus = useMemo(() => statusMeetingItems.filter(s => statusItems.includes(s.value)), [statusMeetingItems]);

  const onChange = (prop: keyof MeetingRecordCriteria, value?: string | number | Date) => {
    setCriteria({ ...criteria, [prop]: value });
  };

  const onSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    submit({
      page: meetingRecordList.page,
      size: meetingRecordList.size,
      criteria: JSON.stringify(criteria),
    });
  };

  return (
    <Card>
      <Form onSubmit={onSubmitSearch}>
        <Row>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Selector
              name="meetingTypeId"
              label="ประเภทการประชุม"
              placeholder="ประเภทการประชุม"
              items={meetingTypeDDL}
              value={criteria.meetingTypeId}
              onChange={(value) => onChange("meetingTypeId", value)} />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Input
              name="time"
              label="ครั้งที่ประชุม"
              placeholder="ครั้งที่"
              type="number"
              value={criteria.time}
              onChange={(value) => onChange("time", value)} />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Selector
              name="periodId"
              label="สมัยการประชุม"
              placeholder="สมัยการประชุม"
              items={meetingPeriodDDL}
              value={criteria.periodId}
              onChange={(value) => onChange("periodId", value)} />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Input
              name="name"
              label="ชื่อการประชุม"
              placeholder="ชื่อการประชุม"
              value={criteria.name}
              onChange={(value) => onChange("name", value)} />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Selector
              name="year"
              label="ปี"
              placeholder="ปี"
              items={yearsDDL}
              value={criteria.year}
              onChange={(value) => onChange("year", value)} />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <InputDate
              label="วันที่ประชุม"
              value={criteria.meetingDate}
              onChange={(value) => onChange("meetingDate", value)} />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Selector
              label="สถานะ"
              placeholder="สถานะ"
              items={filterStatus}
              value={criteria.status}
              onChange={(value) => onChange("status", value)} />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="d-flex gap-2 justify-content-start">
            <Button
              variant="primary"
              type="submit">
              <FaSearch className="me-2" />ค้นหา
            </Button>
            <Button
              variant="outline-primary"
              type="button"
              onClick={onClear}>
              <FaTrashAlt className="me-2" />ล้างค่า
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

function DataTable() {
  const { meetingRecordList } = useLoaderData() as Loader;
  const { onSearch, criteria } = useContext(Context);
  const navigate = useNavigate();

  return (
    <div className="table-relative-fix">
      <div className="table-scroll">
        <Table
          className='mt-3'
          total={meetingRecordList.totalRows}
          page={meetingRecordList.page}
          size={meetingRecordList.size}
          onChange={(size, page) => onSearch(page, size, criteria)}>
          <Table.Header>
            <Table.Row>
              <Table.Column minWidth={100}>ลำดับ</Table.Column>
              <Table.Column minWidth={150}>ประเภทการประชุม</Table.Column>
              <Table.Column minWidth={150}>ชื่อการประชุม</Table.Column>
              <Table.Column minWidth={100}>ครั้งที่</Table.Column>
              <Table.Column minWidth={300}>สมัยการประชุม</Table.Column>
              <Table.Column minWidth={300}>วันที่ประชุม</Table.Column>
              <Table.Column minWidth={150}>สถานะ</Table.Column>
              <Table.Column minWidth={200}>ผู้แก้ไขข้อมูล</Table.Column>
              <Table.Column minWidth={200}>วันที่แก้ไขข้อมูล</Table.Column>
              <Table.Column minWidth={150} className="fix-col" />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {meetingRecordList.rows?.map((value, index) => (
              <Table.Row key={value.id}>
                <Table.Cell center>{calculateRowNumber(index, meetingRecordList.page, meetingRecordList.size).toString()}</Table.Cell>
                <Table.Cell >{value.meetingType}</Table.Cell>
                <Table.Cell >{value.name}</Table.Cell>
                <Table.Cell center>{value.time}</Table.Cell>
                <Table.Cell center>{value.meetingPeriod}</Table.Cell>
                <Table.Cell center>
                  {`${getFormatDateBC(value.meetingDate)} ${getTime(value.startTime)} - ${getTime(value.endTime)} น.`}
                </Table.Cell>
                <Table.Cell center>{<Status type={StatusType.MEETING_STATUS} value={value.status} />}</Table.Cell>
                <Table.Cell>{value.updateByUserFullName}</Table.Cell>
                <Table.Cell center>{fullDateTime(value.updateDate)}</Table.Cell>
                <Table.Cell center className="fix-col">
                  <div className="d-flex justify-content-center">
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={() => navigate(`detail/${value.id}`)}>
                      <FaRegFileAlt className="me-2" />ดูรายละเอียด
                    </Button>
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
}