import { useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import { User } from "models";
import { signOutAsync } from "utils";
import { isExpired } from "react-jwt";
import { authenticationService } from "services";
import { Cookie, HttpStatusCode } from "constant";
import toast from "utils/toast";
import cookie from "utils/cookie";
import Hls from "hls.js";

export default function App() {
  const [isSignedIn, setIsSignedIn] = useState<boolean>(false);
  const [user, setUser] = useState<User>({} as User);
  const [rolePrograms, setRolePrograms] = useState<string[]>();
  const [currentModule, setCurrentModule] = useState<string>();
  const [allModules, setAllModules] = useState<string[]>();
  const hlsRef = useRef<Hls>(new Hls());

  // check signed in
  useEffect(() => {
    if (isSignedIn) {
      getUserAsync();
      getRoleProgramsAsync();
      getModuleAsync();

      return;
    }

    const accessToken = cookie.get(Cookie.ACCESS_TOKEN);

    if (accessToken && !isExpired(accessToken)) {
      setIsSignedIn(true);
    }
  }, [isSignedIn]);

  const getUserAsync = async () => {
    const res = await authenticationService.getUserAsync();

    if (res?.status !== HttpStatusCode.OK) {
      return;
    }

    const user = {
      ...res.data.user,
      isCommander: res.data.isCommander,
      isAdmin: res.data.isAdmin,
      isCommissionAndRoom: res.data.isCommissionAndRoom,
    };

    setUser(user);
  };

  const getRoleProgramsAsync = async () => {
    const res = await authenticationService.getRoleProgramsAsync();

    if (res?.status !== HttpStatusCode.OK) {
      return;
    }

    setRolePrograms(res.data);
  };

  const getModuleAsync = async () => {
    const res = await authenticationService.getModuleAsync();

    if (res.status === HttpStatusCode.UNAUTHORIZED) {
      toast.error('กรุณาเข้าสู่ระบบ');

      setIsSignedIn(false);
      signOutAsync();
    }

    if (res?.status !== HttpStatusCode.OK) {
      return;
    }

    const modules = res.data as string[];

    setAllModules(modules);

    const currentModule = cookie.get(Cookie.MODULE);

    setCurrentModule(currentModule);
  };

  const context = {
    setIsSignedIn,
    user,
    rolePrograms,
    currentModule,
    setCurrentModule,
    allModules,
    setAllModules,
    hlsRef,
  };

  return (
    <Outlet context={context} />
  );
}
