import { Layout, Card, Input } from "components";
import { Button, Col, Row, Form } from "react-bootstrap";
import { useState, FormEvent, useEffect } from "react";
import { FaSave } from "react-icons/fa";
import { submitForm } from "utils";
import { SmVideoPeriod } from "models/m0104";
import { useLoaderData } from "react-router";
import { m0104 } from "services";
import { HttpStatusCode } from "constant";
import toast from "utils/toast";
import { useSubmit } from "react-router-dom";

type Loader = { smVideoPeriodData: SmVideoPeriod };

export default function M0104() {
  const { smVideoPeriodData } = useLoaderData() as Loader;
  const [smVideoPeriod, setSmVideoPeriod] = useState<SmVideoPeriod>({} as SmVideoPeriod);
  const submit = useSubmit();

  useEffect(() => {
    if (smVideoPeriodData) {
      setSmVideoPeriod(smVideoPeriodData);
    }
  }, [smVideoPeriodData]);

  const handleOnSubmit = (event: FormEvent<HTMLElement>) => {
    event.preventDefault();

    submitForm({
      senateTimePeriod: smVideoPeriod.senateTimePeriod,
      senateTimeBetween: smVideoPeriod.senateTimeBetween,
      senateTimeCutting: smVideoPeriod.senateTimeCutting
    });

    if (smVideoPeriod.senateTimePeriod
      && smVideoPeriod.senateTimeBetween
      && smVideoPeriod.senateTimeCutting) {
      (async () => {
        const { status } = await m0104.updateSmVideoPeriodAsync(smVideoPeriod);

        if (status === HttpStatusCode.ACCEPTED) {
          toast.success('แก้ไขข้อมูลสำเร็จ');

          submit({});
        }
      })();
    }
  };

  const onChangePeriod = (value: number, prop: string) => {
    setSmVideoPeriod({ ...smVideoPeriod, [prop]: value });
  };

  return (
    <Layout title="กำหนดการบันทึกภาพ">
      <Form onSubmit={handleOnSubmit}>
        <div className="d-flex justify-content-end">
          <Button
            variant="primary"
            type="submit">
            <FaSave className="me-2" /> บันทึก
          </Button>
        </div>
        <div className="mt-3">
          <Card>
            <Form.Label>การประชุมวุฒิสภา/นิติบัญญัติ</Form.Label>
            <Row>
              <Col className="d-flex gap-4 mx-5">
                <Input
                  type="number"
                  className="w-25"
                  name="senateTimePeriod"
                  label="ช่วงเวลาของแต่ละตอน / นาที"
                  value={smVideoPeriod.senateTimePeriod}
                  rule={{ required: true, maxValue: 59.59, minValue: 0 }}
                  onChange={(value) => onChangePeriod(+value, 'senateTimePeriod')} />
                <Input
                  type="number"
                  className="w-25"
                  name="senateTimeBetween"
                  label="ระยะเวลาเหลื่อมระหว่างตอน / วินาที"
                  value={smVideoPeriod.senateTimeBetween}
                  rule={{ required: true, maxValue: 59.59, minValue: 0 }}
                  onChange={(value) => onChangePeriod(+value, 'senateTimeBetween')} />
                <Input
                  className="w-25"
                  name="senateTimeCutting"
                  label="ระยะเวลาในการตัด / นาที"
                  value={smVideoPeriod.senateTimeCutting}
                  rule={{ required: true, maxValue: 59.59, minValue: 0 }}
                  onChange={(value) => onChangePeriod(+value, 'senateTimeCutting')} />
              </Col>
            </Row>
          </Card>
        </div>
      </Form>
    </Layout>
  );
}