import { Layout, Card, InputDate, Table, Chart as Charts, Modal, Status, StatusType, Tabs } from "components";
import { Form, Col, Row, Button, Tab } from "react-bootstrap";
import { FaFilter, FaRegFileExcel, FaRegFilePdf, FaRegFileWord, FaTimes, FaTrashAlt } from "react-icons/fa";
import { DataList, OverviewChart, PaginationDashBoard, Chart, DashBoardDetail } from "models";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SHORT_MONTHS } from "data";
import { ChartBarType, Cookie, Module } from 'constant'
import { d01Service } from "services";
import { HttpStatusCode } from "axios";
import { exportFileExcel, exportFileWord, exportPdfByte, formatDateTh } from "utils";
import toast from "utils/toast";
import cookie from "utils/cookie";
import { useModule } from "hooks";

export enum DataTableType {
  Senate,
  Committee,
  CommitteeBureau,
};

type Loader = {
  senateMeetingLoader: PaginationDashBoard<DataList>,
  committeeMeetingLoader: PaginationDashBoard<DataList>,
  committeeBureauLoader: PaginationDashBoard<DataList>,
  senateMeetingChartLoader: OverviewChart[],
  committeeMeetingChartLoader: OverviewChart[],
};

const getLastDayOfMonth = (year: number, month: number) => {
  const nextMonthDate = new Date(year, month + 1, 1);
  const lastDayOfMonth = new Date(nextMonthDate.getTime() - 1);

  return lastDayOfMonth.getDate();
};

const convertMonthYear = (month: number, year: number) => {
  const getMonth = SHORT_MONTHS[month - 1];
  const thYear = `${year + 543}`;
  const getYear = thYear.substring(thYear.length - 2);

  return (`${getMonth} ${getYear}`);
};

const setDefaultData = (data: OverviewChart[], setData: Dispatch<SetStateAction<OverviewChart[]>>) => {
  if (data.length === 0) {
    setData([]);

    return;
  }

  const dataList = [...data];

  if (dataList.length === 12) {
    return;
  }

  const x = 12 - dataList.length;

  for (let i = 0; i < x; i++) {
    const newData = {} as OverviewChart;

    newData.month = dataList[0].month - 1 > 0 ? dataList[0].month - 1 : (13 - 1);
    newData.year = dataList[0].month - 1 > 0 ? dataList[0].year : dataList[0].year - 1;
    newData.guarantee = 0;
    newData.collect = 0;
    newData.inprogress = 0;

    dataList.unshift(newData);
  }

  setData(dataList);
};

const startDateCurrent = new Date();
const month = startDateCurrent.getMonth();
startDateCurrent.setMonth(month - 11);
startDateCurrent.setDate(1);

const endDateCurrent = new Date();
endDateCurrent.setDate(getLastDayOfMonth(endDateCurrent.getFullYear(), endDateCurrent.getMonth()));

const TABS = [
  { label: 'ภาพรวม', value: 1 },
  { label: 'วุฒิสภา', value: 2 },
  { label: 'กรรมาธิการ', value: 3 },
];

export default function D01() {
  return (
    <Layout title="ภาพรวมรายงานการประชุม">
      <Card>
        <Tabs items={TABS} defaultActiveKey={1}>
          <Tab.Content>
            <Tab.Pane eventKey={1}>
              <SummaryTab />
            </Tab.Pane>
            <Tab.Pane eventKey={2}>
              <SenateTab />
            </Tab.Pane>
            <Tab.Pane eventKey={3}>
              <CommitteeTab />
            </Tab.Pane>
          </Tab.Content>
        </Tabs>
      </Card>
    </Layout>
  );
}

function SummaryTab() {
  const { senateMeetingChartLoader, senateMeetingLoader, committeeMeetingChartLoader, committeeMeetingLoader, committeeBureauLoader } = useLoaderData() as Loader;
  const [startDate, setStartDate] = useState<Date>(startDateCurrent);
  const [endDate, setEndDate] = useState<Date>(endDateCurrent);
  const [senateMeetingChart, setSenateMeetingChart] = useState<OverviewChart[]>([]);
  const [senateMeetingDataTable, setSenateMeetingDataTable] = useState<PaginationDashBoard<DataList>>({} as PaginationDashBoard<DataList>);
  const [committeeMeetingChart, setCommitteeMeetingChart] = useState<OverviewChart[]>([]);
  const [committeeMeetingDataTable, setCommitteeMeetingDataTable] = useState<PaginationDashBoard<DataList>>({} as PaginationDashBoard<DataList>);
  const [committeeBureauGroupDataTable, setCommitteeBureauGroupDataTable] = useState<PaginationDashBoard<DataList>>({} as PaginationDashBoard<DataList>);


  useEffect(() => {
    setSenateMeetingChart(senateMeetingChartLoader);
    setSenateMeetingDataTable(senateMeetingLoader);
    setCommitteeMeetingChart(committeeMeetingChartLoader);
    setCommitteeMeetingDataTable(committeeMeetingLoader);
    setCommitteeBureauGroupDataTable(committeeBureauLoader);
  }, [senateMeetingChartLoader, committeeMeetingChartLoader, senateMeetingLoader, committeeMeetingLoader, committeeBureauLoader]);

  const onClear = () => {
    setStartDate(startDateCurrent);
    setEndDate(endDateCurrent);
    onSubmitAsync(startDateCurrent, endDateCurrent);
  };

  const onSubmitAsync = async (startDate: Date, endDate: Date) => {
    if (startDate && endDate) {
      if (startDate > endDate) {
        toast.warn("วันที่เริ่มต้นมีค่ามากกว่าวันที่สิ้นสุด");

        return;
      }
    }

    if (startDate && endDate) {
      const durationInMonths = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());

      if (durationInMonths >= 12) {
        toast.warn("เลือกวันที่เริ่มต้นและสิ้นสุดห่างกันได้ไม่เกิน 12 เดือน");

        return;
      }
    }

    const [senateChart, senateDataTable, committeeChart, committeeDataTable, committeeBureauGroup] = await Promise.all([
      getSenateMeetingChartAsync(startDate, endDate),
      getSenateMeetingDataTableAsync(senateMeetingDataTable.senatePage, senateMeetingDataTable.senateSize, startDate, endDate),
      getCommitteeMeetingChartAsync(startDate, endDate),
      getCommitteeMeetingDataTableAsync(committeeMeetingDataTable.senatePage, committeeMeetingDataTable.senateSize, startDate, endDate),
      getCommitteeBureauGroupDataTableAsync(committeeBureauGroupDataTable.committeeBureauPage, committeeBureauGroupDataTable.committeeBureauSize, startDate, endDate),
    ]);

    setSenateMeetingChart(senateChart.data);
    setSenateMeetingDataTable({
      ...senateMeetingDataTable,
      all: senateDataTable.data.all,
      guarantee: senateDataTable.data.guarantee,
      inprogress: senateDataTable.data.inprogress,
      overviewReviewed: senateDataTable.data.overviewReviewed,
      collect: senateDataTable.data.collect,
      rows: senateDataTable.data.pagination.rows,
      totalRows: senateDataTable.data.pagination.totalRows,
    });
    setCommitteeMeetingChart(committeeChart.data);
    setCommitteeMeetingDataTable({
      ...committeeMeetingDataTable,
      all: committeeDataTable.data.all,
      guarantee: committeeDataTable.data.guarantee,
      collect: committeeDataTable.data.collect,
      inprogress: committeeDataTable.data.inprogress,
      overviewReviewed: committeeDataTable.data.overviewReviewed,
      withoutAll: committeeDataTable.data.withoutAll,
      withoutCollect: committeeDataTable.data.withoutCollect,
      withoutGuarantee: committeeDataTable.data.withoutGuarantee,
      withoutInprogress: committeeDataTable.data.withoutInprogress,
      rows: committeeDataTable.data.pagination.rows,
      totalRows: committeeDataTable.data.pagination.totalRows,
    });
    setCommitteeBureauGroupDataTable({
      ...committeeBureauGroupDataTable,
      all: committeeBureauGroup.data.all,
      collect: committeeBureauGroup.data.collect,
      guarantee: committeeBureauGroup.data.guarantee,
      inprogress: committeeBureauGroup.data.inprogress,
      overviewReviewed: committeeBureauGroup.data.overviewReviewed,
      withoutAll: committeeBureauGroup.data.withoutAll,
      withoutCollect: committeeBureauGroup.data.withoutCollect,
      withoutGuarantee: committeeBureauGroup.data.withoutGuarantee,
      withoutInprogress: committeeBureauGroup.data.withoutInprogress,
      rows: committeeBureauGroup.data.pagination.rows,
      totalRows: committeeBureauGroup.data.pagination.totalRows,
    });
  };

  const onSenateDataTableChangeAsync = async (page: number, size: number) => {
    const res = await getSenateMeetingDataTableAsync(page, size, startDate, endDate);

    if (res.status === HttpStatusCode.Ok) {
      setSenateMeetingDataTable({
        ...senateMeetingDataTable,
        all: res.data.all,
        guarantee: res.data.guarantee,
        inprogress: res.data.inprogress,
        overviewReviewed: res.data.overviewReviewed,
        rows: res.data.pagination.rows,
        totalRows: res.data.pagination.totalRows,
        senatePage: page,
        senateSize: size,
      });
    }
  };

  const onCommitteeDataTableChangeAsync = async (page: number, size: number) => {
    const res = await getCommitteeMeetingDataTableAsync(page, size, startDate, endDate);

    if (res.status === HttpStatusCode.Ok) {
      setCommitteeMeetingDataTable({
        ...committeeMeetingDataTable,
        all: res.data.all,
        guarantee: res.data.guarantee,
        collect: res.data.collect,
        inprogress: res.data.inprogress,
        withoutAll: res.data.withoutAll,
        withoutCollect: res.data.withoutCollect,
        withoutGuarantee: res.data.withoutGuarantee,
        withoutInprogress: res.data.withoutInprogress,
        rows: res.data.pagination.rows,
        totalRows: res.data.pagination.totalRows,
        committeePage: page,
        committeeSize: size,
      });
    }
  };

  const onCommitteeBureauGroupDataTableChangeAsync = async (page: number, size: number) => {
    const res = await getCommitteeBureauGroupDataTableAsync(page, size, startDate, endDate);

    if (res.status === HttpStatusCode.Ok) {
      setCommitteeBureauGroupDataTable({
        ...committeeBureauGroupDataTable,
        all: res.data.all,
        collect: res.data.collect,
        guarantee: res.data.guarantee,
        inprogress: res.data.inprogress,
        withoutAll: res.data.withoutAll,
        withoutCollect: res.data.withoutCollect,
        withoutGuarantee: res.data.withoutGuarantee,
        withoutInprogress: res.data.withoutInprogress,
        rows: res.data.pagination.rows,
        totalRows: res.data.pagination.totalRows,
        committeeBureauPage: page,
        committeeBureauSize: size,
      });
    }
  };

  return (
    <>
      <Card>
        <Form>
          <Form.Label>กรองข้อมูล</Form.Label>
          <div className="criteria-flex gap-3">
            <div>
              <InputDate
                label="วันที่เริ่มต้น"
                value={startDate}
                onChange={(value) => setStartDate(value!)}
                isHideClearButton />
            </div>
            <div>
              <InputDate
                label="วันที่สิ้นสุด"
                value={endDate}
                onChange={(value) => setEndDate(value!)}
                isHideClearButton />
            </div>
            <div className="button">
              <Button
                type="button"
                onClick={() => onSubmitAsync(startDate, endDate)}>
                <FaFilter className="me-2" />กรอง
              </Button>
              <Button
                variant="outline-primary"
                onClick={onClear}>
                <FaTrashAlt className="me-2" />ล้าง
              </Button>
            </div>
          </div>
        </Form>
      </Card>
      <Row className="mt-3">
        <Col lg={6} xxl={6}>
          <SenateChartDetail
            chart={senateMeetingChart} />
        </Col>
        <Col lg={6} xxl={6}>
          <CommitteeChartDetail
            chart={committeeMeetingChart} />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xl={6}>
          <div className="d-flex justify-content-between my-2 align-items-center">
            <div>
              <h4 className="text-decoration-underline m-0">การประชุมวุฒิสภา</h4>
            </div>
            <ExportButton
              exportExcelFunction={() =>
                exportExcelSenateAsync(senateMeetingLoader.senatePage, senateMeetingLoader.senateSize, startDate, endDate)}
              exportPdfFunction={() =>
                exportPdfSenateAsync(senateMeetingLoader.senatePage, senateMeetingLoader.senateSize, startDate, endDate)}
              exportWordFunction={() =>
                exportWordSenateAsync(startDate, endDate)} />
          </div>
          <SenateDataTable
            data={senateMeetingDataTable}
            onChange={onSenateDataTableChangeAsync}
            startDate={startDate}
            endDate={endDate} />
        </Col>
        <Col xl={6}>
          <div className="d-flex justify-content-between my-2 align-items-center">
            <div>
              <h4 className="text-decoration-underline m-0">การประชุมคณะกรรมาธิการ <br /> (จำแนกตามคณะ)</h4>
            </div>
            <ExportButton
              exportExcelFunction={() =>
                exportExcelCommitteeAsync(DataTableType.Committee, committeeMeetingLoader.senatePage, committeeMeetingLoader.senateSize, startDate, endDate)}
              exportPdfFunction={() =>
                exportPdfCommitteeAsync(DataTableType.Committee, committeeMeetingLoader.senatePage, committeeMeetingLoader.senateSize, startDate, endDate)}
              exportWordFunction={() =>
                exportWordCommitteeAsync(DataTableType.Committee, startDate, endDate)} />
          </div>
          <CommitteeDataTable
            data={committeeMeetingDataTable}
            onChange={onCommitteeDataTableChangeAsync}
            startDate={startDate}
            endDate={endDate} />
        </Col>
      </Row>
      <Row className="justify-content-md-end mt-3">
        <Col xl={6}>
          <div className="d-flex justify-content-between my-2 align-items-center">
            <div>
              <h4 className="text-decoration-underline m-0">การประชุมคณะกรรมาธิการ <br /> (จำแนกตามสำนัก)</h4>
            </div>
            <ExportButton
              exportExcelFunction={() =>
                exportExcelCommitteeAsync(DataTableType.CommitteeBureau, committeeMeetingLoader.senatePage, committeeMeetingLoader.senateSize, startDate, endDate)}
              exportPdfFunction={() =>
                exportPdfCommitteeAsync(DataTableType.CommitteeBureau, committeeMeetingLoader.senatePage, committeeMeetingLoader.senateSize, startDate, endDate)}
              exportWordFunction={() =>
                exportWordCommitteeAsync(DataTableType.CommitteeBureau, startDate, endDate)} />
          </div>
          <CommitteeBureauGroupDataTable
            data={committeeBureauGroupDataTable}
            onChange={onCommitteeBureauGroupDataTableChangeAsync}
            startDate={startDate}
            endDate={endDate} />
        </Col>
      </Row>
    </>
  );
}

function SenateTab() {
  const { senateMeetingChartLoader, senateMeetingLoader } = useLoaderData() as Loader;
  const [startDate, setStartDate] = useState<Date>(startDateCurrent);
  const [endDate, setEndDate] = useState<Date>(endDateCurrent);
  const [chart, setChart] = useState<OverviewChart[]>([]);
  const [pagination, setPagination] = useState<PaginationDashBoard<DataList>>
    ({ senatePage: 1, senateSize: 10 } as PaginationDashBoard<DataList>);

  useEffect(() => {
    if (senateMeetingChartLoader) {
      setChart(senateMeetingChartLoader);
    }

    if (senateMeetingLoader) {
      setPagination({ ...senateMeetingLoader });
    }
  }, [senateMeetingChartLoader, senateMeetingLoader]);

  const onSubmitAsync = async (page: number, size: number, startDate: Date, endDate: Date) => {
    if (startDate && endDate) {
      if (startDate > endDate) {
        toast.warn("วันที่เริ่มต้นมีค่ามากกว่าวันที่สิ้นสุด");

        return;
      }
    }

    if (startDate && endDate) {
      const durationInMonths = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());

      if (durationInMonths >= 12) {
        toast.warn("เลือกวันที่เริ่มต้นและสิ้นสุดห่างกันได้ไม่เกิน 12 เดือน");

        return;
      }
    }

    const [chart, dataTable] = await Promise.all([
      getSenateMeetingChartAsync(startDate, endDate),
      getSenateMeetingDataTableAsync(page, size, startDate, endDate),
    ]);

    setChart(chart.data);
    setPagination({
      ...pagination,
      all: dataTable.data.all,
      guarantee: dataTable.data.guarantee,
      inprogress: dataTable.data.inprogress,
      collect: dataTable.data.collect,
      overviewReviewed: dataTable.data.overviewReviewed,
      rows: dataTable.data.pagination.rows,
      totalRows: dataTable.data.pagination.totalRows,
      senatePage: page,
      senateSize: size,
    });
  };

  const onClear = () => {
    setStartDate(startDateCurrent);
    setEndDate(endDateCurrent);
    onSubmitAsync(pagination.senatePage, pagination.senateSize, startDateCurrent, endDateCurrent);
  };

  const onDataTableChange = (page: number, size: number) => {
    setPagination({
      ...pagination,
      senatePage: page,
      senateSize: size,
    });

    onSubmitAsync(page, size, startDate, endDate);
  };

  return (
    <>
      <Card>
        <Form>
          <Form.Label>กรองข้อมูล</Form.Label>
          <div className="criteria-flex gap-3">
            <div>
              <InputDate
                label="วันที่เริ่มต้น"
                value={startDate}
                onChange={(value) => setStartDate(value!)}
                isHideClearButton />
            </div>
            <div>
              <InputDate
                label="วันที่สิ้นสุด"
                value={endDate}
                onChange={(value) => setEndDate(value!)}
                isHideClearButton />
            </div>
            <div className="button">
              <Button
                type="button"
                onClick={() => onSubmitAsync(pagination.senatePage, pagination.senateSize, startDate, endDate)}>
                <FaFilter className="me-2" />กรอง
              </Button>
              <Button
                variant="outline-primary"
                onClick={onClear}>
                <FaTrashAlt className="me-2" />ล้าง
              </Button>
            </div>
          </div>
        </Form>
      </Card>
      <Row className="mt-3 d-flex justify-content-center">
        <Col sm={12} md={10} lg={6}>
          <SenateChartDetail
            chart={chart} />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <div className="d-flex justify-content-between my-2 align-items-center">
            <div>
              <h4 className="text-decoration-underline m-0">การประชุมวุฒิสภา</h4>
            </div>
            <ExportButton
              exportExcelFunction={() =>
                exportExcelSenateAsync(senateMeetingLoader.senatePage, senateMeetingLoader.senateSize, startDate, endDate)}
              exportPdfFunction={() =>
                exportPdfSenateAsync(senateMeetingLoader.senatePage, senateMeetingLoader.senateSize, startDate, endDate)}
              exportWordFunction={() =>
                exportWordSenateAsync(startDate, endDate)} />
          </div>
          <SenateDataTable
            data={pagination}
            onChange={onDataTableChange}
            startDate={startDate}
            endDate={endDate} />
        </Col>
      </Row>
    </>
  );
}

function SenateChartDetail(props: { chart: OverviewChart[] }) {
  const { setCurrentModule } = useModule();
  const [meetingChartData, setMeetingChartData] = useState<OverviewChart[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    setDefaultData(props.chart, setMeetingChartData);
  }, [props.chart]);

  const labelOverview = meetingChartData.map(o => convertMonthYear(o.month, o.year));
  const guarantee = meetingChartData.map(o => o.guarantee);
  const collect = meetingChartData.map(o => o.collect);
  const inprogress = meetingChartData.map(o => o.inprogress);
  const overviewReviewed = meetingChartData.map(o => o.overviewReviewed);

  const chartData: Chart = {
    labels: labelOverview,
    chartData: [
      {
        label: 'รับรองรายงาน',
        data: guarantee,
        backgroundColor: '#581913'
      },
      {
        label: 'ตรวจสอบภาพรวมสำเร็จ',
        data: overviewReviewed,
        backgroundColor: '#517634'
      },
      {
        label: 'รวบรวมรายงาน',
        data: collect,
        backgroundColor: '#EDAD4F'
      },
      {
        label: 'รอดำเนินการ',
        data: inprogress,
        backgroundColor: '#9B9B9B'
      }
    ],
  };

  const navigateToSnMeeting = (label: string) => {
    switch (label) {
      case ChartBarType.COLLECT:
        navigate('/s0201?size=10&page=1&criteria=%7B"status"%3A"Guarantee"%7D');
        break;
      case ChartBarType.GUARANTEE:
        navigate('/s0201?size=10&page=1');
        break;
      case ChartBarType.IN_PROGRESS:
        navigate('/s0201?size=10&page=1');
        break;
    }

    setCurrentModule(Module.Senate);
    cookie.set(Cookie.MODULE, Module.Senate);
  };

  return (
    <>
      <h4 className="text-center mt-2">วุฒิสภา</h4>
      <Card>
        <h6 className="text-decoration-underline">ครั้ง</h6>
        <Charts chartData={chartData} onClickChart={navigateToSnMeeting} />
      </Card>
    </>
  );
}

function SenateDataTable(
  props: {
    data: PaginationDashBoard<DataList>,
    onChange: (page: number, size: number) => void,
    startDate: Date,
    endDate: Date,
  }) {
  const [dataTable, setDataTable] = useState<DataList[]>([]);
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [detailId, setDetailId] = useState("");

  useEffect(() => {
    if (props.data.rows) {
      setInitValueToSenateData(props.data.rows);
    }
  }, [props.data]);

  const setInitValueToSenateData = (senateDataList: DataList[]) => {
    const dataList = [...senateDataList];

    if (senateDataList.length > 0) {
      dataList.push({
        meetingPeriodName: 'รวม',
        all: props.data.all,
        overviewReviewed: props.data.overviewReviewed,
        guarantee: props.data.guarantee,
        inprogress: props.data.inprogress,
        collect: props.data.collect,
      });
    }

    setDataTable(dataList);
  };

  const onChangePageSize = (size: number, page: number) => {
    props.onChange(page, size);
  };

  const onShowModalDetail = (id: string) => {
    setDetailId(id);
    setShowModalDetail(true);
  };

  return (
    <>
      <Table
        total={props.data.totalRows}
        page={props.data.senatePage}
        size={props.data.senateSize}
        onChange={onChangePageSize}>
        <Table.Header>
          <Table.Row>
            <Table.Column minWidth={250}>ประเภทการประชุม</Table.Column>
            <Table.Column minWidth={100}>จำนวนครั้ง</Table.Column>
            <Table.Column minWidth={100}>
              <>รับรองรายงาน</>
            </Table.Column>
            <Table.Column minWidth={100}>
              <>ตรวจสอบภาพรวมสำเร็จ</>
            </Table.Column>
            <Table.Column minWidth={100}>
              <>รวบรวมรายงาน</>
            </Table.Column>
            <Table.Column minWidth={100}>รอดำเนินการ</Table.Column>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {dataTable?.map((data, i) =>
            <Table.Row key={i}>
              <Table.Cell className="text-break summary-row">
                {data.meetingPeriodName !== "รวม" ? <Button
                  variant="link text-start"
                  onClick={() => onShowModalDetail(data.id!)}>
                  {data.meetingPeriodName}
                </Button> : <span>รวม</span>}
              </Table.Cell>
              <Table.Cell right className="summary-row">{data.all}</Table.Cell>
              <Table.Cell right className="summary-row">{data.guarantee}</Table.Cell>
              <Table.Cell right className="summary-row">{data.overviewReviewed}</Table.Cell>
              <Table.Cell right className="summary-row">{data.collect}</Table.Cell>
              <Table.Cell right className="summary-row">{data.inprogress}</Table.Cell>
            </Table.Row>)}
        </Table.Body>
      </Table>
      <DataTableDetailModal
        show={showModalDetail}
        onHide={() => setShowModalDetail(false)}
        modalType={DataTableType.Senate}
        id={detailId}
        startDate={props.startDate}
        endDate={props.endDate} />
    </>
  );
}

function CommitteeTab() {
  const { committeeMeetingChartLoader, committeeMeetingLoader, committeeBureauLoader } = useLoaderData() as Loader;
  const [startDate, setStartDate] = useState<Date>(startDateCurrent);
  const [endDate, setEndDate] = useState<Date>(endDateCurrent);
  const [chart, setChart] = useState<OverviewChart[]>([]);
  const [committeePagination, setCommitteePagination] = useState<PaginationDashBoard<DataList>>
    ({ committeePage: 1, committeeSize: 10 } as PaginationDashBoard<DataList>);
  const [committeeBureauGroupPagination, setCommitteeBureauGroupPagination] = useState<PaginationDashBoard<DataList>>
    ({ committeePage: 1, committeeSize: 10 } as PaginationDashBoard<DataList>);

  const CommiteeTabs = [
    { label: 'จำแนกตามคณะ', value: 1 },
    { label: 'จำแนกตามสำนัก', value: 2 },
  ];

  useEffect(() => {
    if (committeeMeetingChartLoader) {
      setChart(committeeMeetingChartLoader);
    }

    if (committeeMeetingLoader) {
      setCommitteePagination({ ...committeeMeetingLoader });
    }

    if (committeeBureauLoader) {
      setCommitteeBureauGroupPagination({ ...committeeBureauLoader });
    }
  }, [committeeMeetingChartLoader, committeeMeetingLoader, committeeBureauLoader]);

  const onClear = () => {
    setStartDate(startDateCurrent);
    setEndDate(endDateCurrent);
    onSubmitAsync(startDateCurrent, endDateCurrent);
  };

  const onSubmitAsync = async (startDate: Date, endDate: Date) => {
    if (startDate && endDate) {
      if (startDate > endDate) {
        toast.warn("วันที่เริ่มต้นมีค่ามากกว่าวันที่สิ้นสุด");

        return;
      }
    }

    if (startDate && endDate) {
      const durationInMonths = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());

      if (durationInMonths >= 12) {
        toast.warn("เลือกวันที่เริ่มต้นและสิ้นสุดห่างกันได้ไม่เกิน 12 เดือน");

        return;
      }
    }

    const [chart, committeeDataTable, committeeBureauGroup] = await Promise.all([
      getCommitteeMeetingChartAsync(startDate, endDate),
      getCommitteeMeetingDataTableAsync(committeePagination.committeePage, committeePagination.committeeSize, startDate, endDate),
      getCommitteeBureauGroupDataTableAsync(committeePagination.committeeBureauPage, committeePagination.committeeBureauSize, startDate, endDate),
    ]);

    setChart(chart.data);
    setCommitteePagination({
      ...committeePagination,
      all: committeeDataTable.data.all,
      guarantee: committeeDataTable.data.guarantee,
      collect: committeeDataTable.data.collect,
      inprogress: committeeDataTable.data.inprogress,
      overviewReviewed: committeeDataTable.data.overviewReviewed,
      withoutAll: committeeDataTable.data.withoutAll,
      withoutCollect: committeeDataTable.data.withoutCollect,
      withoutGuarantee: committeeDataTable.data.withoutGuarantee,
      withoutInprogress: committeeDataTable.data.withoutInprogress,
      rows: committeeDataTable.data.pagination.rows,
      totalRows: committeeDataTable.data.pagination.totalRows,
    });
    setCommitteeBureauGroupPagination({
      ...committeeBureauGroupPagination,
      all: committeeBureauGroup.data.all,
      guarantee: committeeBureauGroup.data.guarantee,
      collect: committeeBureauGroup.data.collect,
      inprogress: committeeBureauGroup.data.inprogress,
      overviewReviewed: committeeBureauGroup.data.overviewReviewed,
      withoutAll: committeeBureauGroup.data.withoutAll,
      withoutCollect: committeeBureauGroup.data.withoutCollect,
      withoutGuarantee: committeeBureauGroup.data.withoutGuarantee,
      withoutInprogress: committeeBureauGroup.data.withoutInprogress,
      rows: committeeBureauGroup.data.pagination.rows,
      totalRows: committeeBureauGroup.data.pagination.totalRows,
    });
  };

  const onCommitteeDataTableChangeAsync = async (page: number, size: number) => {
    const res = await getCommitteeMeetingDataTableAsync(page, size, startDate, endDate);

    if (res.status === HttpStatusCode.Ok) {
      setCommitteePagination({
        ...committeePagination,
        all: res.data.all,
        guarantee: res.data.guarantee,
        collect: res.data.collect,
        inprogress: res.data.inprogress,
        overviewReviewed: res.data.overviewReviewed,
        withoutAll: res.data.withoutAll,
        withoutCollect: res.data.withoutCollect,
        withoutGuarantee: res.data.withoutGuarantee,
        withoutInprogress: res.data.withoutInprogress,
        rows: res.data.pagination.rows,
        totalRows: res.data.pagination.totalRows,
        committeePage: page,
        committeeSize: size,
      });
    }
  };

  const onCommitteeBureauGroupDataTableChangeAsync = async (page: number, size: number) => {
    const res = await getCommitteeBureauGroupDataTableAsync(page, size, startDate, endDate);

    if (res.status === HttpStatusCode.Ok) {
      setCommitteeBureauGroupPagination({
        ...committeeBureauGroupPagination,
        all: res.data.all,
        guarantee: res.data.guarantee,
        collect: res.data.collect,
        inprogress: res.data.inprogress,
        overviewReviewed: res.data.overviewReviewed,
        withoutAll: res.data.withoutAll,
        withoutCollect: res.data.withoutCollect,
        withoutGuarantee: res.data.withoutGuarantee,
        withoutInprogress: res.data.withoutInprogress,
        rows: res.data.pagination.rows,
        totalRows: res.data.pagination.totalRows,
        committeeBureauPage: page,
        committeeBureauSize: size,
      });
    }
  };

  return (
    <>
      <Card>
        <Form>
          <Form.Label>กรองข้อมูล</Form.Label>
          <div className="criteria-flex gap-3">
            <div>
              <InputDate
                label="วันที่เริ่มต้น"
                value={startDate}
                onChange={(value) => setStartDate(value!)}
                isHideClearButton />
            </div>
            <div>
              <InputDate
                label="วันที่สิ้นสุด"
                value={endDate}
                onChange={(value) => setEndDate(value!)}
                isHideClearButton />
            </div>
            <div className="button">
              <Button
                type="button"
                onClick={() => onSubmitAsync(startDate, endDate)}>
                <FaFilter className="me-2" />กรอง
              </Button>
              <Button
                variant="outline-primary"
                onClick={onClear}>
                <FaTrashAlt className="me-2" />ล้าง
              </Button>
            </div>
          </div>
        </Form>
      </Card>
      <Row className="mt-3 d-flex justify-content-center">
        <Col sm={12} md={10} lg={6}>
          <CommitteeChartDetail
            chart={chart} />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xl={12}>
          <h4 className="text-decoration-underline mb-2">การประชุมคณะกรรมาธิการ</h4>
          <Tabs items={CommiteeTabs} defaultActiveKey={1}>
            <Tab.Content>
              <Tab.Pane eventKey={1}>
                <div className="d-flex justify-content-end mb-2 align-items-center">
                  <ExportButton
                    exportExcelFunction={() =>
                      exportExcelCommitteeAsync(DataTableType.Committee, committeeMeetingLoader.senatePage, committeeMeetingLoader.senateSize, startDate, endDate)}
                    exportPdfFunction={() =>
                      exportPdfCommitteeAsync(DataTableType.Committee, committeeMeetingLoader.senatePage, committeeMeetingLoader.senateSize, startDate, endDate)}
                    exportWordFunction={() =>
                      exportWordCommitteeAsync(DataTableType.Committee, startDate, endDate)} />
                </div>
                <CommitteeDataTable
                  data={committeePagination}
                  onChange={onCommitteeDataTableChangeAsync}
                  startDate={startDate}
                  endDate={endDate} />
              </Tab.Pane>
              <Tab.Pane eventKey={2}>
                <div className="d-flex justify-content-end mb-2 align-items-center">
                  <ExportButton
                    exportExcelFunction={() =>
                      exportExcelCommitteeAsync(DataTableType.CommitteeBureau, committeeMeetingLoader.senatePage, committeeMeetingLoader.senateSize, startDate, endDate)}
                    exportPdfFunction={() =>
                      exportPdfCommitteeAsync(DataTableType.CommitteeBureau, committeeMeetingLoader.senatePage, committeeMeetingLoader.senateSize, startDate, endDate)}
                    exportWordFunction={() =>
                      exportWordCommitteeAsync(DataTableType.CommitteeBureau, startDate, endDate)} />
                </div>
                <CommitteeBureauGroupDataTable
                  data={committeeBureauGroupPagination}
                  onChange={onCommitteeBureauGroupDataTableChangeAsync}
                  startDate={startDate}
                  endDate={endDate} />
              </Tab.Pane>
            </Tab.Content>
          </Tabs>
        </Col>
      </Row>
    </>
  );
}

function CommitteeChartDetail(props: { chart: OverviewChart[] }) {
  const { setCurrentModule } = useModule();
  const [meetingChartData, setMeetingChartData] = useState<OverviewChart[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    setDefaultData(props.chart, setMeetingChartData);
  }, [props.chart]);

  const labelOverview = meetingChartData.map(o => convertMonthYear(o.month, o.year));
  const guarantee = meetingChartData.map(o => o.guarantee);
  const collect = meetingChartData.map(o => o.collect);
  const inprogress = meetingChartData.map(o => o.inprogress);
  const overviewReviewed = meetingChartData.map(o => o.overviewReviewed);

  const chartData: Chart = {
    labels: labelOverview,
    chartData: [
      {
        label: 'รับเรื่องแล้ว',
        data: guarantee,
        backgroundColor: '#581913'
      },
      {
        label: 'รอรับเรื่อง',
        data: overviewReviewed,
        backgroundColor: '#517634'
      },
      {
        label: 'รวบรวมรายงาน',
        data: collect,
        backgroundColor: '#EDAD4F'
      },
      {
        label: 'รอดำเนินการ',
        data: inprogress,
        backgroundColor: '#9B9B9B'
      }
    ],
  };

  const navigateToCtMeeting = (label: string) => {
    switch (label) {
      case ChartBarType.COLLECT:
        navigate('/c0202?size=10&page=1&criteria=%7B"status"%3A"Guarantee"%7D');
        break;
      case ChartBarType.GUARANTEE:
        navigate('/c0202?size=10&page=1');
        break;
      case ChartBarType.IN_PROGRESS:
        navigate('/c0202?size=10&page=1');
        break;
    }

    setCurrentModule(Module.Committee);
    cookie.set(Cookie.MODULE, Module.Committee);
  };

  return (
    <>
      <h4 className="text-center mt-2">คณะกรรมาธิการ</h4>
      <Card>
        <h6 className="text-decoration-underline">ครั้ง</h6>
        <Charts chartData={chartData} onClickChart={navigateToCtMeeting} />
      </Card>
    </>
  );
}

function CommitteeDataTable(
  props: {
    data: PaginationDashBoard<DataList>,
    onChange: (page: number, size: number) => void,
    startDate: Date,
    endDate: Date,
  }) {
  const [dataTable, setDataTable] = useState<DataList[]>([]);
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [detailId, setDetailId] = useState("");

  useEffect(() => {
    if (props.data.rows) {
      setInitValueToCommitteeData(props.data.rows);
    }
  }, [props.data]);

  const setInitValueToCommitteeData = (committeeDataList: DataList[]) => {
    const dataList = [...committeeDataList];

    if (committeeDataList.length > 0) {
      dataList.push({
        committeeName: 'ไม่ได้ระบุ',
        all: props.data.withoutAll,
        guarantee: props.data.withoutGuarantee,
        collect: props.data.withoutCollect,
        inprogress: props.data.withoutInprogress,
        overviewReviewed: props.data.withoutOverviewReviewed,
      });

      dataList.push({
        committeeName: 'รวม',
        all: props.data.all,
        guarantee: props.data.guarantee,
        collect: props.data.collect,
        inprogress: props.data.inprogress,
        overviewReviewed: props.data.overviewReviewed
      });
    }

    setDataTable(dataList);
  };

  const onChangePageSize = (size: number, page: number) => {
    props.onChange(page, size);
  };

  const onShowModalDetail = (code: string) => {
    setDetailId(code);
    setShowModalDetail(true);
  };

  return (
    <>
      <Table
        total={props.data.totalRows}
        page={props.data.committeePage}
        size={props.data.committeeSize}
        onChange={onChangePageSize}>
        <Table.Header>
          <Table.Row>
            <Table.Column minWidth={250}>ประเภทการประชุม</Table.Column>
            <Table.Column minWidth={100}>จำนวนครั้ง</Table.Column>
            <Table.Column minWidth={100}>
              <>รับเรื่องแล้ว</>
            </Table.Column>
            <Table.Column minWidth={100}>
              รอรับเรื่อง
            </Table.Column>
            <Table.Column minWidth={100}>
              รวบรวมรายงาน
            </Table.Column>
            <Table.Column minWidth={100}>รอดำเนินการ</Table.Column>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {dataTable?.map((data, i) => (
            <Table.Row key={i}>
              <Table.Cell className="text-break summary-row">
                <>
                  {data.committeeName !== "รวม" && data.committeeName !== 'ไม่ได้ระบุ' ? <Button
                    variant="link text-start"
                    onClick={() => onShowModalDetail(data.code!)}>
                    {data.committeeName}
                  </Button> : <>{data.committeeName}</>}
                </>
              </Table.Cell>
              <Table.Cell right className="summary-row">{data.all}</Table.Cell>
              <Table.Cell right className="summary-row">{data.guarantee}</Table.Cell>
              <Table.Cell right className="summary-row">{data.overviewReviewed}</Table.Cell>
              <Table.Cell right className="summary-row">{data.collect}</Table.Cell>
              <Table.Cell right className="summary-row">{data.inprogress}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <DataTableDetailModal
        show={showModalDetail}
        onHide={() => setShowModalDetail(false)}
        modalType={DataTableType.Committee}
        id={detailId}
        startDate={props.startDate}
        endDate={props.endDate} />
    </>
  );
}

function CommitteeBureauGroupDataTable(
  props: {
    data: PaginationDashBoard<DataList>,
    onChange: (page: number, size: number) => void,
    startDate: Date,
    endDate: Date,
  }) {
  const [dataTable, setDataTable] = useState<DataList[]>([]);
  const [showMeetingModal, setShowMeetingModal] = useState(false);
  const [bureauId, setBureauId] = useState('');
  const [showModalCommitteeDetail, setShowModalCommitteeDetail] = useState(false);
  const [committeeId, setCommitteeId] = useState<number>(0);

  useEffect(() => {
    if (props.data.rows) {
      setInitValueToCommitteeData(props.data.rows);
    }
  }, [props.data]);

  const setInitValueToCommitteeData = (committeeBureauDataList: DataList[]) => {
    const dataList = [...committeeBureauDataList];

    if (committeeBureauDataList.length > 0) {
      dataList.push({
        bureauName: 'ไม่ระบุ',
        all: props.data.withoutAll,
        guarantee: props.data.withoutGuarantee,
        collect: props.data.withoutCollect,
        inprogress: props.data.withoutInprogress,
        overviewReviewed: props.data.withoutOverviewReviewed,
      });

      dataList.push({
        bureauName: 'รวม',
        all: props.data.all,
        guarantee: props.data.guarantee,
        collect: props.data.collect,
        inprogress: props.data.inprogress,
        overviewReviewed: props.data.overviewReviewed
      });
    }

    setDataTable(dataList);
  };

  const onChangePageSize = (size: number, page: number) => {
    props.onChange(page, size);
  };

  const onShowCommitteeModalDetail = (bureauId: string) => {
    setBureauId(bureauId);
    setShowModalCommitteeDetail(true);
  };

  const onShowMeetingDetailModal = (id: string) => {
    setCommitteeId(+id);
    setShowMeetingModal(true);
  };

  return (
    <>
      <Table
        total={props.data.totalRows}
        page={props.data.committeeBureauPage}
        size={props.data.committeeBureauSize}
        onChange={onChangePageSize}>
        <Table.Header>
          <Table.Row>
            <Table.Column minWidth={250}>ประเภทการประชุม</Table.Column>
            <Table.Column minWidth={100}>จำนวนครั้ง</Table.Column>
            <Table.Column minWidth={100}>
              <>รับเรื่องแล้ว</>
            </Table.Column>
            <Table.Column minWidth={100}>
              <>รอรับเรื่อง</>
            </Table.Column>
            <Table.Column minWidth={100}>
              <>รวบรวมรายงาน</>
            </Table.Column>
            <Table.Column minWidth={100}>รอดำเนินการ</Table.Column>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {dataTable?.map((data, i) => (
            <Table.Row key={i}>
              <Table.Cell className="text-break summary-row">
                <>
                  {data.bureauName !== "รวม" && data.bureauName !== "ไม่ระบุ" ? <Button
                    variant="link text-start"
                    onClick={() => onShowCommitteeModalDetail(data.id!)}>
                    {data.bureauName}
                  </Button> : <>{data.bureauName}</>}
                </>
              </Table.Cell>
              <Table.Cell right className="summary-row">{data.all}</Table.Cell>
              <Table.Cell right className="summary-row">{data.guarantee}</Table.Cell>
              <Table.Cell right className="summary-row">{data.overviewReviewed}</Table.Cell>
              <Table.Cell right className="summary-row">{data.collect}</Table.Cell>
              <Table.Cell right className="summary-row">{data.inprogress}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <CommitteeDetailModal
        show={showModalCommitteeDetail}
        onHide={() => setShowModalCommitteeDetail(false)}
        bureauId={bureauId}
        onShowDetail={onShowMeetingDetailModal}
        startDate={props.startDate}
        endDate={props.endDate} />
      <MeetingDetailModal
        show={showMeetingModal}
        onHide={() => setShowMeetingModal(false)}
        committeeId={committeeId}
        startDate={props.startDate}
        endDate={props.endDate} />
    </>
  );
}

function ExportButton(props: { exportExcelFunction: Function, exportPdfFunction: Function, exportWordFunction: Function }) {
  return (
    <div>
      <Button
        className="me-2"
        variant="outline-primary"
        onClick={() => props.exportPdfFunction()}>
        <FaRegFilePdf className="me-2" />Export PDF
      </Button>
      <Button
        className="me-2"
        variant="outline-primary"
        onClick={() => props.exportExcelFunction()}>
        <FaRegFileExcel className="me-2" />Export Excel
      </Button>
      <Button
        variant="outline-primary"
        onClick={() => props.exportWordFunction()}>
        <FaRegFileWord className="me-2" />Export Word
      </Button>
    </div>
  );
}

function DataTableDetailModal(props: {
  show: boolean,
  onHide: () => void,
  modalType: DataTableType,
  id: string,
  startDate: Date,
  endDate: Date,
}) {
  const [detailList, setDetailList] = useState<DashBoardDetail[]>([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    if (props.show && props.id) {
      getDetailAsync();
    }
  }, [props.show, props.id, page, size]);

  const getDetailAsync = async () => {
    switch (props.modalType) {
      case DataTableType.Senate:
        await getSeneteDetailAsync();
        break;
      case DataTableType.Committee:
        await getCommitteeDetailAsync();
        break;
      case DataTableType.CommitteeBureau:
        await getCommitteeBureauDetailAsync();
        break;
    }
  };

  const getSeneteDetailAsync = async () => {
    const { data, status } = await d01Service.getSenateDetailAsync(props.id, page, size, props.startDate, props.endDate);

    if (status === HttpStatusCode.Ok) {
      setInitValue(data.pagination.rows, data.chapterAll);
      setTotalRows(data.pagination.totalRows);
    }
  };

  const getCommitteeDetailAsync = async () => {
    const { data, status } = await d01Service.getCommitteeDetailAsync(props.id, page, size, props.startDate, props.endDate);

    if (status === HttpStatusCode.Ok) {
      setInitValue(data.pagination.rows, data.chapterAll);
      setTotalRows(data.pagination.totalRows);
    }
  };

  const getCommitteeBureauDetailAsync = async () => {
    const { data, status } = await d01Service.getCommitteeBureauDetailAsync(props.id, page, size, props.startDate, props.endDate);

    if (status === HttpStatusCode.Ok) {
      setInitValue(data.pagination.rows, data.chapterAll);
      setTotalRows(data.pagination.totalRows);
    }
  };

  const onChangePageSize = (size: number, page: number) => {
    setPage(page);
    setSize(size);
  };

  const setInitValue = (dataList: DashBoardDetail[], chapterAll: number) => {
    const list = [...dataList];

    if (dataList.length > 0) {
      list.push({
        id: "",
        meetingName: "",
        chapterNum: chapterAll,
        status: "",
        inspect: "",
      });
    }

    setDetailList(list);
  };

  return (
    <Modal show={props.show} size="xl">
      <div
        className="d-flex justify-content-end align-items-center mb-2"
        onClick={props.onHide}>
        <FaTimes className="fs-4" />
      </div>
      <Table
        total={totalRows}
        page={page}
        size={size}
        onChange={onChangePageSize}>
        <Table.Header>
          <Table.Row>
            <Table.Column minWidth={150}>วันเดือนปี</Table.Column>
            <Table.Column minWidth={200}>ชื่อการประชุม/คณะ</Table.Column>
            {props.modalType === DataTableType.Senate ?
              <Table.Column minWidth={100}>
                <>กลุ่มงานที่รับผิดชอบ<br />รวบรวมส่ง</>
              </Table.Column> : <></>}
            <Table.Column minWidth={100}>
              <>จำนวน<br />ตอน</>
            </Table.Column>
            <Table.Column minWidth={200}>สถานะ</Table.Column>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {detailList?.map((data, index) => (
            <Table.Row key={index}>
              <Table.Cell center className="summary-row">{data.meetingDate ? formatDateTh(data.meetingDate) : 'รวม'}</Table.Cell>
              <Table.Cell className="summary-row">
                {data.meetingName}
              </Table.Cell>
              {props.modalType === DataTableType.Senate ?
                <Table.Cell>
                  {data.inspect}
                </Table.Cell> : <></>}
              <Table.Cell right className="summary-row">{data.chapterNum}</Table.Cell>
              <Table.Cell right className="summary-row">
                <div className="d-flex justify-content-center">
                  <Status
                    type={StatusType.MEETING_STATUS}
                    value={data.status}
                    committee={props.modalType !== DataTableType.Senate} />
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Modal>
  );
}

function CommitteeDetailModal(props: {
  show: boolean,
  onHide: () => void,
  bureauId: string,
  onShowDetail: (id: string) => void,
  startDate: Date,
  endDate: Date,
}) {
  const [dataTable, setDataTable] = useState<DataList[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);

  useEffect(() => {
    if (props.show) {
      getDateAsync(page, size);
    }
  }, [props.show]);

  const getDateAsync = async (page: number, size: number) => {
    const res = await getCommitteeMeetingDataTableAsync(page, size, props.startDate, props.endDate, props.bureauId);

    setInitValueToCommitteeData(
      res.data.pagination,
      res.data.all,
      res.data.guarantee,
      res.data.collect,
      res.data.inprogress,
      res.data.overviewReviewed);
    setTotalRows(res.data.pagination.totalRows);
  };

  const setInitValueToCommitteeData = (
    data: PaginationDashBoard<DataList>,
    all: number,
    guarantee: number,
    collect: number,
    inprogress: number,
    overviewReviewed: number) => {
    const dataList = [...data.rows];

    if (dataList.length > 0) {
      dataList.push({
        committeeName: 'รวม',
        all: all,
        guarantee: guarantee,
        collect: collect,
        inprogress: inprogress,
        overviewReviewed: overviewReviewed,
      });
    }

    setDataTable(dataList);
  };

  const onTableChange = (page: number, size: number) => {
    setPage(page);
    setSize(size);

    getDateAsync(page, size);
  };

  return (
    <Modal show={props.show} size="xl">
      <div
        className="d-flex justify-content-end align-items-center mb-2"
        onClick={props.onHide}>
        <FaTimes className="fs-4" />
      </div>
      <Table
        total={totalRows}
        page={page}
        size={size}
        onChange={onTableChange}>
        <Table.Header>
          <Table.Row>
            <Table.Column minWidth={250}>ประเภทการประชุม</Table.Column>
            <Table.Column minWidth={100}>จำนวนครั้ง</Table.Column>
            <Table.Column minWidth={100}>
              <>งานสำเร็จ<br />(ส่งสำนัก)</>
            </Table.Column>
            <Table.Column minWidth={100}>
              <>รับรองรายงาน<br />การประชุม</>
            </Table.Column>
            <Table.Column minWidth={100}>รอดำเนินการ</Table.Column>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {dataTable?.map((data, i) => (
            <Table.Row key={i}>
              <Table.Cell className="text-break summary-row">
                <>
                  {data.committeeName !== "รวม" ? <Button
                    variant="link text-start"
                    onClick={() => props.onShowDetail(data.code!)}>
                    {data.committeeName}
                  </Button> : <span>รวม</span>}
                </>
              </Table.Cell>
              <Table.Cell right className="summary-row">{data.all}</Table.Cell>
              <Table.Cell right className="summary-row">{data.overviewReviewed}</Table.Cell>
              <Table.Cell right className="summary-row">{data.guarantee}</Table.Cell>
              <Table.Cell right className="summary-row">{data.inprogress}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Modal >
  );
}

function MeetingDetailModal(props: {
  show: boolean,
  onHide: () => void,
  committeeId: number,
  startDate: Date,
  endDate: Date,
}) {
  const [dataTable, setDataTable] = useState<DashBoardDetail[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);

  useEffect(() => {
    if (props.show) {
      getDateAsync(page, size);
    }
  }, [props.show]);

  const getDateAsync = async (page: number, size: number) => {
    const res = await getMeetingByCommitteeIdAsync(page, size, props.committeeId, props.startDate, props.endDate);

    setInitValueToCommitteeData(res.data.pagination, res.data.chapterAll);
    setTotalRows(res.data.pagination.totalRows);
  };

  const setInitValueToCommitteeData = (data: PaginationDashBoard<DashBoardDetail>, chapterAll: number) => {
    const dataList = [...data.rows];

    if (dataList.length > 0) {
      dataList.push({
        meetingName: 'รวม',
        chapterNum: chapterAll,
      } as DashBoardDetail);
    }

    setDataTable(dataList);
  };

  const onTableChange = (size: number, page: number) => {
    setPage(page);
    setSize(size);

    getDateAsync(page, size);
  };

  return (
    <Modal show={props.show} size="xl">
      <div
        className="d-flex justify-content-end align-items-center mb-2"
        onClick={props.onHide}>
        <FaTimes className="fs-4" />
      </div>
      <Table
        total={totalRows}
        page={page}
        size={size}
        onChange={onTableChange}>
        <Table.Header>
          <Table.Row>
            <Table.Column minWidth={250}>วัน/เดือน/ปี</Table.Column>
            <Table.Column minWidth={100}>ชื่อการประชุม/คณะ</Table.Column>
            <Table.Column minWidth={100}>จำนวนตอน</Table.Column>
            <Table.Column minWidth={100}>สถานะ</Table.Column>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {dataTable?.map((data, i) => (
            <Table.Row key={i}>
              <Table.Cell center className="text-break summary-row">
                <>
                  {data.meetingName !== "รวม" ? <>{formatDateTh(data.meetingDate)}</> : <span>รวม</span>}
                </>
              </Table.Cell>
              <Table.Cell className="summary-row">
                {data.meetingName !== 'รวม' ? <>{data.meetingName}</> : ''}
              </Table.Cell>
              <Table.Cell right className="summary-row">{data.chapterNum}</Table.Cell>
              <Table.Cell className="summary-row">
                <Status type={StatusType.MEETING_STATUS} value={data.status} committee />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Modal >
  );
}

const getSenateMeetingChartAsync = async (
  startDate: Date,
  endDate: Date,
) => {
  return d01Service.getSenateOverviewChartMeetingAsync(startDate, endDate);
};

const getCommitteeMeetingChartAsync = async (
  startDate: Date,
  endDate: Date,
) => {
  return d01Service.getCommitteeOverviewChartMeetingAsync(startDate, endDate);
};

const getSenateMeetingDataTableAsync = async (
  page: number,
  size: number,
  startDate: Date,
  endDate: Date,
) => {
  return await d01Service.getSenateMeetingAsync(page, size, startDate, endDate);
};

const getCommitteeMeetingDataTableAsync = async (
  page: number,
  size: number,
  startDate: Date,
  endDate: Date,
  bureauId?: string,
) => {
  return await d01Service.getCommitteeMeetingAsync(page, size, startDate, endDate, bureauId);
};

const getCommitteeBureauGroupDataTableAsync = async (
  page: number,
  size: number,
  startDate?: Date,
  endDate?: Date,
  committeeId?: string,
) => {
  return await d01Service.getCommitteeBureauGroupAsync(page, size, startDate, endDate, committeeId);
};

const getMeetingByCommitteeIdAsync = async (
  page: number,
  size: number,
  committeeId: number,
  startDate: Date,
  endDate: Date,
) => {
  return await d01Service.getMeetingByCommitteeIdAsync(page, size, committeeId, startDate, endDate);
};

const exportExcelSenateAsync = async (
  page: number,
  size: number,
  startDate: Date,
  endDate: Date,
) => {
  const { data, status } = await d01Service.exportExcelSenateAsync(page, size, startDate, endDate);

  if (status === HttpStatusCode.Ok) {
    exportFileExcel(data);
  }
};

const exportWordSenateAsync = async (
  startDate: Date,
  endDate: Date,
) => {
  const { data, status } = await d01Service.exportWordSenateAsync(startDate, endDate);

  if (status === HttpStatusCode.Ok) {
    exportFileWord(data);
  }
}

const exportPdfSenateAsync = async (
  page: number,
  size: number,
  startDate: Date,
  endDate: Date,
) => {
  const { data, status } = await d01Service.exportPdfSenateAsync(page, size, startDate, endDate);

  if (status === HttpStatusCode.Ok) {
    exportPdfByte(data);
  }
};

const exportExcelCommitteeAsync = async (
  type: DataTableType,
  page: number,
  size: number,
  startDate: Date,
  endDate: Date,
) => {
  const { data, status } = await d01Service.exportExcelCommitteeAsync(type, page, size, startDate, endDate);

  if (status === HttpStatusCode.Ok) {
    exportFileExcel(data);
  }
};

const exportWordCommitteeAsync = async (
  type: DataTableType,
  startDate: Date,
  endDate: Date,
) => {
  const { data, status } = await d01Service.exportWordCommitteeAsync(type, startDate, endDate);

  if (status === HttpStatusCode.Ok) {
    exportFileWord(data);
  }
};

const exportPdfCommitteeAsync = async (
  type: DataTableType,
  page: number,
  size: number,
  startDate: Date,
  endDate: Date,
) => {
  const { data, status } = await d01Service.exportPdfCommitteeAsync(type, page, size, startDate, endDate);

  if (status === HttpStatusCode.Ok) {
    exportPdfByte(data);
  }
};