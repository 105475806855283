export function padZero2Digit(value: number) {
  return ('0' + value).slice(-2);
};

export function isNullOrEmpty(value?: number | string) {
  return value == undefined || value == null || value == '';
};

export function convertNumberToCurrency(value: number) {
  return new Intl.NumberFormat('th-TH').format(value);
};

export function isNullOrEmptyTime(value?: Date) {
  return value == undefined || value == null;
}