import { LoaderFunctionArgs } from 'react-router-dom';
import { s0102 as service } from 'services';

export const s0102LoaderAsync = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 1;
  const size = url.searchParams.get('size') || 10;
  const keyword = url.searchParams.get('keyword') || '';
  
  const { data } = await service.getAgendaTypeListAsync(+page, +size, keyword);

  return {
    agendaTypeList: {
      rows: data.rows,
      totalRows: data.totalRows,
      page: +page,
      size: +size,
    },
  };
};