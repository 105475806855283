import { Nav, Tab } from "react-bootstrap";

interface Props {
  items: { label: string, value: string | number }[];
  defaultActiveKey?: string | number;
  onSelect?: (value: string | number | null) => void;
  children: JSX.Element;
}

export function Tabs(props: Props) {
  const onSelect = (value: string | number | null) => {
    if (props.onSelect) {
      props.onSelect(value);
    }
  };

  return (
    <Tab.Container defaultActiveKey={props.defaultActiveKey}>
      <Nav
        variant="pills"
        className="flex-row"
        onSelect={onSelect}>
        {props.items.map(item =>
          <Nav.Item key={item.value}>
            <Nav.Link eventKey={item.value}>{item.label}</Nav.Link>
          </Nav.Item>)}
      </Nav>
      <div className="mt-3">
        {props.children}
      </div>
    </Tab.Container>
  );
}