import { LoaderFunctionArgs } from 'react-router';
import { m0103 } from 'services';

export const m0103LoaderAsync = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 1;
  const size = url.searchParams.get('size') || 10;
  const criteria = JSON.parse(url.searchParams.get('criteria')!)

  const [userList, bureauSelection, bureauGroupSelection] = await Promise.all([
    m0103.getUserListAsync(+page, +size, criteria),
    m0103.getBureauSelectionAsync(),
    m0103.getBureauGroupSelectionAsync(),
  ]);

  return {
    data: {
      rows: userList.data.rows,
      totalRows: userList.data.totalRows,
      page: +page,
      size: +size,
    },
    bureauSelection: bureauSelection.data,
    bureauGroupSelection: bureauGroupSelection.data,
  };
};

export const m0103DetailLoaderAsync = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;

  const roleSelection = (await m0103.getRoleSelectionAsync()).data;

  if (id) {
    const userDetail = (await m0103.getUserDetailAsync(id)).data;

    return {
      userDetail,
      roleSelection,
    };
  }

  return {
    roleSelection,
  };
};