import { SelectionType } from "constant";
import { ItemModel, MeetingInspectCriteria, MeetingInspectDetailModel, MeetingInspectListModel, Pagination } from "models";
import { LoaderFunctionArgs } from "react-router-dom";
import { s0205 as service, selectionService as ddlService, s0201 } from "services";

export const s0205LoaderAsync = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 1;
  const size = url.searchParams.get('size') || 10;
  const criteriaStringify = url.searchParams.get('criteria') || '{}';
  const criteria = JSON.parse(criteriaStringify) as MeetingInspectCriteria;

  const year = (new Date()).getUTCFullYear() - 10;
  const yearsDDL: ItemModel[] = [];
  Array.from(new Array(20), (_, index) => yearsDDL.push({ label: (index + (year + 543)).toString(), value: index + year }));

  const [meetingPeriodDDL, meetingTypeDDL, inspectListData] = await Promise.all([
    ddlService.getSelectionAsync(SelectionType.SN_MEETING_PERIOD),
    ddlService.getSelectionAsync(SelectionType.SN_MEETING_TYPE),
    service.getInspectListAsync(+page, +size, criteria),
  ]);

  const inspectListDataResult: Pagination<MeetingInspectListModel> = inspectListData.data || {} as Pagination<MeetingInspectListModel>;
  inspectListDataResult.page = +page;
  inspectListDataResult.size = +size;

  return {
    meetingTypeDDL: meetingTypeDDL.data,
    meetingPeriodDDL: meetingPeriodDDL.data,
    yearsDDL: yearsDDL,
    inspectList: inspectListDataResult,
  };
};

export const s0205DetailLoaderAsync = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;

  const [inspectDetailResponse, agendaDDLResponse] = await Promise.all(
    [service.getInspectDetailListAsync(id, 1, 10),
    service.getAgendaDDLAsync(id)],
  );

  const detailResult: Pagination<MeetingInspectDetailModel> = inspectDetailResponse.data || {} as Pagination<MeetingInspectDetailModel>;
  detailResult.page = 1;
  detailResult.size = 10;

  return {
    inspectDetailList: detailResult,
    agendaDDL: agendaDDLResponse.data,
  };
};

export const s0205DocumentLoaderAsync = async ({ params, request }: LoaderFunctionArgs) => {
  const { id } = params;
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 1;
  const size = url.searchParams.get('size') || 10;

  const [detail, header] = await Promise.all([
    service.getInspectDetailListAsync(id, +page, +size),
    s0201.getSnMeetingDetailAsync(id!),
  ]);

  return {
    inspectDetailList: {
      rows: detail.data.rows,
      totalRows: detail.data.totalRows,
      page: +page,
      size: +size,
    },
    headerData: header.data,
  };
};