import { LoaderFunctionArgs } from "react-router-dom";
import d01Service from "services/d01Service";

export const d01LoaderAsync = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const senatePage = url.searchParams.get('senatePage') || 1;
  const senateSize = url.searchParams.get('senateSize') || 10;
  const committeePage = url.searchParams.get('committeePage') || 1;
  const committeeSize = url.searchParams.get('committeeSize') || 10;
  const committeeBureauPage = url.searchParams.get('committeeBureauPage') || 1;
  const committeeBureauSize = url.searchParams.get('committeeBureauSize') || 10;
  const startDate = url.searchParams.get('startDate');
  const endDate = url.searchParams.get('endDate');

  const getLastDayOfMonth = (year: number, month: number) => {
    const nextMonthDate = new Date(year, month + 1, 1);
    const lastDayOfMonth = new Date(nextMonthDate.getTime() - 1);

    return lastDayOfMonth.getDate();
  };

  const startDateCurrent = new Date();
  const month = startDateCurrent.getMonth();
  startDateCurrent.setMonth(month - 11);
  startDateCurrent.setDate(1);

  const endDateCurrent = new Date();
  endDateCurrent.setDate(getLastDayOfMonth(endDateCurrent.getFullYear(), endDateCurrent.getMonth()));

  const getStartDate = startDate ? new Date(startDate) : startDateCurrent;
  const getEndDate = endDate ? new Date(endDate) : endDateCurrent;

  const [senateMeeting,
    committeeMeeting,
    committeeBureau,
    senatMeetingChart,
    committeeMeetingChart] = await Promise.all([
      d01Service.getSenateMeetingAsync(+senatePage, +senateSize, getStartDate, getEndDate),
      d01Service.getCommitteeMeetingAsync(+committeePage, +committeeSize, getStartDate, getEndDate),
      d01Service.getCommitteeBureauGroupAsync(+committeeBureauPage, +committeeBureauSize, getStartDate, getEndDate),
      d01Service.getSenateOverviewChartMeetingAsync(getStartDate, getEndDate),
      d01Service.getCommitteeOverviewChartMeetingAsync(getStartDate, getEndDate),
    ]);

  return {
    senateMeetingLoader: {
      rows: senateMeeting.data.pagination.rows,
      totalRows: senateMeeting.data.pagination.totalRows,
      senatePage: +senatePage,
      senateSize: +senateSize,
      committeePage: +committeePage,
      committeeSize: +committeeSize,
      committeeBureauPage: +committeeBureauPage,
      committeeBureauSize: +committeeBureauSize,
      all: senateMeeting.data.all,
      guarantee: senateMeeting.data.guarantee,
      overviewReviewed: senateMeeting.data.overviewReviewed,
      inprogress: senateMeeting.data.inprogress,
      collect: senateMeeting.data.collect,
    },
    committeeMeetingLoader: {
      rows: committeeMeeting.data.pagination.rows,
      totalRows: committeeMeeting.data.pagination.totalRows,
      senatePage: +senatePage,
      senateSize: +senateSize,
      committeePage: +committeePage,
      committeeSize: +committeeSize,
      committeeBureauPage: +committeeBureauPage,
      committeeBureauSize: +committeeBureauSize,
      all: committeeMeeting.data.all,
      guarantee: committeeMeeting.data.guarantee,
      collect: committeeMeeting.data.collect,
      inprogress: committeeMeeting.data.inprogress,
      overviewReviewed: committeeMeeting.data.overviewReviewed,
      withoutAll: committeeMeeting.data.withoutAll,
      withoutGuarantee: committeeMeeting.data.withoutGuarantee,
      withoutCollect: committeeMeeting.data.withoutCollect,
      withoutInprogress: committeeMeeting.data.withoutInprogress,
      withoutOverviewReviewed: committeeBureau.data.withoutOverviewReviewed,
    },
    committeeBureauLoader: {
      rows: committeeBureau.data.pagination.rows,
      totalRows: committeeBureau.data.pagination.totalRows,
      senatePage: +senatePage,
      senateSize: +senateSize,
      committeePage: +committeePage,
      committeeSize: +committeeSize,
      committeeBureauPage: +committeeBureauPage,
      committeeBureauSize: +committeeBureauSize,
      all: committeeBureau.data.all,
      guarantee: committeeBureau.data.guarantee,
      collect: committeeBureau.data.collect,
      inprogress: committeeBureau.data.inprogress,
      overviewReviewed: committeeBureau.data.overviewReviewed,
      withoutAll: committeeBureau.data.withoutAll,
      withoutGuarantee: committeeBureau.data.withoutGuarantee,
      withoutCollect: committeeBureau.data.withoutCollect,
      withoutInprogress: committeeBureau.data.withoutInprogress,
      withoutOverviewReviewed: committeeBureau.data.withoutOverviewReviewed,
    },
    senateMeetingChartLoader: senatMeetingChart.data,
    committeeMeetingChartLoader: committeeMeetingChart.data,
  };
};
