import { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { Rule } from 'models';
import { FaCalendarAlt, FaTimes } from 'react-icons/fa';
import { usePopperTooltip } from 'react-popper-tooltip';
import { getFormatDateBC, getMonthYearBC, getYearBC, thaiFormatDate } from 'utils';
import { LONG_MONTHS } from 'data';
import { Selector } from 'components';

interface Props {
  label?: string;
  placeholder?: string;
  rule?: Rule;
  value?: Date;
  onChange?: (value?: Date) => void;
  name?: string;
  className?: string;
  disabled?: boolean;
  monthYearOnly?: boolean;
  fullDate?: boolean;
  isHideClearButton?: boolean;
}

export function InputDate(props: Props) {
  const [currentDate] = useState(new Date());
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [year, setYear] = useState<number>(currentDate.getUTCFullYear());
  const [month, setMonth] = useState<number>(currentDate.getMonth());
  const [day, setDay] = useState<number>(currentDate.getDate());
  const [dateList, setDateList] = useState<(Date | null)[]>([]);
  const [dateShow, setDateShow] = useState<string>();
  const [value, setValue] = useState<Date | undefined>(props.value);
  const [visible, setVisible] = useState(false);

  document.addEventListener('onSubmitCustom', (data) => {
    const event = data as CustomEvent;

    if (event.detail.hasNameOnly) {
      if (props.name) {
        validate(value);
      }
    } else {
      validate(value);
    }
  });

  useEffect(() => {
    setValue(props.value);

    if (!props.value) {
      setDateShow('');
    }
  }, [props.value]);

  const handlerOnVisibleChange = (value: boolean) => {
    setVisible(value);

    if (!value) {
      setLabelValue();
    }
  };

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
  } = usePopperTooltip({
    trigger: 'click',
    interactive: true,
    onVisibleChange: handlerOnVisibleChange,
    closeOnOutsideClick: true,
    visible,
  });

  const validate = useCallback((value: Date | undefined) => {
    const checkRequired = (value: Date | undefined) => {
      if (props.rule?.required && !value) {
        setErrorMessage('กรุณากรอกข้อมูล');

        return false;
      }

      return true;
    };

    if (!checkRequired(value)) {
      return;
    }

    setErrorMessage('');
  }, [props.rule]);

  useEffect(() => {
    findDaysInMonth(year, month);
  }, [year, month]);

  const setLabelValue = useCallback(() => {
    if (value) {
      const newDate = new Date(value);

      if (props.monthYearOnly) {
        setDay(1);
        setMonth(newDate.getMonth());
        setYear(newDate.getUTCFullYear());

        setDateShow(getMonthYearBC(newDate));
      } else if (props.fullDate) {
        setDay(newDate.getDate());
        setMonth(newDate.getMonth());
        setYear(newDate.getUTCFullYear());

        setDateShow(thaiFormatDate(newDate));
      } else {
        setDay(newDate.getDate());
        setMonth(newDate.getMonth());
        setYear(newDate.getUTCFullYear());

        setDateShow(getFormatDateBC(newDate));
      }
    } else {
      if (props.monthYearOnly) {
        setDay(1);
      } else {
        setDay(currentDate.getDate());
      }

      setMonth(currentDate.getMonth());
      setYear(currentDate.getUTCFullYear());
    }
  }, [currentDate, value]);

  useEffect(() => {
    setLabelValue();
  }, [value, setLabelValue]);

  const getRequired = () => {
    if (props.rule?.required) {
      return <span className="text-danger">*</span>;
    }

    return null;
  };

  const getErrorMessage = useMemo(() => {
    if (errorMessage) {
      return <Form.Text className="text-danger">{errorMessage}</Form.Text>;
    }

    return null;
  }, [errorMessage]);

  const findDaysInMonth = (year: number, month: number) => {
    const dateSelected = new Date(year, month, 1);
    const dayOfFirstDate = dateSelected.getDay();
    const dateList: (Date | null)[] = [];

    Array.from(Array(dayOfFirstDate)).forEach(() => dateList.push(null));

    while (dateSelected.getMonth() === month) {
      const date = new Date(dateSelected);

      dateList.push(date);

      dateSelected.setDate(dateSelected.getDate() + 1);
    }

    setDateList(dateList);
  };

  const calendar = () => {
    const getYears = () => {
      let yearBack = currentDate.getUTCFullYear() - 10;

      return Array.from(Array(101)).map(() => {
        const item = { label: getYearBC(yearBack.toString()), value: yearBack.toString() };

        yearBack += 1;

        return item;
      });
    };

    const getMonths = () => LONG_MONTHS.map((m, i) => ({ label: m.toString(), value: i.toString() }));

    const createDate = (date: Date | null, i: number) => {
      if (!date) {
        return <p key={i} />;
      }

      const dayValue = date.getDate();
      const weekday = date.getDay();
      const isWeekend = weekday === 0 || weekday === 6;

      const onChange = () => {
        setDay(dayValue);
        onSubmit(year, month, dayValue);
      };

      const classNames = `date ${isWeekend ? 'weekend' : ''} ${dayValue === day ? 'active' : ''}`;

      return (
        <p key={i}
          className={classNames}
          onClick={onChange}>
          {dayValue}
        </p>
      );
    };

    const createMonth = (i: number) => {
      const monthData = getMonths()[i];

      const onChange = () => {
        setMonth(i);
        onSubmit(year, i, day);
      };

      return (
        <p
          key={i}
          className={`month ${month === i ? 'active' : ''}`}
          onClick={onChange}
        >
          {monthData.label}
        </p>
      );
    };

    const dates = () => (
      <>
        <div className="year-month-select">
          <Selector
            value={year.toString()}
            items={getYears()}
            onChange={(val) => setYear(+val)}
            size="sm"
            isHideClearButton
            notFixed={true}
          />
          <Selector
            value={month.toString()}
            items={getMonths()}
            onChange={(val) => setMonth(+val)}
            size="sm"
            isHideClearButton
            notFixed={true}
          />
        </div>
        <div className="day-name">
          <p>อา</p>
          <p>จ</p>
          <p>อ</p>
          <p>พ</p>
          <p>พฤ</p>
          <p>ศ</p>
          <p>ส</p>
        </div>
        <div className="date">
          <div className="row-date">
            {Array.from([0, 1, 2, 3, 4, 5, 6]).map((i) => createDate(dateList[i], i))}
          </div>
          <div className="row-date">
            {Array.from([7, 8, 9, 10, 11, 12, 13]).map((i) => createDate(dateList[i], i))}
          </div>
          <div className="row-date">
            {Array.from([14, 15, 16, 17, 18, 19, 20]).map((i) => createDate(dateList[i], i))}
          </div>
          <div className="row-date">
            {Array.from([21, 22, 23, 24, 25, 26, 27]).map((i) => createDate(dateList[i], i))}
          </div>
          {dateList.length >= 28
            ? (
              <div className="row-date">
                {Array.from([28, 29, 30, 31, 32, 33, 34]).map((i) => createDate(dateList[i], i))}
              </div>
            ) : null}
          {dateList.length >= 35
            ? (
              <div className="row-date">
                {Array.from([35, 36]).map((i) => createDate(dateList[i], i))}
              </div>
            ) : null}
        </div>
      </>
    );

    const months = () => (
      <>
        <div className="year-select">
          <Selector
            value={year.toString()}
            items={getYears()}
            onChange={(val) => setYear(+val)}
            size="sm"
            isHideClearButton
          />
        </div>
        <div className="month">
          <div className="row-mount">
            {Array.from([0, 1, 2]).map((i) => createMonth(i))}
          </div>
          <div className="row-mount">
            {Array.from([3, 4, 5]).map((i) => createMonth(i))}
          </div>
          <div className="row-mount">
            {Array.from([6, 7, 8]).map((i) => createMonth(i))}
          </div>
          <div className="row-mount">
            {Array.from([9, 10, 11]).map((i) => createMonth(i))}
          </div>
        </div>
      </>
    );

    return (
      <div className="calendar">
        {!props.monthYearOnly
          ? dates()
          : months()}
      </div>
    );
  };

  const onSubmit = (year: number, month: number, day: number) => {
    const value = new Date(Date.UTC(year, month, day));
    setValue(value);
    validate(value);
    setVisible(false);

    if (props.onChange) {
      props.onChange(value);
    }
  };

  const onClear = () => {
    setValue(undefined);
    setVisible(false);

    if (props.rule?.required) {
      validate(undefined);
    }

    if (props.onChange) {
      props.onChange(undefined);
    }
  };

  return (
    <div className={`date-picker ${props.disabled ? 'disabled' : ''}`}>
      <Form.Group className={`w-100 position-relative ${props.className ?? ''}`}>
        {props.label ? <Form.Label className='m-0'>{props.label} {getRequired()}</Form.Label> : null}
        <InputGroup ref={setTriggerRef}>
          <Form.Control
            readOnly
            className={`none-border-right ${getErrorMessage ? 'is-invalid' : ''}`}
            value={dateShow ?? ''}
            type="text"
            placeholder={props.placeholder}
            disabled={props.disabled} />
          <InputGroup.Text
            className={props.disabled ? '' : 'cursor-pointer'}>
            <FaCalendarAlt />
          </InputGroup.Text>
        </InputGroup>
        {(props.value && (!props.disabled && !props.isHideClearButton)) ?
          <InputGroup.Text
            className="fatime-noborder-nobg cursor-pointer">
            <FaTimes onClick={onClear} />
          </InputGroup.Text>
          : null
        }
        {getErrorMessage}
      </Form.Group>
      {visible && !props?.disabled ? (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: 'tooltip-container',
            style: visible
              ? { visibility: 'visible', zIndex: '4' }
              : { visibility: 'hidden', pointerEvents: 'none' },
          })}
        >
          {calendar()}
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
        </div>
      ) : null}
    </div>
  );
}