import { Card, Input, Layout, Modal, Table } from "components";
import { MeetingTypeModel, Pagination } from "models";
import { Dispatch, FormEvent, SetStateAction, useCallback, useEffect, useState } from "react";
import { Button, Col, Row, Form } from "react-bootstrap";
import { FaEdit, FaPlus, FaSearch, FaTrashAlt } from "react-icons/fa";
import { useLoaderData, useSubmit } from "react-router-dom";
import { calculateRowNumber, fullDateTime, showModalRemoveAsync, submitForm } from "utils";
import { s0101 as service } from 'services';
import { HttpStatusCode } from "axios";
import { MdAddCircleOutline, MdOutlineModeEdit } from "react-icons/md";
import toast from "utils/toast";
import { ModalType } from "constant";

type Loader = { meetingTypeList: Pagination<MeetingTypeModel> };

interface ModalProps {
  show: boolean;
  type: ModalType;
  onHide: () => void;
  meetingType: MeetingTypeModel;
  setMeetingType: Dispatch<SetStateAction<MeetingTypeModel>>;
  onSearch: () => void;
}

export default function S0101() {
  const { meetingTypeList } = useLoaderData() as Loader;
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [meetingType, setMeetingType] = useState<Pagination<MeetingTypeModel>>(meetingTypeList);
  const [meetingTypeSelected, setMeetingTypeSelected] = useState<MeetingTypeModel>({} as MeetingTypeModel);
  const [keyword, setKeyword] = useState<string>("");
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState<ModalType>(ModalType.ADD);
  const submit = useSubmit();

  useEffect(() => {
    setMeetingType(meetingTypeList);
  }, [meetingTypeList]);

  const searchAsync = useCallback(async (page: number, size: number) => {
    submit({
      page: page.toString(),
      size: size.toString(),
      keyword,
    });
  }, [page, size, keyword]);

  const onClearAsync = async () => {
    setKeyword("");
    setPage(1);
    setSize(10);

    const { data, status } = await service.getMeetingTypeListAsync(page, size);

    if (status === HttpStatusCode.Ok) {
      setMeetingType(data);
    }
  };

  const handlerShowModal = (type: ModalType, data?: MeetingTypeModel) => {
    setMeetingTypeSelected(data || {} as MeetingTypeModel);
    setModalType(type);
    setShowModal(true);
  };

  const onRemoveAsync = async (id: string) => {
    const isConfirm = await showModalRemoveAsync();

    if (isConfirm) {
      const { status } = await service.deleteMeetingTypeAsync(id);

      switch (status) {
        case HttpStatusCode.NoContent:
          await searchAsync(page, size);

          toast.success("ลบรายการประเภทการประชุมสำเร็จ");

          break;
        case HttpStatusCode.Conflict:
          toast.warn("ไม่สามารถลบรายการได้ เนื่องจากข้อมูลมีการใช้งานอยู่");

          break;
        case HttpStatusCode.NotFound:
          toast.error("ไม่พบข้อมูลกรุณาลองใหม่อีกครั้ง");

          break;
      }
    }
  };

  const onChangePageSize = (size: number, page: number) => {
    setPage(page);
    setSize(size);

    searchAsync(page, size);
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    searchAsync(page, size);
  };

  return (
    <>
      <Layout title="ประเภทการประชุม">
        <Card>
          <Row>
            <Col md={8} xl={6}>
              <Form className="criteria-flex" onSubmit={onSubmit}>
                <div className="control">
                  <Input
                    label="ค้นหา"
                    placeholder="ประเภทการประชุม"
                    value={keyword}
                    onChange={(value) => setKeyword(value)} />
                </div>
                <div className="button">
                  <Button
                    type="submit"
                    variant="primary">
                    <FaSearch className="me-2" />ค้นหา
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={onClearAsync}>
                    <FaTrashAlt className="me-2" />ล้างค่า
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Card>
        <div className="my-3 d-flex justify-content-end gap-2">
          <Button
            variant="primary"
            type="submit"
            onClick={() => handlerShowModal(ModalType.ADD)}>
            <FaPlus className="me-2" />เพิ่ม
          </Button>
        </div>
        <Table
          total={meetingType.totalRows}
          page={page}
          size={size}
          onChange={onChangePageSize}>
          <Table.Header>
            <Table.Row>
              <Table.Column minWidth={100}>ลำดับ</Table.Column>
              <Table.Column minWidth={250}>ประเภทการประชุม</Table.Column>
              <Table.Column minWidth={100}>ผู้แก้ไขข้อมูล</Table.Column>
              <Table.Column minWidth={100}>วันที่แก้ไขข้อมูล</Table.Column>
              <Table.Column minWidth={100} />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {meetingType.rows?.map((value, index) => (
              <Table.Row key={value.id}>
                <Table.Cell center>{calculateRowNumber(index, page, size).toString()}</Table.Cell>
                <Table.Cell center>{value.name}</Table.Cell>
                <Table.Cell>{value.updateByUserFullName}</Table.Cell>
                <Table.Cell center>{fullDateTime(value.updateDate)}</Table.Cell>
                <Table.Cell center>
                  <FaEdit className='me-2 fs-5' onClick={() => handlerShowModal(ModalType.EDIT, value)} />
                  <FaTrashAlt className="ms-2 fs-5" onClick={() => onRemoveAsync(value.id)} />
                </Table.Cell>
              </Table.Row>))}
          </Table.Body>
        </Table>
      </Layout>
      <MeetingTypeModal
        show={showModal}
        type={modalType}
        onHide={() => setShowModal(!showModal)}
        meetingType={meetingTypeSelected}
        setMeetingType={setMeetingTypeSelected}
        onSearch={() => searchAsync(page, size)} />
    </>
  );
}

function MeetingTypeModal(props: ModalProps) {
  const handleOnSubmitAsync = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    submitForm(props.meetingType);

    const isValid = !props.meetingType.name;

    if (isValid) {
      toast.warn("กรุณากรอกข้อมูลให้ครบถ้วน");

      return;
    }

    if (props.meetingType.id) {
      const { status } = await service.updateMeetingTypeAsync(props.meetingType);

      if (status === HttpStatusCode.Accepted) {
        props.onSearch();

        toast.success("แก้ไขประเภทประชุมสำเร็จ");
      }
    } else {
      const { status } = await service.createMeetingTypeAsync(props.meetingType);

      if (status === HttpStatusCode.Created) {
        props.onSearch();

        toast.success("เพิ่มรายการประเภทการประชุมสำเร็จ");
      }
    }

    onHideModal();
  };

  const onHideModal = () => {
    props.onHide();
  };

  return (
    <Modal
      show={props.show}
      icon={props.type === ModalType.ADD ? <MdAddCircleOutline /> : <MdOutlineModeEdit />}
      title={props.type === ModalType.ADD ? `เพิ่มประเภทการประชุม` : "แก้ไขประเภทการประชุม"}>
      <Form onSubmit={handleOnSubmitAsync}>
        <div className="mt-3">
          <Input
            name="name"
            label="ประเภทการประชุม"
            value={props.meetingType.name}
            onChange={(value) => props.setMeetingType({ ...props.meetingType, name: value })}
            rule={{ required: true, maxLength: 100 }} />
          <div className="button d-flex flex-row gap-3 justify-content-center mt-4">
            <Button
              variant="outline-primary"
              className="w-50"
              onClick={onHideModal}>
              ยกเลิก
            </Button>
            <Button
              variant="primary"
              className="w-50"
              type="submit">
              บันทึก
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
}