import { Card, Input, InputTime, Layout, Selector, Status, StatusType, Table } from "components";
import { StatusChapter, statusChapterItems } from "constant";
import { CtInquiryMeetingDetail, CtInquiryMeetingDetailCriteria, CtInquiryMeetingDetailHeader, Pagination } from "models";
import { SetStateAction, createContext, useContext, useState, Dispatch, useMemo, FormEvent } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaAngleLeft, FaRegFileAlt, FaSearch, FaTrashAlt } from "react-icons/fa";
import { useLoaderData, useNavigate, useSubmit } from "react-router-dom";
import { formatDateTh, getFormatDateBC, getTime, yearToTHFormat } from "utils";
import toast from "utils/toast";

type Loader = { header: CtInquiryMeetingDetailHeader; data: Pagination<CtInquiryMeetingDetail>; };

type CtInquiryDetailContext = {
  data: Pagination<CtInquiryMeetingDetail>;
  criteria: CtInquiryMeetingDetailCriteria;
  setCriteria: Dispatch<SetStateAction<CtInquiryMeetingDetailCriteria>>;
  onSearch: (size: number, page: number) => void;
  onClear: () => void;
};

const Context = createContext({} as CtInquiryDetailContext);

export enum DetailType {
  MEETING = 'meeting',
  MEETING_RANK = 'meetingrank',
}


export default function C0302Detail() {
  const { data } = useLoaderData() as Loader;
  const [criteria, setCriteria] = useState<CtInquiryMeetingDetailCriteria>({} as CtInquiryMeetingDetailCriteria);
  const submit = useSubmit();

  const onSearch = (size: number, page: number) => {
    submit({
      size: size.toString(),
      page: page.toString(),
      criteria: JSON.stringify(criteria),
    });
  };

  const onClear = () => {
    setCriteria({} as CtInquiryMeetingDetailCriteria);

    submit({
      size: 10,
      page: 1,
    });
  };

  const contextValue = useMemo(() => {
    return {
      data,
      criteria,
      setCriteria,
      onSearch,
      onClear,
    }
  }, [data, criteria, setCriteria, onSearch, onClear]);

  return (
    <Context.Provider value={contextValue}>
      <Layout title="รายละเอียดสถานะรายงานการประชุม">
        <HeaderButton />
        <MeetingDetail />
        <Criteria />
        <WritingReportDataTable />
      </Layout>
    </Context.Provider>
  );
}

function HeaderButton() {
  const navigate = useNavigate();

  return (
    <div className="my-3">
      <Button
        variant="outline-primary"
        type="button"
        onClick={() => navigate("/c0302")}>
        <FaAngleLeft className="me-2" />ย้อนกลับ
      </Button>
    </div>
  );
};

function MeetingDetail() {
  const { header } = useLoaderData() as Loader;

  return (
    <Card>
      <label><b>ข้อมูลการประชุม</b></label>
      <Row className="mx-2">
        <Col sm={6} md={4} xxl={3}>
          <p>สำนัก<span className="mx-2">:</span>{header.ctBureauName}</p>
        </Col>
        <Col sm={6} md={4} xxl={3}>
          <p>คณะกรรมาธิการ/อื่นๆ<span className="mx-2">:</span>{header.ctCommitteeName}</p>
        </Col>
        <Col sm={6} md={4} xxl={3}>
          <p>คณะอนุกรรมาธิการ/คณะทำงาน<span className="mx-2">:</span>{header.ctSubCommitteeName}</p>
        </Col>
        <Col sm={6} md={4} xxl={3}>
          <p>ครั้งที่<span className="mx-2">:</span>{`${header.time}`}</p>
        </Col>
        <Col sm={6} md={4} xxl={3}>
          <p>ปี<span className="mx-2">:</span>{`${yearToTHFormat(header.year)}`}</p>
        </Col>
        <Col sm={6} md={4} xxl={3}>
          <p>ณ ห้องประชุม<span className="mx-2">:</span>{header.smMeetingRoomName}</p>
        </Col>
        <Col sm={6} md={4} xxl={3}>
          <p>วันที่ประชุม<span className="mx-2">:</span>{formatDateTh(header.meetingDate)}</p>
        </Col>
        <Col sm={6} md={4} xxl={3}>
          <p>เวลา<span className="mx-2">:</span>{`${getTime(header.startTime)} - ${getTime(header.endTime)}`}</p>
        </Col>
        <Col sm={6} md={4} xxl={3}>
          <div className="d-flex gap-1">สถานะ : <Status type={StatusType.MEETING_STATUS} value={header.status} textOnly /></div>
        </Col>
      </Row>
    </Card>
  );
};

function Criteria() {
  const { data, criteria, setCriteria, onSearch, onClear } = useContext(Context);

  const onChange = (prop: keyof CtInquiryMeetingDetailCriteria, value?: string | number | Date) => {
    setCriteria({ ...criteria, [prop]: value });
  };

  const onSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSearch(data.size, data.page);
  };

  return (
    <Card className="mt-3">
      <Form onSubmit={onSubmitSearch}>
        <Row>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Input
              label="ตอนที่"
              name="chapter"
              type="number"
              value={criteria.chapter}
              onChange={(value) => onChange("chapter", value)} />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <InputTime
              label="ช่วงเวลาเริ่มต้น"
              name="startTime"
              value={criteria.startTime}
              onChange={(value) => onChange("startTime", value)} />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <InputTime
              label="ช่วงเวลาสิ้นสุด"
              name="endTime"
              value={criteria.endTime}
              onChange={(value) => onChange("endTime", value)} />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Input
              label="ผู้จดรายงานการประชุม"
              name="dutyOfficerUserName"
              value={criteria.dutyOfficerUserName}
              onChange={(value) => onChange("dutyOfficerUserName", value)} />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Selector
              label="สถานะ"
              placeholder="สถานะ"
              name="status"
              items={statusChapterItems}
              value={criteria.status}
              onChange={(value) => onChange("status", value)} />
          </Col>
        </Row>
        <Row>
          <Col className="d-flex gap-2 justify-content-start mt-2">
            <Button
              variant="primary"
              type="submit">
              <FaSearch className="me-2" />ค้นหา
            </Button>
            <Button
              variant="outline-primary"
              onClick={onClear}>
              <FaTrashAlt className="me-2" />ล้างค่า
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

function WritingReportDataTable() {
  const navigate = useNavigate();
  const { header } = useLoaderData() as Loader;
  const { data, onSearch } = useContext(Context);

  const onClickCollectDetail = () => {
    if (data.rows.every(d => d.status === StatusChapter.COLLECT ||
      d.status === StatusChapter.GUARANTEE ||
      d.status === StatusChapter.CONFIRM ||
      d.status === StatusChapter.REJECT ||
      d.status === StatusChapter.REVIEWED_DOCUMENT) &&
      data.rows.length > 0) {
      navigate(`document/${DetailType.MEETING}/${header.id}`);

      return;
    }

    toast.warn("ไม่สามารถเข้าได้เนื่องจากยังไม่มีการรวบรวมรายงาน");
  };

  return (
    <>
      <div className="my-3 d-flex align-items-end justify-content-end">
        <Button onClick={onClickCollectDetail}>
          รายงานที่ตรวจสอบและจัดทำรูปเล่มแล้ว
        </Button>
      </div>
      <div className="table-relative-fix">
        <div className="table-scroll">
          <Table
            total={data.totalRows}
            page={data.page}
            size={data.size}
            onChange={onSearch}>
            <Table.Header>
              <Table.Row>
                <Table.Column minWidth={75}>ตอนที่</Table.Column>
                <Table.Column minWidth={100}>ช่วงเวลา</Table.Column>
                <Table.Column minWidth={150}>ผู้จดรายงานการประชุม</Table.Column>
                <Table.Column minWidth={150}>ผู้ตรวจทาน</Table.Column>
                <Table.Column minWidth={100}>สถานะ</Table.Column>
                <Table.Column minWidth={100}>แก้ไขครั้งล่าสุด</Table.Column>
                <Table.Column minWidth={100}>เวอร์ชั่น</Table.Column>
                <Table.Column minWidth={150} className="fix-col" />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.rows?.map((value) => (
                <Table.Row key={value.id}>
                  <Table.Cell center>{`${value.chapter}`}</Table.Cell>
                  <Table.Cell center>{`${getTime(value.startTime)} - ${getTime(value.endTime)}`}</Table.Cell>
                  <Table.Cell center>{value.dutyOfficerFullName}</Table.Cell>
                  <Table.Cell>{value.reviewUserFullName}</Table.Cell>
                  <Table.Cell center><Status type={StatusType.CHAPTER_STATUS} value={value.status} /></Table.Cell>
                  <Table.Cell center>{getFormatDateBC(value.updateDate)}</Table.Cell>
                  <Table.Cell center>{value.versionDocument}</Table.Cell>
                  <Table.Cell center className="fix-col">
                    <div className="d-flex justify-content-center">
                      <Button
                        variant="outline-primary"
                        size="sm"
                        onClick={() => navigate(`document/${DetailType.MEETING_RANK}/${value.id}`, { state: value })}>
                        <FaRegFileAlt className="me-2" />
                        ดูรายละเอียด
                      </Button>
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </div>
    </>
  );
};