import { Card, Input, InputDate, Layout, Selector, Status, StatusType, Table } from "components";
import { StatusMeeting, statusMeetingCommitteeItems } from "constant";
import { Pagination, CtMeetingRecord, CtMeetingRecordCriteria, ItemModel } from "models";
import { Dispatch, FormEvent, SetStateAction, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaRegFileAlt, FaSearch, FaTrashAlt } from "react-icons/fa";
import { useLoaderData, useNavigate, useSubmit } from "react-router-dom";
import { formatDateTh, fullDateTime, yearToTHFormat } from "utils";

type Loader = { listData: Pagination<CtMeetingRecord>; bureauDDL: ItemModel[]; committeeDDL: ItemModel[]; subCommitteeDDL: ItemModel[]; yearDDL: ItemModel[]; smUser: ItemModel[]; };

type CtMeetingRecordContext = {
  criteria: CtMeetingRecordCriteria;
  setCriteria: Dispatch<SetStateAction<CtMeetingRecordCriteria>>;
  search: (size: number, page: number, criteria?: CtMeetingRecordCriteria) => void;
  onClear: () => void;
  list: Pagination<CtMeetingRecord>;
};

const Context = createContext({} as CtMeetingRecordContext);

export default function C0203() {
  const { listData } = useLoaderData() as Loader;
  const [list, setList] = useState<Pagination<CtMeetingRecord>>(listData);
  const [criteria, setCriteria] = useState<CtMeetingRecordCriteria>({} as CtMeetingRecordCriteria);
  const submit = useSubmit();

  useEffect(() => {
    setList(listData);
  }, [listData]);

  const search = useCallback((size: number, page: number, criteria?: CtMeetingRecordCriteria) => {
    submit({
      page: page.toString(),
      size: size.toString(),
      criteria: JSON.stringify(criteria),
    });
  }, []);

  const onClear = () => {
    setCriteria({} as CtMeetingRecordCriteria);

    submit({
      page: 1,
      size: 10,
    });
  };

  const contextValue = useMemo(() => {
    return {
      criteria,
      setCriteria,
      search,
      onClear,
      list,
    }
  }, [criteria, setCriteria, search, onClear, list]);

  return (
    <Context.Provider value={contextValue}>
      <Layout title="บันทึกข้อมูลภาพและเสียงการประชุม">
        <Criteria />
        <DataTable />
      </Layout>
    </Context.Provider>
  );
};

function Criteria() {
  const { bureauDDL, committeeDDL, subCommitteeDDL, yearDDL, smUser } = useLoaderData() as Loader;
  const [subCommitteeItems, setSubCommitteeItems] = useState<ItemModel[]>([...subCommitteeDDL]);
  const { criteria, setCriteria, search, list, onClear } = useContext(Context);

  const statusItems = [
    StatusMeeting.CONFIRM,
    StatusMeeting.RECORD,
    StatusMeeting.RECORDED,
  ];

  const filterStatus = useMemo(() => statusMeetingCommitteeItems.filter(s => statusItems.includes(s.value)), [statusMeetingCommitteeItems]);

  const onChange = (prop: keyof CtMeetingRecordCriteria, value?: string | number | Date) => {
    setCriteria({ ...criteria, [prop]: value });
  };

  useEffect(() => {
    if (criteria.committeeId) {
      const selectedId = committeeDDL.find(f => f.value === criteria.committeeId)?.id;

      const filterItems = subCommitteeDDL.filter(f => f.depenId === selectedId);

      setSubCommitteeItems(filterItems);

      return;
    }

    setCriteria({ ...criteria, subCommitteeId: "" });
    setSubCommitteeItems(subCommitteeDDL);
  }, [criteria.committeeId]);

  const onSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    search(list.size, list.page, criteria);
  };

  return (
    <Card>
      <Form onSubmit={onSubmitSearch}>
        <Row>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Selector
              label="สำนัก"
              placeholder="สำนัก"
              items={bureauDDL}
              value={criteria.bureauId}
              onChange={(value) => setCriteria({ ...criteria, bureauId: value.toString() })} />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Selector
              label="คณะกรรมาธิการ/อื่นๆ"
              placeholder="คณะกรรมาธิการ/อื่นๆ"
              items={committeeDDL}
              value={criteria.committeeId}
              onChange={(value) => onChange("committeeId", value)} />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Selector
              label="คณะอนุกรรมาธิการ/คณะทำงาน"
              placeholder="คณะอนุกรรมาธิการ/คณะทำงาน"
              items={subCommitteeItems}
              value={criteria.subCommitteeId}
              onChange={(value) => onChange("subCommitteeId", value)} />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Input
              label="ครั้งที่ประชุม"
              type="number"
              value={criteria.time}
              onChange={(value) => onChange("time", value)} />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Selector
              label="ปี"
              placeholder="ปี"
              items={yearDDL}
              value={criteria.year}
              onChange={(value) => onChange("year", value)} />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <InputDate
              label="วันที่ประชุม"
              value={criteria.date}
              onChange={(value) => onChange("date", value)} />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Selector
              label="ชื่อผู้จด"
              placeholder="ชื่อผู้จด"
              value={criteria.dutyOfficerUserId}
              items={smUser}
              onChange={(value) => onChange('dutyOfficerUserId', value)} />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Selector
              label="สถานะ"
              placeholder="สถานะ"
              value={criteria.status}
              items={filterStatus}
              onChange={(value) => onChange("status", value)} />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="d-flex gap-2 justify-content-start">
            <Button
              variant="primary"
              type="submit">
              <FaSearch className="me-2" />ค้นหา
            </Button>
            <Button
              variant="outline-primary"
              onClick={onClear}>
              <FaTrashAlt className="me-2" />ล้างค่า
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

function DataTable() {
  const { list, criteria, search } = useContext(Context);
  const navigate = useNavigate();

  const getMeetingTime = (timeFirst: string, timeSecond?: string) => {
    if (timeFirst && timeSecond) {
      return <>{timeFirst}/<br />{timeSecond}</>
    } else if (timeSecond) {
      return timeSecond;
    } else {
      return timeFirst;
    }
  };

  return (
    <div className="table-relative-fix">
      <div className="table-scroll">
        <Table
          total={list.totalRows}
          page={list.page}
          size={list.size}
          onChange={(size, page) => search(size, page, criteria)}
          className='mt-3'>
          <Table.Header>
            <Table.Row>
              <Table.Column minWidth={50}>ลำดับ</Table.Column>
              <Table.Column minWidth={120}><>ประเภทการ<br />ประชุม</></Table.Column>
              <Table.Column minWidth={150}>ห้องประชุม</Table.Column>
              <Table.Column minWidth={100}>วันที่ประชุม</Table.Column>
              <Table.Column minWidth={130}>เวลาประชุม</Table.Column>
              <Table.Column minWidth={150}>สำนัก</Table.Column>
              <Table.Column minWidth={180}>คณะกรรมาธิการ/อื่นๆ</Table.Column>
              <Table.Column minWidth={180}>ชื่อการประชุม</Table.Column>
              <Table.Column minWidth={50}>ปี</Table.Column>
              <Table.Column minWidth={150}>สถานะ</Table.Column>
              <Table.Column minWidth={200}>ผู้แก้ไขข้อมูล</Table.Column>
              <Table.Column minWidth={150}>วันที่แก้ไขข้อมูล</Table.Column>
              <Table.Column minWidth={150} className="fix-col" />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {list.rows?.map((value, index) => (
              <Table.Row key={value.id}>
                <Table.Cell center>{index + 1}</Table.Cell>
                <Table.Cell center>{value.meetingType}</Table.Cell>
                <Table.Cell center>{value.meetingRoomName}</Table.Cell>
                <Table.Cell center>{formatDateTh(value.meetingDate)}</Table.Cell>
                <Table.Cell center>{getMeetingTime(value.meetingTimeFirst, value.meetingTimeSecond)}</Table.Cell>
                <Table.Cell center>{value.bureauName}</Table.Cell>
                <Table.Cell>
                  {value.committeeShortName ? value.committeeShortName : value.committeeName}
                </Table.Cell>
                <Table.Cell>
                  {value.subCommitteeShortName ? value.subCommitteeShortName : value.name}
                </Table.Cell>
                <Table.Cell center>{yearToTHFormat(value.year)}</Table.Cell>
                <Table.Cell center><Status type={StatusType.MEETING_STATUS} value={value.status} committee /></Table.Cell>
                <Table.Cell>{value.updateByUserFullName}</Table.Cell>
                <Table.Cell center>{fullDateTime(value.updateDate)}</Table.Cell>
                <Table.Cell center className="fix-col">
                  <Button
                    variant="outline-primary"
                    size='sm'
                    onClick={() => navigate(`detail/${value.id}`)}>
                    <FaRegFileAlt className="me-2" />ดูรายละเอียด
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};