import { SelectionType } from 'constant';
import { InQueryS1Criteria, InQueryS1Detail, InQueryS1DetailCriteria, InQueryS1List, ItemModel, Pagination } from 'models';
import { LoaderFunctionArgs } from 'react-router-dom';
import { s0301 as service, selectionService as ddlService, s0205, snVideoService, s0204 } from 'services';
import { getYearList } from 'utils';

export const s0301LoaderAsync = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 1;
  const size = url.searchParams.get('size') || 10;
  const criteriaStringify = url.searchParams.get('criteria') || '{}';
  const criteria = JSON.parse(criteriaStringify) as InQueryS1Criteria;

  const yearsDDL: ItemModel[] = getYearList();
  const [meetingPeriodDDL, meetingTypeDDL, listData] = await Promise.all([
    ddlService.getSelectionAsync(SelectionType.SN_MEETING_PERIOD),
    ddlService.getSelectionAsync(SelectionType.SN_MEETING_TYPE),
    service.getListAsync(+page, +size, criteria),
  ]);

  const dataResult: Pagination<InQueryS1List> = listData.data || {} as Pagination<InQueryS1List>;
  dataResult.page = +page;
  dataResult.size = +size;

  return {
    meetingTypeDDL: meetingTypeDDL.data,
    meetingPeriodDDL: meetingPeriodDDL.data,
    listData: dataResult,
    yearsDDL: yearsDDL,
  };
};

export const s0301DetailLoaderAsync = async ({ request, params }: LoaderFunctionArgs) => {
  const { id } = params;
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 1;
  const size = url.searchParams.get('size') || 10;
  const criteriaStringify = url.searchParams.get('criteria') || '{}';
  const criteria = JSON.parse(criteriaStringify) as InQueryS1DetailCriteria;

  const [detailData, detailListData, agendaDDLResponse] = await Promise.all([
    service.getDetailAsync(id),
    service.getDetailListAsync(+page, +size, criteria, id),
    s0205.getAgendaDDLAsync(id),
  ]);

  const dataResult: Pagination<InQueryS1Detail> = detailListData.data || {} as Pagination<InQueryS1Detail>;
  dataResult.page = +page;
  dataResult.size = +size;

  return {
    headerData: detailData.data,
    listData: dataResult,
    agendaDDL: agendaDDLResponse.data,
  };
};

export const s0301DocumentLoaderAsync = async ({ params }: LoaderFunctionArgs) => {
  const { id, docId } = params;

  if (docId && id) {
    const [document, fileId] = await Promise.all([s0204.getSnMakeDocumentDetailAsync(docId), snVideoService.getRecordFileIdAsync(docId, id!)])

    return {
      documentData: document.data,
      fileId: fileId.data,
    }
  }
};