interface Props {
  children: JSX.Element | JSX.Element[];
}

function Body(props: Props) {
  return (
    <tbody>
      {props.children}
    </tbody>
  );
}

export { Body };