import { Card } from "components";
import SpeechToText from "components/SpeechToText";
import { VideoControl } from "constant";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { Button, Form, ListGroup } from "react-bootstrap";
import { FaBackward, FaExclamationCircle, FaForward, FaPause, FaPlay, FaTimes } from "react-icons/fa";
import { showModalConfirmAsync } from "utils";

export interface VideoOption {
  playRef: () => void;
  backRef: () => void;
  aheadRef: () => void;
}

enum UploadType {
  Normal,
  Secret
}

interface PlayerProps {
  path: string;
  showPlayButton?: boolean;
  showPlayTimeConfig?: boolean;
  showSpeakToText?: boolean;

  uploadSection?: boolean;
  uploadSecretSection?: boolean;
  uploadVideo?: (uploadFile: File) => void;

  onEnded?: () => void;
  onLoadedData?: () => void;
}

const VideoPlayer = forwardRef<VideoOption, PlayerProps>((props, ref) => {
  const videoRef = useRef<HTMLVideoElement>({} as HTMLVideoElement);
  const [played, setPlayed] = useState<boolean>(false);
  const [back, setBack] = useState<number | undefined>(5);
  const [ahead, setAhead] = useState<number | undefined>(5);
  const [videoActive, setVideoActive] = useState<number | undefined>();
  const [secretFile, setSecretFile] = useState<File>({} as File);
  const [uploadSecret, setUploadSecret] = useState<boolean>(false);

  function setVideoRef(path: string, type?: string) {
    if (path && !type) {
      videoRef.current.src = props.path;
      videoRef.current.load();

      setVideoActive(0);

      return;
    }

    if (type) {
      videoRef.current.src = URL.createObjectURL(secretFile);
      videoRef.current.load();

      return;
    }

    videoRef.current.src = "";
    videoRef.current.load();
  }

  useEffect(() => {
    setVideoRef(props.path, secretFile.type);
  }, [props.path, secretFile]);

  useImperativeHandle(ref, () => ({
    playRef() {
      play();
    },
    backRef() {
      scrollBack();
    },
    aheadRef() {
      scrollAhead();
    },
  }));

  const play = useCallback(() => {
    if (videoActive === undefined) {
      return;
    }

    if (played) {
      videoRef.current.pause();
      setPlayed(false);

      return;
    }

    videoRef.current.currentTime = videoRef.current.currentTime - 2;
    videoRef.current.play();
    setPlayed(true);
  }, [videoActive, played]);

  const onPlay = useCallback(() => {
    setPlayed(true);
  }, []);

  const onPause = useCallback(() => {
    setPlayed(false);
  }, []);

  const scrollBack = useCallback(() => {
    if (back) {
      videoRef.current.currentTime = videoRef.current.currentTime - back;
    }
  }, [back]);

  const scrollAhead = useCallback(() => {
    if (ahead) {
      videoRef.current.currentTime = videoRef.current.currentTime + ahead;
    }
  }, [ahead]);

  const browseVideoRef = useRef<HTMLInputElement>({} as HTMLInputElement);

  useEffect(() => {
    if (props.showPlayButton) {
      document.onkeydown = (e) => {
        switch (e.key) {
          case VideoControl.F10:
            scrollBack();
            return false;
          case VideoControl.F9:
            play();
            return false;
          case VideoControl.F11:
            if (e.ctrlKey) {
              scrollAhead();
              return false;
            }
        }
      }
    }
  }, [props.showPlayButton, scrollBack, play, scrollAhead]);

  const onDeleteSecretFiles = async () => {
    videoRef.current.pause();
    setPlayed(false);
    setSecretFile({} as File);
    browseVideoRef.current.value = '';
  };

  const onSelectFileHandler = useCallback((event: HTMLInputElement) => {
    const file = event.files?.length ? event.files[0] : null;

    if (!file) {
      return;
    }

    if (uploadSecret) {
      setSecretFile(file);
      setUploadSecret(false);
      browseVideoRef.current.value = '';

      return;
    }

    props.uploadVideo!(file);
  }, [secretFile, uploadSecret]);

  const getVideoList = useMemo(() => {
    if (secretFile.type) {
      return (
        <>
          <span>รายการวิดีโอ</span>
          <ListGroup className='mt-1'>
            <ListGroup.Item key={secretFile.size} active={videoActive === 0}>
              <div className='d-flex justify-content-between align-items-center'>
                {secretFile.name} <FaTimes onClick={onDeleteSecretFiles} />
              </div>
            </ListGroup.Item>
          </ListGroup>
        </>);
    }
  }, [secretFile, videoActive]);


  const onClickUploadVideoAsync = async (validateType: UploadType) => {
    switch (validateType) {
      case UploadType.Secret:
        setUploadSecret(true);
        browseVideoRef.current.click();

        break;
      case UploadType.Normal:
        const path = props.path;
        const showModal = await showModalConfirmAsync("ท่านต้องการแนบไฟล์วิดีโอหรือไม่ ?", "วิดีโอใหม่ที่แนบจะทับวิดีโอเดิม", <FaExclamationCircle className="text-primary" />);

        if (path && !showModal) {
          return;
        }

        browseVideoRef.current.click();

        break;
    }
  };

  return (
    <>
      <video
        className='w-100'
        controls
        ref={videoRef}
        onPlay={onPlay}
        onPause={onPause}
        onEnded={props.onEnded ?? undefined}
        onLoadedData={props.onLoadedData ?? undefined}>
        <source />
      </video>
      <div>
        {props.uploadSection ?
          <Button
            variant='outline-primary'
            className='w-100 mt-2 d-flex justify-content-center align-items-center fs-7'
            onClick={() => onClickUploadVideoAsync(UploadType.Normal)}>
            เพิ่มวิดีโอ
          </Button> : null}
        {props.uploadSecretSection ?
          <>
            {getVideoList}
            <Button
              variant='outline-primary'
              className='w-100 mt-2 d-flex justify-content-center align-items-center fs-7'
              onClick={() => onClickUploadVideoAsync(UploadType.Secret)}>
              เพิ่มวิดีโอ ( ประชุมลับ )
            </Button>
          </> : null
        }
        <Form.Control
          ref={browseVideoRef}
          type='file'
          accept='video/mp4, video/x-m4v, video/*, audio/mp3,audio/*'
          className='d-none'
          onChange={event => onSelectFileHandler(event.target as HTMLInputElement)} />
      </div>
      {
        props.showPlayButton && <div className="d-flex justify-content-center gap-2 mt-2">
          <Button
            variant='outline-primary'
            disabled={videoActive === undefined}
            className='w-100 fs-7'
            onClick={scrollBack}>
            <FaBackward className="me-2" /> ย้อนหลัง (F10)
          </Button>
          <Button
            id='stopPlay'
            variant='outline-primary'
            disabled={videoActive === undefined}
            className='w-100 fs-7'
            onClick={play}>
            {played ?
              <><FaPause className="mx-1" /> หยุดชั่วคราว (F9)</> :
              <><FaPlay className="mx-1" /> เล่น (F9)</>}
          </Button>
          <Button
            variant='outline-primary'
            disabled={videoActive === undefined}
            className='w-100 fs-7'
            onClick={scrollAhead}>
            เดินหน้า (Ctrl + F11) < FaForward className="mx-2" />
          </Button>
        </div>
      }
      {
        props.showSpeakToText && <SpeechToText />
      }
      {
        props.showPlayTimeConfig ?
          <Card className="mt-2 border-primary">
            <Form.Label className="text-primary">
              ตั้งค่ากำหนดเวลา
            </Form.Label>
            <div className="d-flex justify-content-center gap-3 mt-4">
              <p className="text-primary">ย้อนหลัง</p>
              <div className="w-25">
                <Form.Control
                  name='back'
                  type='number'
                  value={back}
                  onChange={event => setBack(+event.target.value)} />
              </div>
              <p className="text-primary">วินาที</p>
            </div>
            <div className="d-flex justify-content-center gap-3 mt-4">
              <p className="text-primary">เดินหน้า</p>
              <div className="w-25">
                <Form.Control
                  name='front'
                  type='number'
                  value={ahead}
                  onChange={event => setAhead(+event.target.value)} />
              </div>
              <p className="text-primary">วินาที</p>
            </div>
          </Card> : null
      }
    </>
  );
});

export default VideoPlayer;
