import { Card, Input, Layout, Table, Modal } from "components";
import { MeetingRoomListModel, MeetingRoomModel } from "models";
import { Dispatch, FormEvent, SetStateAction, useState, useCallback, useEffect } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import { FaEdit, FaPlus, FaSearch, FaTrashAlt } from "react-icons/fa";
import { calculateRowNumber, fullDateTime, showModalRemoveAsync, submitForm } from "utils";
import { m0101 as service } from "services";
import { HttpStatusCode } from "axios";
import { useLoaderData } from "react-router-dom";
import { MdAddCircleOutline, MdOutlineModeEdit } from "react-icons/md";
import toast from "utils/toast";

enum ModalType {
  ADD = "Add",
  EDIT = "Edit",
};

type Loader = { meetingRoomList: MeetingRoomListModel };

interface AddModalProps {
  modalShow: boolean,
  modalType: ModalType,
  onHideModal: () => void,
  meetingRoom: MeetingRoomModel,
  setMeetingRoom: Dispatch<SetStateAction<MeetingRoomModel>>,
  setList: Dispatch<SetStateAction<MeetingRoomListModel>>,
  page: number,
  size: number,
};

export default function M0101() {
  const { meetingRoomList } = useLoaderData() as Loader;
  const [commissionName, setCommisionName] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [mount, setMount] = useState<boolean>(false);
  const [modalType, setModalType] = useState<ModalType>(ModalType.ADD);
  const [meetingRoom, setMeetingRoom] = useState<MeetingRoomListModel>(meetingRoomList);
  const [meetingRoomSelected, setMeetingRoomSelected] = useState<MeetingRoomModel>({} as MeetingRoomModel);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);

  const handleDeleteAsync = async (id: string) => {
    const confirm = await showModalRemoveAsync();

    if (confirm) {
      const { status } = await service.deleteMeetingRoomAsync(id);

      switch (status) {
        case HttpStatusCode.NoContent:
          handleClearAsync();

          toast.success("ลบรายการห้องประชุมสำเร็จ");
          break;
        case HttpStatusCode.NotFound:
          toast.error("ไม่พบข้อมูล");

          break;
        case HttpStatusCode.Conflict:
          toast.warn("ไม่สามารถลบได้เนื่องจากข้อมูลมีการใช้งานอยู่");

          break;
      }
    }
  };

  const handleOnSubmitAsync = useCallback(async (page: number, size: number, commissionNameData?: string) => {
    const { data, status } = await service.getMeetingRoomListAsync(page, size, commissionNameData ? commissionNameData : commissionName);

    if (status === HttpStatusCode.Ok) {
      setMeetingRoom(data);
    }
  }, [page, size]);

  useEffect(() => {
    if (mount) {
      handleOnSubmitAsync(page, size);
    }

    !mount && setMount(true);
  }, [handleOnSubmitAsync]);

  const handleClearAsync = async () => {
    setCommisionName("");
    setPage(1);
    setSize(10);

    const { data, status } = await service.getMeetingRoomListAsync(1, 10);

    if (status === HttpStatusCode.Ok) {
      setMeetingRoom(data);
    }
  };

  const openModal = (type: ModalType, data?: MeetingRoomModel) => {
    setMeetingRoomSelected(data || {} as MeetingRoomModel);
    setModalShow(true);
    setModalType(type);
  };

  const onChangePageSize = (size: number, page: number) => {
    setPage(page);
    setSize(size);
  };

  const onSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    handleOnSubmitAsync(page, size, commissionName);
  };

  return (
    <Layout title="ห้องประชุม">
      <Card>
        <Form onSubmit={onSubmitSearch}>
          <Row>
            <Col sm={12} lg={4}>
              <Input
                value={commissionName}
                label="ค้นหา"
                placeholder="ชื่อห้องประชุม"
                onChange={(value) => setCommisionName(value)} />
            </Col>
            <Col sm={12} lg={8}>
              <div className="criteria-flex">
                <div className="button">
                  <Button
                    variant="primary"
                    type="submit">
                    <FaSearch className="me-2" />ค้นหา
                  </Button>
                  <Button
                    variant="outline-primary"
                    type="button"
                    onClick={handleClearAsync}>
                    <FaTrashAlt className="me-2" />ล้างค่า
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </Card>
      <div className="d-flex justify-content-end my-3">
        <Button
          variant="primary"
          type="button"
          onClick={() => openModal(ModalType.ADD)}>
          <FaPlus className="me-2" />เพิ่ม
        </Button>
      </div>
      <Row className="mt-3">
        <Col>
          <div className="table-relative-fix">
            <div className="table-scroll">
              <Table
                total={meetingRoom.totalRows}
                page={page}
                size={size}
                onChange={onChangePageSize}>
                <Table.Header>
                  <Table.Row>
                    <Table.Column minWidth={200}>ลำดับ</Table.Column>
                    <Table.Column minWidth={250}>ห้องประชุม</Table.Column>
                    <Table.Column minWidth={250}>Stream URL</Table.Column>
                    <Table.Column minWidth={250}>ผู้แก้ไขข้อมูล</Table.Column>
                    <Table.Column minWidth={200}>วันที่แก้ไขข้อมูล</Table.Column>
                    <Table.Column minWidth={200} className="fix-col" />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {meetingRoom.rows?.map((meetingRoomValue, meetingRoomIndex) => (
                    <Table.Row key={meetingRoomValue.id}>
                      <Table.Cell center>{calculateRowNumber(meetingRoomIndex, page, size).toString()}</Table.Cell>
                      <Table.Cell>{meetingRoomValue.name}</Table.Cell>
                      <Table.Cell>{meetingRoomValue.streamUrl}</Table.Cell>
                      <Table.Cell>{meetingRoomValue.updateByUserFullName}</Table.Cell>
                      <Table.Cell center>{fullDateTime(meetingRoomValue.updateDate)}</Table.Cell>
                      <Table.Cell center className="fix-col">
                        <div className="d-flex justify-content-center align-items-center fs-5">
                          <div>
                            <FaEdit className="cursor-pointer text-primary" onClick={() => openModal(ModalType.EDIT, meetingRoomValue)} />
                          </div>
                          <div className="mx-4">
                            <FaTrashAlt className="cursor-pointer text-primary" onClick={() => handleDeleteAsync(meetingRoomValue.id)} />
                          </div>
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          </div>
        </Col>
      </Row>
      <MeetingModal
        modalShow={modalShow}
        modalType={modalType}
        onHideModal={() => setModalShow(false)}
        meetingRoom={meetingRoomSelected}
        setMeetingRoom={setMeetingRoomSelected}
        page={page}
        size={size}
        setList={setMeetingRoom} />
    </Layout>
  );
}

function MeetingModal(props: AddModalProps) {
  const handleOnSubmitAsync = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    submitForm(props.meetingRoom);

    const isValid = !props.meetingRoom.name;

    if (isValid) {
      toast.warn("กรุณากรอกข้อมูลให้ครบถ้วน");

      return;
    }

    if (props.meetingRoom.id) {
      const { status } = await service.updateMeetingRoomAsync(props.meetingRoom);

      if (status === HttpStatusCode.Accepted) {
        const getDetail = await service.getMeetingRoomListAsync(props.page, props.size);

        if (getDetail.status === HttpStatusCode.Ok) {
          props.setList(getDetail.data);

          toast.success("แก้ไขรายการห้องประชุมสำเร็จ");
        }
      }
    } else {
      const { status } = await service.createMeetingRoomAsync(props.meetingRoom);

      if (status === HttpStatusCode.Created) {
        const getDetail = await service.getMeetingRoomListAsync(props.page, props.size);

        if (getDetail.status === HttpStatusCode.Ok) {
          props.setList(getDetail.data);
        }

        toast.success("เพิ่มรายการห้องประชุมสำเร็จ");
      }
    }

    onHideModal();
  };

  const onHideModal = () => {
    props.onHideModal();
  };

  return (
    <Modal
      show={props.modalShow}
      icon={props.modalType === ModalType.ADD ? <MdAddCircleOutline /> : <MdOutlineModeEdit />}
      title={`${props.modalType === ModalType.ADD ? "เพิ่ม" : "แก้ไข"}ห้องประชุม`}>
      <Form onSubmit={handleOnSubmitAsync}>
        <div className="mt-3">
          <Input
            name="name"
            label="ห้องประชุม"
            value={props.meetingRoom.name}
            onChange={(value) => props.setMeetingRoom({ ...props.meetingRoom, name: value })}
            rule={{ required: true, maxLength: 100 }} />
          <Input
            name="streamUrl"
            label="Stream URL"
            value={props.meetingRoom.streamUrl}
            onChange={(value) => props.setMeetingRoom({ ...props.meetingRoom, streamUrl: value })} />
          <div className="button d-flex flex-row gap-3 justify-content-end">
            <Button
              variant="outline-primary"
              type="button"
              onClick={onHideModal}>
              ยกเลิก
            </Button>
            <Button
              variant="primary"
              type="submit">
              ยืนยัน
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
}