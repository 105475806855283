import { useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Rule } from 'models';
import Select, { SingleValue, StylesConfig } from 'react-select';

interface Options {
  value: string | number;
  label: string;
}

interface Props {
  label?: string;
  placeholder?: string;
  rule?: Rule;
  value?: string | number;
  onChange?: (value: string | number) => void;
  name?: string;
  items?: Options[];
  className?: string;
  size?: 'sm' | 'lg';
  disabled?: boolean;
  disabledStar?: boolean;
  isHideClearButton?: boolean;
  notFixed?: boolean;
  isSearchable?: boolean;
}

export function Selector(props: Props) {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [value, setValue] = useState<SingleValue<Options> | null>();

  document.addEventListener('onSubmitCustom', (data) => {
    const event = data as CustomEvent;

    if (event.detail.hasNameOnly) {
      if (props.name) {
        validate(value);
      }
    } else {
      validate(value);
    }
  });

  useEffect(() => {
    const item = props.items?.find((i) => i.value === props.value);

    if (item) {
      setValue({ ...item });
    } else {
      setValue(null);
    }
  }, [props.value, props.items]);

  const validate = useCallback((value: SingleValue<Options> | undefined) => {
    const checkRequired = (value: SingleValue<Options> | undefined) => {
      if (props.rule?.required && !value?.value) {
        setErrorMessage('กรุณาเลือกข้อมูล');

        return false;
      }

      return true;
    };

    if (!checkRequired(value)) {
      return;
    }

    setErrorMessage('');
  }, [props.rule?.required]);

  const getRequired = () => {
    if (props.rule?.required && props.disabledStar) {
      return null;
    }

    if (props.rule?.required) {
      return <span className="text-danger">*</span>;
    }

    return null;
  };

  const getErrorMessage = useMemo(() => {
    if (errorMessage) {
      return <Form.Text className="text-danger">{errorMessage}</Form.Text>;
    }

    return null;
  }, [errorMessage]);

  const handlerOnChange = (value: SingleValue<Options>) => {
    if (props.onChange) {
      if (value) {
        props.onChange(value.value);

        setValue(value);
      } else {
        props.onChange('');

        setValue(null);
      }
    }

    validate(value);
  };

  const styles: StylesConfig = {
    option: (styles, state) => ({
      ...styles,
      backgroundColor:
        state.isSelected ? '#6A0F0F' : undefined,
      zIndex: "4",
      ':active': {
        ...styles[':active'],
        backgroundColor: '#d14b4b',
        color: 'black',
      },
      ':hover': {
        ...styles[':hover'],
        backgroundColor: '#d14b4b',
        color: 'black',
      },
    }),
    valueContainer: (styles) => ({
      ...styles,
      maxHeight: 35,
      alignItems: 'center',
      display: 'grid',
      flex: 1,
      flexWrap: 'wrap',
      position: 'relative',
      overflow: 'hidden',
      boxSizing: 'border-box',
      padding: "0vw 0.5vw",
    }),
  };

  const NoOptionMessage = () => {
    return <p className="m-0">ไม่มีข้อมูล</p>;
  }

  return (
    <Form.Group className={`select w-100 ${props.className ?? ''} ${props.label ? 'mb-3' : ''}`}>
      {props.label ? <Form.Label className='m-0'>{props.label} {getRequired()}</Form.Label> : null}
      <Select
        className={`${getErrorMessage ? 'is-invalid' : ''} ${props.disabled ? 'disabled' : ''}`}
        value={value}
        onChange={(event) => handlerOnChange(event as SingleValue<Options>)}
        options={props.items}
        placeholder={props.placeholder || ''}
        isDisabled={props.disabled}
        styles={styles}
        menuShouldBlockScroll
        isClearable={!props.isHideClearButton}
        noOptionsMessage={NoOptionMessage}
        menuPosition={!props.notFixed ? "fixed" : "absolute"}
        isSearchable={props.isSearchable ?? true} />
      {getErrorMessage}
    </Form.Group>
  );
}