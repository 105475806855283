import { useRef } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { FaCloudUploadAlt, FaExclamationCircle } from "react-icons/fa";
import { showModalConfirmAsync } from "utils";
import toast from "utils/toast";

export interface VideoValue {
  id?: string;
  name?: string;
  file?: File;
  fileName?: string;
}

interface Props {
  type?: string;
  value?: File | Blob;
  onChange?: (value: File | Blob) => void;
  name?: string;
  className?: string;
  disabled?: boolean;
  confirm?: boolean;
}

export function VideoBrowse(props: Props) {
  const ref = useRef({} as HTMLInputElement);
  const fileType = [
    "mp4",
    "mov",
    "avi",
    "wmv",
    "avchd",
    "webm",
    "flv",
    "mkv",
    "mp3",
    "wav",
    "ogg",
    "wma",
    "cda",
    "aiff",
    "aac",
    "m4a",
  ];

  const handlerOnChange = (event: HTMLInputElement) => {

    if (event.files?.length) {
      const file = event.files[0];

      if (!fileType.some(f => file.name.includes(f))) {
        toast.warn("ประเภทไฟล์ไม่ถูกต้อง");

        return;
      }

      if (props.onChange) {
        props.onChange(file);
      }
    }
  };

  const onClickAsync = async () => {
    if (props.disabled) {
      return;
    }

    if (props.confirm) {
      if (await showModalConfirmAsync(
        'ท่านต้องการแนบไฟล์วิดีโอหรือไม่ ?',
        'วิดีโอใหม่ที่แนบจะทับวิดีโอเดิม',
        <FaExclamationCircle className="text-danger fs-1" />)) {
        ref.current.click();
      }

      return;
    }

    ref.current.click();
  };

  return (
    <Form.Group onClick={onClickAsync}>
      <InputGroup>
        <Form.Control
          className="d-none"
          accept='video/mp4, video/x-m4v, video/*, audio/mp3,audio/*'
          type="file"
          ref={ref}
          onChange={event => handlerOnChange(event.target as HTMLInputElement)} />
      </InputGroup>
      <Button
        className={`align-items-center ${props.className ?? ''}`}
        variant="outline-primary"
        type="button"
        disabled={props.disabled}>
        <FaCloudUploadAlt className="me-2" />นำเข้าวิดีโอ
      </Button>
    </Form.Group>
  );
};