import { Card, Input, InputTime, Layout, Selector, Status, StatusType, Table } from "components";
import { HttpStatusCode, StatusChapter, StatusMeeting, statusChapterItems, statusMeetingItems } from "constant";
import { CtMakeDocumentDetailChapterCriteria, CtMakeDocumentDetailChapterList, CtMakeDocumentDetailHeader, Pagination } from "models";
import { FormEvent, useEffect, useMemo, useState } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import { FaAngleLeft, FaRegCheckCircle, FaRegKeyboard, FaSearch, FaTrashAlt } from "react-icons/fa";
import { useLoaderData, useNavigate, useParams, useSubmit } from "react-router-dom";
import { c0204 } from "services";
import { formatDateTh, formatTimeToInput, yearToTHFormat } from "utils";
import toast from "utils/toast";

type Loader = { data: Pagination<CtMakeDocumentDetailChapterList>, ctMakeDocumentHeaderData: CtMakeDocumentDetailHeader }

enum onChangeTimeType {
  START_TIME,
  END_TIME,
};

export default function C0204Detail() {
  const { data, ctMakeDocumentHeaderData } = useLoaderData() as Loader;
  const [chapterData, setChapterData] = useState<Pagination<CtMakeDocumentDetailChapterList>>(data);
  const [criteria, setCriteria] = useState<CtMakeDocumentDetailChapterCriteria>({} as CtMakeDocumentDetailChapterCriteria);
  const id = useParams().id as string;
  const navigate = useNavigate();
  const submit = useSubmit();

  useEffect(() => {
    if (data) {
      setChapterData(data);
    }
  }, [data]);

  const onSearch = (size: number, page: number) => {
    submit({
      page: page.toString(),
      size: size.toString(),
      criteria: JSON.stringify(criteria),
    });
  };

  const onClear = () => {
    setCriteria({} as CtMakeDocumentDetailChapterCriteria);

    submit({
      page: 1,
      size: 10,
    });
  };

  const onChangeCriteria = (prop: string, value?: string | number | Date) => {
    setCriteria({ ...criteria, [prop]: value });
  };

  const convertStatusToText = (status: string) => {
    const findStatus = statusMeetingItems.find(s => s.value === status)?.label;

    return findStatus;
  };

  const onChangeTimeTable = (type: onChangeTimeType, index: number, time?: Date) => {
    switch (type) {
      case onChangeTimeType.START_TIME:
        chapterData.rows[index].startTime = time;
        break;
      case onChangeTimeType.END_TIME:
        chapterData.rows[index].endTime = time;
        break;
    };

    setChapterData({ ...chapterData, rows: chapterData.rows });
  };

  const onSubmitChangeTimeTableAsync = async () => {
    if (!chapterData.rows.every(s => s.startTime) || !chapterData.rows.every(s => s.endTime)) {
      return toast.warn("กรุณากรอกเวลาให้ครบถ้วน");
    }

    const response = await c0204.updateTimeOfChapterListAsync(id, chapterData.rows);

    if (response.status === HttpStatusCode.ACCEPTED) {
      toast.success("อัพเดทข้อมูลสำเร็จ");
    }

    onSearch(chapterData.size, chapterData.page);
  };

  const isDisabled = useMemo(() => {
    switch (ctMakeDocumentHeaderData.status) {
      case StatusMeeting.CONFIRM:
        return true;
      case StatusMeeting.RECORD:
        return true;
      case StatusMeeting.RECORDED:
        return true;
      case StatusMeeting.DOCUMENT_MAKING:
        return true;
      default: return false;
    }
  }, [ctMakeDocumentHeaderData]);

  const onSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSearch(chapterData.size, chapterData.page);
  };

  return (
    <Layout title="จัดทำ/แก้ไข รายงานการประชุม">
      <div className="d-flex my-3">
        <Button
          variant="outline-primary"
          type="button"
          onClick={() => navigate('/c0204')}>
          <FaAngleLeft size={15} className="me-2" />ย้อนกลับ
        </Button>
      </div>
      <div>
        <Card>
          <label><b>ข้อมูลการประชุม</b></label>
          <div className="mt-3 mx-4">
            <Row>
              <Col sm={6} md={4} lg={4} xl={3}>
                <p>สำนัก : {ctMakeDocumentHeaderData.ctBureauName}</p>
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <p>คณะกรรมาธิการ/อื่นๆ : {ctMakeDocumentHeaderData.ctCommitteeName}</p>
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <p>คณะอนุกรรมาธิการ/คณะทำงาน : {ctMakeDocumentHeaderData.ctSubCommitteeName}</p>
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <p>ครั้งที่  : {ctMakeDocumentHeaderData.time}</p>
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <p>ปี : {yearToTHFormat(ctMakeDocumentHeaderData.year)}</p>
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <p>ณ ห้องประชุม : {ctMakeDocumentHeaderData.smMeetingRoomName}</p>
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <p>วันที่ประชุม : {formatDateTh(ctMakeDocumentHeaderData.meetingDate)}</p>
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <p>เวลา : {`${formatTimeToInput(ctMakeDocumentHeaderData.startTime)}-${formatTimeToInput(ctMakeDocumentHeaderData.endTime)}`}</p>
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <p>สถานะ : {convertStatusToText(ctMakeDocumentHeaderData.status)}</p>
              </Col>
            </Row>
          </div>
        </Card>
      </div>
      <div className="mt-2">
        <Card>
          <Form onSubmit={onSubmitSearch}>
            <label>ค้นหา</label>
            <Row>
              <Col sm={6} md={4} lg={4} xl={3}>
                <Input
                  type="number"
                  label="ตอนที่"
                  value={criteria.chapter}
                  onChange={(value) => onChangeCriteria('chapter', value)} />
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <InputTime
                  name="start-time"
                  label="ช่วงเวลาเริ่มต้น"
                  value={criteria.startTime}
                  onChange={(value) => onChangeCriteria('startTime', value)} />
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <InputTime
                  name="end-time"
                  label="ช่วงเวลาสิ้นสุด"
                  value={criteria.endTime}
                  onChange={(value) => onChangeCriteria('endTime', value)} />
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <Input
                  label="ผู้จดรายงานการประชุม"
                  value={criteria.dutyOfficerUserName}
                  onChange={(value) => onChangeCriteria('dutyOfficerUserName', value)} />
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <Selector
                  label="สถานะ"
                  items={statusChapterItems}
                  value={criteria.status}
                  onChange={(value) => onChangeCriteria('status', value)} />
              </Col>
              <div className="d-flex justify-content-start mt-2 gap-2">
                <Button
                  variant="primary"
                  type="submit">
                  <FaSearch size={15} className="me-2" />ค้นหา
                </Button>
                <Button
                  variant="outline-primary"
                  type="button"
                  onClick={onClear}>
                  <FaTrashAlt size={15} className="me-2" />ล้างค่า
                </Button>
              </div>
            </Row>
          </Form>
        </Card>
      </div >
      <Row className="mt-2">
        {isDisabled &&
          <Col sm={12} xxl={12} className="d-flex justify-content-end mb-3">
            <Button
              variant="primary"
              onClick={onSubmitChangeTimeTableAsync}>
              <FaRegCheckCircle className="me-2" />บันทึก
            </Button>
          </Col>
        }
        <Col>
          <div className="table-relative-fix">
            <div className="table-scroll">
              <Table
                total={chapterData.totalRows}
                page={chapterData.page}
                size={chapterData.size}
                onChange={onSearch}>
                <Table.Header>
                  <Table.Row>
                    <Table.Column minWidth={100}>ตอนที่</Table.Column>
                    <Table.Column minWidth={200}>เวลาเริ่มต้น</Table.Column>
                    <Table.Column minWidth={200}>เวลาสิ้นสุด</Table.Column>
                    <Table.Column minWidth={250}>กลุ่ม</Table.Column>
                    <Table.Column minWidth={250}>ผู้จดรายงานการประชุม</Table.Column>
                    <Table.Column minWidth={250}>ผู้ตรวจทาน</Table.Column>
                    <Table.Column minWidth={200}>สถานะ</Table.Column>
                    <Table.Column minWidth={200}>แก้ไขครั้งล่าสุด</Table.Column>
                    <Table.Column minWidth={200}>เวอร์ชั่น</Table.Column>
                    <Table.Column minWidth={200} className="fix-col" />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {chapterData.rows?.map((data, index) => (
                    <Table.Row key={data.id}>
                      <Table.Cell center>{data.chapter}</Table.Cell>
                      <Table.Cell>
                        <InputTime
                          name="startTime"
                          value={data.startTime}
                          onChange={(value) => onChangeTimeTable(onChangeTimeType.START_TIME, index, value)}
                          disabled={data.status !== StatusChapter.RECORDED &&
                            data.status !== StatusChapter.DOCUMENT_MAKING &&
                            data.status !== StatusChapter.ASSIGN_DUTY_OFFICER &&
                            data.status !== StatusChapter.SEND_EDIT_DOCUMENT}
                          rule={{ required: true }} />
                      </Table.Cell>
                      <Table.Cell>
                        <InputTime
                          name="endTime"
                          value={data.endTime}
                          onChange={(value) => onChangeTimeTable(onChangeTimeType.END_TIME, index, value)}
                          disabled={data.status !== StatusChapter.RECORDED &&
                            data.status !== StatusChapter.DOCUMENT_MAKING &&
                            data.status !== StatusChapter.ASSIGN_DUTY_OFFICER &&
                            data.status !== StatusChapter.SEND_EDIT_DOCUMENT}
                          rule={{ required: true }} />
                      </Table.Cell>
                      <Table.Cell center>{data.bureauGroupName}</Table.Cell>
                      <Table.Cell>{data.dutyOfficerFullName}</Table.Cell>
                      <Table.Cell>{data.reviewUserFullName}</Table.Cell>
                      <Table.Cell center><Status type={StatusType.CHAPTER_STATUS} value={data.status} /></Table.Cell>
                      <Table.Cell center>{data.updateDate ? formatDateTh(data.updateDate) : ""}</Table.Cell>
                      <Table.Cell center>{data.versionDocument}</Table.Cell>
                      <Table.Cell center className="fix-col">
                        <Button
                          disabled={data.status === StatusMeeting.RECORD}
                          variant="outline-primary"
                          type="button"
                          onClick={() => navigate(`document/${data.id}`, { state: { data: data, meetingData: ctMakeDocumentHeaderData } })}>
                          <FaRegKeyboard size={15} className="me-2" />จัดทำรายงาน
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}