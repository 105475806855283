import { useEffect, useMemo, useState } from "react";
import { Button, Col, Row, Image } from "react-bootstrap";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { Card, Check, Input, Layout, Modal, Selector, Table } from "components";
import { FaAngleLeft, FaPlus, FaSave, FaSearch, FaTrashAlt } from "react-icons/fa";
import { ItemModel, Pagination, UserModel } from "models";
import { m0103 } from "services";
import { HttpStatusCode } from "constant";
import toast from "utils/toast";
import { calculateRowNumber, submitForm } from "utils";

type Loader = {
  userDetail: UserModel,
  roleSelection: ItemModel[],
};

type SearchUserResponse = {
  bureauId: string;
  bureauName: string;
  cardId: string;
  personPicture: string;
  firstName: string;
  groupId: string;
  groupName: string;
  lastName: string;
  middleName: string;
  positionMgtName: string;
  positionName: string;
  positionNo: string;
  perType: number;
  title: string;
};

export default function M0103Detail() {
  const { userDetail, roleSelection } = useLoaderData() as Loader;
  const [data, setData] = useState<UserModel>({} as UserModel);
  const [keyword, setKeyword] = useState<string>('');
  const { id } = useParams();
  const navigate = useNavigate();
  const [showUserModal, setShowUserModal] = useState<boolean>(false);
  const [searchUserResponse, setSearchUserResponse] =
    useState<Pagination<SearchUserResponse>>({ size: 20, page: 1, } as Pagination<SearchUserResponse>);

  useEffect(() => {
    if (userDetail) {
      setData({
        ...userDetail,
        roleIds: userDetail.roleIds.map(r => r)
      });
    }
  }, [userDetail]);

  const addRole = () => {
    const role = data.roleIds ? [...data.roleIds] : [];

    role.push('');

    setData({
      ...data,
      roleIds: role,
    });
  };

  const removeRole = (i: number) => {
    const roleIds = [...data.roleIds];

    roleIds.splice(i, 1);

    setData({
      ...data,
      roleIds,
    });
  };

  const onChangeRole = (value: string, index: number) => {
    data.roleIds[index] = value;

    setData({
      ...data,
      roleIds: data.roleIds,
    });
  };

  const onSubmitAsync = async () => {
    if (!data.roleIds || data.roleIds?.length === 0) {
      toast.warn("กรุณาเลือกสิทธิ์การใช้งาน");

      return;
    };

    if (new Set(data.roleIds).size !== data.roleIds.length) {
      toast.warn("สิทธิ์การใช้งานซ้ำ");

      return;
    }

    if (id) {
      await updateAsync();
    } else {
      await saveAsync();
    }
  };

  const saveAsync = async () => {
    const res = await m0103.saveAsync(data);

    if (res.status === HttpStatusCode.CONFLICT) {
      toast.error("ผู้ใช้งานนี้ถูกใช้งานแล้ว");
    }

    if (res.status === HttpStatusCode.NOT_FOUND && res.data === 'bureau') {
      toast.error("ไม่พบสำนักงาน");
    }

    if (res.status === HttpStatusCode.CREATED) {
      toast.success("เพิ่มข้อมูลสำเร็จ");

      navigate(`/m0103/detail/${res.data}`);
    }
  };

  const updateAsync = async () => {
    const res = await m0103.updateAsync(data.id, data);

    if (res.status === HttpStatusCode.ACCEPTED) {
      toast.success("แก้ไขข้อมูลสำเร็จ");
    }
  };

  const onSearchUserAsync = async (page: number) => {
    submitForm();

    if (!keyword) {
      return;
    }

    const res = await m0103.searchDpisUserAsync(searchUserResponse.size, page, keyword);

    if (res.status === HttpStatusCode.NOT_FOUND) {
      toast.error('ไม่พบผู้ใช้งาน');
    }

    if (res.status === HttpStatusCode.OK) {
      if (res.data.totalRows >= 0) {
        setSearchUserResponse({
          ...searchUserResponse,
          totalRows: res.data.totalRows,
          rows: res.data.rows,
          page,
        });

        setShowUserModal(true);
      }
    }
  };

  const onSearchUserModalHide = () => {
    setShowUserModal(false);

    setSearchUserResponse({
      ...searchUserResponse,
      page: 1,
    });
  };

  const onSelectUser = (user: SearchUserResponse) => {
    setData({
      bureauId: user.bureauId,
      bureauName: user.bureauName,
      bureauGroupId: user.groupId,
      bureauGroupName: user.groupName,
      cardId: user.cardId,
      personImage: user.personPicture,
      title: user.title,
      firstName: user.firstName,
      middleName: user.middleName,
      lastName: user.lastName,
      positionMgtName: user.positionMgtName,
      positionName: user.positionName,
      positionNo: user.positionNo,
      perType: user.perType,
      isActive: true,
    } as UserModel);
  };

  const fullName = useMemo(() => {
    return `${data?.firstName} ${data?.middleName ? ' ' + data.middleName : ''} ${data?.lastName}`
  }, [data]);

  return (
    <Layout title="บุคลากร (เพิ่ม/แก้ไข)">
      <div className="d-flex justify-content-between my-3">
        <Button
          variant="outline-primary"
          type="button"
          onClick={() => navigate('/m0103')}
          className="d-flex align-items-center gap-2">
          <FaAngleLeft />ย้อนกลับ
        </Button>
        <Button
          variant="primary"
          type="button"
          onClick={onSubmitAsync}
          className="d-flex align-items-center gap-2">
          <FaSave />บันทึก
        </Button>
      </div>
      {!data?.id ?
        <Card>
          <Col lg={6}>
            <div className="criteria-flex d-flex flex-row gap-3">
              <div className="control d-flex flex-grow-1">
                <Input
                  name='username'
                  value={keyword}
                  label='ค้นหา'
                  onChange={value => setKeyword(value)}
                  rule={{ required: true }}
                  placeholder="สำนัก, กลุ่มงาน, ชื่อ - นามสกุล, ตำแหน่ง" />
              </div>
              <div className="button d-flex justify-content-end">
                <Button
                  variant="primary"
                  onClick={() => onSearchUserAsync(searchUserResponse.page)}>
                  <FaSearch className="me-2" />ค้นหา
                </Button>
              </div>
            </div>
          </Col>
        </Card>
        : <></>}
      {data?.cardId ?
        <>
          <Card title="ข้อมูลผู้ใช้งาน" className="mt-3">
            <div className="d-flex flex-row">
              <div>
                <Image src={data.personImage} style={{ width: '150px', height: '200px' }} />
              </div>
              <div className="ms-5">
                <p>สำนัก : {data.bureauName}</p>
                <p>ชื่่อ - นามสกุล : {fullName}</p>
                <p>ตำแหน่ง : {data.positionName}</p>
                <Check
                  label="เปิดการใช้งาน"
                  value={data.isActive}
                  onChange={isActive => setData({ ...data, isActive })} />
                <Check
                  label="ล็อคผู้ใช้งาน"
                  value={data.isLock}
                  onChange={isLock => setData({ ...data, isLock })} />
              </div>
              <div className="ms-5">
                <p>กลุ่มงาน : {data.bureauGroupName}</p>
              </div>
            </div>
          </Card>
          <Card className="mt-3">
            <div className="d-flex justify-content-between">
              <h3 className="m-0">สิทธิ์การใช้งาน</h3>
              <Button
                variant="primary"
                type="button"
                onClick={addRole}
                className="d-flex align-items-center gap-2">
                <FaPlus />เพิ่มสิทธิ์
              </Button>
            </div>
            <>
              {data.roleIds?.map((data, i) =>
                <Row key={i} className="mb-3">
                  <Col lg={6} className='d-flex flex-row gap-2'>
                    <Selector
                      value={data}
                      rule={{ required: true }}
                      items={roleSelection}
                      onChange={(value) => onChangeRole(value.toString(), i)} />
                    <Button
                      variant="outline-primary"
                      onClick={() => removeRole(i)}>
                      <FaTrashAlt />
                    </Button>
                  </Col>
                </Row>)}
            </>
          </Card>
        </> : <></>}
      <UserModal
        show={showUserModal}
        data={searchUserResponse}
        keyword={keyword}
        keywordOnChange={setKeyword}
        onSearch={onSearchUserAsync}
        onHide={onSearchUserModalHide}
        onSelect={onSelectUser} />
    </Layout>
  );
}

function UserModal(props: {
  show: boolean;
  data: Pagination<SearchUserResponse>;
  keyword: string;
  keywordOnChange: (val: string) => void;
  onSearch: (page: number) => void;
  onHide: () => void;
  onSelect: (user: SearchUserResponse) => void;
}) {
  const [page, setPage] = useState<number>(1);

  const fullName = (
    title: string,
    firstName: string,
    middleName: string,
    lastName: string,
  ) => {
    return `${title}${firstName} ${middleName ? ' ' + middleName : ''} ${lastName}`
  };

  const onSelect = (cardId: string) => {
    const user = props.data.rows.find(r => r.cardId === cardId);

    if (user) {
      props.onSelect(user);

      props.onHide();
    }
  };

  const onSearch = (page: number) => {
    setPage(page);

    props.onSearch(page);
  };

  return (
    <Modal
      show={props.show}
      title="ค้นหาผู้ใช้งาน"
      size="xl">
      <Card className="mt-3">
        <Col lg={6}>
          <div className="criteria-flex d-flex flex-row gap-3">
            <div className="control d-flex flex-grow-1">
              <Input
                name='username'
                value={props.keyword}
                label='ค้นหา'
                onChange={props.keywordOnChange}
                rule={{ required: true }}
                placeholder="สำนัก, กลุ่มงาน, ชื่อ - นามสกุล, ตำแหน่ง" />
            </div>
            <div className="button d-flex justify-content-end">
              <Button
                variant="primary"
                onClick={() => onSearch(1)}>
                <FaSearch className="me-2" />ค้นหา
              </Button>
            </div>
          </div>
        </Col>
      </Card>
      <Table
        total={props.data.totalRows}
        hideSize
        fixSize={20}
        className="mt-3"
        onChange={(_, page) => onSearch(page)}
        page={page}>
        <Table.Header>
          <Table.Row>
            <Table.Column minWidth={100}>ลำดับ</Table.Column>
            <Table.Column minWidth={250}>สำนัก</Table.Column>
            <Table.Column minWidth={250}>กลุ่มงาน</Table.Column>
            <Table.Column minWidth={250}>ชื่อ - นามสกุล</Table.Column>
            <Table.Column minWidth={250}>ตำแหน่ง</Table.Column>
            <Table.Column minWidth={100} />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {props.data.rows?.map((data, i) => (
            <Table.Row key={data.cardId}>
              <Table.Cell center>
                {calculateRowNumber(i, props.data.page, props.data.size).toString()}
              </Table.Cell>
              <Table.Cell center>{data.bureauName}</Table.Cell>
              <Table.Cell center>{data.groupName}</Table.Cell>
              <Table.Cell>
                {fullName(data.title, data.firstName, data.middleName, data.lastName)}
              </Table.Cell>
              <Table.Cell center>{data.positionName}</Table.Cell>
              <Table.Cell center>
                <div className="button d-flex justify-content-center">
                  <Button
                    variant="primary"
                    onClick={() => onSelect(data.cardId)}>
                    เลือก
                  </Button>
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <div className="d-flex justify-content-end mt-3">
        <Button
          variant="outline-primary"
          onClick={props.onHide}>
          ยกเลิก
        </Button>
      </div>
    </Modal>
  );
}