import { Card, Layout } from "components";
import Collabora, { CollaboraRef } from "components/Document/Collabora";
import Video, { VideoOption } from "components/Video/Video";
import { HttpStatusCode, VideoControl } from "constant";
import { ChapterDocuments, CtInquiryMeetingStatusDetailHeader, CtMakeDocumentChapterDetail } from "models";
import { Dispatch, useEffect, useRef, useState, SetStateAction, useMemo, createContext, useContext } from "react";
import { Button, Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import { FaAngleLeft, FaUndo } from "react-icons/fa";
import { ctService } from "services";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { formatDateTh, getTime, yearToTHFormat } from "utils";

type Loader = { header: CtInquiryMeetingStatusDetailHeader; documentData: CtMakeDocumentChapterDetail; };

type InquiryStatusContext = {
  documentSelected: ChapterDocuments;
  setDocumentSelected: Dispatch<SetStateAction<ChapterDocuments>>;
};

const Context = createContext({} as InquiryStatusContext);

export default function C0301Document() {
  const { documentData } = useLoaderData() as Loader;
  const [documentSelected, setDocumentSelected] = useState<ChapterDocuments>({} as ChapterDocuments);
  const [video, setVideo] = useState<File>({} as File);
  const [fileIds, setFileIds] = useState<string[]>([]);
  const [fileIndex, setFileIndex] = useState<number>(0);
  const { id, docId } = useParams();

  useEffect(() => {
    if (documentData) {
      setDefaultAsync();
    }
  }, [docId]);

  const setDefaultAsync = async () => {
    setDocumentSelected(documentData.ctChapterDocuments[0]);

    const fileId = await getFileIdAsync(documentData.ctChapterDocuments[0].ctMeetingChapterId!);

    selectViewVideoAsync(fileId);
  };

  const collaboraRef = useRef<CollaboraRef>(null);

  const videoRef = useRef<VideoOption>({} as VideoOption);

  function loadCollabora() {
    const iframe = document.getElementById(documentSelected.id) as HTMLIFrameElement;

    iframe.contentWindow?.addEventListener('keydown', (e) => clickBtn(e.key));
  }

  function clickBtn(key: string) {
    switch (key) {
      case VideoControl.F10:
        videoRef.current.backRef();
        break;
      case VideoControl.F9:
        videoRef.current.playRef();
        break;
      case VideoControl.F11:
        videoRef.current.aheadRef();
        break;
    }
  }

  const selectViewVideoAsync = async (id: string) => {
    const { data, status } = await ctService.getVideoAsync(id);

    if (status === HttpStatusCode.OK) {
      setVideo(data);
    }
  };

  const getFileIdAsync = async (fileId: string) => {
    const { data, status } = await ctService.getRecordFileIdAsync(fileId, id!);

    if (status === HttpStatusCode.OK) {
      setFileIds(data);

      return data[0];
    }
  };

  const onEndedAsync = async () => {
    const currentIndex = fileIndex + 1;

    if (fileIds.length > currentIndex) {
      selectViewVideoAsync(fileIds[currentIndex]);

      setFileIndex(currentIndex);
    } else {
      setFileIndex(0);
    }
  };

  const contextValue = useMemo(() => {
    return {
      documentSelected,
      setDocumentSelected,
    }
  }, [documentSelected, setDocumentSelected]);

  return (
    <Context.Provider value={contextValue}>
      <Layout title="รายละเอียดการจด/แก้ไข รายงานการประชุม">
        <HeaderButton />
        <Row>
          <Col md={4}>
            <Video
              onEnded={onEndedAsync}
              ref={videoRef}
              files={video.type ? [video] : []}
              showPlayButton={false}
              showPlayTimeConfig={false}
              showSpeakToText={false}>
              <MeetingDetail />
            </Video>
          </Col>
          <Col md={8}>
            <Collabora
              docId={documentSelected.id}
              docName="s0204"
              height="1000px"
              ref={collaboraRef}
              onCollaboraLoaded={() => loadCollabora()} />
          </Col>
        </Row>
      </Layout>
    </Context.Provider>
  );
};

function HeaderButton() {
  const { documentData } = useLoaderData() as Loader;
  const { setDocumentSelected } = useContext(Context);
  const navigate = useNavigate();
  const { id } = useParams();

  const onChangeDocumentVersion = (value: string | null) => {
    if (value) {
      const findDocument = documentData.ctChapterDocuments.find(f => f.id == value);

      setDocumentSelected(findDocument ? findDocument : {} as ChapterDocuments);
    }
  };

  return (
    <div className="d-flex justify-content-between my-3">
      <Button
        variant="outline-primary"
        type="button"
        onClick={() => navigate(`/c0301/detail/${id}`)}>
        <FaAngleLeft className="me-2" />ย้อนกลับ
      </Button>
      <DropdownButton
        title={<span><FaUndo className="me-2" />เวอร์ชั่นก่อนหน้า</span>}
        drop="down-centered"
        variant="outline-primary"
        onSelect={(value) => onChangeDocumentVersion(value)}>
        {documentData.ctChapterDocuments?.map((data) => (
          <Dropdown.Item
            eventKey={data.id}
            key={data.id}>
            {data.versionDocument}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </div>
  );
};

function MeetingDetail() {
  const { header } = useLoaderData() as Loader;

  return (
    <Card className="mt-3">
      <label><b>ข้อมูลการประชุม</b></label>
      <Row className="mt-3">
        <Col xs={12}>
          <p>สำนัก<span className="mx-2">:</span>{header.ctBureauName}</p>
        </Col>
        <Col xs={12}>
          <p>คณะกรรมาธิการ/อื่นๆ<span className="mx-2">:</span>{header.ctCommitteeName}</p>
        </Col>
        <Col xs={12}>
          <p>คณะอนุกรรมาธิการ/คณะทำงาน<span className="mx-2">:</span>{header.ctSubCommitteeName}</p>
        </Col>
        <Col xs={12}>
          <p>ครั้งที่<span className="mx-2">:</span>{`${header.time}`}</p>
        </Col>
        <Col xs={12}>
          <p>ปี<span className="mx-2">:</span>{`${yearToTHFormat(header.year)}`}</p>
        </Col>
        <Col xs={12}>
          <p>ณ ห้องประชุม<span className="mx-2">:</span>{header.smMeetingRoomName}</p>
        </Col>
        <Col xs={12}>
          <p>วันที่ประชุม<span className="mx-2">:</span>{formatDateTh(header.meetingDate)}</p>
        </Col>
        <Col xs={12}>
          <p>เวลา<span className="mx-2">:</span>{`${getTime(header.startTime)} - ${getTime(header.endTime)}`}</p>
        </Col>
      </Row>
    </Card>
  );
};