import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Menu } from "models";
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleDown, FaAngleUp } from "react-icons/fa";
import { Offcanvas, Image } from "react-bootstrap";
import { authenticationService } from "services";
import { HttpStatusCode } from "constant";
import { toast } from "react-toastify";
import { useModule } from "hooks";

export function Sidebar() {
  const [active, setActive] = useState<string>();
  const [menus, setMenus] = useState<Menu[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(true);
  const [offcanvasCollapsed, setOffcanvasCollapsed] = useState(true);
  const [widthScreen, setWidthScreen] = useState<number>(0);
  const breakPoint = 992;
  const { currentModule } = useModule();

  useEffect(() => {
    const currentPath = location.pathname;

    if (active && currentPath.includes(active) && currentPath !== "/") {
      return;
    }

    setActive(`/${currentPath.split('/')[1]}`);

    getMenusAsync(currentModule, currentPath);

    checkWidthScreen();
  }, [currentModule, location.pathname]);

  const getMenusAsync = async (module: string, currentPath: string) => {
    const res = await authenticationService.getMenusAsync(module);

    if (res.status === HttpStatusCode.OK) {
      const data = (res.data as Menu[]).map(d => collapsed(d, currentPath));

      setMenus(data);
    }

    if (res.status === HttpStatusCode.NOT_FOUND) {
      toast.error('ไม่พบเมนู');
    }
  };

  const collapsed = (menu: Menu, currentPath: string) => {
    const splitCurrentPath = `/${currentPath.split('/')[1]}`;

    if (!splitCurrentPath.includes(menu.path)) {
      menu.collapsed = true;
    }

    menu.children = menu.children?.map(c => collapsed(c, currentPath));

    return menu;
  };

  const checkWidthScreen = useCallback(() => {
    setWidthScreen(window.innerWidth);

    if (window.innerWidth >= breakPoint) {
      setSidebarCollapsed(false);
    }

    if (window.innerWidth < breakPoint) {
      onSidebarCollapsed();
    }

    window.onresize = function (event) {
      const widthScreen = (event.target as Window).innerWidth;

      setWidthScreen(widthScreen);

      if (widthScreen < breakPoint) {
        onSidebarCollapsed();
      }
    };
  }, []);

  const handlerParentMenuOnClick = useCallback((i1: number, i2?: number) => {
    if (i1 >= 0 && i2 !== undefined && i2 >= 0) {
      const menu = menus[i1];

      if (menu.children) {
        menu.children[i2].collapsed = !menu.children[i2].collapsed;

        menus[i1] = menu;

        setMenus([...menus]);
      }

      return;
    }

    if (i1 >= 0) {
      menus[i1].collapsed = !menus[i1].collapsed;

      setMenus([...menus]);
    }
  }, [menus]);

  const collapseIcon = (menu: Menu) => {
    if (menu.children?.length) {
      if (menu.collapsed) {
        return <FaAngleDown />;
      }

      return <FaAngleUp />;
    }

    return null;
  };

  const createParentMenu = useCallback((menu: Menu, level: number, i1: number, i2?: number) => {
    const handlerOnClick = (path?: string) => {
      if (path) {
        setActive(path);
        setOffcanvasCollapsed(true);
        navigate(path);
      }
    };

    return (
      <div key={menu.id} className="menu">
        <div
          className={`${level === 1 ? 'parent-first' : 'parent-second'} d-flex justify-content-between align-items-center`}
          onClick={() => handlerParentMenuOnClick(i1, i2)}>
          {menu.name} {collapseIcon(menu)}
        </div>
        <div className={`${menu.collapsed ? 'd-none' : ''}`}>
          {menu.children?.map((child, i) =>
            child.children?.length ?
              createParentMenu(child, level + 1, i1, i) :
              <div
                key={child.id}
                className={`child ${active == child.path ? 'active' : ''}`}
                style={{ paddingLeft: `${0.5 + level}rem` }}
                onClick={() => handlerOnClick(child.path)}>
                <div className="label">
                  {child.name}
                </div>
              </div>)}
        </div>
      </div>
    );
  }, [menus, active]);

  const createMenus = useMemo(() => {
    return (
      <>
        {menus.map((menu, i) => (createParentMenu(menu, 1, i)))}
      </>
    );
  }, [menus, active, sidebarCollapsed, navigate]);

  const handlerOnCollapsed = useCallback(() => {
    if (widthScreen < breakPoint) {
      setOffcanvasCollapsed(!offcanvasCollapsed);
    } else {
      if (sidebarCollapsed) {
        return onOpenSidebarCollapse();
      }

      return onSidebarCollapsed();
    }
  }, [sidebarCollapsed, widthScreen, offcanvasCollapsed]);

  const onOpenSidebarCollapse = useCallback(() => {
    document.dispatchEvent(new CustomEvent('onSidebarIsCollapsed', { detail: false }));

    setSidebarCollapsed(false);
  }, [sidebarCollapsed]);

  const onSidebarCollapsed = useCallback(() => {
    document.dispatchEvent(new CustomEvent('onSidebarIsCollapsed', { detail: true }));

    setSidebarCollapsed(true);
  }, [sidebarCollapsed]);

  return (
    <>
      <div className={`sidebar ${sidebarCollapsed ? 'collapsed' : ''}`}>
        {createMenus}
      </div>
      <div className={`btn-collapse ${sidebarCollapsed && offcanvasCollapsed ? 'collapsed' : ''}`} onClick={handlerOnCollapsed}>
        {sidebarCollapsed && offcanvasCollapsed ? <FaAngleDoubleRight /> : <FaAngleDoubleLeft />}
      </div>
      <Offcanvas show={!offcanvasCollapsed} onHide={() => setOffcanvasCollapsed(!offcanvasCollapsed)}>
        <Offcanvas.Header>
          <Image
            src="/images/logo.png"
            width="60"
            height="60" />
          <div className='d-flex flex-column justify-content-center ms-2 lh-sm'>
            <span className='fs-6'>สำนักงานเลขาธาการวุฒิสภา</span>
            <span className='fs-5'>ระบบจัดทำรายงานการประชุม</span>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body className="sidebar">
          {createMenus}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}