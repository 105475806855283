import { Card, Check, Input, InputDate, Layout, Selector, Status, StatusType, Table } from "components";
import { StatusMeeting, statusMeetingCommitteeItems } from "constant";
import { CtReviewOverviewCriteria, CtReviewOverviewList, ItemModel, Pagination } from "models";
import { FormEvent, useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaRegFileAlt, FaSearch, FaTrashAlt } from "react-icons/fa";
import { useLoaderData, useNavigate, useSubmit } from "react-router-dom";
import { formatDateTh, fullDateTime, getYearList, yearToTHFormat } from "utils";

type Loader = { bureausItems: ItemModel[], committeesItems: ItemModel[], subCommitteesItems: ItemModel[], data: Pagination<CtReviewOverviewList> };

export default function C0206() {
  const { bureausItems, committeesItems, subCommitteesItems, data } = useLoaderData() as Loader;
  const [subCommitteeItems, setSubCommitteeItems] = useState<ItemModel[]>([...subCommitteesItems]);
  const [criteria, setCriteria] = useState<CtReviewOverviewCriteria>({ isNotReviewed: true } as CtReviewOverviewCriteria);
  const navigation = useNavigate();
  const submit = useSubmit();

  const statusItems = [
    StatusMeeting.DOCUMENT_COMPLETE,
    StatusMeeting.COLLECT,
    StatusMeeting.OVERVIEW_REVIEWED,
    StatusMeeting.GUARANTEE,
    StatusMeeting.REJECT,
  ];

  const filterStatus = useMemo(() => statusMeetingCommitteeItems.filter(s => statusItems.includes(s.value)), [statusMeetingCommitteeItems]);

  const onChangeCriteria = (prop: keyof CtReviewOverviewCriteria, value?: string | number | Date | boolean) => {
    setCriteria({ ...criteria, [prop]: value });
  };

  useEffect(() => {
    if (criteria.committeeId) {
      const selectedId = committeesItems.find(f => f.value === criteria.committeeId)?.id;

      const filterItems = subCommitteesItems.filter(f => f.depenId === selectedId);

      setSubCommitteeItems(filterItems);

      return;
    }

    setCriteria({ ...criteria, subCommitteeId: "" });
    setSubCommitteeItems(subCommitteesItems);
  }, [criteria.committeeId]);

  const onSearch = (size: number, page: number) => {
    submit({
      page: page.toString(),
      size: size.toString(),
      criteria: JSON.stringify(criteria),
    });
  };

  const onClear = () => {
    setCriteria({} as CtReviewOverviewCriteria);

    submit({
      page: 1,
      size: 10,
    });
  };

  const onSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSearch(data.size, data.page);
  };

  const getMeetingTime = (timeFirst: string, timeSecond?: string) => {
    if (timeFirst && timeSecond) {
      return <>{timeFirst}/<br />{timeSecond}</>
    } else if (timeSecond) {
      return timeSecond;
    } else {
      return timeFirst;
    }
  };

  return (
    <Layout title="ตรวจสอบและรวบรวมรายงานการประชุม">
      <Card>
        <Form onSubmit={onSubmitSearch}>
          <Row>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Selector
                label="สำนัก"
                placeholder="สำนัก"
                items={bureausItems}
                value={criteria.bureauId}
                onChange={(value) => onChangeCriteria('bureauId', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Selector
                label="คณะกรรมาธิการ/อื่นๆ"
                placeholder="คณะกรรมาธิการ/อื่นๆ"
                items={committeesItems}
                value={criteria.committeeId}
                onChange={(value) => onChangeCriteria('committeeId', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Selector
                label="คณะอนุกรรมาธิการ/คณะทำงาน"
                placeholder="คณะอนุกรรมาธิการ/คณะทำงาน"
                items={subCommitteeItems}
                value={criteria.subCommitteeId}
                onChange={(value) => onChangeCriteria('subCommitteeId', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Input
                label="ครั้งที่ประชุม"
                value={criteria.time}
                onChange={(value) => onChangeCriteria('time', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Selector
                label="ปี"
                placeholder="ปี"
                items={getYearList()}
                value={criteria.year}
                onChange={(value) => onChangeCriteria('year', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <InputDate
                label="วันที่ประชุม"
                name="meeting-date"
                value={criteria.date}
                onChange={(value) => onChangeCriteria('date', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Selector
                label="สถานะ"
                placeholder="สถานะ"
                items={filterStatus}
                value={criteria.status}
                onChange={(value) => onChangeCriteria('status', value)} />
            </Col>
          </Row>
          <Row>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Check
                label="แสดงเฉพาะงานที่ยังไม่อนุมัติ"
                value={criteria.isNotReviewed}
                onChange={(value) => onChangeCriteria('isNotReviewed', value)} />
            </Col>
          </Row>
          <div className="d-flex gap-2 justify-content-start mt-2">
            <Button
              variant="primary"
              type="submit">
              <FaSearch className="me-2" />
              ค้นหา
            </Button>
            <Button
              variant="outline-primary"
              type="button"
              onClick={onClear}>
              <FaTrashAlt className="me-2" />
              ล้างค่า
            </Button>
          </div>
        </Form>
      </Card>
      <Row className="mt-3">
        <Col>
          <div className="table-relative-fix">
            <div className="table-scroll">
              <Table
                total={data.totalRows}
                page={data.page}
                size={data.size}
                onChange={onSearch}>
                <Table.Header>
                  <Table.Row>
                    <Table.Column minWidth={50}>ลำดับ</Table.Column>
                    <Table.Column minWidth={120}><>ประเภทการ<br />ประชุม</></Table.Column>
                    <Table.Column minWidth={150}>ห้องประชุม</Table.Column>
                    <Table.Column minWidth={100}>วันที่ประชุม</Table.Column>
                    <Table.Column minWidth={120}>เวลาประชุม</Table.Column>
                    <Table.Column minWidth={120}>สำนัก</Table.Column>
                    <Table.Column minWidth={120}>คณะกรรมาธิการ/อื่นๆ</Table.Column>
                    <Table.Column minWidth={120}>ชื่อการประชุม</Table.Column>
                    <Table.Column minWidth={50}>ปี</Table.Column>
                    <Table.Column minWidth={120}>ผู้ตรวจสอบและจัดทำรูปเล่ม</Table.Column>
                    <Table.Column minWidth={120}>ผู้ตรวจสอบรูปเล่มรายงาน</Table.Column>
                    <Table.Column minWidth={120}>สถานะ</Table.Column>
                    <Table.Column minWidth={150}>ผู้แก้ไขข้อมูล</Table.Column>
                    <Table.Column minWidth={150}>วันที่แก้ไขข้อมูล</Table.Column>
                    <Table.Column minWidth={150} className="fix-col" />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {data.rows?.map((d, index) => (
                    <Table.Row key={d.id}>
                      <Table.Cell center>{index + 1}</Table.Cell>
                      <Table.Cell center>{d.meetingType}</Table.Cell>
                      <Table.Cell center>{d.meetingRoomName}</Table.Cell>
                      <Table.Cell center>{formatDateTh(d.meetingDate)}</Table.Cell>
                      <Table.Cell center>{getMeetingTime(d.meetingTimeFirst, d.meetingTimeSecond)}</Table.Cell>
                      <Table.Cell center>{d.bureauName}</Table.Cell>
                      <Table.Cell>
                        {d.committeeShortName ? `${d.committeeShortName}` : d.committeeName}
                      </Table.Cell>
                      <Table.Cell>
                        {d.subCommitteeShortName ? `${d.subCommitteeShortName} : ${d.name}` : d.name}
                      </Table.Cell>
                      <Table.Cell center>{yearToTHFormat(d.year)}</Table.Cell>
                      <Table.Cell>{d.reviewerOverviewFullName}</Table.Cell>
                      <Table.Cell>{d.certifyingReportFullName}</Table.Cell>
                      <Table.Cell center><Status type={StatusType.MEETING_STATUS} value={d.status} committee /></Table.Cell>
                      <Table.Cell>{d.updateByUserFullName}</Table.Cell>
                      <Table.Cell center>{fullDateTime(d.updateDate)}</Table.Cell>
                      <Table.Cell center className="fix-col">
                        <Button
                          variant="outline-primary"
                          size="sm"
                          onClick={() => navigation(`detail/${d.id}`)}>
                          <FaRegFileAlt className="me-2" />
                          ดูรายละเอียด
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}