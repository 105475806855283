import { SmVideoPeriod } from 'models/m0104';
import http from 'utils/axios';

const getSmVideoPeriodAsync = async () => {
  return await http.get('api/smvideoperiod');
};

const updateSmVideoPeriodAsync = async (body: SmVideoPeriod) => {
  return await http.put(`api/smvideoperiod/${body.id}`, body);
};

const getCuttingTimeAsync = async () => await http.get("api/smvideoperiod/cuttingtime");

const smVideoPeriodService = {
  getSmVideoPeriodAsync,
  updateSmVideoPeriodAsync,
  getCuttingTimeAsync,
};

export default smVideoPeriodService;