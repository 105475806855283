import { Card } from "components";
import { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { FaChevronCircleDown, FaChevronCircleUp, FaFileAlt, FaFileExcel, FaFileImage, FaFileImport, FaFilePdf, FaFileWord, FaTrashAlt } from "react-icons/fa";
import { down, showModalConfirmAsync, up } from "utils";
import toast from "utils/toast";

export type FileValue = {
  id: string;
  sequence: number;
  file?: File;
  fileName?: string;
};

interface Props {
  rightButton?: JSX.Element;
  onMoveChange?: (value: FileValue[]) => void;
  onRemove?: (index: number) => void;
  onChange?: (file: File, sequence: number) => void;
  value?: FileValue[];
  dowloadFile?: (fileId: string, fileName?: string) => void;
  disabled?: boolean;
  canEdit?: boolean;
  onEditDocument?: (id: string) => void;
}

export function UploadFile(props: Props) {
  const inputRef = useRef({} as HTMLInputElement);
  const [value, setValue] = useState<FileValue[]>([]);
  const fileType = [".doc", ".docx", ".docm", ".dot", ".pdf", ".xlsx", ".xlsm", ".xlsb", ".jpg", ".png", ".svg", ".webp", ".gif"];
  const fileCanEdit = ["doc", "docx",];
  const limitSizeByte = 30 * (1024 ** 2);

  useEffect(() => {
    if (props.value) {
      setValue([...props.value]);
    }
  }, [props.value]);

  const onChange = (event: HTMLInputElement) => {
    const file = event.files![0];
    const sequence = value.length + 1;

    if (file.size > limitSizeByte) {
      return toast.warn("ขนาดไฟล์เกิน 30 mb");
    }

    if (!fileType.some(f => file.name.includes(f))) {
      return toast.warn("ประเภทไฟล์ไม่ถูกต้อง");
    }

    inputRef.current.value = '';

    if (props.onChange) {
      props.onChange(file, sequence);
    }
  };

  const fileIcon = (name: string) => {
    switch (true) {
      case name.includes('.pdf'):
        return (<FaFilePdf className="text-primary" />);
      case name.includes('.doc') || name.includes('.docx'):
        return (<FaFileWord className="text-primary" />);
      case name.includes('.xls') || name.includes('.xlsx'):
        return (<FaFileExcel className="text-primary" />);
      case name.includes('.png') || name.includes('jpg') || name.includes('jpeg'):
        return (<FaFileImage className="text-primary" />);
      default:
        return (<FaFileAlt className="text-primary" />);
    }
  };

  const moveUp = useCallback((index: number) => {
    const newFiles = up(index, value);

    if (props.onMoveChange) {
      props.onMoveChange(newFiles);
    }
  }, [value]);

  const moveDown = useCallback((index: number) => {
    const newFiles = down(index, value);

    if (props.onMoveChange) {
      props.onMoveChange(newFiles);
    }
  }, [value]);

  const removeAsync = useCallback(async (index: number) => {
    if (await showModalConfirmAsync('ยืนยันการลบไฟล์')) {
      if (props.onRemove) {
        props.onRemove(index);
      }
    }
  }, [value]);

  const dowloadFile = (fileSelected: FileValue) => {
    if (props.dowloadFile) {
      props.dowloadFile(fileSelected.id, fileSelected.fileName);
    }
  };

  const handleOnEditFileDocument = (id: string) => {
    if (props.onEditDocument) {
      props.onEditDocument(id);
    }
  };

  return (
    <>
      {(value.length === 0 && props.disabled) ?
        <></> : <Card className="border-primary mt-3">
          <div className="d-flex justify-content-between">
            <div>
              {!props.disabled &&
                <>
                  <Button
                    variant="outline-primary"
                    onClick={() => inputRef.current.click()}>
                    <FaFileImport className="me-2" />เพิ่มเอกสารแนบ
                  </Button>
                  <span className="text-danger mx-2">(ขนาดไฟล์ไม่เกิน 30 mb)</span>
                </>}
            </div>
            <input
              type='file'
              className="d-none"
              ref={inputRef}
              onChange={event => onChange(event.target as HTMLInputElement)}
              accept={fileType.join(',')} />
            {props.rightButton}
          </div>
          <>
            {value.map((value, i) =>
              <Card key={value.id} className="border-primary mt-3">
                <div className="d-flex justify-content-between gap-2">
                  <div className="d-flex gap-2 align-items-center">
                    <span>
                      {i + 1}.
                    </span>
                    <div className="d-flex gap-2 align-items-center cursor-pointer" onClick={() => dowloadFile(value)}>
                      {fileIcon(value.fileName!)}
                      <span className="text-break">{value.fileName}</span>
                    </div>
                  </div>
                  <div className="d-flex gap-2 align-items-center">
                    {(props.canEdit && fileCanEdit.some(f => value.fileName?.includes(f))) ?
                      <Button
                        className="w-100"
                        variant="outline-primary"
                        size="sm"
                        onClick={() => handleOnEditFileDocument(value.id)}>
                        รายละเอียด
                      </Button> : <></>}
                    {!props.disabled ? <div className="d-flex gap-2">
                      <FaChevronCircleUp
                        className="text-primary cursor-pointer fs-5"
                        onClick={() => moveUp(i)} />
                      <FaChevronCircleDown
                        className="text-primary cursor-pointer fs-5"
                        onClick={() => moveDown(i)} />
                      <FaTrashAlt
                        className="text-primary cursor-pointer fs-5"
                        onClick={() => removeAsync(i)} />
                    </div> : <></>}
                  </div>
                </div>
              </Card>)}
          </>
        </Card>}
    </>
  );
}