import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Rule } from "models";
import { FaFileUpload } from "react-icons/fa";

interface Props {
  label?: string;
  type?: string;
  placeholder?: string;
  rule?: Rule;
  value?: File | Blob;
  onChange?: (value: File | Blob) => void;
  name: string;
  className?: string;
}

export function InputFile(props: Props) {
  const ref = useRef({} as HTMLInputElement);
  const [fileName, setFileName] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const validate = useCallback((value: File | Blob) => {
    const checkRequired = (value: File | Blob) => {
      if (props.rule?.required && !value) {
        setErrorMessage('กรุณาเลือกไฟล์');

        return false;
      }

      return true;
    }

    if (!checkRequired(value)) {
      return;
    }

    setErrorMessage('');
  }, [props.rule?.required]);

  useEffect(() => {
    document.addEventListener('onSubmitCustom', (data) => {
      const event = data as CustomEvent;
      const value = event.detail[props.name];

      validate(value);
    });
  }, [props.name, validate]);

  const getRequired = () => {
    if (props.rule?.required) {
      return <span className='text-danger'>*</span>;
    }

    return null;
  };

  const handlerOnChange = (event: HTMLInputElement) => {
    if (event.files?.length) {
      const file = event.files[0];

      if (props.onChange) {
        props.onChange(file);
      }

      setFileName(file.name);

      validate(file);
    }
  };

  const getErrorMessage = useMemo(() => {
    if (errorMessage) {
      return <Form.Text className="text-danger">{errorMessage}</Form.Text>;
    }

    return null;
  }, [errorMessage]);

  return (
    <Form.Group onClick={() => ref.current.click()} className={`w-100 ${props.className ?? ''} ${props.label ? 'mb-3' : ''}`}>
      {props.label ? <Form.Label className='m-0'>{props.label} {getRequired()}</Form.Label> : null}
      <InputGroup>
        <Form.Control value={fileName} readOnly className={`${getErrorMessage ? 'is-invalid' : ''}`} />
        <Form.Control className="d-none" type="file" ref={ref} onChange={event => handlerOnChange(event.target as HTMLInputElement)} />
        <InputGroup.Text><FaFileUpload /></InputGroup.Text>
      </InputGroup>
      {getErrorMessage}
    </Form.Group>
  );
}