import { Dispatch, SetStateAction } from "react";
import { useOutletContext } from "react-router-dom";

export function useModule() {
  return useOutletContext<{
    currentModule: string,
    setCurrentModule: Dispatch<SetStateAction<string>>,
    allModules: string[],
    setAllModules: Dispatch<SetStateAction<string[]>>,
  }>();
}