import { HttpStatusCode } from "axios";
import { Card, Layout, Status, StatusType, Table } from "components";
import Video, { VideoOption } from "components/Video/Video";
import { statusChapterItems } from "constant";
import { CtInquiryMeetingRecordDetailHeader, CtInquiryMeetingRecordDetailList, Pagination } from "models";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FaAngleLeft, FaCloudDownloadAlt, FaPlay } from "react-icons/fa";
import { useLoaderData, useNavigate, useParams, useSubmit } from "react-router-dom";
import { ctService } from "services";
import { formatDateTh, formatTimeToInput, yearToTHFormat } from "utils";

type Loader = {
  ctInquiryMeetingRecordHeader: CtInquiryMeetingRecordDetailHeader,
  data: Pagination<CtInquiryMeetingRecordDetailList>,
};

export default function C0303Detail() {
  const { ctInquiryMeetingRecordHeader, data } = useLoaderData() as Loader;
  const [selectedChapter, setSelectedChapter] = useState<CtInquiryMeetingRecordDetailList>({} as CtInquiryMeetingRecordDetailList);
  const videoRef = useRef<VideoOption>({} as VideoOption);
  const submit = useSubmit();
  const [showVideo, setShowVideo] = useState<File>();
  const meetingId = useParams().id;
  const [fileIds, setFileIds] = useState<string[]>([]);
  const [fileIndex, setFileIndex] = useState<number>(0);

  useEffect(() => {
    if (ctInquiryMeetingRecordHeader.id) {
      getFullVideoAsync(ctInquiryMeetingRecordHeader.id);
    }
  }, [ctInquiryMeetingRecordHeader]);

  useEffect(() => {
    if (fileIds.length) {
      selectViewVideoAsync(fileIds[0]);
      setFileIndex(0);
    } else {
      setShowVideo(undefined);
    }
  }, [fileIds]);

  const onSearch = (size: number, page: number) => {
    submit({
      page: page.toString(),
      size: size.toString(),
    });
  };

  const selectViewVideoAsync = async (id: string) => {
    const { data, status } = await ctService.getVideoAsync(id, meetingId);

    if (status === HttpStatusCode.Ok) {
      setShowVideo(data);

      return data;
    }
  };

  const downloadAsync = async (value: CtInquiryMeetingRecordDetailList) => {
    const responseFileId = await getFileIdAsync(value) as string[];

    responseFileId.forEach(async id => {
      const file = await selectViewVideoAsync(id);

      if (file) {
        const a = document.createElement('a');
        a.style.display = 'none';
        document.body.appendChild(a);
        a.href = URL.createObjectURL(new Blob([file], { type: file.type }));
        a.setAttribute('download', value.fileName!);
        a.click();

        URL.revokeObjectURL(a.href);
        document.body.removeChild(a);
      }
    });
  };

  const getTimeChapter = () => {
    if (selectedChapter.startTime && selectedChapter.endTime) {
      return `${formatTimeToInput(selectedChapter.startTime)}-${formatTimeToInput(selectedChapter.endTime)}`;
    }

    return '';
  };

  const convertStatusToText = (status: string) => {
    const findStatus = statusChapterItems.find(s => s.value === status);

    return findStatus?.label;
  };

  const getFullVideoAsync = async (id: string) => {
    const { data, status } = await ctService.getFullVideoAsync(id);

    if (status === HttpStatusCode.Ok) {
      setShowVideo(data);
    };
  };

  const getFileIdAsync = async (value: CtInquiryMeetingRecordDetailList) => {
    setSelectedChapter(value);

    const { data, status } = await ctService.getRecordFileIdAsync(value.id, meetingId!);

    if (status === HttpStatusCode.Ok) {
      setFileIds(data);

      return data;
    }
  };

  const onEndedAsync = async () => {
    const currentIndex = fileIndex + 1;

    if (fileIds.length > currentIndex) {
      selectViewVideoAsync(fileIds[currentIndex]);

      setFileIndex(currentIndex);
    }
    else {
      setFileIndex(0);
    }
  };

  const onLoadedData = () => {
    if (fileIndex) {
      videoRef.current.playRef();
    }
  };

  return (
    <Layout title="ค้นหาภาพและเสียงการประชุม">
      <HeaderButton
        onWatchVideoClick={() => getFullVideoAsync(ctInquiryMeetingRecordHeader.id)} />
      <Row>
        <Col md={4}>
          <Card title="ข้อมูลการประชุม" className="mt-3">
            <Row>
              <Col xs={12}>
                <p>สำนัก<span className="mx-2">:</span>{ctInquiryMeetingRecordHeader.ctBureauName}</p>
              </Col>
              <Col xs={12}>
                <p>คณะกรรมาธิการ/อื่นๆ<span className="mx-2">:</span>{ctInquiryMeetingRecordHeader.ctCommitteeName}</p>
              </Col>
              <Col xs={12}>
                <p>คณะอนุกรรมาธิการ/คณะทำงาน<span className="mx-2">:</span>{ctInquiryMeetingRecordHeader.ctSubCommitteeName}</p>
              </Col>
              <Col xs={12}>
                <p>ครั้งที่<span className="mx-2">:</span>{ctInquiryMeetingRecordHeader.time}</p>
              </Col>
              <Col xs={12}>
                <p>ปี<span className="mx-2">:</span>{yearToTHFormat(ctInquiryMeetingRecordHeader.year)}</p>
              </Col>
              <Col xs={12}>
                <p>ณ ห้องประชุม<span className="mx-2">:</span>{ctInquiryMeetingRecordHeader.smMeetingRoomName}</p>
              </Col>
              <Col xs={12}>
                <p>วันที่ประชุม<span className="mx-2">:</span>{formatDateTh(ctInquiryMeetingRecordHeader.meetingDate)}</p>
              </Col>
              <Col xs={12}>
                <p>เวลา<span className="mx-2">:</span>{`${formatTimeToInput(ctInquiryMeetingRecordHeader.startTime)}-${formatTimeToInput(ctInquiryMeetingRecordHeader.endTime)}`}</p>
              </Col>
            </Row>
          </Card>
          <Card title="รายละเอียดการบันทึกสัญญาณภาพและเสียง" className="mt-3">
            <Row>
              <Col xs={12}>
                <p>ช่วงเวลา<span className="mx-2">:</span>{getTimeChapter()}</p>
              </Col>
              <Col xs={12}>
                <p>ผู้จดรายงานการประชุม<span className="mx-2">:</span>{selectedChapter.name}</p>
              </Col>
              <Col xs={12}>
                <p>สถานะ<span className="mx-2">:</span>{convertStatusToText(selectedChapter.status)}</p>
              </Col>
              <Col xs={12}>
                <p>ชื่อไฟล์วิดีโอ<span className="mx-2">:</span>{selectedChapter.fileName}</p>
              </Col>
              <Col xs={12}>
                <p>เวลา<span className="mx-2">:</span>{selectedChapter.time}</p>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col md={8} className="mt-3">
          <Video
            ref={videoRef}
            onEnded={onEndedAsync}
            onLoadedData={onLoadedData}
            files={showVideo?.type ? [showVideo] : []}
            showPlayButton={false}
            showPlayTimeConfig={false}
            showSpeakToText={false}>
          </Video>
        </Col>
      </Row>
      <Table total={data.totalRows} onChange={onSearch} className='mt-3' >
        <Table.Header>
          <Table.Row>
            <Table.Column minWidth={75}>ตอนที่</Table.Column>
            <Table.Column minWidth={125}>ช่วงเวลา</Table.Column>
            <Table.Column minWidth={200}>ผู้จดรายงานการประชุม</Table.Column>
            <Table.Column minWidth={150}>สถานะ</Table.Column>
            <Table.Column minWidth={100}>ชื่อไฟลวิดีโอ</Table.Column>
            <Table.Column minWidth={100}>เวลา</Table.Column>
            <Table.Column minWidth={250} />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.rows?.map((value) => (
            <Table.Row key={value.id}>
              <Table.Cell center>{value.chapter}</Table.Cell>
              <Table.Cell center>{`${formatTimeToInput(value.startTime)}-${formatTimeToInput(value.endTime)}`}</Table.Cell>
              <Table.Cell>{value.name}</Table.Cell>
              <Table.Cell center><Status type={StatusType.CHAPTER_STATUS} value={value.status} /></Table.Cell>
              <Table.Cell center>{value.fileName}</Table.Cell>
              <Table.Cell center>{value.time}</Table.Cell>
              <Table.Cell center>
                <div className="d-flex gap-2 justify-content-center">
                  <Button
                    variant="outline-primary"
                    type="button"
                    onClick={() => downloadAsync(value)}>
                    <FaCloudDownloadAlt className="me-2" />ดาวน์โหลดวิดีโอ
                  </Button>
                  <Button
                    variant="outline-primary"
                    type="button"
                    onClick={() => getFileIdAsync(value)}>
                    <FaPlay className="me-2" />ดูวิดีโอ
                  </Button>
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Layout>
  );
};

function HeaderButton(
  props: { onWatchVideoClick: () => void }
) {
  const navigate = useNavigate();

  return (
    <div className="my-3 d-flex justify-content-between">
      <Button
        variant="outline-primary"
        type="button"
        onClick={() => navigate('/c0303')}>
        <FaAngleLeft className="me-2" />ย้อนกลับ
      </Button>
      <Button
        variant="outline-primary"
        type="button"
        onClick={props.onWatchVideoClick}>
        <FaPlay className="me-2" />ดูวิดีโอที่รวบรวม
      </Button>
    </div>
  );
};