import { SelectionType } from "constant";
import { InQuiryMeetingVideoCriteria, InQuiryMeetingVideoDetailList, InQuiryMeetingVideoList, InquiryMeetingVideoDetailCriteria, ItemModel, Pagination } from "models";
import { LoaderFunctionArgs } from "react-router-dom";
import { getYearList } from "utils";
import { s0303 as service, selectionService as ddlService } from 'services';

export const s0303LoaderAsync = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 1;
  const size = url.searchParams.get('size') || 10;
  const criteriaStringify = url.searchParams.get('criteria') || '{}';
  const criteria = JSON.parse(criteriaStringify) as InQuiryMeetingVideoCriteria;

  const yearsDDL: ItemModel[] = getYearList();
  const [meetingPeriodDDL, meetingTypeDDL, listData] = await Promise.all([
    ddlService.getSelectionAsync(SelectionType.SN_MEETING_PERIOD),
    ddlService.getSelectionAsync(SelectionType.SN_MEETING_TYPE),
    service.getListAsync(+page, +size, criteria),
  ]);

  const dataResult: Pagination<InQuiryMeetingVideoList> = listData.data || {} as Pagination<InQuiryMeetingVideoList>;
  dataResult.page = +page;
  dataResult.size = +size;

  return {
    meetingTypeDDL: meetingTypeDDL.data,
    meetingPeriodDDL: meetingPeriodDDL.data,
    listData: dataResult,
    yearsDDL: yearsDDL,
  };
};

export const s0303DetailLoaderAsync = async ({ request, params }: LoaderFunctionArgs) => {
  const { id } = params;
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 1;
  const size = url.searchParams.get('size') || 10;
  const criteriaStringify = url.searchParams.get('criteria') || '{}';
  const criteria = JSON.parse(criteriaStringify) as InquiryMeetingVideoDetailCriteria;

  const [detailData, detailListData] = await Promise.all([
    service.getDetailAsync(id),
    service.getDetailListAsync(+page, +size, criteria, id),
  ]);

  const dataResult: Pagination<InQuiryMeetingVideoDetailList> = detailListData.data || {} as Pagination<InQuiryMeetingVideoDetailList>;
  dataResult.page = +page;
  dataResult.size = +size;

  return {
    headerData: detailData.data,
    listData: dataResult,
  };
};