import Cookies from "js-cookie";

const set = (name: string, value: string) => {
  const expires = new Date();

  expires.setFullYear(expires.getFullYear() + 100);

  Cookies.set(name, value, { expires });
};

const get = (name: string) => Cookies.get(name) ?? '';

const remove = (name: string) => Cookies.remove(name);

const cookie = {
  set,
  get,
  remove,
};

export default cookie;