import { SelectionType } from 'constant';
import { CriteriaOldSystemMeeting } from 'models';
import { LoaderFunctionArgs } from 'react-router-dom';
import { s0304, selectionService } from 'services';

export const s0304LoaderAsync = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 1;
  const size = url.searchParams.get('size') || 10;
  const criteriaStringify = url.searchParams.get('criteria') || '{}';
  const criteria = JSON.parse(criteriaStringify) as CriteriaOldSystemMeeting;

  const [meetingType, listData] = await Promise.all([
    selectionService.getSelectionAsync(SelectionType.SN_MEETING_TYPE),
    s0304.getListAsync(+page, +size, criteria)]
  );

  return {
    meetingType: meetingType.data,
    data: {
      rows: listData.data.rows,
      totalRows: listData.data.totalRows,
      page: +page,
      size: +size,
    },
  };
};