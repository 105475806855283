import { CtReportMakeDailyCreate, CtReportMakerDailyCriteria } from 'models';
import http from 'utils/axios';

const getCtReportMakerDailyListAsync = async (page: number, size: number, criteria?: CtReportMakerDailyCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get('api/CtReportMakerDaily', { params });
};

const getCtReportMakerDailyGenerateAsync = async (day: string) => {
  const params = {
    day,
  };

  return await http.get('api/CtReportMakerDaily/generate', { params, headers: { isDisabledErrorMessage: true } });
};

const getCtReportMakerDailyDetailAsync = async (id: string) => {
  return await http.get(`api/CtReportMakerDaily/${id}`);
};

const createCtReportMakerDailyAsync = async (body: CtReportMakeDailyCreate) => {
  return await http.post('api/CtReportMakerDaily', body);
};

const updateCtReportMakerDailyAsync = async (id: string, body: CtReportMakeDailyCreate) => {
  return await http.put(`api/CtReportMakerDaily/${id}`, body);
};

const checkDuplicateDateAsync = async (date: Date) => {
  const params = {
    date,
  };

  return await http.get(`api/CtReportMakerDaily/checkduplicate`, { params });
};

const exportExcelAsync = async (id: string) => {
  return await http.get(`api/CtReportMakerDaily/export-excel/${id}`, { responseType: 'blob' });
};

const ctReportMakerDailyService = {
  getCtReportMakerDailyListAsync,
  getCtReportMakerDailyGenerateAsync,
  getCtReportMakerDailyDetailAsync,
  createCtReportMakerDailyAsync,
  updateCtReportMakerDailyAsync,
  checkDuplicateDateAsync,
  exportExcelAsync,
};

export default ctReportMakerDailyService;