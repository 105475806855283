import { SelectionType } from "constant";
import { MeetingRecordCriteria } from "models";
import { LoaderFunctionArgs } from "react-router-dom";
import { s0202 as service, selectionService as ddlService, s0302 } from "services";
import { getYearList } from "utils";

export const s0202LoaderAsync = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 1;
  const size = url.searchParams.get('size') || 10;
  const criteriaStringify = url.searchParams.get('criteria') || '{}';
  const criteria = JSON.parse(criteriaStringify) as MeetingRecordCriteria;

  const [meetingPeriodDDL, meetingTypeDDL, meetingRecordList] = await Promise.all([
    ddlService.getSelectionAsync(SelectionType.SN_MEETING_PERIOD),
    ddlService.getSelectionAsync(SelectionType.SN_MEETING_TYPE),
    service.getSnMeetingRecordListAsync(+page, +size, criteria),
  ]);

  return {
    meetingTypeDDL: meetingTypeDDL.data,
    meetingPeriodDDL: meetingPeriodDDL.data,
    meetingRecordList: {
      rows: meetingRecordList.data.rows,
      totalRows: meetingRecordList.data.totalRows,
      page: +page,
      size: +size,
    },
    yearsDDL: getYearList(),
  };
};

export const s0202DetailLoaderAsync = async ({ params }: LoaderFunctionArgs) => {
  const id = params.id;

  const [data, meetingHeaderDetail] = await Promise.all([
    service.getSnMeetingRecordDetailListAsync(1, 10, id),
    s0302.getSnInquiryMeetingHeaderAsync(id ?? ''),
  ]);

  return {
    meetingRecordDetailList: data,
    meetingHeaderDetail: meetingHeaderDetail.data,
  };
};