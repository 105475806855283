import { Card, Input, InputTime, Layout, Selector, Status, StatusType, Table } from "components";
import { ItemModel, MeetingInspectDetailCriteria, MeetingInspectDetailModel, MeetingInspectListModel, Pagination } from "models";
import { Dispatch, FormEvent, SetStateAction, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaAngleLeft, FaSearch, FaTrashAlt, FaFileAlt } from "react-icons/fa";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { getFormatDateBC, getTime } from "utils";
import { s0205 as service } from 'services';
import { HttpStatusCode } from "axios";
import { s0205ChapterStatusItems } from "constant";

type MeetingInspectDetailContext = {
  inspectDetail: Pagination<MeetingInspectDetailModel>;
  setInspectDetail: Dispatch<SetStateAction<Pagination<MeetingInspectDetailModel>>>;
  criteria: MeetingInspectDetailCriteria;
  searchAsync: Function;
};

const Context = createContext({} as MeetingInspectDetailContext);

type Loader = { inspectDetailList: Pagination<MeetingInspectDetailModel>, agendaDDL: ItemModel[] };


export default function S0205Detail() {
  const navigate = useNavigate();
  const headerData = useLocation().state as MeetingInspectListModel;
  const detailId = useLocation().state.id as string;
  const { inspectDetailList, agendaDDL } = useLoaderData() as Loader;
  const [inspectDetail, setInspectDetail] = useState<Pagination<MeetingInspectDetailModel>>(inspectDetailList);
  const [criteria, setCriteria] = useState<MeetingInspectDetailCriteria>({} as MeetingInspectDetailCriteria);
  const [mount, setMount] = useState<boolean>(false);

  const searchAsync = useCallback(async (page: number, size: number, id: string, criteria?: MeetingInspectDetailCriteria) => {
    const { data, status } = await service.getInspectDetailListAsync(id, page, size, criteria);

    if (status === HttpStatusCode.Ok) {
      const response: Pagination<MeetingInspectDetailModel> = data;
      response.page = page;
      response.size = size;

      setInspectDetail(response);
    }
  }, []);

  useEffect(() => {
    if (mount) {
      searchAsync(inspectDetail.page, inspectDetail.size, detailId);
    }

    !mount && setMount(true);
  }, [searchAsync]);

  const onClearAsync = async () => {
    setCriteria({} as MeetingInspectDetailCriteria);
    searchAsync(1, 10, detailId, {} as MeetingInspectDetailCriteria);
  };

  const onChangeTime = (prop: keyof MeetingInspectDetailCriteria, time?: Date) => {
    setCriteria({ ...criteria, [prop]: time });
  };

  const contextValue = useMemo(() => {
    return {
      inspectDetail,
      setInspectDetail,
      criteria,
      searchAsync,
    }
  }, [inspectDetail, setInspectDetail, criteria, searchAsync]);

  const onSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    searchAsync(inspectDetail.page, inspectDetail.size, detailId, criteria);
  };

  return (
    <Context.Provider value={contextValue}>
      <Layout title="ตรวจทานและรวบรวมรายงานการประชุม">
        <div className="d-flex my-3">
          <Button
            variant="outline-primary"
            type="button"
            onClick={() => navigate("/s0205")}>
            <FaAngleLeft className="me-2" />ย้อนกลับ
          </Button>
        </div>
        <Card>
          <label><b>ข้อมูลการประชุม</b></label>
          <div className="mt-3 mx-4">
            <Row>
              <Col sm={6} md={4} lg={4} xl={3}>
                <p>ประเภทการประชุม : {headerData.meetingType}</p>
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <p>ครั้งที่ : {headerData.time}</p>
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <p>สมัยที่ประชุม : {headerData.meetingPeriod}</p>
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <p>วันที่ประชุม  : {getFormatDateBC(headerData.meetingDate)}</p>
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <p>เวลา : {`${getTime(headerData.startTime)} - ${getTime(headerData.endTime)}`}
                </p>
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <p>กลุ่มงานที่รับผิดชอบ : {headerData.bureauGroupNames}</p>
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <div className="d-flex gap-1">สถานะ : <Status type={StatusType.MEETING_STATUS} value={headerData.status} textOnly /></div>
              </Col>
            </Row>
          </div>
        </Card>
        <Card className="mt-2">
          <label>ค้นหา</label>
          <Form onSubmit={onSubmitSearch}>
            <Row>
              <Col sm={6} md={4} lg={4} xl={3}>
                <Input
                  label="ตอนที่"
                  value={criteria.chapter}
                  onChange={(value) => setCriteria({ ...criteria, chapter: Number(value) })} />
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <InputTime
                  name="startTime"
                  label="ช่วงเวลาเริ่มต้น"
                  value={criteria.startTime}
                  onChange={(value) => onChangeTime("startTime", value)} />
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <InputTime
                  name="endTime"
                  label="ช่วงเวลาสิ้นสุด"
                  value={criteria.endTime}
                  onChange={(value) => onChangeTime("endTime", value)} />
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <Selector
                  label="วาระการประชุม"
                  items={agendaDDL}
                  value={criteria.meetingAgendaId}
                  onChange={(value) => setCriteria({ ...criteria, meetingAgendaId: value.toString() })} />
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <Input
                  label="ผู้อภิปราย"
                  value={criteria.meetingMemberName}
                  onChange={(value) => setCriteria({ ...criteria, meetingMemberName: value.toString() })} />
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <Input
                  label="ผู้จดรายงานการประชุม"
                  value={criteria.dutyOfficerName}
                  onChange={(value) => setCriteria({ ...criteria, dutyOfficerName: value.toString() })} />
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <Selector
                  label="สถานะ"
                  items={s0205ChapterStatusItems}
                  value={criteria.status}
                  onChange={(value) => setCriteria({ ...criteria, status: value.toString() })} />
              </Col>
              <div className="d-flex justify-content-start gap-2 mt-2">
                <Button
                  variant="primary"
                  type="submit">
                  <FaSearch size={15} className="me-2" />ค้นหา
                </Button>
                <Button
                  variant="outline-primary"
                  onClick={onClearAsync}>
                  <FaTrashAlt size={15} className="me-2" />ล้างค่า
                </Button>
              </div>
            </Row>
          </Form>
        </Card>
        <div className="my-2 d-flex justify-content-end">
          <Button
            onClick={() => navigate("document", { state: headerData })}>
            <FaFileAlt className="me-2" />ตรวจทาน
          </Button>
        </div>
        <DataTable />
      </Layout>
    </Context.Provider>
  );
}

function DataTable() {
  const { inspectDetail, searchAsync, criteria } = useContext(Context);
  const detailId = useLocation().state.id as string;

  return (
    <Table
      total={inspectDetail.totalRows}
      page={inspectDetail.page}
      size={inspectDetail.size}
      onChange={(size, page) => searchAsync(page, size, detailId, criteria)}>
      <Table.Header>
        <Table.Row>
          <Table.Column minWidth={100}>ตอนที่</Table.Column>
          <Table.Column minWidth={150}>ช่วงเวลา</Table.Column>
          <Table.Column minWidth={200}>กลุ่มงาน</Table.Column>
          <Table.Column minWidth={250}>ผู้จดรายงานการประชุม</Table.Column>
          <Table.Column minWidth={200}>ผู้ตรวจทาน</Table.Column>
          <Table.Column minWidth={200}>สถานะ</Table.Column>
          <Table.Column minWidth={250}>วาระการประชุม</Table.Column>
          <Table.Column minWidth={250}>ผู้อภิปราย</Table.Column>
          <Table.Column minWidth={200}>แก้ไขครั้งล่าสุด</Table.Column>
          <Table.Column minWidth={200}>เวอร์ชั่น</Table.Column>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {inspectDetail.rows?.map((value) => (
          <Table.Row key={value.id}>
            <Table.Cell center>{value.chapter}</Table.Cell>
            <Table.Cell center>{`${getTime(value.startDate)} - ${getTime(value.endDate)}`}</Table.Cell>
            <Table.Cell center>{value.bureauGroupName}</Table.Cell>
            <Table.Cell>{value.dutyOfficerFullName}</Table.Cell>
            <Table.Cell>{value.reviewerFullName}</Table.Cell>
            <Table.Cell center><Status type={StatusType.CHAPTER_STATUS} value={value.status} /></Table.Cell>
            <Table.Cell center>{value.agendaTitle}</Table.Cell>
            <Table.Cell>{value.debater}</Table.Cell>
            <Table.Cell center>{getFormatDateBC(value.updateDate)}</Table.Cell>
            <Table.Cell center>{value.versionDocument?.toString()}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}