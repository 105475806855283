import { SnInquiryMeetingCriteria } from 'models';
import http from 'utils/axios';

const getSnInquiryMeetingListAsync = async (page: number, size: number, criteria: SnInquiryMeetingCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get('api/SnInquiryMeeting', { params });
};

const getSnInquiryMeetingDetailAsync = async (id: string) => {
  return await http.get(`api/SnInquiryMeeting/meeting/${id}`);
};

const getSnInquiryMeetingRankAsync = async (id: string) => {
  return await http.get(`api/SnInquiryMeeting/meetingrank/${id}`);
};

const getSnInquiryMeetingHeaderAsync = async (id: string) => {
  return await http.get(`api/SnInquiryMeeting/meeting/${id}/header`);
};

const getMeetingDocumentAsync = async (id: string) => {
  return await http.get(`api/SnInquiryMeeting/meetingdocument/${id}`);
};

const snInquiryMeetingService = {
  getSnInquiryMeetingListAsync,
  getSnInquiryMeetingDetailAsync,
  getSnInquiryMeetingRankAsync,
  getSnInquiryMeetingHeaderAsync,
  getMeetingDocumentAsync,
};

export default snInquiryMeetingService;