import { CtMeetingDocument, CtReviewChapterCriteria, CtReviewOverviewCriteria } from 'models';
import http from 'utils/axios';

const getCtReviewOverviewListAsync = async (page: number, size: number, criteria?: CtReviewOverviewCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get('api/ctreviewoverview', { params });
};

const getCtReviewOverviewDetailAsync = async (id: string) => {
  return await http.get(`api/ctreviewoverview/ctmeeting/${id}`);
};

const updateCtReviewOverviewStatusAsync = async (id: string, status: string, documents: CtMeetingDocument[], numberOfPages?: number) => {
  const body = {
    status,
    documents,
    numberOfPages,
  };

  return await http.put(`api/ctreviewoverview/ctmeeting/${id}`, body);
};

const uploadFileAsync = async (meetingId: string, document: File, sequence: number) => {
  const formData = new FormData();
  formData.append('document', document);
  formData.append('sequence', sequence.toString());

  return http.post(`api/ctreviewoverview/ctmeeting/${meetingId}/document`, formData);
};

const removeFileAsync = async (meetingId: string, documentId: string) => {
  return http.delete(`api/ctreviewoverview/ctmeeting/${meetingId}/document/${documentId}`);
};

const getCtReviewChapterListAsync = async (id: string, page: number, size: number, criteria?: CtReviewChapterCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get(`api/ctreviewoverview/ctmeeting/${id}/ctmeetingchapter`, { params });
};


const ctReviewOverviewService = {
  getCtReviewOverviewListAsync,
  getCtReviewOverviewDetailAsync,
  updateCtReviewOverviewStatusAsync,
  uploadFileAsync,
  removeFileAsync,
  getCtReviewChapterListAsync
};

export default ctReviewOverviewService;
