import { Module, SelectionType, ShareValue, StaticId } from "constant";
import { IndividualCriteria, ItemModel } from "models";
import { d03Service, selectionService } from "services";

export const d03LoaderAsync = async () => {
  const [
    bureauGroupItems,
    dailyStatusItems,
    leaveList,
    dutyList,
    commanderList,
    summaryList,
    senateData,
    individualSenateData,
    committeeData,
    individualCommitteeData] = await Promise.all([
      selectionService.getSelectionAsync(SelectionType.SM_BUREAU_GROUP),
      selectionService.getShareValueAsync(ShareValue.DAILY_STATUS),
      d03Service.getLeaveListAsync(1, 10),
      d03Service.getIndividualPerformanceOverviewDashboardDutyAsync(1, 10, Module.Senate, {} as IndividualCriteria),
      d03Service.getIndividualPerformanceOverviewDashboardCommanderAsync(1, 10, Module.Senate, {} as IndividualCriteria),
      d03Service.getIndividualperformanceoverviewdashboardSummaryAsync(Module.Senate, {} as IndividualCriteria),
      d03Service.getIndividualperformanceoverviewdashboardSenateAsync(1, 10, {} as IndividualCriteria),
      d03Service.getSenateDataTableByUserAsync(1, 10, {} as IndividualCriteria),
      d03Service.getIndividualperformanceoverviewdashboardCommitteeAsync(1, 10, {} as IndividualCriteria),
      d03Service.getCommitteeDataTableByUserAsync(1, 10, {} as IndividualCriteria),
    ]);

  const castTypeData = bureauGroupItems.data as ItemModel[];
  const filteringBureauGroupData = castTypeData.filter(f => f.id === StaticId.BUREAU_ID);

  return {
    bureauGroupItems: [...filteringBureauGroupData.slice(2)],
    dailyStatusItems: dailyStatusItems.data,
    leaveListData: leaveList.data,
    dutyListData: dutyList.data,
    commanderListData: commanderList.data,
    summaryLoaderData: summaryList.data,
    module: Module.Senate,
    senateLoaderData: {
      rows: senateData.data.rows,
      totalRows: senateData.data.totalRows,
      senatePage: 1,
      senateSize: 10,
    },
    individualSenateLoaderData: {
      rows: individualSenateData.data.rows,
      totalRows: individualSenateData.data.totalRows,
      senateByUserPage: 1,
      senateByUserSize: 10,
    },
    committeeLoaderData: {
      rows: committeeData.data.rows,
      totalRows: committeeData.data.totalRows,
      committeePage: 1,
      committeeSize: 10,
    },
    individualCommitteeLoaderData: {
      rows: individualCommitteeData.data.rows,
      totalRows: individualCommitteeData.data.totalRows,
      committeeByUserPage: 1,
      committeeByUserSize: 10,
    },
  };
};