export const SHORT_MONTHS = [
  'ม.ค.',
  'ก.พ.',
  'มี.ค.',
  'เม.ย.',
  'พ.ค.',
  'มิ.ย.',
  'ก.ค.',
  'ส.ค.',
  'ก.ย.',
  'ต.ค.',
  'พ.ย.',
  'ธ.ค.',
];

export const LONG_MONTHS = [
  'มกราคม',
  'กุมภาพันธ์',
  'มีนาคม',
  'เมษายน',
  'พฤษภาคม',
  'มิถุนายน',
  'กรกฎาคม',
  'สิงหาคม',
  'กันยายน',
  'ตุลาคม',
  'พฤศจิกายน',
  'ธันวาคม',
];

export const LONG_DAYS = [
  { label: 'วันอาทิตย์', value: 0 },
  { label: 'วันจันทร์', value: 1 },
  { label: 'วันอังคาร', value: 2 },
  { label: 'วันพุธ', value: 3 },
  { label: 'วันพฤหัสบดี', value: 4 },
  { label: 'วันศุกร์', value: 5 },
  { label: 'วันเสาร์', value: 6 },
];
