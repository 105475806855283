import { Code, SelectionType, ShareValue } from "constant";
import { ItemModel, SnMeetingCriteria } from "models";
import { LoaderFunctionArgs } from "react-router-dom";
import { m0104, s0201, selectionService } from "services";

export const s0201LoaderAsync = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 1;
  const size = url.searchParams.get('size') || 10;
  const criteriaStringify = url.searchParams.get('criteria') || '{}';
  const criteria = JSON.parse(criteriaStringify) as SnMeetingCriteria;

  const [snMeetingData, snMeetingType, snMeetingPeriod] = await Promise.all([
    s0201.getSnMeetingListAsync(+page, +size, criteria),
    selectionService.getSelectionAsync(SelectionType.SN_MEETING_TYPE),
    selectionService.getSelectionAsync(SelectionType.SN_MEETING_PERIOD),
  ]);

  return {
    snMeetingList: {
      rows: snMeetingData.data.rows,
      totalRows: snMeetingData.data.totalRows,
      page: +page,
      size: +size,
    },
    snMeetingTypeItems: snMeetingType.data,
    snMeetingPeriodItems: snMeetingPeriod.data,
  }
};

export const s0201DetailLoaderAsync = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;
  const [snMeetingType, snMeetingPeriod, snMeetingRoom, snAgendatype, snMember, attendeeTypeData] = await Promise.all([
    selectionService.getSelectionAsync(SelectionType.SN_MEETING_TYPE),
    selectionService.getSelectionAsync(SelectionType.SN_MEETING_PERIOD),
    selectionService.getSelectionAsync(SelectionType.SM_MEETING_ROOM),
    selectionService.getSelectionAsync(SelectionType.SN_AGENDA_TYPE),
    selectionService.getSelectionAsync(SelectionType.SN_MEMBER),
    selectionService.getShareValueAsync(ShareValue.ATTENDEE_TYPE),
  ]);

  if (id) {
    const [snMeetingData] = await Promise.all([
      await s0201.getSnMeetingDetailAsync(id),
    ]);

    return {
      snMeetingDetailData: snMeetingData.data,
      snMeetingTypeItems: snMeetingType.data,
      snMeetingPeriodItems: snMeetingPeriod.data,
      snMeetingRoomItems: snMeetingRoom.data,
      snAgendaTypeItems: snAgendatype.data,
      snMemberItems: snMember.data,
      attendeeTypeItems: attendeeTypeData.data,
    }
  }

  return {
    snMeetingTypeItems: snMeetingType.data,
    snMeetingPeriodItems: snMeetingPeriod.data,
    snMeetingRoomItems: snMeetingRoom.data,
    snAgendaTypeItems: snAgendatype.data,
    snMemberItems: snMember.data,
    attendeeTypeItems: attendeeTypeData.data,
  }
};

export const s0201QueueLoaderAsync = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;

  if (id) {
    const [snMeetingData,
      snMeetingPeriod,
      snMeetingInspect,
      smBureauGroup,
      smUser,
      positionType,
      snMeetingRank,
      smBureau,
      snOfficerRank,
      cuttingTime,
    ] = await Promise.all([
      s0201.getSnMeetingDetailAsync(id),
      selectionService.getSelectionAsync(SelectionType.SN_MEETING_PERIOD),
      s0201.getSnMeetingInspectAsync(id),
      selectionService.getSelectionAsync(SelectionType.SM_BUREAU_GROUP),
      selectionService.getSelectionAsync(SelectionType.SM_USER),
      selectionService.getShareValueAsync(ShareValue.POSITION_TYPE),
      s0201.getSnMeetingRankAsync(id),
      selectionService.getSelectionAsync(SelectionType.SM_BUREAU),
      selectionService.getSelectionAsync(SelectionType.SN_OFFICER_RANK),
      m0104.getCuttingTimeAsync(),
    ]);

    const bureauItems = smBureau.data as ItemModel[];

    return {
      snMeetingDetailData: snMeetingData.data,
      snMeetingPeriodItems: snMeetingPeriod.data,
      snMeetingInspectData: snMeetingInspect.data,
      smBureauGroupItems: smBureauGroup.data,
      smUserItems: smUser.data,
      positionTypeItems: positionType.data,
      snMeetingRankData: snMeetingRank.data,
      smBureauItems: bureauItems.filter(f => f.label.includes(Code.BUREAU_CODE)),
      snOfficerRank: snOfficerRank.data,
      cuttingTime: cuttingTime.data,
    }
  }

  return {
    snMeetingDetailData: null,
    snMeetingPeriodItems: null,
    snMeetingInspectData: null,
    smBureauGroupItems: null,
    smUserItems: null,
    positionTypeItems: null,
    snMeetingRankData: null,
    cuttingTime: null,
  }
};