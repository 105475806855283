import { RouteObject, createBrowserRouter } from 'react-router-dom';
import App from 'App';
import PageBase from 'pages/PageBase';
import { M0101, M0102, M0102Detail, M0103, M0103Detail, M0104, M0105 } from 'pages/M';
import { Demo, NotFound, SignIn, Unauthorized, FreePage } from 'pages/Other';
import { D01, D02, D03 } from 'pages/D';
import { S0101, S0102, S0103, S0104, S0201, S0201Detail, S0201Queue, S0202, S0202Detail, S0203, S0203Detail, S0204, S0204Detail, S0204Document, S0205, S0205Detail, S0205Document, S0206, S0206Detail, S0207, S0207Document, S0301, S0301Detail, S0301Document, S0302, S0302Detail, S0302List, S0303, S0303Detail, S0304 } from 'pages/S';
import { C0100, C0101, C0102, C0103, C0104, C0201, C0201Detail, C0202, C0202Detail, C0203, C0203Detail, C0204, C0204Detail, C0204Document, C0205, C0205Detail, C0205Document, C0206, C0206Detail, C0207, C0207Document, C0301, C0301Detail, C0301Document, C0302, C0302Detail, C0302Document, C0303, C0303Detail } from 'pages/C';
import { m0101LoaderAsync, m0102DetailLoader, m0103LoaderAsync, m0103DetailLoaderAsync, m0104LoaderAsync, m0105LoaderAsync, s0101LoaderAsync, s0102LoaderAsync, s0103LoaderAsync, s0104LoaderAsync, s0201LoaderAsync, s0201DetailLoaderAsync, s0201QueueLoaderAsync, c0101LoaderAsync, c0102LoaderAsync, c0103LoaderAsync, s0202LoaderAsync, s0202DetailLoaderAsync, s0203LoaderAsync, s0203DetailLoaderAsync, s0204LoaderAsync, s0204DetailLoaderAsync, s0204DocumentLoaderAsync, s0205LoaderAsync, s0205DetailLoaderAsync, s0205DocumentLoaderAsync, s0206LoaderAsync, s0206DetailLoaderAsync, s0207LoaderAsync, s0207DetailLoaderAsync, s0301LoaderAsync, s0301DetailLoaderAsync, s0301DocumentLoaderAsync, s0302DetailLoaderAsync, s0302ListLoaderAsync, s0303LoaderAsync, s0303DetailLoaderAsync, s0302LoaderAsync, c0104LoaderAsync, c0201LoaderAsync, c0201DetailLoaderAsync, c0202LoaderAsync, c0202DetailLoaderAsync, c0203LoaderAsync, c0203DetailLoaderAsync, c0204LoaderAsync, c0204DetailLoaderAsync, c0205LoaderAsync, c0205DetailLoaderAsync, c0206LoaderAsync, c0206DetailLoaderAsync, c0207LoaderAsync, c0207DetailLoaderAsync, c0301LoaderAsync, c0301DetailLoaderAsync, c0301DocumentLoaderAsync, c0302LoaderAsync, c0302DetailLoaderAsync, c0302DocumentLoaderAsync, c0303LoaderAsync, c0303DetailLoaderAsync, c0204DocumentLoaderAsync, d01LoaderAsync, d03LoaderAsync, d02LoaderAsync, c0100LoaderAsync, s0304LoaderAsync, } from 'loaders';


const d = [
  {
    path: "/d01",
    element: <D01 />,
    loader: d01LoaderAsync,
  },
  {
    path: "/d02",
    element: <D02 />,
    loader: d02LoaderAsync,
  },
  {
    path: "/d03",
    element: <D03 />,
    loader: d03LoaderAsync,
  },
] as RouteObject[];

const s = [
  {
    path: "/s0101",
    element: <S0101 />,
    loader: s0101LoaderAsync,
  },
  {
    path: "/s0102",
    element: <S0102 />,
    loader: s0102LoaderAsync,
  },
  {
    path: "/s0103",
    element: <S0103 />,
    loader: s0103LoaderAsync,
  },
  {
    path: "/s0104",
    element: <S0104 />,
    loader: s0104LoaderAsync,
  },
  {
    path: "/s0201",
    element: <S0201 />,
    loader: s0201LoaderAsync,
  },
  {
    path: '/s0201/detail',
    element: <S0201Detail />,
    loader: s0201DetailLoaderAsync,
  },
  {
    path: '/s0201/detail/:id',
    element: <S0201Detail />,
    loader: s0201DetailLoaderAsync,
  },
  {
    path: '/s0201/queue/:id',
    element: <S0201Queue />,
    loader: s0201QueueLoaderAsync,
  },
  {
    path: '/s0202',
    element: <S0202 />,
    loader: s0202LoaderAsync,
  },
  {
    path: '/s0202/detail/:id',
    element: <S0202Detail />,
    loader: s0202DetailLoaderAsync,
  },
  {
    path: '/s0203',
    element: <S0203 />,
    loader: s0203LoaderAsync,
  },
  {
    path: "/s0203/detail/:id",
    element: <S0203Detail />,
    loader: s0203DetailLoaderAsync,
  },
  {
    path: "/s0204",
    element: <S0204 />,
    loader: s0204LoaderAsync,
  },
  {
    path: "/s0204/detail/:id",
    element: <S0204Detail />,
    loader: s0204DetailLoaderAsync,
  },
  {
    path: "/s0204/detail/:id/document/:documentId",
    element: <S0204Document />,
    loader: s0204DocumentLoaderAsync,
  },
  {
    path: "/s0205",
    element: <S0205 />,
    loader: s0205LoaderAsync,
  },
  {
    path: "/s0205/detail/:id",
    element: <S0205Detail />,
    loader: s0205DetailLoaderAsync,
  },
  {
    path: "/s0205/detail/:id/document",
    element: <S0205Document />,
    loader: s0205DocumentLoaderAsync,
  },
  {
    path: "/s0206",
    element: <S0206 />,
    loader: s0206LoaderAsync,
  },
  {
    path: "/s0206/detail/:id",
    element: <S0206Detail />,
    loader: s0206DetailLoaderAsync,
  },
  {
    path: "/s0207",
    element: <S0207 />,
    loader: s0207LoaderAsync,
  },
  {
    path: "/s0207/document/:id",
    element: <S0207Document />,
    loader: s0207DetailLoaderAsync,
  },
  {
    path: "/s0301",
    element: <S0301 />,
    loader: s0301LoaderAsync,
  },
  {
    path: "/s0301/detail/:id",
    element: <S0301Detail />,
    loader: s0301DetailLoaderAsync,
  },
  {
    path: "/s0301/detail/:id/document/:docId",
    element: <S0301Document />,
    loader: s0301DocumentLoaderAsync,
  },
  {
    path: "/s0302",
    element: <S0302 />,
    loader: s0302LoaderAsync,
  },
  {
    path: "/s0302/list/:id/detail/:detailId/type/:type",
    element: <S0302Detail />,
    loader: s0302DetailLoaderAsync,
  },
  {
    path: "/s0302/list/:id",
    element: <S0302List />,
    loader: s0302ListLoaderAsync,
  },
  {
    path: "/s0303",
    element: <S0303 />,
    loader: s0303LoaderAsync,
  },
  {
    path: "/s0303/detail/:id",
    element: <S0303Detail />,
    loader: s0303DetailLoaderAsync,
  },
  {
    path: "/s0304",
    element: <S0304 />,
    loader: s0304LoaderAsync,
  }
] as RouteObject[];

const c = [
  {
    path: "/c0100",
    element: <C0100 />,
    loader: c0100LoaderAsync,
  },
  {
    path: "/c0101",
    element: <C0101 />,
    loader: c0101LoaderAsync,
  },
  {
    path: "/c0102",
    element: <C0102 />,
    loader: c0102LoaderAsync,
  },
  {
    path: "/c0103",
    element: <C0103 />,
    loader: c0103LoaderAsync,
  },
  {
    path: "/c0104",
    element: <C0104 />,
    loader: c0104LoaderAsync,
  },
  {
    path: "/c0201",
    element: <C0201 />,
    loader: c0201LoaderAsync,
  },
  {
    path: "/c0201/detail",
    element: <C0201Detail />,
    loader: c0201DetailLoaderAsync,
  },
  {
    path: "/c0201/detail/:id",
    element: <C0201Detail />,
    loader: c0201DetailLoaderAsync,
  },
  {
    path: "/c0202",
    element: <C0202 />,
    loader: c0202LoaderAsync,
  },
  {
    path: "/c0202/detail",
    element: <C0202Detail />,
    loader: c0202DetailLoaderAsync,
  },
  {
    path: "/c0202/detail/:id",
    element: <C0202Detail />,
    loader: c0202DetailLoaderAsync,
  },
  {
    path: "/c0203",
    element: <C0203 />,
    loader: c0203LoaderAsync,
  },
  {
    path: '/c0203/detail/:id',
    element: <C0203Detail />,
    loader: c0203DetailLoaderAsync,
  },
  {
    path: '/c0204',
    element: <C0204 />,
    loader: c0204LoaderAsync,
  },
  {
    path: "/c0204/detail/:id",
    element: <C0204Detail />,
    loader: c0204DetailLoaderAsync,
  },
  {
    path: "/c0204/detail/:id/document/:documentId",
    element: <C0204Document />,
    loader: c0204DocumentLoaderAsync,
  },
  {
    path: "/c0205",
    element: <C0205 />,
    loader: c0205LoaderAsync,
  },
  {
    path: "/c0205/detail/:id",
    element: <C0205Detail />,
    loader: c0205DetailLoaderAsync,
  },
  {
    path: "/c0205/detail/:id/document",
    element: <C0205Document />,
    loader: c0205DetailLoaderAsync,
  },
  {
    path: "/c0206",
    element: <C0206 />,
    loader: c0206LoaderAsync,
  },
  {
    path: "/c0206/detail/:id",
    element: <C0206Detail />,
    loader: c0206DetailLoaderAsync,
  },
  {
    path: "/c0207",
    element: <C0207 />,
    loader: c0207LoaderAsync,
  },
  {
    path: "/c0207/document/:id",
    element: <C0207Document />,
    loader: c0207DetailLoaderAsync,
  },
  {
    path: "/c0301",
    element: <C0301 />,
    loader: c0301LoaderAsync,
  },
  {
    path: '/c0301/detail/:id',
    element: <C0301Detail />,
    loader: c0301DetailLoaderAsync,
  },
  {
    path: '/c0301/detail/:id/document/:docId',
    element: <C0301Document />,
    loader: c0301DocumentLoaderAsync,
  },
  {
    path: '/c0302',
    element: <C0302 />,
    loader: c0302LoaderAsync,
  },
  {
    path: '/c0302/detail/:id',
    element: <C0302Detail />,
    loader: c0302DetailLoaderAsync,
  },
  {
    path: '/c0302/detail/:id/document/:type/:docId',
    element: <C0302Document />,
    loader: c0302DocumentLoaderAsync,
  },
  {
    path: '/c0303',
    element: <C0303 />,
    loader: c0303LoaderAsync,
  },
  {
    path: '/c0303/detail/:id',
    element: <C0303Detail />,
    loader: c0303DetailLoaderAsync,
  },
] as RouteObject[];

const m = [
  {
    path: "/m0101",
    element: <M0101 />,
    loader: m0101LoaderAsync,
  },
  {
    path: "/m0102",
    element: <M0102 />,
  },
  {
    path: "/m0102/detail",
    element: <M0102Detail />,
    loader: m0102DetailLoader,
  },
  {
    path: "/m0102/detail/:id",
    element: <M0102Detail />,
    loader: m0102DetailLoader,
  },
  {
    path: "/m0103",
    element: <M0103 />,
    loader: m0103LoaderAsync,
  },
  {
    path: "/m0103/detail",
    element: <M0103Detail />,
    loader: m0103DetailLoaderAsync,
  },
  {
    path: "/m0103/detail/:id",
    element: <M0103Detail />,
    loader: m0103DetailLoaderAsync,
  },
  {
    path: "/m0104",
    element: <M0104 />,
    loader: m0104LoaderAsync,
  },
  {
    path: "/m0105",
    element: <M0105 />,
    loader: m0105LoaderAsync,
  },
] as RouteObject[];

const other = [
  {
    path: "/demo",
    element: <Demo />,
  },
  {
    path: "/sign-in",
    element: <SignIn />,
  },
  {
    path: "/free-page",
    element: <FreePage />,
  },
  {
    path: "/401",
    element: <Unauthorized />,
  },
] as RouteObject[];

export const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        path: '/',
        element: <PageBase />,
        children: [
          ...d,
          ...s,
          ...c,
          ...m,
        ],
      },
      ...other,
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  }
], { basename: '/web' });