import { LoaderFunctionArgs } from "react-router";
import { SelectionType } from "constant/selection";
import { c0203 as service, selectionService as ddlService } from "services";
import { CtMeetingRecord, CtMeetingRecordCriteria, CtMeetingRecordDetailList, ItemModel, Pagination, UserSelection } from "models";
import { getYearList } from "utils";

export const c0203LoaderAsync = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 1;
  const size = url.searchParams.get('size') || 10;
  const criteriaStringify = url.searchParams.get('criteria') || '{}';
  const criteria = JSON.parse(criteriaStringify) as CtMeetingRecordCriteria;

  const yearsDDL: ItemModel[] = getYearList();
  const [dataResponse, bureauList, committeeList, subCommitteeList, smUser] = await Promise.all([
    service.getListAsync(+size, +page, criteria),
    ddlService.getSelectionAsync(SelectionType.SM_BUREAU),
    ddlService.getSelectionAsync(SelectionType.CT_COMMITTEE),
    ddlService.getSelectionAsync(SelectionType.CT_SUBCOMMITTEE),
    ddlService.getUserSelectionAsync(SelectionType.SM_USER, { isMainGroup: true } as UserSelection),
  ]);

  const dataResult: Pagination<CtMeetingRecord> = dataResponse.data || {} as Pagination<CtMeetingRecord>;
  dataResult.page = +page;
  dataResult.size = +size;

  return {
    listData: dataResult,
    bureauDDL: bureauList.data,
    committeeDDL: committeeList.data,
    subCommitteeDDL: subCommitteeList.data,
    yearDDL: yearsDDL,
    smUser: smUser.data,
  };
};

export const c0203DetailLoaderAsync = async ({ request, params }: LoaderFunctionArgs) => {
  const { id } = params;
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 1;
  const size = url.searchParams.get('size') || 10;

  if (id) {
    const [headerDetail, detailList] = await Promise.all([
      service.getDetailAsync(id),
      service.getDetailListAsync(+size, +page, id),
    ]);

    const dataResult: Pagination<CtMeetingRecordDetailList> = detailList.data || {} as Pagination<CtMeetingRecordDetailList>;
    dataResult.page = +page;
    dataResult.size = +size;

    return {
      headerDetail: headerDetail.data,
      detailListData: dataResult,
    };
  }
};