import { InQueryS1Criteria, InQueryS1DetailCriteria } from 'models';
import http from 'utils/axios';

const getListAsync = async (page: number, size: number, criteria?: InQueryS1Criteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get('api/sninquirymeetingstatus', { params });
};

const getDetailAsync = async (id?: string) =>
  await http.get(`api/snmeetingstatus/${id}`);

const getDetailListAsync = async (page: number, size: number, criteria?: InQueryS1DetailCriteria, id?: string) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get(`api/snmakedocument/meetingId/${id}`, { params });
};

const s0301 = {
  getListAsync,
  getDetailAsync,
  getDetailListAsync,
};

export default s0301;