import http from 'utils/axios';

const getVideoAsync = async (id: string, meetingId?: string) => {
  const params = {
    meetingId,
  };

  return await http.get(`api/ctmeetingrecord/${id}/file`, { params, responseType: 'blob' });
}

const getFullVideoAsync = async (id: string) =>
  await http.get(`api/ctmeetingrecord/${id}/full/file`, { responseType: 'blob' });

const getRecordFileIdAsync = async (id: string, meetingId: string) =>
  await http.get(`api/ctmeetingrecord/${id}/record/fileid`, { params: { meetingId } })

const ctService = {
  getVideoAsync,
  getFullVideoAsync,
  getRecordFileIdAsync,
};

export default ctService;