import { Cookie } from 'constant';
import { useUser } from 'hooks';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import cookie from 'utils/cookie';
import documentSessionServices from 'services/DocumentSessionService';

const apiDocUrl = process.env.REACT_APP_API_URL;
const docsService = process.env.REACT_APP_DOCS_URL;

interface IProps {
  docId: string;
  docName: string;
  onCollaboraLoaded: () => void;
  width?: string;
  height?: string;
  canWrite?: boolean;
}

export interface CollaboraRef {
  clickSave: () => void;
}

export const Collabora = forwardRef<CollaboraRef, IProps>((props, ref) => {
  const { width = '100%', height = '100%' } = props;
  const formRef = useRef<HTMLFormElement>(null);
  const [eventSource, setEventSource] = useState<MessageEventSource | null>(null);
  const [isEditDoc, setIsEditDoc] = useState(false);
  const times = 30;
  const [countdown, setCountdown] = useState(times);
  const { user } = useUser();
  const [sessionToken, setSessionToken] = useState<string | null>(null);
  const sessionTokenRef = useRef<string | null>(null);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const [sessionError, setSessionError] = useState<boolean>(false);

  const onSetDocumentTor = useCallback(async (
    docId: string, canWrite: boolean, sessionToken: string) => {
    if (formRef.current) {
      const documentUrl =
        `${apiDocUrl}wopi/files/${docId}` +
        `?canWrite=${canWrite}` +
        `&userName=${user.fullName}` +
        `&sessionToken=${sessionToken}` +
        `&others=`;

      const chEditorUrl =
        `${docsService}/cool.html?` + 
        `WOPISrc=${encodeURIComponent(documentUrl)}`;

      formRef.current.action = chEditorUrl;
      formRef.current.submit();
    }
  }, [formRef]);

  const post = useCallback((msg: any) => {
    const json = JSON.stringify(msg);

    eventSource?.postMessage(json, {
      targetOrigin: docsService
    });
  }, [eventSource]);

  const save = useCallback(() => {
    post({
      'MessageId': 'Action_Save',
      'Values': { 'Notify': true }
    });
  }, [post]);

  async function receiveMessageAsync(event: MessageEvent<any>) {
    if (!event.data) {
      return;
    }

    const msg = JSON.parse(event.data);
    setEventSource(event.source);

    if (!msg) {
      return;
    }

    switch (msg.MessageId) {
      case 'App_LoadingStatus':
        if (msg.Values && msg.Values.Status === 'Document_Loaded') {
          post({ 'MessageId': 'Host_PostmessageReady' });
          post({ 'MessageId': 'CustomPreventKeyEvent', 'Values': ['119', '120', '121', '122', '123'] });
        }

        break;
      case 'Doc_ModifiedStatus':
        if (msg.Values) {
          setIsEditDoc(msg.Values.Modified);
        }

        break;
    }
  }

  useEffect(() => {
    if (isEditDoc) {
      if (countdown <= 0) {
        setCountdown(times);
        save();

        return;
      }

      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [countdown, save, isEditDoc]);

  window.addEventListener('message', receiveMessageAsync, false);

  useImperativeHandle(ref, () => ({
    clickSave() {
      save();
    }
  }));

  useEffect(() => {
    console.log(`Collabora useEffect ${props.docId} ${sessionToken}`);

    let currentToken: string | null = null;
  
    documentSessionServices.activateSession()
      .then(sessionToken => {
        currentToken = sessionToken;

        if(!currentToken) {
          console.error('Failed to activate session');
          setSessionError(true);
          return;
        }

        setSessionToken(currentToken);

        console.log(`Collabora useEffect ${props.docId} ${currentToken}`);

        if (props.docId && currentToken) {
          onSetDocumentTor(props.docId, props.canWrite ?? false, currentToken);
        }
      })
      .catch(error => {
        console.error('Failed to activate session:', error);
        setSessionError(true);
      });

    return () => {
      if (currentToken) {
        documentSessionServices.deactivateSession(currentToken)
          .catch(error => {
            console.error('Failed to deactivate session:', error);
          });
      }
    };
  }, [onSetDocumentTor, props.docId, props.canWrite]);

  useEffect(() => {
    sessionTokenRef.current = sessionToken;
  }, [sessionToken]);

  useEffect(() => {
    const renew = async () => {
      try {
        const currentToken = sessionTokenRef.current;
        if (!currentToken) return;

        const newSessionToken =
          await documentSessionServices.renewSession(currentToken);

        if (!newSessionToken) {
          throw new Error('Session token is empty');
        }

        setSessionToken(newSessionToken);

        console.log('Session renewed successfully');
      } catch (error) {
        setSessionError(true);
        console.error('Failed to renew session:', error);
      }
    };

    // Initial call
    renew();

    // Set interval to renew every minute (60,000 ms)
    intervalRef.current = setInterval(renew, 60000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  function checkIframeLoaded() {
    const iframe = document.getElementById(props.docId) as HTMLIFrameElement;
    const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;

    if (iframeDoc?.readyState === 'complete') {
      props.onCollaboraLoaded();

      return;
    }

    window.setTimeout(checkIframeLoaded, 100);
  }

  return (
    <>
      {sessionError ? (
        <div style={{ 
          width: width, 
          height: height, 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          backgroundColor: '#f8d7da', 
          color: '#721c24', 
          border: '1px solid #f5c6cb', 
          borderRadius: '4px' 
        }}>
          <p>License activation failed. Please contact support to activate your license.</p>
        </div>
      ) : (
        <>
          <form
            ref={formRef}
            action=''
            target={props.docName}
            encType='multipart/form-data'
            method='post'
            name='collaboraForm'
          >
            <input name='access_token'
              value={cookie.get(Cookie.ACCESS_TOKEN)}
              type='hidden' />
            <input name='ui_defaults'
              value='UIMode=notebookbar;TextSidebar=true'
              type='hidden' />
          </form>
          <iframe
            title={props.docName}
            id={props.docId}
            name={props.docName}
            height={height}
            width={width}
            onLoad={checkIframeLoaded}
          />
        </>
      )}
    </>
  );
});

export default Collabora;
