import { Card, Input, InputTime, Layout, Modal, Selector, Status, StatusType, Table } from "components";
import { Dispatch, createContext, useContext, useEffect, useState, useMemo, FormEvent } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaAngleLeft, FaSave, FaSearch, FaTrashAlt, FaPlus, FaEdit, FaSyncAlt, FaRegFilePdf, FaRegFileWord, FaRegFileExcel, FaUserFriends } from "react-icons/fa";
import { BsPeople } from "react-icons/bs";
import { useLoaderData, useNavigate, useParams, useSubmit } from "react-router-dom";
import { exportFileExcel, exportFileWord, exportPdfByte, formatDateTh, formatTimeToInput, showModalRemoveAsync, submitForm } from "utils";
import { ItemModel, SnMeeting, SnMeetingInspect, SnMeetingRank, SnMeetingRankCriteria, UserSelection } from "models";
import { s0201, selectionService } from "services";
import { Code, HttpStatusCode, SelectionType, StatusChapter, StatusMeeting } from "constant";
import toast from "utils/toast";
import { FiUsers } from "react-icons/fi";

interface Context {
  inspectData: SnMeetingInspect[];
  setInspectData: Dispatch<React.SetStateAction<SnMeetingInspect[]>>;
  rankData: SnMeetingRank[];
  setRankData: Dispatch<React.SetStateAction<SnMeetingRank[]>>;
  criteriaRank: SnMeetingRankCriteria;
  setCriteriaRank: Dispatch<React.SetStateAction<SnMeetingRankCriteria>>;
  onSearchRankData: Function;
  getInspectAsync: Function;
  smBureauGroupFilterItems: ItemModel[];
}

interface InspectModalProps {
  showModal: boolean;
  onHide: () => void;
  index?: number;
}

interface RankModalProps {
  showModal: boolean;
  onHide: () => void;
  id?: string;
}

type Loader = {
  snMeetingDetailData: SnMeeting,
  snMeetingPeriodItems: ItemModel[],
  snMeetingInspectData: SnMeetingInspect[],
  smBureauGroupItems: ItemModel[],
  smUserItems: ItemModel[],
  positionTypeItems: ItemModel[],
  snMeetingRankData: SnMeetingRank[],
  smBureauItems: ItemModel[],
  snOfficerRank: ItemModel[],
  cuttingTime: number,
};

enum ExportType {
  Excel = 'Excel',
  Word = 'Word',
  Pdf = 'Pdf',
}

const Context = createContext({} as Context);

export default function S0201Queue() {
  const { snMeetingInspectData, snMeetingRankData, smBureauGroupItems, smBureauItems } = useLoaderData() as Loader;
  const [inspectData, setInspectData] = useState<SnMeetingInspect[]>([]);
  const [rankData, setRankData] = useState<SnMeetingRank[]>([]);
  const [criteriaRank, setCriteriaRank] = useState<SnMeetingRankCriteria>({} as SnMeetingRankCriteria);
  const [smBureauGroupFilterItems, setSmBureauGroupFilterItems] = useState<ItemModel[]>([]);
  const { id } = useParams();

  useEffect(() => {
    if (smBureauItems) {
      setSmBureauGroupFilterItems(smBureauGroupItems.filter(f => f.id === smBureauItems[0].value).slice(2));
    }
  }, [smBureauItems]);

  useEffect(() => {
    if (snMeetingInspectData) {
      setInspectData(snMeetingInspectData);
    }

    if (snMeetingRankData) {
      setRankData(snMeetingRankData);
    }
  }, [snMeetingInspectData, snMeetingRankData]);

  const onSearchRankData = async (criteriaData?: SnMeetingRankCriteria) => {
    if (id) {
      const { data, status } = await s0201.getSnMeetingRankAsync(id, criteriaData ? criteriaData : criteriaRank);

      if (status === HttpStatusCode.OK) {
        setRankData(data);
      }
    }
  };

  const getInspectAsync = async () => {
    if (id) {
      const { data, status } = await s0201.getSnMeetingInspectAsync(id);

      if (status === HttpStatusCode.OK) {
        setInspectData(data);
      }
    }
  };

  const conTextValue = useMemo(() => {
    return {
      inspectData,
      setInspectData,
      rankData,
      setRankData,
      criteriaRank,
      setCriteriaRank,
      onSearchRankData,
      getInspectAsync,
      smBureauGroupFilterItems,
    }
  }, [inspectData, setInspectData, rankData, setRankData, criteriaRank, setCriteriaRank, onSearchRankData, getInspectAsync, smBureauGroupFilterItems]);

  return (
    <Context.Provider value={conTextValue}>
      <Layout title="กำหนดข้อมูลการประชุม-จัดลำดับคิว">
        <HeaderButton />
        <MeetingDetail />
        <InspectorDataTable />
        <RankDataTable />
      </Layout>
    </Context.Provider>
  );
}

function HeaderButton() {
  const navigate = useNavigate();

  return (
    <div className="my-3 d-flex justify-content-between">
      <Button
        variant="outline-primary"
        type="button"
        onClick={() => navigate(-1)}>
        <FaAngleLeft className="me-2" />ย้อนกลับ
      </Button>
    </div>
  );
}

function MeetingDetail() {
  const { snMeetingDetailData, snMeetingPeriodItems } = useLoaderData() as Loader;

  const getMeetingPeriod = (value: string) => {
    const data = snMeetingPeriodItems.find(s => s.value === value);

    if (data) {
      return data.label;
    }

    return "";
  };

  return (
    <Card>
      <label><b>ข้อมูลการประชุม</b></label>
      <Row className="mt-3">
        <Col sm={6} md={4} xxl={3}>
          <p>ชื่อการประชุม<span className="mx-2">:</span>{snMeetingDetailData.name}</p>
        </Col>
        <Col sm={6} md={4} xxl={3}>
          <p>ครั้งที่<span className="mx-2">:</span>{snMeetingDetailData.time}</p>
        </Col>
        <Col sm={6} md={4} xxl={3}>
          <p>สมัยที่ประชุม<span className="mx-2">:</span>{getMeetingPeriod(snMeetingDetailData.snMeetingPeriodId)}</p>
        </Col>
        <Col sm={6} md={4} xxl={3}>
          <p>ปี<span className="mx-2">:</span>{snMeetingDetailData.year + 543}</p>
        </Col>
        <Col sm={6} md={4} xxl={3}>
          <p>วันที่ประชุม<span className="mx-2">:</span>{formatDateTh(snMeetingDetailData.startDate)}</p>
        </Col>
        <Col sm={6} md={4} xxl={3}>
          <p>เวลา<span className="mx-2">:</span>{formatTimeToInput(snMeetingDetailData.startTime)}-{formatTimeToInput(snMeetingDetailData.endTime)}</p>
        </Col>
      </Row>
    </Card>
  );
}

function InspectorDataTable() {
  const { positionTypeItems } = useLoaderData() as Loader;
  const { inspectData, getInspectAsync } = useContext(Context);
  const [showModal, setShowModal] = useState(false);
  const [index, setIndex] = useState<number>();

  const onShowModalEdit = (i: number) => {
    setIndex(i);
    setShowModal(true);
  };

  const onHideModal = () => {
    setShowModal(false);
    setIndex(undefined);
  };

  const handlerRemoveAsync = async (id: string) => {
    if (await showModalRemoveAsync() && inspectData) {
      const { status } = await s0201.deleteSnMeetingInspectAsync(id);

      if (status === HttpStatusCode.NO_CONTENT) {
        toast.success("ลบข้อมูลสำเร็จ");

        getInspectAsync();
      }
    }
  };

  const getPosition = (value: string) => {
    const positionName = positionTypeItems.find(p => p.value === value);

    if (positionName) {
      return positionName.label;
    }

    return "";
  };

  return (
    <>
      <div className="my-3 d-flex justify-content-between align-items-end">
        <Form.Label>ตารางรายชื่อผู้ตรวจทานรายงานการประชุม</Form.Label>
        <div>
          <Button
            variant="primary"
            type="button"
            onClick={() => setShowModal(true)}>
            <FaPlus className="me-2" />เพิ่มผู้ตรวจ
          </Button>
        </div>
      </div>
      <Table hidePagination>
        <Table.Header>
          <Table.Row>
            <Table.Column minWidth={100}>ลำดับ</Table.Column>
            <Table.Column minWidth={150}>กลุ่มงานชวเลข</Table.Column>
            <Table.Column minWidth={200}>รายชื่อเจ้าหน้าที่ผู้ตรวจทานรายงานการประชุม</Table.Column>
            <Table.Column minWidth={100}>หน้าที่</Table.Column>
            <Table.Column minWidth={100} />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {inspectData.map((data, index) => (
            <Table.Row key={data.userId}>
              <Table.Cell center>{(index + 1).toString()}</Table.Cell>
              <Table.Cell center>{data.bureauGroupName}</Table.Cell>
              <Table.Cell>{data.name}</Table.Cell>
              <Table.Cell center>{getPosition(data.inspectTypeName ?? "")}</Table.Cell>
              <Table.Cell center className="fs-5">
                <FaEdit className='me-2' onClick={() => onShowModalEdit(index)} />
                <FaTrashAlt onClick={() => handlerRemoveAsync(data.id ?? "")} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <InspectModal
        showModal={showModal}
        onHide={onHideModal}
        index={index}
      />
    </>
  );
}

function InspectModal(props: InspectModalProps) {
  const { inspectData, getInspectAsync, smBureauGroupFilterItems } = useContext(Context);
  const { smUserItems, positionTypeItems } = useLoaderData() as Loader;
  const [inspectSingleData, setInspectSingleData] = useState<SnMeetingInspect>({} as SnMeetingInspect);
  const [groupItems, setGroupItems] = useState<ItemModel[]>([]);
  const { id } = useParams();

  useEffect(() => {
    if (props.showModal && props.index !== undefined) {
      setInitailData(props.index);
    }
  }, [props.showModal, props.index]);

  const setInitailData = (i: number) => {
    const filterData = inspectData[i];

    if (filterData.bureauGroupId) {
      getUserListAsync(filterData.bureauGroupId);
    }

    setInspectSingleData(filterData);
  };

  const onSave = () => {
    submitForm();

    if (!inspectSingleData.bureauGroupId || !inspectSingleData.userId || !inspectSingleData.inspectTypeName) {
      return;
    }

    if (inspectData.some(i => i.userId === inspectSingleData.userId)) {
      toast.warn('เลือกผู้ตรวจซ้ำ');

      return;
    }

    const inspectList = inspectData ? [...inspectData] : [];

    if (props.index === undefined) {
      createInspectDataAsync();
    } else {
      updateInspectDataAsync(inspectList[props.index].id ?? "");
    }

    onHideModal();
  };

  const createInspectDataAsync = async () => {
    if (id) {
      const { status } = await s0201.createSnMeetingInspectAsync(id, inspectSingleData);

      if (status === HttpStatusCode.CREATED) {
        toast.success("บันทึกข้อมูลสำเร็จ");

        getInspectAsync();
      }
    }
  };

  const updateInspectDataAsync = async (id: string) => {
    if (id) {
      const { status } = await s0201.updateSnMeetingInspectAsync(id, inspectSingleData);

      if (status === HttpStatusCode.ACCEPTED) {
        toast.success("แก้ไขข้อมูลสำเร็จ");

        getInspectAsync();
      }
    }
  };

  const onChangebureauGroup = (value: string | number) => {
    const dataList = smBureauGroupFilterItems.find(s => s.value === value);

    getUserListAsync(value.toString());

    if (dataList) {
      setInspectSingleData({ ...inspectSingleData, bureauGroupId: dataList.value.toString(), bureauGroupName: dataList.label })
    }
  };

  const onChangeUser = (value: string | number) => {
    const dataList = smUserItems.find(s => s.value === value);

    if (dataList) {
      setInspectSingleData({ ...inspectSingleData, userId: dataList.value.toString(), name: dataList.label })
    }
  };

  const onHideModal = () => {
    setInspectSingleData({} as SnMeetingInspect);
    setGroupItems([]);

    props.onHide();
  };

  const getUserListAsync = async (group: string) => {
    const params: UserSelection = {
      bureauGroupId: group,
      isNotContract: true,
    };

    const { data, status } = await selectionService.getUserSelectionAsync(SelectionType.SM_USER, params);

    if (status === HttpStatusCode.OK) {
      const items: ItemModel[] = data;

      setGroupItems(items);
    }
  };

  return (
    <Modal show={props.showModal}>
      <div>
        <BsPeople className="me-2" />ผู้ตรวจทานรายงานการประชุม
      </div>
      <div>
        <Selector
          label="กลุ่มงาน"
          rule={{ required: true }}
          items={smBureauGroupFilterItems}
          value={inspectSingleData.bureauGroupId}
          onChange={(value) => onChangebureauGroup(value)} />
        <Selector
          label="เจ้าหน้าที่รับผิดชอบ"
          rule={{ required: true }}
          items={groupItems}
          value={inspectSingleData.userId}
          onChange={(value) => onChangeUser(value)} />
        <Selector
          label="หน้าที่"
          rule={{ required: true }}
          items={positionTypeItems}
          value={inspectSingleData.inspectTypeName}
          onChange={(value) => setInspectSingleData({ ...inspectSingleData, inspectTypeName: value.toString(), inspectType: value.toString() })} />
      </div>
      <div className="d-flex gap-3 mt-4">
        <Button
          className="w-100"
          variant="outline-primary"
          onClick={onHideModal}>
          ยกเลิก
        </Button>
        <Button
          className="w-100"
          onClick={onSave}>
          บันทึก
        </Button>
      </div>
    </Modal>
  );
}

function RankDataTable() {
  const { smBureauGroupItems, snMeetingDetailData, smBureauItems } = useLoaderData() as Loader;
  const { criteriaRank, setCriteriaRank, rankData, setRankData, onSearchRankData } = useContext(Context);
  const [showModal, setShowModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [manageChapterDisabled, setManageChapterDisabled] = useState<boolean>(false);
  const [idRankList, setIdRankList] = useState("");
  const [showNewQueueModal, setShowNewQueueModal] = useState(false);
  const [smBureauGroupFilterItems, setSmBureauGroupFilterItems] = useState<ItemModel[]>([]);
  const { id } = useParams();
  const [showAddChapterModal, setShowAddChapterModal] = useState<boolean>(false);

  useEffect(() => {
    setSmBureauGroupFilterItems(smBureauGroupItems.filter(f => f.id === smBureauItems[0].value).slice(2));
    if (snMeetingDetailData.status === StatusMeeting.RECORDED) {
      setDisabled(true);
    }
    let checkStatus = onCheckStatus(snMeetingDetailData.status);
    if (checkStatus) {
      setManageChapterDisabled(checkStatus);
    }
  }, [snMeetingDetailData]);

  const onChangeCriteria = (value: string | number, prop: string) => {
    setCriteriaRank({ ...criteriaRank, [prop]: value });
  };

  const onClearCriteria = () => {
    const criteriaData = {} as SnMeetingRankCriteria;

    onSearchRankData(criteriaData);

    setCriteriaRank(criteriaData);
    setDisabled(false);
    setManageChapterDisabled(false);
  };

  const onSearch = () => {
    if (criteriaRank.name || criteriaRank.bureauGroupId || criteriaRank.chapter || criteriaRank.counterPart) {
      setDisabled(true);
      setManageChapterDisabled(true);
    } else {
      setDisabled(false);
      setManageChapterDisabled(false);
    }

    onSearchRankData(criteriaRank);
  };

  const onShowModal = (id?: string) => {
    if (id) {
      setIdRankList(id);
      setShowModal(true);
    }
  };

  const onUpdateAsync = async () => {
    if (id) {
      const { status } = await s0201.updateSnMeetingRankUpdateRankAsync(id, rankData);

      if (status === HttpStatusCode.ACCEPTED) {
        toast.success("บันทึกข้อมูลสำเร็จ");

        onSearchRankData();
      }
    }
  };

  const onChangeStartTimeEndTime = (type: string, index: number, value?: Date) => {
    switch (type) {
      case "startTime":
        rankData[index].startTime = value;
        break;
      case "endTime":
        rankData[index].endTime = value;
        break;
    }

    setRankData(rankData);
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSearch();
  };

  const deleteSnMeetingRankAsync = async (chapterId: string) => {
    if (!await showModalRemoveAsync()) {
      return;
    }

    const { status } = await s0201.deleteSnMeetingRankAsync(chapterId, id!);

    if (status === HttpStatusCode.NO_CONTENT) {
      toast.success("ลบข้อมูลสำเร็จ");

      onSearchRankData();
    }
  };

  const hiddenAutoQueue = useMemo(() => {
    return (rankData?.some(r => r.status !== StatusChapter.ASSIGN_DUTY_OFFICER));
  }, [rankData]);

  const exportAsync = async (exportType: ExportType) => {
    const { data, status } = await s0201.exportAsync(
      id!,
      exportType ? exportType.toString() : '');

    if (status === HttpStatusCode.OK) {
      if (exportType == ExportType.Excel) {
        exportFileExcel(data);
      }

      if (exportType == ExportType.Pdf) {
        exportPdfByte(data);
      }

      if (exportType == ExportType.Word) {
        exportFileWord(data);
      }
    }
  };

  const onCheckStatus = (status: string) => {
    console.log(status);
    if (status === StatusMeeting.DRAFT ||
      status === StatusMeeting.CONFIRM ||
      status === StatusMeeting.RECORD ||
      status === StatusMeeting.PAUSE_RECORD ||
      status === StatusMeeting.RECORDED ||
      status === StatusMeeting.DOCUMENT_MAKING) {
      return false;
    }
    return true;
  };

  return (
    <>
      <Card className="mt-3">
        <Form onSubmit={onSubmit}>
          <Row>
            <Col sm={6} lg={4} xl={3}>
              <Selector
                label="กลุ่มงานชวเลขที่"
                items={smBureauGroupFilterItems}
                value={criteriaRank.bureauGroupId}
                onChange={(value) => onChangeCriteria(value, 'bureauGroupId')} />
            </Col>
            <Col sm={6} lg={4} xl={3}>
              <Input
                label="เจ้าหน้าที่ผู้พิมพ์"
                value={criteriaRank.name}
                onChange={(value) => onChangeCriteria(value, 'name')} />
            </Col>
            <Col sm={6} lg={4} xl={3}>
              <Input
                type="number"
                label="ตอน"
                value={criteriaRank.chapter}
                onChange={(value) => onChangeCriteria(value, 'chapter')} />
            </Col>
            <Col sm={12} className='d-flex gap-2 justify-content-start'>
              <Button
                variant="primary"
                type="submit">
                <FaSearch className="me-2" />ค้นหา
              </Button>
              <Button
                variant="outline-primary"
                type="button"
                onClick={onClearCriteria}>
                <FaTrashAlt className="me-2" />ล้างค่า
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <div className="d-flex justify-content-between">
        <div className="mt-4">
          <Form.Label>ตารางรายชื่อผู้จัดทำรายงานการประชุม</Form.Label>
        </div>
        <div className="my-3 d-flex gap-2">
          <Button
            variant="outline-primary"
            type="button"
            onClick={() => exportAsync(ExportType.Excel)}>
            <FaRegFileExcel className="me-2" />Export Excel
          </Button>
          <Button
            variant="outline-primary"
            type="button"
            onClick={() => exportAsync(ExportType.Word)}>
            <FaRegFileWord className="me-2" />Export Word
          </Button>
          <Button
            variant="outline-primary"
            type="button"
            onClick={() => exportAsync(ExportType.Pdf)}>
            <FaRegFilePdf className="me-2" />Export PDF
          </Button>
          <Button
            variant="outline-primary"
            onClick={() => setShowNewQueueModal(true)}
            hidden={hiddenAutoQueue}>
            <FaSyncAlt className="me-2" />จัดคิวจัดทำรายงาน
          </Button>
          <Button
            variant="primary"
            type="button"
            disabled={manageChapterDisabled}
            onClick={() => setShowAddChapterModal(true)}>
            <FaPlus className="me-2" />เพิ่มตอน
          </Button>
          <Button
            variant="primary"
            type="button"
            disabled={disabled}
            onClick={onUpdateAsync}>
            <FaSave className="me-2" />บันทึก
          </Button>
        </div>
      </div>
      <div className="table-relative-fix">
        <div className="table-scroll">
          <Table hidePagination>
            <Table.Header>
              <Table.Row>
                <Table.Column minWidth={150}>กลุ่มงานชวเลข</Table.Column>
                <Table.Column minWidth={250}>รายชื่อผู้จัดทำรายงานการประชุม</Table.Column>
                <Table.Column minWidth={100}>ตอน</Table.Column>
                <Table.Column minWidth={150}>เวลาเริ่มต้น</Table.Column>
                <Table.Column minWidth={150}>เวลาสิ้นสุด</Table.Column>
                <Table.Column minWidth={100}>สถานะตอน</Table.Column>
                <Table.Column minWidth={260} className="fix-col" />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {rankData?.map((data, index) => (
                <Table.Row key={data.chapter}>
                  <Table.Cell center>{data.bureauGroupName}</Table.Cell>
                  <Table.Cell>{data.name}</Table.Cell>
                  <Table.Cell center>{data.chapter?.toString()}</Table.Cell>
                  <Table.Cell>
                    <InputTime
                      disabled={(data.status === StatusChapter.DOCUMENT_COMPLETE ||
                        data.status === StatusChapter.REVIEWED_DOCUMENT ||
                        data.status === StatusChapter.SEND_EDIT_DOCUMENT ||
                        data.status === StatusChapter.COLLECT ||
                        data.status === StatusChapter.GUARANTEE)}
                      value={data.startTime}
                      onChange={(value) => onChangeStartTimeEndTime("startTime", index, value)} />
                  </Table.Cell>
                  <Table.Cell>
                    <InputTime
                      disabled={(data.status === StatusChapter.DOCUMENT_COMPLETE ||
                        data.status === StatusChapter.REVIEWED_DOCUMENT ||
                        data.status === StatusChapter.SEND_EDIT_DOCUMENT ||
                        data.status === StatusChapter.COLLECT ||
                        data.status === StatusChapter.GUARANTEE)}
                      value={data.endTime}
                      onChange={(value) => onChangeStartTimeEndTime("endTime", index, value)} />
                  </Table.Cell>
                  <Table.Cell center>
                    <Status type={StatusType.CHAPTER_STATUS} value={data.status} />
                  </Table.Cell>
                  <Table.Cell className="fix-col">
                    <div className="d-flex align-items-center gap-3">
                      <Button
                        disabled={(data.status === StatusChapter.DOCUMENT_COMPLETE ||
                          data.status === StatusChapter.REVIEWED_DOCUMENT ||
                          data.status === StatusChapter.SEND_EDIT_DOCUMENT ||
                          data.status === StatusChapter.COLLECT ||
                          data.status === StatusChapter.GUARANTEE)}
                        variant="outline-primary"
                        size="sm"
                        onClick={() => onShowModal(data.id)}>
                        <BsPeople className="me-2" />ผู้จัดทำรายงาน
                      </Button>
                      <div className="mx-auto">
                        {(data.status === StatusChapter.ASSIGN_DUTY_OFFICER || data.status === StatusChapter.RECORDED) ?
                          <FaTrashAlt
                            className="text-primary fs-5"
                            onClick={() => deleteSnMeetingRankAsync(data.id ?? "")} />
                          : <div></div>}
                      </div>
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </div>
      <RankModal showModal={showModal} onHide={() => setShowModal(false)} id={idRankList} />
      <NewQueueModal showModal={showNewQueueModal} onHide={() => setShowNewQueueModal(false)} />
      <ChapterModal
        show={showAddChapterModal}
        onHide={() => setShowAddChapterModal(!showAddChapterModal)} />
    </>
  );
}

function NewQueueModal(props: {
  showModal: boolean;
  onHide: () => void;
}) {
  const { snOfficerRank } = useLoaderData() as Loader;
  const [userSelectedId, setUserSelectedId] = useState<string>();
  const { onSearchRankData } = useContext(Context);
  const { id } = useParams();

  const onHideModal = () => {
    setUserSelectedId(undefined);

    props.onHide();
  };

  const onSaveAsync = async () => {
    submitForm();

    if (!userSelectedId || !id) {
      return;
    }

    const { status } = await s0201.updateNewQueueAsync(id, userSelectedId);

    if (status === HttpStatusCode.ACCEPTED) {
      toast.success("บันทึกข้อมูลสำเร็จ");

      onHideModal();
      await onSearchRankData();
    }
  };

  return (
    <Modal show={props.showModal}>
      <div>
        <FiUsers className="me-2" />จัดคิวจัดทำรายงาน
      </div>
      <div className="my-3">
        <Selector
          label="เริ่มจาก"
          items={snOfficerRank}
          rule={{ required: true }}
          onChange={(value) => setUserSelectedId(value.toString())} />
      </div>
      <div className="d-flex gap-3">
        <Button
          variant="outline-primary"
          className="w-100"
          onClick={onHideModal}>
          ยกเลิก
        </Button>
        <Button
          className="w-100"
          onClick={onSaveAsync}>
          บันทึก
        </Button>
      </div>
    </Modal>
  );
}

function RankModal(props: RankModalProps) {
  const { onSearchRankData, rankData, smBureauGroupFilterItems } = useContext(Context);
  const [rankSingleData, setRankSingleData] = useState<SnMeetingRank>({} as SnMeetingRank);
  const [groupItems, setGroupItems] = useState<ItemModel[]>([]);

  useEffect(() => {
    if (props.id && props.showModal) {
      const findData = rankData.find(r => r.id === props.id);

      if (findData) {
        setRankSingleData(findData);
        getUserListAsync(findData.bureauGroupId ?? "");
      }
    }
  }, [props.id, props.showModal]);

  const onHideModal = () => {
    setRankSingleData({} as SnMeetingRank);

    props.onHide();
  };

  const updateRankAsync = async () => {
    submitForm();

    if (!rankSingleData.bureauGroupId || !rankSingleData.userId) {
      return;
    }

    if (props.id) {
      const { status } = await s0201.updateSnMeetingRankAsync(props.id, rankSingleData);

      if (status === HttpStatusCode.ACCEPTED) {
        toast.success("บันทึกข้อมูลสำเร็จ");

        props.onHide();
        onSearchRankData();
      }
    }
  };

  const getUserListAsync = async (group: string) => {
    const params: UserSelection = {
      bureauGroupId: group,
      isNotContract: true,
    };

    const { data, status } = await selectionService.getUserSelectionAsync(SelectionType.SM_USER, params);

    if (status === HttpStatusCode.OK) {
      const items: ItemModel[] = data;

      setGroupItems(items);
    }
  };

  const onChangeGroup = (value: string | number) => {
    setRankSingleData({ ...rankSingleData, bureauGroupId: value.toString(), userId: "" });

    getUserListAsync(value.toString());
  };

  return (
    <Modal show={props.showModal}>
      <div>
        <BsPeople className="me-2" />เปลี่ยนผู้รับผิดชอบ/เพิ่มผู้จดรายงานการประชุม
      </div>
      <Form>
        <Row>
          <Col>
            <Selector
              label="กลุ่มงาน"
              rule={{ required: true }}
              items={smBureauGroupFilterItems}
              value={rankSingleData.bureauGroupId}
              onChange={(value) => onChangeGroup(value)} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Selector
              label="เจ้าหน้าที่รับผิดชอบ"
              rule={{ required: true }}
              items={groupItems}
              value={rankSingleData.userId}
              onChange={(value) => setRankSingleData({ ...rankSingleData, userId: value.toString() })} />
          </Col>
        </Row>
        <div className="d-flex justify-content-end gap-2 mt-3">
          <Button
            variant="outline-primary"
            type="button"
            className="w-100"
            onClick={onHideModal}>
            ยกเลิก
          </Button>
          <Button
            variant="primary"
            type="button"
            className="w-100"
            onClick={updateRankAsync}>
            บันทึก
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

const ChapterModal = (props: { show: boolean; onHide: () => void; }) => {
  const { rankData, criteriaRank } = useContext(Context);
  const { smBureauItems, smBureauGroupItems, cuttingTime } = useLoaderData() as Loader;
  const [meetingChapterData, setMeetingChapterData] = useState<SnMeetingRank>({} as SnMeetingRank);
  const [userList, setUserList] = useState<ItemModel[]>([]);
  const [bureauId, setBureauId] = useState("");
  const [bureauGroupItem, setBureauGroupItem] = useState<ItemModel[]>([]);
  const { id } = useParams();
  const submit = useSubmit();

  useEffect(() => {
    if (props.show) {
      setDefaultCounterPathAndChapter();

      const bureauCode = smBureauItems.filter(f => f.label.includes(Code.BUREAU_CODE));
      const bureauGroupFilterData = [...smBureauGroupItems.filter(f => f.id == bureauCode[0].value).slice(2)];

      setBureauId((bureauCode[0].value) as string);
      setBureauGroupItem(bureauGroupFilterData);
    }
  }, [props.show]);

  const onChangeCreateData = (prop: keyof SnMeetingRank, value?: string | number | Date) => {
    setMeetingChapterData({ ...meetingChapterData, [prop]: value });
  };

  const onGetUserListAsync = async (bureauId: string, bureauGroupId: string) => {
    setMeetingChapterData({ ...meetingChapterData, userId: undefined, bureauGroupId: bureauGroupId });

    if (bureauId && bureauGroupId) {
      const criteria: UserSelection = {
        bureauId,
        bureauGroupId,
      };

      const { data, status } = await selectionService.getUserSelectionAsync(SelectionType.SM_USER, criteria);

      if (status === HttpStatusCode.OK) {
        setUserList(data);
      }
    }
  };

  const onHideModal = () => {
    props.onHide();
    setMeetingChapterData({} as SnMeetingRank);
  };

  const setDefaultCounterPathAndChapter = () => {
    if (rankData && rankData.length > 0) {
      const meetingChapterList = rankData;
      const lastData = meetingChapterList[meetingChapterList.length - 1];

      const lastStartTime = new Date(rankData[rankData.length - 1].endTime!);
      const lastEndTime = new Date(rankData[rankData.length - 1].endTime!);

      lastStartTime.setHours(lastStartTime.getHours(), lastStartTime.getMinutes());
      lastEndTime.setHours(lastEndTime.getHours(), lastEndTime.getMinutes() + cuttingTime);

      setMeetingChapterData({
        ...meetingChapterData,
        chapter: lastData.chapter! + 1,
        startTime: lastStartTime,
        endTime: lastEndTime,
      });

      return;
    }

    setMeetingChapterData({ ...meetingChapterData, chapter: 1 });
  };

  const onSubmitAddChapterAsync = async () => {
    submitForm();
    if (!meetingChapterData.bureauGroupId ||
      !meetingChapterData.userId ||
      !meetingChapterData.startTime ||
      !meetingChapterData.endTime) {
      return toast.warn("กรุณากรอกข้อมูลให้ครบถ้วน");
    }

    if (id) {
      const { status } = await s0201.createMeetingChapterAsync(id, meetingChapterData);

      if (status === HttpStatusCode.CREATED) {
        toast.success("เพิ่มตอนสำเร็จ");

        onHideModal();
        submit({
          criteria: JSON.stringify(criteriaRank),
        });
      }
    }
  }

  return (
    <Modal show={props.show}>
      <h5>
        <FaUserFriends className="me-2" />เพิ่มตอน
      </h5>
      <div>
        <Input
          label="ตอนที่"
          disabled
          value={meetingChapterData.chapter} />
        <Selector
          label="กลุ่มงาน"
          isHideClearButton
          rule={{ required: true }}
          value={meetingChapterData.bureauGroupId}
          items={bureauGroupItem}
          onChange={(value) => onGetUserListAsync(bureauId, value as string)} />
        <Selector
          label="ชื่อผู้จัดทำรายงานการประชุม"
          rule={{ required: true }}
          items={userList}
          value={meetingChapterData.userId}
          onChange={(value) => onChangeCreateData("userId", value)} />
        <InputTime
          label="เวลาเริ่ม"
          rule={{ required: true }}
          value={meetingChapterData.startTime}
          onChange={(value) => onChangeCreateData("startTime", value)} />
        <InputTime
          label="เวลาสิ้นสุด"
          rule={{ required: true }}
          value={meetingChapterData.endTime}
          onChange={(value) => onChangeCreateData("endTime", value)} />
      </div>
      <div className="d-flex justify-content-between gap-2">
        <Button
          className="w-100"
          variant="outline-primary"
          onClick={onHideModal}>
          ยกเลิก
        </Button>
        <Button
          className="w-100"
          onClick={onSubmitAddChapterAsync}>
          บันทึก
        </Button>
      </div>
    </Modal>
  )
}