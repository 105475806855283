import { Input, InputDate, InputTime, Layout, Modal, Selector, Table, TextArea } from "components";
import { HttpStatusCode, StatusMeeting } from "constant";
import { ItemModel, SnMeeting, SnMeetingAgendas } from "models";
import { FormEvent, useState, useContext, createContext, Dispatch, useEffect, useMemo } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaAngleLeft, FaCheckCircle, FaEdit, FaPlus, FaRedo, FaRegCheckCircle, FaRegTimesCircle, FaSave, FaTrashAlt } from "react-icons/fa";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { s0201 } from "services";
import { isNullOrEmptyTime, showModalConfirmAsync, showModalRemoveAsync, submitForm } from "utils";
import toast from "utils/toast";

type Loader = {
  snMeetingTypeItems: ItemModel[],
  snMeetingPeriodItems: ItemModel[],
  snMeetingRoomItems: ItemModel[],
  snAgendaTypeItems: ItemModel[],
  snMemberItems: ItemModel[],
  attendeeTypeItems: ItemModel[],
  snMeetingDetailData: SnMeeting,
};

interface Context {
  snMeetingData: SnMeeting;
  setSnMeetingData: Dispatch<React.SetStateAction<SnMeeting>>;
  getSnMeetingDetailAsync: Function;
  disabled: boolean;
}

const Context = createContext({} as Context);

export default function S0201Detail() {
  const { snMeetingDetailData } = useLoaderData() as Loader;
  const [snMeetingData, setSnMeetingData] = useState<SnMeeting>({} as SnMeeting);
  const [disabled, setDisabled] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    if (snMeetingDetailData) {
      setSnMeetingData(snMeetingDetailData);
      setDisabledInputField(snMeetingDetailData);
    }

  }, [snMeetingDetailData]);

  const setDisabledInputField = (data: SnMeeting) => {
    if (data.status !== StatusMeeting.DRAFT) {
      setDisabled(true);
    }
  };

  const getSnMeetingDetailAsync = async () => {
    if (id) {
      const { data, status } = await s0201.getSnMeetingDetailAsync(id);

      if (status === HttpStatusCode.OK) {
        setSnMeetingData(data);
        setDisabledInputField(data);
      }
    }
  };

  const contextValue = useMemo(() => {
    return {
      snMeetingData,
      setSnMeetingData,
      getSnMeetingDetailAsync,
      disabled,
    }
  }, [snMeetingData, setSnMeetingData, getSnMeetingDetailAsync, disabled]);

  return (
    <Context.Provider value={contextValue}>
      <Layout title="กำหนดข้อมูลการประชุม">
        <HeaderButton />
        <DataForm />
        <AgendaTable />
      </Layout>
    </Context.Provider>
  );
}

function HeaderButton() {
  const { snMeetingData, getSnMeetingDetailAsync } = useContext(Context);
  const { id } = useParams();
  const navigate = useNavigate();

  const onCheckTimeValue = (autoQueue?: boolean) => {
    const isValidMorning = snMeetingData.startTime && snMeetingData.endTime;

    if (autoQueue && !isValidMorning) {
      toast.warn("กรุณาระบุเวลาก่อนทำการจัดผู้จัดทำรายงาน");

      return false;
    }

    if (isValidMorning && (new Date(snMeetingData.startTime) >= new Date(snMeetingData.endTime))) {
      toast.warn("เวลาสิ้นสุดประชุม ต้องมากกว่า เวลาเริ่มประชุม");

      return false;
    }


    if ((snMeetingData.startTime && !snMeetingData.endTime) || (!snMeetingData.startTime && snMeetingData.endTime)) {
      toast.warn("กรุณาระบุ เวลาเริ่มประชุม และ เวลาสิ้นสุดประชุม ให้ครบถ้วน");

      return false;
    }

    return true;
  };

  const onSave = (status: StatusMeeting, saveDraft?: boolean) => {
    submitForm();

    if (!snMeetingData.snMeetingTypeId ||
      !snMeetingData.time ||
      !snMeetingData.year ||
      !snMeetingData.snMeetingPeriodId ||
      !snMeetingData.name ||
      !snMeetingData.smMeetingRoomId ||
      !snMeetingData.startDate) {
      return toast.warn('กรุณากรอกข้อมูลให้ครบถ้วน');
    }

    if (!onCheckTimeValue()) {
      return;
    }

    if (!saveDraft) {
      if (!onCheckTimeValue(true)) {
        return;
      }
    }

    if ((!snMeetingData.snMeetingAgendas || snMeetingData.snMeetingAgendas.length === 0) && !saveDraft) {
      return toast.warn('กรุณาเพิ่มข้อมูลตารางวาระการประชุม');
    }

    if (!id) {
      saveSnMeetingAsync(status);
    } else {
      updateSnMeetingAsync(id, status);
    }
  };

  const saveSnMeetingAsync = async (statusType: StatusMeeting) => {
    const body = snMeetingData;
    body.status = statusType;

    const { data, status } = await s0201.createSnMeetingAsync(body);

    if (status === HttpStatusCode.CREATED) {
      if (statusType === StatusMeeting.CONFIRM) {
        toast.success('ยืนยันสำเร็จ');
      } else if (statusType === StatusMeeting.CANCEL) {
        toast.success('ยกเลิกการประชุมสำเร็จ');
      } else {
        toast.success('บันทึกข้อมูลสำเร็จ');
      }

      navigate(`/s0201/detail/${data}`);

      await getSnMeetingDetailAsync();
    }
  };

  const updateSnMeetingAsync = async (id: string, statusType: StatusMeeting) => {
    if (statusType === StatusMeeting.CANCEL && !await showModalConfirmAsync('ยืนยันการยกเลิกการประชุม')) {
      return;
    }

    if (statusType === StatusMeeting.CONFIRM && !await showModalConfirmAsync('ท่านต้องการยืนยันการประชุมหรือไม่ ?', "ยืนยันการประชุม", <FaCheckCircle color="#6A0F0F" />)) {
      return;
    }

    const body = snMeetingData;
    body.status = statusType;

    const { status } = await s0201.updateSnMeetingAsync(id, body);

    if (status === HttpStatusCode.ACCEPTED) {
      if (statusType === StatusMeeting.CONFIRM) {
        toast.success('ยืนยันสำเร็จ');
      } else if (statusType === StatusMeeting.CANCEL) {
        toast.success('ยกเลิกการประชุมสำเร็จ');
      }
      else {
        toast.success('บันทึกข้อมูลสำเร็จ');
      }

      await getSnMeetingDetailAsync();
    }
  };

  return (
    <div className="my-3 d-flex justify-content-between">
      <Button
        variant="outline-primary"
        type="button"
        onClick={() => navigate('/s0201')}>
        <FaAngleLeft className="me-2" />ย้อนกลับ
      </Button>
      <div className="d-flex gap-2">
        {snMeetingData.status === StatusMeeting.CONFIRM && <Button
          variant="outline-primary"
          type="button"
          onClick={() => onSave(StatusMeeting.CANCEL)}>
          <FaRegTimesCircle className="me-2" />ยกเลิกการประชุม
        </Button>}
        {(snMeetingData.status === StatusMeeting.DRAFT || !snMeetingData.status) && <Button
          variant="outline-primary"
          type="button"
          onClick={() => onSave(StatusMeeting.DRAFT, true)}>
          <FaSave className="me-2" />บันทึกแบบร่าง
        </Button>}
        {(snMeetingData.status === StatusMeeting.DRAFT && id) && <Button
          variant="primary"
          type="button"
          onClick={() => onSave(StatusMeeting.CONFIRM)}>
          <FaRegCheckCircle className="me-2" />ยืนยัน
        </Button>}
      </div>
    </div>
  );
}

function DataForm() {
  const { snMeetingData, setSnMeetingData, disabled } = useContext(Context);
  const { snMeetingTypeItems, snMeetingPeriodItems, snMeetingRoomItems } = useLoaderData() as Loader;

  const onChangeDataForm = (prop: keyof SnMeeting, value?: string | number | Date) => {
    setSnMeetingData({ ...snMeetingData, [prop]: value });
  };

  const getYearList = () => {
    const date = new Date();
    const getPresentYear = date.getFullYear();
    const thYear = (getPresentYear + 543) - 10;
    const yearItems: ItemModel[] = [];

    for (let i = 0; i <= 20; i++) {
      yearItems.push({
        label: (thYear + i).toString(), value: (getPresentYear - 10) + i
      });
    }

    return yearItems;
  };

  const onChangeTime = (prop: keyof SnMeeting, time?: Date) => {
    if (prop === 'startTime' && isNullOrEmptyTime(snMeetingData.endTime) && time != null) {
      const autoGenerageEndTime = new Date();
      autoGenerageEndTime.setHours(23, 59, 0, 0);

      setSnMeetingData({ ...snMeetingData, startTime: time, endTime: autoGenerageEndTime, });

      return;
    }

    setSnMeetingData({ ...snMeetingData, [prop]: time });
  };

  return (
    <Form>
      <Row>
        <Col sm={6} md={4} lg={4} xl={3} xxl={2}>
          <Selector
            name="snMeetingTypeId"
            label="ประเภทการประชุม"
            disabled={disabled}
            rule={{ required: true }}
            items={snMeetingTypeItems}
            value={snMeetingData.snMeetingTypeId}
            onChange={(value) => onChangeDataForm('snMeetingTypeId', value)} />
        </Col>
      </Row>
      <Row>
        <Col sm={6} md={4} lg={4} xl={3} xxl={2}>
          <Input
            name="time"
            label="ครั้งที่"
            type="number"
            disabled={disabled}
            value={snMeetingData.time}
            rule={{ required: true }}
            onChange={(value) => onChangeDataForm('time', value)} />
        </Col>
        <Col sm={6} md={4} lg={4} xl={3} xxl={2}>
          <Selector
            label="ปี"
            name="year"
            disabled={disabled}
            rule={{ required: true }}
            items={getYearList()}
            value={snMeetingData.year}
            onChange={(value) => onChangeDataForm('year', value)} />
        </Col>
        <Col sm={6} md={4} lg={4} xl={3} xxl={2}>
          <Selector label="สมัยการประชุม"
            name="snMeetingPeriodId"
            disabled={disabled}
            rule={{ required: true }}
            value={snMeetingData.snMeetingPeriodId}
            items={snMeetingPeriodItems}
            onChange={(value) => onChangeDataForm('snMeetingPeriodId', value)} />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={8} lg={8} xl={6} xxl={4}>
          <Input
            name="name"
            label="ชื่อการประชุม"
            disabled={disabled}
            rule={{ required: true }}
            value={snMeetingData.name}
            onChange={(value) => onChangeDataForm('name', value)} />
        </Col>
        <Col sm={6} md={4} lg={4} xl={4} xxl={3}>
          <Selector
            name="smMeetingRoomId"
            label="ณ ห้องประชุม"
            disabled={disabled}
            rule={{ required: true }}
            value={snMeetingData.smMeetingRoomId}
            items={snMeetingRoomItems}
            onChange={(value) => onChangeDataForm('smMeetingRoomId', value)} />
        </Col>
      </Row>
      <Row>
        <Col sm={6} md={4} lg={4} xl={3} xxl={2}>
          <InputDate
            name="startDate"
            label="วันที่ประชุม"
            disabled={disabled}
            rule={{ required: true }}
            value={snMeetingData.startDate ? new Date(snMeetingData.startDate) : undefined}
            onChange={(value) => onChangeDataForm('startDate', value)} />
        </Col>
        <Col sm={6} md={4} lg={4} xl={3} xxl={2}>
          <InputTime
            name="startTime"
            label="เวลาเริ่มประชุม"
            disabled={disabled}
            value={snMeetingData.startTime}
            onChange={(value) => onChangeTime("startTime", value)} />
        </Col>
        <Col sm={6} md={4} lg={4} xl={3} xxl={2}>
          <InputTime
            name="endTime"
            label="เวลาสิ้นสุดประชุม"
            disabled={disabled}
            value={snMeetingData.endTime}
            onChange={(value) => onChangeTime("endTime", value)} />
        </Col>
      </Row>
    </Form>
  );
}

function AgendaTable() {
  const { snMeetingData, setSnMeetingData, disabled, getSnMeetingDetailAsync } = useContext(Context);
  const { snAgendaTypeItems, snMeetingDetailData } = useLoaderData() as Loader;
  const [snMeetingAgendaList, setSnMeetingAgendaList] = useState<SnMeetingAgendas[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [index, setIndex] = useState<number>();

  useEffect(() => {
    if (snMeetingData.snMeetingAgendas) {
      setSnMeetingAgendaList(snMeetingData.snMeetingAgendas);
    }
  }, [snMeetingData]);

  const handlerRemoveAsync = async (i: number) => {
    if (await showModalRemoveAsync() && snMeetingData.snMeetingAgendas) {
      const snMeeting = [...snMeetingData.snMeetingAgendas];

      snMeeting.splice(i, 1);
      setSnMeetingData({ ...snMeetingData, snMeetingAgendas: snMeeting });
    }
  };

  const onShowModalEdit = (i: number) => {
    setIndex(i);
    setShowModal(true);
  };

  const AddModal = () => {
    const [snAgenda, setSnAgenda] = useState<SnMeetingAgendas>({} as SnMeetingAgendas);

    useEffect(() => {
      if (showModal && index !== undefined) {
        setSnAgenda(snMeetingData.snMeetingAgendas[index]);
      }
    }, [showModal, index]);

    const onChangeAgenda = (value: string | number, prop: string) => {
      setSnAgenda({ ...snAgenda, [prop]: value });
    };

    const onChangeAgendaType = (value: string | number) => {
      const findAgenda = snAgendaTypeItems.find(s => s.value === value);

      if (findAgenda) {
        setSnAgenda({ ...snAgenda, snAgendaTypeId: value.toString(), agendaTypeName: findAgenda.label });
      }
    };

    const handleModalOnSubmit = (event: FormEvent<HTMLElement>) => {
      event.preventDefault();

      onSave();
    };

    const onSave = () => {
      submitForm();

      if (!snAgenda.sequence ||
        !snAgenda.snAgendaTypeId ||
        !snAgenda.agendaTitle) {
        return;
      }

      const snMeeting = snMeetingData.snMeetingAgendas ? [...snMeetingData.snMeetingAgendas] : [];
      const snAgendaData = snAgenda;
      snAgendaData.refMeetingAgendaId = '0';

      if (index === undefined) {
        snMeeting.push(snAgendaData);
      } else {
        snMeeting[index] = snAgendaData;
      }

      setSnMeetingData({ ...snMeetingData, snMeetingAgendas: snMeeting });
      onHide();
    };

    const onHide = () => {
      setIndex(undefined);
      setSnAgenda({} as SnMeetingAgendas);
      setShowModal(false);
    };

    return (
      <Modal show={showModal} title={`${index !== undefined ? `แก้ไข` : `เพิ่ม`}วาระการประชุม`}>
        <Form onSubmit={handleModalOnSubmit}>
          <div className="d-flex flex-column">
            <Input
              name="sequence"
              type="number"
              label="ระเบียบวาระที่"
              rule={{ required: true }}
              value={snAgenda.sequence}
              onChange={(value) => onChangeAgenda(value, 'sequence')} />
            <Input
              name="bullet"
              label="ระเบียบวาระย่อยที่"
              value={snAgenda.bullet}
              onChange={(value) => onChangeAgenda(value, 'bullet')} />
            <Selector
              name="snAgendaTypeId"
              label="ประเภทวาระ"
              rule={{ required: true }}
              items={snAgendaTypeItems}
              value={snAgenda.snAgendaTypeId}
              onChange={(value) => onChangeAgendaType(value)} />
            <TextArea
              name="agendaTitle"
              label="หัวข้อ"
              rule={{ required: true }}
              value={snAgenda.agendaTitle}
              onChange={(value) => onChangeAgenda(value, 'agendaTitle')} />
          </div>
          <div className="d-flex justify-content-end gap-2">
            <Button
              className="w-100"
              variant="outline-primary"
              type="button"
              onClick={onHide}>
              ยกเลิก
            </Button>
            <Button
              className="w-100"
              variant="primary"
              type="submit">
              บันทึก
            </Button>
          </div>
        </Form>
      </Modal>
    );
  };

  const syncAgendaAsync = async () => {
    if (!await showModalConfirmAsync('ยืนยันการซิงค์ข้อมูล')) {
      return;
    }

    const { status } = await s0201.syncAgendaAsync(snMeetingDetailData.id, snMeetingDetailData.meetingIdPis);

    if (status === HttpStatusCode.ACCEPTED) {
      toast.success('ซิงค์ข้อมูลสำเร็จ');

      getSnMeetingDetailAsync();
    }
  };

  return (
    <>
      <div className="my-3 d-flex align-items-end justify-content-between">
        <h4 className="m-0">ตารางวาระการประชุม</h4>
        <div className="d-flex gap-2">
          {(!disabled && snMeetingData.meetingIdPis) && <Button
            variant="outline-primary"
            type="button"
            onClick={syncAgendaAsync}>
            <FaRedo className="me-2" />ซิงค์ข้อมูล
          </Button>}
          {!disabled && <Button
            variant="outline-primary"
            type="button"
            onClick={() => setShowModal(true)}>
            <FaPlus className="me-2" />เพิ่มวาระการประชุม
          </Button>}
        </div>
      </div>
      <Table total={snMeetingAgendaList.length} hidePagination>
        <Table.Header>
          <Table.Row>
            <Table.Column minWidth={100}>ระเบียบวาระที่</Table.Column>
            <Table.Column minWidth={100}>ระเบียบวาระย่อยที่</Table.Column>
            <Table.Column minWidth={150}>ประเภทวาระ</Table.Column>
            <Table.Column minWidth={300}>หัวข้อวาระ</Table.Column>
            {!disabled ? <Table.Column minWidth={100} /> : <></>}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {snMeetingAgendaList?.map((data, index) => (
            <Table.Row key={data.sequence}>
              <Table.Cell center>{data.sequence === 0 ? "" : data.sequence}</Table.Cell>
              <Table.Cell center>{data.bullet}</Table.Cell>
              <Table.Cell>{data.agendaTypeName}</Table.Cell>
              <Table.Cell>{data.agendaTitle}</Table.Cell>
              {!disabled ? <Table.Cell center>
                <FaEdit className="ms-2 fs-5" onClick={() => onShowModalEdit(index)} />
                <FaTrashAlt onClick={() => handlerRemoveAsync(index)} className="ms-2 fs-5" />
              </Table.Cell> : <></>}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <AddModal />
    </>
  );
}