import { Layout, Card, Tabs, Selector, InputDate, Input, Table, Chart as Charts, Modal, Status, StatusType } from "components";
import { Tab, Form, Col, Row, Button } from "react-bootstrap";
import { FaFilter, FaTimes, FaTrashAlt } from "react-icons/fa";
import { TbList, TbListCheck } from "react-icons/tb";
import { RiTimeLine } from "react-icons/ri";
import {
  CommitteeGroupDataTable,
  BureauDataTable,
  ChartDataList,
  CommitteeCriteriaList,
  ItemModel,
  PaginationPerformanceDashBoard,
  SenateCriteriaList,
  Chart,
  SenateMeetingDataTable,
  CommitteeGroupAndBureauDataTable,
  PerformanceDetail,
} from "models";
import { useLoaderData, useSubmit } from "react-router-dom";
import { FormEvent, useEffect, useState } from "react";
import { formatDateTh, getYearList } from "utils";
import { d02Service } from "services";
import { BureauId, Code, HttpStatusCode, SenateGroup } from "constant";
import toast from "utils/toast";

enum OverviewDataTableType {
  senate,
  committee,
  committeeByBureauGroup,
}

type Loader = {
  snMeetingTypeItems: ItemModel[];
  snMeetingPeriodItems: ItemModel[];
  smBureauGroupItems: ItemModel[];
  bureauItems: ItemModel[];
  committeeItems: ItemModel[];
  subCommitteeItems: ItemModel[];
  senateChart: ChartDataList;
  committeeChart: ChartDataList;
  committeeGroupDataTable: PaginationPerformanceDashBoard<CommitteeGroupDataTable>;
  committeeBureauDataTable: PaginationPerformanceDashBoard<BureauDataTable>;
};

interface ChartProps {
  data: ChartDataList;
};

const TABS = [
  { label: 'การประชุมวุฒิสภา', value: 1 },
  { label: 'คณะกรรมาธิการ', value: 2 },
];

const senateGroupTab = [
  { label: 'กลุ่มงานชวเลข 1', value: 1 },
  { label: 'กลุ่มงานชวเลข 2', value: 2 },
  { label: 'กลุ่มงานชวเลข 3', value: 3 },
  { label: 'กลุ่มงานชวเลข 4', value: 4 },
]

export default function D02() {
  return (
    <Layout title="ภาพรวมสถิติการปฏิบัติงาน" className="d02">
      <Card>
        <Tabs items={TABS} defaultActiveKey={1}>
          <Tab.Content>
            <Tab.Pane eventKey={1}>
              <SenateTab />
            </Tab.Pane>
            <Tab.Pane eventKey={2}>
              <CommitteeTab />
            </Tab.Pane>
          </Tab.Content>
        </Tabs>
      </Card>
    </Layout>
  );
};

function SenateTab() {
  const {
    senateChart,
    snMeetingTypeItems,
    snMeetingPeriodItems,
    smBureauGroupItems,
    bureauItems,
    committeeGroupDataTable,
    committeeBureauDataTable,
  } = useLoaderData() as Loader;
  const [criteria, setCriteria] = useState<SenateCriteriaList>({} as SenateCriteriaList);
  const [tempCriteria, setTempCriteria] = useState<SenateCriteriaList>({} as SenateCriteriaList);
  const [bureauGroupItems, setBureauGroupItems] = useState<ItemModel[]>([]);
  const submit = useSubmit();

  useEffect(() => {
    const bureauCode = bureauItems.filter(f => f.label.includes(Code.BUREAU_CODE));
    const bureauGroupFilterData = [...smBureauGroupItems.filter(f => f.id == bureauCode[0].value).slice(2)];

    setBureauGroupItems(bureauGroupFilterData);
  }, []);

  const onChangeCriteria = (prop: string, value?: string | number | Date) => {
    setCriteria({ ...criteria, [prop]: value });
  };

  const onFilter = () => {
    if (criteria.startDate > criteria.endDate) {
      toast.warn('วันที่เริ่มต้นมากกว่าวันที่สิ้นสุด');

      return;
    }

    setTempCriteria(criteria);

    submit({
      senateCriteria: JSON.stringify(criteria),
      committeeGroupPage: committeeGroupDataTable.committeeGroupPage,
      committeeGroupSize: committeeGroupDataTable.committeeGroupSize,
      committeeBureauPage: committeeBureauDataTable.committeeBureauPage,
      committeeBureauSize: committeeBureauDataTable.committeeBureauSize,
    });
  };

  const onClear = () => {
    setCriteria({} as SenateCriteriaList);
    setTempCriteria({} as SenateCriteriaList);

    submit({});
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onFilter();
  };

  return (
    <Form onSubmit={onSubmit}>
      <Form.Label>กรองข้อมูล</Form.Label>
      <Row>
        <Col sm={6} md={4} lg={4} xl={3}>
          <Selector
            label="ประเภทการประชุม"
            items={snMeetingTypeItems}
            value={criteria.meetingTypeId}
            onChange={(value) => onChangeCriteria('meetingTypeId', value)} />
        </Col>
        <Col sm={6} md={4} lg={4} xl={3}>
          <Input
            label="ครั้งที่ประชุม"
            type="number"
            value={criteria.time}
            onChange={(value) => onChangeCriteria('time', value)} />
        </Col>
        <Col sm={6} md={4} lg={4} xl={3}>
          <Selector
            label="สมัยการประชุม"
            items={snMeetingPeriodItems}
            value={criteria.meetingPeriodId}
            onChange={(value) => onChangeCriteria('meetingPeriodId', value)} />
        </Col>
        <Col sm={6} md={4} lg={4} xl={3}>
          <Input
            label="ชื่อการประชุม"
            value={criteria.meetingName}
            onChange={(value) => onChangeCriteria('meetingName', value)} />
        </Col>
        <Col sm={6} md={4} lg={4} xl={3}>
          <Selector
            label="ปี"
            items={getYearList()}
            value={criteria.year}
            onChange={(value) => onChangeCriteria('year', value)} />
        </Col>
        <Col sm={6} md={4} lg={4} xl={3}>
          <Selector
            label="กลุ่มงานชวเลข"
            items={bureauGroupItems}
            value={criteria.bureauGroupId}
            onChange={(value) => onChangeCriteria('bureauGroupId', value)} />
        </Col>
        <Col sm={6} md={4} lg={4} xl={3}>
          <InputDate
            label="วันที่เริ่มต้น"
            value={criteria.startDate}
            onChange={(value) => onChangeCriteria('startDate', value)} />
        </Col>
        <Col sm={6} md={4} lg={4} xl={3}>
          <InputDate
            label="วันที่สิ้นสุด"
            value={criteria.endDate}
            onChange={(value) => onChangeCriteria('endDate', value)} />
        </Col>
      </Row>
      <div className="d-flex justify-content-end gap-2">
        <Button type="submit">
          <FaFilter className="me-2" />กรอง
        </Button>
        <Button
          variant="outline-primary"
          onClick={onClear}>
          <FaTrashAlt className="me-2" />ล้าง
        </Button>
      </div>
      <Row className="justify-content-md-center">
        <Col lg={9} xxl={6}>
          <ChartBar data={senateChart} />
        </Col>
        <Col xxl={2}>
          <ChartDetail data={senateChart} />
        </Col>
      </Row>
      <Row>
      </Row>
      <Row className="mb-3">
        <Tabs items={senateGroupTab} defaultActiveKey={1}>
          <Tab.Content>
            <Tab.Pane eventKey={1}>
              <SenateMeetingDataTable senateGroup={SenateGroup.SENATE_GROUP_CODE_1} criteria={tempCriteria} />
            </Tab.Pane>
            <Tab.Pane eventKey={2}>
              <SenateMeetingDataTable senateGroup={SenateGroup.SENATE_GROUP_CODE_2} criteria={tempCriteria} />
            </Tab.Pane>
            <Tab.Pane eventKey={3}>
              <SenateMeetingDataTable senateGroup={SenateGroup.SENATE_GROUP_CODE_3} criteria={tempCriteria} />
            </Tab.Pane>
            <Tab.Pane eventKey={4}>
              <SenateMeetingDataTable senateGroup={SenateGroup.SENATE_GROUP_CODE_4} criteria={tempCriteria} />
            </Tab.Pane>
          </Tab.Content>
        </Tabs>
      </Row>
    </Form>
  );
};

function CommitteeTab() {
  const {
    committeeChart,
    bureauItems,
    committeeItems,
    subCommitteeItems,
    smBureauGroupItems,
    committeeBureauDataTable,
    committeeGroupDataTable,
  } = useLoaderData() as Loader;
  const [criteria, setCriteria] = useState<CommitteeCriteriaList>({} as CommitteeCriteriaList);
  const [tempCriteria, setTempCriteria] = useState<CommitteeCriteriaList>({} as CommitteeCriteriaList);
  const [bureauGroupItems, setBureauGroupItems] = useState<ItemModel[]>([]);
  const submit = useSubmit();

  useEffect(() => {
    const bureauCode = bureauItems.filter(f => f.label.includes(Code.BUREAU_CODE));
    const bureauGroupFilterData = [...smBureauGroupItems.filter(f => f.id == bureauCode[0].value).slice(2)];

    setBureauGroupItems(bureauGroupFilterData);
  }, []);

  const onChangeCriteria = (prop: keyof CommitteeCriteriaList, value?: string | number | Date) => {
    setCriteria({ ...criteria, [prop]: value });
  };

  const onFilter = () => {
    if (criteria.startDate > criteria.endDate) {
      toast.warn('วันที่เริ่มต้นมากกว่าวันที่สิ้นสุด');

      return;
    }

    setTempCriteria(criteria);

    submit({
      committeeCriteria: JSON.stringify(criteria),
      committeeGroupPage: committeeGroupDataTable.committeeGroupPage,
      committeeGroupSize: committeeGroupDataTable.committeeGroupSize,
      committeeBureauPage: committeeBureauDataTable.committeeBureauPage,
      committeeBureauSize: committeeBureauDataTable.committeeBureauSize,
    });
  };

  const onClear = () => {
    setCriteria({} as CommitteeCriteriaList);
    setTempCriteria({} as CommitteeCriteriaList);

    submit({});
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onFilter();
  };

  return (
    <Form onSubmit={onSubmit}>
      <Form.Label>กรองข้อมูล</Form.Label>
      <Row>
        <Col sm={6} md={4} lg={4} xl={3}>
          <Selector
            label="สำนัก"
            items={bureauItems}
            value={criteria.bureauId}
            onChange={(value) => onChangeCriteria('bureauId', value)} />
        </Col>
        <Col sm={6} md={4} lg={4} xl={3}>
          <Selector
            label="คณะกรรมาธิการ"
            items={committeeItems}
            value={criteria.committeeId}
            onChange={(value) => onChangeCriteria('committeeId', value)} />
        </Col>
        <Col sm={6} md={4} lg={4} xl={3}>
          <Selector
            label="คณะอนุกรรมาธิการ"
            items={subCommitteeItems}
            value={criteria.subCommitteeId}
            onChange={(value) => onChangeCriteria('subCommitteeId', value)} />
        </Col>
        <Col sm={6} md={4} lg={4} xl={3}>
          <Input
            label="ครั้งที่ประชุม"
            type="number"
            value={criteria.time}
            onChange={(value) => onChangeCriteria('time', value)} />
        </Col>
        <Col sm={6} md={4} lg={4} xl={3}>
          <Selector
            label="ปี"
            items={getYearList()}
            value={criteria.year}
            onChange={(value) => onChangeCriteria('year', value)} />
        </Col>
        <Col sm={6} md={4} lg={4} xl={3}>
          <Selector
            label="กลุ่มงานชวเลข"
            items={bureauGroupItems}
            value={criteria.bureauGroupId}
            onChange={(value) => onChangeCriteria('bureauGroupId', value)} />
        </Col>
        <Col sm={6} md={4} lg={4} xl={3}>
          <InputDate
            label="วันที่เริ่มต้น"
            value={criteria.startDate}
            onChange={(value) => onChangeCriteria('startDate', value)} />
        </Col>
        <Col sm={6} md={4} lg={4} xl={3}>
          <InputDate
            label="วันที่สิ้นสุด"
            value={criteria.endDate}
            onChange={(value) => onChangeCriteria('endDate', value)} />
        </Col>
      </Row>
      <div className="d-flex justify-content-end gap-2">
        <Button type="submit">
          <FaFilter className="me-2" />กรอง
        </Button>
        <Button
          variant="outline-primary"
          onClick={onClear}>
          <FaTrashAlt className="me-2" />ล้าง
        </Button>
      </div>
      <Row className="justify-content-md-center">
        <Col lg={9} xxl={6}>
          <ChartBar data={committeeChart} />
        </Col>
        <Col xxl={2}>
          <ChartDetail data={committeeChart} />
        </Col>
      </Row>
      <Row className="my-3">
        <Col xs={12}>
          <div className="mb-3">
            <h4 className="text-decoration-underline m-0">การประชุมคณะกรรมาธิการ (จำแนกตามคณะ)</h4>
          </div>
        </Col>
        <Tabs items={senateGroupTab} defaultActiveKey={1}>
          <Tab.Content>
            <Tab.Pane eventKey={1}>
              <MeetingOverviewDataTable overviewDataTableType={OverviewDataTableType.committee} bureauGroupId={BureauId.BUREAU_ID_GROUP_1} criteria={tempCriteria} />
            </Tab.Pane>
            <Tab.Pane eventKey={2}>
              <MeetingOverviewDataTable overviewDataTableType={OverviewDataTableType.committee} bureauGroupId={BureauId.BUREAU_ID_GROUP_2} criteria={tempCriteria} />
            </Tab.Pane>
            <Tab.Pane eventKey={3}>
              <MeetingOverviewDataTable overviewDataTableType={OverviewDataTableType.committee} bureauGroupId={BureauId.BUREAU_ID_GROUP_3} criteria={tempCriteria} />
            </Tab.Pane>
            <Tab.Pane eventKey={4}>
              <MeetingOverviewDataTable overviewDataTableType={OverviewDataTableType.committee} bureauGroupId={BureauId.BUREAU_ID_GROUP_4} criteria={tempCriteria} />
            </Tab.Pane>
          </Tab.Content>
        </Tabs>
      </Row>
      <Row className="my-3">
        <Col xs={12}>
          <div className="mb-3">
            <h4 className="text-decoration-underline my-3">การประชุมคณะกรรมาธิการ (จำแนกตามสำนัก)</h4>
          </div>
        </Col>
        <Tabs items={senateGroupTab} defaultActiveKey={1}>
          <Tab.Content>
            <Tab.Pane eventKey={1}>
              <MeetingOverviewDataTable overviewDataTableType={OverviewDataTableType.committeeByBureauGroup} bureauGroupId={BureauId.BUREAU_ID_GROUP_1} criteria={tempCriteria} />
            </Tab.Pane>
            <Tab.Pane eventKey={2}>
              <MeetingOverviewDataTable overviewDataTableType={OverviewDataTableType.committeeByBureauGroup} bureauGroupId={BureauId.BUREAU_ID_GROUP_2} criteria={tempCriteria} />
            </Tab.Pane>
            <Tab.Pane eventKey={3}>
              <MeetingOverviewDataTable overviewDataTableType={OverviewDataTableType.committeeByBureauGroup} bureauGroupId={BureauId.BUREAU_ID_GROUP_3} criteria={tempCriteria} />
            </Tab.Pane>
            <Tab.Pane eventKey={4}>
              <MeetingOverviewDataTable overviewDataTableType={OverviewDataTableType.committeeByBureauGroup} bureauGroupId={BureauId.BUREAU_ID_GROUP_4} criteria={tempCriteria} />
            </Tab.Pane>
          </Tab.Content>
        </Tabs>
      </Row>
    </Form>
  );
};

function ChartBar(props: ChartProps) {
  const bureauGroup1 = props.data.bureauGroup1;
  const bureauGroup2 = props.data.bureauGroup2;
  const bureauGroup3 = props.data.bureauGroup3;
  const bureauGroup4 = props.data.bureauGroup4;

  const allChapter = [
    bureauGroup1.totalChapter,
    bureauGroup2.totalChapter,
    bureauGroup3.totalChapter,
    bureauGroup4.totalChapter,
  ];

  const allSuccess = [
    bureauGroup1.totalFinished,
    bureauGroup2.totalFinished,
    bureauGroup3.totalFinished,
    bureauGroup4.totalFinished,
  ];

  const allTotalInProcess = [
    bureauGroup1.totalInProcess,
    bureauGroup2.totalInProcess,
    bureauGroup3.totalInProcess,
    bureauGroup4.totalInProcess,
  ];

  const allGuarantee = [
    bureauGroup1.totalGuarantee,
    bureauGroup2.totalGuarantee,
    bureauGroup3.totalGuarantee,
    bureauGroup4.totalGuarantee,
  ];

  const allCollect = [
    bureauGroup1.totalCollect,
    bureauGroup2.totalCollect,
    bureauGroup3.totalCollect,
    bureauGroup4.totalCollect,
  ]

  const chartData: Chart = {
    labels: ['กลุ่มงาน 1', 'กลุ่มงาน 2', 'กลุ่มงาน 3', 'กลุ่มงาน 4'],
    chartData: [
      {
        label: 'ตอนทั้งหมด',
        data: allChapter,
        backgroundColor: '#6A0F0F'
      },
      {
        label: 'สำเร็จ',
        data: allSuccess,
        backgroundColor: '#517634'
      },
      {
        label: 'รวบรวมรายงาน',
        data: allCollect,
        backgroundColor: '#fca503'
      },
      {
        label: 'รับรองรายงาน',
        data: allGuarantee,
        backgroundColor: '#cccccc'
      },
      {
        label: 'ระหว่างดำเนินการ',
        data: allTotalInProcess,
        backgroundColor: '#9B9B9B'
      }
    ],
  };

  return (
    <Charts chartData={chartData} />
  );
};

function ChartDetail(props: ChartProps) {
  const data = props.data;

  return (
    <Row className="chart-detail">
      <Col xs={6} md={6} xl={12}>
        <div className="d-flex gap-2 mt-3">
          <TbList
            className="text-primary my-auto"
            size={40} />
          <div>
            <label
              className="text-decoration-underline fs-5 text-primary">
              จำนวนตอนทั้งหมด
            </label>
            <p
              className="text-primary fs-3 my-auto">
              {data.totalChapter} ตอน
            </p>
          </div>
        </div>
      </Col>
      <Col xs={6} md={6} xl={12}>
        <div className="d-flex gap-2 mt-3">
          <TbListCheck
            className="text-success my-auto"
            size={40} />
          <div>
            <label
              className="text-decoration-underline fs-5 text-success">
              จำนวนตอนที่สำเร็จทั้งหมด
            </label>
            <p
              className="text-success fs-3 my-auto">
              {data.totalFinished} ตอน
            </p>
          </div>
        </div>
      </Col>
      <Col xs={6} md={6} xl={12}>
        <div className="d-flex gap-2 mt-3">
          <TbListCheck
            className="text-warning my-auto"
            size={40} />
          <div>
            <label
              className="text-decoration-underline fs-5 text-warning">
              จำนวนตอนที่รวบรวมรายงานทั้งหมด
            </label>
            <p
              className="text-warning fs-3 my-auto">
              {data.totalCollect} ตอน
            </p>
          </div>
        </div>
      </Col>
      <Col xs={6} md={6} xl={12}>
        <div className="d-flex gap-2 mt-3">
          <TbListCheck
            className="text-pending my-auto"
            size={40} />
          <div>
            <label
              className="text-decoration-underline fs-5 text-pending">
              จำนวนตอนที่รับรองรายงานทั้งหมด
            </label>
            <p
              className="text-pending fs-3 my-auto">
              {data.totalGuarantee} ตอน
            </p>
          </div>
        </div>
      </Col>
      <Col xs={6} md={6} xl={12}>
        <div className="d-flex gap-2 mt-3">
          <TbList
            className="text-light-border my-auto"
            size={40} />
          <div>
            <label
              className="text-decoration-underline fs-5 text-light-border">
              จำนวนตอนระหว่างดำเนินการ
            </label>
            <p
              className="text-light-border fs-3 my-auto">
              {data.totalInProcess} ตอน
            </p>
          </div>
        </div>
      </Col>
      <Col xs={6} md={6} xl={12}>
        <div className="d-flex gap-2 mt-3">
          <RiTimeLine
            className="text-primary my-auto"
            size={40} />
          <div>
            <label
              className="text-decoration-underline fs-5 text-primary">
              ระยะเวลาการจดรวมทั้งหมด
            </label>
            <p
              className="text-primary fs-3 my-auto">
              {data.totalWritingTime} ชม
            </p>
          </div>
        </div>
      </Col>
      <Col md={6} xl={12}>
        <div className="d-flex gap-2 mt-3">
          <RiTimeLine
            className="text-primary my-auto"
            size={40} />
          <div>
            <label
              className="text-decoration-underline fs-5 text-primary">
              ระยะเวลาเฉลี่ยการจด/คน
            </label>
            <p
              className="text-primary fs-3 my-auto">
              {data.averageWritingTime} ชม
            </p>
          </div>
        </div>
      </Col>
    </Row>
  );
};

interface SenateMeetingDataTableProps {
  senateGroup: string;
  criteria: SenateCriteriaList;
}

function SenateMeetingDataTable(props: SenateMeetingDataTableProps) {
  const [senateMeetingDataList, setSenateMeetingDataList] = useState<SenateMeetingDataTable[]>([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [meetingId, setMeetingId] = useState('');

  useEffect(() => {
    getSenateMeetingDataListAsync();
  }, [props.senateGroup, page, size, props.criteria.startDate, props.criteria.endDate]);

  const getSenateMeetingDataListAsync = async () => {
    const { data, status } = await d02Service.getMeetingPeriodListAsync(page, size, props.senateGroup, props.criteria.startDate, props.criteria.endDate);

    if (status === HttpStatusCode.OK) {
      setSenateMeetingDataList(data.rows);
      setTotalRecord(data.totalRows);
    }
  };

  const onShowModalDetail = (id: string) => {
    setMeetingId(id);
    setShowModalDetail(true);
  };

  return (
    <>
      <Table
        total={totalRecord}
        onChange={(size, page) => (setPage(page), setSize(size))}
        page={page}
        size={size}>
        <Table.Header>
          <Table.Row>
            <Table.Column
              minWidth={150}>
              <>ประเภท<br />การประชุม</>
            </Table.Column>
            <Table.Column
              minWidth={80}>
              <> จำนวนครั้ง</>
            </Table.Column>
            <Table.Column
              minWidth={50}>
              รับรองรายงาน
            </Table.Column>
            <Table.Column
              minWidth={50}>
              ตรวจสอบภาพรวมสำเร็จ
            </Table.Column>
            <Table.Column
              minWidth={50}>
              รวบรวมรายงาน
            </Table.Column>
            <Table.Column
              minWidth={50}>
              รอดำเนินการ
            </Table.Column>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {senateMeetingDataList?.map((data) => (
            <Table.Row key={data.meetingPeriodName}>
              <Table.Cell className={`summary-row ${data.summaryName ? ' text-center' : ''}`}>
                {
                  data.summaryName ? "รวม" :
                    <p
                      className="text-link text-decoration-underline cursor-pointer"
                      onClick={() => onShowModalDetail(data.meetingPeriodId)}>
                      {data.meetingPeriodName}
                    </p>
                }
              </Table.Cell>
              <Table.Cell className="summary-row" right>{data.chapterAll}</Table.Cell>
              <Table.Cell className="summary-row" right>{data.guarantee}</Table.Cell>
              <Table.Cell className="summary-row" right>{data.overviewReviewed}</Table.Cell>
              <Table.Cell className="summary-row" right>{data.collect}</Table.Cell>
              <Table.Cell className="summary-row" right>{data.inProgress}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <MeetingOverviewDetailModal
        show={showModalDetail}
        onHide={() => setShowModalDetail(false)}
        meetingType={OverviewDataTableType.senate}
        id={meetingId}
        senateGroup={props.senateGroup}
        startDate={props.criteria.startDate}
        endDate={props.criteria.endDate} />
    </>
  )
};

function MeetingOverviewDataTable(props: {
  overviewDataTableType: OverviewDataTableType,
  bureauGroupId: string,
  criteria: CommitteeCriteriaList,
}) {
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [dataList, setDataList] = useState<CommitteeGroupAndBureauDataTable[]>([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [meetingId, setMeetingId] = useState("");

  useEffect(() => {
    getDataListAsync();
  }, [page, size, props.overviewDataTableType, props.bureauGroupId, props.criteria.startDate, props.criteria.endDate]);

  const getDataListAsync = async () => {
    if (props.overviewDataTableType === OverviewDataTableType.committee) {
      const { data, status } = await d02Service.getCommitteeGroupByBureauIdAsync(page, size, props.bureauGroupId, props.criteria.startDate, props.criteria.endDate);

      if (status === HttpStatusCode.OK) {
        setDataList(data.rows);
        setTotalRecord(data.totalRows);
      }
    } else if (props.overviewDataTableType === OverviewDataTableType.committeeByBureauGroup) {
      const { data, status } = await d02Service.getCommitteeByBureauGroupListByBureauIdAsync(page, size, props.bureauGroupId, props.criteria.startDate, props.criteria.endDate);

      if (status === HttpStatusCode.OK) {
        setDataList(data.rows);
        setTotalRecord(data.totalRows);
      }
    }
  };

  const getMeetingType = (data: CommitteeGroupAndBureauDataTable): string => {
    switch (props.overviewDataTableType) {
      case OverviewDataTableType.committee:
        return data.committeeName;
      default:
        return data.bureauName;
    }
  };

  const onShowModalDetail = (id: string) => {
    setMeetingId(id);
    setShowModalDetail(true);
  };

  return (
    <>
      <Table
        className="table-no-rounded"
        total={totalRecord}
        page={page}
        size={size}
        onChange={(size, page) => (setPage(page), setSize(size))}>
        <Table.Header>
          <Table.Row>
            <Table.Column
              minWidth={150}>
              <>ประเภทการประชุม</>
            </Table.Column>
            <Table.Column
              minWidth={50}>
              จำนวนครั้ง
            </Table.Column>
            <Table.Column
              minWidth={50}>
              รับรองรายงาน
            </Table.Column>
            <Table.Column
              minWidth={50}>
              ตรวจสอบภาพรวมสำเร็จ
            </Table.Column>
            <Table.Column
              minWidth={50}>
              รวบรวมรายงาน
            </Table.Column>
            <Table.Column
              minWidth={50}>
              รอดำเนินการ
            </Table.Column>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {dataList?.map(data => (
            <Table.Row key={data.bureauName ?? data.committeeName}>
              <Table.Cell className={`text-break summary-row ${data.summaryName ? ' text-center' : ''}`}>
                {
                  data.summaryName ? "รวม" :
                    <p
                      className="text-link"
                      onClick={() => onShowModalDetail(data.bureauId ?? data.committeeId)}>
                      {getMeetingType(data)}
                    </p>
                }
              </Table.Cell>
              <Table.Cell right className="summary-row">{data.all}</Table.Cell>
              <Table.Cell right className="summary-row">{data.guarantee}</Table.Cell>
              <Table.Cell right className="summary-row">{data.overviewReviewed}</Table.Cell>
              <Table.Cell right className="summary-row">{data.collect}</Table.Cell>
              <Table.Cell right className="summary-row">{data.inprogress}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <MeetingOverviewDetailModal
        show={showModalDetail}
        onHide={() => setShowModalDetail(false)}
        id={meetingId}
        senateGroup={props.bureauGroupId}
        meetingType={props.overviewDataTableType}
        startDate={new Date()}
        endDate={new Date()} />
    </>
  );
}

function MeetingOverviewDetailModal(props: {
  show: boolean,
  onHide: () => void,
  id: string,
  senateGroup: string,
  meetingType: OverviewDataTableType,
  startDate: Date,
  endDate: Date,
}) {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [detailList, setDetailList] = useState<PerformanceDetail[]>([]);

  useEffect(() => {
    if (props.show) {
      getDetailDataAsync();
    }
  }, [props.id, props.senateGroup, props.meetingType, page, size, props.show]);

  async function getMeetingPeriodListDataAsync() {
    const { data, status } = await d02Service.getSenateMeetingPeriodListDetailAsync(page, size, props.senateGroup, props.id, props.startDate, props.endDate);

    if (status !== HttpStatusCode.OK) {
      return;
    }

    const dataDetailList: PerformanceDetail[] = data.data.rows;

    if (dataDetailList.length > 0) {
      dataDetailList.push({
        totalChapter: data.totalChapter,
      } as PerformanceDetail);
    }

    setDetailList(dataDetailList);
    setTotalRecord(data.data.totalRows);
  }

  async function getCommitteeGroupDetailAsync() {
    const { data, status } = await d02Service.getCommitteeGroupDetailAsync(page, size, props.id, props.senateGroup);

    if (status !== HttpStatusCode.OK) {
      return;
    }

    const dataDetailList: PerformanceDetail[] = data.data.rows;

    if (dataDetailList.length > 0) {
      dataDetailList.push({
        totalChapter: data.totalChapter,
      } as PerformanceDetail);
    }

    setDetailList(dataDetailList);
    setTotalRecord(data.data.totalRows);
  }

  async function getCommitteeBureauDetailAsync() {
    const { data, status } = await d02Service.getCommitteeBureauDetailAsync(page, size, props.senateGroup, props.id);

    if (status !== HttpStatusCode.OK) {
      return;
    }

    const dataDetailList: PerformanceDetail[] = data.data.rows;

    if (dataDetailList.length > 0) {
      dataDetailList.push({
        totalChapter: data.totalChapter,
      } as PerformanceDetail);
    }

    setDetailList(dataDetailList);
    setTotalRecord(data.data.totalRows);
  }

  const getDetailDataAsync = async () => {
    switch (props.meetingType) {
      case OverviewDataTableType.senate:
        await getMeetingPeriodListDataAsync();
        break;
      case OverviewDataTableType.committee:
        await getCommitteeGroupDetailAsync();
        break;
      case OverviewDataTableType.committeeByBureauGroup:
        await getCommitteeBureauDetailAsync();
        break;
    }
  };

  return (
    <Modal show={props.show} size="xl">
      <div
        className="d-flex justify-content-end align-items-center mb-2"
        onClick={props.onHide}>
        <FaTimes className="fs-4" />
      </div>
      <Table
        page={page}
        size={size}
        total={totalRecord}
        onChange={(size, page) => (setPage(page), setSize(size))}>
        <Table.Header>
          <Table.Row>
            <Table.Column minWidth={150}>วันเดือนปี</Table.Column>
            <Table.Column minWidth={200}>ชื่อการประชุม/คณะ</Table.Column>
            <Table.Column minWidth={100}>
              <>จำนวน<br />ตอน</>
            </Table.Column>
            <Table.Column minWidth={80}>{props.meetingType === OverviewDataTableType.senate ? "รายชื่อผู้รวบรวมส่ง" : "คู่จด"}</Table.Column>
            <Table.Column minWidth={100}>สถานะ</Table.Column>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {detailList?.map(data => (
            <Table.Row key={data.meetingId}>
              <Table.Cell center className="summary-row">{data.meetingDate ? formatDateTh(data.meetingDate) : "รวม"}</Table.Cell>
              <Table.Cell className="summary-row">{data.meetingName}</Table.Cell>
              <Table.Cell right className="summary-row">{data.totalChapter}</Table.Cell>
              <Table.Cell className="summary-row">{data.inspect ?? data.dutyOfficerCouple}</Table.Cell>
              <Table.Cell right className="summary-row">
                <div className="w-100">
                  <Status
                    type={StatusType.MEETING_STATUS}
                    value={data.status} />
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Modal>
  )
};
