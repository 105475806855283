import { LoaderFunctionArgs } from "react-router-dom";
import { c0301 as service, selectionService, c0204 } from 'services';
import { SelectionType } from "constant";
import { CtInquiryMeetingStatusCriteria, CtInquiryMeetingStatusDetailCriteria } from "models";

export const c0301LoaderAsync = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 1;
  const size = url.searchParams.get('size') || 10;
  const criteriaStringify = url.searchParams.get('criteria') || '{}';
  const criteria = JSON.parse(criteriaStringify) as CtInquiryMeetingStatusCriteria;

  const [bureaus, committees, subCommittees, listResponse] = await Promise.all([
    selectionService.getSelectionAsync(SelectionType.SM_BUREAU),
    selectionService.getSelectionAsync(SelectionType.CT_COMMITTEE),
    selectionService.getSelectionAsync(SelectionType.CT_SUBCOMMITTEE),
    service.getListAsync(+page, +size, criteria),
  ]);

  return {
    bureaus: bureaus.data,
    committees: committees.data,
    subCommittees: subCommittees.data,
    data: {
      rows: listResponse.data.rows,
      totalRows: listResponse.data.totalRows,
      page: +page,
      size: +size,
    },
  };
};

export const c0301DetailLoaderAsync = async ({ request, params }: LoaderFunctionArgs) => {
  const id = params.id as string;
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 1;
  const size = url.searchParams.get('size') || 10;
  const criteriaStringify = url.searchParams.get('criteria') || '{}';
  const criteria = JSON.parse(criteriaStringify) as CtInquiryMeetingStatusDetailCriteria;

  const [headers, historys, lists, userList] = await Promise.all([
    service.getDetailAsync(id),
    service.getDetailHistoryListAsync(id),
    service.getDetailListAsync(id, +page, +size, criteria),
    selectionService.getUserSelectionAsync(SelectionType.SM_USER),
  ]);

  return {
    header: headers.data,
    history: historys.data,
    data: {
      rows: lists.data.rows,
      totalRows: lists.data.totalRows,
      page: +page,
      size: +size,
    },
    userList: userList.data,
  };
};

export const c0301DocumentLoaderAsync = async ({ params }: LoaderFunctionArgs) => {
  const id = params.id as string;
  const docId = params.docId as string;

  const [headers, document] = await Promise.all([
    service.getDetailAsync(id),
    c0204.getCtMakeDocumentChapterDetailAsync(docId),
  ]);

  return {
    header: headers.data,
    documentData: document.data,
  };
};