import { M0103Criteria, UserModel } from 'models';
import http from 'utils/axios';

const getUserListAsync = async (page: number, size: number, criteria?: M0103Criteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get('api/smuser', { params });
}

const getUserDetailAsync = async (id: string) => {
  return await http.get(`api/smuser/${id}`);
};

const updateAsync = async (id: string, body: UserModel) => {
  return await http.put(`api/smuser/${id}`, body);
};

const saveAsync = async (user: UserModel) => {
  return await http.post('api/smuser', user);
};

const getRoleSelectionAsync = async () => {
  return await http.get('api/smrole/selection');
};

const getBureauSelectionAsync = async () => {
  return await http.get('api/smbureau/selection');
};

const getBureauGroupSelectionAsync = async () => {
  return await http.get('api/smbureaugroup/selection');
};


const searchDpisUserAsync = async (size: number, page: number, keyword: string) => {
  return http.get('api/smuser/dpis/user', { params: { size, page, keyword } });
};

const m0103 = {
  getUserListAsync,
  getUserDetailAsync,
  updateAsync,
  saveAsync,
  getRoleSelectionAsync,
  getBureauSelectionAsync,
  getBureauGroupSelectionAsync,
  searchDpisUserAsync,
};

export default m0103;