import { HttpStatusCode } from "constant";
import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import toast from "./toast";
import { isAuthorized, signOutAsync } from "./authentication";
import cookie from "./cookie";
import loading from "./loading";

const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

Axios.interceptors.request.use(
  async (request) => {
    const accessToken = cookie.get('accessToken');

    if (accessToken && isAuthorized()) {
      request.headers.Authorization = `Bearer ${accessToken}`;
    }

    if (!request.url?.includes('/wopi/sessions/renew')) {
      showLoading(request);
    }

    return request;
  }, (error) => {
    return error;
  },
);

Axios.interceptors.response.use((response) => {
  hideLoading(response);

  return response;
}, (error) => {
  handlerErrorMessage(error);

  hideLoading(error);

  return error.response;
});

const handlerErrorMessage = (error: AxiosError) => {
  if (error.config?.headers?.isDisabledErrorMessage) {
    return;
  }

  switch (error.response?.status) {
    case HttpStatusCode.UNAUTHORIZED:
      signOutAsync();
      break;
    case HttpStatusCode.FORBIDDEN:
      toast.error('หวงห้าม');
      break;
    case HttpStatusCode.METHOD_NOT_ALLOWED:
      toast.error('วิธีที่ใช้ไม่ได้รับการอนุญาต');
      break;
    case HttpStatusCode.INTERNAL_SERVER_ERROR:
      toast.error('เกิดความผิดพลาดที่เซิฟเวอร์');
      break;
    case HttpStatusCode.NOT_FOUND:
      if (typeof(error.response.data) === "string") {
        toast.error(`${error.response.data}`);
      } else {
        toast.error('ไม่พบข้อมูล');
      }
      break;
  }
};

const showLoading = (request: InternalAxiosRequestConfig) => {
  if (Boolean(request.headers?.isDisabledLoading)) {
    return;
  }

  loading.showLoading(request.url);
};

const hideLoading = (response: AxiosResponse) => {
  if (Boolean(response.config.headers?.isDisabledLoading)) {
    return;
  }

  loading.hideLoading(response.config.url);
};

export default Axios;