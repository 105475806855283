import { DataTableType } from 'pages/D/D01';
import http from 'utils/axios';

const getSenateMeetingAsync = async (page: number, size: number, startDate?: Date, endDate?: Date) => {
  const params = {
    page,
    size,
    startDate: startDate?.toISOString(),
    endDate: endDate?.toISOString(),
  };

  return await http.get('api/meetingoverviewdashboard/senate/meeting', { params });
};

const getCommitteeMeetingAsync = async (page: number, size: number, startDate?: Date, endDate?: Date, bureauId?: string) => {
  const params = {
    page,
    size,
    startDate: startDate?.toISOString(),
    endDate: endDate?.toISOString(),
    bureauId,
  };

  return await http.get('api/meetingoverviewdashboard/committee/meeting', { params });
};

const getCommitteeBureauGroupAsync = async (page: number, size: number, startDate?: Date, endDate?: Date, bureauCode?: string) => {
  const params = {
    page,
    size,
    startDate: startDate?.toISOString(),
    endDate: endDate?.toISOString(),
    bureauCode,
  };

  return await http.get('api/meetingoverviewdashboard/committee/meetingbybureau', { params });
};

const getSenateOverviewChartMeetingAsync = async (startDate?: Date, endDate?: Date) => {
  const params = {
    startDate,
    endDate,
  };

  return await http.get('api/meetingOverviewDashboard/overviewchart/senate', { params });
};

const getCommitteeOverviewChartMeetingAsync = async (startDate?: Date, endDate?: Date) => {
  const params = {
    startDate,
    endDate,
  };

  return await http.get('api/meetingOverviewDashboard/overviewchart/committee', { params });
};

const exportExcelSenateAsync = async (page: number, size: number, startDate: Date, endDate: Date) => {
  const params = {
    page,
    size,
    startDate,
    endDate,
  };

  return await http.get('api/meetingoverviewdashboard/senate/export/excel', { params, responseType: 'blob' });
};

const exportWordSenateAsync = async (startDate: Date, endDate: Date) => {
  const params = {
    startDate,
    endDate,
  };

  return await http.get('api/meetingoverviewdashboard/senate/export/word', { params, responseType: 'blob' });
};

const exportPdfSenateAsync = async (page: number, size: number, startDate: Date, endDate: Date) => {
  const params = {
    page,
    size,
    startDate,
    endDate,
  };

  return await http.get('api/meetingoverviewdashboard/senate/export/pdf', { params, responseType: 'blob' });
};

const exportExcelCommitteeAsync = async (type: DataTableType, page: number, size: number, startDate: Date, endDate: Date) => {
  const params = {
    page,
    size,
    startDate,
    endDate,
  };

  if (type === DataTableType.Committee) {
    return await http.get('api/meetingoverviewdashboard/committee/ctcommittee/export/excel', { params, responseType: 'blob' });
  }

  return await http.get('api/meetingoverviewdashboard/committee/bureau/export/excel', { params, responseType: 'blob' });
};

const exportWordCommitteeAsync = async (type: DataTableType, startDate: Date, endDate: Date) => {
  const params = {
    startDate,
    endDate,
  };

  if (type === DataTableType.Committee) {
    return await http.get('api/meetingoverviewdashboard/committee/ctcommittee/export/word', { params, responseType: 'blob' });
  }

  return await http.get('api/meetingoverviewdashboard/committee/bureau/export/word', { params, responseType: 'blob' });
};

const exportPdfCommitteeAsync = async (type: DataTableType, page: number, size: number, startDate: Date, endDate: Date) => {
  const params = {
    page,
    size,
    startDate,
    endDate,
  };

  if (type === DataTableType.Committee) {
    return await http.get('api/meetingoverviewdashboard/committee/ctcommittee/export/pdf', { params, responseType: 'blob' });
  }

  return await http.get('api/meetingoverviewdashboard/committee/bureau/export/pdf', { params, responseType: 'blob' });
};

const getSenateDetailAsync = async (id: string, page: number, size: number, startDate: Date, endDate: Date) => {
  const params = {
    page,
    size,
    startDate,
    endDate,
  };

  return await http.get(`api/MeetingOverviewDashboard/senate/meeting/snmeetingperiod/${id}`, { params });
};

const getCommitteeDetailAsync = async (code: string, page: number, size: number, startDate: Date, endDate: Date) => {
  const params = {
    page,
    size,
    startDate,
    endDate,
  };

  return await http.get(`api/MeetingOverviewDashboard/committee/meeting/ctcommittee/${code}`, { params });
};

const getCommitteeBureauDetailAsync = async (id: string, page: number, size: number, startDate: Date, endDate: Date) => {
  const params = {
    page,
    size,
    startDate,
    endDate,
  };

  return await http.get(`api/MeetingOverviewDashboard/committee/meetingbybureau/smbureau/${id}`, { params });
};

const getMeetingByCommitteeIdAsync = async (page: number, size: number, committeeId: number, startDate: Date, endDate: Date) => {
  const params = {
    page,
    size,
    startDate,
    endDate,
  };

  return await http.get(`api/MeetingOverviewDashboard/committee/meeting/${committeeId}`, { params });
};

const d01Service = {
  getSenateMeetingAsync,
  getCommitteeMeetingAsync,
  getSenateOverviewChartMeetingAsync,
  getCommitteeOverviewChartMeetingAsync,
  getCommitteeBureauGroupAsync,
  exportExcelSenateAsync,
  exportExcelCommitteeAsync,
  getSenateDetailAsync,
  getCommitteeDetailAsync,
  getCommitteeBureauDetailAsync,
  exportPdfSenateAsync,
  exportPdfCommitteeAsync,
  exportWordSenateAsync,
  exportWordCommitteeAsync,
  getMeetingByCommitteeIdAsync,
};

export default d01Service;