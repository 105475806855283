interface Props {
  children: JSX.Element | JSX.Element[];
  color?: string;
  backgroundColor?: string;
}

export function Row(props: Props) {
  return (
    <tr style={{ color: props.color ?? '', backgroundColor: props.backgroundColor ?? '' }}>
      {props.children}
    </tr>
  );
}