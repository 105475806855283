import { CtReviewChapterCriteria, CtReviewChapterDocumentCriteria } from 'models';
import http from 'utils/axios';

const getCtReviewChapterDocumentListAsync = async (page: number, size: number, criteria?: CtReviewChapterDocumentCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get('api/ctreviewchapterdocument', { params });
};

const getCtReviewChapterDocumentDetailHeaderAsync = async (id: string) => {
  return await http.get(`api/ctreviewchapterdocument/ctmeeting/${id}`);
};

const getCtReviewChapterListAsync = async (id: string, page: number, size: number, criteria?: CtReviewChapterCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get(`api/ctreviewchapterdocument/ctmeeting/${id}/ctmeetingchapter`, { params });
};

const sendReviewChapterAsync = async (id: string) => {
  return await http.put(`api/ctreviewchapterdocument/ctchapter/${id}/reviewed`);
};

const sendEditChapterAsync = async (id: string, comment: string) => {
  return await http.put(`api/ctreviewchapterdocument/ctchapter/${id}/sendedit`, JSON.stringify(comment),
    { headers: { 'Content-Type': 'application/json' } });
};

const collectDocumentAsync = async (id: string) => {
  return await http.put(`api/ctreviewchapterdocument/ctmeeting/${id}/collect`);
};

const ctReviewChapterDocumentService = {
  getCtReviewChapterDocumentListAsync,
  getCtReviewChapterDocumentDetailHeaderAsync,
  getCtReviewChapterListAsync,
  sendReviewChapterAsync,
  sendEditChapterAsync,
  collectDocumentAsync,
};

export default ctReviewChapterDocumentService;