import { SelectionType } from "constant";
import { DetailType } from "pages/S/S0302/List";
import { LoaderFunctionArgs } from "react-router-dom";
import { s0204, s0205, s0302, selectionService, snVideoService } from "services";

export const s0302LoaderAsync = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 1;
  const size = url.searchParams.get('size') || 10;
  const criteria = JSON.parse(url.searchParams.get('criteria')!);

  const [s0206nInquiryMeeting, snMeetingType, snMeetingPeriod] = await Promise.all([
    s0302.getSnInquiryMeetingListAsync(+page, +size, criteria),
    selectionService.getSelectionAsync(SelectionType.SN_MEETING_TYPE),
    selectionService.getSelectionAsync(SelectionType.SN_MEETING_PERIOD),
  ]);

  return {
    data: {
      rows: s0206nInquiryMeeting.data.rows,
      totalRows: s0206nInquiryMeeting.data.totalRows,
      page: +page,
      size: +size,
    },
    snMeetingTypeItems: snMeetingType.data,
    snMeetingPeriodItems: snMeetingPeriod.data,
  };
};

export const s0302ListLoaderAsync = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;

  if (id) {
    const [snAgendaType] = await Promise.all([
      s0205.getAgendaDDLAsync(id),
    ]);

    return {
      snAgendaTypeList: snAgendaType.data,
    };
  }
};

export const s0302DetailLoaderAsync = async ({ params }: LoaderFunctionArgs) => {
  const { id, detailId, type } = params;

  if (id && type === DetailType.MEETING) {
    const [snInquiryMeetingDetail, snMeetingType, snMeetingPeriod, document, videoFile] = await Promise.all([
      s0302.getSnInquiryMeetingDetailAsync(id),
      selectionService.getSelectionAsync(SelectionType.SN_MEETING_TYPE),
      selectionService.getSelectionAsync(SelectionType.SN_MEETING_PERIOD),
      s0302.getMeetingDocumentAsync(id),
      snVideoService.getFullVideoAsync(id),
    ]);

    return {
      snInquiryMeetingDetail: snInquiryMeetingDetail.data,
      snMeetingTypeItems: snMeetingType.data,
      snMeetingPeriodItems: snMeetingPeriod.data,
      videoFile: videoFile.data,
      documentData: document.data,
    };
  }

  if (detailId && type === DetailType.MEETING_RANK) {
    const [snInquiryMeetingDetail, snMeetingType, snMeetingPeriod, document, fileId] = await Promise.all([
      s0302.getSnInquiryMeetingRankAsync(detailId),
      selectionService.getSelectionAsync(SelectionType.SN_MEETING_TYPE),
      selectionService.getSelectionAsync(SelectionType.SN_MEETING_PERIOD),
      s0204.getSnMakeDocumentDetailAsync(detailId),
      snVideoService.getRecordFileIdAsync(detailId, id!),
    ]);

    const videoFile = await snVideoService.getVideoAsync(fileId.data[0]);

    return {
      snInquiryMeetingDetail: snInquiryMeetingDetail.data,
      snMeetingTypeItems: snMeetingType.data,
      snMeetingPeriodItems: snMeetingPeriod.data,
      videoFile: videoFile.data,
      documentData: document.data.snChapterDocuments,
      fileId: fileId.data,
    };
  }
};