import { Collabora, CollaboraRef } from "components"
import { Col, Row } from "react-bootstrap";
import { useRef, useState } from "react";
import { Container, Navbar as NavbarBT, Image } from 'react-bootstrap';
import Video, { VideoOption } from "components/Video/Video";
import { VideoControl } from "constant";

export default function FreePage() {
  const [videoData] = useState<File>();
  const [fileIndex, setFileIndex] = useState<number>(0);
  const collaboraRef = useRef<CollaboraRef>(null);
  const videoRef = useRef<VideoOption>({} as VideoOption);
  const emptyDocumentId = process.env.REACT_APP_DOCS_EMPTY;

  function loadCollabora() {
    const iframe = document.getElementById(emptyDocumentId ?? "") as HTMLIFrameElement;

    iframe.contentWindow?.addEventListener('keydown', (e) => clickBtn(e.key));
  }

  function clickBtn(key: string) {
    switch (key) {
      case VideoControl.F10:
        videoRef.current.backRef();
        break;
      case VideoControl.F9:
        videoRef.current.playRef();
        break;
      case VideoControl.F11:
        videoRef.current.aheadRef();
        break;
    }
  }

  const onEndedAsync = async () => {
    setFileIndex(0);
  };
  const onLoadedData = () => {
    if (fileIndex) {
      videoRef.current.playRef();
    }
  };

  return (
    <>
      <Navbar />
      <div className="p-3">
        <Row>
          <Col xl={3}>
            <Video
              onEnded={onEndedAsync}
              onLoadedData={onLoadedData}
              ref={videoRef}
              files={videoData?.type ? [videoData] : []}
              showPlayButton={true}
              showPlayTimeConfig={true}
              showSpeakToText={true}
              uploadFakeVideo
              showGuideCollabora
              getUrlLink />
          </Col>
          <Col>
            <Collabora
              docId={emptyDocumentId ?? ""}
              docName="s0204"
              height="1000px"
              ref={collaboraRef}
              onCollaboraLoaded={() => loadCollabora()}
              canWrite />
          </Col>
        </Row>
      </div>
    </>
  );
};

function Navbar() {
  return (
    <>
      <NavbarBT expand="lg" bg="primary" style={{ position: "sticky", top: 0 }}>
        <Container fluid>
          <NavbarBT.Brand>
            <div className="d-flex">
              <Image
                src="/images/logo.png"
                width="60"
                height="60" />
              <div className='d-flex flex-column justify-content-center ms-2 lh-sm'>
                <span className='fs-5'>สำนักงานเลขาธิการวุฒิสภา</span>
                <span className='fs-5'>ระบบจดและจัดทำรายงานการประชุม</span>
              </div>
            </div>
          </NavbarBT.Brand>
        </Container>
      </NavbarBT>
    </>
  );
}