import { Card, Input, InputDate, Layout, Selector, Status, StatusType, Table } from "components";
import { StatusMeeting, statusMeetingItems } from "constant";
import { ItemModel, Pagination, SnReviewOverViewCriteria, SnReviewOverViewList } from "models";
import { FormEvent, useMemo, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaRegFileAlt, FaSearch, FaTrashAlt } from "react-icons/fa";
import { useLoaderData, useNavigate, useSubmit } from "react-router-dom";
import { formatDateTh, fullDateTime, getTime, getYearList } from "utils";

type Loader = { snMeetingTypeItems: ItemModel[], snMeetingPeriodItems: ItemModel[], data: Pagination<SnReviewOverViewList> };

export default function S0206() {
  const { snMeetingTypeItems, snMeetingPeriodItems, data } = useLoaderData() as Loader;
  const [criteria, setCriteria] = useState<SnReviewOverViewCriteria>({} as SnReviewOverViewCriteria);
  const submit = useSubmit();
  const navigate = useNavigate();

  const statusItems = [
    StatusMeeting.DOCUMENT_COMPLETE,
    StatusMeeting.COLLECT,
    StatusMeeting.OVERVIEW_REVIEWING,
    StatusMeeting.OVERVIEW_REVIEWED,
    StatusMeeting.GUARANTEE,
    StatusMeeting.REJECT,
  ];

  const filterStatus = useMemo(() => statusMeetingItems.filter(s => statusItems.includes(s.value)), [statusMeetingItems]);

  const onSearch = (size: number, page: number) => {
    submit({
      page: page.toString(),
      size: size.toString(),
      criteria: JSON.stringify(criteria),
    });
  };

  const onClear = () => {
    setCriteria({} as SnReviewOverViewCriteria);

    submit({});
  };

  const onChangeCriteria = (prop: keyof SnReviewOverViewCriteria, value?: string | number | Date) => {
    setCriteria({ ...criteria, [prop]: value });
  };

  const onSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSearch(data.size, data.page);
  };

  return (
    <Layout title="ตรวจสอบภาพรวมและจัดทำรูปเล่ม">
      <Card>
        <Form onSubmit={onSubmitSearch}>
          <Row>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Selector
                label="ประเภทการประชุม"
                placeholder="ประเภทการประชุม"
                items={snMeetingTypeItems}
                value={criteria.meetingTypeId}
                onChange={(value) => onChangeCriteria('meetingTypeId', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Input
                type="number"
                label="ครั้งที่ประชุม"
                value={criteria.time}
                onChange={(value) => onChangeCriteria('time', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Selector
                label="สมัยการประชุม"
                placeholder="สมัยการประชุม"
                items={snMeetingPeriodItems}
                value={criteria.periodId}
                onChange={(value) => onChangeCriteria('periodId', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Input
                label="ชื่อการประชุม"
                value={criteria.name}
                onChange={(value) => onChangeCriteria('name', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Selector
                label="ปี"
                placeholder="ปี"
                items={getYearList()}
                value={criteria.year}
                onChange={(value) => onChangeCriteria('year', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <InputDate
                name="meeting-date"
                label="วันที่ประชุม"
                value={criteria.meetingDate}
                onChange={(value) => onChangeCriteria('meetingDate', value)} />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3}>
              <Selector
                label="สถานะ"
                placeholder="สถานะ"
                items={filterStatus}
                value={criteria.status}
                onChange={(value) => onChangeCriteria('status', value)} />
            </Col>
          </Row>
          <div className="d-flex gap-3 justify-content-start mt-2">
            <Button
              variant="primary"
              type="submit">
              <FaSearch className="me-2" />
              ค้นหา
            </Button>
            <Button
              variant="outline-primary"
              type="button"
              onClick={onClear}>
              <FaTrashAlt className="me-2" />
              ล้างค่า
            </Button>
          </div>
        </Form>
      </Card>
      <Row className="mt-3">
        <Col>
          <div className="table-relative-fix">
            <div className="table-scroll">
              <Table
                total={data.totalRows}
                page={data.page}
                size={data.size}
                onChange={onSearch}>
                <Table.Header>
                  <Table.Row>
                    <Table.Column minWidth={100}>ลำดับ</Table.Column>
                    <Table.Column minWidth={150}>ประเภทการประชุม</Table.Column>
                    <Table.Column minWidth={100}>ครั้งที่</Table.Column>
                    <Table.Column minWidth={250}>สมัยการประชุม</Table.Column>
                    <Table.Column minWidth={250}>ชื่อการประชุม</Table.Column>
                    <Table.Column minWidth={250}>ผู้ตรวจสอบและจัดทำรูปเล่ม</Table.Column>
                    <Table.Column minWidth={250}>ผู้ตรวจสอบรูปเล่มรายงาน</Table.Column>
                    <Table.Column minWidth={250}>วันที่ประชุม</Table.Column>
                    <Table.Column minWidth={200}>สถานะ</Table.Column>
                    <Table.Column minWidth={200}>ผู้แก้ไขข้อมูล</Table.Column>
                    <Table.Column minWidth={200}>วันที่แก้ไขข้อมูล</Table.Column>
                    <Table.Column minWidth={200} className="fix-col" />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {data.rows?.map((data, index) => (
                    <Table.Row key={data.id}>
                      <Table.Cell center>{(index + 1).toString()}</Table.Cell>
                      <Table.Cell center>{data.meetingType}</Table.Cell>
                      <Table.Cell center>{data.time.toString()}</Table.Cell>
                      <Table.Cell center>{data.meetingPeriod}</Table.Cell>
                      <Table.Cell>{data.name}</Table.Cell>
                      <Table.Cell>{data.reviewerFullName}</Table.Cell>
                      <Table.Cell>{data.certifyingFullName}</Table.Cell>
                      <Table.Cell center>{`${formatDateTh(data.meetingDate)} ${getTime(data.startTime)} - ${getTime(data.endTime)} น.`}</Table.Cell>
                      <Table.Cell center><Status type={StatusType.MEETING_STATUS} value={data.status} /></Table.Cell>
                      <Table.Cell>{data.updateByUserFullName}</Table.Cell>
                      <Table.Cell center>{fullDateTime(data.updateDate)}</Table.Cell>
                      <Table.Cell center className="fix-col">
                        <div className="mx-4">
                          <Button
                            variant="outline-primary"
                            size="sm"
                            onClick={() => navigate(`detail/${data.id}`)}>
                            <FaRegFileAlt className="me-2" />
                            ดูรายละเอียด
                          </Button>
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}