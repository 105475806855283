import { CtMeetingRecordCriteria, CtMeetingRecordDetailList } from 'models';
import http from 'utils/axios';
import { HttpStatusCode, RecordType } from 'constant';
import * as tus from 'tus-js-client'
import { showModalProgressBarLoadingAsync } from 'utils';

const getListAsync = async (size: number, page: number, criteria?: CtMeetingRecordCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get(`api/ctmeetingrecord`, { params });
};

const getDetailAsync = async (id: string) => {
  return await http.get(`api/ctmeetingrecord/header/${id}`);
};

const getDetailListAsync = async (size: number, page: number, id: string) => {
  const params = {
    page,
    size,
  };

  return await http.get(`api/ctmeetingrecord/${id}`, { params });
};

const uploadManualVideoAsync = async (id: string, data: CtMeetingRecordDetailList) => {
  const formData = new FormData();

  formData.append('ctMeetingChapterId', data.id);

  if (data.video) {
    formData.append("video", data.video);
  }

  return await http.post(`api/ctmeetingrecord/uploadmanualvideo/${id}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

const startAndStopRecordAsync = async (id: string, type: RecordType) =>
  await http.put(`api/ctmeetingrecord/${id}`, JSON.stringify(type),
    { headers: { 'Content-Type': 'application/json' } });

const startRecordAsync = async (id: string) =>
  await http.post(`api/ctmeetingrecord/${id}/startrecord`);

const pauseRecordAsync = async (id: string) =>
  await http.post(`api/ctmeetingrecord/${id}/pauserecord`);

const stopRecordAsync = async (id: string) =>
  await http.post(`api/ctmeetingrecord/${id}/stoprecord`);

const uploadFullVideoManualAsync = async (id: string, video: File) => {
  const fileId = await new Promise<string>(
    (resolve, reject) => {
      const upload = new tus.Upload(video, {
        endpoint: `${process.env.REACT_APP_API_URL}api/files/tus/`,
        // Retry delays will enable tus-js-client to automatically retry on errors
        retryDelays: [0, 3000, 5000, 10000, 20000],
        chunkSize: 30000000,
        parallelUploads: 1,
        // Attach additional meta data about the file for the server
        metadata: {
          filename: video.name,
          filetype: video.type,
        },
        // Callback for errors which cannot be fixed using retries
        onError: function (error) {
          console.log('Failed because: ' + error);
          reject(error);
        },
        // Callback for reporting upload progress
        onProgress: function (bytesUploaded, bytesTotal) {
          const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2)
          showModalProgressBarLoadingAsync(parseFloat(percentage), "กำลังอัพโหลดวิดิโอ...");
        },
        // Callback for once the upload is completed
        onSuccess: async function () {
          const paths = upload.url?.split('/');
          if (paths != null) {
            const lastPath = paths[paths?.length - 1];
            console.log('TUS Upload ID is ', lastPath);

            const formData = new FormData();
            formData.append("TusFileId", lastPath);
            formData.append("FileName", video.name);

            const resp = await http.post(
              `api/ctmeetingrecord/${id}/uploadmanualfullvideo`,
              formData)

            if (resp.status === HttpStatusCode.ACCEPTED ||
              resp.status === HttpStatusCode.OK) {
              resolve(resp.data);
            } else {
              reject(resp.data);
            }
          }
        },
      })

      upload.start();
    })

  return fileId;
};

const removeFileAsync = async (meetingId: string, id: string) => {
  return await http.delete(`api/ctmeetingrecord/${meetingId}/chapter/${id}`);
};

const c0203 = {
  getListAsync,
  getDetailListAsync,
  uploadManualVideoAsync,
  getDetailAsync,
  startAndStopRecordAsync,
  startRecordAsync,
  pauseRecordAsync,
  stopRecordAsync,
  uploadFullVideoManualAsync,
  removeFileAsync,
};

export default c0203;
