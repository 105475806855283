import { C0202Criteria, CtMeeting, CtMeetingChapter } from 'models';
import http from 'utils/axios';

const saveAsync = async (data: CtMeeting) => {
  const meetingDate = new Date(data.meetingDate).toISOString().split('T')[0];

  return await http.post('api/ctmeeting', { ...data, meetingDate });
};

const updateAsync = async (data: CtMeeting) => {
  const meetingDate = new Date(data.meetingDate).toISOString().split('T')[0];

  return await http.put(`api/ctmeeting/${data.id}`, { ...data, meetingDate });
};

const autoQueueAsync = async (date: Date, meetingType: string, startTime?: Date, endTime?: Date, startTimeSecond?: Date, endTimeSecond?: Date, id?: string) => {
  const params = {
    date: new Date(date).toISOString().split('T')[0],
    meetingType,
    startTime,
    endTime,
    startTimeSecond,
    endTimeSecond,
    id,
  };

  return await http.get('api/ctmeeting/autoqueue', { params: params });
};

const getDetailAsync = async (id: string) => {
  return await http.get(`api/ctmeeting/${id}`);
};

const getListAsync = async (page: number, size: number, data: C0202Criteria) => {
  const params = {
    ...data,
    page,
    size,
    date: data?.meetingDate ? new Date(data.meetingDate).toISOString().split('T')[0] : '',
  };

  return await http.get('api/ctmeeting', { params });
};

const removeAsync = async (id: string) => {
  return await http.delete(`api/ctmeeting/${id}`);
};

const syncDataAsync = async (date: Date) => {
  const meetingDate = date.toISOString().split('T')[0];

  return await http.post(`api/ctmeeting/sync-data`, JSON.stringify(meetingDate),
    { headers: { 'Content-Type': 'application/json' } });
}

const generateInspectAsync = async (bureauCode: string, month: number) => {
  const params = {
    bureauCode,
    month,
  };

  return await http.get('api/CtMeeting/auto-inspect', { params });
};

const exportMeetingExcelAsync = async (day: string) => {
  const params = {
    day,
  };

  return await http.get('api/ctmeeting/export-excel', { params, responseType: 'blob' });
};

const exportMeetingWordAsync = async (day: string) => {
  const params = {
    day,
  };

  return await http.get('api/ctmeeting/export-word', { params, responseType: 'blob' });
};

const exportMeetingPDFAsync = async (day: string) => {
  const params = {
    day,
  };

  return await http.get('api/ctmeeting/export-pdf', { params, responseType: 'blob' });
};

const confirmMeetingChapterAsync = async (id: string, startTime: Date, endTime: Date) => {
  const body = {
    id,
    startTime,
    endTime,
  };

  return await http.put(`api/ctmeeting/ctmeetingChapter/${id}/confirm`, body);
};

const removeMeetingChapterAsync = async (id: string, meetingId: string) => {
  const params = {
    meetingId,
  };

  return await http.delete(`api/ctmeeting/ctmeetingchapter/${id}`, { params });
};


const createMeetingChapterAsync = async (id: string, data: CtMeetingChapter) => {
  return await http.post(`api/ctmeeting/${id}/ctmeetingchapter`, data);
};

const runQueueAsync = async (runQueueData: CtMeeting) => {
  const params = {
    meetingType: runQueueData.meetingType,
    date: runQueueData.meetingDate,
    startTime: runQueueData.startTime,
    endTime: runQueueData.endTime,
    startTimeSecond: runQueueData.startTimeSecond,
    endTimeSecond: runQueueData.endTimeSecond,
  };

  return await http.get('api/ctmeeting/run-queue', { params });
};

const updateStatusAsync = async (id: string, status: string) =>
  await http.put(`api/ctmeeting/update-status/${id}`, { status });

const c0202Service = {
  saveAsync,
  updateAsync,
  autoQueueAsync,
  getDetailAsync,
  getListAsync,
  removeAsync,
  syncDataAsync,
  generateInspectAsync,
  exportMeetingExcelAsync,
  confirmMeetingChapterAsync,
  removeMeetingChapterAsync,
  createMeetingChapterAsync,
  runQueueAsync,
  exportMeetingWordAsync,
  exportMeetingPDFAsync,
  updateStatusAsync,
};

export default c0202Service;