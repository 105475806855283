import { Layout, Modal, TextArea, CollaboraRef, VideoOption, Collabora, Card, UploadFile, FileValue } from "components";
import { Button, DropdownButton, Dropdown, Form, Col, Row } from "react-bootstrap";
import { FaAngleLeft, FaFileAlt, FaRegFilePdf, FaRegFileWord, FaUndo } from "react-icons/fa";
import { useLoaderData, useNavigate, useParams, useSubmit } from "react-router-dom";
import { TbFileCheck, TbFileX } from "react-icons/tb";
import { FiSave } from "react-icons/fi";
import { useEffect, useMemo, useRef, useState } from 'react';
import { ItemModel, S0207SnDocument, SnCerifyingReportDetail } from "models";
import { HttpStatusCode, StatusMeeting, VideoControl, statusMeetingItems } from "constant";
import { submitForm, exportFileWordAsync, exportFilePDFAsync, getFormatDateBC, getTime } from "utils";
import { s0207 } from "services";
import toast from "utils/toast";

type Loader = { snCerifyingreportData: SnCerifyingReportDetail, snMeetingTypeItems: ItemModel[], snMeetingPeriodItems: ItemModel[], snMeetingRoomItems: ItemModel[] };

export default function S0207Document() {
  const { snCerifyingreportData } = useLoaderData() as Loader;
  const [showModal, setShowModal] = useState(false);
  const [showModalApprove, setShowModalApprove] = useState(false);
  const [canWrite, setCanWrite] = useState<boolean>();
  const navigate = useNavigate();
  const { id } = useParams();
  const submit = useSubmit();
  const [docVersion, setDocVersion] = useState<S0207SnDocument>({} as S0207SnDocument);
  const programName = useState<string>("ตรวจสอบและรับรองรายงานการประชุม");

  useEffect(() => {
    if (snCerifyingreportData.snDocuments) {
      setDocVersion(snCerifyingreportData.snDocuments[0]);
    }

    if (snCerifyingreportData.status === StatusMeeting.OVERVIEW_REVIEWED) {
      setCanWrite(true);
    } else {
      setCanWrite(false);
    }
  }, [snCerifyingreportData]);

  const documents = useMemo(() => {
    return snCerifyingreportData.documents?.map(d => ({ id: d.id, fileName: d.fileName, sequence: d.sequence })) as FileValue[];
  }, [snCerifyingreportData.documents]);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const actionAsync = async (statusData: StatusMeeting) => {
    if (id) {
      const { status } = await s0207.actionSnCerifyingreportAsync(id, statusData);

      if (status === HttpStatusCode.ACCEPTED && statusData === StatusMeeting.OVERVIEW_REVIEWED) {
        toast.success('บันทึกข้อมูลสำเร็จ');

        submit({});
      }

      if (status === HttpStatusCode.ACCEPTED && statusData === StatusMeeting.GUARANTEE) {
        toast.success('ส่งอนุมัติสำเร็จ');

        submit({});
      }
    }
  };

  const button = () => {
    if ((snCerifyingreportData.status !== StatusMeeting.REJECT && snCerifyingreportData.status !== StatusMeeting.GUARANTEE)) {
      return (
        <>
          <Button
            variant="outline-primary"
            type="button"
            onClick={() => actionAsync(StatusMeeting.OVERVIEW_REVIEWED)}>
            <FiSave className="me-2" />บันทึกเวอร์ชั่น
          </Button>
          <Button
            variant="outline-primary"
            type="button"
            onClick={() => handleShowModal()}>
            <TbFileX className="me-2" />ไม่รับรองรายงาน
          </Button>
          <Button
            variant="primary"
            type="button"
            onClick={() => setShowModalApprove(true)}>
            <TbFileCheck className="me-2" />รับรองรายงาน
          </Button>
        </>
      );
    }
  };

  const collaboraRef = useRef<CollaboraRef>(null);

  const videoRef = useRef<VideoOption>({} as VideoOption);

  function loadCollabora() {
    const iframe = document.getElementById(docVersion.id) as HTMLIFrameElement;

    iframe.contentWindow?.addEventListener('keydown', (e) => clickBtn(e.key));
  }

  function clickBtn(key: string) {
    switch (key) {
      case VideoControl.F10:
        videoRef.current.backRef();
        break;
      case VideoControl.F9:
        videoRef.current.playRef();
        break;
      case VideoControl.F11:
        videoRef.current.aheadRef();
        break;
    }
  }

  const onSelectVerstion = (id: string | null) => {
    if (id) {
      const documentSelected = snCerifyingreportData.snDocuments.find(f => f.id == id);

      setDocVersion(documentSelected ? documentSelected : {} as S0207SnDocument);

      if (documentSelected !== snCerifyingreportData.snDocuments[0]) {
        setCanWrite(false);

        return;
      }

      if (snCerifyingreportData.status === StatusMeeting.OVERVIEW_REVIEWED) {
        setCanWrite(true);
      }
    }
  };

  const setDefaultDocumentOverview = () => {
    setDocVersion(snCerifyingreportData.snDocuments ? snCerifyingreportData.snDocuments[0] : {} as S0207SnDocument);
  };

  return (
    <Layout title={programName[0]} className="s0207-detail">
      <Form>
        <div className="d-flex justify-content-between my-3">
          <Button
            variant="outline-primary"
            type="button"
            onClick={() => navigate('/s0207')}>
            <FaAngleLeft className="me-2" />ย้อนกลับ
          </Button>
          <div className="d-flex gap-2">
            <Button
              variant="outline-primary"
              onClick={setDefaultDocumentOverview}>
              <FaFileAlt className="me-2" /> ดูรายงานที่รวบรวม
            </Button>
            <DropdownButton
              title={<span><FaUndo className="me-2" />เวอร์ชั่นก่อนหน้า ({docVersion.versionDocument})</span>}
              drop="down-centered"
              variant="outline-primary"
              onSelect={(value) => onSelectVerstion(value)}>
              {snCerifyingreportData.snDocuments.map(doc =>
                <Dropdown.Item
                  eventKey={doc.id}
                  key={doc.id}>
                  {doc.versionDocument}
                </Dropdown.Item>)}
            </DropdownButton>
            <Button
              variant="outline-primary"
              type="button"
              onClick={() => exportFilePDFAsync(docVersion.id, programName[0])}>
              <FaRegFilePdf className="me-2" />Export PDF
            </Button>
            <Button
              variant="outline-primary"
              type="button"
              onClick={() => exportFileWordAsync(docVersion.id, programName[0])}>
              <FaRegFileWord className="me-2" />Export Word
            </Button>
            {button()}
          </div>
        </div>
        <Row>
          <Col xl={4}>
            <Card className="mt-2">
              <ManageVideo />
            </Card>
            <UploadFile
              value={documents}
              disabled
              canEdit
              onEditDocument={(value) => setDocVersion({ ...docVersion, id: value })} />
          </Col>
          <Col xl={8}>
            <Collabora
              docId={docVersion.id}
              docName="s0204"
              height="1000px"
              ref={collaboraRef}
              onCollaboraLoaded={() => loadCollabora()}
              canWrite={canWrite} />
          </Col>
        </Row>
        <ModalDisAprrove showModal={showModal} onHide={() => setShowModal(false)} />
        <ModalApprove showModal={showModalApprove} onHide={() => setShowModalApprove(false)} action={actionAsync} />
      </Form>
    </Layout>
  );
}

function ManageVideo() {
  const { snCerifyingreportData, snMeetingPeriodItems, snMeetingTypeItems, snMeetingRoomItems } = useLoaderData() as Loader;
  const [snCerifyingreport, setSnCerifyingreport] = useState<SnCerifyingReportDetail>({} as SnCerifyingReportDetail);

  useEffect(() => {
    if (snCerifyingreportData) {
      setSnCerifyingreport(snCerifyingreportData);
    }
  }, [snCerifyingreportData]);

  const getSnMeetingTypeName = (value: string) => {
    const findData = snMeetingTypeItems.find(s => s.value === value);

    return findData?.label;
  };

  const getSnMeetingPeriodName = (value: string) => {
    const findData = snMeetingPeriodItems.find(s => s.value === value);

    return findData?.label;
  };

  const getSnMeetingRoom = (value: string) => {
    const findData = snMeetingRoomItems.find(s => s.value === value);

    return findData?.label;
  };

  const convertStatusToText = (status: string) => {
    const findStatus = statusMeetingItems.find(s => s.value === status)?.label;

    return findStatus;
  };

  return (
    <>
      <Row className="mx-3">
        <Col xl={4}>
          <Form.Label className="fw-bold">
            ข้อมูลการประชุม
          </Form.Label>
        </Col>
        <Row>
          <Col lg={12}>
            ประเภทวาระประชุม : {getSnMeetingTypeName(snCerifyingreport.snMeetingTypeId)}
          </Col>
          <Col lg={6}>
            ครั้งที่ : {snCerifyingreport.time}
          </Col>
          <Col lg={12}>
            สมัยที่ประชุม : {getSnMeetingPeriodName(snCerifyingreport.snMeetingPeriodId)}
          </Col>
          <Col lg={6}>
            วันที่ประชุม : {getFormatDateBC(snCerifyingreport.startDate)}
          </Col>
          <Col lg={6}>
            เวลา : {`${getTime(snCerifyingreport.startTimeFirst)}-${getTime(snCerifyingreport.endTimeFirst)}`}
          </Col>
          <Col lg={12}>
            ณ ห้องประชุม : {getSnMeetingRoom(snCerifyingreport.smMeetingRoomId)}
          </Col>
          <Col lg={12}>
            สถานะ : {convertStatusToText(snCerifyingreport.status)}
          </Col>
        </Row>
      </Row>
    </>
  );
}

interface ModalProps {
  showModal: boolean;
  onHide: () => void;
}

function ModalDisAprrove(props: ModalProps) {
  const [comment, setCommet] = useState<string>();
  const { id } = useParams();
  const submit = useSubmit();

  const rejectAsync = async () => {
    submitForm();

    if (!comment) {
      return;
    }

    if (id && comment) {
      const { status } = await s0207.rejectSnCerifyingreportAsync(id, comment);

      if (status === HttpStatusCode.ACCEPTED) {
        toast.success('ไม่รับรองรายงานสำเร็จ');

        onHide();
        submit({});
      }
    }
  };

  const onHide = () => {
    setCommet(undefined);

    props.onHide();
  };

  return (
    <Modal
      show={props.showModal}
      title="ไม่รับรองรายงาน">
      <Form>
        <TextArea
          label="ให้ความคิดเห็น"
          rule={{ required: true }}
          value={comment}
          onChange={(value) => setCommet(value)} />
        <div className="d-flex justify-content-center gap-4">
          <Button
            type="button"
            className="w-100"
            variant="outline-primary"
            onClick={onHide}>
            ยกเลิก
          </Button>
          <Button
            type="button"
            className="w-100"
            onClick={rejectAsync}>
            ยืนยัน
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

interface ModalApproveProps {
  showModal: boolean;
  onHide: () => void;
  action: Function;
}

function ModalApprove(props: ModalApproveProps) {
  const confirm = () => {
    props.onHide();

    props.action(StatusMeeting.GUARANTEE);
  };

  return (
    <Modal
      show={props.showModal}>
      <div>
        <Row >
          <Col sm={12} className="mt-3 d-flex align-items-center">
            <h3>อนุมัติ</h3>
          </Col>
          <Col sm={12} className="mt-2 mb-2 d-flex align-items-center">
            <p>ต้องการอนุมัติหรือไม่ ?</p>
          </Col>
        </Row>
      </div>
      <Row>
        <Col>
          <div className="d-flex flex-row gap-2 justify-content-center">
            <Button variant="light" className="w-50" type="button" onClick={props.onHide}>ยกเลิก</Button>
            <Button variant="primary" className="w-50" type="button" onClick={confirm}>ยืนยัน</Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
}