import { Layout, Card, Table, Input, Selector, InputDate, Status, StatusType } from "components";
import { statusMeetingItems } from "constant";
import { InQueryS1List, ItemModel, Pagination, InQueryS1Criteria } from "models";
import { Dispatch, FormEvent, SetStateAction, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaRegFileAlt, FaSearch, FaTrashAlt } from "react-icons/fa";
import { useLoaderData, useNavigate, useSubmit } from "react-router-dom";
import { fullDateTime, getFormatDateBC, getTime } from "utils";

type InQueryS1Context = {
  list: Pagination<InQueryS1List>;
  setList: Dispatch<SetStateAction<Pagination<InQueryS1List>>>;
  searchAsync: Function;
  criteria: InQueryS1Criteria;
};

type Loader = { meetingTypeDDL: ItemModel[], meetingPeriodDDL: ItemModel[], yearsDDL: ItemModel[], listData: Pagination<InQueryS1List> };

const Context = createContext({} as InQueryS1Context);

export default function S0301() {
  const { meetingPeriodDDL, meetingTypeDDL, yearsDDL, listData } = useLoaderData() as Loader;
  const [criteria, setCriteria] = useState<InQueryS1Criteria>({} as InQueryS1Criteria);
  const [list, setList] = useState<Pagination<InQueryS1List>>(listData);
  const submit = useSubmit();

  useEffect(() => {
    setList(listData);
  }, [listData]);

  const searchAsync = useCallback(async (size: number, page: number, criteria?: InQueryS1Criteria) => {
    submit({
      page: page.toString(),
      size: size.toString(),
      criteria: JSON.stringify(criteria),
    });
  }, []);

  const onClearAsync = async () => {
    setCriteria({} as InQueryS1Criteria);
    submit({});
  };

  const contextValue = useMemo(() => {
    return {
      list,
      setList,
      searchAsync,
      criteria,
    }
  }, [list, setList, searchAsync, criteria]);

  const onSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    searchAsync(list.size, list.page, criteria);
  };

  const onChangeCriteria = (prop: keyof InQueryS1Criteria, value?: string | number | Date) => {
    setCriteria({ ...criteria, [prop]: value });
  };

  return (
    <Context.Provider value={contextValue}>
      <Layout title="สถานะรายงานการประชุม">
        <Card className="my-3">
          <Form onSubmit={onSubmitSearch}>
            <Row>
              <Col sm={6} md={4} lg={4} xl={3}>
                <Selector
                  label="ประเภทการประชุม"
                  placeholder="ประเภทการประชุม"
                  items={meetingTypeDDL}
                  value={criteria.meetingTypeId}
                  onChange={(value) => onChangeCriteria('meetingTypeId', value)} />
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <Input
                  label="ครั้งที่ประชุม"
                  value={criteria.time}
                  type="number"
                  onChange={(value) => onChangeCriteria('time', value)} />
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <Selector
                  label="สมัยการประชุม"
                  placeholder="สมัยการประชุม"
                  items={meetingPeriodDDL}
                  value={criteria.periodId}
                  onChange={(value) => onChangeCriteria('periodId', value)} />
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <Input
                  label="ชื่อการประชุม"
                  value={criteria.name}
                  onChange={(value) => onChangeCriteria('name', value)} />
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <Selector
                  label="ปี"
                  placeholder="ปี"
                  items={yearsDDL}
                  value={criteria.year}
                  onChange={(value) => onChangeCriteria('year', value)} />
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <InputDate
                  label="วันที่ประชุม"
                  value={criteria.meetingDate}
                  onChange={(value) => onChangeCriteria('meetingDate', value)} />
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <Selector
                  label="สถานะ"
                  placeholder="สถานะ"
                  items={statusMeetingItems}
                  value={criteria.status}
                  onChange={(value) => onChangeCriteria('status', value)} />
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <Input
                  label="ผู้อภิปราย"
                  value={criteria.debater}
                  onChange={(value) => onChangeCriteria('debater', value)} />
              </Col>
              <Col sm={6} md={4} lg={4} xl={3}>
                <Input
                  label="วาระการประชุม"
                  value={criteria.agenda}
                  onChange={(value) => onChangeCriteria('agenda', value)} />
              </Col>
            </Row>
            <div className="d-flex gap-2 justify-content-start mt-2">
              <Button
                type="submit">
                <FaSearch className="me-2" />ค้นหา
              </Button>
              <Button
                variant="outline-primary"
                onClick={onClearAsync}>
                <FaTrashAlt className="me-2" />ล้างค่า
              </Button>
            </div>
          </Form>
        </Card>
        <DataTable />
      </Layout>
    </Context.Provider>
  );
}

function DataTable() {
  const navigate = useNavigate();
  const { list, searchAsync, criteria } = useContext(Context);

  return (
    <div className="table-relative-fix">
      <div className="table-scroll">
        <Table
          total={list.totalRows}
          page={list.page}
          size={list.size}
          onChange={(size, page) => searchAsync(size, page, criteria)}>
          <Table.Header>
            <Table.Row>
              <Table.Column minWidth={200}>ประเภทการประชุม</Table.Column>
              <Table.Column minWidth={100}>ครั้งที่</Table.Column>
              <Table.Column minWidth={250}>สมัยการประชุม</Table.Column>
              <Table.Column minWidth={200}>ห้องประชุม</Table.Column>
              <Table.Column minWidth={200}>ชื่อการประชุม</Table.Column>
              <Table.Column minWidth={250}>วันที่ประชุม</Table.Column>
              <Table.Column minWidth={200}>สถานะ</Table.Column>
              <Table.Column minWidth={200}>ผู้แก้ไขข้อมูล</Table.Column>
              <Table.Column minWidth={200}>วันที่แก้ไขข้อมูล</Table.Column>
              <Table.Column minWidth={200} className="fix-col" />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {list.rows?.map((value) => (
              <Table.Row key={value.id}>
                <Table.Cell center>{value.meetingType}</Table.Cell>
                <Table.Cell center>{value.time}</Table.Cell>
                <Table.Cell center>{value.meetingPeriod}</Table.Cell>
                <Table.Cell>{value.meetingRoom}</Table.Cell>
                <Table.Cell>{value.name}</Table.Cell>
                <Table.Cell center>{`${getFormatDateBC(value.meetingDate)} ${getTime(value.startTime)} - ${getTime(value.endTime)} น.`}</Table.Cell>
                <Table.Cell><Status type={StatusType.MEETING_STATUS} value={value.status} /></Table.Cell>
                <Table.Cell>{value.updateByUserFullName}</Table.Cell>
                <Table.Cell center>{fullDateTime(value.updateDate)}</Table.Cell>
                <Table.Cell center className="fix-col">
                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => navigate(`detail/${value.id}`)}>
                    <FaRegFileAlt className="me-2" />
                    ดูรายละเอียด
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
}